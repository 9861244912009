import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useText } from 'phoenix/hooks/UseText';
import { FormatNumber, SafeFormat } from 'phoenix/util';
import React, { ReactElement } from 'react';

export type PartialFillRow = {
    price: number;
    quantity: number;
    sequenceNumber: number;
    timestamp: string;
};

export type MultiLegFillRow = {
    description: string;
    orderNumber: string;
    orderType: string;
    price: string;
    quantity: string;
    symbol: string;
    timestamp: string;
};

export function MultilegFillsTable({ fills }: { fills: MultiLegFillRow[] }): ReactElement {
    const text = useText((t) => t);

    return (
        <Table className='fills-table'>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant='h6'>{text.orders.orderNumber}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.orders.dateAndTime}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.transactionsScreen.description}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.tradeTicket.receipt.orderType.priceType}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.orders.quantity}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.orders.fillPrice}</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fills?.map((f) => (
                    <TableRow key={f?.description}>
                        <TableCell>
                            <Typography variant='body2'>{f.orderNumber}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='body2'>{f.timestamp}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='body2'>{f.description}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='body2'>{f.orderType}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='body2'>{f.quantity}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='body2'>{f.price}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export const PartialFillsTable = ({ fills }: { fills: PartialFillRow[] }): ReactElement => {
    const text = useText((t) => t.orders);

    return (
        <Table className='fills-table partial-fills' stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant='h6'>{text.fills.sequenceNumber}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.fills.quantity}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.fills.price}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='h6'>{text.fills.timestamp}</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fills?.map((f, key) => (
                    <TableRow key={key} style={{ position: 'relative', overflow: 'hidden', borderRadius: '30px' }}>
                        <TableCell>
                            <Typography variant='h6'>{f.sequenceNumber}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='h6'>{FormatNumber.toDecimal(f.quantity)}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='h6'>{FormatNumber.toMoney(f.price)}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='h6'>{SafeFormat(f.timestamp, 'dd MMM yyyy, h:mm:ssa')}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
