import { Typography } from '@mui/material'
import { ArrowBackIosRounded } from '@mui/icons-material'
import { useColors } from 'hooks/UseColors'
import React, { useMemo } from 'react'
import { Flex } from '../../..'
import { PaddedSection } from '../PaddedSection'

export interface ModalViewHeaderProps {
    goBack?: () => any;
    onAction?: () => any;
    // Needs to be Capitalized since it's a component
    ActionIcon?: any;
    actionLabel?: string;
    actionDisabled?: boolean;
    title?: string;
}

export const ModalViewHeader = (props: ModalViewHeaderProps) => {
    const { segmentedControlActiveColor } = useColors()
    const { goBack, onAction, ActionIcon, actionLabel, title, actionDisabled } = props
    const showAction = useMemo(() => !!onAction && (!!actionLabel || ActionIcon), [ onAction, actionLabel, ActionIcon ])

    return (
        <Flex style={{ width: '100%', boxSizing: 'border-box', padding: '10px 0px' }}>
            <PaddedSection>
                <Flex style={{ width: '100%', boxSizing: 'border-box' }}>
                    <Flex
                        align='center'
                        justify='flex-start'
                        style={{ flex: 0.5 }}
                    >
                        {!!goBack && <Flex onClick={goBack} tabIndex={0}><ArrowBackIosRounded style={{ fontSize: 20 }} /> </Flex>}
                    </Flex>
                    <Flex center style={{ flex: 5, overflow: 'hidden' }}>
                        <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant='h4'>
                            { title || '' }
                        </Typography>
                    </Flex>
                    <Flex
                        align='center'
                        justify='flex-end'
                        style={{ flex: 0.5, opacity: actionDisabled ? 0.5 : 1.0 }}
                    >
                        { showAction && 
                            <Flex align='center' justify='flex-end' onClick={onAction}>
                                <Typography style={{ color: segmentedControlActiveColor }} variant='h6'>
                                    { !!actionLabel && actionLabel }
                                </Typography>
                                {!!ActionIcon && <ActionIcon style={{ marginLeft: 5, fontSize: 18, color: segmentedControlActiveColor }} />}
                            </Flex>
                        }
                    </Flex>
                </Flex>
            </PaddedSection>
        </Flex>
    )
}