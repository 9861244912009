import { QualifiedId } from 'phoenix/util/QualifiedId';

const monthLookup = 'F,G,H,J,K,M,N,Q,U,V,X,Z'.split(',');

// Ex: ZC FTS
const baseSpreadRegex = /^([A-Z0-9]+)\sFTS$/;
// Ex: ZC FTS +U23,-Z23
// Capture groups:
// Whole (to determine match)
// nearMonthDirection - Long or short the first leg? + or -
// nearMonth - monthly suffix for near month contract symbol, ie ZC FTS +U23,-Z23 => U23
// nearMonthLookup - Lookup for above month array, ie ZC FTS +U23,-Z23 => U (September)
// farMonth - monthly suffix for far month contract symbol, ie ZC FTS +U23,-Z23 => Z23
const concreteSpreadRegex = /^([A-Z0-9]+)\sFTS\s([+-])(([A-Z]{1})[0-9]{2}),[+-]([A-Z][0-9]{2})$/;

// Ex: ESU28
const symWithMonthAndYear = /[FGHJKMNQUVXZ]\d{2}$/;
export class FuturesSymbol {
    baseContract: string;
    baseContractNoPrefix: string;
    date: Date | null = null;
    isFuture: boolean;
    isTimeSpread?: boolean;
    month: number | undefined; // Month is base-zero (Jan = 0, Feb = 1, etc.)
    noPrefix: string;
    type: 'base' | 'concrete' | 'error';
    withPrefix: string;
    year: number | undefined;

    // Time Spreads
    // Calendar spreads are always different dates, always feature near (closer to now) and far (farther away) contracts
    // ex: F:ZC FTS +U23,-Z23 | farMonthContract = F:ZCZ23
    farMonthContract?: string;
    // ex: F:ZC FTS +U23,-Z23 | nearMonthContract = F:ZCU23
    nearMonthContract?: string;
    // Whether the trade is long or short the first leg. The 2nd leg will always be the inverse
    nearMonthDirection?: '+' | '-'; // + is long, - is short

    constructor(src = '') {
        this.isFuture = /^F:/.test(src);
        const nsym = src?.replace(/^F:/g, '') || '';

        // Futures Time Spreads - Base | Example: F:ZC FTS
        if (baseSpreadRegex.test(nsym)) {
            const [, base] = nsym.match(baseSpreadRegex) || [];
            this.isTimeSpread = true;
            this.baseContract = 'F:' + base;
            this.baseContractNoPrefix = base;
            this.noPrefix = nsym;
            this.withPrefix = `F:${nsym}`;
            this.type = 'base';
        }
        // Futures Time Spreads - Concrete | Example: F:ZC FTS +U23,-Z23
        else if (concreteSpreadRegex.test(nsym)) {
            const [, base, nearMonthDirection = '', nearMonth = '', nearMonthLookup = '', farMonth = ''] = nsym.match(concreteSpreadRegex) || [];
            this.baseContract = `F:${base}`;
            this.baseContractNoPrefix = base;
            this.farMonthContract = `F:${base}${farMonth}`;
            this.nearMonthContract = `F:${base}${nearMonth}`;
            this.nearMonthDirection = nearMonthDirection as '+' | '-';
            this.isTimeSpread = true;
            this.month = Number(nearMonthLookup && monthLookup.findIndex((x) => x === nearMonthLookup));
            this.noPrefix = nsym;
            this.withPrefix = `F:${nsym}`;
            this.type = 'concrete';
        } else if (/^.+[A-Z]\d{2}$/.test(nsym)) {
            const [, base, month, year] = nsym.match(/^(.*)([A-Z])(\d{2})$/) || [];
            this.noPrefix = nsym;
            this.withPrefix = `F:${nsym}`;
            this.baseContractNoPrefix = base;
            this.baseContract = 'F:' + base;
            this.month = monthLookup.findIndex((x) => x === month);
            this.year = parseInt(year) + 2000; // TODO -- Update in 100 years lol
            this.date = new Date(this.year, this.month, 1);
            this.type = 'concrete';
        } else {
            this.noPrefix = nsym;
            this.withPrefix = `F:${nsym}`;
            this.baseContract = 'F:' + nsym;
            this.baseContractNoPrefix = nsym;
            this.type = nsym.length < 20 ? 'base' : 'error';
        }
    }

    static IsFuturesSymbol = (src: string): boolean => /^F:/.test(src);

    static IsTimeSpreadSymbol = (src: string): boolean => baseSpreadRegex.test(QualifiedId.RemovePrefix(src)) || concreteSpreadRegex.test(QualifiedId.RemovePrefix(src));

    static FromRaw = (base: string, monthIndex: number, year: number): string => {
        // Now Symbols for Future contracts are coming with month and year attached from API response so added extra check.
        if (symWithMonthAndYear.test(base)) {
            return base;
        }
        return `${base}${monthLookup[monthIndex] || ''}${year?.toString?.()?.substr(2) || ''}`;
    };

    static ShareSameBaseContract = (a: string, b: string): boolean => {
        const prefixLength = 4;
        if ((a?.length || 0) < prefixLength || (b?.length || 0) < prefixLength) return false;
        const pref = (s: string) => s.substr(0, s.length - prefixLength);
        const apref = pref(a);
        const bpref = pref(a);
        return !!apref && !!bpref && apref === bpref;
    };
}
