import { Link, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Flex } from '..';
import { FormattedText, FormattedTextList } from 'phoenix/assets/lang/Snex1LanguagePack';
import './FormattedTextDisplay.scss';

const _FormattedTextDisplay = React.memo(
    ({ text, style, append }: { text: FormattedText | FormattedTextList; style?: React.CSSProperties; append?: React.ReactNode }) => {
        const src = !Array.isArray(text) ? [[text as FormattedText]] : (text as FormattedTextList);

        return (
            <Flex column>
                {src.map((paragraph, i) => (
                    <div className={style ? undefined : 'formatted-text-paragraph'} key={i}>
                        {paragraph.map((phrase, idx) => (
                            <span key={idx}>
                                <FormattedTextPhrase key={phrase?.text} t={phrase} style={style} />
                                {idx === paragraph.length && append ? append : null}
                            </span>
                        ))}
                    </div>
                ))}
            </Flex>
        );
    }
);

const FormattedTextPhrase = React.memo(({ t, style }: { t: FormattedText; style?: React.CSSProperties }) => {
    const classes = useMemo(
        () =>
            classNames(
                style
                    ? undefined
                    : {
                          'formatted-text': true,
                          'formatted-text-title': t?.size === 'title',
                          'formatted-text-large': t?.size === 'large',
                          'formatted-text-small': t?.size === 'small',
                          'formatted-text-bold': t?.weight === 'bold'
                      }
            ),
        [t]
    );

    if (!t?.text) return null;

    if (t.url)
        return (
            <Link href={t.url} style={{ ...style, color: t.color }} className={classes} underline='hover'>
                {t.text}
            </Link>
        );

    return (
        <Typography style={{ ...style, color: t.color }} className={classes}>
            {t.text}
        </Typography>
    );
});

export const FormattedTextDisplay = ({ text }: { text: FormattedText | FormattedTextList }) => <_FormattedTextDisplay text={text} />;

export const StyledTextDisplay = ({ text, style, append }: { text: FormattedText | FormattedTextList; style: React.CSSProperties; append?: React.ReactNode }) => (
    <_FormattedTextDisplay text={text} style={style} append={append} />
);
