// @ts-strict-ignore
import React, { ReactChild, useState, ReactNode } from 'react';
import { Typography, Collapse } from '@mui/material';
import { ExpandMoreRounded, ExpandLessRounded, MoreHoriz } from '@mui/icons-material';
import { Flex, AnchoredMenu, Spinny } from '..';
import { IAnchoredMenuItem } from '../AnchoredMenu';
import { StyledIcon } from '../StyledIcon';
import style from './style.module.scss';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { isClass } from 'highcharts';
import { Routes } from 'util/Routes';
import { useColors } from 'hooks/UseColors';
import { Link } from 'react-router-dom';

interface SubListProps {
    id?: string;
    title: string;
    fullHover?: boolean;
    isCollapsed?: boolean;
    onCollapse?: (newValue: boolean) => any;
    moreMenuItems?: IAnchoredMenuItem[];
    children?: ReactChild;
    emptyList?: boolean;
    isWatchListItem?: boolean;
}

const SubListTitle = (props: {title: string, style?: React.CSSProperties}) => {
    const { title, style } = props;
    return (
        <Typography style={{ color: 'rgb(182, 182, 182)', marginRight: 20, fontWeight: 500, ...style }} variant='h5'>
            {title}
        </Typography>
    )
}

export const SubList = (props: SubListProps) => {
    const [isCollapsed, setCollapsed] = useLocalStorage(`sublist-collapsed-${props.id || props.title}`, props.isCollapsed || false);
    const [isHeaderFocused, setIsHeaderFocused] = useState(false);
    const [isHeaderHovered, setIsHeaderHovered] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const colors = useColors();

    const { title, children, moreMenuItems, emptyList = false, isWatchListItem = true } = props;

    const handleCollapseToggle = () => {
        setCollapsed(!isCollapsed);
        if (props.onCollapse) props.onCollapse(!isCollapsed);
    };

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const shouldRenderMoreMenuIcon = moreMenuItems?.length > 0;
    const shouldDisplayMoreMenuIcon = isHeaderFocused || isHeaderHovered || anchorEl;

    return (
        <div className={style.wrapper}>
            <Flex row wrap={false} style={{ flex: 1 }} align='center' className={style.header} justify='space-between'>
                { isWatchListItem
                    ? <Link
                        to={Routes.watchlists(props.id)}
                        className={`${!props.fullHover && style.hoverable}`}
                        onFocus={() => setIsHeaderFocused(true)}
                        onBlur={() => setIsHeaderFocused(false)}
                        onMouseEnter={() => setIsHeaderHovered(true)}
                        onMouseLeave={() => setIsHeaderHovered(false)}
                    >
                        <SubListTitle title={title}/>
                    </Link>
                    : <SubListTitle title={title} style={{ padding: '5px 18px' }} />
                }
                <Flex align='center' justify='flex-end'>
                    {shouldRenderMoreMenuIcon && (
                        <>
                            <button
                                onFocus={() => setIsHeaderFocused(true)}
                                onBlur={() => setIsHeaderFocused(false)}
                                onMouseEnter={() => setIsHeaderHovered(true)}
                                onMouseLeave={() => setIsHeaderHovered(false)}
                                onClick={handleMenuOpen}
                                type='button'
                            >
                                <StyledIcon IconComponent={MoreHoriz} style={{ fill: shouldDisplayMoreMenuIcon ? colors.fadedTextColor : 'none' }} />
                            </button>
                            <AnchoredMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} items={moreMenuItems} />
                        </>
                    )}
                    {!emptyList && (
                        <button onClick={handleCollapseToggle}>
                            <Spinny spun={isCollapsed} />
                        </button>
                    )}
                </Flex>
            </Flex>
            {props.children && (
                <CulledCollapse eventTag={`${title} Sublist`} in={!isCollapsed && !emptyList}>
                    <div style={{ marginBottom: 10 }}>{children}</div>
                </CulledCollapse>
            )}
        </div>
    );
};
