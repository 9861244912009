// @ts-strict-ignore
import React from "react"
import { setSelectedScreenId, useFullyPaidLendingStore } from "../../Store"
import { T } from "phoenix/assets/lang/T"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "phoenix/onboarding/OnboardingTypes"
import { getFPLDisclosuresErrors } from "./Validation"
import { FPL_disclosures_Form } from "./Form"

export const fpl_disclosures_step: OnboardingStep = {
    label: "Disclosures",
    isComplete: () => getFPLDisclosuresErrors().length < 1,
    isEnabled: () =>  !!useFullyPaidLendingStore?.getState()?.data?.selectedAccount,
    isCurrent: () => ['fplDisclosures'].includes(useFullyPaidLendingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'fplDisclosures'
}

export const fpl_disclosures_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => setSelectedScreenId('fplAgreements'),
    isPrimary: true,
    isEnabled: () => getFPLDisclosuresErrors()?.length < 1
}

export const fpl_disclosures_prevButton: OnboardingButton = {
    label: "Previous",
    onClick: () => setSelectedScreenId('fplInfo'),
    isPrimary: false,
    isEnabled: () => true
}

export const fpl_disclosures_screen: OnboardingScreen = {
    id: "fplDisclosures",
    validate: getFPLDisclosuresErrors,
    buttons: [fpl_disclosures_prevButton, fpl_disclosures_nextButton],
    title: T(t => t.fplOnboarding.disclosures.title),
    subtitles: [T(t => t.fplOnboarding.disclosures.requiredClick)],
    body: <FPL_disclosures_Form />
}