// @ts-strict-ignore
import { Skeleton, Typography } from '@mui/material';
import { CulledCollapse } from 'components/CulledCollapse/CulledCollapse';
import { Flex } from 'components/Flex';
import { ListHeader } from 'components/ListHeader';
import { useColors } from 'hooks/UseColors';
import { useOneProNetworkStore } from 'phoenix/stores/OneProNetworkStore/OneProNetworkStore';
import { Post } from 'phoenix/stores/OneProNetworkStore/OneProNetworkTypes';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';
import React, { useEffect, useMemo, useState } from 'react';
import { T } from 'phoenix/assets/lang/T';
import { ExpandButton } from 'components/ExpandButton';
import { XS } from 'phoenix/xstream/XS';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { useAppDispatch } from 'AppRoot';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { useInView } from 'react-intersection-observer';
import { ChangeColor, FormatNumber } from 'phoenix/util';
import { VideoPlayerHelpers, useModalVideoPlayerStore } from '../videoPlayer/videoPlayerHelpers';
import { WindowSizes, useAppWindowSize } from 'hooks/UseWindowSize';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { GetAsOf } from '../deskAlerts/deskAlerts';
import { LongFormIcon } from 'components/OneProNetwork/assets/longFormIcon';
import { PlayIcon } from 'components/OneProNetwork/assets/playIcon';
import style from './style.module.scss';
import { Urls } from 'phoenix/constants';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { NewsContent } from 'phoenix/redux/models/News/NewsContent';


export const PostsSection = ({
    isLoading,
    posts,
    title,
    isShown,
    isSidebar
}: {
    isLoading?: boolean;
    posts?: Post[];
    title?: string;
    isShown?: boolean;
    isSidebar?: boolean;
}): JSX.Element => {
    const [showMore, setShowMore] = useState<boolean>(true);
    const [appWindowSize] = useAppWindowSize();
    const colors = useColors();
    const postsToShow = useMemo(() => (isSidebar ? 1 : 6), [isSidebar]);
    const maxWidth = isSidebar ? '100%' : appWindowSize === WindowSizes.large ? '33.33%' : '50%';
    const skeletons = useMemo(() => {
        const arr = [];
        for (let i = 0; i < postsToShow; i++) {
            arr.push(<SkeletonPostCard key={`skeleton-${i}`} maxWidth={maxWidth} />);
        }
        return arr;
    }, [postsToShow, maxWidth]);

    if (!isShown) return null;

    return (
        <Flex column fullWidth style={{ margin: '16px 0' }}>
            <ListHeader title={title} />
            <Flex row={!isSidebar} column={isSidebar} fullWidth wrap style={{ marginTop: 8, boxSizing: 'border-box', rowGap: 8 }}>
                {isLoading && isSidebar ? (
                    <>{skeletons}</>
                ) : (
                    <>
                        {posts
                            ?.filter((p, pidx) => pidx < postsToShow)
                            .map((p, pidx) => (
                                <GetPostCard key={`post-${pidx}`} maxWidth={maxWidth} {...{ post: p, colors }} />
                            ))}
                        <CulledCollapse eventTag={`One Pro Network Summary`} in={!showMore}>
                            {posts
                                ?.filter((p, pidx) => pidx >= postsToShow && p.type === 'basic-post')
                                .map((p, pidx) => (
                                    <GetPostCard maxWidth={maxWidth} key={`post-${pidx}`} {...{ post: p, colors }} />
                                ))}
                        </CulledCollapse>
                    </>
                )}
            </Flex>
            {posts?.length > postsToShow && (
                <ExpandButton
                    expanded={!showMore}
                    expandedLabel={T((s) => s.misc.showFewer)}
                    overcount={posts.length - postsToShow}
                    overhang={-8}
                    symbols={null}
                    onClick={() => setShowMore(!showMore)}
                />
            )}
        </Flex>
    );
};

export const LatestPosts = ({ isShown, isSidebar }: { isShown?: boolean; isSidebar?: boolean }): JSX.Element => {
    const { data, mainScreen } = useOneProNetworkStore();
    const title = 'Latest Posts';

    return <PostsSection isLoading={mainScreen.loading || mainScreen.pristine} posts={data?.featuredPosts} {...{ title, isShown, isSidebar }} />;
};

export const PopularPosts = ({ isShown, isSidebar }: { isShown?: boolean; isSidebar?: boolean }): JSX.Element => {
    const { data, mainScreen } = useOneProNetworkStore();
    const title = 'Most Popular';

    return <PostsSection isLoading={mainScreen.loading || mainScreen.pristine} posts={data?.popularPosts} {...{ title, isShown, isSidebar }} />;
};

export const SkeletonPostCard = ({ maxWidth }: { maxWidth?: string | number }): JSX.Element => {
    return (
        <div className={style['skeleton-post-card-container']} style={{ width: maxWidth }}>
            <div className={style['skeleton-post-card-shaded-overlay']} />
            <Flex column fullWidth justify={'space-between'} className={style['skeleton-post-card-content']}>
                <Flex row justify={'space-between'}>
                    <div style={{ fontSize: 12 }}>
                        <Skeleton width={90} />
                    </div>
                </Flex>
                <Flex column fullWidth>
                    <div style={{ fontSize: 16, fontWeight: 600, lineHeight: '18px' }}>
                        <Skeleton width={'100%'} />
                    </div>
                    <Flex row style={{ gap: 6, marginTop: 4 }}>
                        {' '}
                        <Skeleton width={'100%'} />
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
};

export const renderHtml = (rawHtml: string) => {
    const secondaryWindow = window.open('', '_blank', 'width=800,height=600');
    secondaryWindow.document.open();
    secondaryWindow.document.write(rawHtml)
}

export const GetPostCard = ({ post, colors, maxWidth }: { post: Post; colors: AppColorTheme; maxWidth?: string | number }): JSX.Element => {
    const helpers = VideoPlayerHelpers();
    const { actions } = useModalVideoPlayerStore();
    const [appWindowSize] = useAppWindowSize();

    

    const handleClick = async (e: { stopPropagation: () => void }) => {
         e.stopPropagation();
        if (post?.media?.type === 'video') {
            // open in modal
            helpers.load(post?.media?.content[0]);
            actions.setData({
                post: {
                    title: post?.title,
                    url: post?.media?.content[0],
                    thumbnail: post?.thumbnailUri
                },
                author: {
                    title: post?.author?.title,
                    name: post?.author?.name,
                    imageUri: post?.author?.imageUri
                }
            });
            actions.setMuted(false);
            actions.setVisible(true);
        } 
        else if (post?.media?.type === 'html') {
           const c = await SnexAxios.ApiGet<NewsContent>(Urls.news.articleContent(post?.media?.content[0])).run()
           if (c.contentType === 'Url') await window.open(c.content, '_blank')
           else renderHtml(c.content)
        } else {
            // open in new window
            window.open(post?.media?.content[0], '_blank');
        }
    };
    return (
        <div key={post.id} className={style['post-card-container']} style={{ width: maxWidth, backgroundImage: `url('${post.thumbnailUri}')` }} >
            <div className={style['post-shaded-overlay']} />
            <Flex column fullWidth justify={'space-between'} className={style['post-card-content']} >
                <Flex row justify={'space-between'}>
                    {post?.author?.name && (
                        <Flex row fullWidth align={'center'} style={{ fontSize: 12, gap: 8 }}>
                            <div>{post?.author?.name}</div>
                            <div>·</div>
                            <div style={{ opacity: 0.75 }}>{GetAsOf(post?.created)}</div>
                        </Flex>
                    )}
                    <div>{post.media?.type === 'video' ? <PlayIcon width={16} /> : <LongFormIcon width={16} />}</div>
                </Flex>
                <Flex column fullWidth>
                    <div style={{ fontSize: appWindowSize === WindowSizes.large ? 14 : 12, fontWeight: 600, lineHeight: '18px' }}>{post.title}</div>
                    <Flex row style={{ gap: 6, marginTop: 4 }}>
                        {' '}
                        {post?.relatedSecurities?.slice(0, 4).map((sym, idx) => (
                            <QuotePercent key={`${post.id}_related_${idx}`} symbol={sym.qsi} />
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
};

const QuotePercent = ({ symbol}: { symbol: string}) => {
    const quote = XS.Quotes.use(symbol);
    const xdispatch = useXstreamDispatch();
    const { ref, inView } = useInView({ trackVisibility: true, threshold: 0, delay: 400 });
    const [subscription, setSubscription] = useState<string>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [symbol, inView]);

    useEffect(() => {
        const run = async () => {
            if (!inView) {
                XS.stop(subscription);
                setSubscription(null);
            } else {
                await dispatch(GetSecurityQuoteAction(symbol));

                if (!subscription) {
                    const ticket: string = await xdispatch(XS.Quotes.start(symbol, 'OneProNetworkQuoteTicker'));
                    setSubscription(ticket);
                }
            }
        };

        run();
        return () => {
            XS.stop(subscription);
        };
    }, [inView]);

    return (
        <Flex ref={ref} row style={{ gap: 4 }}>
            <Link to={Routes.security(symbol)} style={{ overflow: 'hidden', width: '100%' }}>
                <div style={{ fontSize: 12 }}>{symbol}</div>
            </Link>
            {quote?.changePercent ? (
                <Flex row style={{ fontSize: 12 }}>
                    <Typography style={{ paddingLeft: '5px', color: ChangeColor(quote.changePercent), fontSize: 12 }} variant='body1'>
                        {FormatNumber.toPercent(quote.changePercent, 'never', 2)}
                    </Typography>
                </Flex>
            ) : (
                <Skeleton width={60} />
            )}
        </Flex>
    );
};
