// @ts-strict-ignore
import React from 'react';
import { TradeReceiptPage } from '../Shared/TradeReceiptPage';
import { TradeReviewPage } from '../Shared/TradeReviewPage';
import { EquityTradeInputPage } from './EquityTradeInputPage';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { EquityTradeTicketViewModel } from '../Store/TradeTicketViewModel';

export const EquityTradeTicket = React.memo(() => {
    const viewModel = useTradeTicketViewModel<EquityTradeTicketViewModel>();
    const { state } = viewModel;
    switch (state) {
        case 'input':
            return <EquityTradeInputPage />;
        case 'review':
            return <TradeReviewPage />;
        case 'done':
            return <TradeReceiptPage />;
    }
});
