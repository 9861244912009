// @ts-strict-ignore
import { DynamicModalSize } from 'components/Modals/DynamicModal';
import { EarningsCallTranscript } from '../Securities/EarningsCallTranscript';

export class MiniplayerState {
    // Idea: maybe we could have a playlist feature?
    open: boolean;
    id: any;
    mediaTitle: string;
    mediaSubtitle?: string;
    mediaDate?: Date;
    mediaTranscript?: EarningsCallTranscript;
    mediaSymbol?: string;
    mediaUrl: string;
    autoplay: boolean;
    loading: boolean;

    size: DynamicModalSize;
    playing: boolean;
    muted: boolean;

    constructor() {
        this.id = null;
        this.open = false;
        this.mediaUrl = 'https://youtu.be/GIrXA-Gfb-I';
        this.mediaTitle = null;
        this.playing = false;
        this.muted = false;
        this.size = 'mini' as DynamicModalSize;
    }
}
