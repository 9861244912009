// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../../models'
import { GroupBySelect, GroupBySelectMap } from '../../../util'
import { GlobalState } from '../../GlobalState'
import { BrowseScreenBundle } from '../../models/MobileScreens/BrowseScreenBundle'

export const BrowseScreenReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as BrowseScreenBundle
    const asData = <T>(data: T) => new ApiData<T>().succeeded(data)
    const out = { ...state }

    out.logos.bySymbol = {
        ...out.logos.bySymbol,
        ...(GroupBySelectMap(bundle.logos, l => l.symbol, l => asData({ symbol: l[0].symbol, url: l[0].logo })))
    }

    out.securityQuote.bySymbol = {
        ...out.securityQuote.bySymbol,
        ...(GroupBySelectMap(bundle.quotes, l => l.symbol, l => asData(l[0])))
    }

    out.securities.ipos = asData(bundle.ipos)

    out.securities.sectors.performance = asData(bundle.sectors)
    out.securities.top.equities = {
        movers: asData(bundle.topMovers),
        gainers: asData(bundle.topGainers),
        losers: asData(bundle.topLosers)
    }

    out.market.upcoming.earningsCalls = asData(bundle.upcomingEarnings)
    out.news.marketNews = asData(bundle.marketNews)
    out.mobileScreenLoading.browseScreen = out.mobileScreenLoading.browseScreen.succeeded()

    return out
}
