// @ts-strict-ignore
import { SnexApiResponse } from 'phoenix/redux/models/SnexApiResponse'
import { ReduxAction } from '../../models'
import { Actions } from '../actions/Constants'
import { Transaction } from '../models/Transactions/Transaction'
import { TransactionsState } from '../models/Transactions/TransactionsState'
import { Page } from '../models/Paging/Page'

export const TransactionsReducer = (state: TransactionsState = new TransactionsState(), action: ReduxAction): TransactionsState => {
    switch (action.type) {

        case Actions.Transactions.SearchResults.Loading: return { ...state, searchResults: state.searchResults.startLoading(action.passthrough.append ? state.searchResults.data : []) }
        case Actions.Transactions.SearchResults.Success: {
            const newData: SnexApiResponse<Transaction[]> = action.passthrough.append
                ? { data: [...(state?.searchResults?.data || []), ...action.data], errors: action.error }
                : { data: action.data, errors: action.error }
            return { ...state, searchResults: state.searchResults.succeeded(newData.data, newData.errors) }
        }
        case Actions.Transactions.SearchResults.Failure: return { ...state, searchResults: state.searchResults.failed(action.error) }

        case Actions.Transactions.SearchResultsV2.Loading:
            return {
                ...state,
                searchResults: state.searchResults.startLoading(action.passthrough.append ? state.searchResults.data : []),
                nextOffsets: state.nextOffsets.startLoading(state.nextOffsets.data)
            };
            
        case Actions.Transactions.SearchResultsV2.Success: {
            const previousCount = state.searchResults?.data?.length;
            const newResponse = action.data as Page<Transaction>;
            const newTransactionData = action.passthrough.append ? [...(state.searchResults.data || []), ...newResponse?.items] : newResponse?.items;
    
            return {
                ...state,
                noMoreResults: previousCount === newTransactionData.length,
                searchResults: state.searchResults.succeeded(newTransactionData),
                nextOffsets: state.nextOffsets.succeeded(newResponse.nextOffsets)
            };
        }
        case Actions.Transactions.SearchResultsV2.Failure:
            return {
                ...state,
                noMoreResults: true,
                searchResults: state.searchResults.failed(action.error),
                nextOffsets: state.nextOffsets.failed(action.error)
            };

        default: return state
    }
}
