import { AlertConditionType as AlertCondition, FormatAlertCondition } from 'phoenix/constants/Alerts';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useText } from 'phoenix/hooks/UseText';
import { CreateAlertRuleAction } from 'phoenix/redux/actions/AlertsActions';
import { AlertRule } from 'phoenix/redux/models';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex, MainSearchBox } from '../..';
import { SecurityCell } from '../../SecurityCell';
import { ModalViewHeader, PaddedSection } from '../Shared';
import { CreateConditionForm } from './CreateConditionForm';
import { IAlertViewProps } from './Shared/IAlertViewProps';

export interface CreateAlertViewProps extends IAlertViewProps {
    alert?: Partial<AlertRule>;
    symbol?: string;
}

export const CreateAlertView = React.memo((props: CreateAlertViewProps) => {
    const { navigate } = props;
    const dispatch = useDispatch();
    const [symbol, setSymbol] = useState(props.symbol);
    const text = useText((t) => t);

    const [repeatDaily, setRepeatDaily] = useState(false);
    const repeatDailyRef = useRef(false);
    const handleSaveAlert = useCallback(
        async (newCondition: AlertCondition) => {
            dispatch(
                CreateAlertRuleAction({
                    symbol,
                    name: `${symbol} ${text.alerts.priceAlert}`,
                    conditions: [FormatAlertCondition.toApiFriendly(newCondition)],
                    repeatDaily: repeatDailyRef.current
                })
            );
            navigate('list');
        },
        [dispatch, navigate, symbol, text.alerts.priceAlert]
    );

    const handleGoBack = useCallback(() => props.navigate('list'), []);
    const handleAddCondition = useCallback((newCondition: AlertCondition) => handleSaveAlert(newCondition), [handleSaveAlert]);

    useEffect(() => {
        repeatDailyRef.current = repeatDaily;
    }, [repeatDaily]);

    return (
        <Flex id={'create-alerts-section'} column style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <ModalViewHeader goBack={handleGoBack} title={text.alerts.createAlert} />
            <Flex column style={{ width: '100%', flex: 1, overflow: 'auto', paddingTop: 20 }}>
                <PaddedSection>
                    {symbol ? (
                        <SecurityCell
                            onDelete={() => setSymbol('')}
                            streamingNamespace={LiveDataNamespaces.SecurityCellAlert}
                            symbol={symbol}
                            variant='search-result-removable'
                            visible
                        />
                    ) : (
                        <MainSearchBox
                            fullWidth
                            hideLogo
                            hideMetadata
                            translations={{ placeholder: text.misc.search.placeholder }}
                            onResultClick={(symbol: string) => {
                                setSymbol(symbol);
                            }}
                        />
                    )}
                </PaddedSection>
                <CreateConditionForm symbol={symbol} onAdd={handleAddCondition} onRepeatDailyChange={setRepeatDaily} />
            </Flex>
        </Flex>
    );
});
