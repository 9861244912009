import { Flex } from 'components';
import { UserAvatarPlusTimeBadge } from 'components/UserAvatarPlusTimeBadge/UserAvatarPlusTimeBadge';
import React, { ReactElement, useEffect } from 'react';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';

export const ZanderDebugScreen = (): ReactElement => {
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Set Base Layout Props
    useEffect(() => {
        setBaseLayoutProps({ hideGlance: true });
    }, [setBaseLayoutProps]);

    return (
        <Flex fullWidth justify='center' style={{ gap: 28, height: '100%' }}>
            <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                <UserAvatarPlusTimeBadge />
            </Flex>
            <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                <UserAvatarPlusTimeBadge orientation='right' />
            </Flex>
            <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                <UserAvatarPlusTimeBadge orientation='top' />
            </Flex>
            <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                <UserAvatarPlusTimeBadge orientation='bottom' />
            </Flex>
        </Flex>
    );
};
