
export type ThemeVariant = 'premium' | 'dark' | 'light' | 'device' | 'market' | 'default'

export const ThemeVariants = {
    premium: 'premium' as ThemeVariant,
    dark: 'dark' as ThemeVariant,
    light: 'light' as ThemeVariant,
    device: 'device' as ThemeVariant,
    default: 'default' as ThemeVariant,
    market: 'market' as ThemeVariant
}
