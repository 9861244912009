import { ReduxAction } from '../../../models'
import { GlobalState } from '../../GlobalState'
import { LandingScreenBundle } from '../../models/MobileScreens/LandingScreenBundle'
import { MobileStateUpdates } from './MobileStateUpdates'

export const LandingScreenReducer = (state: GlobalState, action: ReduxAction): GlobalState => {
    const bundle = action.data as LandingScreenBundle
    MobileStateUpdates.addLogos(state, bundle.logos)
    MobileStateUpdates.addMetadata(state, bundle.metadata)
    MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts)

    return state
}
