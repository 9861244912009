// @ts-strict-ignore
import { Skeleton, Typography } from '@mui/material';
import { Card } from 'components/Card';
import { CircularLogo } from 'components/CircularLogo';
import { Flex } from 'components/Flex';
import { useColors } from 'hooks/UseColors';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { GetSecurityQuoteAction, PublishSubscriptionWhitelist } from 'phoenix/redux/actions';
import { ChangeColor, FormatNumber } from 'phoenix/util';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import './index.css';


const QuoteTicker = ({contentRef, count, children}: {contentRef: React.MutableRefObject<HTMLDivElement>, count?: number, children: JSX.Element}) => {
    
    const containerRef = useRef(null)

    // determine how wide the obj is
    const contentW = contentRef?.current?.clientWidth
    // determine how wide the viewport is
    const containerW = containerRef?.current?.clientWidth

    return ( 
    contentW <= containerW?
    <Flex row ref={containerRef} fullWidth style={{ overflow: 'hidden', position: 'relative'}}>
        {children}
    </Flex>:
    <Flex row ref={containerRef} fullWidth style={{ overflow: 'hidden', position: 'relative'}}>
        <div className={`quote-container`} style={{animation:`slide-left ${count * 10}s linear infinite`}}>
            {children}
        </div>
        <div className={`quote-container`} style={{animation: `slide-left2 ${count * 10}s linear infinite`}}>
            {children}
        </div>
    </Flex>
    )

}

export const QuoteCards = ({ symbols, id, scroll }: { id: string; symbols?: string[]; scroll?: boolean }): JSX.Element => {

    const contentRef = useRef(null)
    const QuoteContent = () => {
        return (
            <Flex ref={contentRef} id={id} row align={'center'} style={{ gap: 8, height: 52, minHeight: 52, marginRight: 8 }}>
                {symbols?.map((s, idx) => (
                    <QuoteCard key={`quote_${s}_${idx}`} symbol={s} />
                ))}
            </Flex>
        )
    }

    return (
        <QuoteTicker contentRef={contentRef} count={symbols?.length || 1}> 
            <QuoteContent />
        </QuoteTicker>
    )
};

export const QuoteCard = ({ symbol }: { symbol?: string }): JSX.Element => {
    const colors = useColors();
    const dispatch = useDispatch();
    const quote = XS.Quotes.use(symbol);
    const xdispatch = useXstreamDispatch();

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [dispatch, symbol]);

    useEffect(() => {
        xdispatch(XS.Quotes.start(symbol, LiveDataNamespaces.OneProNetworkTicker));
        PublishSubscriptionWhitelist([LiveDataNamespaces.OneProNetworkTicker]);
        return () => {
            XS.stopNs(LiveDataNamespaces.OneProNetworkTicker);
        };
    }, [symbol, xdispatch]);

    return (
        <Card className='quote-card'>
            <Link to={Routes.security(symbol)} style={{ borderRadius: 17 }}>
                <Flex row fullWidth align={'center'} style={{ overflow: 'visible', flex: '1 1 auto', gap: 8, borderRadius: 17 }}>
                    <CircularLogo symbol={symbol} size={24} />
                    <Flex row align={'center'} style={{ gap: 16 }}>
                        {symbol ? <Typography variant={'body1'}>{symbol}</Typography> : <Skeleton width={32} height={52} />}
                        {quote?.changePercent ? (
                            <Flex column style={{ fontSize: 12 }}>
                                <Typography style={{ paddingLeft: '5px', fontSize: 12 }}>{FormatNumber.toMoneyOpt2(quote.change)}</Typography>
                                <Typography style={{ paddingLeft: '5px', color: ChangeColor(quote.change), fontSize: 12 }}>
                                    {FormatNumber.toPercent(quote.changePercent, 'never', 2)}
                                </Typography>
                            </Flex>
                        ) : (
                            <Flex column style={{ fontSize: 12 }}>
                                <Skeleton width={42} height={16} />
                                <Skeleton width={42} height={16} />
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Link>
        </Card>
    );
};
