import { Typography } from '@mui/material';
import React from 'react';
import { TradeTicketSection } from '../Shared/TradeTicketSection';

export const TradeMessageHeader = React.memo((props: { title: string; subtitle?: string }) => {
    return (
        <TradeTicketSection
            style={{ padding: '6px 20px', minHeight: 65, display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
            <Typography style={{ fontSize: 20, marginRight: 18, marginBottom: 5, marginTop: 5 }} variant='h5'>
                {props.title}
            </Typography>
            {props.subtitle && <Typography>{'Account #: ' + props.subtitle}</Typography>}
        </TradeTicketSection>
    );
});
