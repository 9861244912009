// @ts-strict-ignore
import { ApiData } from '../../../models'
import { PaginationOffsets } from '../Paging/Page';
import { Transaction } from './Transaction'

export class TransactionsState {
    searchResults: ApiData<Transaction[]>
    nextOffsets: ApiData<PaginationOffsets>;
    noMoreResults: boolean;
    constructor () {
        this.searchResults = new ApiData([])
        this.nextOffsets = new ApiData(null)
        this.noMoreResults = false
    }
}

