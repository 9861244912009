import { getUnixTime } from 'date-fns';
import { produce } from 'immer';
import { isUTCTimestamp, UTCTimestamp } from 'lightweight-charts';
import _ from 'lodash';
import { ChartRange } from 'phoenix/constants';
import { ApiData } from 'phoenix/models';
import { SecurityChartData, SecurityQuote } from 'phoenix/redux/models';
import { GetNewYorkTimeChunks, IsMarketOpen, QuoteAttribute, UniqueBy } from 'phoenix/util';
import { SeriesDataPoint, SupportedSeriesType } from '../Chart/SeriesConfig';
import { SeriesList } from './SecurityChartWrapper';

export type RangeArg = ChartRange | 'loRes';
export type DataArg = {
    [symbol: string]: {
        [range: string]: ApiData<SecurityChartData[]>;
    };
};
export type SymbolsArg = string[];
export type QuotesArg = {
    [symbol: string]: SecurityQuote;
};

/**
 * Makes changes to the seriesList based on business logic.
 * It does not mutate any arguments.
 * Idempotent; if no modifications apply, it returns the same seriesList object.
 *
 * @param range
 * @param data
 * @param symbols
 * @param seriesList
 * @param quotes
 * @param showOpenLine
 * @returns a clone (if necessary) of seriesList
 */
export const manipulateData = (
    seriesList: SeriesList,
    range: RangeArg,
    data: DataArg,
    symbols: SymbolsArg,
    quotes: QuotesArg,
    showOpenLine: boolean,
    seriesType: SupportedSeriesType,
    extraOptions?: {
        lineColor?: string;
        lineColorLighter?: string;
    }
): SeriesList =>
    produce(seriesList, (draft) => {
        // compare symbols
        const oldSymbols = _.keys(draft);
        const symbolsToAdd = _.difference(symbols, oldSymbols);
        const symbolsToRemove = _.difference(oldSymbols, symbols);
        const symbolsToKeep = _.intersection(oldSymbols, symbols);

        // remove symbols from seriesList
        if (symbolsToRemove.length > 0) {
            _.each(symbolsToRemove, (s) => delete draft[s]);
        }
        // add symbols to seriesList
        if (symbolsToAdd.length > 0) {
            const newSeries = symbolsToAdd.map((s) => ({
                seriesId: s,
                data: [],
                seriesType,
                extraOptions: { showOpenLine }
            }));
            _.merge(draft, _.zipObject(symbolsToAdd, newSeries));
        }

        _.each(symbolsToKeep, (s) => {
            const dataHere = data[s]?.[range]?.data;
            const hasData = dataHere?.length;
            // if the symbol was already in the seriesList,
            // but we don't have any data from redux,
            // clear out the data in the seriesList.
            // unsure if this is needed.
            if (seriesList[s] && !hasData) {
                draft[s].data = [];
            }
        });

        _.each(symbols, (s, idx) => {
            const dataHere = data[s]?.[range]?.data || [];
            if (dataHere?.length) {
                const orderedData = [...dataHere]
                    .sort((a, b) => Date.parse(QuoteAttribute.getTime(b)) - Date.parse(QuoteAttribute.getTime(a)))
                    .filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)) && QuoteAttribute.getPrice(d));
                if (orderedData.length) {
                    let newChartData = orderedData.map<SeriesDataPoint>((d) => ({
                        ...d,
                        time: getUnixTime(new Date(QuoteAttribute.getTime(d))) as UTCTimestamp,
                        value: QuoteAttribute.getPrice(d) ?? undefined
                    }));

                    // if range is 1d and market is open, just show the day *so far*
                    if (range === '1d' && IsMarketOpen(newChartData[0].time as number, true)) {
                        newChartData = newChartData.filter((d) => IsMarketOpen(d.time as number, true));
                    }
                    const uniqueChartData = UniqueBy(newChartData, (item) => item.time as number);
                    if (uniqueChartData?.length) newChartData = uniqueChartData;

                    // Want to move away from changing chart values because this can be dangerous... = Roman C.
                    const lastData = newChartData[newChartData.length - 1];
                    const currentPrice = quotes[s]?.price;
                    if (newChartData?.length && lastData && currentPrice) {
                        lastData.value = currentPrice;
                    }

                    // Copying some questionable mapping logic from SecurityChartSection so mini-chart data matches big Sec chart data
                    // TODO We should really find out why we are doing this and possibly stop doing it
                    if (range === 'loRes' && newChartData?.length) {
                        const apiQuote = quotes?.[s];
                        if (newChartData?.[0] && apiQuote?.previousClose) {
                            newChartData[0].value = apiQuote?.previousClose;
                        }
                    }

                    if (seriesList[s]) {
                        draft[s] = seriesList[s];
                        draft[s].data = newChartData || [];
                        draft[s].seriesType = seriesType;
                    } else {
                        draft[s] = {
                            seriesId: s,
                            data: newChartData || [],
                            seriesType,
                            extraOptions: {
                                ...extraOptions,
                                showOpenLine: idx === 0 && showOpenLine
                            }
                        };
                    }
                }
            }
        });
    });

export const getVisibleLogicalRangeOffset = (dataLength: number | undefined): number | undefined => {
    if (!dataLength) return undefined;
    // Divide the current number of data points by the % of the session that has expired
    // To calculate an expected number of data points for the chart by the time the session is over
    const { hour, minute } = GetNewYorkTimeChunks();
    const hoursSinceOpen = hour - 4; // hours since 4am EST
    const totalMinutesSinceOpen = hoursSinceOpen * 60 + minute;
    const percentOfDayIsOver = totalMinutesSinceOpen / 780; // 4am - 5pm EST = 13 hrs, 13 * 60 minutes = 780 total potential data points
    const expectedNumberOfPoints = dataLength / percentOfDayIsOver;

    return expectedNumberOfPoints;
};
