import { getSimulatedEnvironment } from 'phoenix/constants/Support';
import { Environment } from 'phoenix/constants/Urls';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { getLastResortEmail } from 'screens/DeniedScreen/UseLastResortEmail';

// if the salesforce script has properly loaded and run, then `window.embedded_svc` will be defined.
// if `embedded_svc` is defined, then its properties, including `settings` and `init`, will also be defined.

declare global {
    interface Window {
        embedded_svc?: SalesforceEmbeddedService;
    }
}

interface SalesforceEmbeddedService {
    settings: Partial<{
        autoOpenPostChat: boolean;
        entryFeature: string;
        enabledFeatures: string[];
        language: string;
        displayHelpButton: boolean;
        chatbotAvatarImgURL: string;
        extraPrechatFormDetails: Partial<ExtraPrechatFormDetail>[];
    }>;
    init: SalesforceEmbeddedServiceInit;
}

interface ExtraPrechatFormDetail {
    label: string;
    value: string;
    displayToAgent: boolean;
}

interface SalesforceEmbeddedServiceInit {
    (urlA: string, urlB: string, gslbBaseURL: string | null, somethingA: string, clientArg: string, extraOptions: SalesforceEmbeddedServiceInitExtraOptions): void;
}

interface SalesforceEmbeddedServiceInitExtraOptions {
    baseLiveAgentContentURL: string;
    deploymentId: string;
    buttonId: string;
    baseLiveAgentURL: string;
    eswLiveAgentDevName: string;
    isOfflineSupportEnabled: boolean;
}

export interface InitSalesforceOptions {
    locale: string;
}

export default function InitSalesforce({ locale }: InitSalesforceOptions): void {
    if (window.embedded_svc) {
        initESW('https://service.force.com', locale, window.embedded_svc);
    } else {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://gaincapital.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        s.onload = function () {
            if (!window.embedded_svc) {
                const error = new Error('Salesforce embedded_svc was not loaded properly');
                ErrorLogger.RecordError(error, "Salesforce Chat - Couldn't Load Script");
                return;
            }
            initESW(null, locale, window.embedded_svc);
        };
        document.body.appendChild(s);
    }
}

export function OpenSalesforceChatWindow(): void {
    const helpButton = document.getElementsByClassName('helpButtonEnabled')[0] as HTMLButtonElement | undefined;
    if (!helpButton) {
        const error = new Error('User tried to open Salesforce chat window, but the button was not found');
        ErrorLogger.RecordError(error, "Salesforce Chat - Couldn't Find Button");
        return;
    }

    helpButton.click();
}

interface SalesforceConfig {
    buttonId: string;
    clientArg: string;
    eswLiveAgentDevName: string;
}

type ConfigVariant = 'pro' | 'default';

const configs: Record<ConfigVariant, SalesforceConfig> = {
    // Beta is no longer a thing
    // beta: {
    //     buttonId: '5737V00000000rX',
    //     clientArg: 'StoneX_Client_legacy',
    //     eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7V000000CaTXUA0_1818ad78a28'
    // },
    pro: {
        buttonId: '5737V000000Cb7B',
        clientArg: 'StoneX_One_Pro',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7V0000008OKpUAM_18340a76376'
    },
    default: {
        buttonId: '5737V00000000rN',
        clientArg: 'StoneX_One',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7V000000CaTNUA0_1817140b9b5'
    }
};

function getConfigFromEnv(env: Environment): SalesforceConfig {
    if (env === 'pro') return configs.pro;
    return configs.default;
}

function initESW(gslbBaseURL: string | null, locale: string, embedded_svc: SalesforceEmbeddedService) {
    const env = getSimulatedEnvironment();
    const { buttonId, clientArg, eswLiveAgentDevName } = getConfigFromEnv(env);

    embedded_svc.settings.chatbotAvatarImgURL = 'https://www.filesandimages.com/Brand/TradeResource/Images/ChatBotImage.jpg';
    embedded_svc.settings.displayHelpButton = false;
    embedded_svc.settings.language = locale || 'en-US'; // For example, enter 'en' or 'en-US'

    // embedded_svc.settings.defaultMinimizedText = '...'; // (Defaults to Chat with an Expert)
    // embedded_svc.settings.disabledMinimizedText = '...'; // (Defaults to Agent Offline)

    // embedded_svc.settings.loadingText = ''; // (Defaults to Loading)
    // embedded_svc.settings.storageDomain = 'yourdomain.com'; // (Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // // Settings for Chat
    // embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    //     // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    //     // Returns a valid button ID.
    // };
    // embedded_svc.settings.prepopulatedPrechatFields = {}; // Sets the auto-population of pre-chat form fields
    // embedded_svc.settings.fallbackRouting = []; // An array of button IDs, user IDs, or userId_buttonId
    // embedded_svc.settings.offlineSupportMinimizedText = '...'; // (Defaults to Contact Us)

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';
    embedded_svc.settings.autoOpenPostChat = true;

    setExtraPrechatFormDetails(embedded_svc);

    embedded_svc.init('https://gaincapital.my.salesforce.com', 'https://gaincapital.my.salesforce-sites.com/G2NMI', gslbBaseURL, '00D300000000CHg', clientArg, {
        baseLiveAgentContentURL: 'https://c.la4-c2-ia5.salesforceliveagent.com/content',
        deploymentId: '5727V00000000fg',
        buttonId,
        baseLiveAgentURL: 'https://d.la4-c2-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName,
        isOfflineSupportEnabled: false
    });
}

function setExtraPrechatFormDetails(embedded_svc: SalesforceEmbeddedService): void {
    // LogonEmail
    getLastResortEmail()
        .then((email) => {
            if (email) {
                embedded_svc.settings.extraPrechatFormDetails ||= [];
                embedded_svc.settings.extraPrechatFormDetails.push({
                    label: 'LogonEmail',
                    value: email,
                    displayToAgent: true
                });
            } else {
                const error = new Error("Failed to get user's email for Salesforce prechat form");
                ErrorLogger.RecordError(error, 'Salesforce Chat - Found No User Email');
            }
        })
        .catch((e) => {
            const error = e as Error;
            ErrorLogger.RecordError(error, 'Salesforce Chat - Error Getting User Email');
        });

    // LogonDomain
    embedded_svc.settings.extraPrechatFormDetails ||= [];
    embedded_svc.settings.extraPrechatFormDetails.push({
        label: 'LogonDomain',
        value: window.location.hostname,
        displayToAgent: true
    });
}
