// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { AssetTradeability } from 'phoenix/constants/AssetTradeability';
import { AssetClass, StandardPriceToQuantityFormula, StandardQuantityToPriceFormula } from 'phoenix/models/AssetClasses/AssetClass';
import { CalculatedAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { Account, AccountSummary, MyInfo } from 'phoenix/redux/models';
import { AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { FormatNumber, MarketTimeSegment } from 'phoenix/util';
import { Ts } from 'phoenix/assets/lang/T';
import { ChartRanges } from 'phoenix/constants';
import { OrderTypes } from 'phoenix/constants/Trade';
import { SecurityMetadataMix } from 'phoenix/stores/SecurityMetadataV2Store';
import { ApiOrderType } from '../ApiTradeRequest';

export const EquitiesAssetClass: AssetClass = {
    // Typing
    type: 'equity',
    family: 'equities',
    derivative: 'base',
    derivatives: ['option'],
    algoliaTypes: ['cs'],
    defaultTradePrice: {
        market: 'bid-ask',
        limit: 'bid-ask',
        stop: 'bid-ask',
        stoplimit: 'bid-ask'
    },

    // Tradeability
    accountCanTradeThis: (account: Account) => /^\d{4,8}$/.test(account.accountNumber),
    defaultOrderType: (marketTimeSegment?: MarketTimeSegment) => (marketTimeSegment === 'postmarket' ? OrderTypes.limit : OrderTypes.market),
    defaultTimeInForce: (marketTimeSegment?: MarketTimeSegment, orderType?: ApiOrderType) => {
        if (orderType !== 'limit') return 'Day';
        return marketTimeSegment === 'premarket' ? 'GTXPre' : marketTimeSegment === 'postmarket' ? 'GTXPost' : 'Day';
    },
    tradeability: AssetTradeability?.equity,
    userAllowedToTradeThis: () => true,
    getNumberOfStrikes: (user: MyInfo) => user.strikes,
    negativePriceAllowed: false,

    // Features
    hasDeepAnalysis: true,
    canCreateAlerts: true,
    hasOptions: true,
    optionsType: 'option',
    canWatchlist: true,
    tradePriceShortcutButtonsVariant: 'percent',
    showStopPriceShortcutButtons: true,
    hasMultiLeg: true,
    hasDerivatives: () => EquitiesAssetClass?.derivatives.length > 1,

    // Charting
    neverShowChartPulse: false,
    hasAdvancedChart: true,
    advancedChartConfiguration: {
        supported_resolutions: ['1', '10', '30', '1D', '1W']
    },
    chartVariants: ['line', 'candles'],
    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.oneDay, value: ChartRanges.oneDay },
            { label: text.fiveDay, value: ChartRanges.fiveDays },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.threeMonths, value: ChartRanges.threeMonths },
            { label: text.oneYear, value: ChartRanges.oneYear },
            { label: text.fiveYear, value: ChartRanges.fiveYears }
        ];
    },
    accountChartRanges: ['1d', '5d', '1m', '3m', '1y', '5y'],
    securityChartRanges: ['1d', '5d', '1m', '3m', '1y', '5y'],

    // Formatting + Codec
    getPriceFormatInfo: () => {
        return {
            decimalPlaces: undefined,
            tickSize: 0.01,
            moneyFormatOptions: {
                hideCurrencySymbol: false,
                maxDecimalPlaces: 4,
                min: 0,
                minDecimalPlaces: 2,
                showDecimals: true,
                tickSize: 0.00001
            }
        };
    },

    formatOrderQuantity: (o) => {
        const { showDecimals = true, decimalPlaces = 2 } = o?.quantityQualifier === 'Shares' ? { showDecimals: true, decimalPlaces: 8 } : {};
        let qty = FormatNumber.removeDecimalsIfZero(FormatNumber.toCommas(o?.orderQuantity, showDecimals, decimalPlaces));

        if (o?.quantityQualifier === 'EvenDollar') {
            qty = `${FormatNumber.toMoneyOpt2(o?.cost)}`;
        }

        return qty;
    },

    formatPrice: (price: number, meta: SecurityMetadataMix) => FormatNumber.toMoneyOpt2(price, EquitiesAssetClass?.getPriceFormatInfo(meta).moneyFormatOptions),
    getQuantityFormatOptions: () => {
        return {
            maxDecimalPlaces: 5,
            minDecimalPlaces: 0,
            hideCurrencySymbol: true,
            tickSize: 0.00001,
            showDecimals: true
        };
    },
    formatQuantity: (quantity: number) => FormatNumber.toMoneyOpt2(quantity, EquitiesAssetClass?.getQuantityFormatOptions()),
    unit: 'share',
    idMatches: (id: string) => undefined,
    getSymbolName: (s) => s,
    getSymbolNameLines: (s) => [s],
    getBaseSymbol: (s) => s,
    getConcreteSymbol: (s) => s,

    // Colors
    positiveChangeColor: 'rgb(2,179,52)',
    negativeChangeColor: 'rgb(178,41,46)',

    // Pricing
    getQuantityPrice: StandardQuantityToPriceFormula,
    getPriceForQuantity: StandardQuantityToPriceFormula,
    getQuantityForPrice: StandardPriceToQuantityFormula,
    getTodaysEarningsLabel: (l) => l.positionsScreen.todaysGainLoss,
    getTotalEarningsLabel: (l) => l.positionsScreen.openPnL,

    // Account Data
    getBuyingPowerFigures: (power: AggregateBuyingPower, lang: Snex1LanguagePack) => [
        { id: 'cash', label: lang.portfolioScreen.summary.cashAndEquivalents, value: FormatNumber.toMoneyOpt2(power?.cashPosition) },
        { id: 'margin-cash', label: lang.portfolioScreen.buyingPower.marginCashAvailable, value: FormatNumber.toMoneyOpt2(power?.marginCash) },
        { id: 'margin', label: lang.portfolioScreen.buyingPower.marginAvailable, value: FormatNumber.toMoneyOpt2(power?.marginCash) }
    ],

    getAccountSummaryFigures: (summary: AccountSummary, lang: Snex1LanguagePack) => [
        { id: 'cash', label: 'Cash & Equivalents', value: FormatNumber.toMoneyOpt2(summary?.totalCashCurrent), bold: true },
        { id: 'long', label: 'Long Market Value', value: FormatNumber.toMoneyOpt2(summary?.totalLongMarketCurrent) },
        { id: 'short', label: 'Short Market Value', value: FormatNumber.toMoneyOpt2(summary?.totalShortMarketCurrent) }
    ],

    getAccountGlanceFigures: (val: CalculatedAccountValuation, lang: Snex1LanguagePack) => {
        const l = lang.portfolioScreen.glance;
        return [
            { id: 'value', label: l.accountValue, value: val?.value },
            { id: 'invested', label: l.amountInvested, value: val?.amountInvested },
            { id: 'todayPnl', label: l.todaysGainLoss, value: val?.change },
            { id: 'unrealized', label: l.unrealizedGainLoss, value: val?.unrealizedGainLoss }
        ].map((i) => ({ ...i, value: FormatNumber.toMoneyOpt2(i.value) }));
    },

    showShortPositionIcon: true,

    // Documents
    documentDeliveryOptions: 'mail-electronic'
};
