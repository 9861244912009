import { AccountRestrictionCodes } from "phoenix/constants/AccountRestrictionCodes";
import { useActiveAndPendingAccounts } from "phoenix/hooks/useActiveAndPendingAccounts";

export const useAccountRestricted = (accountNumber: string) => {
    const { accounts, pendingAccounts } = useActiveAndPendingAccounts()
    const account = accountNumber ? accounts?.find(a => a.accountNumber === accountNumber) : null
    if(account) return account?.restrictionCode === AccountRestrictionCodes.stonexTraderRestriction;

    const pendingAccount = accountNumber ? pendingAccounts?.find(a => a.accountNumber === accountNumber) : null
    if(pendingAccount) return true
}