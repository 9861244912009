// @ts-strict-ignore
import AnimatedNumber from 'animated-number-react';
import { ChangeBar } from 'components/ChangeBar/ChangeBar';
import { DelayedBadge } from 'components/DelayedBadge/DelayedBadge';
import { Flex } from 'components/Flex';
import { useSecurityValues, useShowAdditionalStats } from 'components/SecurityValues/SecurityValuesViewModel';
import { ChartRange } from 'phoenix/constants';
import { ChangeColor, toCommas, toPercent } from 'phoenix/util';
import React, { memo, ReactElement } from 'react';
import './style.css';

const AdditionalFiguresCell = ({ label, value }: { label: string; value?: string }) => (
    <div className='additional-figures-cell'>
        <h6>{label}</h6>
        <span>{value}</span>
    </div>
);

export function SecurityValues({
    accentColor,
    formatValue,
    hideChangeBar,
    range,
    symbol,
    delayed
}: {
    accentColor?: string;
    formatValue: (x: number) => string;
    hideChangeBar?: boolean;
    range?: ChartRange;
    symbol?: string;
    delayed?: boolean;
}): ReactElement {
    const securityValues = useSecurityValues(symbol, range);
    const showAdditionalStats = useShowAdditionalStats();

    const { price, change, changePercent, open, close, high, low, volume } = { ...securityValues };

    return (
        <SecurityValuesPresentation
            price={price}
            change={change}
            changePercent={changePercent}
            open={open}
            close={close}
            high={high}
            low={low}
            volume={volume}
            accentColor={accentColor}
            formatValue={formatValue}
            showAddtionalStats={showAdditionalStats}
            hideChangeBar={hideChangeBar}
            delayed={delayed}
        ></SecurityValuesPresentation>
    );
}

export const SecurityValuesPresentation = memo(
    ({
        accentColor,
        formatValue,
        showAddtionalStats,
        price,
        change,
        changePercent,
        open,
        close,
        high,
        low,
        volume,
        hideChangeBar,
        delayed
    }: {
        accentColor?: string;
        formatValue: (x: number) => string;
        showAddtionalStats: boolean;
        price: number;
        change: number;
        changePercent: number;
        open: number;
        close: number;
        high: number;
        low: number;
        volume: number;
        hideChangeBar?: boolean;
        delayed?: boolean;
    }) => {
        return (
            <div className='security-values-container'>
                <div className='security-value-change'>
                    {
                        // Only animate truthy values, default to $---.--, and reevaluate/rerender on any price change
                        // This prevents a react error that can occur if forcing a rerender mid-animation
                        price ? (
                            <Flex className='animated-number' row style={{ minWidth: 200 + 72 }}>
                                <AnimatedNumber duration={300} formatValue={formatValue} value={price}></AnimatedNumber>
                                {delayed ? <DelayedBadge /> : null}
                            </Flex>
                        ) : (
                            <span className='animated-number'>$---.--</span>
                        )
                    }
                    {!hideChangeBar && (
                        <ChangeBar
                            change={formatValue(change)}
                            color={accentColor || ChangeColor(changePercent || change)}
                            percent={toPercent(changePercent)}
                            spun={changePercent < 0}
                        />
                    )}
                </div>
                {showAddtionalStats && (
                    <div className='ohlcv-wrapper'>
                        <AdditionalFiguresCell label='Open' value={formatValue(open)} />
                        <AdditionalFiguresCell label='High' value={formatValue(high)} />
                        <AdditionalFiguresCell label='Low' value={formatValue(low)} />
                        <AdditionalFiguresCell label='Close' value={formatValue(close)} />
                        <AdditionalFiguresCell label='Volume' value={toCommas(volume)} />
                    </div>
                )}
            </div>
        );
    }
);
