// @ts-strict-ignore
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetAggregateSummaryAction, GetClientAccountsAction, GetPendingFuturesAccounts } from 'phoenix/redux/actions';
import { GetAccountOpeningsAction } from 'phoenix/redux/actions/AccountOpeningActions';
import { Account, ApiPosition } from 'phoenix/redux/models';
import { PendingApplicationStatuses } from 'phoenix/redux/models/AccountOpenings/AccountOpening';
import { AccountBuyingPower, AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { BuyingPowerStore_Load, useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { isSecurityMetadataV2, SecurityMetadataMix, useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { Sum } from 'phoenix/util';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

type useAccountsArgs = {
    filter?: (a: { accountNumber: string }) => boolean;
    selectedAccount?: string | null; // This is sometimes handled locally per component, so pass it in if necessary
    withAllAccountsOption?: boolean;
    withBuyingPower?: boolean;
    withPending?: boolean;
    withPositionsForSymbol?: string;
    withRequests?: boolean;
};

export type SnexAccountOption = Pick<Account, 'accountNumber' | 'effectiveName' | 'restrictionCode'> & {
    buyingPower?: AccountBuyingPower | AggregateBuyingPower;
    sharesOwned?: number;
    isPending?: boolean;
};

export type AccountHookResponse = {
    loading?: boolean;
    error?: any; // TODO type
    data?: SnexAccountOption[];
};

function getSharesOwned({
    accountNumber,
    meta,
    positions,
    symbol
}: {
    accountNumber?: string;
    meta?: SecurityMetadataMix;
    positions?: ApiPosition[];
    symbol: string;
}): number {
    const secId = QualifiedSecurityId.Parse(symbol);
    const securityNumber = isSecurityMetadataV2(meta) ? meta?.equity?.securityNumber : meta?.securityNumber;
    const secIdByNumber = QualifiedSecurityId.Parse(`B:${securityNumber}`);

    const positionLookup = symbol
        ? positions
              ?.filter((p) => secId.MatchesPosition(p) || secIdByNumber.MatchesPosition(p))
              .reduce((lookup, next) => ({ ...lookup, [next.accountNumber]: (lookup[next.accountNumber] || 0) + next.quantity }), {})
        : null;

    const sum = positionLookup ? (accountNumber ? positionLookup[accountNumber] || 0 : Sum(Object.values(positionLookup))) : 0;
    return positions ? sum : null;
}

/** @deprecated UseActiveAndPendingAccountOptionsV2 for SecurityMetadataV2 */
export const UseActiveAndPendingAccountOptions = ({
    filter,
    selectedAccount,
    withAllAccountsOption,
    withBuyingPower,
    withPositionsForSymbol,
    withRequests
}: useAccountsArgs): AccountHookResponse => {
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const accounts = useSnexStore((s) => s.accounts?.all);
    const accountOpenings = useSnexStore((s) => s.accountOpenings.all);
    const pendingFuturesAccounts = useSnexStore((s) => s.accounts.pendingFuturesAccounts);
    const text = useText((s) => s);

    const pendingFuturesLoading = pendingFuturesAccounts?.loading || pendingFuturesAccounts?.pristine;
    const openingsLoading = accountOpenings?.loading || accountOpenings?.pristine;
    const accountsLoading = accounts?.loading || accounts?.pristine;

    const buyingPowerStore = useBuyingPowerStore();
    const positions = usePositionsStore((s) => s.positions);
    const securityStateBySymbol = useSnexStore((s) => s.securities.bySymbol);

    const onboardingV2Flag = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.onboardingV2]);

    useEffect(() => {
        if (!withRequests) return;
        if (pendingFuturesAccounts?.pristine && !pendingFuturesAccounts?.loading) dispatch(GetPendingFuturesAccounts());
        if (accounts?.pristine && !accounts?.loading) dispatch(GetClientAccountsAction());
        if (accountOpenings?.pristine && !accountOpenings?.loading) dispatch(GetAccountOpeningsAction());
        if (withAllAccountsOption) dispatch(GetAggregateSummaryAction());
        if (withBuyingPower) BuyingPowerStore_Load();
        if (withPositionsForSymbol) loadPositions();
    }, [accountOpenings, accounts, dispatch, loadPositions, pendingFuturesAccounts, withAllAccountsOption, withBuyingPower, withPositionsForSymbol, withRequests]);

    const allAccountsOption = withAllAccountsOption
        ? [
              {
                  accountNumber: 'all',
                  ...(withBuyingPower ? { buyingPower: buyingPowerStore?.summary } : {}),
                  ...(withPositionsForSymbol
                      ? {
                            sharesOwned: getSharesOwned({
                                positions,
                                meta: securityStateBySymbol[withPositionsForSymbol]?.metadata?.data,
                                symbol: withPositionsForSymbol
                            })
                        }
                      : {})
              }
          ]
        : [];

    const pendingAccounts = useMemo(
        () => (onboardingV2Flag?.enabled ? accountOpenings?.data?.filter((a) => PendingApplicationStatuses.has(a.accountApplicationStatus)) : []),
        [accountOpenings?.data, onboardingV2Flag?.enabled]
    );

    const mappedAccounts = (accounts?.data || [])

        .map((a) => ({
            ...a,
            ...(withBuyingPower ? { buyingPower: buyingPowerStore?.findByAccount(a.accountNumber) } : {}),
            ...(withPositionsForSymbol
                ? {
                      sharesOwned: getSharesOwned({
                          accountNumber: a.accountNumber,
                          meta: securityStateBySymbol[withPositionsForSymbol]?.metadata?.data,
                          positions,
                          symbol: withPositionsForSymbol
                      })
                  }
                : {})
        }))
        .sort((a) => (selectedAccount ? (a.accountNumber === selectedAccount ? -1 : 1) : 0));

    const mappedOpenings = [...pendingAccounts, ...(pendingFuturesAccounts?.data || [])].map((a) => ({
        accountNumber: a.accountNumber,
        effectiveName: a.name || `${text.general.pendingApproval}`,
        isPending: true
    }));

    const data = [...allAccountsOption, ...mappedAccounts, ...(pendingFuturesAccounts?.data || []), ...mappedOpenings];
    const filteredData = filter ? data.filter(filter) : data;

    return { data: [...new Map(filteredData.map((a) => [a.accountNumber, a])).values()], loading: accountsLoading || openingsLoading || pendingFuturesLoading }; // cleaning up issue where duplicate pending accounts were being returned to store
};

export const UseActiveAndPendingAccountOptionsV2 = ({
    filter,
    selectedAccount,
    withAllAccountsOption,
    withBuyingPower,
    withPositionsForSymbol,
    withRequests
}: useAccountsArgs): AccountHookResponse => {
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const accounts = useSnexStore((s) => s.accounts?.all);
    const accountOpenings = useSnexStore((s) => s.accountOpenings.all);
    const pendingFuturesAccounts = useSnexStore((s) => s.accounts.pendingFuturesAccounts);
    const text = useText((s) => s);

    const pendingFuturesLoading = pendingFuturesAccounts?.loading || pendingFuturesAccounts?.pristine;
    const openingsLoading = accountOpenings?.loading || accountOpenings?.pristine;
    const accountsLoading = accounts?.loading || accounts?.pristine;

    const buyingPowerStore = useBuyingPowerStore();
    const positions = usePositionsStore((s) => s.positions);
    const securityStateBySymbol = useSecurityMetadataV2((s) => s.data);

    const onboardingV2Flag = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.onboardingV2]);

    useEffect(() => {
        if (!withRequests) return;
        if (pendingFuturesAccounts?.pristine && !pendingFuturesAccounts?.loading) dispatch(GetPendingFuturesAccounts());
        if (accounts?.pristine && !accounts?.loading) dispatch(GetClientAccountsAction());
        if (accountOpenings?.pristine && !accountOpenings?.loading) dispatch(GetAccountOpeningsAction());
        if (withAllAccountsOption) dispatch(GetAggregateSummaryAction());
        if (withBuyingPower) BuyingPowerStore_Load();
        if (withPositionsForSymbol) loadPositions();
    }, [accountOpenings, accounts, dispatch, loadPositions, pendingFuturesAccounts, withAllAccountsOption, withBuyingPower, withPositionsForSymbol, withRequests]);

    const allAccountsOption = withAllAccountsOption
        ? [
              {
                  accountNumber: 'all',
                  ...(withBuyingPower ? { buyingPower: buyingPowerStore?.summary } : {}),
                  ...(withPositionsForSymbol
                      ? {
                            sharesOwned: getSharesOwned({
                                positions,
                                meta: securityStateBySymbol[withPositionsForSymbol],
                                symbol: withPositionsForSymbol
                            })
                        }
                      : {})
              }
          ]
        : [];

    const pendingAccounts = useMemo(
        () => (onboardingV2Flag?.enabled ? accountOpenings?.data?.filter((a) => PendingApplicationStatuses.has(a.accountApplicationStatus)) : []),
        [accountOpenings?.data, onboardingV2Flag?.enabled]
    );

    const mappedAccounts = (accounts?.data || [])

        .map((a) => ({
            ...a,
            ...(withBuyingPower ? { buyingPower: buyingPowerStore?.findByAccount(a.accountNumber) } : {}),
            ...(withPositionsForSymbol
                ? {
                      sharesOwned: getSharesOwned({
                          accountNumber: a.accountNumber,
                          meta: securityStateBySymbol[withPositionsForSymbol],
                          positions,
                          symbol: withPositionsForSymbol
                      })
                  }
                : {})
        }))
        .sort((a) => (selectedAccount ? (a.accountNumber === selectedAccount ? -1 : 1) : 0));

    const mappedOpenings = [...pendingAccounts, ...(pendingFuturesAccounts?.data || [])].map((a) => ({
        accountNumber: a.accountNumber,
        effectiveName: a.name || `${text.general.pendingApproval}`,
        isPending: true
    }));

    const data = [...allAccountsOption, ...mappedAccounts, ...(pendingFuturesAccounts?.data || []), ...mappedOpenings];
    const filteredData = filter ? data.filter(filter) : data;

    return { data: [...new Map(filteredData.map((a) => [a.accountNumber, a])).values()], loading: accountsLoading || openingsLoading || pendingFuturesLoading }; // cleaning up issue where duplicate pending accounts were being returned to store
};
