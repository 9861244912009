// @ts-strict-ignore
import { SubmitTransfer, ValidateTransfer } from "./FundingTransferHelpers";
import { useFundingSourcesStore } from "phoenix/stores/FundingSourcesStore";
import { useFundingLimitsStore } from "phoenix/stores/FundingLimitsStore";
import { QualifiedId } from "phoenix/util/QualifiedId";

export const FundingHelpers = {

    // Transfers
    ValidateTransfer,
    SubmitTransfer,

    // sources
    GetSources: useFundingSourcesStore.getState().load,
    AddSource : useFundingSourcesStore.getState().add,
    DeleteSource: useFundingSourcesStore.getState().delete,
    GetAvailableBalance: useFundingSourcesStore.getState().loadAvailableBalance,

    // limits
    GetLimits: useFundingLimitsStore.getState().load,
    GetMinimumAllowedDeposit: (snexAccountNumber: string): number => 
        useFundingLimitsStore.getState().limits?.minimumAllowedDeposits?.find(x=> 
            QualifiedId.RemovePrefix(x.snexAccountNumber) === QualifiedId.RemovePrefix(snexAccountNumber))?.minAllowedDeposit

    


}