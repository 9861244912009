import { KeyboardArrowLeft } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { CircularLogoPresentation } from 'components/CircularLogo/CircularLogo';
import { TabSelectPresentation } from 'components/TabSelect/TabSelect';
import { useColors } from 'hooks/UseColors';
import useOptionsOpenClose from 'hooks/useOptionsOpenClose';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { OptionSymbol } from 'phoenix/redux/models/Options/OptionSymbol';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React from 'react';
import { Flex } from '../..';
import './index.scss';

type TradeCancelHeaderProps = {
    action?: string;
    logo: ApiData<SecurityLogo>;
    onCancel: () => void;
    showLogo?: boolean;
    symbol?: string;
    text: Snex1LanguagePack['tradeTicket']['input'];
    title: string;
};

export const TradeCancelHeader = React.memo((props: TradeCancelHeaderProps) => {
    const { action, logo, onCancel, showLogo, symbol = '', text, title } = props;
    const { futuresColor, primaryItemColor } = useColors();
    const { openClose } = useOptionsOpenClose();
    const opennessText = useText((t) => t.misc.tradeTypeButton);
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const color = FuturesSymbol.IsFuturesSymbol(symbol) ? futuresColor : primaryItemColor;

    let actionTranslation: string;

    if (isOption && !isFuture) {
        actionTranslation =
            openClose === 'Open'
                ? props?.action === 'Buy'
                    ? opennessText.buyToOpen
                    : opennessText.sellToOpen
                : props?.action === 'Buy'
                ? opennessText.buyToClose
                : opennessText.sellToClose;
    } else {
        actionTranslation = props?.action ? text.action[props?.action?.toLowerCase() as 'buy' | 'sell'] : '';
    }

    return (
        <div className='trade-header cancel'>
            {showLogo && (
                <div style={{ marginRight: 10 }}>
                    <CircularLogoPresentation loaded={true} logo={logo} size={25} symbol={symbol} />
                </div>
            )}
            <Typography className='security-id' variant='h5'>
                {QualifiedId.RemovePrefix(title)}
            </Typography>
            {action ? (
                <Flex align='center' justify='space-between' style={{ flex: 0.75 }}>
                    <TabSelectPresentation
                        defaultSelected={action}
                        values={[{ label: actionTranslation || action, value: action }]}
                        onSelect={() => undefined}
                        style={{ color }}
                    />
                    <IconButton onClick={onCancel} size='large'>
                        <KeyboardArrowLeft />
                    </IconButton>
                </Flex>
            ) : (
                <IconButton onClick={onCancel} size='large'>
                    <KeyboardArrowLeft />
                </IconButton>
            )}
        </div>
    );
});
