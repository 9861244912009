import { Typography } from "@mui/material";
import React from "react";
import { Flex } from "../..";
import { useColors } from "hooks/UseColors";

export const NoDataView = ({ height, width, range }: { height?: any, width?: any, range?: string }) => {
    const colors = useColors();
    return (
        <Flex
            align='center'
            justify='center'
            style={{ height, width }}>
            <Typography
                style={{ color: colors.blurredAdornmentColor }}
                variant='h5'>No data for <span style={{ color: colors.primaryItemColor }}>{range ? range.toUpperCase() : ''}</span> chart</Typography>
        </Flex>)
}