import { SubmitTrade, UpdateTrade, CancelTrade } from 'phoenix/util/Trading/TradeSubmission';
import { ConvertOrderToTradeRequest } from './TradeConversion';
import { ValidateSubmitTrade, ValidateUpdateTrade } from 'phoenix/util/Trading/TradeValidation';

export const TradeHelpers = {
    ConvertOrderToTradeRequest,
    ValidateSubmitTrade,
    ValidateUpdateTrade,
    SubmitTrade,
    UpdateTrade,
    CancelTrade
};
