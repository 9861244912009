// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3m3AOCumUq-V5r-Gh2WvfY{margin:0;padding:0}._3m3AOCumUq-V5r-Gh2WvfY button{background:inherit;border:none;color:inherit;cursor:pointer;display:block;padding:.5rem 18px;text-align:left;width:100%}._3m3AOCumUq-V5r-Gh2WvfY{border-bottom:1px solid var(--dividerColor)}.sOI7sfSFMK-ZflXySUTgD{display:flex;align-items:center;justify-content:flex-end}._2-uYNhc40zmbXEd8E0bkvD,h5._2-uYNhc40zmbXEd8E0bkvD{font-size:20px;font-weight:800;margin:0;padding:0}._1x7vzCgmi1Ha9wiKtAQazm{border-radius:5px;transition:.1s;cursor:pointer}button._1x7vzCgmi1Ha9wiKtAQazm{background:inherit;border:none;color:inherit;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;padding:.5rem 18px}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094",
	"header": "_3m3AOCumUq-V5r-Gh2WvfY",
	"actionsWrapper": "sOI7sfSFMK-ZflXySUTgD",
	"headerTitle": "_2-uYNhc40zmbXEd8E0bkvD",
	"listIconWrapper": "_1x7vzCgmi1Ha9wiKtAQazm"
};
module.exports = exports;
