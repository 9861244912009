import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Usage:
// const accountNumber = useQueryParam('accountNumber')
// const { accountNumber } = useQueryParams()

export const useQueryParam = (key: string) => {
    const lookup = useQueryParams();
    return lookup[key];
};

export const pullValuesFromUrl = (search?: string): { [key: string]: string } => {
    const queryString = search || window.location.search;
    return queryString
        .split('&')
        .map((p) => p.replace(/^\?/, '').split('=').slice(0, 2))
        .filter((p) => p?.length === 2)
        .reduce((lookup, next) => ({ ...lookup, [next[0]]: next[1] }), {});
}

export const useQueryParams = (): { [key: string]: string } => {
    const { search } = useLocation();
    const [values, setValues] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setValues(pullValuesFromUrl(search));
    }, [search]);
    return values;
};
