import React, { PropsWithChildren, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { XS } from 'phoenix/xstream/XS';

interface AutoSubscribeProps {
    onVisibleChange?: (visible: boolean) => void;
    subscribe: () => string;
    children?: ReactNode;
}

export const AutoSubscribe = React.memo((props: AutoSubscribeProps) => {
    const [ref, visible] = useInView();
    const { subscribe } = props;
    const subscription = useRef<string | null>(null);

    const sub = useCallback(() => {
        if (subscription.current) unsub();
        subscription.current = subscribe();
    }, [subscribe]);

    const unsub = () => {
        if (subscription.current) XS.stop(subscription.current);
    };

    useEffect(() => {
        if (visible) sub();
        else if (!visible) unsub();
        if (props.onVisibleChange) props.onVisibleChange(visible);

        return () => {
            unsub();
        };
    }, [props, sub, visible]);

    return <span ref={ref}>{props.children}</span>;
});

interface AutoSubscribeStockQuotesProps {
    onVisibleChange?: (visible: boolean) => void;
    symbol: string;
    namespace: string;
    unless?: boolean;
}

export const AutoSubscribeStockQuotes = (props: PropsWithChildren<AutoSubscribeStockQuotesProps>): ReactElement | null => {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(props.symbol));
    }, [dispatch, props.symbol]);

    const sub = useCallback(
        () =>
            OptionSymbol.IsOptionSymbol(props.symbol)
                ? xdispatch(XS.OptionQuotes.start([new OptionSymbol(props.symbol).osiSymbol], props.namespace))
                : xdispatch(XS.Quotes.start(props.symbol, props.namespace)),
        [props.namespace, props.symbol, xdispatch]
    );

    useEffect(() => {
        return () => {
            if (props.namespace) XS.stopNs(props.namespace);
        };
    }, [props.namespace]);

    if (props.unless) return <>{props.children}</>;

    return (
        <AutoSubscribe subscribe={sub} onVisibleChange={props.onVisibleChange}>
            {props.children}
        </AutoSubscribe>
    );
};
