// @ts-strict-ignore
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Notes, KeyboardArrowLeft, FullscreenExit, Fullscreen, Minimize, Close } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CircularLogo, Flex } from '../..';
import { useColors } from 'hooks/UseColors';
import { FormattedTextList } from 'phoenix/assets/lang/Snex1LanguagePack';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { CloseMiniplayerAction } from 'phoenix/redux/actions';
import { GroupBySelectFirst, SafeFormat } from 'phoenix/util';
import { FormattedTextDisplay } from '../../FormattedTextDisplay/FormattedTextDisplay';
import { WithScrollbar } from '../../WithScrollbar/WithScrollbar';
import { NewMiniplayerContentProps } from '../NewMiniplayer';
import { DynamicModalSize } from 'components/Modals/DynamicModal';
import { MiniplayerState } from 'phoenix/redux/models';

export const NewMiniplayerAudio = React.memo((props: NewMiniplayerContentProps) => {
    const state = useSnexStore((s) => s.miniplayer);

    const sizeStyle = {
        small: { height: '210px', padding: '15px', width: '350px' },
        fullscreen: { height: '100vh', padding: '30px', width: '100vw' }
    };

    const style = sizeStyle[props.size] || { height: 'calc(100vh - 130px', padding: '30px', width: 'calc(50vw -  100px)' };

    return (
        <Flex column justify='space-between' style={style}>
            {props.size === 'small' ? (
                <NewMiniplayerAudioSmallHeader onChangeSize={props.onChangeSize} />
            ) : (
                <NewMiniplayerTranscript size={props.size} onChangeSize={props.onChangeSize} />
            )}
            <Flex row justify='center' style={{ height: 32 }}>
                <NewMiniplayerAudioPlayer mediaUrl={state.mediaUrl} />
            </Flex>
        </Flex>
    );
});

const NewMiniplayerAudioSmallHeader = React.memo((props: { onChangeSize: (size: DynamicModalSize) => void }) => {
    const dispatch = useDispatch();
    const { mediaTitle, mediaSubtitle, mediaTranscript, mediaSymbol } = useSnexStore((s) => s.miniplayer);
    const text = useText((s) => s.miniplayer);
    const colors = useColors();

    const subtitle: FormattedTextList = useMemo(() => {
        if (mediaTranscript?.participants) {
            const people = mediaTranscript.participants.map((p, idx) => [
                { text: p.name, weight: 'bold', size: 'regular' },
                { text: `, ${p.description}${idx === mediaTranscript.participants.length - 1 ? '' : ' · '}`, size: 'regular', color: 'rgba(127,127,127)' }
            ]);
            return [people.flat()] as FormattedTextList;
        } else return [[{ text: mediaSubtitle }]];
    }, [mediaSubtitle, mediaTranscript]);

    return (
        <Flex row style={{ maxHeight: 'calc(100% - 46px)' }}>
            <Flex column align='center' style={{ marginRight: 20, marginBottom: 20 }}>
                <CircularLogo symbol={mediaSymbol} size={50} style={{ marginBottom: 10 }} />
                <Tooltip title={text.viewTranscript} placement='right'>
                    <IconButton onClick={() => props.onChangeSize('expanded')} disabled={!mediaTranscript} size='large'>
                        <Notes />
                    </IconButton>
                </Tooltip>
            </Flex>

            <Flex column>
                <Flex row align='center' justify='space-between' style={{ marginBottom: 10 }}>
                    <Typography variant='h5' style={{ marginRight: 10 }}>
                        {mediaTitle}
                    </Typography>
                    <IconButton size='small' onClick={() => dispatch(CloseMiniplayerAction())}>
                        <Close />
                    </IconButton>
                </Flex>
                <div style={{ overflowY: 'scroll' }}>
                    <FormattedTextDisplay text={subtitle} />
                </div>
            </Flex>
        </Flex>
    );
});

const NewMiniplayerAudioPlayer = React.memo((props: { mediaUrl?: string }) => {
    return (
        <audio controls autoPlay style={{ height: '32px', width: '100%', maxWidth: '600px' }}>
            {' '}
            <source src={props.mediaUrl} />{' '}
        </audio>
    );
});

const NewMiniplayerTranscript = React.memo((props: { size: DynamicModalSize; onChangeSize: (size: DynamicModalSize) => void }) => {
    const { mediaTitle, mediaTranscript, mediaSymbol, mediaDate }: MiniplayerState = useSnexStore((s) => s.miniplayer);

    const peopleByName = useMemo(() => GroupBySelectFirst(mediaTranscript?.participants || [], (p) => p.name), [mediaTranscript]);
    const text = useText((s) => s.miniplayer);

    return (
        <Flex column style={{ maxHeight: 'calc(100% - 46px)' }}>
            <Flex row justify='space-between' align='flex-start'>
                <Flex row>
                    <CircularLogo symbol={mediaSymbol} size={60} />
                    <Flex column style={{ marginLeft: 30 }}>
                        <Typography variant='h4'>{mediaTitle}</Typography>
                        <Flex row>
                            <Typography variant='h6' color='textSecondary' style={{ marginRight: 20 }}>
                                {SafeFormat(mediaDate, 'dd MMMM yyyy')}
                            </Typography>
                            <Typography variant='h6' color='textSecondary'>
                                {mediaTranscript.participants.length} Participants
                            </Typography>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex row>
                    {props.size === 'expanded' ? (
                        <IconButton onClick={() => props.onChangeSize('tucked')} size='large'>
                            <KeyboardArrowLeft />
                        </IconButton>
                    ) : null}
                    {props.size === 'fullscreen' ? (
                        <IconButton onClick={() => props.onChangeSize('expanded')} size='large'>
                            <FullscreenExit />
                        </IconButton>
                    ) : null}
                    {props.size === 'expanded' ? (
                        <IconButton onClick={() => props.onChangeSize('fullscreen')} size='large'>
                            <Fullscreen />
                        </IconButton>
                    ) : null}
                    <IconButton onClick={() => props.onChangeSize('small')} style={{ marginLeft: 10 }} size='large'>
                        <Minimize />
                    </IconButton>
                </Flex>
            </Flex>

            <WithScrollbar>
                <Flex column>
                    {mediaTranscript?.transcript.map((s) => <TranscriptBlurb key={s.name} segment={s} speaker={peopleByName[s.name]} />) || (
                        <Flex center>
                            <Typography variant='h5'>{text.noTranscriptAvailable}</Typography>
                        </Flex>
                    )}
                </Flex>
            </WithScrollbar>
        </Flex>
    );
});

type SpeechSegment = {
    name: string;
    speech: string[];
};

type Speaker = {
    name: string;
    description: string;
};

const TranscriptBlurb = React.memo((props: { segment: SpeechSegment; speaker?: Speaker }) => {
    return (
        <Flex row style={{ marginTop: 24 }}>
            <Flex column style={{ width: 96 }}>
                <Typography variant='h6' style={{ textAlign: 'right' }} color='textSecondary'>
                    {props.segment.name}
                </Typography>
                {props.speaker ? (
                    <Typography variant='body1' style={{ textAlign: 'right' }} color='textSecondary'>
                        {props.speaker.description}
                    </Typography>
                ) : null}
            </Flex>
            <div style={{ flex: 1, margin: '0 20px' }}>
                {props.segment.speech.map((s) => (
                    <Typography variant='body1' style={{ textAlign: 'justify', marginBottom: 16, fontSize: 14, lineHeight: 1.5 }}>
                        {s}
                    </Typography>
                ))}
            </div>
        </Flex>
    );
});
