// @ts-strict-ignore
import { AxiosError } from 'axios';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { IsNonProd, ResolveBackend, StorageKeys } from '../../../constants';
import { GetMemo } from '../../../util/Memo';

const TorchBaseUrlTest = 'https://vulcan.test.stonex.com';
const TorchBaseUrlProd = 'https://vulcan.stonex.com';

type Upstream = 'vulcan' | 'snex'
const MakeUrl = (path: string, upstream: Upstream = 'vulcan') => {
    const base = (() => {
        if (upstream === 'vulcan') return IsNonProd() ? TorchBaseUrlTest : TorchBaseUrlProd;
        else return ResolveBackend();
    })()
    return `${base}${path}`
}

export type ActionResult<TResult> = { success: boolean; error: any; result: TResult };

const genAuthHeader = () => {
    const token = GetMemo(StorageKeys.MobileVulcanToken);
    const authHeaders = { Authorization: `Bearer ${token}` };
    return authHeaders;
};

export const OnboardingPut = async <TResult>(path: string, payload?: object, upstream?: 'vulcan' | 'snex'): Promise<ActionResult<TResult>> => {
    const url = upstream ? MakeUrl(path, upstream) : path
    try {
        console.log('[ONBO HTTP] PUT ', url, payload);
        const result = await SnexAxios.ApiPut<TResult>(url, { data: payload }).withVulcanToken(upstream === 'vulcan').run();
        ErrorLogger.RecordMessage(`Onboarding PUT - ${url}`, "Onboarding Request", { info: { payload, result, url } })
        return { success: true, error: '', result };
    } catch (err) {
        const ae = err as AxiosError;
        console.log(`[ONBO EROR] PUT ${ae.code}`, url, ae.response.data);
        const error = ae.response ? { status: ae.response.status, data: ae.response.data } : err.message || err.toString();
        ErrorLogger.RecordError(err, 'Onboarding PUT Error', { tags: { responseCode: ae.code, path }, info: { error } })
        return { success: false, error, result: null };
    }
};

export const OnboardingPost = async <TResult>(path: string, payload?: object, upstream?: 'vulcan' | 'snex'): Promise<ActionResult<TResult>> => {
    const url = upstream ? MakeUrl(path, upstream) : path
    try {
        console.log('[ONBO HTTP] POST ', url, payload)
        const result = await SnexAxios.ApiPost<TResult>(url, { data: payload }).withVulcanToken(upstream === 'vulcan').run();
        ErrorLogger.RecordMessage(`Onboarding POST - ${url}`, "Onboarding Request", { info: { payload, result, url } })
        return { success: true, error: '', result };
    } catch (err) {
        const ae = err as AxiosError;
        console.log(`[ONBO EROR] POST ${ae.code}`, url, ae.response?.data);
        const error = ae.response ? { status: ae.response.status, data: ae.response.data } : err.message || err.toString();
        ErrorLogger.RecordError(err, 'Onboarding POST Error', { tags: { responseCode: ae.code, path } })
        return { success: false, error, result: null };
    }
};

export const OnboardingGet = async <TResult>(path: string, upstream?: 'vulcan' | 'snex'): Promise<ActionResult<TResult>> => {
    const url = upstream ? MakeUrl(path, upstream) : path
    try {
        console.log('[ONBO HTTP] GET ', url)
        const result = await SnexAxios.ApiGet<TResult>(url).withVulcanToken(upstream === 'vulcan').run()
        ErrorLogger.RecordMessage(`Onboarding GET - ${url}`, "Onboarding Request", { info: { result, url } })
        return { success: true, error: '', result }
    } catch (err) {
        const ae = err as AxiosError;
        console.log(`[ONBO EROR] GET ${ae.code}`, url, ae.response.data);
        const error = ae.response ? { status: ae.response.status, data: ae.response.data } : err.message || err.toString();
        ErrorLogger.RecordError(err, 'Onboarding GET Error', { tags: { responseCode: ae.code, path }, info: { error, url } })
        return { success: false, error, result: null };
    }
};

export const OnboardingDelete = async <TResult>(path: string, upstream?: 'vulcan' | 'snex'): Promise<ActionResult<TResult>> => {
    const url = upstream ? MakeUrl(path, upstream) : path
    try {
        console.log('[ONBO HTTP] DELETE', url);
        const result = await SnexAxios.ApiDelete<TResult>(url).withVulcanToken(upstream === 'vulcan').run();
        ErrorLogger.RecordMessage(`Onboarding DELETE - ${url}`, "Onboarding Request", { info: { result, url } })
        return { success: true, error: '', result };
    } catch (err) {
        const ae = err as AxiosError;
        console.log(`[ONBO EROR] DELETE ${ae.code}`, url, ae.response.data);
        const error = ae.response ? { status: ae.response.status, data: ae.response.data } : err.message || err.toString();
        ErrorLogger.RecordError(err, 'Onboarding GET Error', { tags: { responseCode: ae.code, path }, info: { error, url } })
        return { success: false, error, result: null };
    }
};
