// @ts-strict-ignore
import { LinearProgress, Typography } from '@mui/material';
import { CircularLogo } from 'components/CircularLogo/CircularLogo';
import { Flex } from 'components/Flex';
import { getActionSummary, getStatusColor } from 'components/OpenOrders/helpers';
import { ActionIcon, LightText, SharePriceNugget } from 'components/OpenOrders/OrderRow';
import { useColors } from 'hooks/UseColors';
import { FromEnglish } from 'phoenix/assets/lang/T';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { Order } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { SafeFormat } from 'phoenix/util';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';

function SidePanelOrderRow({ order }: { order: Order }): ReactElement {
    const secId = QualifiedSecurityId.FromOrder(order);
    const isMultiLeg = order.complexOrders?.length > 0;
    const filledQuantity = order.orderQuantity - order.leavesQuantity;
    const isFuture = FuturesSymbol.IsFuturesSymbol(order?.symbol);
    const isOpen = order.orderStatus === 'Open' || order.orderStatus === 'Working';
    const isPartiallyFilled = !isFuture && isOpen && order?.orderQuantity > 1 && filledQuantity > 0;
    const text = useText((s) => s);
    const assetClass = useAssetClassMetaV2(order?.symbol);
    const { formatPrice } = assetClass;
    const meta = useSecurityMetadataV2().getMetadataBySymbol(order?.symbol);
    const formatValue = (value: number) => formatPrice(value, meta);

    const percentComplete = Math.floor((filledQuantity / order.orderQuantity) * 100);

    const actionSummaryArgs = {
        assetClass,
        isPartiallyFilled,
        isPrimaryOrder: true,
        meta,
        order,
        text
    };
    const actionSummaryFirstLine = getActionSummary(actionSummaryArgs);
    const actionSummarySecondLine = isMultiLeg && getActionSummary({ ...actionSummaryArgs, isPrimaryOrder: false });
    const statusColor = getStatusColor(order, useColors());

    return (
        <Link to={Routes.security(secId?.toString())}>
            <button className='order-row'>
                {isPartiallyFilled ? (
                    <Flex column center style={{ width: '75%' }}>
                        <LightText style={{ marginLeft: 5, textAlign: 'right' }}>
                            {text.orders.partialFill(secId?.id, order.orderQuantity - order.leavesQuantity, order.orderQuantity)}
                        </LightText>
                        <Flex row align='center' justify='center' style={{ flex: 'auto', width: '100%' }}>
                            <LinearProgress variant='determinate' value={percentComplete} style={{ flex: 'auto' }} />
                            <Typography marginLeft={'5px'} variant='body2' color='text.secondary'>{`${percentComplete}%`}</Typography>
                        </Flex>
                    </Flex>
                ) : (
                    !isMultiLeg && <SharePriceNugget formatValue={formatValue} namespace={LiveDataNamespaces.SidePanel} symbol={secId.id} />
                )}
                <div className='order-details'>
                    <Flex>
                        <CircularLogo altText={order?.securityName} symbol={order.symbol} size={30} />
                        <div className='order-title'>
                            <Typography variant='h6'>{text.general.symbolName(secId.id)}</Typography>
                            {isMultiLeg && <Typography variant='h6'>{text.general.symbolName(order?.complexOrders?.[0]?.symbol)}</Typography>}
                            <LightText style={{ color: statusColor }}>{FromEnglish((s) => s.orders.statuses, order.orderStatus, 'key')}</LightText>
                        </div>
                    </Flex>
                    <div className='action-summary'>
                        <Typography variant='h6'>{actionSummaryFirstLine}</Typography>
                        {actionSummarySecondLine && <Typography variant='h6'>{actionSummarySecondLine}</Typography>}
                        <Flex row align='center' justify='flex-end' style={{ flex: 1 }}>
                            <LightText style={{ whiteSpace: 'nowrap' }}>{SafeFormat(order.placedDate || order.completedDate, 'dd MMM yyyy, h:mma')}</LightText>
                            <ActionIcon orderDetails={order} />
                            {isMultiLeg && order.complexOrders.map((o, i) => <ActionIcon key={i} orderDetails={o} />)}
                        </Flex>
                    </div>
                </div>
            </button>
        </Link>
    );
}

export default SidePanelOrderRow;
