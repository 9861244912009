import { Link } from '@mui/material';
import { ActionModal, Flex, LoadingSpinner, OpenOrders, SecurityChartSection } from 'components';
import { UseSelectedAccount, useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { BigTextLink } from 'components/BigTextLink/BigTextLink';
import { DisclosureFooter } from 'components/DisclosureFooter/DisclosureFooter';
import { AddToListModal } from 'components/Modals/AddToListModal';
import { SecurityHoldings } from 'components/SecurityHoldings/SecurityHoldings';
import 'components/TradeTicket/Shared/index.scss';
import { OptionsTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { getDefaultOrderProps, useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { TradeTicket } from 'components/TradeTicket/TradeTicket';
import { TableViewMinHeight } from 'constants/GlobalDimensions';
import { useColors } from 'hooks/UseColors';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useQueryParams } from 'hooks/UseQueryParams';
import { StorageKeys } from 'phoenix/constants';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { LevelOrBetter, OptionsLevel } from 'phoenix/constants/OptionsLevels';
import { TradeActions } from 'phoenix/constants/Trade';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import {
    ConvertTradeableSecurityTypeToApiTradeableSecurityType,
    GetClientAccountsAction,
    GetSecurityQuoteAction,
    PublishSubscriptionWhitelist
} from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { OptionSymbol, TradeAction } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useOrdersStore } from 'phoenix/stores/OrdersStore';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { FuturesOptionStatsSection } from 'screens/FuturesOptionContractScreen/FuturesOptionStatsSection';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import { Routes } from 'util/Routes';
import { EnableOptionsScreen } from '../OptionsScreen/EnableOptionsScreen';
import { OptionContractHeaderSection } from './OptionContractHeaderSection';
import { OptionStatsSection } from './OptionStatsSection';

export const OptionContractScreen = React.memo(() => {
    const [addToListOpen, setAddToListOpen] = useState(false);
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const { osiSymbol: symbol = '' } = useParams<{ osiSymbol: string }>();
    const { searchOrders } = useOrdersStore();
    const colors = useColors();
    const optSym = new OptionSymbol(symbol);
    const occSym = optSym.toOccSymbol();
    const accounts = useSelector((s: GlobalState) => s.accounts.all);
    const text = useText((s) => s.optionContractScreen);
    const positionsText = useText((s) => s.positionsScreen);
    const assetClass = useAssetClass(symbol);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const underlying = (isFuture ? optSym.underlyingSymbolWithPrefix : optSym.underlyingSymbol) || '';
    const { setViewModel } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const { loadData, getMetadataBySymbol } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(symbol);
    const quote = XS.OptionQuotes.use(symbol);
    const [, setShowMultiLeg] = useLocalStorage(StorageKeys.ShowMultiLeg, false);
    const positions = usePositionsStore((s) => s.positions);
    const selectedAccountNumber = useSelectedAccountByAssetFamily(assetClass.family);
    const position = positions?.find((p) => p.secMasterOptionSymbol === symbol && selectedAccountNumber === p.accountNumber);
    const [selectedAccount = ''] = UseSelectedAccount();
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Navigate to the put chain if this symbol is a put
    const queryParams = useMemo(() => {
        return {
            side: optSym.putCall || ''
        };
    }, [optSym.putCall]);

    const rollPositionParams = useMemo(() => {
        return {
            rollAction: (position?.quantity || 0) > 0 ? TradeActions.Sell : TradeActions.Buy,
            rollQuantity: assetClass.formatQuantity(Math.abs(position?.quantity || 0)),
            rollSymbol: symbol,
            selectedAccount
        };
    }, [assetClass, position?.quantity, selectedAccount, symbol]);

    const [isTicketInitialized, setIsTicketInitialized] = useState<boolean>(false);

    const { action: initialAction } = useQueryParams();

    const { color, hasOptionsAccounts, linkText, StatsComponent } = useMemo(() => {
        return isFuture
            ? {
                  color: colors.futuresColor,
                  hasOptionsAccounts: true,
                  linkText: new FuturesSymbol(optSym.underlyingSymbol).noPrefix,
                  StatsComponent: FuturesOptionStatsSection
              }
            : {
                  color: colors.primaryItemColor,
                  hasOptionsAccounts: accounts?.data?.some((a) => LevelOrBetter(a.optionsLevel, OptionsLevel.CoveredCalls)),
                  linkText: optSym.underlyingSymbol,
                  StatsComponent: OptionStatsSection
              };
    }, [accounts?.data, colors.futuresColor, colors.primaryItemColor, isFuture, optSym.underlyingSymbol]);

    // Set Base Layout Props
    useEffect(() => {
        const hasOptionsAccountsProps = hasOptionsAccounts
            ? {
                  noCard: true,
                  sidebar: (
                      <div className={`trade-sidebar ${assetClass.family}`}>
                          <TradeTicket />
                          {meta && !meta?.option?.isAdjusted ? (
                              <>
                                  <div className='trade-links'>
                                      {assetClass.family === 'equities' && position && (
                                          <Link onClick={() => setShowMultiLeg(true)} component={ReactLink} to={Routes.optionChain(underlying, rollPositionParams)}>
                                              {positionsText.rollPosition}
                                          </Link>
                                      )}
                                      <Link component={ReactLink} to={Routes.security(underlying)}>
                                          {text.tradeUnderlying(linkText)}
                                      </Link>
                                  </div>
                                  <BigTextLink color={color} route={Routes.optionChain(underlying, queryParams)}>
                                      {text.viewAllOptions}
                                  </BigTextLink>
                              </>
                          ) : (
                              <br />
                          )}
                          <SecurityHoldings symbol={symbol} />
                      </div>
                  )
              }
            : {};

        setBaseLayoutProps({
            hideGlance: true,
            ...hasOptionsAccountsProps
        });
    }, [
        assetClass.family,
        color,
        hasOptionsAccounts,
        linkText,
        meta,
        position,
        positionsText.rollPosition,
        queryParams,
        rollPositionParams,
        setBaseLayoutProps,
        setShowMultiLeg,
        symbol,
        text,
        underlying
    ]);

    useEffect(() => {
        PublishSubscriptionWhitelist([LiveDataNamespaces.OptionContractScreen]);
        dispatch(GetClientAccountsAction());
        const run = async () => {
            await loadPositions();
            await loadData(symbol);
            const searchFilters = { symbol, take: 1000, tradeableSecurityType: ConvertTradeableSecurityTypeToApiTradeableSecurityType(assetClass.type) };
            await searchOrders(searchFilters);
        };
        run();
        dispatch(GetSecurityQuoteAction(symbol));
    }, [assetClass.type, dispatch, loadData, loadPositions, searchOrders, symbol]);

    useEffect(() => {
        let ticket: string;
        let uTicket: string;
        const run = async () => {
            ticket = await xdispatch(XS.OptionQuotes.start([symbol], LiveDataNamespaces.OptionContractScreen));
            if (!isFuture) uTicket = await xdispatch(XS.Quotes.start(optSym.underlyingSymbol, LiveDataNamespaces.OptionContractScreen));
        };
        run();

        return () => {
            XS.stop(ticket);
            XS.stop(uTicket);
        };
    }, [dispatch, isFuture, occSym, optSym.underlyingSymbol, symbol, xdispatch]);

    useEffect(() => {
        if (isTicketInitialized) return;

        const defaultProps = getDefaultOrderProps({
            assetClass,
            marketTimeSegment,
            quote,
            ...(meta?.option?.isAdjusted ? { tradeAction: TradeActions.Sell } : {}) // Only allow selling adjusted options
        });

        if (initialAction) defaultProps.tradeAction = initialAction as TradeAction;

        setViewModel({
            ...defaultProps,
            state: 'input',
            symbol,
            variant: 'option-individual',
            ...(meta?.option?.isAdjusted ? { tradeAction: TradeActions.Sell } : {}) // Only allow selling adjusted options
        });

        if (quote) setIsTicketInitialized(true);
    }, [assetClass, initialAction, isTicketInitialized, marketTimeSegment, meta, quote, setViewModel, symbol]);

    useEffect(() => {
        setIsTicketInitialized(false);
    }, [assetClass, initialAction, marketTimeSegment, meta, setViewModel, symbol]);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        return () => XS.stopNs(LiveDataNamespaces.OptionContractScreen);
    }, []);

    if (hasOptionsAccounts === false) return <EnableOptionsScreen />;
    else if (hasOptionsAccounts !== true) {
        return (
            <Flex center column style={{ height: '80vh' }}>
                <LoadingSpinner />
            </Flex>
        );
    }

    return (
        <>
            <Flex column style={{ marginTop: 20, minHeight: TableViewMinHeight(200) }}>
                <OptionContractHeaderSection symbol={symbol} onAddToListClick={() => setAddToListOpen(true)} />
                <SecurityChartSection symbol={symbol} />
                <OpenOrders securityKnown label={text.history} status='All' symbol={symbol} unexpandedOrders={!isFuture ? 1 : undefined} />
                <StatsComponent symbol={symbol} />
            </Flex>
            <ActionModal closerStyle='done-button' isOpen={addToListOpen} label={'addToList'} toggleModal={() => setAddToListOpen(false)}>
                <AddToListModal symbol={symbol} />
            </ActionModal>
            <DisclosureFooter earningsAndRatingsData featuresAndBalances ordersAndActivity securityPricing clientExtras />
        </>
    );
});
