import React from 'react';
import { FormatNumber } from 'phoenix/util';
import { useColors } from 'hooks/UseColors';
import SpreadIndicator from 'phoenix/assets/images/spreadIndicator.png';

interface NumberBlipProps {
    n: number;
    size: number;
    style?: React.CSSProperties;
    hideNumber?: boolean;
    isSpread?: boolean;
}

export const NumberBlip = React.memo((props: NumberBlipProps) => {
    const colors = useColors()
    if (props.isSpread) {
        return (
            <img
                src={SpreadIndicator}
                alt='Spread Indicator'
                style={{
                    width: props.size * 1.2,
                    height: props.size * 1.2
                }}
            />
        );
    }

    const nPlaces = Math.ceil(Math.log10(Math.abs(props.n))) // College math coming in clutch lol
    return (
        <span
            style={{
                background: props.n < 0 ? colors.negativeValueColor : colors.positiveValueColor,
                borderRadius: props.size / 2,
                display: 'inline-block',
                color: colors.mainBackgroundColor,
                fontSize: props.size - 8,
                height: props.size,
                minWidth: props.size + nPlaces * 5,
                fontWeight: 'bolder',
                paddingTop: 2,
                paddingRight: 1,
                ...props.style
            }}
        >
            {props.hideNumber ? '' : FormatNumber.toCommas(props.n)}
        </span>
    );
});
