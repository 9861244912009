import { AreaSeriesPartialOptions, CandlestickSeriesPartialOptions, DeepPartial, HistogramSeriesPartialOptions, CrosshairOptions as LightweightCrosshairType, LineSeriesPartialOptions } from "lightweight-charts";
import { AppColorTheme } from "phoenix/theming/ColorVariants/AppColorTheme";

export type CrosshairOptionsSetKey = 'none' | 'vertical' | 'marker';
export type CrosshairOptionsSetValue = {
    options: DeepPartial<LightweightCrosshairType>;
    markerVisible: boolean;
};
export type CrosshairOptionsSet = Record<CrosshairOptionsSetKey, CrosshairOptionsSetValue>;

export const CrosshairOptions = ({colors}: { colors: AppColorTheme}): CrosshairOptionsSet => ({
        none: {
            options: {
                vertLine: {
                    visible: false
                },
                horzLine: {
                    visible: false
                }
            },
            markerVisible: false
        },
        vertical: {
            options: {
                vertLine: {
                    visible: true,
                    style: 0,
                    width: 2,
                    color: colors.chartVerticalCrosshairColor,
                    labelVisible: false
                },
                horzLine: {
                    visible: false
                }
            },
            markerVisible: true
        },
        marker: {
            options: {
                vertLine: {
                    visible: false
                },
                horzLine: {
                    visible: false
                }
            },
            markerVisible: true
        }
    })
