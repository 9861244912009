// @ts-strict-ignore
import { Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Flex } from '..';
import { FormatNumber, GetValueChangeColor } from 'phoenix/util';
import { PercentChange } from '../PercentChange';
import style from './style.module.scss';
import { Help } from '@mui/icons-material';
import { useColors } from 'hooks/UseColors';
import { useAppWindowSize } from 'hooks/UseWindowSize';

export interface GlanceItem {
    value?: number;
    percentChange?: number;
    label?: string;
    info?: string;
}

export const GlancePresentation = ({
    loading,
    items,
    nonFixed,
}: {
    loading?: boolean;
    items?: GlanceItem[];
    nonFixed?: boolean;
}): JSX.Element => {
    return (
        <Flex row className={!nonFixed ? style.wrapper : 'wrapper non-fixed'} >
            {items?.map((i, index) => (
                <GlanceCellPresentation key={`Glance_${index}`} label={i.label} loading={loading} value={i.value} percentChange={i.percentChange} info={i.info} />
            ))}
        </Flex>
    );
};

export const GlanceCellPresentation = ({
    loading,
    value,
    percentChange,
    label,
    info,
}: {
    loading?: boolean;
    value?: number;
    percentChange?: number;
    label?: string;
    info?: string;
}): JSX.Element => {
    const [appWindowSize] = useAppWindowSize();
    const colors = useColors();

    const fontSizes = useMemo(() => {
        switch (appWindowSize) {
            case 'tablet':
                return { percentChange: 12, value: 24 };
            default:
                return { percentChange: 15, value: 30 };
        }
    }, [appWindowSize]);

    return (
        <Flex id={'portfolio-glance-section'} column className={style.cell} style={{ opacity: loading && isNaN(value) && isNaN(percentChange) ? 0.5 : 1 }}>
            <Flex align='center' justify={'flex-start'} style={{ gap: 10 }}>
                <Flex row justify={'flex-start'} align='center'>
                    <Typography className={style.cellLabel} variant='h5'>
                        {label}
                    </Typography>
                    {info && (
                        <Tooltip placement='right' title={<span style={{ fontSize: 10 }}>{info}</span>}>
                            <Help style={{ fontSize: 17, marginLeft: 10, color: colors.blurredAdornmentColor }} />
                        </Tooltip>
                    )}
                </Flex>
                {!!percentChange && (
                    <Flex row justify={'flex-start'}>
                        <PercentChange percentChange={percentChange} size={fontSizes.percentChange} />
                    </Flex>
                )}
            </Flex>

            <Typography style={{ paddingTop: 5, fontSize: fontSizes.value, ...GetValueChangeColor(value) }} variant='h3'>
                {value !== undefined && value !== null ? FormatNumber.toMoney(value) : '---'}
            </Typography>
        </Flex>
    );
};
