import { Skeleton } from '@mui/material';
import React from 'react';
import { Flex } from '..';

export const SectorExposureLoadingGrid = () => {
    return (
        <>
            {new Array(12).fill(0).map((_, i) => (
                <Flex key={i} row style={{ width: '50%', overflow: 'hidden', padding: '15px 5px', boxSizing: 'border-box' }}>
                    <div style={{ height: 15, width: 15, backgroundColor: 'grey', margin: 3, marginRight: 10, borderRadius: 3 }} />
                    <Flex column style={{ flex: 1 }}>
                        <Skeleton variant='text' width={'80%'} height='20px' />
                        <Skeleton variant='text' width={'40%'} height='20px' />
                    </Flex>
                </Flex>
            ))}
        </>
    );
};
