// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6i2kxe0tQYfBEZnOqJjyP{gap:12px;border-radius:17px;background-position:center;background-size:cover;margin-bottom:12px;padding:12px;position:relative;overflow:hidden}.FY-tjKffQNYo6Il6TDN3y{height:100%;width:100%;flex-direction:row;display:flex;background:linear-gradient(to bottom, transparent 30%, #000);position:absolute;top:0;justify-content:flex-start;gap:12px;left:0;padding:12px}", ""]);
// Exports
exports.locals = {
	"feed-video-container": "_6i2kxe0tQYfBEZnOqJjyP",
	"feed-video-shaded-overlay": "FY-tjKffQNYo6Il6TDN3y"
};
module.exports = exports;
