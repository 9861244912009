import { AlertConditionType as AlertCondition, FormatAlertCondition } from 'phoenix/constants/Alerts';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useText } from 'phoenix/hooks/UseText';
import { UpdateAlertRuleAction } from 'phoenix/redux/actions/AlertsActions';
import { AlertRule } from 'phoenix/redux/models';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { SecurityCell } from '../../SecurityCell';
import { ModalViewHeader, PaddedSection } from '../Shared';
import { CreateConditionForm } from './CreateConditionForm';
import { IAlertViewProps } from './Shared/IAlertViewProps';

export interface EditAlertViewProps extends IAlertViewProps {
    alert?: Partial<AlertRule>;
    symbol?: string;
}

export const EditAlertView = React.memo((props: EditAlertViewProps) => {
    const { navigate, alert } = props;
    const dispatch = useDispatch();
    const [symbol] = useState(props.alert?.symbol ?? '');
    const text = useText((t) => t);

    const [repeatDaily, setRepeatDaily] = useState(props.alert?.repeatDaily ?? false);
    const repeatDailyRef = useRef(false);
    const handleSaveAlert = useCallback(
        async (newCondition: AlertCondition) => {
            dispatch(
                UpdateAlertRuleAction({
                    id: alert?.id,
                    symbol,
                    name: `${symbol} ${text.alerts.priceAlert}`,
                    conditions: [FormatAlertCondition.toApiFriendly(newCondition)],
                    repeatDaily: repeatDailyRef.current
                })
            );
            navigate('list');
        },
        [dispatch, navigate, symbol, text.alerts.priceAlert]
    );

    const handleGoBack = useCallback(() => props.navigate('list'), []);
    const handleAddCondition = useCallback((newCondition: AlertCondition) => handleSaveAlert(newCondition), [handleSaveAlert]);

    useEffect(() => {
        repeatDailyRef.current = repeatDaily;
    }, [repeatDaily]);

    return (
        <Flex column style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <ModalViewHeader goBack={handleGoBack} title={text.alerts.editExistingAlert} />
            <Flex column style={{ width: '100%', flex: 1, overflow: 'auto', paddingTop: 20 }}>
                <PaddedSection>
                    {<SecurityCell streamingNamespace={LiveDataNamespaces.SecurityCellAlert} symbol={symbol} variant='search-result' visible />}
                </PaddedSection>
                <CreateConditionForm
                    symbol={symbol}
                    onAdd={handleAddCondition}
                    onRepeatDailyChange={setRepeatDaily}
                    isUpdate={true}
                    repeatDaily={repeatDaily}
                    monitoredField={props.alert?.monitoredField}
                    operatorSymbol={props.alert?.operatorSymbol}
                    initialValue={props.alert?.triggerValue}
                />
            </Flex>
        </Flex>
    );
});
