// @ts-strict-ignore
import React, { createContext, useState } from 'react';
import { TelemetryCategories } from '../constants/Telemetry/TelemetryCategories';

// TelemetryContext: to query the context state
export const TelemetryContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
export const TelemetryProvider =
    (InnerComponent, category = TelemetryCategories.general) =>
    (props: any) =>
        (
            <TelemetryContext.Provider value={category || TelemetryCategories.general}>
                <InnerComponent {...props} />
            </TelemetryContext.Provider>
        );
