import { Close } from '@mui/icons-material';
import { CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import { Card, Flex } from 'components';
import { DownloadDocumentActionV2 } from 'phoenix/redux/actions/DocumentsActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { SnexDocumentV2 } from 'phoenix/redux/models';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';

interface DocumentPreviewModalProps {
    document: SnexDocumentV2 | null;
    onClose: () => void;
    open: boolean;
}

export const DocumentPreviewModal = React.memo((props: DocumentPreviewModalProps) => {
    const dispatch = useDispatch();
    const download = useSelector((s: GlobalState) => s.documents.download);
    const metadata = props.document;

    const Loading = () => (
        <Flex row align='center' justify='center'>
            <CircularProgress />
        </Flex>
    );

    useEffect(() => {
        if (props.open && props.document?.id) dispatch(DownloadDocumentActionV2(props.document));
    }, [dispatch, props.document, props.document?.id, props.open]);

    const isLoading = useMemo(() => download.loading || !download.data?.blob, [download.loading, download.data?.blob]);

    return (
        <Modal open={props.open} style={{ zIndex: 999999 }}>
            <Flex className='document-preview-modal' row align='center' justify='center' style={{ height: '100%' }}>
                <Card>
                    <Flex column justify='flex-start' style={{ height: '90vh' }}>
                        <Flex row align='center' justify='space-between' style={{ padding: 10 }}>
                            <Flex column style={{ flex: 1 }}>
                                {metadata ? (
                                    <Typography color='textSecondary' variant='h6'>
                                        {metadata?.documentDate} &middot; {metadata?.description}
                                    </Typography>
                                ) : null}
                            </Flex>
                            <IconButton onClick={props.onClose} size='large'>
                                <Close />
                            </IconButton>
                        </Flex>
                        {isLoading ? <Loading /> : <DocumentDisplay blob={download.data?.blob} />}
                    </Flex>
                </Card>
            </Flex>
        </Modal>
    );
});

const DocumentDisplay = (p: { blob?: Blob | null }) => {
    const src = useMemo(() => !!p?.blob && window.URL.createObjectURL(p.blob), [p.blob]);
    return <embed src={src || ''} style={{ width: '100%', flex: 1 }}></embed>;
};
