// @ts-strict-ignore
import { Checkbox, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Flex } from '../..';
import { useShowAmexCardModal } from 'hooks/UseShowAmexCardModal';
import { AnimatedModal } from '../AnimatedModal';
import style from './style.module.scss';
import Green from './GreenCard.png';
import Gold from './GoldCard.png';
import Platinum from './PlatinumCard.png';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { StorageKeys } from 'phoenix/constants';
import { AllowedAmexCountryCodes, AmexAccountBalanceRanges, AmexSignupUrls } from 'phoenix/constants/Amex';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { AccountSummaryState } from 'phoenix/redux/models';

const InnerModalContent = () => {
    const [hideAmexOfferModal, setHideAmexOfferModal] = useLocalStorage(StorageKeys.HideAmexOfferModal, false);
    const clientAccounts = useSnexStore((s) => s.accounts.all);
    const accountSummaries: AccountSummaryState = useSnexStore((s) => s.accountSummary);

    const accountNumbersInAllowedCountries = useMemo(() => {
        return !clientAccounts.loading && !clientAccounts.pristine && !!clientAccounts.data?.length
            ? new Set(clientAccounts.data.filter((a) => AllowedAmexCountryCodes.has(a.countryCode)).map((a) => a.accountNumber))
            : new Set([]);
    }, [clientAccounts.data, clientAccounts.loading, clientAccounts.pristine]);

    const sortedAccounts = (() => {
        const summaryEntries = Object.values(accountSummaries.byNumber).filter((a) => accountNumbersInAllowedCountries.has(a.data.accountNumber));
        if (summaryEntries.every((a) => !a.loading && !a.pristine))
            return summaryEntries.sort((a, b) => b?.data?.totalAccountValueCurrent - a?.data?.totalAccountValueCurrent);
        else return [];
    })();

    const highestValueAccount = useMemo(() => {
        return sortedAccounts[0] || null;
    }, [sortedAccounts]);

    const cardContent: { imgSrc: string; windowUrl: string } = (() => {
        if (!highestValueAccount) return null;
        const { totalAccountValueCurrent } = highestValueAccount?.data;
        if (AmexAccountBalanceRanges.green.min <= totalAccountValueCurrent && totalAccountValueCurrent <= AmexAccountBalanceRanges.green.max)
            return { imgSrc: Green, windowUrl: AmexSignupUrls.green };
        else if (AmexAccountBalanceRanges.gold.min <= totalAccountValueCurrent && totalAccountValueCurrent <= AmexAccountBalanceRanges.gold.max)
            return { imgSrc: Gold, windowUrl: AmexSignupUrls.gold };
        else if (AmexAccountBalanceRanges.platinum.min <= totalAccountValueCurrent) return { imgSrc: Platinum, windowUrl: AmexSignupUrls.platinum };
    })();

    return (
        <Flex column align='center' className={style.wrapper} justify='center'>
            <Flex center column style={{ width: '100%', position: 'relative', boxSizing: 'border-box', marginBottom: 20, paddingBottom: 50 }}>
                <Typography style={{ color: 'white' }} variant='h3'>
                    Experience American Express with this offer.
                </Typography>
            </Flex>
            <img
                src={cardContent.imgSrc}
                style={{ position: 'relative', width: '90%', border: '2px solid #1c8bed', alignSelf: 'center', borderRadius: 10 }}
                onClick={() => window.open(cardContent.windowUrl, '_blank')}
            />
            <Flex
                row
                align='center'
                justify='flex-end'
                style={{ margin: '20px 0', width: '90%' }}
                onClick={() => {
                    setHideAmexOfferModal(!hideAmexOfferModal);
                }}
            >
                <Checkbox checked={hideAmexOfferModal} style={{ marginRight: 10, height: 20, width: 20, color: 'white' }} />
                <Typography style={{ fontWeight: 400, color: 'rgba(255,255,255,0.7)' }} variant='h6'>
                    Do not show me this offer again
                </Typography>
            </Flex>
        </Flex>
    );
};

export const AmexCardModal = () => {
    const [isOpen, setIsOpen] = useShowAmexCardModal();

    return (
        <AnimatedModal open={isOpen} onClose={() => setIsOpen(false)}>
            <InnerModalContent />
        </AnimatedModal>
    );
};
