import { Typography, Skeleton } from '@mui/material';
import { Flex } from 'components/Flex';
import { useText } from 'phoenix/hooks/UseText';
import React from 'react';
import { MutualFundAssetClass } from 'phoenix/models/AssetClasses/MutualFundAssetClass';

export const MinimumInvestmentDisplay = React.memo(({ minimumInvestment, loading }: { minimumInvestment: number; loading: boolean }) => {
    const text = useText((t) => t.fundScreen.profile);
    const title = text.minimumInitialInvestment;
    const minInvest = MutualFundAssetClass.formatPrice(minimumInvestment);
    return (
        <Flex column>
            <div className='market-price-display'>
                <Typography className='label' variant='h6'>
                    {title}
                </Typography>
                {loading ? (
                    <Skeleton animation='wave' width={60} />
                ) : (
                    <Typography style={{ fontSize: 20, fontWeight: 500 }} variant='h6'>
                        {minInvest}
                    </Typography>
                )}
            </div>
        </Flex>
    );
});
