import { MarketTimeSegment as MarketTimeSegmentType } from '../util'

export const MarketTimeSegments = {
    loading: 'loading' as MarketTimeSegmentType,
    closed: 'closed' as MarketTimeSegmentType,
    open: 'open' as MarketTimeSegmentType,
    postmarket: 'postmarket' as MarketTimeSegmentType,
    premarket: 'premarket' as MarketTimeSegmentType,
    holiday: 'holiday' as MarketTimeSegmentType
}
