// @ts-strict-ignore
import { Flex } from 'components';
import { SnexButton } from 'components/SnexButton';
import { XBackgroundScreen } from 'components/XBackgroundScreen/XBackgroundScreen';
import { Urls } from 'phoenix/constants';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { OnboardingViewModel } from '../../../phoenix/onboarding/OnboardingTypes';
import { OnboardingForm, OnboardingInputRow, OnboardingLoading, OnboardingPageTitle } from './OnboardingControls';

export const OnboardingEngine = ({
    viewModel,
    selectedScreenId,
    setScreenId,
    showDisclosure
}: {
    viewModel?: OnboardingViewModel;
    selectedScreenId: string;
    setScreenId: (value?: any) => void;
    showDisclosure?: boolean;
}): JSX.Element => {
    const currentScreen = viewModel?.screens?.find((s) => s?.id?.toLowerCase() === selectedScreenId?.toLowerCase());
    const navButtonStyle = { maxWidth: 400, padding: '15px', margin: '0' };
    const { addToast } = useToasts();

    const disclosureFooter = (
        <Flex row center fullWidth>
            <em>
                We are committed to protecting your privacy. Read the{' '}
                <Link to={Urls.misc.privacyPolicy()} style={{ color: '#0070E0' }} target='_blank' rel='noreferrer noopener'>
                    StoneX One Privacy Policy
                </Link>{' '}
                to learn about the choices you have for how we collect and/or use your information.
            </em>
        </Flex>
    );

    /* 117430 - add hubspot embeded script */
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hs-scripts.com/2022783.js?businessUnitId=100880';
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.defer = true;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <XBackgroundScreen>
            <div style={{ height: '100vh', width: '100vw' }}>
                {!currentScreen ? (
                    <OnboardingLoading />
                ) : (
                    <OnboardingForm
                        title={viewModel?.headerText}
                        steps={viewModel.steps}
                        handleLogoClick={viewModel?.handleLogoClick}
                        setScreenId={setScreenId}
                        stepsDisabled={viewModel.stepsDisabled ? viewModel.stepsDisabled() : null}
                    >
                        {currentScreen.title ? <OnboardingPageTitle title={currentScreen.title} subtitles={currentScreen.subtitles} /> : null}
                        {currentScreen?.body ? <OnboardingInputRow>{currentScreen?.body}</OnboardingInputRow> : null}
                        <OnboardingInputRow gap={'20px'}>
                            {currentScreen?.buttons?.map((b, bIndex) => (
                                <SnexButton
                                    key={`button_${bIndex}`}
                                    disabled={!b.isEnabled()}
                                    flavor={b.isLoading && b.isLoading() ? 'loading' : b.isPrimary ? 'submit' : 'secondary'}
                                    size={'large'}
                                    style={navButtonStyle}
                                    onClick={() => b?.onClick(addToast)}
                                >
                                    {b.label}
                                </SnexButton>
                            ))}
                        </OnboardingInputRow>
                    </OnboardingForm>
                )}
                {showDisclosure ? disclosureFooter : null}
            </div>
        </XBackgroundScreen>
    );
};
