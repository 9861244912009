import { da } from "date-fns/locale"
import { T } from "phoenix/assets/lang/T"
import { OnboardingInputError, ValidateRequired } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"


export const VerifyEmailIsValid = () => getVerifyEmailErrors().length < 1
export const getVerifyEmailErrors = () : OnboardingInputError[] => {
    const keys = ['verificationCode']
    const data = useRegistrationStore.getState()
    const _errors = []  as OnboardingInputError[]
    
    keys.forEach(key => {
        switch(key) {
            case 'verificationCode':
                _errors.push(ValidateRequired(data, key))
                if(data.codeIsVerified === false)
                    _errors.push({key: 'verificationCode', error: 'Invalid code'})
                break
    }})

    return _errors.filter(e => !!e);
}