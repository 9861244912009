// @ts-strict-ignore
import axios from "axios"
import { Urls } from "phoenix/constants"
import { WeatherForecast, WeatherSystemOfMeasurement } from "phoenix/stores/weather/WeatherForecast"
import { SnexAxios } from "../AxiosHelpers"
import { createClearable as create } from 'phoenix/stores/ZustandStore'

export enum WeatherForecastType {
     current,
     hourly,
     daily
}

export type WeatherLocation = {
    latitude: number,
    longitude: number,
    city: string,
    state: string,
}

export type WeatherForecastState = {
    pristine: boolean,
    loading: boolean,
    error: boolean,
    currentView: WeatherForecastType,
    subView?: string,
    selectedHourlyChart: WeatherForecastHourlyChart,
    location?: WeatherLocation,
    systemOfMeasurement: WeatherSystemOfMeasurement,
    current?: WeatherForecast,
    hourly?: WeatherForecast[],
    daily?: WeatherForecast[],
    getForecasts: () => void,
    getLocationByLatLong: (latitude: number, longitude: number) => void,
    getLocationBySearch: (value: string) => void,
    setLocation: (location: WeatherLocation) => void,
    setCurrentView: (view: WeatherForecastType, subView?: string) => void
    setLoading: (val: boolean) => void
    setSelectedHourlyChart: (chart: WeatherForecastHourlyChart) => void
}

export enum WeatherForecastHourlyChart {
    temperature,
    rainAmount,
    rainChance,
    windGust,
    visibility,
}

const defaultWeatherForecastState = {
    pristine: true,
    loading: false,
    error: false,
    currentView: WeatherForecastType.current,
    subView: undefined,
    selectedHourlyChart: WeatherForecastHourlyChart.temperature,
    systemOfMeasurement: 'F' as WeatherSystemOfMeasurement,
    location: undefined,
    current: undefined,
    hourly: undefined,
    daily: undefined
}

const GetForecastFromApi = (forecastType: WeatherForecastType, latitude: number, longitude: number)  => 
    SnexAxios.ApiGet<WeatherForecast | WeatherForecast[]>(Urls.weather.getForecast(forecastType, latitude, longitude))
    .withMutex(`weather-forcast-${forecastType}`)
    .run()


export const useWeatherForecastStore = create()<WeatherForecastState>((set, get) => ({

    ...defaultWeatherForecastState,
    getForecasts: () => {
        const currentPromise = GetForecastFromApi(WeatherForecastType.current, get().location.latitude, get().location.longitude)
        const hourlyPromise = GetForecastFromApi(WeatherForecastType.hourly, get().location.latitude, get().location.longitude)
        const dailyPromise = GetForecastFromApi(WeatherForecastType.daily, get().location.latitude, get().location.longitude)

        Promise.allSettled([currentPromise, hourlyPromise, dailyPromise])
        .then(responses => {
            get().setLoading(false)
            const isError = responses.some(r => r.status === 'rejected')
            const results = responses.map(r => r.status === 'fulfilled'? r.value: undefined)
            set((s) => ({...s, 
                pristine: false, 
                loading: false, 
                error: isError, 
                current: isError? undefined: results[0] as WeatherForecast,
                hourly: isError? undefined: results[1] as WeatherForecast[],
                daily: isError? undefined: results[2] as WeatherForecast[],
            }))
        })
        .finally(() =>
            set((s) => ({...s, loading: false}))
        )

        
    },
    getLocationByLatLong: (latitude: number, longitude: number) => {
        get().setLoading(true)
        const geocodePromise = SnexAxios.ApiGet<string>(Urls.geocode.getByLatLong(latitude, longitude))
        Promise.allSettled([geocodePromise])
            .then(responses => {
                const isError = responses.some(r => r.status === 'rejected')
                const result = responses[0].status === 'fulfilled'? responses[0].value[0] : undefined
                set((s) => ({...s, 
                    pristine: false, 
                    loading: false, 
                    error: isError, 
                    location: isError || !result?.latitude || !result?.longitude ? undefined: {
                        latitude: result?.latitude,
                        longitude: result?.longitude,
                        city: result?.city,
                        state: result?.state
                    }
                }))
            })
            .finally(() =>
            set((s) => ({...s, loading: false}))
        )


    },
    getLocationBySearch: (value: string) => {
        get().setLoading(true)
        const geocodePromise = SnexAxios.ApiGet<string>(Urls.geocode.getByAddress(value))
        Promise.allSettled([geocodePromise])
            .then(responses => {
                 const isError = responses.some(r => r.status === 'rejected')
                const result = responses[0].status === 'fulfilled'? responses[0].value[0] : undefined
                set((s) => ({...s, 
                    pristine: false, 
                    loading: false, 
                    error: isError, 
                    location: isError || !result?.latitude || !result?.longitude ? undefined: {
                        latitude: result?.latitude,
                        longitude: result?.longitude,
                        city: result?.city,
                        state: result?.state
                    }
                }))
            })
            .finally(() =>
            set((s) => ({...s, loading: false}))
        )
    },
    setLocation: (location: WeatherLocation) => set((s) => ({...s, location})),
    setCurrentView: (view: WeatherForecastType, subView?: string) => set((s) => ({...s, currentView: view, subView: subView || 'table'})),
    setLoading: (val: boolean) => {
        if(get().loading) return;
        set((s) => ({...s, loading: val}))
    },
    setSelectedHourlyChart: (chart: WeatherForecastHourlyChart) => set((s) => ({...s, selectedHourlyChart: chart}))
}))