import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSecurityTypeFromStore, useSecurityTypeFromStoreV2 } from 'phoenix/hooks/UseSecurityType';
import { AdrAssetClass } from 'phoenix/models/AssetClasses/AdrAssetClass';
import { AssetClass, AssetFamily, DerivativeType } from 'phoenix/models/AssetClasses/AssetClass';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { EquityOptionsAssetClass } from 'phoenix/models/AssetClasses/EquityOptionsAssetClass';
import { EtfAssetClass } from 'phoenix/models/AssetClasses/EtfAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { FuturesOptionsAssetClass } from 'phoenix/models/AssetClasses/FuturesOptionsAssetClass';
import { MutualFundAssetClass } from 'phoenix/models/AssetClasses/MutualFundAssetClass';
import { GetSecurityMetadataAction } from 'phoenix/redux/actions';
import { OptionSymbol, TradeableSecurityType } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { GetSecurityTypeFromMetadata, GetSecurityTypeFromMetadataV2, GetSecurityTypeFromStore, GetSecurityTypeFromStoreV2 } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import FuturesTimeSpreadsAssetClass from './FuturesTimeSpreadsAssetClass';
import { GetColors } from 'phoenix/theming/Colors';
import { ThemeVariant } from 'phoenix/theming/ThemeVariants';
import { SecurityMetadataV2, useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

export interface UseAssetClassOptions {
    fetchMetadata?: boolean;
}

export const AssetClasses = [
    AdrAssetClass,
    CryptosAssetClass,
    FuturesAssetClass,
    FuturesOptionsAssetClass,
    EquitiesAssetClass,
    EquityOptionsAssetClass,
    EtfAssetClass,
    MutualFundAssetClass
];

/** @deprecated useAssetClassMetaV2 for SecurityMetadataV2 */
export const useAssetClass = (qsi: string, options?: UseAssetClassOptions): AssetClass => {
    const dispatch = useDispatch();

    const secType: TradeableSecurityType = useSecurityTypeFromStore(qsi);
    useEffect(() => {
        if (secType === 'loading' && options?.fetchMetadata) dispatch(GetSecurityMetadataAction(qsi));
    }, [secType, qsi, options, dispatch]);

    return useMemo(() => GetAssetClassByName(secType), [secType]);
};

export const useAssetClassMetaV2 = (qsi: string, options?: UseAssetClassOptions): AssetClass => {
    const { loadData } = useSecurityMetadataV2();

    const secType: TradeableSecurityType = useSecurityTypeFromStoreV2(qsi);
    useEffect(() => {
        if (secType === 'loading' && options?.fetchMetadata) {
            loadData(qsi);
        }
    }, [secType, qsi, options, loadData]);

    return useMemo(() => GetAssetClassByName(secType), [secType]);
};

export const useAssetClassByQsi = (qsi: string, options?: UseAssetClassOptions): AssetClass => {
    const dispatch = useDispatch();

    const secType: TradeableSecurityType = useSecurityTypeFromStore(qsi);
    useEffect(() => {
        if (secType === 'loading' && options?.fetchMetadata) dispatch(GetSecurityMetadataAction(qsi));
    }, [secType, qsi, options, dispatch]);

    return useMemo(() => GetAssetClassByName(secType), [secType]);
};

export const useAssetClassV2 = (
    findAssetClassBy?: { qsi?: string; accountNumber?: string; secType?: TradeableSecurityType },
    options?: UseAssetClassOptions
): AssetClass => {
    const { qsi = '', accountNumber, secType } = findAssetClassBy || {};

    let assetClass;
    if (accountNumber) assetClass = useAccountAssetClass(accountNumber);
    else if (secType) assetClass = useAssetClassByName(secType);
    else assetClass = useAssetClassByQsi(qsi, options);
    return assetClass;
};

export const useAccountAssetClass = (accountNumber: string): AssetClass => {
    return useMemo(() => {
        const c = QualifiedId.Class(accountNumber);
        switch (c) {
            case 'crypto':
                return CryptosAssetClass;
            case 'futures':
                return FuturesAssetClass;
            case 'equities':
            default:
                return EquitiesAssetClass;
        }
    }, [accountNumber]);
};

// non-reactive veresion
export const getAccountAssetClass = (accountNumber: string): AssetClass => {
    const c = QualifiedId.Class(accountNumber);
    switch (c) {
        case 'crypto':
            return CryptosAssetClass;
        case 'futures':
            return FuturesAssetClass;
        case 'equities':
        default:
            return EquitiesAssetClass;
    }
};

export const useAssetClassByName = (type: TradeableSecurityType): AssetClass => {
    return useMemo(() => GetAssetClassByName(type), [type]);
};

export const GetAssetClassForSecurity = (qsi: string): AssetClass => {
    let secType: TradeableSecurityType = GetSecurityTypeFromStore(qsi);
    if (FuturesSymbol.IsFuturesSymbol(qsi) && OptionSymbol.IsOptionSymbol(qsi)) secType = 'futures-option';
    return GetAssetClassByName(secType);
};

export const GetAssetClassForSecurityV2 = (qsi: string): AssetClass => {
    let secType: TradeableSecurityType = GetSecurityTypeFromStoreV2(qsi);
    if (FuturesSymbol.IsFuturesSymbol(qsi) && OptionSymbol.IsOptionSymbol(qsi)) secType = 'futures-option';
    return GetAssetClassByName(secType);
};

// Does not require store context
/** @deprecated Use GetAssetClassFromMetadata for SecurityMetadataV2 */
export const GetAssetClassFromMetadata = (meta: SecurityMetadata): AssetClass => {
    const secType = GetSecurityTypeFromMetadata(meta);

    return GetAssetClassByName(secType);
};

export const GetAssetClassFromMetadataV2 = (meta: SecurityMetadataV2): AssetClass => {
    const secType = GetSecurityTypeFromMetadataV2(meta);

    return GetAssetClassByName(secType);
};

export const GetAssetClassByName = (name: TradeableSecurityType): AssetClass => {
    switch (name) {
        case 'adr':
            return AdrAssetClass;
        case 'crypto':
            return CryptosAssetClass;
        case 'future':
            return FuturesAssetClass;
        case 'futures-option':
            return FuturesOptionsAssetClass;
        case 'futures-time-spread':
            return FuturesTimeSpreadsAssetClass;
        case 'option':
            return EquityOptionsAssetClass;
        case 'etf':
            return EtfAssetClass;
        case 'mutual-fund':
            return MutualFundAssetClass;
        case 'equity':
        default:
            return EquitiesAssetClass;
    }
};

export const GetAssetClassByFamilyAndDerivative = (family: AssetFamily, deriv: DerivativeType): AssetClass => {
    switch (family) {
        case 'equities':
            if (deriv === 'option') return EquityOptionsAssetClass;
            else return EquitiesAssetClass;
        case 'futures':
            if (deriv === 'option') return FuturesOptionsAssetClass;
            else if (deriv === 'time-spread') return FuturesTimeSpreadsAssetClass;
            else return FuturesAssetClass;
        case 'cryptos':
            return CryptosAssetClass;
        default:
            return EquitiesAssetClass;
    }
};

export interface AssetClassColors {
    primaryColor?: string;
    backgroundColor?: string;
}

export const useAssetClassColors = (assetClass: AssetClass, appTheme?: ThemeVariant): AssetClassColors => {
    const colors = GetColors(appTheme);
    switch (assetClass.family) {
        case 'futures':
            return { primaryColor: colors.futuresColor, backgroundColor: colors.futuresBackdropColor };
        case 'cryptos':
            return { primaryColor: colors.cryptosColor, backgroundColor: colors.cryptosLighterColor };
        case 'equities':
            return { primaryColor: colors.equitiesColor, backgroundColor: colors.equitiesBackdropColor };
        default:
            return { primaryColor: undefined, backgroundColor: undefined };
    }
};
