// @ts-strict-ignore
import { FeatureFlags } from "phoenix/constants/FeatureFlags"
import { useFeatureFlag } from "phoenix/hooks/UseFeatureFlag"
import { GetIgnoreFPLMinimumBuyingPower } from "phoenix/util"
import { QualifiedId } from "phoenix/util/QualifiedId"
import { useState, useMemo } from "react"
import { SnexAccountOption } from "phoenix/hooks/useAccounts"
import { useSnexStore } from "./UseSnexStore"
import { useDispatch } from "react-redux"
import { useAsyncEffect } from "./UseAsyncEffect"
import { GetAccountSummaryAction } from "phoenix/redux/actions"
import { FPLStatus } from "phoenix/redux/models/FullyPaidLending/FplStatus"


export const useFullyPaidLending = (accountNumber?: string, mobileFlag?: boolean, withRequests?: boolean, forceHideBanner?: boolean): {
    fullyPaidLendingFeatureFlag: boolean,
    isEquitiesAndHasMinimumBuyingPower: boolean,
    fullyPaidLendingEligibleAccounts: SnexAccountOption[],
    showFPLBanner: boolean,
    showApplyForFPLLink: boolean,
    isLoading: boolean,
    fplStatus?: FPLStatus,
    fplSubmittedDate?: Date,
    hasRequiredMargin?: boolean,
    updateFplSubmittedStatus?: () => void /* do not use. adding to fix issue with typecheck */
} => {
    const [accountsFetched, setAccountsFetched] =  useState<boolean>(false); 

    const dispatch = useDispatch()
    useAsyncEffect(async () => {
        if (summaries.some(s => !s || s?.pristine)) {
            const tasks = allEquityAccounts?.map(a => dispatch(GetAccountSummaryAction(a.accountNumber)))
            await Promise.allSettled(tasks);
            setAccountsFetched(true);
        }
    }, [dispatch])


    const minimumBuyingPower = GetIgnoreFPLMinimumBuyingPower() === true ? 1 : 50000
    const allAccounts = useSnexStore(s => s.accounts.all)
    const allEquityAccounts = useMemo(() => allAccounts?.data?.filter(a => QualifiedId.Family(a.accountNumber) === 'equities'), [allAccounts])

    // Simplified selector for the useSnexStore without a map within it helps us manage how often the 'summaries' reference changes.
    // This change fixed a constant re-render we were seeing for any component using this hook.
    const accountSummaries = useSnexStore(s => s.accountSummary)
    const summaries = useMemo(() => allEquityAccounts.map(x => accountSummaries.byNumber[x.accountNumber]), [allEquityAccounts?.length, accountSummaries, accountsFetched])

    const fullyPaidLendingEligibleAccountNumbers = useMemo(() => summaries?.reduce((final, current) => {
        // If account number was specified to the hook, exclude non matching numbers
        if (accountNumber && current?.data?.accountNumber !== accountNumber) return final
        // Does account have minBP? If so, add to the list of eligible accounts
        if (current?.data?.totalAccountValueCurrent >= minimumBuyingPower) return [...final, { accountNumber: current?.data?.accountNumber, totalAccountValueCurrent: current?.data?.totalAccountValueCurrent }]
        return final
    }, []), [minimumBuyingPower, summaries])


    const fullyPaidLendingEligibleAccounts = useMemo(() =>
        fullyPaidLendingEligibleAccountNumbers?.length ?
            allEquityAccounts?.filter(a => fullyPaidLendingEligibleAccountNumbers.some(x => x.accountNumber === a.accountNumber)) : [],
        [fullyPaidLendingEligibleAccountNumbers, allEquityAccounts])

    // FPL Accounts with margin
    const fplAccountsMeetingMarginReq = useMemo(() => {
        return fullyPaidLendingEligibleAccounts?.length ? fullyPaidLendingEligibleAccounts?.filter(a => {
            if (accountNumber && a?.accountNumber !== accountNumber) return false
            return a.marginAgreementOnFile === 'YES'
        }) : []
    }, [fullyPaidLendingEligibleAccountNumbers, allEquityAccounts])

    const selectedAccount = useMemo(() => fullyPaidLendingEligibleAccounts.find(a => a.accountNumber === accountNumber), [fullyPaidLendingEligibleAccounts, accountNumber])

    const fplStatusesList = fullyPaidLendingEligibleAccounts.map(a => ({
        [a.accountNumber]: {
            status: a.fplStatus,
            hasSubmitted: a.fplStatus === 'Approved' || a.fplStatus === 'Submitted'
        }
    }))
    const anySubmitted = fplStatusesList.some(a => !!Object.values(a)[0].hasSubmitted)
    const fullyPaidLendingFeatureFlag = useFeatureFlag(mobileFlag ? FeatureFlags.Mobile.MobileFullyPaidLending : FeatureFlags.fullyPaidLending)
    const hasSubmittedFpl = useMemo(() => {

        // // left in for debugging purposes in the future
        // console.log({fplStatuses, fplStatusesList, accountNumber, fplStatus: selectedAccount?.fplStatus, anySubmitted, fullyPaidLendingEligibleAccounts })
        return anySubmitted

    }, [anySubmitted])

    const { fplStatus, fplSubmittedDate } = useMemo((): { fplStatus: FPLStatus, fplSubmittedDate?: Date } => {
        if (!accountNumber) {
            if (fullyPaidLendingEligibleAccounts?.some(x => x?.fplStatus === 'Approved')) return { fplStatus: 'Approved' }
            if (fullyPaidLendingEligibleAccounts?.some(x => x?.fplStatus === 'Submitted')) return { fplStatus: 'Submitted' }
            return { fplStatus: 'None' }
        }

        return { fplStatus: selectedAccount?.fplStatus, fplSubmittedDate: selectedAccount?.fplSubmissionDate }
    }, [accountNumber, selectedAccount, fullyPaidLendingEligibleAccounts])


    const isLoading = useMemo(() => allAccounts?.loading || summaries.some(s => s?.loading), [allAccounts, summaries])

    const hasEquityAccount = useMemo(() => {
        if (accountNumber) return QualifiedId.Family(accountNumber) === 'equities'
        return !!allEquityAccounts?.length
    }, [accountNumber, allEquityAccounts])

    const hasMinimumBuyingPower = useMemo(() => {
        if (!hasEquityAccount) return false;
        if (accountNumber) {
            return fullyPaidLendingEligibleAccounts.some(a => a.accountNumber === accountNumber)
        }
        return !!fullyPaidLendingEligibleAccounts?.length
    }, [accountNumber, hasEquityAccount, fullyPaidLendingEligibleAccounts])


    const isEquitiesAndHasMinimumBuyingPower = useMemo(() => hasEquityAccount && hasMinimumBuyingPower, [hasEquityAccount, hasMinimumBuyingPower])
    const showFPLBanner = useMemo(() => fullyPaidLendingFeatureFlag && isEquitiesAndHasMinimumBuyingPower && !forceHideBanner && !hasSubmittedFpl,
        [fullyPaidLendingFeatureFlag, isEquitiesAndHasMinimumBuyingPower, forceHideBanner, hasSubmittedFpl])

    const showApplyForFPLLink = useMemo(() => !!fullyPaidLendingFeatureFlag && !!isEquitiesAndHasMinimumBuyingPower && !hasSubmittedFpl,
        [fullyPaidLendingFeatureFlag, isEquitiesAndHasMinimumBuyingPower, hasSubmittedFpl])
    
        // From my understanding, we show everyone with 50K+ the banner, but only those who have margin account will be able to actually submit an application
    const hasRequiredMargin: boolean = useMemo(() => !!fplAccountsMeetingMarginReq?.length, [fplAccountsMeetingMarginReq])

    // // left in for debugging purposes in the future
    // useEffect(() => {
    //     console.log("Values for modal stuff: ", JSON.stringify({ accountNumber, selectedAccountStatus: selectedAccount?.fplStatus, hasSubmittedFpl, fullyPaidLendingEligibleAccountNumbers, hasEquityAccount, hasMinimumBuyingPower, minimumBuyingPower, isEquitiesAndHasMinimumBuyingPower, showFPLBanner, showApplyForFPLLink, fullyPaidLendingFeatureFlag, accountNumber }, null, 2))
    // }, [selectedAccount, hasSubmittedFpl, fullyPaidLendingEligibleAccountNumbers, hasEquityAccount, hasMinimumBuyingPower, isEquitiesAndHasMinimumBuyingPower, showFPLBanner, showApplyForFPLLink, fullyPaidLendingFeatureFlag, minimumBuyingPower, accountNumber])

    return {
        fullyPaidLendingFeatureFlag,
        isEquitiesAndHasMinimumBuyingPower,
        showFPLBanner,
        showApplyForFPLLink,
        fullyPaidLendingEligibleAccounts,
        isLoading,
        fplStatus,
        fplSubmittedDate,
        hasRequiredMargin
     }
}