// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1GzifvgYlGyLP2lremvL-3{align-self:center;padding:8px 16px !important;cursor:pointer !important;margin:8px !important;font-size:15px !important}._1GzifvgYlGyLP2lremvL-3 :hover{text-decoration:none !important}.J0-sZygMlfOiAXSAH6bBY:hover{text-decoration:none !important}", ""]);
// Exports
exports.locals = {
	"link": "_1GzifvgYlGyLP2lremvL-3",
	"linkk": "J0-sZygMlfOiAXSAH6bBY"
};
module.exports = exports;
