import { Flex } from 'components';
import React from 'react';
import { useFullyPaidLendingStore } from '../../Store';
import { AgreementRow, OnboardingAgreementsSections } from 'screens/OnboardingScreens/Base/OnboardingControls';
import { useText } from 'phoenix/hooks/UseText';
import { T } from 'phoenix/assets/lang/T';

export const FPL_Agreements_Form = (): JSX.Element => {
    const { getValue, setValue } = useFullyPaidLendingStore();
    const fplAgreements = useText((t) => t.fplOnboarding.agreements);

    const agreementRows: AgreementRow[] = [
        {
            label: fplAgreements.loanAgreementPDFTitle,
            field: 'data.acceptedLoanAgreement',
            docs: [{ text: fplAgreements.loanAgreementText(), url: T((t) => t.fplOnboarding.agreements.loanAgreementPDFUrl) }]
        },
        {
            label: fplAgreements.fplAccountControlAgreementPDFTitle,
            field: 'data.acceptedAccountControlAgreement',
            docs: [{ text: fplAgreements.fplAccountControlAgreementText(), url: T((t) => t.fplOnboarding.agreements.fplAccountControlAgreementPDFUrl) }]
        },
        { label: fplAgreements.axosAcknowledgementText, field: 'data.axosAcknowledgement', docs: [] }
    ];

    return (
        <Flex column fullWidth center style={{ minWidth: '450px' }}>
            <Flex column style={{ marginBottom: '1rem' }}>
                <OnboardingAgreementsSections agreementRows={agreementRows} getter={getValue} setter={setValue} />
            </Flex>
        </Flex>
    );
};
