import { Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import React, { useMemo } from 'react';

interface PaddedLabelProps {
    label: string;
    style?: React.CSSProperties;
    color?: string;
    backgroundColor?: string;
    size?: 'small' | 'large';
    textAlign?: 'left' | 'center' | 'right';
}

export const PaddedLabel = React.memo(function PaddedLabelUnmemoized(props: PaddedLabelProps) {
    const { label, style, color, backgroundColor, size, textAlign } = props;
    const { primaryItemColor, primaryItemBackdropColor } = useColors();

    const textColor = color || primaryItemColor;
    const bgColor = backgroundColor || primaryItemBackdropColor;

    const prebakedStyles: React.CSSProperties = useMemo(
        function getStyles() {
            switch (size) {
                case 'small':
                    return { padding: 0, textTransform: 'capitalize', color: textColor, fontSize: 11, minWidth: 60, textAlign: textAlign || 'center' };
                default:
                    return {
                        padding: '3px 12px',
                        color: textColor,
                        backgroundColor: bgColor,
                        textTransform: 'capitalize',
                        fontSize: 13,
                        borderRadius: 4,
                        minWidth: 70,
                        textAlign: textAlign || 'center'
                    };
            }
        },
        [bgColor, size, textAlign, textColor]
    );

    return (
        <Typography style={{ ...prebakedStyles, ...style }} variant='h6'>
            {label}
        </Typography>
    );
});
