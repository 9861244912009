import React from 'react';
import { Flex } from '../..';
import style from '../style.module.scss';

export const SecurityNameSection = React.memo((props: { children: any }) => {
    return (
        <Flex column className={style.securityNameColumn} style={{ cursor: 'pointer' }}>
            {props.children}
        </Flex>
    );
});