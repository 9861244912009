const CapitalizeFirstOnly = (src: string) => src ? src[0]?.toUpperCase() + src.slice(1)?.toLowerCase() : ''
const LowercaseFirstOnly = (src: string) => src ? src[0]?.toLowerCase() + src?.slice(1) : ''

const ChangeCamelCaseToSentenceCase = (src: string) =>
    src ? src.charAt(0).toUpperCase() + src.slice(1).replace(/([A-Z])/g, ' $1') : ''

const ChangeCaseToTitleCase = (src: string) =>
    src?.toLowerCase().replace(/\s+/g, ' ').split(/(\s+|\.|,|!|\?)/)
        .map(word => word?.replace(word[0], word[0]?.toUpperCase() || ''))
        .filter(x => !!x)
        .join('')

const ChangeCamelCaseToSpaceSeparated = (string?: string) => {
    return string?.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
}

const RemoveExtraSpace = (string: string) => {
    if (typeof string === 'string') return string.replace(/\s+/g, ' ')
}

const MaskNumber = (string: string, toShow = 4) => {
    if (string.length <= toShow) return '****'
    return `****${string.substring(string.length - toShow)}`
}

const OrDashes = (v: string) => (!v || !v.replace(/\s+/g, '') || v === '0') ? '---' : v

const Replicate = (s: string, times: number) => new Array(times).fill(s).join('')

export const FormatString = {
    CapitalizeFirstOnly,
    ChangeCamelCaseToSentenceCase,
    ChangeCaseToTitleCase,
    ChangeCamelCaseToSpaceSeparated,
    LowercaseFirstOnly,
    OrDashes,
    RemoveExtraSpace,
    MaskNumber,
    Replicate
}
