import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppRoot } from './AppRoot';
import * as Sentry from '@sentry/react';

ErrorLogger.Init(Sentry, 'web');

document.addEventListener(
    'DOMContentLoaded',
    () => {
        // logging boundary errors to Sentry
        const el = document.getElementById('root');
        if (el === null) {
            throw new Error('Root element not found');
        }
        const root = createRoot(el);
        root.render(
            <ErrorBoundary rootLevel>
                <AppRoot />
            </ErrorBoundary>
        );
    },
    { passive: true }
);
