// @ts-strict-ignore
import React from 'react';
import { Flex } from '../..';
import { T } from 'phoenix/assets/lang/T';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { BigTradeButton, BuyingPowerDisplayV2, TotalCostDisplay } from '../Shared/TradeFormComponents';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { ReinvestmentOptions, ReinvestmentOptionsControls } from './ReinvestmentOptionControls';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { MutualFundTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { UseSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';

export const ReinvestmentOptionsPage = () => {
    const oneClickTrading = useSnexStore((s) => s.user.myInfo?.data?.oneClickTrading);
    const { reinvestDividends, reinvestLongTermGains, reinvestShortTermGains, setViewModel, symbol, tradeAction, submitTrade } =
        useTradeTicketViewModel<MutualFundTradeTicketViewModel>();
        const [selectedAccountNumber] = UseSelectedAccount();
    const text = useText((t) => t.tradeTicket.input);
    const logo = useSnexStore((s) => s.logos.bySymbol[symbol]);

    const getOpts = () => {
        return {
            dividends: reinvestDividends || false,
            shortTermGains: reinvestShortTermGains || false,
            longTermGains: reinvestLongTermGains || false
        };
    };

    const handleSubmit = () => {
        if (oneClickTrading) {
            submitTrade();
            return;
        }

        setViewModel({ state: 'review' });
    };

    const handleTradeOptsUpdate = (newOpts: ReinvestmentOptions) => {
        setViewModel({
            reinvestDividends: newOpts?.dividends || false,
            reinvestLongTermGains: newOpts?.longTermGains || false,
            reinvestShortTermGains: newOpts?.shortTermGains || false
        });
    };

    const buttonText = oneClickTrading ? text.tradeNow : T((s) => s.tradeTicket.reinvestmentOptions.review);

    return (
        <Flex column>
            <TradeCancelHeader logo={logo} text={text} symbol={symbol} title={symbol} onCancel={() => setViewModel({ state: 'input' })} />
            <TradeTicketSection padBottom padTop>
                <ReinvestmentOptionsControls isFund accountNumber={selectedAccountNumber} options={getOpts()} symbol={symbol} onOptionsChange={handleTradeOptsUpdate} />
                <TotalCostDisplay />
                <BigTradeButton onClick={handleSubmit}>{buttonText}</BigTradeButton>
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
};
