import {
    CreditDebit as CreditDebitType,
    OptionsOpenClose as OptionsOpenCloseType,
    OrderType,
    TradeAction,
    OptionsPutCall as OptionsPutCallType,
    OptionsPutCallAbbreviation as OptionsPutCallAbbreviationType,
    TradeableSecurityType,
    TradeTicketState
} from '../redux/models';

export const OptionsOpenClose = {
    Close: 'Close' as OptionsOpenCloseType,
    Loading: 'Loading' as OptionsOpenCloseType,
    Open: 'Open' as OptionsOpenCloseType
};

export const optionsPutCall = {
    Put: 'put' as OptionsPutCallType,
    Call: 'call' as OptionsPutCallType
};

export const optionsPutCallAbbreviation = {
    Put: 'P' as OptionsPutCallAbbreviationType,
    Call: 'C' as OptionsPutCallAbbreviationType
};

export const OrderTypes: Record<string, OrderType> = {
    limit: 'limit',
    market: 'market',
    stop: 'stop',
    stoplimit: 'stoplimit'
};

export const TradeActions = {
    Buy: 'Buy' as TradeAction,
    Sell: 'Sell' as TradeAction
};

export const CreditDebit = {
    Credit: 'Credit' as CreditDebitType,
    Debit: 'Debit' as CreditDebitType,
    Even: 'Even' as CreditDebitType
};

export const TradeableSecurityTypes = {
    adr: 'adr' as TradeableSecurityType,
    crypto: 'crypto' as TradeableSecurityType,
    equity: 'equity' as TradeableSecurityType,
    etf: 'etf' as TradeableSecurityType,
    future: 'future' as TradeableSecurityType,
    'mutual-fund': 'mutual-fund' as TradeableSecurityType,
    option: 'option' as TradeableSecurityType,
    unknown: 'unknown' as TradeableSecurityType
};

export const TradeTicketStates = {
    input: 'input' as TradeTicketState,
    review: 'review' as TradeTicketState,
    submitting: 'submitting' as TradeTicketState,
    done: 'done' as TradeTicketState
}
