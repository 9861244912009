import { Actions, ReduxApiGet, ReduxApiPut, ReduxApiResponse } from '.';
import { Urls } from '../../constants';
import { UpdateReinvestmentOptionsRequest } from '../models/Funds/UpdateReinvestmentOptionsRequest';

export const GetFundHoldingsAction = (qsi: string) => ReduxApiGet(Urls.funds.getHoldings(qsi), Actions.Funds.GetHoldings).withLoading().withSubject(qsi).run();

export const GetFundProfileAction = (qsi: string) =>
    ReduxApiGet(Urls.funds.getProfile(qsi), Actions.Funds.GetProfile)
        .useStored((s) => s.funds.byQsi[qsi]?.profile?.data)
        .withLoading()
        .withSubject(qsi)
        .run();

export const GetFundSectorExposure = (qsi: string) =>
    ReduxApiGet(Urls.funds.getSectorExposure(qsi), Actions.Funds.GetSectorExposure).withLoading().withSubject(qsi).run();

export const GetFundCountryExposureAction = (qsi: string) =>
    ReduxApiGet(Urls.funds.getCountryExposure(qsi), Actions.Funds.GetCountryExposure)
        // .useStored(s => s.funds.byQsi[qsi]?.countryExposure?.data)
        .withLoading()
        .withSubject(qsi)
        .run();

export const GetFundReinvestmentPreferences = (qsi: string, accountNumber: string): ReduxApiResponse<unknown> =>
    ReduxApiGet<unknown, unknown, { qsi: string; accountNumber: string }>(
        Urls.funds.getReinvestmentPreferences(qsi, accountNumber),
        Actions.Funds.GetReinvestmentPreferences
    )
        // .useStoredExotic(s => s.funds.byQsi[qsi]?.reinvestmentPreferences?.[accountNumber]?.data)
        .withLoading()
        .withSubject({ qsi, accountNumber })
        .run();

export const SetFundReinvestmentPreferences = (qsi: string, accountNumber: string, options: UpdateReinvestmentOptionsRequest): ReduxApiResponse<unknown> =>
    ReduxApiPut<unknown, unknown, { qsi: string; accountNumber: string }>(
        Urls.funds.setReinvestmentPreferences(qsi, accountNumber),
        Actions.Funds.SetReinvestmentPreferences,
        options
    )
        .withLoading()
        .withSubject({ qsi, accountNumber })
        .run();
