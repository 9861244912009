// @ts-strict-ignore
import { IconButton } from '@mui/material';
import { Flex } from 'components/Flex';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAppWindowSize } from 'hooks/UseWindowSize';
import { useColors } from 'hooks/UseColors';

export const HorizScrollContainer = ({
    showDebug,
    children,
    style,
    variant = 'normal'
}: {
    showDebug?: boolean;
    children: ReactNode;
    style?: React.CSSProperties;
    variant?: 'normal' | 'transparent';
}): JSX.Element => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);

    const colors = useColors();

    const [showLeftButton, setShowLeftButton] = useState<boolean>();
    const [showRightButton, setShowRightButton] = useState<boolean>();

    const [appWindowSize] = useAppWindowSize();

    useEffect(() => {
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [appWindowSize]);

    const maxScrollPos = useCallback(() => {
        const diff = canvasRef?.current?.offsetWidth - containerRef?.current?.offsetWidth;
        return diff <= 0 ? 0 : diff;
    }, [containerRef, canvasRef]);

    const showLeftScrollButton = useCallback(() => {
        if ((showLeftButton || showRightButton) && containerRef?.current?.offsetWidth >= canvasRef?.current?.offsetWidth - 80) return false;
        if (containerRef?.current?.offsetWidth >= canvasRef?.current?.offsetWidth) return false;
        const scrollPosLeft = containerRef?.current?.scrollLeft;
        if (maxScrollPos() === 0) return false;
        if (scrollPosLeft === 0 || scrollPosLeft === 1) return false;
        return true;
    }, [maxScrollPos, showLeftButton, showRightButton]);

    const showRightScrollButton = useCallback(() => {
        if ((showLeftButton || showRightButton) && containerRef?.current?.offsetWidth >= canvasRef?.current?.offsetWidth - 80) return false;
        if (containerRef?.current?.offsetWidth >= canvasRef?.current?.offsetWidth) return false;
        const scrollPosLeft = containerRef?.current?.scrollLeft;
        const maxPos = maxScrollPos();
        if (maxPos === 0) return false;
        if (scrollPosLeft >= maxPos - 3) return false;
        return true;
    }, [maxScrollPos, showLeftButton, showRightButton]);

    const doScroll = (direction: 'left' | 'right') => {
        const _containerRef = containerRef?.current;
        const scrollUnitPx = Math.floor(containerRef?.current?.offsetWidth / 2);
        const multiplier = direction === 'left' ? -1 : 1;
        let newLeftPos = _containerRef?.scrollLeft + scrollUnitPx * multiplier;
        if (newLeftPos > maxScrollPos()) newLeftPos = maxScrollPos();
        if (newLeftPos < 2) newLeftPos = 0;
        _containerRef?.scroll({ left: newLeftPos, behavior: 'smooth' });
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    };

    const Scrollbar = ({ flavor }: { flavor: 'left' | 'right' }) => {
        if (!showLeftButton && !showRightButton) return null;

        const ArrowIcon = flavor === 'left' ? ArrowBackIosIcon : ArrowForwardIosIcon;
        const marginStyle: React.CSSProperties = flavor === 'left' ? { marginRight: 8 } : { marginLeft: 8 };
        const absoluteStyle: React.CSSProperties = {
            position: 'absolute',
            top: '50%',
            left: flavor === 'left' ? 8 : undefined,
            right: flavor === 'right' ? 8 : undefined,
            background: colors.cardBackgroundColor,
            borderRadius: 9999,
            boxShadow: `0px 0px 11px 3px ${colors.boxShadow}`
        };
        const showButton = flavor === 'left' ? showLeftButton : showRightButton;

        if (variant === 'transparent') {
            return (
                <Flex row style={{ width: 40, minWidth: 40, maxWidth: 40, boxSizing: 'border-box', ...marginStyle, ...absoluteStyle }} align='center' center>
                    {showButton && (
                        <IconButton style={{ height: 40 }} onClick={() => (doScroll ? doScroll(flavor) : null)}>
                            <ArrowIcon style={{ paddingLeft: flavor === 'left' ? '4px' : '' }} />
                        </IconButton>
                    )}
                </Flex>
            );
        } else {
            return (
                <Flex row style={{ width: 40, minWidth: 40, maxWidth: 40, boxSizing: 'border-box', ...marginStyle }} align='center' center>
                    {showButton && (
                        <IconButton style={{ height: 40 }} onClick={() => (doScroll ? doScroll(flavor) : null)}>
                            <ArrowIcon style={{ paddingLeft: flavor === 'left' ? '4px' : '' }} />
                        </IconButton>
                    )}
                </Flex>
            );
        }
    };

    const handleWrapperResize = useCallback(() => {
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [showLeftScrollButton, showRightScrollButton]);

    const handleContainerResize = useCallback(() => {
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [showLeftScrollButton, showRightScrollButton]);

    const handleCanvasResize = useCallback(() => {
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [showLeftScrollButton, showRightScrollButton]);

    const handleScroll = useCallback(() => {
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [showLeftScrollButton, showRightScrollButton]);

    useEffect(() => {
        const _containerRef = containerRef.current;
        const wrapperResizeObserver = new ResizeObserver(() => handleWrapperResize());
        const containerResizeObserver = new ResizeObserver(() => handleContainerResize());
        const canvasResizeObserver = new ResizeObserver(() => handleCanvasResize());
        _containerRef?.addEventListener('scroll', handleScroll);

        wrapperResizeObserver.observe(wrapperRef?.current);
        containerResizeObserver.observe(containerRef?.current);
        canvasResizeObserver.observe(canvasRef?.current);

        return () => {
            containerResizeObserver.disconnect();
            canvasResizeObserver.disconnect();
            _containerRef?.removeEventListener('scroll', handleScroll);
        };
    }, [handleContainerResize, handleCanvasResize, handleScroll, handleWrapperResize]);

    return (
        <Flex row fullWidth className='horiz-scroll-wrapper' ref={wrapperRef} style={{ overflow: 'hidden', position: 'relative', ...style }}>
            <Scrollbar flavor='left' />
            <Flex row fullWidth className='horiz-scroll-container' ref={containerRef} style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
                <div className='horiz-scroll-canvas' ref={canvasRef}>
                    {children}
                </div>
            </Flex>
            <Scrollbar flavor='right' />
        </Flex>
    );
};
