// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2WFK9K4hoeFhPJbl24tPfL{width:470px}", ""]);
// Exports
exports.locals = {
	"ticketWrapper": "_2WFK9K4hoeFhPJbl24tPfL"
};
module.exports = exports;
