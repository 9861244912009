import { Typography } from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { QuoteAttribute, SafeFormat } from 'phoenix/util';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { OptionQuote, OptionSymbol } from 'phoenix/redux/models';

interface LastQuoteUpdateDisplayProps {
    qsi: string;
    debounceMs: number;
    style?: React.CSSProperties;
    prefix?: string;
    subscriptionNamespace?: string;
}

export const LastQuoteUpdateDisplay = React.memo((props: LastQuoteUpdateDisplayProps) => {
    const { qsi, debounceMs, prefix, style, subscriptionNamespace } = props;

    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const [showTimeout, setShowTimeout] = useState<any>(null);
    const [show, setShow] = useState(false);

    const sQuote: StandardQuote = XS.Quotes.use(props.qsi);
    const oQuote: OptionQuote = XS.OptionQuotes.use(props.qsi);


    const isOption = OptionSymbol.IsOptionSymbol(qsi);

    const quote : Partial<StandardQuote | OptionQuote> =  isOption ? oQuote : sQuote; 

    const time = useMemo(() => QuoteAttribute.getTime(quote), [quote]);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(props.qsi));
    }, [props.qsi]);

    useEffect(() => {
        return () => XS.stopNs(subscriptionNamespace || LiveDataNamespaces.LastUpdate);
    }, []);

    useEffect(() => {
        isOption
            ? xdispatch(XS.OptionQuotes.start([qsi], subscriptionNamespace || LiveDataNamespaces.LastUpdate))
            : xdispatch(XS.Quotes.start(qsi, subscriptionNamespace || LiveDataNamespaces.LastUpdate));
    }, [qsi]);

    useEffect(() => {
        const msDiff = Math.abs(differenceInMilliseconds(time, new Date()));
        setShow(msDiff > debounceMs);
        if (showTimeout !== null) clearTimeout(showTimeout);
        setShowTimeout(setTimeout(() => setShow(true), debounceMs));
    }, [time]);

    const valid = useMemo(() => !!time, [time]);
    const s: React.CSSProperties = useMemo(() => ({ ...style, transition: 'opacity 500ms', opacity: show && valid ? 1 : 0 }), [show, valid]);
    const dt = useMemo(() => new Date(time), [time]);

    return (
        <Typography variant='h6' style={s}>
            {prefix} {SafeFormat(dt, 'dd MMM yyyy hh:mm:ss bb')}
        </Typography>
    );
});
