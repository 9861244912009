import { ApiData } from '../../../models'
import { TaxlotAggregateItem } from './TaxlotAggregate'

export class TaxlotState {
    aggregate: ApiData<TaxlotAggregateItem | null>;
    openTaxlots: ApiData<TaxlotAggregateItem | null>;
    closedTaxlots: ApiData<TaxlotAggregateItem | null>;

    constructor () {
        this.aggregate = new ApiData(null)
        this.openTaxlots = new ApiData(null)
        this.closedTaxlots = new ApiData(null)
    }
}
