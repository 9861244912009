// @ts-strict-ignore
import { PlaylistAdd, PlaylistAddCheck } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { T } from 'phoenix/assets/lang/T';
import { GetWatchlistsAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface AddToListIconProps {
    securityId: string;
    onClick?: (e: React.MouseEvent) => void;
}

export const AddToListIcon = React.memo((props: AddToListIconProps) => {
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const lists = useSelector((s: GlobalState) => s.watchlists.all);
    const addedLists = lists.data?.filter((l) => l.securities?.some((s) => s.symbol === props.securityId) || false);

    useEffect(() => {
        loadPositions();
        dispatch(GetWatchlistsAction());
    }, [dispatch, loadPositions]);

    const listed = addedLists?.length;

    const Icon = () => (listed ? <PlaylistAddCheck /> : <PlaylistAdd />);
    const helpText = listed ? T((s) => s.general.spokenJoin(addedLists.map((l) => l.name))) : T((s) => s.securityScreen.addToList);

    return (
        <Tooltip placement='right' title={helpText}>
            <IconButton size='small' onClick={(e) => props.onClick(e)}>
                <Icon />
            </IconButton>
        </Tooltip>
    );
});
