// @ts-strict-ignore
import { useEffect } from "react";

// Hook that calls a callback when a user clicks outside of the passed ref
export const useOutsideClickHandler = (ref, callback: () => void) => {
  useEffect(() => {
    // Fire callback if clicked on outside of element
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && callback) {
       callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}