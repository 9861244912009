// @ts-strict-ignore
import { ApiTradeRequest, ApiTradeAction, ApiTimeInForce } from 'phoenix/models/ApiTradeRequest';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { Order } from 'phoenix/redux/models';
import { DetermineOrderType } from 'phoenix/util/TradeHelpers';

// TODO: SNEX1API should probably do this for the frontends. Until then it's a frontend responsibility
export const ConvertOrderTifToApiTif = (orderTif: string): ApiTimeInForce => {
    let tif = orderTif === 'GoodTillCancel' ? 'GTC' : orderTif;

    if (!['Day', 'GTC', 'FOK', 'IOC', 'GTXPost', 'NTE', 'GTXPre'].some((x) => x === tif)) {
        console.log(`Warning: order TIF was ${tif}, defaulting to Day`);
        tif = 'Day';
    }

    return tif as ApiTimeInForce;
};

export const ConvertOrderToTradeRequest = (order: Order): ApiTradeRequest => {
    const tif = ConvertOrderTifToApiTif(order?.timeInForce);

    const orderType = DetermineOrderType(order);
    const qsi = QualifiedSecurityId.FromOrder(order);

    const action =
        {
            BuyToOpen: 'Buy',
            BuyToClose: 'Buy',
            SellToOpen: 'Sell',
            SellToClose: 'Sell'
        }[order.action] || order.action;

    return {
        orderId: order.orderId,
        securityId: qsi.toString(),
        // securityNumber: order.securityNumber,
        accountNumber: order.accountNumber,
        action: action as ApiTradeAction,
        quantity: order.orderQuantity,
        orderPrice: /^stop/.test(orderType) ? order.stopPrice : order.limitPrice,
        stopLimitPrice: orderType === 'stoplimit' ? order.limitPrice : undefined,
        timeInForce: tif as any,
        quantityQualifier: order.quantityQualifier, // Only for mutual funds
        floorNote: order.floorNote,
        notHeld: order.notHeld,
        orderType,
        onClose: order?.onClose
    };
};
