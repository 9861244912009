import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { ChartRange } from 'phoenix/constants';
import { GetAccountChartAction, GetAggregateAccountChartAction, ResetSecurityChartsAction, GetDummyAggregateAccountChartAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { MarketTimeSegment } from 'phoenix/util';
import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const SecurityChartsForMarketTimeManager = React.memo(() => {
    const dispatch = useDispatch();
    const [currentTimeSegment] = useMarketTimeSegmentV2();
    const previousTimeSegment = useRef<MarketTimeSegment | null>(null);

    useEffect(() => {
        previousTimeSegment.current = currentTimeSegment;
    });

    const switchedToOpen = previousTimeSegment.current !== currentTimeSegment && previousTimeSegment.current === 'premarket' && currentTimeSegment === 'open';

    useEffect(() => {
        if (switchedToOpen) dispatch(ResetSecurityChartsAction());
    }, [dispatch, switchedToOpen]);

    return null;
});

export const AccountChartsForMarketTimeManager = React.memo((props: { accountNumber?: string; range?: ChartRange }) => {
    const { accountNumber, range = '1d' } = props;
    const dispatch = useDispatch();
    const [currentTimeSegment] = useMarketTimeSegmentV2();
    const previousTimeSegment = useRef<MarketTimeSegment | null>(null);

    const userInfo = useSelector((s: GlobalState) => s.user.myInfo.data);
    const displayFundOverlay = userInfo && !userInfo?.hasFunded;

    useEffect(() => {
        previousTimeSegment.current = currentTimeSegment;
    });

    const switchedToOpen = previousTimeSegment.current !== currentTimeSegment && previousTimeSegment.current === 'premarket' && currentTimeSegment === 'open';

    useEffect(() => {
        if (switchedToOpen) {
            if (userInfo === null) return;
            if (!displayFundOverlay) {
                if (accountNumber) dispatch(GetAccountChartAction(accountNumber, range));
                else dispatch(GetAggregateAccountChartAction(range));
            } else dispatch(GetDummyAggregateAccountChartAction());
        }
    }, [switchedToOpen]);

    return null;
});
