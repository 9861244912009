// @ts-strict-ignore
import { SnexError } from "phoenix/models/SnexError"
import { create } from "zustand"
import { PlaidLinkToken } from "phoenix/redux/models"
import { PlaidAuthorization } from "phoenix/redux/models/Funding/PlaidAuthorization"
import { PlaidVerification } from "phoenix/redux/models/Funding/PlaidVerification"
import { SnexAxios } from "./AxiosHelpers"
import { Urls } from "phoenix/constants"
import { SnexApiResponse } from "phoenix/redux/models/SnexApiResponse"

export type PlaidStoreData = {

    isLoading: boolean
    plaidLinkToken?: PlaidLinkToken 
    plaidAuthorization?: PlaidAuthorization
    plaidVerification?: PlaidVerification
    errors?: SnexError[]

}

export type PlaidStoreMethods = {

    getToken: (mode: 'create' | 'update', fundingSourceId?: string, env?: string) => Promise<PlaidLinkToken>
    getAuthorization: (fundingSourceId?: string) => Promise<PlaidAuthorization>
    getVerification: (fundingSourceId?: string) => Promise<PlaidVerification>
    updateAuthorization: (publicToken: string, fundingSourceId?: string) => Promise<PlaidAuthorization>
    resetAuthorization: (fundingSourceId?: string) => Promise<PlaidAuthorization>
}

export type PlaidStore = PlaidStoreData & PlaidStoreMethods

export const usePlaidStore = create<PlaidStore>((set, get) => {

    return {

        isLoading: false,
        plaidLinkToken: null,
        plaidAuthorization: null,
        plaidVerification: null,
        errors: null,

        getToken: async (mode: 'create' | 'update', fundingSourceId?: string, env?: string) => {
            set({ isLoading: true })
            const response = await SnexAxios.ApiGet<PlaidLinkToken>(
                Urls.funding.v2.plaid.getLinkToken(mode, fundingSourceId, env))
            .run();
            
            const plaidLinkToken = response
            set({ plaidLinkToken, isLoading: false })

            return plaidLinkToken
        },
        getAuthorization: async (fundingSourceId?: string) => {
            set({ isLoading: true })
            const response = await SnexAxios.ApiGet<PlaidAuthorization>(Urls.funding.v2.plaid.authorization(fundingSourceId))
            .run();
            const plaidAuthorization = response
            set({ plaidAuthorization, isLoading: false })
            return plaidAuthorization
        },
        getVerification: async (fundingSourceId?: string) => {
            set({ isLoading: true })
            const response = await SnexAxios.ApiGet<PlaidVerification>(Urls.funding.v2.plaid.verificationStatus(fundingSourceId))
            .run();
            const plaidVerification = response
            set({ plaidVerification, isLoading: false,  })
            return plaidVerification
        },
        updateAuthorization: async (publicToken: string, fundingSourceId?: string) => {
            set({ isLoading: true })
            const response = await SnexAxios.ApiPut<PlaidAuthorization>(
                Urls.funding.v2.plaid.authorization(fundingSourceId),
                { data: { PlaidToken: publicToken }}
            )
            .run();
            const plaidAuthorization = response
            set({ plaidAuthorization, isLoading: false })
            return plaidAuthorization
        },
        resetAuthorization: async (fundingSourceId?: string) => {
            set({ isLoading: true })
            const response = await SnexAxios.ApiPut<PlaidAuthorization>(Urls.funding.v2.plaid.resetAuthorization(fundingSourceId))
            .run();
            const plaidAuthorization = null
            set({ plaidAuthorization, isLoading: false, })
            return plaidAuthorization
        },
            
    
    }

})