// @ts-strict-ignore
import { PaginationOffsets } from 'phoenix/redux/models/Paging/Page';
import { Urls } from '../../constants';
import { OrderStatusInput } from '../../constants/OrderStatus';
import { GlobalState } from '../GlobalState';
import { Order, OrderIdentifierType, TradeableSecurityType } from '../models';
import { Actions } from './Constants';
import { ReduxApiGet } from './Helpers';

export type ApiTradeableSecurityType = 'Equity' | 'MutualFund' | 'Etf' | 'Adr' | 'Option' | 'Future' | 'FuturesOption' | 'Crypto';

export const ConvertTradeableSecurityTypeToApiTradeableSecurityType = (type?: TradeableSecurityType): ApiTradeableSecurityType => {
    // prettier-ignore
    switch (type?.toLowerCase())
        {
            case 'equity': return 'Equity' as ApiTradeableSecurityType;
            case 'mutual-fund': return 'MutualFund' as ApiTradeableSecurityType;
            case 'etf': return 'Etf' as ApiTradeableSecurityType;
            case 'adr': return 'Adr' as ApiTradeableSecurityType;
            case 'option': return 'Option' as ApiTradeableSecurityType;
            case 'future': return 'Future' as ApiTradeableSecurityType;
            case 'futures-option': return 'FuturesOption' as ApiTradeableSecurityType;
            case 'crypto': return 'Crypto' as ApiTradeableSecurityType;
            default: return null
        }
};

export type OrderSearchFiltersV3 = {
    accountNumber?: string;
    tradeableSecurityType?: ApiTradeableSecurityType; // de-kabobed TradeableSecurityType
    skip?: any;
    status?: OrderStatusInput;
    symbol?: string;
    take?: number;
};
/**
 * @deprecated use `useOrdersStore.searchOrders` instead
 */
export const SearchOrdersV3Action = (filters?: OrderSearchFiltersV3, append?: boolean, offsets?: PaginationOffsets) =>
    ReduxApiGet(Urls.orders.searchV3(filters, offsets), Actions.Orders.SearchV3).withPassthrough({ passthrough: { append } }).withLoading().run();

/**
 * @deprecated use `useOrdersStore.getAllOpenOrders` instead
 */
export const GetAllOpenOrdersAction = (options?: { page?: number; size?: number }) => {
    const { page = 1, size = 20 } = options || {};
    return ReduxApiGet(Urls.orders.openV3({ page, size }), Actions.Orders.GetOpenOrders).run();
};

/**
 * @deprecated use `useOrdersStore.getSingleOrderAction` instead
 */
export const GetSingleOrderAction = (orderId: string, useStored = false) => {
    return ReduxApiGet(Urls.orders.getOneV3(orderId, 'OrderId'), Actions.Orders.GetSingleOrder)
        .withSubject(orderId)
        .useStored((s) => (useStored ? FindOrderInStore(s, (o) => o.orderId === orderId) : null))
        .run();
};

/**
 * @deprecated use `useOrdersStore.getSingleOrderV3Action` instead
 */
export const GetSingleOrderV3Action = (orderIdentifier: string, orderIdentifierType: OrderIdentifierType, useStored = true) => {
    return ReduxApiGet(Urls.orders.getOneV3(orderIdentifier, orderIdentifierType), Actions.Orders.GetSingleOrderV3)
        .withSubject(orderIdentifier)
        .withMutex(() => `single-order-${orderIdentifier}`, true)
        .run();
};

// Optimization for `GetSingleOrderAction`
const FindOrderInStore = (s: GlobalState, selector: (o: Order) => boolean) => {
    const o = s.orders;
    return [o.searchResults.data, Object.values(o.openOrders).flatMap((x) => x.data)]
        .flat()
        .filter((o) => !!o.symbol)
        .find(selector);
};
