// @ts-strict-ignore
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo, useState } from 'react';
import { useColors } from 'hooks/UseColors';
import { FromEnglish } from 'phoenix/assets/lang/T';
import { FormatNumber, GenerateRandomColor } from 'phoenix/util';
import './DonutChart.scss';

highchartsAccessibility(Highcharts);

export interface DonutChartProps {
    data: { name: string; y: number; color?: string }[];
    allowFormatToggle?: boolean;
    size?: number;
    dataLabelDistance?: number;
    hideLabels?: boolean;
    disableDimming?: boolean;
    disableAnimation?: boolean;
    onClick?: (series: Highcharts.Series, event: PointerEvent) => any;
    onMouseOver?: (series: Highcharts.Series, event: PointerEvent) => any;
    onMouseOut?: (series: Highcharts.Series, event: PointerEvent) => any;
    spacing?: {
        spacingBottom?: number;
        spacingLeft?: number;
        spacingRight?: number;
    };
}

const DonutChart = (props: DonutChartProps) => {
    const themeColors = useColors();

    const defaultColors = themeColors.donutChartSegments;

    const assetColors = useMemo(
        () => ({
            Equities: themeColors.primaryItemColor,
            'Cash & Equivalents': themeColors.green,
            Futures: themeColors.futuresColor,
            Options: defaultColors[6],
            'Mutual Funds': defaultColors[1],
            default: defaultColors[7]
        }),
        [defaultColors, themeColors]
    );

    const colors = props.data.map((d) => d.color || assetColors[d.name] || GenerateRandomColor() || assetColors.default);

    const [showValue, setShowValue] = useState(false);
    const localizedData: { name: string; y: number; color?: string }[] = useMemo(() => {
        if (Array.isArray(props.data)) {
            return props.data.map((d) => ({
                name: FromEnglish((s) => s.portfolioScreen.portfolioBreakdown, d.name),
                y: d.y
            }));
        }
    }, [props.data]);

    const options = {
        lang: {
            thousandsSep: ','
        },
        credits: {
            enabled: false
        },
        chart: {
            backgroundColor: 'transparent',
            height: props.size ? props.size + 75 : 270,
            overflow: 'none',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            spacingBottom: 30,
            spacingLeft: 50,
            spacingRight: 30,
            ...(props.spacing ? props.spacing : {})
        },
        title: {
            enabled: false,
            text: null
        },
        tooltip: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            series: {
                animation: !props.disableAnimation
            },
            pie: {
                point: {
                    events: {
                        click: props.onClick,
                        mouseOut: props.onMouseOut,
                        mouseOver: props.onMouseOver
                    }
                },
                size: props.size ? props.size : null,
                cursor: 'pointer',
                colors,
                startAngle: 90
            }
        },
        series: [
            {
                name: 'Securities',
                colorByPoint: true,
                innerSize: '42%',
                data: localizedData,
                borderColor: themeColors.donutChartBorder,
                borderWidth: 5,
                dataLabels: {
                    enabled: !props.hideLabels,
                    ...(props.dataLabelDistance ? { distance: props.dataLabelDistance } : {}),
                    useHTML: true,
                    formatter: function () {
                        return `<div style="max-width: 110px; padding: 8px; border-radius: 8px; display: flex; overflow: hidden; background: ${
                            themeColors.cardBackgroundColor
                        }; box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);"><div style="background: ${
                            this.color
                        }; height: 15px; width: 15px; border-radius: 5px;"></div><div style="margin-left: 10px; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; text-align: left; flex: 1; overflow: hidden;"><h4 style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden !important; margin: 0; padding: 0; color: #b2b2b2; font-size: 10px; font-weight: 300;">${
                            this.key
                        }</h4><h5 style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden !important; margin: 0; padding: 0; font-size: 11px; font-weight: 700; color:${
                            themeColors.generalTextColor
                        }">${
                            showValue && props.allowFormatToggle ? FormatNumber.toMoney(this.y) : `${FormatNumber.toPercent(this.percentage, 'never', 3)}`
                        }</h5></div></div>`;
                    },
                    className: 'account-holdings-chart-label'
                }
            }
        ]
    };

    return (
        <div onClick={props.allowFormatToggle ? () => setShowValue(!showValue) : null}>
            <HighchartsReact highcharts={Highcharts} oneToOne={true} options={options} />
        </div>
    );
};

export default React.memo(DonutChart);
