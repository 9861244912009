// @ts-strict-ignore
import { Collapse, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Spinny, SubList } from '../../components';
import { Snex1English } from '../../phoenix/assets/lang/Snex1English';
import { Snex1LanguagePack } from '../../phoenix/assets/lang/Snex1LanguagePack';
import { GetSectorPerformanceAction, StartPollingSectorPerformanceAction, StopPollingSectorPerformanceAction } from '../../phoenix/redux/actions';
import { GlobalState } from '../../phoenix/redux/GlobalState';
import { ChangeColor, FormatNumber, FormatString, SafeFormatToNowAbbrev } from '../../phoenix/util';
import { FromEnglish, T } from '../../phoenix/assets/lang/T';
import style from './style.module.scss';
import { useColors } from '../../hooks/UseColors';
import { Link } from 'react-router-dom';
import { Routes } from '../../util/Routes';
import { GetSectorDisplayName, GetSectorForRoute } from '../../screens/SectorScreen/SectorScreen';
import { CulledCollapse } from '../../components/CulledCollapse/CulledCollapse';
import { useText } from 'phoenix/hooks/UseText';

export const SectorsLists = () => {
    const dispatch = useDispatch();
    const sectors = useSelector((s: GlobalState) => s.securities.sectors.performance);
    const text = useText(s => s.sectorScreen);

    // TODO: Build out some skeleton cells
    const ListSkeletonCells = React.memo(() => {
        return <div />
    })
    const colors = useColors()
    const [isCollapsed, setIsCollapsed] = useState(false);

    const SectorRow = React.memo((props: {sectorKey: string, performance: number, subtitle: string | JSX.Element}) =>
        <Link to={Routes.sectors(GetSectorForRoute(props.sectorKey))}>
            <Flex column fullWidth className={`${style.subListHeaderWrapper} ${style.hoverable}`} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Flex row fullWidth>
                    <Flex row style={{ flex: 1, height: '100%' }}>
                        <Typography
                            className={`${style.listName}`}
                            style={{ color: colors.generalTextColor, marginRight: 20, fontWeight: 600, opacity: 0.75 }}
                            variant='h5'
                            >{GetSectorDisplayName(props.sectorKey, text)}</Typography>
                    </Flex>
                    <Flex row style={{ flex: 0 }} align='center' justify='flex-end'><Typography style={{ color: ChangeColor(props.performance) }} variant='h6'>
                        {FormatNumber.toPercent(props.performance * 100)}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex row fullWidth >
                    <Typography style={{ opacity: 0.4, fontSize: '12px' }}>As of {props.subtitle} ago</Typography>
                </Flex>
            </Flex>
        </Link>
    )

    const lastUpdated = useCallback((time: number) =>
        <div style={{ height: 10, marginBottom: 10, textAlign: 'left', width: '100%' }}>
            {time && <Typography
                style={{ opacity: 0.5 }}
                variant='caption'>{T(s => s.portfolioScreen.sectors.asOf(new Date(time)))}</Typography>}
        </div>
    , [sectors])

    useEffect(() => {
        dispatch(GetSectorPerformanceAction());
        dispatch(StartPollingSectorPerformanceAction());
        return () => { dispatch(StopPollingSectorPerformanceAction()); };
    }, []);

    const handleCollapseToggle = () => setIsCollapsed(!isCollapsed)

    return (
        <div className={style.wrapper}>
                <Flex
                    row
                    fullWidth
                    className={style.headerTitle}
                    onClick={handleCollapseToggle}
                    align='center'
                    justify='space-between'
                    style={{ flex: 1 }}
                >
                <button onClick={handleCollapseToggle}>
                    <Typography className={style.headerTitle} variant='h5'>{text.sectors}</Typography>
                    <Spinny style={{ flex: '0' }} spun={isCollapsed} />
                </button>
                </Flex>
                <CulledCollapse in={!isCollapsed}>
                { sectors.pristine || sectors.loading ? <ListSkeletonCells />

                    : sectors.data.map((list, listIdx) => (
                        <SectorRow key={listIdx} sectorKey={list.name} performance={list.performance} subtitle={SafeFormatToNowAbbrev(new Date(list.lastUpdated).toUTCString())} />
                    ))
                }
                </CulledCollapse>
            </div>
    )
}

function getText() {
    throw new Error('Function not implemented.');
}
