import React from 'react';
import { getScaledImage } from './getScaledImage';

export const Previous10Icon = ({ width, height, style }: { width?: number; height?: number; style?: React.CSSProperties }): JSX.Element => {
    const { height: _h, width: _w } = getScaledImage(30, 30, width, height);

    return (
        <div style={{ ...style }}>
            <svg width={_w} height={_h} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <mask id='mask0_22_131' style={{ maskType: 'luminance' }} maskUnits='userSpaceOnUse' x='0' y='0' width={_w} height={_h}>
                    <path fillRule='evenodd' clipRule='evenodd' d='M0 0H30V30H0V0Z' fill='white' />
                </mask>
                <g mask='url(#mask0_22_131)'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M15 6.25V1.25L8.75 7.5L15 13.75V8.75C19.125 8.75 22.5 12.125 22.5 16.25C22.5 20.375 19.125 23.75 15 23.75C10.875 23.75 7.5 20.375 7.5 16.25H5C5 21.75 9.5 26.25 15 26.25C20.5 26.25 25 21.75 25 16.25C25 10.75 20.5 6.25 15 6.25ZM13.5583 20H12.5V15.9204L11.2366 16.3123V15.4517L13.4448 14.6606H13.5583V20ZM18.8794 17.7954C18.8794 18.5327 18.7268 19.0967 18.4216 19.4873C18.1165 19.8779 17.6697 20.0732 17.0813 20.0732C16.5002 20.0732 16.0559 19.8816 15.7483 19.4983C15.4407 19.115 15.2832 18.5657 15.2759 17.8503V16.8689C15.2759 16.1243 15.4303 15.5591 15.7391 15.1733C16.048 14.7876 16.4929 14.5947 17.074 14.5947C17.655 14.5947 18.0994 14.7858 18.407 15.1678C18.7146 15.5499 18.8721 16.0986 18.8794 16.814V17.7954ZM17.821 16.7188C17.821 16.2769 17.7606 15.9552 17.6398 15.7538C17.5189 15.5524 17.3303 15.4517 17.074 15.4517C16.825 15.4517 16.6412 15.5475 16.5228 15.7391C16.4044 15.9308 16.3416 16.2305 16.3342 16.6382V17.9346C16.3342 18.3691 16.3934 18.692 16.5118 18.9032C16.6302 19.1144 16.8201 19.22 17.0813 19.22C17.3401 19.22 17.5269 19.1187 17.6416 18.916C17.7563 18.7134 17.8162 18.4033 17.821 17.9858V16.7188Z'
                        fill='white'
                        fillOpacity='0.73'
                    />
                </g>
            </svg>
        </div>
    );
};
