// @ts-strict-ignore
import React from 'react';
import { TradeReceiptPage } from '../Shared/TradeReceiptPage';
import { TradeReviewPage } from '../Shared/TradeReviewPage';
import { OptionIndivTradeInputPage } from './OptionIndivTradeInputPage';
import { OptionTradeInputPage } from './OptionTradeInputPage';
import { useText } from 'phoenix/hooks/UseText';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { OptionsTradeTicketViewModel } from '../Store/TradeTicketViewModel';

export const OptionTradeTicket = React.memo(() => {
    const { state, variant } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const text = useText((t) => t);

    switch (state) {
        case 'input':
            return variant === 'option-individual' ? <OptionIndivTradeInputPage variant={variant} /> : <OptionTradeInputPage />;
        case 'review':
            return <TradeReviewPage title={text.tradeTicket.review.confirmTrade} />;
        case 'done':
            return <TradeReceiptPage />;
    }
});
