// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { SubscriptionState } from '../models/Subscription/SubscriptionState'

const permitted = GroupNameChecker([Actions.Subscription])
export const SubscriptionReducer = (state: SubscriptionState = new SubscriptionState(), action: ReduxAction): SubscriptionState => {
    if (!permitted(action)) return state

    switch (action.type) {
        case Actions.Subscription.GetMyInfo.Loading: return { ...state, mySubscription: state.mySubscription.startLoading(state.mySubscription.data) }
        case Actions.Subscription.GetMyInfo.Success: return { ...state, mySubscription: state.mySubscription.succeeded(action.data) }
        case Actions.Subscription.GetMyInfo.Failure: return { ...state, mySubscription: state.mySubscription.failed(action.error) }

        case Actions.Subscription.GetStripeCheckoutUrl.Loading: return { ...state, stripeCheckoutUrl: state.stripeCheckoutUrl.startLoading(state.stripeCheckoutUrl.data) }
        case Actions.Subscription.GetStripeCheckoutUrl.Success: return { ...state, stripeCheckoutUrl: state.stripeCheckoutUrl.succeeded(action.data) }
        case Actions.Subscription.GetStripeCheckoutUrl.Failure: return { ...state, stripeCheckoutUrl: state.stripeCheckoutUrl.failed(action.error) }

        case Actions.Subscription.GetStripePortalUrl.Loading: return { ...state, stripePortalUrl: state.stripePortalUrl.startLoading(state.stripePortalUrl.data) }
        case Actions.Subscription.GetStripePortalUrl.Success: return { ...state, stripePortalUrl: state.stripePortalUrl.succeeded(action.data) }
        case Actions.Subscription.GetStripePortalUrl.Failure: return { ...state, stripePortalUrl: state.stripePortalUrl.failed(action.error) }

        case Actions.Subscription.UpdatePaymentAccount.Loading: return { ...state, updatePaymentAccount: state.updatePaymentAccount.startLoading() }
        case Actions.Subscription.UpdatePaymentAccount.Success: return { ...state, updatePaymentAccount: state.updatePaymentAccount.succeeded() }
        case Actions.Subscription.UpdatePaymentAccount.Failure: return { ...state, updatePaymentAccount: state.updatePaymentAccount.failed(action.error) }

        case Actions.Subscription.Cancel.Loading: return { ...state, cancel: state.cancel.startLoading() }
        case Actions.Subscription.Cancel.Success: return { ...state, cancel: state.cancel.succeeded() }
        case Actions.Subscription.Cancel.Failure: return { ...state, cancel: state.cancel.failed(action.error) }

        default: return state
    }
}
