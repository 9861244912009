import { FeatureFlags } from "phoenix/constants/FeatureFlags";
import { useFeatureFlag } from "phoenix/hooks/UseFeatureFlag";
import { GlobalState } from "phoenix/redux/GlobalState";
import { useSelector } from "react-redux";

export const useShowOneProNetwork = (): boolean => {
    const myInfo = useSelector((s: GlobalState) => s.user.myInfo);
    const oneProFlagEnabled = useFeatureFlag(FeatureFlags.OneProNetwork);
    const showOneProNetwork = oneProFlagEnabled && myInfo?.data?.showOneProNetwork;
    return showOneProNetwork === true
}