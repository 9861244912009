// @ts-strict-ignore
import { ApiData } from '../../models';
import { ReduxAction } from '../../models/ReduxAction';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { AccountChartPoint } from '../models/AccountChart/AccountChartPoint';
import { PortfolioChartPoint } from '../models/AccountChart/PortfolioChartPoint';
import { AccountChartResponse } from '../models/AccountChart/AccountChartResponse';
import { AccountChartState } from '../models/AccountChart/AccountChartState';
import { UniqueBy } from '../../util';
import { add } from 'date-fns';

const ResetAccountDayCharts = (state: AccountChartState): AccountChartState => {
    const byAccount = Object.keys(state.byAccount).reduce((final, accountNumber) => {
        const accountSubstate = state.byAccount[accountNumber];
        const daySubstate = accountSubstate['1d'] || new ApiData<AccountChartResponse>({ accountNumber, data: [] });

        const lastPoint = daySubstate.data?.data?.length ? daySubstate.data?.data?.[daySubstate.data?.data?.length - 1] : null;

        if(lastPoint) lastPoint.timestamp = new Date().getTime();

        const newAccountChartSubstate = {
            ...accountSubstate,
            '1d': daySubstate.succeeded({ accountNumber, data: lastPoint ? [lastPoint] : [] })
        };
        return { ...final, [accountNumber]: newAccountChartSubstate };
    }, {});

    const aggregateDaySubstate = state.aggregate?.['1d'] || new ApiData<AccountChartPoint[]>([]);

    const aggregateLastPoint = aggregateDaySubstate.data?.length ? aggregateDaySubstate.data?.[aggregateDaySubstate.data?.length - 1] : null;

    if(aggregateLastPoint) aggregateLastPoint.timestamp = new Date().getTime();

    return {
        ...state,
        byAccount,
        aggregate: { ...state.aggregate, '1d': aggregateDaySubstate.succeeded(aggregateLastPoint ? [aggregateLastPoint] : []) }
    };
};

const permitted = GroupNameChecker([Actions.AccountCharts]);
export const AccountChartReducer = (state: AccountChartState = new AccountChartState(), action: ReduxAction): AccountChartState => {
    if (!permitted(action)) return state;

    const chartData =
        action.subject?.accountNumber && action.subject?.range
            ? state.byAccount[action.subject?.accountNumber]
                ? state.byAccount[action.subject?.accountNumber]?.[action.subject?.range]
                : new ApiData<AccountChartResponse | null>({ accountNumber: action.subject?.accountNumber, data: [] })
            : new ApiData<AccountChartResponse | null>({ accountNumber: action.subject?.accountNumber, data: [] });

    const aggChartData = state.aggregate[action.subject] ? state.aggregate[action.subject] : new ApiData<AccountChartPoint[]>([]);

    const normalizeBatchCharts = (subject: any, actionMethod: (q: ApiData<AccountChartResponse | null>, symbol?: string) => ApiData<AccountChartResponse | null>) => {
        if (!Array.isArray(subject)) {
            return {
                [subject.accountNumber]: {
                    ...(state.byAccount[subject.accountNumber] || {}),
                    [subject.range]: actionMethod(state.byAccount[subject.accountNumber]?.[subject.range] || new ApiData<AccountChartResponse | null>())
                }
            };
        }
        const newCharts = subject.reduce(
            (f: any, c: any) => ({
                ...f,
                [c.accountNumber]: {
                    ...state.byAccount[c.accountNumber],
                    [c.range]: actionMethod(state.byAccount[c.accountNumber]?.[c.range] || new ApiData<AccountChartResponse | null>(), c)
                }
            }),
            {}
        );
        return newCharts;
    };

    switch (action.type) {
        case Actions.AccountCharts.GetAggregate.Loading:
            return { ...state, aggregate: { ...state.aggregate, [action.subject]: aggChartData.startLoading(aggChartData.data) } };
        case Actions.AccountCharts.GetAggregate.Success: {
            const newState = { ...state };
            const sortedValues = action.data.sort((a, b) => Date.parse(new Date(b?.timestamp).toUTCString()) - Date.parse(new Date(a?.timestamp).toUTCString()));
            if (action.subject === '1d' && !!sortedValues?.length) {
                const intervalInMilliseconds =
                    Date.parse(new Date(sortedValues[0]?.timestamp).toUTCString()) - Date.parse(new Date(sortedValues[1]?.timestamp).toUTCString());
                const intervalInMinutes = Math.round(intervalInMilliseconds / 1000 / 60);
                newState.intervalInMinutes = intervalInMinutes;
            }

            if (action.subject !== '1d' && state.aggregate['1d']?.data?.length) {
                action.data.unshift(state.aggregate['1d']?.data?.[0]);
            }

            return { ...newState, aggregate: { ...state.aggregate, [action.subject]: aggChartData.succeeded(action.data) } };
        }
        case Actions.AccountCharts.GetAggregate.Failure:
            return { ...state, aggregate: { ...state.aggregate, [action.subject]: aggChartData.failed(action.error, true) } };
        case Actions.AccountCharts.GetOne.Loading:
            return {
                ...state,
                byAccount: {
                    ...state.byAccount,
                    [action?.subject?.accountNumber]: {
                        ...state.byAccount[action?.subject?.accountNumber],
                        [action.subject.range]: chartData.startLoading(chartData.data)
                    }
                }
            };
        case Actions.AccountCharts.GetOne.Success:
            return {
                ...state,
                byAccount: {
                    ...state.byAccount,
                    [action?.subject?.accountNumber]: { ...state.byAccount[action?.subject?.accountNumber], [action.subject.range]: chartData.succeeded(action.data) }
                }
            };
        case Actions.AccountCharts.GetOne.Failure:
            return {
                ...state,
                byAccount: {
                    ...state.byAccount,
                    [action?.subject?.accountNumber]: { ...state.byAccount[action?.subject?.accountNumber], [action.subject.range]: chartData.failed(action.error, true) }
                }
            };
        case Actions.AccountCharts.ResetCharts:
            return ResetAccountDayCharts(state);
        case Actions.AccountCharts.GetMany.Loading:
            return { ...state, byAccount: { ...state.byAccount, ...normalizeBatchCharts(action.subject, (q) => q.startLoading(q.data)) } };
        case Actions.AccountCharts.GetMany.Success: {
            const response: AccountChartResponse[] = action.data;
            let lookup = null;
            if (!response?.length) {
                lookup = action.subject.reduce((lookup, next) => {
                    return {
                        ...lookup,
                        [next.accountNumber]: {
                            ...state.byAccount[next.accountNumber],
                            [next.range]: new ApiData<AccountChartResponse>().succeeded(state.byAccount[next.accountNumber]?.[next.range]?.data || null)
                        }
                    };
                }, {});
            } else {
                lookup = response.reduce((lookup, next) => {
                    const relatedSubject = action.subject.find((s) => s.accountNumber === next.accountNumber);
                    if (!relatedSubject) return lookup;
                    if (relatedSubject.range !== '1d' && state.byAccount[next.accountNumber]?.['1d']?.data?.data?.length) {
                        next.data.push(state.byAccount[next.accountNumber]?.['1d']?.data?.data[state.byAccount[next.accountNumber]?.['1d']?.data?.data?.length - 1]);
                    }
                    next.data = next?.data
                        ?.filter((x) => x && x?.timestamp)
                        ?.sort((a, b) => Date.parse(new Date(b?.timestamp).toUTCString()) - Date.parse(new Date(a?.timestamp).toUTCString()));
                    return {
                        ...lookup,
                        [next.accountNumber]: { ...state.byAccount[next.accountNumber], [relatedSubject.range]: new ApiData<AccountChartResponse>().succeeded(next) }
                    };
                }, {});
            }

            return { ...state, byAccount: { ...state.byAccount, ...lookup } };
        }
        case Actions.AccountCharts.GetMany.Failure:
            return { ...state, byAccount: { ...state.byAccount, ...normalizeBatchCharts(action.subject, (q) => q.failed(action.error, true)) } };
        case Actions.AccountCharts.LiveUpdates.Update:
            return {
                ...state,
                byAccount: {
                    ...state.byAccount,
                    [action.data.accountId]: {
                        ...state.byAccount[action.data.accountId],
                        [action.subject.range]: chartData.succeeded({
                            ...chartData.data,
                            data: UniqueBy([...chartData.data.data, { ...action.data, timestamp: new Date() }], (d) => d?.timestamp)
                        })
                    }
                }
            };

        case Actions.AccountCharts.AggregateLiveUpdates.Update: {
            const data: PortfolioChartPoint = action.data;

            const lastPointDate = aggChartData.data?.[0] ? new Date(aggChartData.data[0]?.timestamp) : new Date();
            const nextPointDate = add(lastPointDate, { minutes: 1 });

            const newPoint: AccountChartPoint = { timestamp: nextPointDate.getTime(), value: data.totalValue, accountId: '' };
            const diffBetweenNowAndLastPoint = aggChartData.data?.[0]
                ? Date.parse(new Date().toUTCString()) - Date.parse(new Date(aggChartData.data[0]?.timestamp).toUTCString())
                : 0;

            const diffInMinutes = diffBetweenNowAndLastPoint / 1000 / 60;
            let newData = [];
            if (diffInMinutes < state.intervalInMinutes && aggChartData?.data?.length) {
                newData = aggChartData?.data;
                newData[0].value = newPoint?.value || 0;
            } else {
                newData = [...(aggChartData.data || []), newPoint];
            }
            newData = newData.sort((a, b) => Date.parse(new Date(b?.timestamp).toUTCString()) - Date.parse(new Date(a?.timestamp).toUTCString()));

            return { ...state, aggregate: { ...state.aggregate, [action.subject]: aggChartData.succeeded(newData) } };
        }
        default:
            return state;
    }
};
