import { Button, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import Lottie from 'lottie-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '..';
import { UseSubmitButtonStyles } from '../../theming';

interface BlankViewProps {
    animationData: unknown;
    onAction?: () => void;
    onActionLabel?: string;
    animationHeight?: number;
    animationWidth?: number;
    text: string;
    secondaryText?: string;
    loop?: boolean;
    route?: string;
}

export const BlankView = (props: BlankViewProps): JSX.Element => {
    const { animationHeight = 300, animationWidth = 300, loop = false } = props;
    const colors = useColors();
    const buttonStyles = UseSubmitButtonStyles(colors)();

    const defaultOptions = {
        loop,
        autoplay: true,
        animationData: props.animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Flex column align='center'>
            <Lottie height={animationHeight} {...defaultOptions} style={{ height: animationHeight, width: animationWidth }} width={animationWidth} />
            <Flex column align='center' style={{ marginTop: 30 }}>
                <Typography style={{ fontSize: 20 }} variant='h6'>
                    {props.text}
                </Typography>
                {!!props.secondaryText && (
                    <Typography style={{ marginTop: 10, color: colors.fadedTextColor, fontSize: 15 }} variant='body2'>
                        {props.secondaryText}
                    </Typography>
                )}
            </Flex>
            {props.onActionLabel && (
                <Flex center style={{ marginTop: 25, width: '100%' }}>
                    <Link to={props.route || ''}>
                        <Button classes={buttonStyles} onClick={props.onAction}>
                            {props.onActionLabel}
                        </Button>
                    </Link>
                </Flex>
            )}
        </Flex>
    );
};
