// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2F4AMjSF5XD9IHUaGmp2oB{cursor:pointer;overflow:hidden;padding:0 18px;position:relative;transition:.3s;width:100%}._1Tx6oGrQubJIcxQ6e1Mu4L{width:100%;overflow:hidden;position:relative;transition:.3s;cursor:pointer}.JvZMP7w5s0vPZbgpSa-vz{border-bottom:1px solid var(--dividerColor);width:100%;padding:10px 0;overflow:hidden;position:relative;transition:.3s}._1ieyFSvOsGKw5qBw-3FO9h{border-bottom:none !important}._2F4AMjSF5XD9IHUaGmp2oB:hover{background-color:var(--selectListItemColor)}.IgLKEJEWp339dNgYu9GmG{width:40px;box-sizing:border-box}._29q7A0TT0gRTZY81-TXxRK{align-items:flex-start;justify-content:center;margin-right:7px}._3yBa1BieI2Us_Ypyh8mnJ{margin-top:7px;justify-content:space-between;align-items:center}.Zybf9ImmZyIOxpTAWf0tF{font-size:13px;font-weight:500}._3kq3nFvp6Fe7wjwLKkutQF{font-size:13px;color:var(--grayText);font-weight:500}._1NzoEDNGyEgupZTbXDNk-k{flex:1;overflow:hidden}.Xs1PDFYsjbvnB0cI4SzoZ{width:100%;flex:1;overflow:hidden}._1g_9tExnX5BGMNrOVH2dcN{flex:1;overflow:hidden;margin-right:8px}._3T2i_ruadnV10uxTnlGFFw{justify-content:center;align-items:center;border-radius:5px}.gcCgaNbknnFR0P1J5yx9t{display:flex;flex-direction:column;flex:.8;align-items:flex-end;margin-left:8px}.godHXTUwrcIx17lEmmccF{color:#000;font-weight:500}._1M837HkxwT53gtdOR4guWf{color:var(--grayText);font-size:13px}._1lpPN5EeZUYnAWyUnM1oWj{color:#000;font-weight:500}._3GdjPH7Q_TiCSj2kD5kA9-{color:var(--grayText);font-size:13px}._3ZvP-VX71kD3co2-GHuLFr{color:var(--positiveValueColor)}._3jzys24AP9LPifwWqOxZMK{color:var(--negativeValueColor)}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094",
	"wrapper": "_2F4AMjSF5XD9IHUaGmp2oB",
	"withoutHoverEffectWrapper": "_1Tx6oGrQubJIcxQ6e1Mu4L",
	"innerWrapper": "JvZMP7w5s0vPZbgpSa-vz",
	"withoutBorder": "_1ieyFSvOsGKw5qBw-3FO9h",
	"deleteAction": "IgLKEJEWp339dNgYu9GmG",
	"securityLogoWrapper": "_29q7A0TT0gRTZY81-TXxRK",
	"secondaryRow": "_3yBa1BieI2Us_Ypyh8mnJ",
	"quantity": "Zybf9ImmZyIOxpTAWf0tF",
	"accountNickname": "_3kq3nFvp6Fe7wjwLKkutQF",
	"cellContent": "_1NzoEDNGyEgupZTbXDNk-k",
	"mainRow": "Xs1PDFYsjbvnB0cI4SzoZ",
	"securityNameColumn": "_1g_9tExnX5BGMNrOVH2dcN",
	"miniChartColumn": "_3T2i_ruadnV10uxTnlGFFw",
	"pricingColumn": "gcCgaNbknnFR0P1J5yx9t",
	"symbol": "godHXTUwrcIx17lEmmccF",
	"name": "_1M837HkxwT53gtdOR4guWf",
	"price": "_1lpPN5EeZUYnAWyUnM1oWj",
	"percentChange": "_3GdjPH7Q_TiCSj2kD5kA9-",
	"positiveChange": "_3ZvP-VX71kD3co2-GHuLFr",
	"negativeChange": "_3jzys24AP9LPifwWqOxZMK"
};
module.exports = exports;
