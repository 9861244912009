// @ts-strict-ignore
import React from 'react';
import { ApiPosition } from 'phoenix/redux/models';
import { PositionCell } from './PositionCell';
import { SearchResultCell } from './SearchResultCell';
import { WatchlistCell } from './WatchlistCell';

export interface SecurityCellProps {
    getCached?: boolean;
    hideAccountName?: boolean;
    index?: number;
    onDelete?: () => any;
    position?: ApiPosition;
    streamingNamespace?: string;
    symbol: string;
    variant: 'watchlist' | 'position' | 'search-result-removable' | 'search-result';
    visible?: boolean;
    watchlistId?: any;
}

export const SecurityCell = React.memo((props: SecurityCellProps) => {
    const { variant = 'watchlist', getCached, streamingNamespace, position, hideAccountName, watchlistId, index, visible = false, onDelete, symbol } = props;
    const commonProps = { getCached, streamingNamespace, symbol, visible, onDelete };

    switch (variant) {
        case 'position':
            return <PositionCell {...commonProps} hideAccountName={hideAccountName} position={position} />;
        case 'search-result-removable':
            return <SearchResultCell {...commonProps} removable />;
        case 'search-result':
            return <SearchResultCell {...commonProps} />;
        case 'watchlist':
        default:
            return <WatchlistCell {...commonProps} index={index} watchlistId={watchlistId} />;
    }
});
