import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useColors } from 'hooks/UseColors';
import { ShadedTextList } from 'phoenix/assets/lang/Snex1LanguagePack';

const ShadedTextDisplayComponent = (props: { segments: ShadedTextList; style?: React.CSSProperties }) => {
    const { segments = [] } = props;
    return (
        <Typography variant='body1' style={{ fontSize: 19, fontWeight: 600, ...props.style }}>
            {segments.map((s, idx) =>
                s.shade === 'dark' ? (
                    <Fragment key={idx}>
                        <ValueText color={s.color}>{s.text}</ValueText>
                        {s.lineBreak ? s.lineBreak === 'double' ? <><br/><br/></> :  <br /> : null}
                    </Fragment>
                ) : (
                    <Fragment key={idx}>
                        <GrayText color={s.color} noSpace={s.text === '.'}>
                            {s.text}
                        </GrayText>
                        {s.lineBreak ? s.lineBreak === 'double' ? <><br/><br/></> :  <br /> : null}
                    </Fragment>
                )
            )}
        </Typography>
    );
};

export const ShadedTextDisplay = React.memo(ShadedTextDisplayComponent);

const ValueText = React.memo((props: { color?: string; style?: React.CSSProperties; children: any }) => {
    const colors = useColors();
    return <span style={{ color: props.color || colors.generalTextColor, ...props.style }}> {props.children}</span>;
});

const GrayText = React.memo((props: { color?: string; style?: React.CSSProperties; children: any; noSpace?: boolean }) => {
    const { grayText } = useColors();
    return (
        <span style={{ color: props.color || grayText, ...props.style }}>
            {props.noSpace ? '' : ' '}
            {props.children}
        </span>
    );
});
