// @ts-strict-ignore
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Flex, MainSearchBox } from '../..';
import { AddToWatchlistAction, GetFuturesContractsAction } from 'phoenix/redux/actions';
import { SnexBadge } from '../../SnexBadge';
import { SnexButton } from '../../SnexButton/SnexButton';
import { useColors } from 'hooks/UseColors';
import { useText } from 'phoenix/hooks/UseText';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { useAppDispatch } from 'AppRoot';
import { FuturesContract } from 'phoenix/redux/models/Securities/FuturesContract';

export const AddManyToListContent = React.memo((props: { toggleModal?: (isOpen: boolean) => any; variant?: 'modal' | 'sidebar'; watchlistId?: string }) => {
    const dispatch = useAppDispatch();
    const colors = useColors();
    const [symbols, setSymbols] = useState([]);
    const placeholder = useText((t) => t.misc.search.placeholder);

    const handleAddToList = async () => {
        await dispatch(AddToWatchlistAction(props.watchlistId, symbols));
        props.toggleModal && props.toggleModal(false);
    };

    const handleDeleteFromList = (symbol) => {
        const newList = [...symbols];
        newList.splice(symbols.indexOf(symbol), 1);
        setSymbols(newList);
    };
    const text = useText((x) => x.modals.addManyToListContent);
    const handleResultSelect = async (symbol) => {
        if (symbols.find((s) => s === symbol) === undefined) {
            let actualSymbol = symbol;

            // Algolia returns base contract symbols. Find the front month symbol and add that instead.
            if (FuturesSymbol.IsFuturesSymbol(symbol)) {
                const contracts = (await dispatch(GetFuturesContractsAction(symbol))) as FuturesContract[];

                // Validate that the contract is a valid futures symbol
                actualSymbol = contracts.find((c) => c.symbol === symbol)?.symbol;

                // If the symbol is not a valid futures symbol, default to the first contract
                if (!actualSymbol) actualSymbol = contracts?.[0]?.symbol;

                if (!actualSymbol) return; // TODO Handle UX if can't find futures front month
            }

            setSymbols([actualSymbol, ...symbols]);
        }
    };

    return (
        <Flex
            column
            align='center'
            justify='space-between'
            style={{
                width: props.variant === 'sidebar' ? '100%' : 400,
                height: props.variant === 'sidebar' ? 200 : 300,
                maxHeight: '80vh',
                background: colors.cardBackgroundColor,
                borderRadius: 10,
                overflow: 'auto',
                boxSizing: 'border-box',
                padding: '0px 15px'
            }}
        >
            <Flex column align='center' style={{ width: '100%', padding: '10px 0px', boxSizing: 'border-box' }}>
                <MainSearchBox fullWidth hideLogo hideMetadata translations={{ placeholder }} onResultClick={(symbol: string) => handleResultSelect(symbol)} />
                {!symbols.length ? (
                    <Typography style={{ marginTop: props.variant === 'sidebar' ? 32 : 60, width: '90%', textAlign: 'center' }} variant='h5'>
                        {text.title}
                    </Typography>
                ) : (
                    <Flex row wrap style={{ marginTop: 20, width: '100%', overflow: 'hidden' }}>
                        {symbols.map((s, key) => (
                            <SnexBadge flavor='blue' key={key} onDelete={() => handleDeleteFromList(s)}>
                                {QualifiedId.RemovePrefix(s)}
                            </SnexBadge>
                        ))}
                    </Flex>
                )}
            </Flex>
            <SnexButton flavor='submit' style={{ margin: '20px 0px' }} onClick={handleAddToList}>
                <Flex row center align='center'>
                    <span>{text.button}</span>
                    <AddCircleOutlineOutlined style={{ color: 'white', fontSize: '1.25rem', marginLeft: '.5rem' }} />{' '}
                </Flex>
            </SnexButton>
        </Flex>
    );
});
