import { useEffect } from 'react'

/**
 * Lets you run asynchronous effects without all the boilerplate. IMPORTANT: does not support
 * unmount / update callbacks (hence the `Promise<void>` callback return type). You'll have to
 * define unmount / update callbacks using a regular `useEffect`.
 */
export const useAsyncEffect = (run: () => Promise<void>, deps: any[]) => {
    useEffect(() => { run() }, deps)
}
