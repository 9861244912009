// @ts-strict-ignore
import { ApiError } from '../../../models'

interface ErrorDetails extends ApiError {
    subject: string;
    display: 'toast' | 'inline' | 'none';
}

export class ErrorsState {
    apiErrors: {
        latest: ErrorDetails;
        all: ErrorDetails[];
    }

    constructor () {
        this.apiErrors = {
            latest: null,
            all: []
        }
    }
}
