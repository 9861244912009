import { ModeNight, SvgIconComponent } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Flex } from 'components/Flex';
import React, { useMemo } from 'react';

type StylesObj = {
    [orientation: string]: {
        containerDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse';
        padding: string;
        writingMode: string | undefined;
        textOrientation: string | undefined;
        textMargin: string;
    };
};

const getStyles = (orientation: 'left' | 'right' | 'top' | 'bottom' = 'left') => {
    const orientationObj = {
        left: {
            containerDirection: 'row' as const,
            padding: '8px 24px 8px 12px',
            writingMode: undefined,
            textOrientation: undefined,
            textMargin: '0 0 0 -12px'
        },
        right: {
            containerDirection: 'row-reverse' as const,
            padding: '8px 12px 8px 24px',
            writingMode: undefined,
            textOrientation: undefined,
            textMargin: '0 -12px 0 0'
        },
        top: {
            containerDirection: 'column' as const,
            padding: '12px 8px 24px 8px',
            writingMode: 'vertical-rl' as const,
            textOrientation: 'upright' as const,
            textMargin: '-12px 0 0 0'
        },
        bottom: {
            containerDirection: 'column-reverse' as const,
            padding: '24px 8px 12px 8px',
            writingMode: 'vertical-rl' as const,
            textOrientation: 'upright' as const,
            textMargin: '0 0 -12px 0'
        }
    };

    return orientationObj[orientation];
};

export const TimeBadge = ({
    orientation = 'left',
    animated = true,
    icon = ModeNight,
    text = 'Post Market Hours',
    backgroundColor = '#d9e8f1',
    textColor = '#02679e',
    style,
    onHover
}: {
    orientation?: 'left' | 'right' | 'top' | 'bottom';
    animated?: boolean;
    icon?: SvgIconComponent;
    text?: string;
    backgroundColor?: string;
    textColor?: string;
    style?: React.CSSProperties;
    onHover?: (isHover: boolean) => void;
}): JSX.Element => {
    const Icon = icon;

    const { containerDirection, padding, writingMode, textOrientation, textMargin } = useMemo(() => getStyles(orientation), [orientation]);

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                transition: '1.25s',
                flexDirection: containerDirection,
                ...style
            }}
            onMouseEnter={() => onHover && onHover(true)}
            onFocus={() => onHover && onHover(true)}
            onBlur={() => onHover && onHover(false)}
            onMouseLeave={() => onHover && onHover(false)}
            tabIndex={0}
        >
            <Flex justify='center' align='center' style={{ borderRadius: '50%', height: 42, width: 42, minWidth: 42, backgroundColor, color: textColor, zIndex: 5 }}>
                <Icon />
            </Flex>
            <div
                style={{
                    margin: textMargin,
                    padding,
                    backgroundColor,
                    alignSelf: 'center',
                    writingMode,
                    textOrientation,
                    zIndex: 4
                }}
            >
                <Typography
                    style={{
                        color: textColor,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        transition: '100ms'
                    }}
                >
                    {text}
                </Typography>
            </div>
        </div>
    );
};
