import { Urls } from 'phoenix/constants';
import { OrderSearchFiltersV3 } from 'phoenix/redux/actions';
import { Order, OrderIdentifierType } from 'phoenix/redux/models';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from 'phoenix/redux/models/Messages/SnexOrderUpdateMessagePayload';
import { Page, PaginationOffsets } from 'phoenix/redux/models/Paging/Page';
import { create } from 'zustand';
import { SnexAxios } from './AxiosHelpers';
import { matchOrder, isMessageRelevant, withUpdateOrder } from './ordersHelpers';

type OrdersStoreData = {
    openOrders: Order[];
    openOrdersLoading: boolean;
    openOrdersPristine: boolean;
    searchResults: Order[];
    searchResultsLoading: boolean;
    searchResultsPristine: boolean;
    nextOffsets: PaginationOffsets | null;
    noMoreResults: boolean;
};

type OrdersStoreMethods = {
    searchOrders: (filters: OrderSearchFiltersV3, append?: boolean, offsets?: PaginationOffsets) => Promise<void>;
    getAllOpenOrders: (options?: { page?: number; size?: number }) => Promise<void>;
    getSingleOrder: (orderIdentifier: string, orderIdentifierType?: OrderIdentifierType, useStored?: boolean) => Promise<void>;
    retrieveOrder: (order: Order) => Order | null;
    addNewOrder: (order: Order) => void;
    removeOrder: (order: Order | string) => void;
    clearOrders: () => void;
};

export type OrdersStoreState = OrdersStoreData & OrdersStoreMethods;

export const useOrdersStore = create<OrdersStoreState>((set, get) => ({
    openOrders: [],
    openOrdersLoading: false,
    openOrdersPristine: true,
    searchResults: [],
    searchResultsLoading: false,
    searchResultsPristine: true,
    nextOffsets: null,
    noMoreResults: false,

    searchOrders: async (filters, append, offsets) => {
        if (!append) {
            set({ searchResults: [] });
        }
        set({ searchResultsLoading: true });
        const result = await SnexAxios.ApiGet<Page<Order>>(Urls.orders.searchV3(filters, offsets)).run();
        set({ searchResultsLoading: false, searchResultsPristine: false });
        const searchResults = append ? [...get().searchResults, ...result.items] : result.items;

        set((s) => {
            return {
                searchResults,
                nextOffsets: result.nextOffsets,
                noMoreResults: s.searchResults.length === searchResults.length
            };
        });
    },

    getAllOpenOrders: async (options) => {
        const { page = 1, size = 20 } = options || {};
        set({ openOrdersLoading: true });
        const result = await SnexAxios.ApiGet<Order[]>(Urls.orders.openV3({ page, size })).run();
        set((s) => {
            return { openOrders: [...s.openOrders, ...result], openOrdersLoading: false, openOrdersPristine: false };
        });
    },

    getSingleOrder: async (orderIdentifier, orderIdentifierType = 'OrderId') => {
        const result = await SnexAxios.ApiGet<Order>(Urls.orders.getOneV3(orderIdentifier, orderIdentifierType))
            .withMutex(() => `single-order-${orderIdentifier}`, true)
            .run();

        // If we're fetching an order and it's not found, we don't want to add it to the store
        // I.e., Vulcan API returning an empty array for a DOLE order number request
        if (!result) return;

        const existingOrder = get().searchResults.find((o) => matchOrder(o, result)) || get().openOrders.find((o) => matchOrder(o, result));

        if (existingOrder) {
            set((s) => {
                return {
                    searchResults: s.searchResults.map((o) => (matchOrder(o, result) ? result : o)),
                    searchResultsPristine: false,
                    openOrders: s.openOrders.map((o) => (matchOrder(o, result) ? result : o)),
                    openOrdersPristine: false
                };
            });
        } else {
            set((s) => {
                return { searchResults: [...s.searchResults, result], searchResultsPristine: false, openOrders: [...s.openOrders, result], openOrdersPristine: false };
            });
        }
    },

    addNewOrder: (order: Order) => {
        set((s) => {
            return {
                searchResults: [...s.searchResults, order],
                openOrders: [...s.openOrders, order]
            };
        });
    },

    removeOrder: (order) => {
        let tempOrder = order;
        if (typeof order === 'string') {
            const storeOrder = get().searchResults.find((o) => o.orderId === order) || get().openOrders.find((o) => o.orderId === order);
            if (!storeOrder) return;
            tempOrder = storeOrder;
        }
        set((s) => {
            return {
                searchResults: s.searchResults.filter((o) => !matchOrder(o, tempOrder as Order)),
                openOrders: s.openOrders.filter((o) => !matchOrder(o, tempOrder as Order))
            };
        });
    },

    retrieveOrder: (order) => {
        const storeOrder = get().searchResults.find((o) => matchOrder(o, order)) || get().openOrders.find((o) => matchOrder(o, order));
        if (!storeOrder) return null;
        return storeOrder;
    },

    clearOrders: () => {
        set({
            openOrders: [],
            openOrdersLoading: false,
            openOrdersPristine: true,
            searchResults: [],
            searchResultsLoading: false,
            searchResultsPristine: true,
            nextOffsets: null,
            noMoreResults: false
        });
    }
}));

export const OrdersStore_RefreshOrders = async (filter: OrderSearchFiltersV3 = { take: 20 }): Promise<void> => {
    useOrdersStore.getState().clearOrders();
    await useOrdersStore.getState().getAllOpenOrders();
    await useOrdersStore.getState().searchOrders(filter);
};

export const OrdersStore_MessageHandler = (message: SnexMessage): void => {
    if (!isMessageRelevant(message)) return;

    const updatedOrder = message.data as SnexOrderUpdateMessagePayload & Order;
    const fromColorPalette = !updatedOrder.orderId;

    if (fromColorPalette) {
        const order = useOrdersStore.getState().retrieveOrder(updatedOrder);

        const isUpdatePartialFill =
            updatedOrder?.leavesQuantity && updatedOrder.leavesQuantity > 0 && order?.leavesQuantity && order.leavesQuantity > updatedOrder.leavesQuantity;
        const isOrderPartiallyFilled = order?.leavesQuantity && order.leavesQuantity !== order.orderQuantity;

        // Use partial fill data from color pallete to immediately update the fills, but we don't want to fetch from torch since the fills take considerable time to register with torch
        if (isUpdatePartialFill || isOrderPartiallyFilled) {
            return useOrdersStore.setState((s) => {
                return {
                    searchResults: withUpdateOrder(s.searchResults, updatedOrder),
                    openOrders: withUpdateOrder(s.openOrders, updatedOrder)
                };
            });
        }

        // Show color palette opens as pending, so user gets immediate feedback their order is in flight
        if (updatedOrder.orderStatus.toLowerCase() === 'open') {
            useOrdersStore.getState().addNewOrder({ ...updatedOrder, orderStatus: 'Pending' });
        }

        // If a update is from color palette, fetch from api to get missing/consistent data.
        // For fills we don't want to fetch the data from torch until it knows it has been filled, so we delay the fetch.
        const delay = updatedOrder.orderStatus.toLowerCase() === 'filled' ? 500 : 10;

        setTimeout(async () => {
            await useOrdersStore.getState().getSingleOrder(updatedOrder.orderNumber, 'OrderNumber');
        }, delay);

        // For most cases we don't want to don't show the data from color palette, we only use it to trigger fetch of torch data.
        return;
    }

    if (useOrdersStore.getState().retrieveOrder(updatedOrder) === null) {
        return useOrdersStore.getState().addNewOrder({ ...updatedOrder });
    } else {
        return useOrdersStore.setState((s) => {
            return {
                searchResults: withUpdateOrder(s.searchResults, updatedOrder),
                openOrders: withUpdateOrder(s.openOrders, updatedOrder)
            };
        });
    }
};
