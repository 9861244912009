// @ts-strict-ignore
import { CompletedOnboStatuses } from 'phoenix/constants/EquitiesAccountStatuses';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { GetClientAccountsAction, GetPendingFuturesAccounts } from 'phoenix/redux/actions';
import { GetAccountOpeningsAction } from 'phoenix/redux/actions/AccountOpeningActions';
import { Account } from 'phoenix/redux/models';
import { AccountOpening, PendingApplicationStatuses } from 'phoenix/redux/models/AccountOpenings/AccountOpening';
import { AssetClass, QualifiedId } from 'phoenix/util/QualifiedId';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

interface ActiveAndPendingAccounts {
    loading: boolean;
    accounts?: Account[];
    pendingAccounts?: AccountOpening[];
    fixedPlusEligibleAccounts?: Account[]
    inProgressAccountOpenings?: AccountOpening[];
    accountTypes: Set<AssetClass>;
    hasError: boolean;
}

interface ActiveAndPendingAccountsConfig {
    withRequests?: boolean;
}

const fixedPlusAllowedSubs = ['205']

export const useActiveAndPendingAccounts = (config?: ActiveAndPendingAccountsConfig): ActiveAndPendingAccounts => {
    const withRequests = useMemo(() => config?.withRequests, [config?.withRequests]);
    const dispatch = useDispatch();
    const accounts = useSnexStore((s) => s.accounts?.all);
    const accountOpenings = useSnexStore((s) => s.accountOpenings.all);
    const pendingFuturesAccounts = useSnexStore((s) => s.accounts.pendingFuturesAccounts);
    const pendingFuturesLoading = (pendingFuturesAccounts?.loading && !pendingFuturesAccounts?.data?.length) || pendingFuturesAccounts?.pristine
    const openingsLoading = (accountOpenings?.loading && !accountOpenings?.data?.length) || accountOpenings?.pristine
    const accountsLoading = (accounts?.loading && !accounts?.data?.length) || accounts?.pristine
    const hasError = !!accounts?.errors || !!accountOpenings?.errors || !!pendingFuturesAccounts?.errors

    useEffect(() => {
        if (!withRequests) return;
        if (pendingFuturesAccounts?.pristine && !pendingFuturesAccounts?.loading) dispatch(GetPendingFuturesAccounts());
        if (accounts?.pristine && !accounts?.loading) dispatch(GetClientAccountsAction());
        if (accountOpenings?.pristine && !accountOpenings?.loading) dispatch(GetAccountOpeningsAction());
    }, [accountOpenings, accounts, pendingFuturesAccounts, withRequests, hasError]);

    const pendingAccounts = useMemo(() => accountOpenings?.data?.filter((a) => PendingApplicationStatuses.has(a.accountApplicationStatus)), [accountOpenings?.data]);

    const inProgressAccountOpenings = useMemo(() => accountOpenings?.data?.filter((a) => !CompletedOnboStatuses.has(a.onboardingStatus)), [accountOpenings?.data]);

    const filteredAccounts = useMemo(() => {
        if (accountsLoading && pendingFuturesLoading) return [];
        if (!pendingFuturesAccounts?.data?.length) return accounts?.data;
        const filteredClientAccounts =
            accounts?.data?.filter((a) => pendingFuturesAccounts?.data?.every((pendingFuturesAccount) => pendingFuturesAccount.accountNumber !== a.accountNumber)) || [];
        return [...filteredClientAccounts, ...(pendingFuturesAccounts?.data || [])];
    }, [accounts?.data?.length, pendingFuturesAccounts?.data?.length, accountsLoading, pendingFuturesLoading]);

    const loading = useMemo(
        () => (accountsLoading || openingsLoading || pendingFuturesLoading) && ![...filteredAccounts, pendingAccounts]?.length,
        [accountsLoading, openingsLoading, filteredAccounts?.length, pendingAccounts?.length]
    );

    const fixedPlusEligibleAccounts = useMemo(() => filteredAccounts?.filter(a =>  
        fixedPlusAllowedSubs.includes(a.subNo?.toString()) || 
        fixedPlusAllowedSubs.includes(a.subNumber?.toString())), [filteredAccounts, fixedPlusAllowedSubs])

    const accountTypes: Set<AssetClass> = useMemo(() => new Set(filteredAccounts.map((a) => QualifiedId.Class(a.accountNumber))), [filteredAccounts?.length, loading]);
    
    return {
        loading,
        accounts: filteredAccounts,
        fixedPlusEligibleAccounts,
        pendingAccounts,
        inProgressAccountOpenings,
        accountTypes,
        hasError
    };
};

interface RelatedAccounts {
    account?: Account;
    pendingAccount?: AccountOpening;
}

export const useRelatedAccounts = (accountNumber: string): RelatedAccounts => {
    const { accounts, pendingAccounts } = useActiveAndPendingAccounts();
    return {
        account: accounts?.find((a) => a.accountNumber === accountNumber),
        pendingAccount: pendingAccounts?.find((a) => a.accountNumber === accountNumber)
    };
};
