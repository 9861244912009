import { ApiData } from '../../../models'
import { PositionsSummary } from './PositionsSummary'
import { SnexClosedPositionGroup } from './ClosedPosition'

export class PositionsState {
    all: ApiData<PositionsSummary[]>;
    closed: ApiData<SnexClosedPositionGroup[]>;
    moreAvailable: boolean;
    searchQuery: string;
    pageNumber: number;

    constructor () {
        this.all = new ApiData<PositionsSummary[]>([])
        this.closed = new ApiData<SnexClosedPositionGroup[]>([])
        this.moreAvailable = false
        this.searchQuery = ''
        this.pageNumber = 1
    }
}
