import { OptionSymbol } from "phoenix/redux/models";
import { FuturesSymbol } from "phoenix/redux/models/Futures/FuturesSymbol";


export const EffectiveSymbolForLogo = (symbol: string): string => {
    const optSym = new OptionSymbol(symbol);
    const fsym = new FuturesSymbol(symbol);

    switch (true) {
        case fsym.isFuture && optSym.isOption:
            return new FuturesSymbol(optSym.underlyingSymbol).baseContract;
        case fsym.isFuture && !optSym.isOption:
            return fsym.baseContract;
        case !fsym.isFuture && optSym.isOption:
            return optSym.underlyingSymbol;
        default:
            return symbol;
    }
}