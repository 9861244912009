// @ts-strict-ignore
import { CancelOutlined } from '@mui/icons-material';
import { useColors } from 'hooks/UseColors';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { WatchlistSecurity } from 'phoenix/redux/models';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { TradeableSecurityType } from 'phoenix/util';
import React, { useEffect, useMemo } from 'react';
import { Card, Flex, StyledIcon } from '../..';
import style from '../style.module.scss';
import { WatchlistCellProps } from '../WatchlistCell';
import { EquityWatchlistCell } from '../WatchlistCell/EquityWatchlistCell';
import { FuturesWatchlistCell } from '../WatchlistCell/FuturesWatchlistCell';

export interface SearchResultCellProps {
    getCached?: boolean;
    onDelete?: () => void;
    removable?: boolean;
    secType?: TradeableSecurityType;
    security?: WatchlistSecurity;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
}

export const SearchResultCell = React.memo((props: SearchResultCellProps) => {
    const { removable, symbol } = props;
    const { loadData, getMetadataBySymbol } = useSecurityMetadataV2();
    const metadata = getMetadataBySymbol(symbol);
    const colors = useColors();

    useEffect(() => {
        if (!metadata) {
            loadData(symbol);
        }
    }, [loadData, metadata, symbol]);

    const onDelete = (e) => {
        e.stopPropagation();
        if (props.onDelete) props.onDelete();
    };

    const security = useMemo(() => ({ name: metadata?.info?.securityName || '', symbol }), [metadata?.info?.securityName, symbol]);

    return (
        <Card>
            <Flex row className={`${style.withoutHoverEffectWrapper}`}>
                <Flex row className={`${style.innerWrapper} ${style.withoutBorder}`} style={{ minHeight: 60 }}>
                    <Content {...props} security={security} />
                    <Flex align='center' className={style.deleteAction} justify='flex-end' {...{ ...(removable && { tabIndex: 0, onClick: onDelete }) }}>
                        {removable && <StyledIcon IconComponent={CancelOutlined} size='22px' style={{ color: colors.fadedTextColor }} />}
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
});

export const Content = React.memo((props: WatchlistCellProps) => {
    const { getCached, streamingNamespace, secType: incomingType, visible, security, symbol } = props;
    const commonProps = { getCached, streamingNamespace, visible, security, symbol };
    const secType = incomingType || GetAssetClassForSecurity(symbol).type;
    /* Showcasing all the case we can possible expand to if needed and then some.  */
    switch (secType) {
        case 'future':
            return <FuturesWatchlistCell {...commonProps} />;
        case 'option':
        case 'mutual-fund':
        case 'equity':
        case 'adr':
        case 'unknown':
        default:
            return <EquityWatchlistCell {...commonProps} />;
    }
});
