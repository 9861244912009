// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"

export const registration_welcome_step: OnboardingStep = {
    label: "Welcome",
    isComplete: () => useRegistrationStore.getState().isWelcomed,
    isEnabled: () => true,
    isCurrent: () => ['welcome'].includes(useRegistrationStore.getState().selectedScreenId),
    onClickGoToScreenId: 'welcome'
}

export const registration_welcome_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useRegistrationStore.getState().setValue('isWelcomed', true)
        useRegistrationStore.getState().setValue('selectedScreenId', 'userInfo')
    },
    isPrimary: true,
    isEnabled: () => true
}

export const registration_welcome_screen: OnboardingScreen = {
    id: "welcome",
    validate: NoValidation,
    buttons: [registration_welcome_nextButton],
    title: T(t => t.webRegistration.welcome),
    subtitles: [T(t => t.webRegistration.getAnAccount)],
    body: null
}