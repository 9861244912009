import { useSnexStore } from "phoenix/hooks/UseSnexStore"
import { useAssetClass } from "phoenix/models/AssetClasses/useAssetClass"
import { FuturesSymbol } from "phoenix/redux/models/Futures/FuturesSymbol"
import { GetDecimalPlaces, MoneyFormatOptions } from "phoenix/util"
import { QualifiedId } from "phoenix/util/QualifiedId"

export type PriceFormatInfo = {
    tickSize: number,
    decimalPlaces: number,
    moneyFormatOptions: MoneyFormatOptions
}

/** @deprecated -- Use useMoneyFormat instead */ export const usePriceFormatInfo = (qsi: string) : PriceFormatInfo => {
    const ac = useAssetClass(qsi)
    const meta = useSnexStore(s => s.securities.bySymbol[qsi]?.metadata?.data)
    const fmt = ac.getPriceFormatInfo(meta);
    return fmt;
}

export const useMoneyFormat = (qsi: string) : MoneyFormatOptions => {
    const ac = useAssetClass(qsi)
    const meta = useSnexStore(s => s.securities.bySymbol[qsi]?.metadata?.data)
    const fmt = ac.getPriceFormatInfo(meta);
    return fmt.moneyFormatOptions;
}

/** @deprecated -- Comprehensive price format info cannot be determined without SecurityMetadata */
export const GetPriceFormatInfo = (qsi: string, tickSize: number): PriceFormatInfo => {

    const defaultSize = FuturesSymbol.IsFuturesSymbol(qsi) ? 0.0001 : 0.01
    const type = QualifiedId.Class(qsi)

    const ts = Math.abs(tickSize || defaultSize)
    const places = GetDecimalPlaces(ts);
    return {
        tickSize: ts,
        decimalPlaces: places,
        moneyFormatOptions: {
            showDecimals: true,
            decimalPlaces: places,
            hideCurrencySymbol: type === 'futures'
        }
    }

}
