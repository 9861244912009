// @ts-strict-ignore
import { Typography } from '@mui/material';
import { UseSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TimeInForceSelect } from 'components/TradeTicket/Shared/TimeInForceSelect';
import { DisplayOrderType, OptionsTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { ApiTimeInForce } from 'phoenix/models/ApiTradeRequest';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { FormatNumber, isNotANumber } from 'phoenix/util';
import { getMultiLegDefaultPrice, getMultiLegEstimatedTotalCostCredit, getMultiLegNetAskBid } from 'phoenix/util/OptionsHelpers';
import { XS } from 'phoenix/xstream/XS';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Flex } from '..';
import { AccountDropdown } from '../AccountDropdown';
import { TradeCancelHeader } from '../TradeTicket/Headers/TradeCancelHeader';
import { TradeInputHeader } from '../TradeTicket/Headers/TradeInputHeader';
import { OptionTradeList } from '../TradeTicket/Option/OptionTradeList';
import { AlgoControl, BuyingPowerDisplayV2, MultiLegOptionPriceRow, OptionUnderlyingHoldingsDisplay } from '../TradeTicket/Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../TradeTicket/Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../TradeTicket/Shared/TradeTicketSection';

interface OptionTradeInputPageProps {
    color?: string;
    logo: ApiData<SecurityLogo>;
    orderAgain?: boolean;
}

export default (props: OptionTradeInputPageProps): ReactElement => {
    const { logo, orderAgain } = props;
    const { symbol } = useParams<{ symbol: string }>();
    const assetClass = useAssetClassMetaV2(symbol);
    const marketMultiLegEnabled = useSnexStore((s) => s.user.myInfo?.data?.marketMultiLegEnabled);
    const permittedOrderTypes: DisplayOrderType[] = marketMultiLegEnabled ? ['market', 'limit'] : ['limit'];
    const orderTypeDisabled = !marketMultiLegEnabled;
    // Reset and rerender price input when Bid/Ask buttons are clicked to "reset" price
    const [limitInputKey, setLimitInputKey] = useState<number>(0);
    const viewModel = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        debitCredit,
        initialLimitPrice,
        initialQuantity,
        leg2Quantity,
        leg2Symbol,
        leg2TradeAction,
        limitPrice,
        orderType,
        quantity,
        setViewModel,
        symbol: symbolFromStore,
        tradeAction
    } = viewModel;

    const langPack = useText((s) => s);
    const text = langPack.tradeTicket.input;
    const [selectedAccountNumber] = UseSelectedAccount();
    const [marketTimeSegment] = useMarketTimeSegmentV2();

    const optSym = new OptionSymbol(symbolFromStore);
    const osiSym = optSym.toOsiSymbol();
    const { loadData, getMetadataBySymbol } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(osiSym);
    const leg1Quote = XS.OptionQuotes.use(symbolFromStore);
    const leg2Quote = XS.OptionQuotes.use(leg2Symbol);
    const dispatch = useDispatch();

    const multiLegNetAskBid = useMemo(
        () => getMultiLegNetAskBid({ leg1Quote, leg2Quote, quantity: quantity || initialQuantity, tradeAction, leg2Quantity, leg2TradeAction }),
        [leg1Quote, leg2Quote, quantity, initialQuantity, tradeAction, leg2Quantity, leg2TradeAction]
    );

    const { netAsk, netBid, midpoint } = multiLegNetAskBid;

    const effectiveLimitPrice = limitPrice || initialLimitPrice;
    const estimatedLimitPrice = effectiveLimitPrice;
    const { initialPrice: estimatedMarketPrice, debitCredit: marketDebitCredit } = getMultiLegDefaultPrice({
        leg1Quote,
        leg2Quote,
        orderType,
        quantity: quantity || initialQuantity,
        tradeAction,
        leg2Quantity,
        leg2TradeAction
    });

    const limitTitle = debitCredit === 'Debit' ? text.estimatedTotalCost : text.estimatedTotalGain;
    const marketTitle = marketDebitCredit === 'Debit' ? text.estimatedTotalCost : text.estimatedTotalGain;
    const orderPrice = orderType === 'limit' ? estimatedLimitPrice : estimatedMarketPrice;
    const estimatedPrice = getMultiLegEstimatedTotalCostCredit({ leg2Quantity, orderPrice, quantity, deliverableCount: meta?.sizing?.deliverableCount }) || 0;
    const title = orderType === 'limit' ? limitTitle : marketTitle;

    const userInfo = useSnexStore((s) => s.user?.myInfo.data);
    const hasAdvancedRouting = useMemo(() => userInfo?.hasAdvancedRouting, [userInfo]);
    const showAlgo = hasAdvancedRouting && marketTimeSegment === 'open';

    const handleAccountSelect = useCallback(() => {
        if (awaitingConfirmation) {
            setViewModel({ awaitingConfirmation: false });
        }
    }, [awaitingConfirmation, setViewModel]);

    useEffect(() => {
        if (leg2Symbol && !leg2Quote) {
            dispatch(GetSecurityQuoteAction(leg2Symbol));
            loadData(leg2Symbol);
        }
    }, [dispatch, leg2Symbol, loadData]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text}
                    symbol={symbol}
                    title={symbol}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: null })}
                />
            ) : (
                <TradeInputHeader orderTypeDisabled={orderTypeDisabled} customActions={[]} orderType={'limit'} permittedOrderTypes={permittedOrderTypes} />
            )}
            <TradeTicketSection style={{ paddingTop: 10, paddingBottom: 10 }}>
                <AccountDropdown
                    assetFamily={assetClass.family}
                    balanceType={'custom'}
                    defaultToFirst={'if-only-one'}
                    isByAssetClass
                    onSelect={handleAccountSelect}
                    skipInitialSelect={!!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                />
                <OptionTradeList orderAgain={orderAgain} />
                <Flex column>
                    {orderType === 'limit' ? (
                        <>
                            <MultiLegOptionPriceRow key={limitInputKey} />
                            <div className='bid-ask-buttons space-between'>
                                <button
                                    disabled={!netBid}
                                    onClick={() => {
                                        setViewModel({
                                            initialLimitPrice: netBid,
                                            limitPrice: undefined
                                        });
                                        setLimitInputKey(limitInputKey + 1);
                                    }}
                                >
                                    <Typography variant='body1'>{text.options.netBid}</Typography>
                                    <Typography variant='body2'>{FormatNumber.toMoneyOpt2(netBid)}</Typography>
                                </button>
                                <button
                                    disabled={!midpoint}
                                    onClick={() => {
                                        setViewModel({
                                            initialLimitPrice: midpoint,
                                            limitPrice: undefined
                                        });
                                        setLimitInputKey(limitInputKey + 1);
                                    }}
                                >
                                    <Typography variant='body1'>{text.options.midpoint}</Typography>
                                    <Typography variant='body2'>{FormatNumber.toMoneyOpt2(midpoint)}</Typography>
                                </button>
                                <button
                                    disabled={!netAsk}
                                    onClick={() => {
                                        setViewModel({
                                            initialLimitPrice: netAsk,
                                            limitPrice: undefined
                                        });
                                        setLimitInputKey(limitInputKey + 1);
                                    }}
                                >
                                    <Typography variant='body1'>{text.options.netAsk}</Typography>
                                    <Typography variant='body2'>{FormatNumber.toMoneyOpt2(netAsk)}</Typography>
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='market-price-display'>
                                <Typography className='label' variant='h6'>
                                    {text.marketPrice}
                                </Typography>
                                <Typography style={{ fontSize: 20, fontWeight: 500 }} variant='h6'>
                                    {FormatNumber.toMoney(estimatedMarketPrice)}
                                </Typography>
                            </div>
                            <Flex row justify='space-between' className={orderType === 'market' ? 'bid-ask-display' : ''}>
                                <Flex center column>
                                    <Typography variant='body1'>{text.options.netBid}</Typography>
                                    <Typography variant='body2'>{FormatNumber.toMoney(netBid)}</Typography>
                                </Flex>
                                <Flex center column>
                                    <Typography variant='body1'>{text.options.midpoint}</Typography>
                                    <Typography variant='body2'>{FormatNumber.toMoney(midpoint)}</Typography>
                                </Flex>
                                <Flex center column>
                                    <Typography variant='body1'>{text.options.netAsk}</Typography>
                                    <Typography variant='body2'>{FormatNumber.toMoney(netAsk)}</Typography>
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Flex>
                <OptionUnderlyingHoldingsDisplay accountNumber={selectedAccountNumber} underlyingSymbol={meta?.option?.underlyingSymbol} />
                {orderType !== 'market' && <TimeInForceSelect onValueChange={(timeInForce: ApiTimeInForce) => setViewModel({ timeInForce })} />}
                {!isNotANumber(quantity) && !isNotANumber(leg2Quantity) && (
                    <Flex row center style={{ height: '48px', lineHeight: '48px' }}>
                        <Typography variant='h6' style={{ flex: '1 1 auto', fontWeight: 800 }}>
                            {title}
                        </Typography>
                        <Typography variant='h6' style={{ flex: '0' }}>
                            {FormatNumber.toMoney(estimatedPrice)}
                        </Typography>
                    </Flex>
                )}
                {showAlgo && <AlgoControl />}
                <TradeInputValidationButton />
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={symbol} useOptionsBuyingPower={true} />
            </TradeTicketSection>
        </Flex>
    );
};
