// @ts-strict-ignore
import { SecurityDom } from 'phoenix/redux/models/Securities/SecurityDom'
import { StandardQuote } from '../constants/ReduxSelectors'
import { AccountChartPoint, OptionQuote, SecurityChartData } from '../redux/models'

export class XstreamState {
    chartFrequencyInMinutes: number
    quotes: { [qsi: string]: StandardQuote }
    streamingCharts: { [qsi: string]: SecurityChartData[] }
    dom: { [qsi: string]: SecurityDom }
    options: { [osi: string]: OptionQuote }
    accountValuations: { [accountNumber: string]: AccountChartPoint }
    portfolioValuation: AccountChartPoint
    streamingAccountCharts: { [accountNumber: string]: AccountChartPoint[] }
    streamingPortfolioChart: AccountChartPoint[]

    // TODO -- Please replace `chartFrequencyInMinutes` with an options object to make more self-documenting
    constructor (chartFrequencyInMinutes?: number) {
        this.chartFrequencyInMinutes = chartFrequencyInMinutes || 1
        this.quotes = {}
        this.dom = {}
        this.streamingCharts = {}
        this.options = {}
        this.accountValuations = {}
        this.portfolioValuation = null
        this.streamingAccountCharts = {}
        this.streamingPortfolioChart = []
    }
}
