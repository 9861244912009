import { Skeleton, Typography } from '@mui/material';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useColors } from 'hooks/UseColors';
import { FormattedTextList, InfoIconBundle } from 'phoenix/assets/lang/Snex1LanguagePack';
import React, { useMemo } from 'react';
import { Flex } from '..';
import { ArrowLink } from '../ArrowLink';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import './style.module.scss';

interface SectionHeaderProps {
    actionLabel?: string;
    altActionComponent?: ReactJSXElement;
    assetFamily?: string;
    gutterBottom?: boolean;
    headerValue?: string; // Displayed on the right
    infoIconMessagePath?: (lang: InfoIconBundle) => FormattedTextList;
    label: string;
    loading?: boolean;
    midComponent?: ReactJSXElement;
    modalWidth?: number;
    onAction?: () => void;
    route?: string;
    style?: React.CSSProperties;
    subLabel?: string;
    totalValue?: string;
}

export const SectionHeader = (props: SectionHeaderProps): ReactJSXElement => {
    const { grayText } = useColors();
    const {
        actionLabel,
        altActionComponent,
        assetFamily,
        headerValue,
        infoIconMessagePath,
        label,
        loading,
        midComponent,
        modalWidth,
        onAction,
        route,
        style,
        subLabel,
        totalValue
    } = props;

    const styles: React.CSSProperties = useMemo(() => {
        return {
            marginBottom: props.gutterBottom ? 15 : undefined,
            ...style
        };
    }, [style, props.gutterBottom]);

    return (
        <Flex row style={styles} className='account-summary-header' justify='space-between'>
            <Flex row align='center'>
                <Typography className='account-summary-header-text' variant='h5' style={{ fontSize: 20, fontWeight: 700, padding: '15px 0' }}>
                    {label}
                    {!!subLabel && <span style={{ color: grayText }}> - {subLabel}</span>}
                    {!!totalValue && <span style={{ marginLeft: 24 }}>{totalValue}</span>}
                </Typography>
                {midComponent ? (
                    <>
                        <span style={{ marginLeft: 24 }} />
                        {midComponent}
                    </>
                ) : null}
                {loading ? <Skeleton animation='wave' style={{ width: 200 }} /> : null}

                {infoIconMessagePath ? <InfoIcon modalWidth={modalWidth} title={label} messagePath={infoIconMessagePath} /> : null}
            </Flex>

            <Flex row align='center'>
                {!!headerValue && !loading && (
                    <Typography className='account-summary-header-text' variant='h5' style={{ fontSize: 20, fontWeight: 700 }}>
                        {headerValue}
                    </Typography>
                )}

                {onAction && actionLabel ? (
                    <ArrowLink className={assetFamily} route={route || undefined} onClick={onAction} text={actionLabel} />
                ) : (
                    altActionComponent || null
                )}
            </Flex>
        </Flex>
    );
};
