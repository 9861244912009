import { useTextStore } from 'phoenix/hooks/UseText'
import { ReduxAction } from '../../../models'
import { GroupBySelectFirst } from '../../../util'
import { Actions } from '../../actions'
import { GlobalState } from '../../GlobalState'
import { AppStartupBundle, AppStartupPartTwoBundle } from '../../models/MobileScreens/AppStartupBundle'
import { asData } from '../ReducerUpdateHelpers'
import { MobileStateUpdates } from 'phoenix/redux/reducers/MobileScreens/MobileStateUpdates'

export const AppStartupReducer = (state: GlobalState, action: ReduxAction): GlobalState => {
    switch (action.type) {
        case Actions.MobileScreens.LoadAppStartup.Success: return AppStartupStageOne(state, action)
        case Actions.MobileScreens.LoadAppStartupPartTwo.Success: return AppStartupStageTwo(state, action)
        default: return state
    }
}

const AppStartupStageOne = (state: GlobalState, action: ReduxAction): GlobalState => {
    const bundle = action.data as AppStartupBundle

    state.market.status = asData(bundle.marketStatus)
    state.market.holidays = asData(bundle.holidays)
    state.system.status = asData(bundle.systemStatus)
    state.system.config = asData(bundle.config)
    state.user.myInfo = asData(bundle.me)

    if (bundle.preferences?.preference?.language?.code !== null)
        state.user.myPrefs = asData(bundle.preferences)

    state.featureFlags.all = asData(bundle.featureFlags)
    state.featureFlags.byId = GroupBySelectFirst(bundle.featureFlags, f => f.id)
    state.messages.systemControls = asData(bundle.messageDeliveryControls)

    return state
}

const AppStartupStageTwo = (state: GlobalState, action: ReduxAction): GlobalState => {
    const bundle = action.data as AppStartupPartTwoBundle

    state = MobileStateUpdates.addMetadata(state, bundle.metadata)
    state.user.myFeatures = asData(bundle.userFeatures)
    state.orders.openOrders = asData(bundle.openOrders)
    state.taxlots.openTaxlots = asData(bundle.openTaxLots)

    return state
}
