
import { ThemeVariant, ThemeVariants } from "phoenix/theming/ThemeVariants";

export const GetDeviceThemeVariant = () : ThemeVariant => {

    const darkMode = window.matchMedia('(prefers-color-scheme: dark)');
    const lightMode = window.matchMedia('(prefers-color-scheme: light)');

    if(darkMode.matches) return ThemeVariants.dark
    if(lightMode.matches) return ThemeVariants.light
    return ThemeVariants.market
}