// @ts-strict-ignore
import { merge } from 'lodash';
import { QuoteSelector } from 'phoenix/constants/ReduxSelectors';
import { useFlattenedPositions } from 'phoenix/hooks/UseFlattenedPositions';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { AccountBuyingPower, useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { SecurityMetadataV2, useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { GetGainLossFigures, GetGainLossFiguresV2, GetTotalGainLossFiguresForFuturesPosition } from 'phoenix/util/HoldingsCalculations';
import { useXstreamStore } from 'phoenix/xstream/useXstreamStore';
import { useMemo } from 'react';
import { createSelector } from 'reselect';
import { ChartRange, ChartRanges } from '../constants';
import { ApiData } from '../models';
import { AccountChartPoint, AccountChartResponse, AccountSummary, ApiPosition, OptionSymbol, SecurityQuote, SymbolSubstate, TaxlotItem } from '../redux/models';
import { First, Last, Sum } from '../util';
import { XS } from '../xstream/XS';
import { useSnexStore } from './UseSnexStore';

export type CalculatedAccountValuation = {
    value: number;
    change: number;
    percent1: number;
    /** @deprecated Please use percent1 */ percent100: number;
    chart: AccountChartPoint[];
    unrealizedGainLoss: number;
    unrealizedGainLossPercent?: number;
    amountInvested: number;
    summary: AccountSummary;
    buyingPower: AccountBuyingPower;
};

/** @deprecated Use useAccountValuationV2 for SecurityMetadataV2 */
export const useAccountValuation = (accountNumber?: string, range?: ChartRange, options?: { withoutMarketTimeRules?: boolean }): CalculatedAccountValuation => {
    const withoutMarketTimeRules = options?.withoutMarketTimeRules;
    const assetClass = useAccountAssetClass(accountNumber);
    const selectQuoteData = useMemo(makeSelectQuoteData, []);
    const allLots = useSnexStore((s) => s.taxlots.openTaxlots);

    if (!range) range = assetClass.accountChartRanges?.[0] || ChartRanges.oneDay;

    const allPositions = usePositionsStore((s) => s.positions);
    const contracts = useSnexStore((s) => s.securities.bySymbol);
    const positions = useFlattenedPositions(accountNumber);

    const buyingPowerStore = useBuyingPowerStore();
    const buyingPower = useMemo(() => (accountNumber ? buyingPowerStore?.findByAccount(accountNumber) : {}), [accountNumber, buyingPowerStore]);

    const summary = useSnexStore((s) => (accountNumber ? s.accountSummary.byNumber[accountNumber]?.data : s.accountSummary.aggregate?.data));

    const symbols = useMemo(() => getFormattedSymbolsFromPositions(positions), [positions]);

    const apiQuotes: { [symbol: string]: SecurityQuote } = useSnexStore((s) => selectQuoteData(s, symbols));
    const xStreamQuotes: { [symbol: string]: SecurityQuote } = useXstreamStore((s) => s.quotes);
    const xStreamOptionsQuotes: { [symbol: string]: SecurityQuote } = useXstreamStore((s) => s.options);

    const quotes = merge({ ...apiQuotes }, { ...xStreamQuotes, ...xStreamOptionsQuotes });

    const { chart, value, change, percent1, percent100 } = useChartPoints(range, accountNumber, withoutMarketTimeRules);

    const glFacts = useGlFacts(positions, contracts, quotes);

    const openLots = useMemo(() => {
        const l = allLots?.data?.taxlGroupItems?.flatMap((i) => i.items) || [];
        return (accountNumber ? l.filter((t) => t.accountNumber === accountNumber) : l) || [];
    }, [allLots, accountNumber]);

    const { unrealizedGainLoss, unrealizedGainLossPercent, amountInvested } = useUnrealizedValues(glFacts, allPositions, openLots, accountNumber);

    return { value, change, percent1, percent100, chart, unrealizedGainLoss, unrealizedGainLossPercent, amountInvested, summary, buyingPower };
};

export const useAccountValuationV2 = (accountNumber?: string, range?: ChartRange, options?: { withoutMarketTimeRules?: boolean }): CalculatedAccountValuation => {
    const withoutMarketTimeRules = options?.withoutMarketTimeRules;
    const assetClass = useAccountAssetClass(accountNumber);
    const selectQuoteData = useMemo(makeSelectQuoteData, []);
    const allLots = useSnexStore((s) => s.taxlots.openTaxlots);

    if (!range) range = assetClass.accountChartRanges?.[0] || ChartRanges.oneDay;

    const allPositions = usePositionsStore((s) => s.positions);
    const contracts = useSecurityMetadataV2((s) => s.data);
    const positions = useFlattenedPositions(accountNumber);

    const buyingPowerStore = useBuyingPowerStore();
    const buyingPower = useMemo(() => (accountNumber ? buyingPowerStore?.findByAccount(accountNumber) : {}), [accountNumber, buyingPowerStore]);

    const summary = useSnexStore((s) => (accountNumber ? s.accountSummary.byNumber[accountNumber]?.data : s.accountSummary.aggregate?.data));

    const symbols = useMemo(() => getFormattedSymbolsFromPositions(positions), [positions]);

    const apiQuotes: { [symbol: string]: SecurityQuote } = useSnexStore((s) => selectQuoteData(s, symbols));
    const xStreamQuotes: { [symbol: string]: SecurityQuote } = useXstreamStore((s) => s.quotes);
    const xStreamOptionsQuotes: { [symbol: string]: SecurityQuote } = useXstreamStore((s) => s.options);

    const quotes = merge({ ...apiQuotes }, { ...xStreamQuotes, ...xStreamOptionsQuotes });

    const { chart, value, change, percent1, percent100 } = useChartPoints(range, accountNumber, withoutMarketTimeRules);

    const glFacts = useGlFactsV2(positions, contracts, quotes);

    const openLots = useMemo(() => {
        const l = allLots?.data?.taxlGroupItems?.flatMap((i) => i.items) || [];
        return (accountNumber ? l.filter((t) => t.accountNumber === accountNumber) : l) || [];
    }, [allLots, accountNumber]);

    const { unrealizedGainLoss, unrealizedGainLossPercent, amountInvested } = useUnrealizedValues(glFacts, allPositions, openLots, accountNumber);

    return { value, change, percent1, percent100, chart, unrealizedGainLoss, unrealizedGainLossPercent, amountInvested, summary, buyingPower };
};

const useChartPoints = (range?: ChartRange, accountNumber?: string, withoutMarketTimeRules?: boolean) => {
    const [segment] = useMarketTimeSegmentV2();
    const chart = useSnexStore((s) => (accountNumber ? s.accountChart.byAccount[accountNumber]?.[range] : s.accountChart.aggregate?.[range]));
    const chartData = useMemo(() => (accountNumber ? (chart as ApiData<AccountChartResponse>)?.data?.data : chart?.data), [accountNumber, chart]);
    const streamingChart = accountNumber ? XS.AccountCharts.use(accountNumber) : XS.PortfolioCharts.use();
    const summaryValues = accountNumber ? XS.AccountValuations.use(accountNumber) : XS.PortfolioValuation.use();
    const withStreamingPoints = useMemo(() => segment === 'open' || withoutMarketTimeRules, [segment, withoutMarketTimeRules]);

    const points = useMemo(() => {
        const p = [...((chartData as AccountChartPoint[]) || []), ...(streamingChart || [])].sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
        return p;
    }, [chartData, streamingChart]);

    const { totalAccountValueCurrent } = summaryValues || {};

    const { value, change, percent1, percent100 } = useMemo(() => {
        const last = Last(points)?.value;
        const first = First(points)?.value;
        const change = last - first;
        let percent = first ? MatchSign(change, change / first) : NaN;

        if (Number.isNaN(percent)) {
            const filteredPoints = removeLeadZerosPoints(points);
            const filteredFirst = First(filteredPoints)?.value;
            const filteredChange = last - filteredFirst;
            percent = MatchSign(filteredChange, filteredChange / filteredFirst);
        }

        // Prioritize the MTM summary stream, if that doesn't exist for some reason default to the last chart point
        // const lastStreaming = withStreamingPoints && streamingChart?.length > 0 ? last : undefined;
        const effectiveValue = totalAccountValueCurrent || last || undefined;
        const data = { value: effectiveValue, change, percent1: percent, percent100: percent * 100 };
        return data;
    }, [points, totalAccountValueCurrent]);

    return { chart: withStreamingPoints ? points : (chartData as AccountChartPoint[]), value, change, percent1, percent100 };
};

const useGlFacts = (positions?: ApiPosition[], contracts?: { [key: string]: Partial<SymbolSubstate> }, quotes?: { [symbol: string]: SecurityQuote }) => {
    const glFacts = useMemo(
        () =>
            positions
                ?.map((p) => {
                    if (!p?.symbol) return null;
                    let optSym = new OptionSymbol(p.description, p.symbol);
                    const secId = optSym.isOption ? QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol) : QualifiedSecurityId.FromPosition(p);

                    // TODO -- Futures specific code
                    if (FuturesSymbol.IsFuturesSymbol(p.symbol)) {
                        optSym = new OptionSymbol(p.secMasterOptionSymbol);
                        const cs = contracts[p.symbol];
                        const contractSize = cs?.metadata?.data?.unitFactor;

                        return { ...GetTotalGainLossFiguresForFuturesPosition(p, quotes[secId.id], contractSize) };
                    }

                    return { ...GetGainLossFigures(p, quotes[secId.id]), symbol: p.symbol, account: p?.accountNumber };
                })
                .filter((p) => p),
        [positions, quotes, contracts]
    ); // Quotes not included as a dep intentionally. We do not want to trigger this everytime a quote updates. We should trigger this when the account/portfolio value updates every minute - Roman C.

    return glFacts;
};

const useGlFactsV2 = (positions?: ApiPosition[], contracts?: { [key: string]: SecurityMetadataV2 }, quotes?: { [symbol: string]: SecurityQuote }) => {
    const glFacts = useMemo(
        () =>
            positions
                ?.map((p) => {
                    if (!p?.symbol) return null;
                    let optSym = new OptionSymbol(p.description, p.symbol);
                    const secId = optSym.isOption ? QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol) : QualifiedSecurityId.FromPosition(p);

                    // TODO -- Futures specific code
                    if (FuturesSymbol.IsFuturesSymbol(p.symbol)) {
                        optSym = new OptionSymbol(p.secMasterOptionSymbol);
                        const contractSize = contracts[p.symbol]?.sizing?.multiplier;

                        return { ...GetTotalGainLossFiguresForFuturesPosition(p, quotes[secId.id], contractSize) };
                    }

                    return { ...GetGainLossFiguresV2(p, quotes[secId.id]), symbol: p.symbol, account: p?.accountNumber };
                })
                .filter((p) => p),
        [positions, quotes, contracts]
    ); // Quotes not included as a dep intentionally. We do not want to trigger this everytime a quote updates. We should trigger this when the account/portfolio value updates every minute - Roman C.

    return glFacts;
};

// TODO -- glFacts needs a type
const useUnrealizedValues = (glFacts, allPositions?: ApiPosition[], openLots?: TaxlotItem[], accountNumber?: string) => {
    const summary = useSnexStore((s) => (accountNumber ? s.accountSummary.byNumber[accountNumber] : s.accountSummary.aggregate));
    const assetClass = useAccountAssetClass(accountNumber);

    // TODO -- Futures-specific code
    const futuresAccountsWithPositions = useMemo(
        () => !accountNumber && allPositions?.filter((p) => FuturesSymbol.IsFuturesSymbol(p.accountNumber)).map((x) => x.accountNumber),
        [allPositions, accountNumber]
    );

    const accountSummaryByNumber: { [key: string]: ApiData<AccountSummary> } = useSnexStore((s) => s.accountSummary.byNumber);

    // TODO -- Futures-specific code
    const futuresSummaries = (
        accountNumber
            ? [summary?.data]
            : Object.entries(accountSummaryByNumber)
                  .filter((e) => futuresAccountsWithPositions?.includes(e[0]))
                  .map((e) => e[1]?.data)
    ).filter((a) => FuturesSymbol.IsFuturesSymbol(a?.accountNumber));

    const unrealizedValues = useMemo(() => {
        const amountInvested = Sum([...openLots?.map((p) => p?.costAmount), ...futuresSummaries.map((s) => s?.totalCashCurrent)]);
        const { unrealizedGainLoss, unrealizedGainLossPercent } = (() => {
            switch (assetClass.family) {
                case 'futures':
                    return { unrealizedGainLoss: summary?.data?.unrealizedPnLCurrent, unrealizedGainLossPercent: null };
                case 'equities':
                default: {
                    const unrealizedGainLoss = Sum(glFacts?.map((p) => p.glTotal).filter((f) => !isNaN(f)));
                    const unrealizedGainLossPercent = (Math.abs(amountInvested) === 0 ? 0 : unrealizedGainLoss / Math.abs(amountInvested)) * 100;
                    return { unrealizedGainLoss, unrealizedGainLossPercent };
                }
            }
        })();

        return { unrealizedGainLoss, unrealizedGainLossPercent, amountInvested };
    }, [openLots, futuresSummaries, assetClass.family, summary?.data?.unrealizedPnLCurrent, glFacts]);
    return unrealizedValues;
};

const makeSelectQuoteData = () =>
    createSelector(
        (state: GlobalState) => state,
        (_: GlobalState, symbols: string[]) => symbols,
        (state, symbols) => symbols?.reduce((f, s) => ({ ...f, [s]: QuoteSelector(s)(state) }), {})
    );

const getFormattedSymbolsFromPositions = (positions: ApiPosition[]) => {
    return positions
        ?.map((p) => {
            const isFuture = FuturesSymbol.IsFuturesSymbol(p.symbol);
            const optSym = new OptionSymbol(isFuture ? p.secMasterOptionSymbol : p.symbol);
            if (optSym.isOption && FuturesSymbol.IsFuturesSymbol(p.symbol)) return p.secMasterOptionSymbol;
            const secId = optSym.isOption ? QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol) : QualifiedSecurityId.FromPosition(p);
            return secId.id;
        })
        .filter((s) => s);
};

const MatchSign = (toMatch: number, toChange: number) => {
    return toMatch < 0 ? -Math.abs(toChange) : Math.abs(toChange);
};

const removeLeadZerosPoints = (points: AccountChartPoint[]) => {
    const _points = [...points];
    while (_points.length > 0 && _points[0].value === 0) {
        _points.shift();
    }
    return _points;
};
