import getSymbolFromCurrency from 'currency-symbol-map'
import { QualifiedId } from 'phoenix/util/QualifiedId'

const brandColors = [
    '#0a3254', // Dark blue
    '#336094', // Medium blue
    '#7aadd4', // Light blue
    '#287151', // Green
    '#e0d253', // Yellow
    '#e99c23', // Orange
    '#b2292e' // Red
]

const moneyGreen = '#2CB333'

export const MakePlaceholderContent = (name: string, isCurrency?: boolean): { color: string, initials: string } => {
    const colorLookup = brandColors

    if (!name) return { color: colorLookup[0], initials: '' }

    // Different rules for currencies
    if (isCurrency) {
        const green = moneyGreen
        return {
            initials: getSymbolFromCurrency(name) || '¤',
            color: green
        }
    }

    // Make initials
    const words = QualifiedId.RemovePrefix(name)
        .replace(/[^A-Za-z0-9\s]/g, '')
        .split(' ')
        .filter(x => !!x) // Sometimes there are double spaces
    const initials = (words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0]?.slice(0, 2))?.toUpperCase() || ''

    // Pick color
    const idx = [...initials].reduce((sum, c) => sum + c.charCodeAt(0), 0) % colorLookup.length
    return { initials, color: colorLookup[idx] }
}
