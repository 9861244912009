import { Flex, PremiumUpgradeModal } from 'components';
import { EquityPositionCell } from 'components/SecurityCell/PositionCell/EquityPositionCell';
import { EquityWatchlistCell } from 'components/SecurityCell/WatchlistCell/EquityWatchlistCell';
import { SelectList, SelectListItem } from 'components/SelectList';
import { T } from 'phoenix/assets/lang/T';
import { Urls } from 'phoenix/constants';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetAllPositions } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { GoldenTicket } from 'phoenix/util';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const YuliDebugScreen = () => {
    return (
        <Flex column justify='flex-start' align='center' style={{ marginTop: 100, flex: 1, width: '100vw', overflow: 'scroll' }}>
            {<EquityWatchlistCell symbol='AMZN' visible={true} />}
        </Flex>
    );
};
