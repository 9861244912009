// @ts-strict-ignore
import { AxiosError } from 'axios';
import { T } from 'phoenix/assets/lang/T';
import { Urls } from 'phoenix/constants';
import { ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { SnexError, SnexWarning, warningVariant } from 'phoenix/models/SnexError';
import { TradeResult } from 'phoenix/models/TradeResult';
import { OptionSymbol, Order } from 'phoenix/redux/models';
import { GetFromSnexStore } from 'phoenix/redux/util/GetFromSnexStore';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { UniqueBy } from 'phoenix/util/ArrayMutations';
import {
    ExceedsUsersMaxNotionalValue,
    ExceedsUsersMaxNotionalValueV2,
    IsInvalidStopOrder,
    OrderWillTriggerResidualSettlement,
    TradeIsForLowVolumeOption,
} from 'phoenix/util/Trading/TradeValidationHelpers';

const DEBUG_TRADE_VALIDATION = false;
const _thDebug = (...args: string[]) => {
    if (DEBUG_TRADE_VALIDATION) console.debug('[TRADE DEBUG]', ...args);
};

export const ValidateSubmitTrade = async (trade: ApiTradeRequest, allowShort = false, ac?: AssetClass, useV2?: boolean): Promise<TradeResult> => {
    try {
        // Submit-Specific Front-End Errors -- break out into separate function if this gets too long
        _thDebug('VST passed specific front-end errors');

        // Common Front-End Errors
        const standardFrontEndValidations = CheckForCommonFrontEndErrors(trade, allowShort, ac, useV2);
        if (!standardFrontEndValidations.success) return standardFrontEndValidations;
        _thDebug('VST passed common front-end errors');

        // Submit-Specific Front-End Warnings
        _thDebug('VST passed specific front-end warnings');

        // Common Front-End Warnings
        const warnings = CheckForCommonFrontEndWarnings(trade, ac);
        _thDebug('VST passed common front-end warnings');

        // Back-End Validations
        const response = await SnexAxios.ApiPost<Order>(Urls.trading.verify(), { data: trade }).withMutex('trade-validation').run();
        const data = response;
        _thDebug('VST received response from back-end validation');
        const localizedWarnings = LocalizeOrderWarnings([...warnings, ...(data?.orderWarnings || [])], trade);
        return { order: data, warnings: localizedWarnings, success: true };
    } catch (e) {
        const x = e as Error;
        console.log('Caught an exception while validating order submission', { e, name: x?.name, message: x?.message, stack: x?.stack });
        return CreateTradeResultForErrorCode((e as AxiosError<SnexError>)?.response?.data?.errorCode || e?.errorCode || 'INTERNAL_FRONTEND', trade, ac);
    }
};

export const ValidateUpdateTrade = async (trade: ApiTradeRequest, allowShort = false, ac?: AssetClass, useV2?: boolean): Promise<TradeResult> => {
    try {
        // Edit-Specific Front-End Errors -- break out into separate function if this gets too long
        if (!trade.orderId) return CreateTradeResultForErrorCode('INTERNAL', trade, ac);
        _thDebug('VUT passed specific front-end errors');

        // Common Front-End Errors
        const standardFrontEndValidations = CheckForCommonFrontEndErrors(trade, allowShort, ac, useV2);
        if (!standardFrontEndValidations.success) return standardFrontEndValidations;
        _thDebug('VUT passed common front-end errors');

        // Edit-Specific Front-End Warnings
        _thDebug('VUT passed specific front-end warnings');

        // Common Front-End Warnings
        const warnings = CheckForCommonFrontEndWarnings(trade, ac);
        _thDebug('VUT passed common front-end warnings');

        // Back-End Validation
        const response = await SnexAxios.ApiPut<Order>(Urls.trading.verifyUpdate(trade.orderId), { data: trade }).withMutex('trade-validation').run();
        const data = response;
        _thDebug('VUT received response from back-end validation');
        const localizedWarnings = LocalizeOrderWarnings([...warnings, ...(data?.orderWarnings || [])], trade);
        return { order: data, warnings: localizedWarnings, success: true };
    } catch (e) {
        console.log('Caught an exception while validating order update', JSON.stringify(e));
        return CreateTradeResultForErrorCode((e as AxiosError<SnexError>)?.response?.data?.errorCode || e?.errorCode || 'INTERNAL_FRONTEND', trade, ac);
    }
};

// === Validation Groups and Conveniences

const CheckForCommonFrontEndErrors = (trade: ApiTradeRequest, allowShort = false, ac: AssetClass, useV2?: boolean): TradeResult => {
    const qsi = trade.securityId;

    if (useV2) {
        if (ExceedsUsersMaxNotionalValueV2(qsi, trade)) return CreateTradeResultForErrorCode('MAX_NOTIONAL_VALUE_EXCEEDED', trade, ac);
        _thDebug('-- does not exceed max notional value');
    } else {
        if (ExceedsUsersMaxNotionalValue(qsi, trade)) return CreateTradeResultForErrorCode('MAX_NOTIONAL_VALUE_EXCEEDED', trade, ac);
        _thDebug('-- does not exceed max notional value');
    }

    if (IsInvalidStopOrder(trade)) return CreateTradeResultForErrorCode(trade.action === 'Sell' ? 'INVALID_SELL_STOP_ORDER' : 'INVALID_BUY_STOP_ORDER', trade, ac);
    _thDebug('-- is not invalid stop order');

    return { success: true };
};

const CheckForCommonFrontEndWarnings = (trade: ApiTradeRequest, ac?: AssetClass): string[] => {
    const warnings = [];

    if (ac?.tradeability?.showPreTradeDisclosure) warnings.push(`PRE_TRADE_DISCLOSURE|${T((t) => t.warnings.pretradeDisclosures[ac.family])}`);

    if (OrderWillTriggerResidualSettlement(trade)) warnings.push(T((s) => s.tradeTicket.misc.residualSharesWillBeSettled));
    _thDebug('-- checked residual settlement warning');

    if (TradeIsForLowVolumeOption(trade)) warnings.push(T((s) => s.warnings.trade.optionsLowVolumeWarning));
    _thDebug('-- checked low-volume warning');

    return warnings;
};

// This function utilizes the full error object; it requires access to properties besides the errorCode
export const CreateTradeResultForError = (error: SnexError, trade: ApiTradeRequest, ac?: AssetClass): TradeResult => {
    // ErrorNote for INSUFFICIENT_QUANTITY_TO_SHORT is a string representing a number, ie: "1.0"
    if (error?.errorCode === 'INSUFFICIENT_QUANTITY_TO_SHORT' && !isNaN(Number(error?.errorDetails?.insufficientQuantityAvailable))) {
        const errorTitle = T((s) => s.tradeTicket.input.cannotShort);
        const errorMessage = T((s) => s.errors.trade.insufficientShortQuantity(Number(error?.errorDetails?.insufficientQuantityAvailable)));
        const result = { error: { errorCode: error.errorCode, errorTitle, errorMessage }, success: false };
        _thDebug(`-- assembled error response for code ${error.errorCode}`);
        return result;
    }

    return CreateTradeResultForErrorCode(error.errorCode, trade, ac);
};

export const CreateTradeResultForErrorCode = (errorCode: string, trade: ApiTradeRequest, ac?: AssetClass): TradeResult => {
    const getErrorTitle = () => {
        switch (errorCode) {
            case 'ACCT_RESTRICTED':
                return T((s) => s.tradeTicket.input.error);
            case 'ORD_CANT_AFFORD':
            case 'CANNOT_AFFORD_BUY':
                return T((s) => s.tradeTicket.input.advisories.insufficientValue.notEnoughBuyingPower);
            case 'INVALID_QTY':
                return T((s) => s.tradeTicket.input.invalidQuantity);
            case 'SHORT_NOTPERMITTED_LONG':
            case 'SHORT_NOTPERMITTED_ACCOUNTNOTMARGIN':
                return T((s) => s.tradeTicket.input.advisories.insufficientValue.notEnoughMarketValue);
            case 'BUY_LONG_EXCEEDS_SHORTS_HELD':
                return T((s) => s.tradeTicket.input.tradeNotPermitted);
            case 'CANNOT_AFFORD_SELL':
                return T((s) => s.tradeTicket.input.advisories.insufficientValue.notEnoughMarketValue);
            case 'INVALID_BUY_STOP_ORDER':
                return T((s) => s.tradeTicket.input.advisories.invalidStopOrder.buy);
            case 'INVALID_SELL_STOP_ORDER':
                return T((s) => s.tradeTicket.input.advisories.invalidStopOrder.sell);
            case 'FUND_MINIMUM_NOT_MET':
                return T((s) => s.tradeTicket.misc.minimumNotMetTitle);
            case 'INVALID_BRANCH':
                return 'Not permitted to trade';
            case 'MAX_NOTIONAL_VALUE_EXCEEDED':
                return T((s) => s.tradeTicket.input.advisories.notionalValueExceeded.mainText);
            case 'REST_RESTRICTED':
                return T((s) => s.errors.trade.restrictedError);
            case 'SHORT_NOTPERMITTED_ACCOUNTVALUE':
                return T((s) => s.errors.trade.thisIsAShortOrder);
            case 'ADJUSTED_CLOSE_ONLY':
                return T((s) => s.errors.trade.adjustedCloseOnly);
            case 'INSUFFICIENT_QUANTITY_TO_SHORT':
            case 'NO_STOCK_LOAN_RATE':
            case 'SHORTSELL_OUT_OF_SESSION':
            case 'SEC_NOT_FOUND': // TODO -- This doesn't seem right... if the security isn't found then show the "no short" message?
                return T((s) => s.tradeTicket.input.cannotShort);
            case 'ORD_MF_QRP_ONLY':
            case 'ORD_MF_NO_FOREIGN_ON_DOMESTIC':
            case 'ORD_MF_NO_DOMESTIC_ON_FOREIGN':
                return T((s) => s.tradeTicket.input.orderNotAllowed);
            default:
                return T((s) => s.errors.trade.generalTitle);
        }
    };

    const getErrorMessage = () => {
        // prettier-ignore
        switch (errorCode) {
            case 'INVALID_BRANCH': return T(s => s.errors.trade.sxDenied)
            case 'INSUFF_OPTION_LEVEL': return T(s => s.errors.trade.optionLevel)
            case 'ORD_OUT_OF_SESSION': return T(s => s.errors.trade.securityOutOfSession)
            case 'ORD_CANT_AFFORD': return T(s => s.warnings.trade.cannotAfford(trade, ac))
            case 'ORD_MINIMUM_NOT_MET': return T(s => s.warnings.trade.minimumNotMet)
            case 'ORD_DIME': return T(s => s.errors.trade.dimeProduct)
            case 'ORD_NICKEL': return T(s => s.errors.trade.nickelProduct)
            case 'ORD_PENNY': return T(s => s.errors.trade.pennyProduct)
            case 'ACCT_RESTRICTED': return T(s => s.errors.trade.accountRestricted)
            case 'NOT_FOUND_PRICE': return T(s => s.errors.trade.securityUnavailable)
            case 'CANNOT_AFFORD_BUY': return T(s => s.warnings.trade.cannotAfford(trade, ac))
            case 'SHORTSELL_OUT_OF_SESSION': return T(s => s.tradeTicket.misc.notInSessionShortSell);
            case "SHORTSELL_TIF_MUST_BE_DAY":
            case 'SHORT_NOTPERMITTED_LONG': return T(s => s.warnings.trade.shortsNotPermittedWhenLong(trade))
            case 'SHORT_NOTPERMITTED_ACCOUNTNOTMARGIN': return T(s => s.warnings.trade.shortsNotPermittedWhenAccountNotMargin(trade, ac))
            case 'BUY_LONG_EXCEEDS_SHORTS_HELD': return T(s => s.warnings.trade.buyExceedsShortsHeld(trade))
            case 'INSUFFICIENT_QUANTITY_TO_SHORT': return T(s => s.warnings.trade.insufficientQuantity)
            case 'SHORT_NOTPERMITTED_ACCOUNTVALUE': return T(s => s.warnings.trade.shortNotPermittedAccountValue(trade))
            case 'CANNOT_AFFORD_SELL': return T(s => s.warnings.trade.cannotAfford(trade, ac))
            case 'ACCT_FOREIGN': return T(s => s.warnings.trade.foreignAcctDomesticFunds)
            case 'FUND_FORBID': return T(s => s.errors.trade.fundTradingDenied)
            case 'TRD_FORBID': return T(s => s.errors.trade.basicTradingDenied)
            case 'ORD_NO_BUY': return T(s => s.errors.trade.noBuy)
            case 'ORD_OPT_AGREEMENT': return T(s => s.errors.trade.optionAgreement)
            case 'ORD_OPT_LEVEL': return T(s => s.errors.trade.optionLevel)
            case 'ORD_OPT_PERM': return T(s => s.errors.trade.optionPermission)
            case 'ORD_OPT_POSITION': return T(s => s.errors.trade.noOptionPosition)
            case 'ORD_OFF_HOURS': return T(s => s.errors.trade.offHours)
            case 'FUND_MINIMUM_NOT_MET': {
                const minInvestment = GetFromSnexStore(s => s.funds.byQsi[trade.securityId]?.profile?.data?.mutualFundProfile?.minimumInvestment)
                return T(s => s.tradeTicket.misc.minimumNotMetSubtext(minInvestment))
            }
            case 'ORD_CLOSED_TO_NEW': return T(s => s.tradeTicket.misc.closedToNewInvestors)
            case 'ORD_SELL_STOP_LESSTHAN_MARKET': return T(s => s.errors.trade.sellStopLessThanMarket)
            case 'MAX_NOTIONAL_VALUE_EXCEEDED': return T(s => s.tradeTicket.input.advisories.notionalValueExceeded.subText)
            case 'INVALID_QTY': {
                if (OptionSymbol.IsOptionSymbol(trade.securityId)) {
                    return T(s => s.warnings.trade.invalidQuantityOptions)
                }
                return T(s => s.warnings.trade.invalidQuantity)
            }
            case 'NO_STOCK_LOAN_RATE':
            case 'ADJUSTED_CLOSE_ONLY': return T((s) => s.errors.trade.adjustedCloseOnlyMessage);
            case 'SEC_NOT_FOUND': return T(s => s.errors.trade.shortNotAllowed)
            case 'ORD_MF_QRP_ONLY': return T(s => s.errors.trade.mutualFundQrpOnly);
            case 'ORD_MF_NO_FOREIGN_ON_DOMESTIC': return T(s => s.errors.trade.mutualFundNoForeignOnDomestic);
            case 'ORD_MF_NO_DOMESTIC_ON_FOREIGN': return T(s => s.errors.trade.mutualFundNoDomesticOnForeign);
            case 'ORD_GENERAL':
            case 'INVALID_BUY_STOP_ORDER':
            case 'INVALID_SELL_STOP_ORDER':
            case 'INTERNAL':
            case 'INTERNAL_FRONTEND':
            case 'UPSTREAM':
            default: return T(s => s.errors.trade.generalVerify)
        }
    };

    const result = { error: { errorCode, errorTitle: getErrorTitle(), errorMessage: getErrorMessage() }, success: false };
    _thDebug(`-- assembled error response for code ${errorCode}`);
    return result;
};

export const LocalizeOrderWarnings = (warnings: string[], trade: ApiTradeRequest): SnexWarning[] => {
    const isEdit = !!trade?.orderId;
    _thDebug(`-- localizing ${warnings.length} warnings...`);

    let code0000 = false;
    let code5301 = false;

    const localized = UniqueBy(
        (warnings || [])
            .map((w) => {
                if (/^PRE_TRADE_DISCLOSURE/.test(w)) {
                    const msg = w.replace('PRE_TRADE_DISCLOSURE|', '');
                    return { variant: 'asset-class' as warningVariant, warningMessage: msg };
                }
                if (/Allowed Short Sale/.test(w)) return { warningMessage: T((s) => s.warnings.trade.isShort) };
                if (/POSSIBLE DUPLICATE/.test(w) && !isEdit) return { warningMessage: T((s) => s.warnings.trade.possibleDuplicate) };
                if (/^8046/.test(w)) return { warningMessage: T((s) => s.warnings.trade.accountChangedToMargin) };
                if (/^8047/.test(w)) return { warningMessage: T((s) => s.warnings.trade.accountChangedToCash) };
                if (/^8070/.test(w)) return { warningMessage: T((s) => s.warnings.trade.optionLevel) };
                if (/0000/.test(w)) {
                    code0000 = true;
                    return {};
                }
                if (/5301/.test(w)) {
                    code5301 = true;
                    return {};
                } else return {};
            })
            .filter((w) => !!w.warningMessage),
        (i) => i.warningMessage
    );

    const mutualFundCutoffWarning = code0000 && code5301;

    if (mutualFundCutoffWarning) {
        localized.push({ warningMessage: T((s) => s.warnings.trade.cutoffTime) });
    }

    _thDebug(`-- created ${localized.length} localized warnings`);
    return localized;
};
