import { Flex } from "components/Flex";
import { ShadedTextDisplay } from "components/ShadedTextDisplay/ShadedTextDisplay";
import { ShadedTextList } from "phoenix/assets/lang/Snex1LanguagePack";
import { useText } from "phoenix/hooks/UseText"
import React from "react";


export const ShortAcceptanceComponent = ({text}: {text: ShadedTextList}) => {
    return(
        <Flex column style={{paddingTop: 20}}>
            <div style={{ marginBottom: 10 }}><ShadedTextDisplay segments={text} /></div>
        </Flex>
    )
}

export const ShortAcceptance = ({shares, rate} : 
    {shares: number, rate: number}) => {
    
    const text = useText(t => t.tradeTicket.shortSales)
    const segments = text.acceptance(shares, rate)
    return(
        rate ? <ShortAcceptanceComponent text={segments} /> : null
    )
}