import React, { useMemo } from "react";
import { IsFirefox } from "util/BrowserId";

interface WithScrollbarProps {
    style?: React.CSSProperties
    hideScrollbar?: boolean
    includeHorizontal?: boolean
    children: any
}

export const WithScrollbar = React.memo((props: WithScrollbarProps) => {

    const isFf = useMemo(IsFirefox, []);

    const className = useMemo(() => {
        if (props.hideScrollbar) return undefined;
        return isFf ? 'this-with-scrollbar' : 'with-scrollbar'
    }, [ props.hideScrollbar ])

    const oflowProp = useMemo(() => {
        return props.includeHorizontal ? 'overflow' : 'overflowY'
    }, [ props.includeHorizontal ])

    return (
        <div className={ className } style={{ maxHeight: '100%', [oflowProp]: 'scroll', ...props.style }}>
            { props.children }
        </div>
    )

})