import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import './Spinny.scss';

interface SpinnyProps {
    children?: any,
    onClick?: (e: any) => void,
    spun?: boolean
    targetDegrees?: number
    style?: React.CSSProperties
}

export const Spinny = (props: SpinnyProps) => {
    return (
        <div className='spinny-icon' style={{
            transform: props.spun ? `rotate(${ props.targetDegrees || 180 }deg)` : 'rotate(0deg)',
            ...props.style
        }} onClick={props.onClick}>
            { props.children || <ExpandMore /> }
        </div>
    );
};