// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { setSelectedScreenId, useFullyPaidLendingStore } from "../../Store"
import { FPL_FPLInfo_Form } from "./Form"
import React from "react"


export const fpl_fplinfo_step: OnboardingStep = {
    label: "FPL Info",
    isComplete: () => !!useFullyPaidLendingStore?.getState()?.data?.selectedAccount,
    isEnabled: () => true,
    isCurrent: () => ['fplInfo'].includes(useFullyPaidLendingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'fplInfo'
}

export const fpl_fplinfo_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => setSelectedScreenId('fplDisclosures'),
    isPrimary: true,
    isEnabled: () => !!useFullyPaidLendingStore?.getState()?.data?.selectedAccount
}

export const fpl_fplinfo_prevButton: OnboardingButton = {
    label: "Previous",
    onClick: () => setSelectedScreenId('welcome'),
    isPrimary: false,
    isEnabled: () => true
}

export const fpl_fplinfo_screen: OnboardingScreen = {
    id: "fplInfo",
    validate: NoValidation,
    buttons: [fpl_fplinfo_prevButton, fpl_fplinfo_nextButton],
    title: T(t => t.fplOnboarding.fplInfo.title),
    subtitles: [T(t => t.fplOnboarding.fplInfo.text)], 
    body: <FPL_FPLInfo_Form />
}