// @ts-strict-ignore
import { Environment } from '../constants'
import { useEffect } from 'react'
import { BrowserTracing } from '@sentry/react'
import { MyInfo } from 'phoenix/redux/models'
import { GetJwtClaim, Jwt } from './Jwt'

// Cross-platform typing does not appear to be availble for the Sentry client
// Instead, please refer to this documentation when using: https://docs.sentry.io/platforms/react-native/usage/

export type LogCategory = 'Trading' | 'Funding' | 'Onboarding' | 'Login' | 'Startup' | 'General' | 'Push Notifications' | 'Algolia'

export const ErrorCategories: { [key: string]: LogCategory } = {
    general: 'General',
    trading: 'Trading',
    funding: 'Funding',
    onboarding: 'Onboarding',
    login: 'Login',
    startup: 'Startup',
    pushNotifications: 'Push Notifications',
    algolia: 'Algolia'
}

let _sentry = null

type SentryLogLevel =
    | 'fatal'
    | 'critical'
    | 'error'
    | 'warning'
    | 'log'
    | 'info'
    | 'debug'
type SentryBreadcrumbType =
    | 'default'
    | 'debug'
    | 'error'
    | 'navigation'
    | 'http'
    | 'info'
    | 'query'
    | 'user'

interface SentryExtras {
    level?: SentryLogLevel;
    user?: {
        [key: string]: any;
        id?: string;
        ip_address?: string;
        email?: string;
        username?: string;
    }
    tags?: { [key: string]: string }
}

interface LoggingExtras extends SentryExtras {
    info?: { [key: string]: any }
}

interface ErrorLoggerOptions {
    debug?: boolean
    environment?: Environment,
    integrations?: object[]
}

interface MobileErrorLoggerOptions extends ErrorLoggerOptions {
    enableNative?: boolean
    onReady?: () => any
    integrations?: object[]
    release?: string
    dist?: string
    attachScreenshot?: boolean
}

const _initForWeb = (Sentry, options?: ErrorLoggerOptions) => {
        Sentry.init({
            dsn: 'https://6949bc7cadb040f9ba810f40f905ec75@o1289537.ingest.sentry.io/6509434',
            // We may want to adjust this in prod
            tracesSampleRate: 1.0,
            ...options
        })
        _sentry = Sentry
}

const _initForMobile = (Sentry, options?: MobileErrorLoggerOptions) => {
    Sentry.init({
        dsn: 'https://0e4f99df137041258189f57f461da244@o1289537.ingest.sentry.io/6508514',
        tracesSampleRate: 1.0,
        enableInExpoDevelopment: true,
        ...options
    })
    _sentry = Sentry
}

const Init = (
    Sentry,
    platform?: 'web' | 'mobile',
    options?: ErrorLoggerOptions | MobileErrorLoggerOptions
): void => {
    switch (platform) {
        case 'mobile':
            _initForMobile(Sentry, options)
            break
        case 'web':
            _initForWeb(Sentry, options)
            break
        default:
            console.warn(
                'Error Logger Init issue: \'platform\' argument not specified.'
            )
    }
}

/** @deprecated Please use RecordError, which requires a name for errors  */
const Record = (error: Error, extras?: { [key: string]: any }) => {
    if (_sentry) _sentry.captureException(error, { ...extras, __notice: 'Sent from legacy Record function, please update' })
    else console.warn('Sentry could not capture exception: SENTRY NOT FOUND')
}

const RecordError = (error: Error, name?: string, extras?: LoggingExtras) => {
    if (_sentry) _sentry.configureScope(function(scope) {
        if(name) scope.setTag('name', name)
        if(extras?.info) scope.setContext('Info', extras?.info)
        _sentry.captureException(error, extras)
      });
    else console.warn('Sentry could not capture exception: SENTRY NOT FOUND')
}

const RecordMessage = (message: string, name?: string, extras?: LoggingExtras) => {
    if (_sentry) _sentry.configureScope(function(scope) {
        if(name) scope.setTag('name', name)

        if(extras?.info) scope.setContext('Info', extras?.info)
        _sentry.captureMessage(message, extras)
    })
    else console.warn('Sentry could not capture exception: SENTRY NOT FOUND')
}

const SetContext = (key: string, value: object) => {
    if (_sentry) _sentry.setContext(key, value)
    else console.warn('Sentry could not set context: SENTRY NOT FOUND')
}

const SetUser = (jwt: string, userInfo?: MyInfo): void => {
    if(_sentry) _sentry.setUser({id: Jwt.TryGetClaim(jwt, 'cds_id'), email: userInfo?.email, name: userInfo?.name})
    else {
        _sentry.SetUserId({id: 'anonymous'})
        console.warn('Sentry could not set user: SENTRY NOT FOUND')
    }
}

const SetUserId = (id: string) => {
    if (_sentry) _sentry.setUser({ id })
    else console.warn('Sentry could not set user id: SENTRY NOT FOUND')
}

const SetTag = (key: string, value: string) => {
    if (_sentry) _sentry.setTag(key, value)
    else console.warn('Sentry could not set tag: SENTRY NOT FOUND')
}

const SetCategory = (category: LogCategory) =>  SetTag('category', category)

const ClearScope = () => {
    if (_sentry) _sentry.configureScope((scope) => scope.clear())
    else console.warn('Sentry could not clear scope: SENTRY NOT FOUND')
}

const AddBreadcrumb = (params: {
    type?: SentryBreadcrumbType
    category?: string
    message?: string
    data?: any
    level?: SentryLogLevel
    timestamp?: Date | number
}) => {
    const {
        type,
        category,
        message,
        data,
        level,
        timestamp = new Date()
    } = params

    if (_sentry) {
 _sentry.addBreadcrumb({
            type,
            category,
            message,
            data,
            level,
            timestamp
        })
} else console.warn('Sentry could not add breadcrumb: SENTRY NOT FOUND')
}

// If we want to set a category in a component as a hook
export const useLoggingCategory = (category: LogCategory) => {
    useEffect(() => {
        SetTag('category', category)
        return () => {
            ClearScope()
        }
    }, [category])
}

export const ErrorLogger = {
    Init,
    /** @deprecated Please use ErrorLogger.RecordError, which requires errors to be named. */ Record,
    RecordError,
    RecordMessage,
    SetContext,
    SetTag,
    SetCategory,
    ClearScope,
    SetUserId,
    SetUser,
    AddBreadcrumb
}
