// @ts-strict-ignore
import { SecurityChartData } from '..'
import { ApiData } from '../../../models'
import { SecurityQuoteLiveUpdate } from './SecurityQuoteLiveUpdate'

export class SecurityChartState {
    intervalInMinutes: number;
    bySymbol: {
        [qsi: string]: { [range: string]: ApiData<SecurityChartData[]> }
    };

    streamBySymbol: {
        [qsi: string]: SecurityChartData[]
    };

    constructor () {
        this.bySymbol = {}
        this.streamBySymbol = {}
    }
}
