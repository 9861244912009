
export const LoadImagesAsync = async (imageUrls: string[]) => {
    const asyncImageLoaders = imageUrls
        .map(async (src) => {
            let img
            return new Promise((resolve, reject) => {
                img = new Image()
                img.onload = resolve
                img.onerror = reject
                img.src = src
            })
        })

    return await Promise.all(asyncImageLoaders)
}
