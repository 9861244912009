import { AccountCardList, Flex, LoadingSpinner } from 'components';
import { BlankView } from 'components/BlankView';
import { SnexButton } from 'components/SnexButton/SnexButton';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import animationData from 'phoenix/assets/animations/BankingCalendar.json';
import { Urls } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { GetClientAccountsAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Account } from 'phoenix/redux/models';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';

export const EnableOptionsScreen = TelemetryProvider(() => {
    const dispatch = useDispatch();
    const text = useText((s) => s.enableOptionsScreen);
    const accounts = useSelector((s: GlobalState) => s.accounts.all);
    const data = accounts?.data || [];
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Set Base Layout Props
    useEffect(() => {
        setBaseLayoutProps({ hideGlance: true });
    }, [setBaseLayoutProps]);

    useEffect(() => {
        dispatch(GetClientAccountsAction());
    }, []);

    const openInNewTab = (a: Account) => {
        const newWindow = window.open(Urls.registration.enableOptions(a.accountNumber), '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <>
            {data?.length ? (
                <Flex column align='center' style={{ height: '100%', overflow: 'scroll' }}>
                    <BlankView animationData={animationData} secondaryText={data?.length > 1 ? text.getStartedWithOptions : undefined} text={text.title} />
                    {data?.length > 1 ? (
                        <div style={{ marginBottom: 10, width: '70%', marginTop: 30 }}>
                            <AccountCardList accounts={data} onAccountSelect={openInNewTab} />
                        </div>
                    ) : (
                        <div style={{ marginBottom: 10, width: '30%', marginTop: 30, minWidth: 370 }}>
                            <SnexButton eventTag='Enable Options' flavor='submit' onClick={() => openInNewTab(data[0])}>
                                Enable Options
                            </SnexButton>
                        </div>
                    )}
                </Flex>
            ) : (
                <Flex center style={{ width: '100%', flex: 1, height: '100%' }}>
                    <LoadingSpinner />
                </Flex>
            )}
        </>
    );
}, TelemetryCategories.trade);
