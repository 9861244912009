import DarkIcon from '../../phoenix/assets/animations/FPLIconDark.json';
import LightIcon from '../../phoenix/assets/animations/FPLIconLight.json';
import { GetAppTheme } from "theming";
import React from "react";
import Lottie from "lottie-react";

export interface FPLIconProps
{
    height?: number,
    width?: number,
    loop?: boolean,
    style?: React.CSSProperties
}

export const FullyPaidLendingIcon = (props: FPLIconProps) : JSX.Element => {
    const theme = GetAppTheme()
    const { height = 50, width = 50, loop = true, style } = props;

    const defaultOptions = {
        loop,
        autoplay: true,
        animationData: theme === 'dark' ? DarkIcon : LightIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div style={{ height, width, ...style }}>
            <Lottie height={height} {...defaultOptions} style={{ height, width }} width={width} />
        </div>
    )
}