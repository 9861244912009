import { Urls } from '../../constants'
import { Actions } from './Constants'
import { ReduxApiGet } from './Helpers'

export const GetAccountOpeningsAction = () => {
    return ReduxApiGet(
        Urls.accountOpenings.getAll(),
        Actions.AccountOpenings.GetAll)
        .withMutex()
        .withLoading()
        .run()
}
