import { Phone } from '@mui/icons-material';
import React from 'react';
import { useColors } from 'hooks/UseColors';
import { GetSupportInfoByEnv } from 'phoenix/constants/Support';
import { Flex } from '../Flex';
import { SnexButton } from '../SnexButton/SnexButton';

export function CallSupportButton(): JSX.Element | null {
    const colors = useColors();
    const supportContactInfo = GetSupportInfoByEnv();
    const phone = supportContactInfo.phone;
    if (!phone)
        return null;

    return (
        <SnexButton href={`tel:${phone.number}`} flavor='secondary-transparent' style={{ color: colors.primaryItemColor }}>
            <Flex row align='center' justify='center'>
                <Phone />
                <span style={{ minWidth: 250, textAlign: 'center' }}>{phone.label}</span>
            </Flex>
        </SnexButton>
    );
};
