// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7s-EuYHnIWiQlfZkDPnx{margin:15px 10px;padding:15px;transition:200ms}._1iYhPkGRmM3gElx6qYKX4I{cursor:pointer}._1iYhPkGRmM3gElx6qYKX4I ._1GRSv9FGlUhLdPXl2BTyFU{opacity:0}._1iYhPkGRmM3gElx6qYKX4I:hover{overflow:hidden;border-radius:10.5px;background-color:var(--selectListItemColor)}._1iYhPkGRmM3gElx6qYKX4I:hover ._1GRSv9FGlUhLdPXl2BTyFU{opacity:.5}._1pl5w_5k3gWOMM19vXnVMw{color:var(--primaryItemColor);width:15px;height:15px;margin-top:2px}._1H352DX2YLIKUhhLjyq_HI{margin-right:0}._3hA29vIdxpgE_cCT62ws9d ._2yq9DP_kx5taOOE-E2XRh8{display:none;width:0 !important;margin-right:0 !important}._3hA29vIdxpgE_cCT62ws9d:hover ._2yq9DP_kx5taOOE-E2XRh8{display:block;margin-right:4px !important;width:18px !important}.H4721mj7qZxi-c2Fr6Qfq,._3mj5vPXAdmVPgeNl0aGS3t{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"card": "_7s-EuYHnIWiQlfZkDPnx",
	"listRow": "_1iYhPkGRmM3gElx6qYKX4I",
	"addToListRow": "_1GRSv9FGlUhLdPXl2BTyFU",
	"infoButton": "_1pl5w_5k3gWOMM19vXnVMw",
	"label": "_1H352DX2YLIKUhhLjyq_HI",
	"descriptionContainer": "_3hA29vIdxpgE_cCT62ws9d",
	"editIcon": "_2yq9DP_kx5taOOE-E2XRh8",
	"defaultWatchlistDescription": "H4721mj7qZxi-c2Fr6Qfq",
	"watchlistDescription": "_3mj5vPXAdmVPgeNl0aGS3t"
};
module.exports = exports;
