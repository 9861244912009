// @ts-strict-ignore
import { CallMade } from '@mui/icons-material';
import React from 'react';
import { Spinny } from '..';
import { ChangeColor } from 'phoenix/util';

export const DirectionalArrow = React.memo(({ valueChange, height }: {valueChange?: number, height?: number}) => {
    if (valueChange === 0) return null;
    return (
        <Spinny spun={ valueChange < 0 } targetDegrees={ 90 }>
            <CallMade style={{ fill: ChangeColor(valueChange), fontSize: height || 0 }} />
        </Spinny>
    )
});
