import { ApiData } from '../../../models'
import { AccountChartPoint } from './AccountChartPoint'
import { AccountChartResponse } from './AccountChartResponse'

export class AccountChartState {
    intervalInMinutes: number;
    chartIsDeficient?: boolean;
    deficientAccounts?: string[];
    aggregate: { [key: string]: ApiData<AccountChartPoint[]>};
    byAccount: { [accountNumber: string]: { [range: string]: ApiData<AccountChartResponse> } }; // Maps account IDs to chart data

    constructor () {
        this.intervalInMinutes = 5
        this.aggregate = {}
        this.byAccount = {}
    }
}
