// @ts-strict-ignore

import _ from 'lodash';
import { MarketTimeSegments } from 'phoenix/constants/MarketTimeSegments';
import { MarketHoliday } from 'phoenix/redux/models/Market/MarketHoliday';
import { useMemo } from 'react';
import { GetConfig } from '../constants/Config';
import { SegmentType } from '../redux/models/Market/MarketStatus';
import {
    GetClosedSimulatedHours,
    GetHolidaySimulatedHours,
    GetMarketSimulatedHours,
    GetPostmarketSimulatedHours,
    GetPremarketSimulatedHours,
    MarketTimeSegment
} from '../util';
import { useSnexStore } from './UseSnexStore';


export const ClosedMarketSegments = new Set<MarketTimeSegment>(['holiday', 'closed', 'premarket', 'postmarket'])


// const TEST_SEGMENT: MarketTimeSegment = 'open'
const TEST_SEGMENT: MarketTimeSegment = undefined;

const getTestSegment = () => {
    if (GetPremarketSimulatedHours()) return 'premarket';
    if (GetMarketSimulatedHours()) return 'open';
    if (GetPostmarketSimulatedHours()) return 'postmarket';
    if (GetClosedSimulatedHours()) return 'closed';
    if (GetHolidaySimulatedHours()) return 'holiday';
    return TEST_SEGMENT;
};

export const useMarketTimeSegmentV2: () => [MarketTimeSegment, () => any] = () => {
    const segment = useSnexStore(s => s?.market?.status?.data?.segment);
    const currentHoliday = useSnexStore(s => s?.market?.status?.data?.currentHoliday);
    return useMemo(() => [getTestSegment() || GetSegmentFromStatus(segment, currentHoliday), _.noop], [segment, currentHoliday]);
};

export function GetMarketTimeSegmentV2(): MarketTimeSegment {
    const  { segment, currentHoliday } = GetConfig()?.Store.getState().market.status?.data ?? {};
    return getTestSegment() || GetSegmentFromStatus(segment, currentHoliday);
}

function GetSegmentFromStatus(segment: SegmentType | undefined, currentHoliday: MarketHoliday | undefined): MarketTimeSegment {
    if (!currentHoliday && !segment) return 'loading';

    // TODO -- account for half days
    if (currentHoliday) return MarketTimeSegments.holiday;
    else {
        switch (segment) {
            case 'PRE_MARKET':
                return MarketTimeSegments.premarket;
            case 'POST_MARKET':
                return MarketTimeSegments.postmarket;
            case 'MAIN_MARKET':
                return MarketTimeSegments.open;
            case 'CLOSED':
                return MarketTimeSegments.closed;
            default:
                console.warn('WARNING: Market status is unknown: ', segment);
                return 'loading';
        }
    }
}
