import { MyProgress } from 'phoenix/redux/models/Users/MyProgress';

export const ProgressNames = {
    hasBeenWelcomed: 'misc.has-been-welcomed',
    hasTraded: 'misc.has-traded',
    tours: {
        portfolioScreen: 'tour.portfolio',
        stockScreen: 'tour.stock-screen',
        premiumStockScreen: 'tour.premium-stock-screen',
        tickerBarTour: 'tour.ticker-bar',
        cryptoTour: 'tour.crypto'
    }
};

export const ProgressNameKey = (progressName: string): keyof MyProgress => {
    // prettier-ignore
    switch (progressName) {
        case ProgressNames.hasBeenWelcomed: return 'hasBeenWelcomed'
        case ProgressNames.hasTraded: return 'hasTraded'
        case ProgressNames.tours.portfolioScreen: return 'portfolioTour'
        case ProgressNames.tours.premiumStockScreen: return 'premiumStockTour'
        case ProgressNames.tours.stockScreen: return 'stockScreenTour'
        case ProgressNames.tours.tickerBarTour: return 'tickerBarTour'
        case ProgressNames.tours.cryptoTour: return 'cryptoTour'
        default: throw new Error(`Invalid progressName ( ${progressName} ) provided.`)
    }
};
