import { GetMySubscriptionInfoAction } from 'phoenix/redux/actions/SubscriptionActions';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import style from './style.module.scss';

export type ShiningWrapperVariant = 'gold' | 'green';
export const ShiningWrapperVariants = {
    Gold: 'gold' as ShiningWrapperVariant,
    Green: 'green' as ShiningWrapperVariant
};

const GetClassNameFromVariant = (variant?: ShiningWrapperVariant): string => {
    switch (variant) {
        case 'green':
            return style.textAnimationGreen;
        case 'gold':
        default:
            return style.textAnimation;
    }
};

export const ShiningWrapper = React.memo(
    ({ children, withLock, variant }: PropsWithChildren<{ withLock?: boolean; permittedTiers?: string[]; variant?: ShiningWrapperVariant }>) => {
        const dispatch = useDispatch();

        useEffect(() => {
            if (withLock) dispatch(GetMySubscriptionInfoAction());
        }, [dispatch, withLock]);

        const _className = GetClassNameFromVariant(variant);

        return (
            <Flex center className={_className}>
                {children}
            </Flex>
        );
    }
);
