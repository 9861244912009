import { ItemUpdate } from 'lightstreamer-client-web'
import { LiveDataGroups } from '../../constants'
import { AccountChartPoint, PortfolioChartPoint } from '../../redux/models'
import { Lightstreamer } from '../../util'
import { XstreamReduxSubscribe } from './XstreamActionShared'

export const XstreamAccountChartUpdateActionName = 'XSTREAM_ACCOUNT_UPDATE'
export const XstreamPortfolioChartUpdateActionName = 'XSTREAM_PORTFOLIO_UPDATE'
export const XstreamResetAccountValuationActionName = 'XSTREAM_ACCOUNT_VALUATION_RESET'

const AccountValuationFields = ['id', 'value', 'timestamp']
const PortfolioValuationFields = ['timestamp', 'totalValue']

export const XstreamSubscribeToLiveAccountChartAction = (accountNumber: string, namespace: string) => XstreamReduxSubscribe(
    LiveDataGroups.AccountValuations, 
    [`acct:${accountNumber}`], 
    XstreamAccountChartUpdateActionName, 
    AccountValuationFields, 
    AccountChartPoint.FromLiveData, 
    { includeSnapshot: true, mode: 'MERGE', maxFrequencySeconds: 0.5, subject: { accountNumber, range: '1d' }, namespace }
)

export const XstreamSubscribeToLivePortfolioChartAction = (namespace: string) => XstreamReduxSubscribe(
    LiveDataGroups.PortfolioValuations,
    [`portfolio:${Lightstreamer.GetCurrentUserId()}`],
    XstreamPortfolioChartUpdateActionName,
    PortfolioValuationFields,
    (d: ItemUpdate) => {
        const { totalValue, timestamp } = PortfolioChartPoint.FromLiveData(d)
        return { value: totalValue, timestamp }
    },
    {
        includeSnapshot: true,
        mode: 'MERGE',
        maxFrequencySeconds: 0.5,
        subject: '1d',
        namespace
    })

export const XstreamResetAccountValuationAction = (accountNumber?: string) => ({ type: XstreamResetAccountValuationActionName, subject: accountNumber })
