import { Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import React from 'react'
import { Flex } from '../../Flex'
import { TradeTicketSection } from './TradeTicketSection'

export const TradeTicketError = React.memo((props: { title: string, message?: string, children?: any, noPadding?: boolean }) => {
    return (
        <TradeTicketSection noBorder style={{ padding: props.noPadding ? 0 : '0 20px' }}>
            <Flex column style={{ padding: '20px 0' }}>
                <Flex row align='center' style={{ marginBottom: 10 }}>
                    <InfoOutlined />
                    <Typography id='trade-ticket-error-title' style={{ marginLeft: 10 }} variant='h6'>{ props.title }</Typography>
                </Flex>
                { props.message ? (
                    <Typography id='trade-ticket-error-message' variant='body1' style={{ fontSize: 15 }}>{ props.message }</Typography>
                ) : null }
                { props.children || null }
            </Flex>
        </TradeTicketSection>
    )
})