// @ts-strict-ignore
import React, { useEffect, useMemo, useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { IsMutualFund } from 'phoenix/util';
import { SecurityCell, SecurityCellProps } from './SecurityCell';
import { SecuritySkeletonCell } from './SecuritySkeletonCell';

const LazyLoadedSecurityCellFunction = (props: SecurityCellProps) => {
    const [initialized, setInitialized] = useState(false);
    const [visible, setVisible] = useState(false);

    const hideLogo = useMemo(() => {
        return false;
    }, []);

    const skeletonCellTitle = useMemo(() => {
        if (!props.position) return props.symbol;
        if (IsMutualFund(props.position.productType)) return props.position.description;
        return props.symbol;
    }, [props.position, props.symbol]);

    useEffect(() => {
        if (visible) setInitialized(true);
    }, [visible]);

    return (
        <ReactVisibilitySensor onChange={(value: boolean) => setVisible(value)}>
            {initialized ? (
                <SecurityCell {...props} visible={visible} />
            ) : (
                <SecuritySkeletonCell hideLogo={hideLogo} hideSecondaryRow={props.hideAccountName} title={skeletonCellTitle} />
            )}
        </ReactVisibilitySensor>
    );
};

export const LazyLoadedSecurityCell = React.memo(LazyLoadedSecurityCellFunction);
