
export const ChartFrequencies = {
    loRes: 5,
    hiRes: 1
}

export const XstreamChartResetActionName = 'XSTREAM_CHART_RESET'
export const XstreamAccountChartResetActionName = 'XSTREAM_ACCOUNT_CHART_RESET'
export const XstreamUpdateChartFrequencyInMinutes = 'XSTREAM_UPDATE_CHART_FREQUENCY_IN_MINUTES'

export const XstreamResetAccountChartAction = (subject?: string) => ({ type: XstreamAccountChartResetActionName, subject })

export const XstreamChartResetAction = (qsi: string) => ({ type: XstreamChartResetActionName, subject: qsi })

export const XstreamUpdateChartFrequencyInMinutesAction = (frequency: number) => ({type: XstreamUpdateChartFrequencyInMinutes, data: frequency })
