import { Urls } from '../../constants';
import { Watchlist, WatchlistSecurity } from '../models';
import { Actions } from './Constants';
import { ReduxApiDelete, ReduxApiGet, ReduxApiPost, ReduxApiPut, ReduxApiResponse } from './Helpers';

export const GetWatchlistsAction = (forceReload?: boolean) => {
    const q = ReduxApiGet(Urls.watchlists.getAll(), Actions.Watchlists.GetAll);
    if (!forceReload) q.useStored((s) => (s.watchlists.all?.data?.length ? s.watchlists.all.data : null));
    return q.run();
};

export const CreateWatchlistAction = (name: string) =>
    ReduxApiPost<Watchlist>(Urls.watchlists.create(), Actions.Watchlists.Create, { name }).showToasts().withLoading().withAsyncOperationIndicator().run();

export const EditWatchlistAction = (listId: string, name: string, description: string): ReduxApiResponse<unknown> =>
    ReduxApiPut<unknown, unknown, { listId: string }>(Urls.watchlists.edit(listId), Actions.Watchlists.Edit, { name, description })
        .withSubject({ listId })
        .withLoading()
        .withAsyncOperationIndicator()
        .showToasts()
        .run();

export const DeleteWatchlistAction = (listId: string): ReduxApiResponse<unknown> =>
    ReduxApiDelete<unknown, unknown, { listId: string }>(Urls.watchlists.delete(listId), Actions.Watchlists.Delete)
        .withSubject({ listId })
        .withLoading()
        .withAsyncOperationIndicator()
        .showToasts()
        .run();

export const AddToWatchlistAction = (listId: string, symbol: string | string[], index?: number): ReduxApiResponse<unknown & { failed?: boolean }> | undefined => {
    // TODO: Refactor for validation on API rather than mobile
    if (listId && symbol)
        return ReduxApiPut<unknown & { failed?: boolean }, unknown, { listId: string; symbol: string | string[] }>(
            Urls.watchlists.addSymbols(listId, Array.isArray(symbol) ? symbol : [symbol], index),
            Actions.Watchlists.AddSymbols
        )
            .showToasts()
            .withSubject({ listId, symbol })
            .withAsyncOperationIndicator()
            .onError((res) => ({ ...(res as Record<string, unknown>), failed: true }))
            .run();
};

export const RemoveFromWatchlistAction = (listId: string, symbol: string): ReduxApiResponse<unknown & { failed?: boolean }> =>
    ReduxApiDelete<unknown & { failed?: boolean }, unknown, { listId: string; symbol: string }>(
        Urls.watchlists.deleteSymbols(listId, [symbol]),
        Actions.Watchlists.DeleteSymbols
    )
        .showToasts()
        .withSubject({ listId, symbol })
        .withAsyncOperationIndicator()
        .onError((res) => ({ ...(res as Record<string, unknown>), failed: true }))
        .run();

export const ReorderWatchlistItemAction = (listId: string, securities: WatchlistSecurity[]): ReduxApiResponse<unknown> => {
    const symbols = securities.map((s) => s.symbol);
    return ReduxApiPut<unknown, unknown, { listId: string; securities: WatchlistSecurity[] }>(symbols, Actions.Watchlists.ReorderSymbols, {
        symbols
    })
        .showToasts()
        .withSubject({ listId, securities })
        .withLoading()
        .withBatching(`wlist-item-reorder:${listId}`, (_) => Urls.watchlists.reorderSymbols(listId), 100, 'WLIST_ITEM_REORDER_BATCH')
        .withAsyncOperationIndicator()
        .onError((res: any) => ({ ...res, failed: true }))
        .run();
};

export const ReorderWatchlistAction = (watchlists: Watchlist[]): ReduxApiResponse<unknown> => {
    const reorderItems = watchlists.map((w, wIndex) => {
        return { name: w.name, id: w.id, sequence: wIndex };
    });
    const reorderRequest = { reorderItems };
    return ReduxApiPut<unknown, unknown, { watchlists: Watchlist[] }>(null, Actions.Watchlists.ReorderWatchlists, reorderRequest)
        .showToasts()
        .withSubject({ watchlists })
        .withLoading()
        .withBatching('wlist-reorder', Urls.watchlists.reorderWatchlists, 100, 'WLIST_REORDER_BATCH')
        .withAsyncOperationIndicator()
        .onError((res: any) => ({ ...res, failed: true }))
        .run();
};

export const ResetWatchlistAction = () => ({ type: Actions.Watchlists.Reset });
