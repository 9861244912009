// @ts-strict-ignore
import { formatDistanceStrict } from 'date-fns';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { QuoteAttribute, QuoteParam } from 'phoenix/util';

export const CapitalizeFirstOnly = (src: string): string => src[0].toUpperCase() + src.slice(1).toLowerCase();
export const LowercaseFirstOnly = (src: string): string => src[0].toLowerCase() + src.slice(1);

export const ChangeCamelCaseToSentenceCase = (src: string): string => src.charAt(0).toUpperCase() + src.slice(1).replace(/([A-Z])/g, ' $1');

export const ChangeCaseToTitleCase = (src: string): string =>
    src
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((word) => word.replace(word[0], word[0].toUpperCase()))
        .join(' ');

export const RemoveExtraSpace = (string: string): string => string.replace(/\s+/g, ' ');

export const StringsAreEqualIgnoreCase = (a: string, b: string): boolean => a.toLowerCase() === b.toLowerCase();

// Might need to use encodeUriComponent() if the object you're passing in is more complex than just strings with no spaces, numbers and bools.
export const CreateQueryStringFromObject = (obj: { [key: string]: string | number } = {}, leadingCharacter?: string): string => {
    return `${leadingCharacter || ''}${Object.entries(obj)
        .filter((t) => !!t[1])
        .map((i) => i.join('='))
        .join('&')}`;
};

export const SetMemo = (key: string, value: string): void => {
    window[key] = value;
};

export const GetMemo = (key: string): string => {
    return window[key];
};

export const GetUniqueTicketNumber = (ticketName: string): number => {
    const globalKey = `__k8xqqrt3_intl_sequence_dictionary_${ticketName}`;
    window[globalKey] = window[globalKey] ? window[globalKey] + 1 : 1;
    const val = window[globalKey];
    return val;
};

export const CheckUniqueTicketNumber = (ticketName: string, compareValue: number): boolean => {
    const globalKey = `__k8xqqrt3_intl_sequence_dictionary_${ticketName}`;
    const val = window[globalKey] || NaN;
    return val === compareValue;
};

export const TimeSinceInWordsAbbreviated = (date: Date | string): string => {
    const replacements = {
        second: 'sec',
        minute: 'min',
        hour: 'h',
        day: 'd',
        month: 'm',
        year: 'y'
    };
    if (typeof date === 'string') date = new Date(date);
    const now = new Date();
    const dist = formatDistanceStrict(date, now);
    const out = Object.entries(replacements).reduce((out, next) => out.replace(next[0], next[1]), dist);
    return out.replace(/\s+/, '').replace(/s$/, '');
};

export interface ValueChange {
    valueChange: number;
    percentChange: number;
}

export const DetermineChange = (newValue?: number, firstValue?: number, withoutMultiplier = false): ValueChange => {
    if (isNaN(newValue) || isNaN(firstValue)) return { valueChange: 0, percentChange: 0 };
    const startingValue = firstValue || 0;
    const valueChange = newValue - startingValue;
    const percentChange = startingValue ? (valueChange / Math.abs(startingValue)) * (withoutMultiplier ? 1 : 100) : 0;
    return { valueChange, percentChange };
};

export const GetChartChange = (quote: QuoteParam, startingValue: number, currentValue: number): { percent: number; value: number } => {
    let percent: number = null;
    let value: number = null;
    if (quote && !quote?.loading && !isNaN(QuoteAttribute.getChange(quote, true))) {
        percent = QuoteAttribute.getChangePercent(quote) || DetermineChange(currentValue, startingValue, true).percentChange;
        value = QuoteAttribute.getChange(quote, true);
    } else {
        const changeObj = DetermineChange(currentValue, startingValue, true);
        percent = changeObj.percentChange;
        value = changeObj.percentChange ? changeObj.valueChange : 0;
    }

    return { percent, value };
};

export const HasNumericValueGreaterThanZero = (val?: string | number): boolean => {
    const convertedVal = +val?.toString().replace('$', '').replace(',', ''); // + converts strings to numeric or NaN
    return !isNaN(convertedVal) && convertedVal > 0;
};

export const DISPLAY_POV_10 = 'POV 10%';
export const DISPLAY_POV_2 = 'POV 2%';
export const DISPLAY_POV_5 = 'POV 5%';
export const DISPLAY_POV_15 = 'POV 15%';
export const DISPLAY_POV_20 = 'POV 20%';
export const DISPLAY_POV_25 = 'POV 25%';

export const FLOORNOTE_POV_10 = 'CUST1';
export const FLOORNOTE_POV_2 = 'CUST2';
export const FLOORNOTE_POV_5 = 'CUST3';
export const FLOORNOTE_POV_15 = 'CUST4';
export const FLOORNOTE_POV_20 = 'CUST5';
export const FLOORNOTE_POV_25 = 'CUST6';

export const POV_DISPLAYS = [DISPLAY_POV_10, DISPLAY_POV_2, DISPLAY_POV_5, DISPLAY_POV_15, DISPLAY_POV_20, DISPLAY_POV_25];
export const POV_FLOORNOTES = [FLOORNOTE_POV_10, FLOORNOTE_POV_2, FLOORNOTE_POV_5, FLOORNOTE_POV_15, FLOORNOTE_POV_20, FLOORNOTE_POV_25];

export const convertFloorNoteToPovDisplay = (note: string): string => {
    switch (note) {
        case FLOORNOTE_POV_10:
            return DISPLAY_POV_10;
        case FLOORNOTE_POV_2:
            return DISPLAY_POV_2;
        case FLOORNOTE_POV_5:
            return DISPLAY_POV_5;
        case FLOORNOTE_POV_15:
            return DISPLAY_POV_15;
        case FLOORNOTE_POV_20:
            return DISPLAY_POV_20;
        case FLOORNOTE_POV_25:
            return DISPLAY_POV_25;
    }
};

export const convertPovDisplayToFloorNote = (povDisplay: string): string => {
    switch (povDisplay) {
        case DISPLAY_POV_10:
            return FLOORNOTE_POV_10;
        case DISPLAY_POV_2:
            return FLOORNOTE_POV_2;
        case DISPLAY_POV_5:
            return FLOORNOTE_POV_5;
        case DISPLAY_POV_15:
            return FLOORNOTE_POV_15;
        case DISPLAY_POV_20:
            return FLOORNOTE_POV_20;
        case DISPLAY_POV_25:
            return FLOORNOTE_POV_25;
    }
};

export function noteAndNotHeldToAlgoStrategy(lang: Snex1LanguagePack, floorNote?: string, notHeld?: boolean): string {
    const text = lang.tradeTicket.input;

    const knownValues = [text.vwap, text.twap, 'GTX PRE-POST', 'GTX POST-MARKET'];

    if (notHeld && POV_FLOORNOTES.includes(floorNote)) return convertFloorNoteToPovDisplay(floorNote);
    if (notHeld && (!floorNote || !knownValues.includes(floorNote))) return text.care;
    if (!notHeld && (!floorNote || !knownValues.includes(floorNote))) return text.sor;
    if (notHeld && floorNote === text.vwap) return text.vwap;
    if (notHeld && floorNote === text.twap) return text.twap;

    return '';
}
