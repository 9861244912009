// @ts-strict-ignore
import { FuturesTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { getDefaultOrderProps, useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useText } from 'phoenix/hooks/UseText';
import FuturesTimeSpreadsAssetClass from 'phoenix/models/AssetClasses/FuturesTimeSpreadsAssetClass';
import { GetV2SecurityQuoteAction } from 'phoenix/redux/actions';
import { TradeTicketState } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { SecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { SafeFormat, Sum, toCommas } from 'phoenix/util';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { MouseEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ContractListRow, { ContractRowTemplate } from 'screens/OptionsScreen/OptionContractList/ContractListRow';
import { Routes } from 'util/Routes';
import { getTimeSpreadLabel } from '.';

type TimeSpreadContractListRowProps = {
    expanded: boolean;
    meta: SecurityMetadata;
    setExpanded: (x: boolean) => void;
};

export default function ({ expanded, meta, setExpanded }: TimeSpreadContractListRowProps): JSX.Element {
    const { symbol } = meta;
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const quote = XS.Quotes.use(symbol);
    const text = useText((t) => t.optionListScreen);
    const subscriptionNamespace = LiveDataNamespaces.FuturesTimeSpreadScreen;

    const { formatPrice } = FuturesTimeSpreadsAssetClass;
    const priceMovement = quote?.change >= 0 ? 'positive' : 'negative';
    const viewModel = useTradeTicketViewModel<FuturesTradeTicketViewModel>();
    const { tradeAction, setViewModel, symbol: tradeTicketSymbol } = viewModel;
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const positions = usePositionsStore((s) => s.positions)?.filter((p) => p.symbol === symbol);
    const ownedShares = Sum(positions?.map((p) => p.quantity));
    const expirationDate = SafeFormat(new Date(meta?.expirationDate), 'dd MMM yyyy');
    const label = getTimeSpreadLabel(meta.symbol);

    const handleSelect = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const alreadySelected = tradeTicketSymbol === symbol;

        const newTrade = {
            ...getDefaultOrderProps({ assetClass: FuturesTimeSpreadsAssetClass, marketTimeSegment, quote, tradeAction }),
            state: 'input' as TradeTicketState,
            symbol,
            // If the user has selected a spread previously, retain whatever tradeAction was previously selected
            ...(new FuturesSymbol(viewModel?.symbol).isTimeSpread ? { tradeAction: viewModel?.tradeAction } : {})
        };

        setViewModel(alreadySelected ? { symbol: null } : newTrade);
    };

    const template: ContractRowTemplate = {
        link: Routes.futuresTimeSpreadDeeperView(symbol),
        collapsed: [expirationDate, toCommas(quote?.volume), formatPrice(quote?.bid, meta), formatPrice(quote?.ask, meta)],
        expanded: [
            { label: text.bid, value: formatPrice(quote?.bid, meta) },
            { label: text.ask, value: formatPrice(quote?.ask, meta) },
            { label: text.open, value: formatPrice(quote?.open, meta) },
            { label: text.high, value: formatPrice(quote?.high, meta) },
            { label: text.low, value: formatPrice(quote?.low, meta) }
        ]
    };

    useEffect(() => {
        let sub: string;
        (async () => {
            dispatch(GetV2SecurityQuoteAction(symbol));
            sub = await xdispatch(XS.Quotes.start(symbol, subscriptionNamespace));
        })();

        return () => {
            XS.stop(sub);
        };
    }, [dispatch, subscriptionNamespace, symbol, xdispatch]);

    return (
        <ContractListRow
            expanded={expanded}
            onSelect={handleSelect}
            lastPrice={formatPrice(quote?.price, meta)}
            onExpand={setExpanded}
            ownedShares={ownedShares}
            priceMovement={priceMovement}
            rowHeader={label}
            selected={tradeTicketSymbol === symbol}
            template={template}
            viewMoreText={text.viewMore}
        />
    );
}
