import { ApiData } from '../../../models'
import { OptionMonth } from './OptionMonth'
import { OptionQuote } from './OptionQuote'

export class OptionsState {
    quotesByOsi: { [osi: string]: ApiData<OptionQuote> }
    sizesByOsi: { [osi: string]: ApiData<number> }
    monthsByUnderlying: { [underlyingSymbol: string]: ApiData<OptionMonth[]> }

    constructor () {
        this.quotesByOsi = {}
        this.sizesByOsi = {}
        this.monthsByUnderlying = {}
    }
}
