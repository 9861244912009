import { ApiData } from '../../../models/ApiData'
import { News } from './News'

export class NewsState {
    marketNews: ApiData<News[]>;
    portfolioNews: ApiData<News[]>;
    bySymbol: { [ key: string ]: ApiData<News[]> };
    byAccount: { [ key: string ]: ApiData<News[]> };

    newMarketArticles: News[];
    newArticlesBySymbol: { [ symbol: string ]: News[] };

    constructor () {
        this.marketNews = new ApiData<News[]>([])
        this.portfolioNews = new ApiData<News[]>([])
        this.bySymbol = {}
        this.byAccount = {}

        this.newMarketArticles = []
        this.newArticlesBySymbol = {}
    }
}
