import { ApiData } from '../../../models/ApiData';
import { ApiOperation } from '../../../models/ApiOperation';
import { AinsHistory } from './AinsHistory';
import { BankBalance } from './BankBalance';
import { DailyLimits } from './DailyLimits';
import { FundingManagementLimits } from './FundingManagementLimits';
import { FundingSource } from './FundingSource';
import { LinkedAccount } from './LinkedAccount';
import { PlaidAuthorization } from './PlaidAuthorization';
import { PlaidLinkToken } from './PlaidLinkToken';
import { PlaidVerification } from './PlaidVerification';

/** @deprecated - use FundingSourcesStore, FundingHistoryStore, FundingLimitsStore and PlaidStore */
export class FundingState {

    /** @deprecated - use PlaidStore */
    plaidLinkToken: ApiData<PlaidLinkToken | null>;
    /** @deprecated - use PlaidStore */
    plaidAuthorization: ApiData<PlaidAuthorization | null>;
    /** @deprecated - use PlaidStore */
    updatePlaidAuthorization: ApiOperation;
    /** @deprecated - use PlaidStore */
    plaidVerification: ApiData<PlaidVerification[]>;

    /** @deprecated - use FundingLimitsStore */
    linkedAccounts: ApiData<LinkedAccount[]>;
    /** @deprecated - use FundingLimitsStore */
    balances: { [linkedAccountId: string]: ApiData<BankBalance> }
    /** @deprecated - use FundingLimitsStore */
    addLinkedAccount: ApiData<LinkedAccount | null>;
    /** @deprecated - use FundingLimitsStore */
    voidLinkedAccount: ApiData<LinkedAccount | null>;
    /** @deprecated - use FundingLimitsStore */
    submitDeposit: ApiOperation;
    /** @deprecated - use FundingLimitsStore */
    submitWithdraw: ApiOperation;
    /** @deprecated - use FundingLimitsStore */
    dailyLimits: ApiData<DailyLimits | null>;
    /** @deprecated - use FundingLimitsStore */
    managementLimits: ApiData<FundingManagementLimits | null>;
    /** @deprecated - use FundingSourcesStore */
    fundingSources: ApiData<FundingSource[] | null>;

    /** @deprecated use FundingHistoryStore */
    voidHistory: ApiData<AinsHistory[] | null>;
     /** @deprecated use FundingHistoryStore */
    depositFailuresHistory: ApiData<AinsHistory[] | null>;
     /** @deprecated use FundingHistoryStore */
    withdrawFailuresHistory: ApiData<AinsHistory[] | null>;

    constructor() {
        this.plaidLinkToken = new ApiData(null);
        this.plaidAuthorization = new ApiData(null);
        this.updatePlaidAuthorization = new ApiOperation();
        this.plaidVerification = new ApiData([]);

        this.linkedAccounts = new ApiData([])
        this.balances = {}

        this.addLinkedAccount = new ApiData(null)
        this.voidLinkedAccount = new ApiData(null)

        this.submitDeposit = new ApiOperation()
        this.submitWithdraw = new ApiOperation()

        this.dailyLimits = new ApiData();
        this.managementLimits = new ApiData();

        this.fundingSources = new ApiData([]);

        this.voidHistory = new ApiData([]);
        this.depositFailuresHistory = new ApiData([]);
        this.withdrawFailuresHistory = new ApiData([]);
    }
}
