import { ApiData, ApiOperation } from '../../../models'
import { UpcomingEarningsResponse } from '../Securities'
import { MarketHoliday } from './MarketHoliday'
import { MarketStatus } from './MarketStatus'

export class MarketState {
    holidays: ApiData<MarketHoliday[]>
    upsertHoliday: ApiOperation
    deleteHoliday: ApiOperation
    status: ApiData<MarketStatus>
    upcoming: {
        earningsCalls: ApiData<UpcomingEarningsResponse>
    }

    constructor () {
        this.holidays = new ApiData([])
        this.upsertHoliday = new ApiOperation()
        this.deleteHoliday = new ApiOperation()
        this.status = new ApiData()
        this.upcoming = {
            earningsCalls: new ApiData()
        }
    }
}
