// @ts-strict-ignore
import { GetConfig, Urls } from '../../constants';
import { ReportingPeriod } from '../../constants/ReportingPeriod';
import { MutexEnter, MutexExit } from '../../util';
import AlgoliaHelper from '../../util/AlgoliaHelper';
import { EstimateFiscalQuarter } from '../../util/FiscalQuarter';
import { AnalystTrends, OptionSymbol, SecurityCompanyInfo, SecurityQuote } from '../models';
import { SecurityEarningsEstimates } from '../models/Securities/SecurityEarningsEstimates';
import { Actions } from './Constants';
import { ReduxApiGet, ReduxApiResponse } from './Helpers';
import { ReduxPollingStart, ReduxPollingStop } from './PollingHelpers';
import { FuturesSymbol } from '../../../phoenix/redux/models/Futures/FuturesSymbol';
import { ErrorCategories, ErrorLogger } from 'phoenix/util/ErrorLogger';
import { SecurityMetadata } from '../models/Securities/SecurityMetadata';

export const setSelectedSymbol = (symbol: string) => ({ type: Actions.Securities.SetSelectedSymbol, data: { symbol } });

export const GetRiskFreeRate = () =>
    ReduxApiGet(Urls.securities.getRiskFreeRate(), Actions.Securities.GetRiskFreeRate)
        .withMutex()
        .useStored((s) => s.securities.riskFreeRate?.data)
        .run();

export const GetSecurityMetadataAction = (symbol: string, bypassCached?: boolean): ReduxApiResponse<SecurityMetadata> =>
    ReduxApiGet<SecurityMetadata>(Urls.securities.core.getMetadata(symbol), Actions.Securities.GetMetadata)
        .withSubject(symbol)
        .withMutex()
        .useStored((s) => (bypassCached ? ({} as SecurityMetadata) : s.securities.bySymbol[symbol]?.metadata?.data))
        .run();

export const GetSecurityCompanyInfoAction = (symbol: string) => {
    const cached = GetConfig().Store.getState().securities.bySymbol[symbol]?.companyInfo?.data;

    if (!cached) {
        (async () => {
            let shared = await MutexEnter('company-info-algolia', 30 * 1000);
            if (!shared) {
                try {
                    const match = await AlgoliaHelper.GetSecurityBySymbolExact(symbol);
                    if (match) {
                        shared = {
                            symbol,
                            companyName: match.name,
                            securityName: match.name,
                            description: match.companyDescription
                        };
                        GetConfig().Store.dispatch({ data: shared, subject: symbol, type: Actions.Securities.GetCompanyInfo.Success });
                    }
                } catch (error) {
                    ErrorLogger.RecordError(error, 'GetSecurityCompanyInfoAction - Algolia', { tags: { category: ErrorCategories.algolia } });
                }

                MutexExit('company-info-algolia', shared);
            }
        })();
    }

    return ReduxApiGet(Urls.securities.core.getCompanyInfo(symbol), Actions.Securities.GetCompanyInfo)
        .withSubject(symbol)
        .withMutex()
        .withLoading()
        .onSuccess((s: SecurityCompanyInfo): SecurityCompanyInfo => ({ ...s, website: /^http/.test(s.website) || !s.website ? s.website : `https://${s.website}` }))
        .useStored((_) => cached)
        .run();
};

export const GetCompanyKeyStatsAction = (symbol: string) =>
    ReduxApiGet(Urls.securities.equities.getCompanyKeyStats(symbol), Actions.Securities.GetCompanyKeyStats)
        .withMutex()
        .withSubject(symbol)
        .useStored((s) => s.securities.bySymbol[symbol]?.companyKeyStats?.data)
        .withLoading()
        .run();

export const GetCryptoStatsAction = (symbol: string) =>
    ReduxApiGet(Urls.securities.cryptos.getCryptoStats(symbol), Actions.Securities.GetCryptoStats)
        .withMutex()
        .withSubject(symbol)
        .useStored((s) => s.securities.bySymbol[symbol]?.cryptoStats?.data)
        .withLoading()
        .run();

export const GetCryptoStatsForSymbol = (symbol: string) =>
    ReduxApiGet(Urls.securities.cryptos.getCryptoStatsForSymbol(symbol), Actions.Securities.GetCryptoStatsForSymbol)
        .withMutex()
        .withSubject(symbol)
        .useStored((s) => s.securities.bySymbol[symbol]?.cryptoStats?.data)
        .withLoading()
        .run();

export const GetSecurityAnalystTrends = (symbol: string) =>
    ReduxApiGet(Urls.securities.core.getRatings(symbol), Actions.Securities.GetRatings)
        .withSubject(symbol)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.analystTrends?.data)
        .onSuccess((res: AnalystTrends) => ({ ...res, symbol }))
        .run();

export const GetSecurityEarnings = (symbol: string) =>
    ReduxApiGet(Urls.securities.equities.getEarnings(symbol), Actions.Securities.GetEarnings)
        .withSubject(symbol)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.earnings?.data)
        .run();

export const GetSecurityEarningsEstimatesAction = (symbol: string) =>
    ReduxApiGet(Urls.securities.equities.getEarningsEstimates(symbol), Actions.Securities.GetEarningsEstimates)
        .withSubject(symbol)
        .onSuccess((res: SecurityEarningsEstimates): SecurityEarningsEstimates => ({ ...res, data: res.data.map((x) => ({ ...x, ...EstimateFiscalQuarter(x.period) })) }))
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.earningsEstimates?.data)
        .run();

export const GetSecurityDividendsAction = (symbol: string, range?: string) =>
    ReduxApiGet(Urls.securities.equities.getDividends(symbol, range), Actions.Securities.GetDividends)
        .withSubject(symbol)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.dividends?.data)
        .run();

export const GetTopMovers = () =>
    ReduxApiGet(Urls.securities.getTopMovers(), Actions.Securities.GetTopMovers)
        .onSuccess((r: any) => r.map((q) => ({ ...q, price: q.extendedPrice || q.latestPrice })))
        .withMutex()
        .useStored((s) => (s.securities.top.equities.movers.data?.length ? s.securities.top.equities.movers.data : null), 'movers', 20)
        .run();

export const StartPollingTopMoversAction = () => ReduxPollingStart('movers', GetTopMovers, 60);
export const StopPollingTopMoversAction = () => ReduxPollingStop('movers');

export const GetTopGainers = () =>
    ReduxApiGet(Urls.securities.getTopGainers(), Actions.Securities.GetTopGainers)
        .onSuccess((r: any) => r.map((q) => ({ ...q, price: q.extendedPrice || q.latestPrice })))
        .withMutex()
        .useStored((s) => (s.securities.top.equities.gainers.data?.length ? s.securities.top.equities.gainers.data : null), 'gainers', 20)
        .run();
export const StartPollingTopGainersAction = () => ReduxPollingStart('gainers', GetTopGainers, 60);
export const StopPollingTopGainersAction = () => ReduxPollingStop('gainers');

export const GetTopLosers = () =>
    ReduxApiGet(Urls.securities.getTopLosers(), Actions.Securities.GetTopLosers)
        .onSuccess((r: any) => r.map((q) => ({ ...q, price: q.extendedPrice || q.latestPrice })))
        .useStored((s) => (s.securities.top.equities.losers.data?.length ? s.securities.top.equities.losers.data : null), 'losers', 20)
        .run();
export const StartPollingTopLosersAction = () => ReduxPollingStart('losers', GetTopLosers, 60);
export const StopPollingTopLosersAction = () => ReduxPollingStop('losers');

export const GetSectorPerformanceAction = () =>
    ReduxApiGet(Urls.securities.getSectorPerformance(), Actions.Securities.GetSectorPerformance)
        .withMutex()
        .useStored(
            (s) => (s.securities.sectors.performance.data?.length ? s.securities.sectors.performance.data : null),
            () => 'sector-perf',
            5
        )
        .run();

export const StartPollingSectorPerformanceAction = () => ReduxPollingStart('sector-perf', GetSectorPerformanceAction, 60);
export const StopPollingSectorPerformanceAction = () => ReduxPollingStop('sector-perf');

export const GetRelatedSymbolsAction = (symbol: string) => {
    const url = FuturesSymbol.IsFuturesSymbol(symbol)
        ? Urls.securities.futures.getRelatedEtfs(new FuturesSymbol(symbol)?.baseContract)
        : Urls.securities.core.getRelatedSymbols(symbol);

    return ReduxApiGet(url, Actions.Securities.GetRelatedSymbols)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.relatedSymbols?.data)
        .withSubject(symbol)
        .run();
};

export const GetFrontMonthTopMovers = (count = 8) =>
    ReduxApiGet(Urls.securities.futures.getFrontMonthTopMovers(count), Actions.Securities.GetFrontMonthTopMovers)
        .useStored((s) => (s.securities.top.futures.movers.data?.length ? s.securities.top.futures.movers.data : null))
        .onSuccess((res: SecurityQuote[]) => res.map((x) => ({ ...x, symbol: new FuturesSymbol(x.symbol).withPrefix })))
        .run();

export const GetRelatedMonthsAction = (symbol: string) =>
    ReduxApiGet(Urls.securities.futures.getRelatedMonths(symbol), Actions.Securities.GetRelatedMonths)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.relatedMonths?.data)
        .withSubject(symbol)
        .run();

export const GetFuturesContractsAction = (symbol: string) => {
    return ReduxApiGet(Urls.securities.futures.getContracts(symbol), Actions.Securities.GetFuturesContracts)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.contracts?.data)
        .withSubject(symbol)
        .run();
};

export const GetFuturesTimeSpreadsAction = (symbol: string) => {
    return ReduxApiGet(Urls.securities.futures.getTimeSpreads(symbol), Actions.Securities.GetFuturesTimeSpreads)
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.timeSpreads?.data)
        .withSubject(symbol)
        .run();
};

export const GetSingleFuturesContractAction = (symbol: string) => {
    return ReduxApiGet(Urls.securities.futures.getSingleContract(symbol), Actions.Securities.GetFuturesContracts)
        .onSuccess((res) => [res]) // TODO Make reducer to handle single item response
        .withMutex()
        .useStored((s) => s.securities.bySymbol[symbol]?.contracts?.data)
        .withSubject(symbol)
        .run();
};

export const GetRevenueEstimatesAction = (symbol: string, period: ReportingPeriod) =>
    ReduxApiGet(Urls.securities.equities.getRevenueEstimates(symbol, period), Actions.Securities.GetRevenueEstimates).withMutex().withSubject(symbol).run();

export const GetStockSplitsAction = (symbol: string) =>
    ReduxApiGet(Urls.securities.equities.getStockSplits(symbol), Actions.Securities.GetStockSplits)
        .withMutex()
        .useStored((s) => (s.securities.bySymbol[symbol]?.splits?.data?.length ? s.securities.bySymbol[symbol]?.splits?.data : null))
        .withSubject(symbol)
        .run();

export const GetUpcomingEarningsAction = (symbol: string) =>
    ReduxApiGet(Urls.securities.equities.getUpcomingEarnings(symbol), Actions.Securities.GetUpcomingEarnings)
        .withMutex()
        .withSubject(symbol)
        .useStored((s) => s.securities.bySymbol[symbol]?.upcomingEarnings?.data)
        .run();
