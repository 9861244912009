// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { FormatNumber } from 'phoenix/util';


// /** @deprecated Use XigniteAlertFields */
export const AllowedAlertFields = new Set(['changePercent', 'latestPrice']);

export const XigniteAlertFields = new Set(['Last', 'PercentChangeFromPreviousClose']);

// /** @deprecated Use GetAlertConditionOperators to support i18n */
export const AlertConditionOperators = [
    { label: 'is above', value: '>' },
    { label: 'is above or equal to', value: '>=' },
    { label: 'is below', value: '<' },
    { label: 'is below or equal to', value: '<=' }
    // { label: 'is equal to', value: '==' } Not supported by xignite
];

export const FormattedAlertFields = {
    changePercent: 'Change Percent',
    latestPrice: 'Price'
};

// /** @deprecated use FormatAlertCondition for i18n and to move away from ES6 classes */
export class AlertCondition {
    label?: string;
    name?: string;
    operator?: string;
    value?: any;

    toApiFriendly() {
        return [this.name, this.operator, this.value];
    }

    toString() {
        return `${AlertCondition.CleanLabel(this.label)} ${AlertConditionOperators.find((c) => c.value === this.operator).label} ${this.value}`;
    }

    static CleanLabel(label: string) {
        return label.indexOf(': ') > -1 ? label.split(': ')[1] : label;
    }

    static toStringFromApi(apiCondition: string[]) {
        return `${FormattedAlertFields[apiCondition[0]]} ${AlertConditionOperators.find((op) => op.value === apiCondition[1]).label} ${apiCondition[2]}`;
    }
}

export type AlertConditionType = {
    label: string;
    name: string;
    operator: string;
    value: string;
};

type AlertsText = Snex1LanguagePack['premiumModal']['alerts'];
type AlertConditionOperatorsType = { label: AlertsText[keyof AlertsText]; value: '<' | '>' | '>=' | '<=' }[];

export const GetAlertConditionOperators = (alertText: AlertsText): AlertConditionOperatorsType => [
    { label: alertText.isAbove, value: '>' },
    { label: alertText.isAboveOrEqual, value: '>=' },
    { label: alertText.isBelow, value: '<' },
    { label: alertText.isBelowOrEqual, value: '<=' }
];

const toLocalizedString = ({ alertText, condition }: { alertText: Snex1LanguagePack['premiumModal']['alerts']; condition: AlertConditionType }): string => {
    if (!alertText || !condition) return '';
    const cleanLabel = condition.label.indexOf(': ') > -1 ? condition?.label.split(': ')[1] : condition?.label;
    const operatorText = GetAlertConditionOperators(alertText).find((op) => op.value === condition.operator)?.label;

    return `${cleanLabel} ${operatorText} ${condition?.value}`;
};

const toLocalizedStringFromApi = ({ apiCondition, text }: { apiCondition: string[]; text: Snex1LanguagePack }): string => {
    if (!apiCondition || !text) {
        console.warn('Unable to localize alert string from API; missing condition and/or localization text');
        return '';
    }

    const FormattedXigniteAlertFields = {
        changePercent: text.sectorScreen.changePercent,
        PercentChangeFromPreviousClose: text.sectorScreen.changePercent,
        Last: text.sectorScreen.price,
        latestPrice: text.sectorScreen.price
    };
    const subject = FormattedXigniteAlertFields[apiCondition[0] as keyof typeof FormattedXigniteAlertFields];
    const operatorText = GetAlertConditionOperators(text.premiumModal.alerts).find((op) => op.value === apiCondition[1])?.label;
    const value = (apiCondition[0] === 'Last' || apiCondition[0] === 'latestPrice') 
        ? FormatNumber.toMoney(Number(apiCondition[2])) 
        : FormatNumber.toPercent(Number(apiCondition[2]))

    return `${subject} ${operatorText} ${value}`;
};

const toApiFriendly = (condition: AlertConditionType): string[] => [condition?.name, condition?.operator, condition?.value];

export const FormatAlertCondition = {
    toApiFriendly,
    toLocalizedString,
    toLocalizedStringFromApi
};
