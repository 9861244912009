import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { WatchlistSecurity } from 'phoenix/redux/models';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { AssetClassLabel } from '../../AssetClassLabel/AssetClassLabel';
import { CaptionLabel, SymbolLabel } from '../Shared/Labels';
import { SecurityCellChart } from '../Shared/SecurityCellChart';
import { SecurityCellLogo } from '../Shared/SecurityCellLogo';
import { SecurityNameSection } from '../Shared/SecurityNameSection';
import style from '../style.module.scss';
import { SecurityValue } from './SecurityValue';

export interface FuturesWatchlistCellProps {
    getCached?: boolean;
    onDelete?: (watchlistId?: any) => void;
    security?: WatchlistSecurity;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
}

export const FuturesWatchlistCell = React.memo((props: FuturesWatchlistCellProps) => {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const { streamingNamespace = '', security, symbol } = props;

    const [latestChartPercChange, setLatestChartPercChange] = useState<number | undefined>();
    const [latestChartPrice, setLatestChartPrice] = useState();

    const { ref, inView } = useInView({ trackVisibility: true, threshold: 0, delay: 400 });
    const [subscription, setSubscription] = useState<string>('');

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [symbol, inView]);

    useEffect(() => {
        const run = async () => {
            if (inView && !subscription) {
                const ticket: string = await xdispatch(XS.Quotes.start(symbol, streamingNamespace));
                setSubscription(ticket);
            } else if (!inView) {
                XS.stop(subscription);
                setSubscription('');
            } else {
                await dispatch(GetSecurityQuoteAction(symbol));

                if (!subscription) {
                    const ticket: string = await xdispatch(XS.Quotes.start(symbol, streamingNamespace));
                    setSubscription(ticket);
                }
            }
        };

        run();
        return () => {
            XS.stop(subscription);
        };
    }, [inView]);

    const onCrosshairUpdate = useCallback((value?: any, isScrubbing?: boolean, percChange?: number) => {
        setLatestChartPercChange(typeof percChange === 'number' ? percChange : undefined);
        setLatestChartPrice(typeof value[symbol] === 'number' ? value[symbol] : value[symbol]?.value);
    }, []);

    return (
        <>
            <SecurityCellLogo symbol={symbol} />
            <Flex ref={ref} column className={style.cellContent}>
                <Flex className={style.mainRow}>
                    <SecurityNameSection>
                        <span style={{ marginRight: 5 }}>
                            <SymbolLabel>{security?.name || QualifiedId.RemovePrefix(symbol)}</SymbolLabel>
                        </span>{' '}
                        <AssetClassLabel size='small' textAlign='left' thingId={symbol} />
                        <CaptionLabel>{QualifiedId.RemovePrefix(symbol)}</CaptionLabel>
                    </SecurityNameSection>
                    <SecurityCellChart symbol={symbol} onCrosshairUpdate={onCrosshairUpdate} withQuoteOverride />
                    <SecurityValue chartPercChange={latestChartPercChange} chartPrice={latestChartPrice} symbol={symbol} />
                </Flex>
            </Flex>
        </>
    );
});
