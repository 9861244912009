import { Fade, ClickAwayListener, Paper } from '@mui/material';
import React, { ReactNode } from 'react';
import { useColors } from 'hooks/UseColors';
import { Flex } from 'components/Flex';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

type SnexPopoverProps = {
    children: ReactNode;
    open: boolean;
    onClose: (e?: MouseEvent | TouchEvent) => void;
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
    paperStyles?: React.CSSProperties;
};

export const SnexErrorPopover = (props: SnexPopoverProps): JSX.Element => {
    const colors = useColors();
    return (
        <SnexPopover
            {...props}
            paperStyles={{
                boxSizing: 'border-box',
                width: '105%',
                color: '#D26A5D',
                backgroundColor: '#FEEBE6',
                overflow: 'hidden',
                borderRadius: 6,
                zIndex: 9999,
                ...props.paperStyles
            }}
        >
            <Flex row fullWidth style={{ gap: '1rem' }}>
                <Flex column center align='center' style={{ backgroundColor: '#F45330', padding: '10px', color: colors.white }}>
                    <WarningAmberIcon />
                </Flex>
                <Flex column justify='center' style={{ padding: '10px' }}>
                    {props.children}
                </Flex>
            </Flex>
        </SnexPopover>
    );
};

export const SnexPopover = (props: SnexPopoverProps): JSX.Element => {
    const { top, left, right, bottom, open, paperStyles = {}, onClose } = props;
    const colors = useColors();
    return (
        <div
            style={{ position: 'absolute', transition: 'all 200ms', opacity: open ? 1 : 0, zIndex: 99999, top, bottom, left, right }}
            onKeyDown={(e) => e.key === 'Escape' && props?.onClose && props?.onClose()}
        >
            <Fade unmountOnExit in={open}>
                <span>
                    <ClickAwayListener onClickAway={onClose}>
                        <Paper elevation={12} role='presentation' style={{ background: colors.cardBackgroundColor, width: 400, borderRadius: 10, ...paperStyles }}>
                            {props.children}
                        </Paper>
                    </ClickAwayListener>
                </span>
            </Fade>
        </div>
    );
};
