// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { AssetTradeability } from 'phoenix/constants/AssetTradeability';
import { AssetClass, StandardPriceToQuantityFormula, StandardQuantityToPriceFormula } from 'phoenix/models/AssetClasses/AssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { AccountSummary } from 'phoenix/redux/models';
import { AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { FormatNumber } from 'phoenix/util';
import { ChartRanges } from 'phoenix/constants';
import { Ts } from 'phoenix/assets/lang/T';
import { OrderTypes } from 'phoenix/constants/Trade';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { SecurityMetadataMix, SecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

export const MutualFundAssetClass: AssetClass = {
    // Typing
    type: 'mutual-fund',
    family: 'equities',
    derivative: 'base',
    derivatives: [],
    algoliaTypes: ['oef'],

    // Tradeability
    accountCanTradeThis: EquitiesAssetClass?.accountCanTradeThis,
    canLiquidate: true,
    tradeability: AssetTradeability?.['mutual-fund'],
    userAllowedToTradeThis: () => true,
    defaultOrderType: () => OrderTypes.market,
    negativePriceAllowed: false,

    // Features
    hasDeepAnalysis: false,
    canCreateAlerts: false,
    hasOptions: false,
    hasMultiLeg: false,
    canWatchlist: true,
    tradePriceShortcutButtonsVariant: 'percent',
    hasDerivatives: () => MutualFundAssetClass?.derivatives.length > 1,

    // Charting
    neverShowChartPulse: true,
    hasAdvancedChart: true,
    advancedChartConfiguration: {
        supported_resolutions: []
    },
    chartVariants: ['line'],
    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.oneDay, value: ChartRanges.oneDay },
            { label: text.fiveDay, value: ChartRanges.fiveDays },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.threeMonths, value: ChartRanges.threeMonths },
            { label: text.oneYear, value: ChartRanges.oneYear },
            { label: text.fiveYear, value: ChartRanges.fiveYears }
        ];
    },
    accountChartRanges: EquitiesAssetClass?.accountChartRanges,
    securityChartRanges: ['5d', '1m', '3m', '1y', '5y'],

    formatOrderQuantity: (o) => {
        const { showDecimals = true, decimalPlaces = 2 } = o?.quantityQualifier === 'Shares' ? { showDecimals: true, decimalPlaces: 8 } : {};
        let qty = FormatNumber.removeDecimalsIfZero(FormatNumber.toCommas(o.orderQuantity, showDecimals, decimalPlaces));

        if (o.quantityQualifier === 'EvenDollar') {
            qty = `${FormatNumber.toMoneyOpt2(o?.cost)}`;
        }

        return qty;
    },

    // Formatting + Codec
    getPriceFormatInfo: (meta: SecurityMetadataV2) => {
        const maxDecimalPlaces = FormatNumber.getNumberDecimals(meta?.sizing?.minimumIncrementUsd) || 4;

        return {
            decimalPlaces: undefined,
            tickSize: 0.01,
            moneyFormatOptions: {
                decimalPlaces: maxDecimalPlaces,
                hideCurrencySymbol: false,
                maxDecimalPlaces: maxDecimalPlaces,
                min: 0,
                minDecimalPlaces: 2,
                showDecimals: true,
                tickSize: 0.01
            }
        };
    },
    formatPrice: (price: number, meta: SecurityMetadataMix) => FormatNumber.toMoneyOpt2(price, MutualFundAssetClass?.getPriceFormatInfo(meta).moneyFormatOptions),
    getQuantityFormatOptions: () => {
        return {
            maxDecimalPlaces: 3,
            minDecimalPlaces: 0,
            hideCurrencySymbol: true,
            tickSize: 0.001,
            showDecimals: true
        };
    },
    formatQuantity: (quantity: number, meta: SecurityMetadataMix) => FormatNumber.toMoneyOpt2(quantity, MutualFundAssetClass.getQuantityFormatOptions(meta)),
    unit: 'share',
    idMatches: (id: string) => undefined,
    getSymbolName: (s) => QualifiedId.RemovePrefix(s),
    getSymbolNameLines: (s) => [s],
    getBaseSymbol: (s) => s,
    getConcreteSymbol: (s) => s,

    // Colors
    /** @deprecated Please do not use this value. It does not use theming Use useAssetClassColors hook */
    primaryColor: '#346094',
    positiveChangeColor: 'rgb(2,179,52)',
    negativeChangeColor: 'rgb(178,41,46)',

    // Pricing
    getQuantityPrice: StandardQuantityToPriceFormula,
    getPriceForQuantity: (quantity, quote, opt) => +StandardQuantityToPriceFormula(quantity, quote, opt).toFixed(2),
    getQuantityForPrice: StandardPriceToQuantityFormula,
    getTodaysEarningsLabel: (l) => l.positionsScreen.todaysGainLoss,
    getTotalEarningsLabel: (l) => l.positionsScreen.openPnL,

    // Account Data (N/A)
    getBuyingPowerFigures: (power: AggregateBuyingPower, lang: Snex1LanguagePack) => [],
    getAccountSummaryFigures: (summary: AccountSummary, lang: Snex1LanguagePack) => [],

    // Documents
    documentDeliveryOptions: 'mail-electronic',

    showShortPositionIcon: false
};
