import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Flex } from 'components/Flex';
import { CustomNumberInputProps } from 'components/TradeTicket/Shared/TradeFormComponents';
import { floatMath, FormatNumber } from 'phoenix/util';
import React, { ChangeEvent, FocusEvent, ForwardedRef, MutableRefObject, useCallback, useEffect, useState } from 'react';
import './index.module.css';

const DollarAdornment = (
    <InputAdornment className='custom-dollar-adornment' position='start'>
        $
    </InputAdornment>
);

/** @deprecated Use DecimalPriceInput */
export const CustomNumberInput = React.forwardRef((props: CustomNumberInputProps & TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        arrows = null,
        decimalPlaces = 0,
        disabled,
        error,
        helperText,
        initialValue,
        label,
        min = 0,
        max = Math.max(),
        maxIsInfinite,
        onDollarValueChange,
        showDollarSign,
        step = 1,
        styles
    } = props;

    const [values, setValues] = useState<{ inputNumber?: number; display: string }>({ display: '' });
    const [cursor, setCursor] = useState<number | null>(null);

    // Forwarded ref can be a function, a mutable object, or null
    // We'll check for nulls, otherwise assume it's an HTML element ref
    const typedRef = ref as MutableRefObject<HTMLInputElement>;

    const updateValue = useCallback(
        (number: number, originalInput?: string | null, selectionStart?: number) => {
            if ([null, undefined].includes(originalInput as null | undefined)) return;
            if (originalInput !== '.' && (isNaN(number) || originalInput === '')) {
                setValues({ display: '' });
                return;
            }
            const source = originalInput === '.' ? '0.' : originalInput;
            const formatted = source?.replace(/[^\d.]/g, '') || '';
            const hasDecimal = formatted.indexOf('.') !== -1;
            const [integerPart, fractionalPart] = formatted.split('.');
            const fractionalPartFormatted = fractionalPart?.slice(0, decimalPlaces);
            const integerPartFormatted = integerPart?.replace(/[^\d]/g, ''); // parseInt() assumes commas are decimal points
            const almost = props?.decimalPlaces
                ? `${integerPart ? FormatNumber.toCommas(parseInt(integerPartFormatted)) : ''}${hasDecimal ? `.${fractionalPartFormatted}` : ''}`
                : FormatNumber.toCommas(parseInt(integerPartFormatted));
            const final = number >= max && maxIsInfinite ? `+${almost}` : almost;

            const prevMatch = [...values?.display?.matchAll(/,/g)];
            const prevCommas = prevMatch?.length;
            const newMatch = [...final.matchAll(/,/g)];
            const newCommas = newMatch?.length;

            // If adding or subtracting a new comma from a position < current cursor position, add or subtract the diff
            // So the cursor appears to stay in the same place within the value
            let newCursor = selectionStart || source?.length || 0;
            if (prevCommas !== newCommas) {
                const diff = newCommas - prevCommas;
                if (newMatch.length && (selectionStart || 0) > newMatch?.[0]?.index) {
                    newCursor += diff;
                }
            }

            setCursor(newCursor);
            setValues({ inputNumber: number, display: final });
        },
        [decimalPlaces, max, maxIsInfinite, props?.decimalPlaces, values?.display]
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
        switch (event?.key) {
            case 'ArrowUp':
                return handleIncrement();
            case 'ArrowDown':
                return handleDecrement();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { selectionStart, value: input } = e?.target || {};
        let number = input && parseFloat(parseFloat(input.replace(/[^\d.]/g, '')).toFixed(decimalPlaces));
        if (isNaN(number as number) || number < min) number = min;
        if (number > max) number = max;
        if (onDollarValueChange) onDollarValueChange(number || 0);

        updateValue(number as number, input, selectionStart as number);
    };

    const handleIncrement = () => {
        const number = parseFloat((values?.display || '')?.replace(/[^\d.]/g, '')) || 0;
        const newNumber = floatMath(number, step, (v, s) => v + s) || 0;
        if (onDollarValueChange) onDollarValueChange(newNumber || 0);
        updateValue(newNumber, `${FormatNumber.toLocaleDecimal({ decimalPlaces, value: newNumber })}`);
    };

    const handleDecrement = () => {
        const number = parseFloat((values?.display || '')?.replace(/[^\d.]/g, '')) || 0;
        if (number === min) return;
        const newNumber = floatMath(number, step, (v, s) => v - s) || 0;
        if (onDollarValueChange) onDollarValueChange(newNumber || 0);
        updateValue(newNumber, `${FormatNumber.toLocaleDecimal({ decimalPlaces, value: newNumber })}`);
    };

    const className = [{ 'custom-number-input': true }, { arrows }, { disabled }]
        .filter((x) => Object.values(x)[0])
        .map((x) => Object.keys(x)[0])
        .join(' ');

    // Reformat on blur
    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) props.onBlur(e.target.value);
        const formatted = FormatNumber.toLocaleDecimal({ decimalPlaces, value: values?.inputNumber, signDisplay: false });
        if (formatted !== values?.display && values?.display !== '') setValues({ ...values, display: formatted });
    };

    useEffect(() => {
        if (!typedRef?.current) return;
        const input = typedRef?.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [typedRef, cursor, initialValue]);

    useEffect(() => {
        if (initialValue !== values?.inputNumber && !values.display) {
            const value = typeof initialValue === 'number' || !isNaN(Number(initialValue)) ? initialValue : null;
            updateValue(value as number, value !== null ? value?.toString() : '');
        }
    }, [decimalPlaces, initialValue, updateValue, values]);

    return (
        <TextField
            inputRef={ref}
            fullWidth
            placeholder={decimalPlaces ? `0.${new Array(decimalPlaces).fill('0').join('')}` : '0'}
            variant='outlined'
            {...{
                className,
                disabled,
                error,
                InputProps: {
                    endAdornment: arrows && (
                        <Flex column justify='space-around'>
                            <button title='Increment' onClick={handleIncrement}>
                                <KeyboardArrowUp />
                            </button>
                            <button title='Decrement' onClick={handleDecrement}>
                                <KeyboardArrowDown />
                            </button>
                        </Flex>
                    ),
                    onChange: (e) => handleChange(e),
                    onKeyDown: (e) => handleKeyDown(e),
                    startAdornment: showDollarSign ? DollarAdornment : undefined
                },
                InputLabelProps: props?.InputLabelProps,
                helperText,
                label,
                onBlur,
                styles,
                value: values?.display
            }}
        />
    );
});
