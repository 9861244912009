// @ts-strict-ignore
import { GetMarginCall } from '../../util';
import { Urls } from '../../constants/Urls';
import { GlobalState } from '../GlobalState';
import { AccountSummary } from '../models';
import { Actions } from './Constants';
import { ReduxApiGet } from './Helpers';
import { ApiError } from 'phoenix/models';

const ProcessSummary = (res: AccountSummary): AccountSummary => ({
    ...res,
    todaysGainLossPercentage: res.todaysGainLossPercentage / 100
});

export const GetAggregateSummaryAction = () =>
    ReduxApiGet(Urls.accountSummaries.getAggregate(), Actions.AccountSummaries.GetAggregate)
        .withMutex()
        .useStored((s) => s.accountSummary.aggregate.data, 'portSummary', 20)
        .onSuccess(ProcessSummary)
        .run();

export const GetAccountSummaryAction = (accountNumber?: string) =>
    ReduxApiGet(accountNumber, Actions.AccountSummaries.GetBatch)
        .withSubject(accountNumber)
        .useStored((s: GlobalState) => s.accountSummary.byNumber[accountNumber]?.data, `acctSummary:${accountNumber}`, 20)
        .withBatching('acct-sum', Urls.accountSummaries.getBatch, 250)
        .onSuccess(({ data, errors }: { data: AccountSummary[]; errors: ApiError[] }) => ({ data: data?.map(ProcessSummary), errors }))
        .run();

/** @deprecated Please use the useBuyingPower hook (BuyingPowerStore.ts) */
export const GetAggregateBuyingPowerAction = () =>
    ReduxApiGet(Urls.accountSummaries.getAggregateBuyingPower(), Actions.AccountSummaries.GetBuyingPower)
        .useStored((s) => s.accountSummary.buyingPower?.data, 'agg-buying-power', 60)
        .withMutex('agg-buying-power', true)
        .run();
