// @ts-strict-ignore
import { Actions, ReduxApiGet } from '.';
import { GetConfig, Urls } from '../../constants';
import { EtfSecurity } from '../models/Sectors/ETFSecurity';
const { Securities: SecActions } = Actions;

export const GetEtfSecuritiesBySectorAction = (sector: string) =>
    ReduxApiGet(Urls.sectors.getEtfSecuritiesBySector(sector), Actions.Sectors.GetEtfSecuritiesBySector)
        .withLoading()
        .useStored((s) => s.sectorSecurities.byName[sector]?.etfs?.data)
        .withSubject(sector)
        .onSuccess((result: EtfSecurity[], dispatch) => {
            const reduxState = GetConfig().Store.getState();
            const getQuote = (s: string) => reduxState.securityQuote.bySymbol[s]?.data || {};
            result.forEach((r: EtfSecurity) => {
                dispatch({ type: SecActions.Quote.Get.Success, subject: r.symbol, data: { ...getQuote(r.symbol), ...r, latestPrice: r.price, change: r.priceChange } });
            });
        })
        .run();
