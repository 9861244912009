// @ts-strict-ignore
import { OptionsLevelValuesOrdered } from '../../constants/OptionsLevels';
import { Urls } from '../../constants';
import { GetOneAccount, GetSimulateOptionsLevel, GetZeroAccounts, GetZeroFuturesAccounts } from '../../util';
import { GlobalState } from '../GlobalState';
import { Actions } from './Constants';
import { ReduxApiGet, ReduxApiPut } from './Helpers';
import { MaxNumberOfFuturesAccounts } from '../models/FuturesOnboarding/MaxNumberOfFuturesAccounts';
import { Account } from '../models';

export const GetClientAccountsAction = (bypassCache = false) => {
    let q = ReduxApiGet<Account[]>(Urls.accounts.getAll(false), Actions.Accounts.GetAll)
        .withMutex()
        .onSuccess((r: Account[]) => {
            const debugOptionsLevel = GetSimulateOptionsLevel();
            const simulatedOptionsLevel = ![null, undefined].includes(debugOptionsLevel) && Number(debugOptionsLevel);

            if (GetZeroAccounts()) return [];
            if (GetZeroFuturesAccounts()) return r.filter((f) => !f.accountNumber.includes('F:'));
            else if (GetOneAccount() && r.length) return [r[0]];
            else if (!simulatedOptionsLevel === false && simulatedOptionsLevel >= 0 && simulatedOptionsLevel < 7) {
                return r.map((a) => ({ ...a, optionsLevel: OptionsLevelValuesOrdered[simulatedOptionsLevel - 1] }));
            } else return r;
        });

    if (!bypassCache) {
        q = q.useStored((s: GlobalState) => {
            return s.accounts.all.data?.length ? s.accounts.all.data : null;
        });
    }

    return q.run();
};

export const GetAccountProjectIncomeAction = (accountNumber: string) =>
    ReduxApiGet(Urls.accounts.getProjectedIncome(accountNumber), Actions.Accounts.GetProjectedIncome).withSubject(accountNumber).run();

export const GetAccountProjectIncomeV2Action = (accountNumber: string) =>
    ReduxApiGet(Urls.accounts.getProjectedIncomeV2(accountNumber), Actions.Accounts.GetProjectedIncomeV2).withSubject(accountNumber).run();

export const RenameAccountAction = (accountNumber: string, newName: string) =>
    ReduxApiPut(Urls.accounts.updateNick(accountNumber), Actions.Accounts.UpdateNickname, { nickname: newName })
        .showToasts()
        .withLoading()
        .withSubject(accountNumber)
        .withPassthrough({ passthrough: { newName } })
        .run();

export const GetMaxNumberOfFuturesAccounts = () =>
    ReduxApiGet(Urls.system.getMaxNumberOfFuturesAccounts(), Actions.Accounts.GetMaxNumberOfFuturesAccounts)
        .withMutex()
        .withLoading()
        .onSuccess((res: MaxNumberOfFuturesAccounts) => {
            if (GetZeroFuturesAccounts()) return { ...res, hasReachedMax: res.maxNumberOfAccounts === 0 };
        })
        .run();

export const GetPendingFuturesAccounts = () =>
    ReduxApiGet(Urls.accounts.getPendingFuturesAccounts(), Actions.Accounts.GetFuturesPendingAccounts).withMutex().withLoading().run();

export const GetMediantSSOUrl = (accountNumber: string) =>
    ReduxApiGet(Urls.mediantSSO.getUrl(accountNumber), Actions.MediantSSO.GetUrl).withLoading().withSubject(accountNumber).withMutex().run();
