// @ts-strict-ignore
import { ApiData, ApiOperation, ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AccountApplicationStatusesState } from '../models/AccountApplicationStatus/AccountApplicationStatusesState'
import { AccountApplicationStatus } from '../models/AccountApplicationStatus/AccountApplicationStatus'

const check = GroupNameChecker([Actions.AccountApplicationStatus])
export const AccountApplicationStatusReducer = (
    state: AccountApplicationStatusesState = new AccountApplicationStatusesState(),
    action: ReduxAction): AccountApplicationStatusesState => {
    if (!check(action)) return state

    const existing = state.byAccountNumber[action.subject] || new ApiData<AccountApplicationStatus>()

    switch (action.type) {
        case Actions.AccountApplicationStatus.GetByAccountNumber.Loading:
            return {
                ...state,
                byAccountNumber: {
                    ...state.byAccountNumber,
                    [action?.subject]: existing.startLoading()
                }
            }
        case Actions.AccountApplicationStatus.GetByAccountNumber.Success:
            return {
                ...state,
                byAccountNumber: {
                    ...state.byAccountNumber,
                    [action?.subject]: state.byAccountNumber[action.subject].succeeded(action.data)
                }
            }
        case Actions.AccountApplicationStatus.GetByAccountNumber.Failure:
            return {
                ...state,
                byAccountNumber: {
                    ...state.byAccountNumber,
                    [action?.subject]: state.byAccountNumber[action.subject].failed(action.error)
                }
            }
        default: return state
    }
}
