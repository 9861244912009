import axios, { Method } from 'axios';
import { addMilliseconds } from 'date-fns';
import { SnexError } from 'phoenix/models/SnexError';
import { Dispatch } from 'redux';
import { GetConfig, StorageKeys } from '../../constants';
import { AsyncOperationOutcome, CrossPlatformRelays } from '../../constants/CrossPlatformRelays';
import { DownOperationSubmitting, UpOperationSubmitting } from '../../hooks/UseOperationSubmitting';
import { SetRelay } from '../../hooks/UseRelay';
import { ReduxAction } from '../../models/ReduxAction';
import { GetEnableDebugLogging, GetUseVerboseRedux, JwtHasExpired } from '../../util';
import { CheckCacheTime, SetCacheTime } from '../../util/CacheManager';
import { HitDebounce } from '../../util/DebounceManager';
import { GenerateFancifulName } from '../../util/GenerateFancifulName';
import { GetMemo, SetMemo } from '../../util/Memo';
import { MutexEnter, MutexExit } from '../../util/MutexManager';
import { ChaosIsHappening } from '../Chaos';
import { SnexThunkDispatch } from '../configureStore';
import { GlobalState } from '../GlobalState';
import { MyToken } from '../models/Users/MyToken';
import { ActionNames, Actions } from './Constants';
import { IncrementApiCallCount, IncrementBatchedCallCount, IncrementCachedCallCount, IncrementMutexdCallCount } from './DebugActions';
import { GetUserTokenAction } from './UserActions';

let _onRequestTimeoutMs = 0;
let _onRequestTime = new Date();
let _onRequestCallback: () => Promise<void>;
export const OnApiRequestAfterTime = (timeoutMs: number, action: () => Promise<void>): void => {
    if (_onRequestCallback !== null) {
        console.warn('WARNING! You are setting OnApiRequestAfterTime when you have already defined a callback. This will override the existing callback.');
    }
    _onRequestTimeoutMs = timeoutMs;
    _onRequestTime = addMilliseconds(new Date(), 2000); // Starting off, check after a couple of seconds in case the token is about to expire
    _onRequestCallback = action;
};

// export type SnexDispatch = ThunkDispatch<GlobalState, (options?: ReduxLoggerOptions) => Middleware, AnyAction>;
export type ReduxApiResponse<TResult> = (dispatch: SnexThunkDispatch, getState: () => GlobalState) => Promise<TResult>;

// URL can be null presumably if it's being batched
type UrlType = string | string[] | null;

export class ReduxApiRequest<TResult, TResponse, SubjectType> {
    method: Method;
    url: UrlType;
    names: ActionNames;
    body?: Record<string, unknown>;
    headers?: Record<string, string>;
    actionExtras?: {
        _debug?: string;
        [key: string]: unknown;
    };

    successHandler?: (response: TResponse, dispatch: Dispatch) => TResult;
    errorHandler?: (error: unknown, dispatch: Dispatch) => TResult;
    skipToken?: boolean;
    useStoredSelector?: (s: GlobalState) => TResult;
    useStoredFireSuccess?: boolean;
    mutexKeySelector?: () => string;
    mutexReuseResult: boolean;
    cacheKeySelector?: () => string;
    cacheTimeoutSeconds?: number;
    cacheSlidingExpiration?: boolean;
    toastableErrorCodes?: Set<string>;
    universalErrorMessage?: string;
    errorTransforms?: { [key: string]: string };
    upstreamErrorTransforms?: { [key: string]: string };
    batchIdSelector?: () => string;
    batchUrlCombiner?: (urls: string[]) => string | string[];
    dispatchOnBatch?: string;
    batchDebounceMs: number;
    skipLoading: boolean;
    triggerAsyncOperationIndicator: boolean;
    beforeRun?: () => TResult | null;
    noOutput?: boolean;

    constructor(method: Method, url: UrlType, names: ActionNames, body?: Record<string, unknown>, headers?: Record<string, string>) {
        this.actionExtras = {};
        this.batchDebounceMs = 0;
        this.body = body;
        this.cacheSlidingExpiration = false;
        this.errorTransforms = {};
        this.headers = headers;
        this.method = method;
        this.mutexReuseResult = true;
        this.names = names;
        this.noOutput = false;
        this.skipLoading = !GetUseVerboseRedux();
        this.skipToken = false;
        this.triggerAsyncOperationIndicator = false;
        this.upstreamErrorTransforms = {};
        this.url = url;
        this.useStoredFireSuccess = false;
    }

    withPassthrough(extras: Partial<ReduxAction>): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.actionExtras = { ...(this.actionExtras || {}), ...extras };
        return this;
    }

    withSubject(subject: SubjectType): ReduxApiRequest<TResult, TResponse, SubjectType> {
        return this.withPassthrough({ subject });
    }

    withDebug(debug: string): ReduxApiRequest<TResult, TResponse, SubjectType> {
        return this.withPassthrough({ passthrough: { _debug: ` DEBUG=${debug}` } });
    }

    // Normally, requets will reach out to the API to get the current user's token to include in the request
    // If you call this, then it won't do that
    withoutToken(): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.skipToken = true;
        return this;
    }

    // Add mutual exclusion (mutex) per selected key. Each request gets a key. If a request with the same key is already
    // pending, the request will sleep until the first returns. If reuseResult is true, then the result of the first will
    // be used immediately for the others. Key selector is executed at runtime to minimize the chances of a race. If no
    // key selected is provied, then the request's URL is used as the mutex key
    withMutex(keySelector?: string | (() => string), reuseResult = true): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.mutexKeySelector = (() => {
            if (!keySelector) return () => (Array.isArray(this.url) ? this.url.join(';') : this.url) || '';
            else if (typeof keySelector === 'string') return () => keySelector;
            else return keySelector;
        })();
        this.mutexReuseResult = reuseResult;
        return this;
    }

    withoutLoading(): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.skipLoading = true;
        return this;
    }

    withLoading(): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.skipLoading = false;
        return this;
    }

    withAsyncOperationIndicator(): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.triggerAsyncOperationIndicator = true;
        return this;
    }

    showToasts(toastCodes: string[] = []): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.toastableErrorCodes = new Set(toastCodes);
        return this;
    }

    withErrorMessage(message: string): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.universalErrorMessage = message;
        return this;
    }

    // If the request fails and its *root-level* `errorCode` field matches `key`, then replace its root-level `errorMessage`
    // field with the provided value. If you want to transform according to `errorCode` in the response's `upstreamData`
    // field, use withUpstreamErrorTransfrom instead.
    withErrorTransform(code: string, newMessage: string): ReduxApiRequest<TResult, TResponse, SubjectType> {
        if (this.errorTransforms) this.errorTransforms[code] = newMessage;
        return this;
    }

    withUpstreamErrorTransform(code: string, newMessage: string): ReduxApiRequest<TResult, TResponse, SubjectType> {
        if (this.upstreamErrorTransforms) this.upstreamErrorTransforms[code] = newMessage;
        return this;
    }

    withBatching(
        batchId: string | (() => string),
        urlCombiner: (urls: string[]) => string | string[],
        debounceTimeMs: number,
        dispatchOnBatch = ''
    ): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.batchIdSelector = typeof batchId === 'string' ? () => batchId : batchId;
        this.batchDebounceMs = debounceTimeMs;
        this.batchUrlCombiner = urlCombiner;
        this.dispatchOnBatch = dispatchOnBatch;
        return this;
    }

    withoutOutput(): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.noOutput = true;
        return this;
    }

    // Set a selector that runs before the API call. If the selector returns anything (i.e., something that isn't null, undefined, or false),
    // just return that result instead of reaching out to the API. Use this to cache data without hitting the API. Use the `sliding` argument
    // to indicate whether cache hits should extend the cache lifetime or not.
    //
    // Important note -- as a performance optimization, useStored will NOT cause the "Success" action to fire.
    // This means that cache hits will not cause any "useSelector"s to update. The most important implication of this
    // is that if your `selector` pulls from a different part of the state than where the reduce puts it, you'll
    // end up with no data. To account for this scenario, please use `useStoredExotic` instead, which will cause the
    // `success` event to fire on cache hits.
    useStored(
        selector: (s: GlobalState) => TResult,
        cacheKeySelector?: (() => string) | string,
        cacheTimeoutSeconds?: number,
        sliding?: boolean
    ): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.cacheKeySelector = typeof cacheKeySelector === 'function' ? cacheKeySelector : () => cacheKeySelector || '';
        this.cacheTimeoutSeconds = cacheTimeoutSeconds;
        this.useStoredSelector = selector;
        this.cacheSlidingExpiration = sliding;
        return this;
    }

    // Same as useStored, but fires off `success` event on cache hits
    useStoredExotic(
        selector: (s: GlobalState) => TResult,
        cacheKeySelector?: () => string,
        cacheTimeoutSeconds?: number,
        sliding?: boolean
    ): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.cacheKeySelector = cacheKeySelector;
        this.cacheTimeoutSeconds = cacheTimeoutSeconds;
        this.useStoredSelector = selector;
        this.useStoredFireSuccess = true;
        this.cacheSlidingExpiration = sliding;
        return this;
    }

    // Used to run some imperative code before the API request is sent. Errors thrown from it are dispatched in an error event. Data returned from
    // it is discarded (for that purpose, please use useStored).
    onBeforeSend(run: () => TResult | null): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.beforeRun = run;
        return this;
    }

    onSuccess(handler: (result: TResponse, dispatch: SnexThunkDispatch) => TResult): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.successHandler = handler;
        return this;
    }

    onError(handler: (result: unknown, dispatch: SnexThunkDispatch) => TResult): ReduxApiRequest<TResult, TResponse, SubjectType> {
        this.errorHandler = handler;
        return this;
    }

    run(includeErrorCode?: boolean): ReduxApiResponse<TResult> {
        return async (dispatch: SnexThunkDispatch, getState: () => GlobalState): Promise<TResult> => {
            const handleError = (error: unknown) => {
                // eslint-disable-next-line no-console -- Debug only
                if (GetEnableDebugLogging()) console.log(`[HTTP] ${this.method} XXX ${this.url}${this.actionExtras?._debug || ''}:`, error);
                const e = (error as SnexError)?.errorCode
                    ? error
                    : (() => {
                          const errorJson = includeErrorCode
                              ? JSON.stringify({
                                    message: (error as XMLHttpRequest)?.responseText,
                                    errorCode: `${(error as XMLHttpRequest)?.response?.status}`
                                })
                              : (error as XMLHttpRequest)?.responseText;

                          try {
                              return errorJson && JSON.parse(errorJson);
                          } catch (x) {
                              console.warn('Failed to parse error response', x, ` to request ${this.url}; original error:`, errorJson || error);
                              return {};
                          }
                      })();

                const errorResult = (this.errorHandler ? this.errorHandler({ ...e, ...this.actionExtras }, dispatch) : { ...e, failed: true }) || {};
                const effectiveCode = errorResult?.errorCode || e?.errorCode || '';
                errorResult.errorCode = effectiveCode;

                const replacementMessage =
                    this.upstreamErrorTransforms?.[e?.upstreamData?.errorCode] || this.errorTransforms?.[effectiveCode || ''] || this.universalErrorMessage;

                if (replacementMessage) errorResult.errorMessage = replacementMessage;

                // Special relay effect for if the API says this feature is premium. API should not send premium errors
                // if feature flag is set, so no need to filter it here as well
                if (e?.errorCode === 'PREMIUM') dispatch({ type: Actions.Relay.Update, subject: CrossPlatformRelays.ShowPremiumUpgradeModal, data: true });

                const showToast = !!this.toastableErrorCodes && (this.toastableErrorCodes.size === 0 || this.toastableErrorCodes?.has(e.errorCode || ''));
                const errorDisplay = showToast ? 'toast' : 'none';

                dispatch({ ...this.actionExtras, type: this.names.Failure, requestBody: this.body, error: errorResult, errorDisplay });
                dispatch({
                    ...this.actionExtras,
                    type: Actions.Errors.ReportApiError,
                    requestBody: this.body,
                    error: errorResult,
                    subject: this.names.Failure,
                    errorDisplay
                });
                return errorResult;
            };

            // If it's time, run the onRequestCallback.
            const timeToRefresh = _onRequestTime < new Date();
            if (_onRequestCallback && timeToRefresh) {
                if (_onRequestTime < new Date()) {
                    // Checking _onRequestTime again since it's possible another bounce already got here
                    try {
                        // console.log('[REFRESH] (winner thread -- calling callback)')
                        await _onRequestCallback();
                        _onRequestTime = addMilliseconds(new Date(), _onRequestTimeoutMs);
                    } catch (e) {
                        console.warn('Notice: onRequest callback failed with error', e);
                    }
                } else {
                    // console.log('[REFRESH] periodic refresh not triggering, another thread has already refreshed')
                }
            } else {
                // if (!_onRequestCallback) console.log('[REFRESH] NOT refreshing, no callback defined')
                // if (!timeToRefresh) console.log(`[REFRESH] NOT refreshing, it is too early still (${ _onRequestTime.getTime() - new Date().getTime() }ms left until we check)`)
            }

            if (this.beforeRun) {
                try {
                    this.beforeRun();
                } catch (e) {
                    return handleError(e);
                }
            }
            if (this.useStoredSelector) {
                const stored = this.useStoredSelector(getState());

                const storedValid = !(stored === null || stored === undefined || stored === false);
                const notExpired = !this.cacheKeySelector || CheckCacheTime(this.cacheKeySelector(), this.cacheTimeoutSeconds || 0) === 'valid';
                if (storedValid && notExpired) {
                    if (this.cacheSlidingExpiration && this.cacheKeySelector) SetCacheTime(this.cacheKeySelector());
                    IncrementCachedCallCount(this.names.BaseName);
                    if (this.useStoredFireSuccess || GetUseVerboseRedux()) {
                        dispatch({ ...this.actionExtras, type: this.names.Success, data: stored, requestBody: this.body, fromCache: true });
                    }
                    return stored;
                }
            }

            if (this.batchIdSelector) {
                if (this.dispatchOnBatch) dispatch({ ...this.actionExtras, type: this.dispatchOnBatch });
                const id = this.batchIdSelector();
                const directive = await HitDebounce(
                    id,
                    { url: this.url, extras: this.actionExtras },
                    (combined: { url: string; extras: Record<string, unknown> }[]) => {
                        this.url = this.batchUrlCombiner ? this.batchUrlCombiner(combined.map((c) => c.url)) : '';
                        this.actionExtras = { ...this.actionExtras, subject: combined.map((c) => c.extras?.subject).filter((x) => !!x) };
                    },
                    this.batchDebounceMs
                );
                if (directive === 'halt') {
                    if (!this.skipLoading) dispatch({ ...this.actionExtras, type: this.names.Loading, requestBody: this.body, fromCache: true });
                    const shared = await MutexEnter<TResult>(id);
                    IncrementBatchedCallCount(this.names.BaseName);
                    // if (shared && GetEnableDebugLogging()) console.log('Using batched result for', this.names.Success, 'data:', shared);
                    return shared;
                }
            }

            if (!this.skipLoading) dispatch({ ...this.actionExtras, type: this.names.Loading, requestBody: this.body });
            if (this.triggerAsyncOperationIndicator) UpOperationSubmitting();

            const mutexKey = !this.mutexKeySelector ? null : this.mutexKeySelector();
            if (mutexKey) {
                const shared = await MutexEnter(this.mutexKeySelector ? this.mutexKeySelector() : '');
                if (this.mutexReuseResult && shared !== null) {
                    // eslint-disable-next-line no-console -- Debug only
                    if (GetEnableDebugLogging()) console.log('Using raced result for', this.names.Success);
                    IncrementMutexdCallCount(this.names.BaseName);
                    if (GetUseVerboseRedux()) dispatch({ ...this.actionExtras, type: this.names.Success, data: shared, requestBody: this.body, fromCache: true });
                    return shared as TResult;
                }
            }

            let headers = { ...this.headers };
            let jwt = '';
            if (!this.skipToken) {
                // Only go through the whole token Redux process if we really need to (and if we're using the API to store the token)
                jwt = GetMemo(StorageKeys.JwtMemo);
                const tokenExists = !!jwt;
                const tokenExpired = JwtHasExpired(jwt);
                if ((!tokenExists || tokenExpired) && GetConfig()?.TokenRetrievalMethod === 'api') {
                    const accessToken: MyToken = await dispatch(GetUserTokenAction()); // <-- emits an event on success, which we don't want to happen unnecessarily

                    jwt = accessToken.accessToken;
                    SetMemo(StorageKeys.JwtMemo, jwt, 'HTTP helper token step (retrieval method = "api")');
                }
                headers = { ...headers, Authorization: `Bearer ${jwt}` };
            }

            IncrementApiCallCount(this.names.BaseName);
            const urls: string[] = Array.isArray(this.url) ? this.url : [this.url || ''];

            let success = true;
            const handle = async (url: string) => {
                try {
                    if (GetEnableDebugLogging() && !this.noOutput)
                        // eslint-disable-next-line no-console -- Debug only
                        console.log(`[HTTP] ${this.method}   > ${url}${this.actionExtras?._debug || ''} / ${GenerateFancifulName(jwt)}`);

                    const start = new Date().getTime();
                    let { data } = await axios({ method: this.method, url, data: this.body, headers });

                    if (GetEnableDebugLogging() && !this.noOutput)
                        // eslint-disable-next-line no-console -- Debug only
                        console.log(`[HTTP] ${this.method} <   ${url}${this.actionExtras?._debug || ''} ${new Date().getTime() - start}ms`);

                    if (ChaosIsHappening()) {
                        const error: SnexError = {
                            errorCode: 'CHAOS',
                            errorMessage: 'This request was intentially sabotatged to simulate API unreliabillity'
                        };
                        throw error;
                    }
                    if (this.successHandler) {
                        const transformed = this.successHandler(data, dispatch);
                        data = transformed || data;
                    }
                    dispatch({ ...this.actionExtras, type: this.names.Success, data, requestBody: this.body });
                    if (this.cacheKeySelector) SetCacheTime(this.cacheKeySelector());
                    if (mutexKey) MutexExit(mutexKey, data);
                    if (this.batchIdSelector) MutexExit(this.batchIdSelector(), data);

                    return data || true;
                } catch (error) {
                    // <-- sometimes error is the error body... other times it a NetworkError object. THANK YOU AXIOS YOU ARE SO COOL!!! :)
                    success = false;
                    return handleError(error);
                }
            };

            const promises = urls.map(async (u) => handle(u));

            await Promise.all(promises);

            if (this.triggerAsyncOperationIndicator) {
                SetRelay<AsyncOperationOutcome>(CrossPlatformRelays.AsyncOperationOutcome, success ? { success: 'success' } : { success: 'failure' });
                DownOperationSubmitting();
            }
            return promises.length === 1 ? await promises[0] : null;
        };
    }

    almostRun(simulateReduxActions?: boolean, mockedDataOrError?: TResult | SnexError, fail?: boolean) {
        return async (dispatch: Dispatch): Promise<TResult | SnexError | undefined> => {
            // eslint-disable-next-line no-console -- Debug only
            const log = () => console.log(`Almost running but not quite! Would have sent:\n\n${this.method} ${this.url}\n\n${JSON.stringify(this.body)}`);
            if (!simulateReduxActions) {
                log();
                return;
            }

            if (!this.skipLoading) dispatch({ ...this.actionExtras, type: this.names.Loading });
            return await new Promise((resolve) => {
                setTimeout(() => {
                    let result: TResult | SnexError | undefined;
                    log();
                    try {
                        if (fail) {
                            const e = mockedDataOrError || new Error();
                            result = this.errorHandler
                                ? this.errorHandler(e, dispatch)
                                : {
                                      ...e,
                                      errorCode: (e as SnexError)?.errorCode || '',
                                      failed: true
                                  };
                            const showToast =
                                !!this.toastableErrorCodes && (this.toastableErrorCodes.size === 0 || this.toastableErrorCodes?.has((e as SnexError)?.errorCode || ''));
                            dispatch({ ...this.actionExtras, type: this.names.Failure, error: e });
                            dispatch({
                                type: Actions.Errors.ReportApiError,
                                error: e,
                                errorDisplay: showToast ? 'toast' : 'none',
                                subject: this.names.Failure
                            });
                        } else {
                            const data = mockedDataOrError || undefined;
                            result = this.successHandler ? this.successHandler(data as TResponse, dispatch) : data;
                            dispatch({ ...this.actionExtras, type: this.names.Success, data });
                        }
                    } catch (e) {
                        console.error('Simulated Redux event failed:', e);
                    }
                    resolve(result);
                }, 3000);
            });
        };
    }
}

// Sometimes the resulting function returns something different than the API response
// TResponse represents data being returned from the API
// TResult represents the final shape of the data after any passthrough transformations
// Usually they will be the same shape, which is why it is defaulted if not specified

// Subject annoyingly can be pretty much anything, so it needs to be generic.
// Most often it's a string used as a key, so that's the default

export const ReduxApiGet = <TResult = unknown, TResponse = TResult, SubjectType = string>(
    url: UrlType,
    names: ActionNames,
    headers?: Record<string, string>
): ReduxApiRequest<TResult, TResponse, SubjectType> => new ReduxApiRequest('GET', url, names, headers);

export const ReduxApiDelete = <TResult = unknown, TResponse = TResult, SubjectType = string>(
    url: UrlType,
    names: ActionNames,
    headers?: Record<string, string>
): ReduxApiRequest<TResult, TResponse, SubjectType> => new ReduxApiRequest('DELETE', url, names, headers);

export const ReduxApiPost = <TResult = unknown, TResponse = TResult, SubjectType = string>(
    url: UrlType,
    names: ActionNames,
    body?: Record<string, unknown>,
    headers?: Record<string, string>
): ReduxApiRequest<TResult, TResponse, SubjectType> => new ReduxApiRequest('POST', url, names, body, headers);

export const ReduxApiPut = <TResult = unknown, TResponse = TResult, SubjectType = string>(
    url: UrlType,
    names: ActionNames,
    body?: Record<string, unknown>,
    headers?: Record<string, string>
): ReduxApiRequest<TResult, TResponse, SubjectType> => new ReduxApiRequest('PUT', url, names, body, headers);
