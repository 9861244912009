// @ts-strict-ignore
import React from 'react';
import ReactLinesEllipsis from 'react-lines-ellipsis';

// Wrapper for react-lines-ellipsis because it doesn't have a types package! :D

interface LinesEllipsisProps {
    lines: number,
    children: string
    component?: string
}

export const LinesEllipsis = (props: LinesEllipsisProps) => {

    return (
        <ReactLinesEllipsis component={props.component || 'div'} maxLine={ props.lines.toString() } text={ props.children || '' } />
    );

};