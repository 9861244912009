import { ApiData } from '../../../models'
import { AccountBalances } from './AccountBalances'

export class AccountBalancesState {
    all: ApiData<AccountBalances[]>;

    constructor () {
        this.all = new ApiData<AccountBalances[]>([])
    }
}
