// @ts-strict-ignore
import * as Sentry from '@sentry/react';
import { Flex } from 'components';
import { SnexButton } from 'components/SnexButton';
import { ResolveFrontend, Urls } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { EquitiesOnboardingActionsV2 } from 'phoenix/onboarding/equities/actions/EquitiesOnboardingActions';
import { Okta } from 'phoenix/util/Okta';
import React, { useEffect, useMemo, useState } from 'react';
import { AddToast, useToasts } from 'react-toast-notifications';
import { OnboardingInputRow, OnboardingTextField, OnboardingToast } from '../../../Base/OnboardingControls';
import { useRegistrationStore } from '../../Store';
import { getVerifyEmailErrors } from './Validation';

export const HandleVerifyAndLoginClick = async (addToast: AddToast) => {
    const { data, verificationCode, clearData, setValue } = useRegistrationStore.getState();
    setValue('verifying', true);
    const { firstName, lastName, email, password, phone, countryCode } = data;
    const verifyRes = await EquitiesOnboardingActionsV2.verifySignupCodeAndComplete({
        email,
        password,
        activationCode: verificationCode,
        firstName,
        lastName,
        citizenshipCountry: 'US',
        phoneNumber: phone,
        countryCode: countryCode.toString()
    });
    if (!verifyRes.success) {
        const err = { ...verifyRes, firstName, lastName, email, phoneNumber: phone, countryCode: countryCode || 1, citizenshipCountry: 'US' };
        Sentry.captureException(err);
        addToast(<OnboardingToast message={'Verification failed.'} isError={false} />, { appearance: 'error' });
        setValue('codeIsVerified', false);
    } else {
        setValue('codeIsVerified', true);
        // use cookies
        const oktaResponse = await Okta._getSessionToken(data.email, data.password);
        const sessionToken = oktaResponse.data?.sessionToken;
        const redirectUrl = ResolveFrontend();
        const loginUrl = Urls.authentication.okta.webLogin(sessionToken, redirectUrl);
        clearData();
        window.location.href = loginUrl;
    }
    setValue('verifying', false);
};

export const Registration_VerifyEmail_Form = () => {
    const { data, verificationCode, codeIsVerified, setValue } = useRegistrationStore();
    const text = useText((t) => t.webRegistration);
    const errors = useMemo(() => getVerifyEmailErrors(), [data, verificationCode, codeIsVerified]);
    const [resending, setResending] = useState<boolean>(false);
    const { addToast } = useToasts();

    useEffect(() => {
        setValue('codeIsVerified', null);
    }, [verificationCode]);

    const handleResendClick = async () => {
        setResending(true);
        try {
            const result = await EquitiesOnboardingActionsV2.resendVerificationCode(data.email);
            if (result?.success) {
                addToast(<OnboardingToast title={'Success'} message={`${text.resentVerificationCode} to ${data.email}`} isError={false} />, { appearance: 'info' });
            } else {
                addToast(<OnboardingToast message={result?.error} isError={false} />, { appearance: 'error' });
            }
        } catch (e) {
            addToast(<OnboardingToast message={e} isError={false} />, { appearance: 'error' });
        }
        setResending(false);
    };

    return (
        <Flex column fullWidth center style={{ minWidth: '450px' }}>
            <OnboardingInputRow noPadding align='flex-end'>
                <OnboardingTextField
                    name='verificationCode'
                    label={text.verificationCode}
                    value={verificationCode}
                    handleChange={setValue}
                    errors={errors?.filter((e) => e.key === 'verificationCode')}
                    button={
                        codeIsVerified ? null : (
                            <SnexButton flavor={resending ? 'loading' : undefined} onClick={handleResendClick} style={{ padding: '10px 15px', margin: 0 }}>
                                {text.resendCode}
                            </SnexButton>
                        )
                    }
                />
            </OnboardingInputRow>
        </Flex>
    );
};
