// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { FundReinvestmentPreferences } from '../models/Funds/FundReinvestmentPreferences';
import { FundsState, FundsSubState } from '../models/Funds/FundsState';

const orNew = (ss: ApiData<any>, defaultValue?: any) => {
    return ss || new ApiData(defaultValue)
}

const check = GroupNameChecker([Actions.Funds]);
export const FundsReducer = (state: FundsState = new FundsState(), action: ReduxAction): FundsState => {
    if (!check(action)) return state;

    const withQsiUpdate = (mutate: (ss: Partial<FundsSubState>) => void): FundsState => {
        const n = { ...(state.byQsi[action.subject] || new FundsSubState()) };
        mutate(n);
        state.byQsi[action.subject] = n;
        return state;
    };

    const withReinvestUpdate = (mutate: (d: ApiData<FundReinvestmentPreferences>) => ApiData<FundReinvestmentPreferences>): FundsState => {
        const n = { ...(state.byQsi[action.subject.qsi] || new FundsSubState()) };
        const account = action.subject.accountNumber;
        if (!n.reinvestmentPreferences) n.reinvestmentPreferences = {};
        if (!n.reinvestmentPreferences[account]) n.reinvestmentPreferences[account] = new ApiData<FundReinvestmentPreferences>();
        n.reinvestmentPreferences[account] = mutate(n.reinvestmentPreferences[account]);
        state.byQsi[action.subject.qsi] = n;
        return state;
    };

    switch (action.type) {
        case Actions.Funds.GetHoldings.Loading:
            return { ...withQsiUpdate((s) => (s.holdings = orNew(s.holdings, []).startLoading())) };
        case Actions.Funds.GetHoldings.Success:
            return { ...withQsiUpdate((s) => (s.holdings = orNew(s.holdings, []).succeeded(action.data))) };
        case Actions.Funds.GetHoldings.Failure:
            return { ...withQsiUpdate((s) => (s.holdings = orNew(s.holdings, []).failed(action.error))) };

        case Actions.Funds.GetProfile.Loading:
            return { ...withQsiUpdate((s) => (s.profile = orNew(s.profile).startLoading())) };
        case Actions.Funds.GetProfile.Success:
            return { ...withQsiUpdate((s) => (s.profile = orNew(s.profile).succeeded(action.data))) };
        case Actions.Funds.GetProfile.Failure:
            return { ...withQsiUpdate((s) => (s.profile = orNew(s.profile).failed(action.error))) };

        case Actions.Funds.GetSectorExposure.Loading:
            return { ...withQsiUpdate((s) => (s.sectorExposure = orNew(s.sectorExposure, []).startLoading())) };
        case Actions.Funds.GetSectorExposure.Success:
            return { ...withQsiUpdate((s) => (s.sectorExposure = orNew(s.sectorExposure, []).succeeded(action.data))) };
        case Actions.Funds.GetSectorExposure.Failure:
            return { ...withQsiUpdate((s) => (s.sectorExposure = orNew(s.sectorExposure, []).failed(action.error))) };

        case Actions.Funds.GetCountryExposure.Loading:
            return { ...withQsiUpdate((s) => (s.countryExposure = orNew(s.countryExposure, []).startLoading())) };
        case Actions.Funds.GetCountryExposure.Success:
            return { ...withQsiUpdate((s) => (s.countryExposure = orNew(s.countryExposure, []).succeeded(action.data))) };
        case Actions.Funds.GetCountryExposure.Failure:
            return { ...withQsiUpdate((s) => (s.countryExposure = orNew(s.countryExposure, []).failed(action.error))) };

        case Actions.Funds.GetReinvestmentPreferences.Loading:
            return { ...withReinvestUpdate((d) => d.startLoading()) };
        case Actions.Funds.GetReinvestmentPreferences.Success:
            return { ...withReinvestUpdate((d) => d.succeeded(action.data)) };
        case Actions.Funds.GetReinvestmentPreferences.Failure:
            return { ...withReinvestUpdate((d) => d.failed(action.error)) };

        default:
            return state;
    }
};
