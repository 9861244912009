// Full list: https://sdk.lightstreamer.com/ls-web-client/8.0.3/api/ClientListener.html
export const LightstreamerErrorCodes = {

    ConnectionRefused: 0,
    InvalidCredentials: 1,
    AdapterSetUnavailable: 2,
    LicenseLimitReached: 7,
    MaxSessionsReached: 8,
    MaxServerLoadReached: 9,
    NewSessionTemporarilyBlocked: 10,
    SpecialLicenseRestriction: 11,
    ServerAffinityFailure: 21,
    SessionKilledByDestroyCommand: 31,
    SessionKilledByAdminOrDaemon: 32,
    UnexpectedError1: 33,
    UnexpectedError2: 34,
    TooManySessionsForUser: 35,
    LsClientVersionNotAllowedPerLicense: 60,
    MalformedServerResponse: 61,
    AuthorizationFailureOrTimeout: 66,
    InternalError: 68,
    LsClientTypeNotAllowedPerLicense: 71

}

const ec = LightstreamerErrorCodes
const recoverableErrors = new Set([
    ec.ConnectionRefused,
    ec.NewSessionTemporarilyBlocked,
    ec.ServerAffinityFailure,
    ec.UnexpectedError1,
    ec.UnexpectedError2,
    ec.MalformedServerResponse,
    ec.AuthorizationFailureOrTimeout,
    ec.InternalError
])
export const LsErrorIsRecoverable = (errorCode: number) => (errorCode < 0) || recoverableErrors.has(errorCode)
