export const DEBUG_JWT_KEY = 'JWT_KEY_DEBUG_ONLY_NOT_FOR_PRODUCTION';
export const DEBUG_JWT_NAME = 'JWT_NAME_DEBUG_ONLY_NOT_FOR_PRODUCTION';

// Position display and sorting option storage
export const POSITION_DISPLAY = 'POSITION_DISPLAY';
export const POSITION_SORT = 'POSITION_SORT';

// Holdings Table Filters
export const StorageKeys = {
    HOLDINGS_TABLE_SORT_FIELD: 'HOLDINGS_SORT_FIELD',
    HOLDINGS_TABLE_SORT_DIRECTION: 'HOLDINGS_SORT_DIR',
    HOLDINGS_TABLE_FILTER_ACCOUNT: 'HOLDINGS_ACCOUNT',
    HOLDINGS_TABLE_FILTER_PRODUCT_TYPE: 'HOLDINGS_PRODUCT_TYPE',
    HOLDINGS_TABLE_FILTER_PRODUCT_INDUSTRY: 'HOLDINGS_PRODUCT_IND',

    POSITIONS_PREV_LENGTH: 'POSITIONS_PREV_LENGTH',

    TopMoversList: 'TOP_MOVERS_LIST',
    TOP_MOVERS_COLLAPSED: 'TOP_MOVERS_COLLAPSED',

    FuturesTopMoversList: 'FUTURES_TOP_MOVERS_LIST',
    FUTURES_TOP_MOVERS_COLLAPSED: 'FUTURES_TOP_MOVERS_COLLAPSED',

    ADVANCED_CHART_TEMPLATES: 'ADVANCED_CHART_TEMPLATES',
    ADVANCED_CHART_STUDIES: 'ADVANCED_CHART_STUDIES',

    // Deep Analysis MillBill Expand
    ExpandAdvancedStats: 'ADV_STATS_EXPAND',

    // Version display
    DisplayVersion: 'DISP_VERSION_NUMBER',

    // Last time that the user was not authenticated when the started the app
    LastAuthBounce: 'LAST_AUTH_BOUNCE',

    // Whether the user wants to view candles or line
    BigChartDisplayType: 'BIG_CHART_TYPE',
    BigChartShowVolume: 'BIG_CHART_VOL',
    BigChartShowWorkingOrders: 'CHART_WORKING_ORDERS',

    // Expand futures expirations
    ExpandFuturesExpirations: 'FUTURES_EXP_SHOW',

    HideAmexOfferModal: 'HIDE_AMEX_OFFER_MODAL',

    AppTheme: 'APP_THEME',
    AppThemePreference: 'APP_THEME_PREFERENCE',

    // Watchlist Market Cap filter
    WATCHLIST_FILTER_MARKET_CAP: 'WATCHLIST_FILTER_MARKET_CAP',

    DomSecurityId: 'SECURITY_ID',
    DomQuantity: 'DOM_QUANTITY',
    ShowDom: 'SHOW_DOM',
    ShowMultiLeg: 'SHOW_MULTI_LEG',

    MessagesLastViewedTime: 'MSG_LAST_VIEWED_TS',

    JwtMemo: '_USR_JWT',

    MobileTourSecurityScreenViewed: 'MOBILE_SEC_SCREEN_VIEWED',
    MobileTourSummaryScreenViewed: 'MOBILE_SUMMARY_SCREEN_VIEWED',

    MobileJwt: 'MOBILE_JWT',
    MobileRefreshToken: 'MOBILE_REFRESH',
    MobileLoginMethod: 'MOBILE_LOGIN_METHOD',
    MobileTours: 'MOBILE_TOURS',
    MobileEnvOverride: 'MOBILE_ENV_OVERRIDE',
    MobileUserCreds: 'MOBILE_USER_CREDENTIALS',
    MobileUserIgnoreBiometric: (uname: string) => `MOBILE_USER_IGNORE_BIOMETRIC_${uname?.toLowerCase().replace(/[^A-Za-z0-9]/g, '_')}`,
    MobilePreferredOpenOrderDisplay: 'MOBILE_SHOW_ALL_OPEN_ORDERS',
    MobileJustLoggedOut: 'MOBILE_JUST_LOGGED_OUT',
    MobileRecentSearches: 'MOBILE_RECENT_SEARCHES',
    MobileFuturesPreferredTimeZone: 'MOBILE_FUTURES_TIME_ZONE',
    MobileVulcanToken: 'MOBILE_VULCAN_TOKEN',
    MobileDebugAlwaysReauth: '__DEBUG_MOBILE_ALWAYS_REAUTH',
    MobileDownloadDirectoryUri: 'MOBILE_DOWNLOAD_DIRECTORY_URI',

    ShowFPLBanner: 'SHOW_FPL_BANNER',

    OPTIONS_STRIKE_SORT_DIRECTION: 'OPTIONS_STRIKE_SORT_DIRECTION'
};
