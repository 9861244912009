import React from 'react';
import { useText } from 'phoenix/hooks/UseText';

export const LineChartButton = ({ height = 25 }: {height?: number}) => {
    const text = useText(s => s.securityScreen)
    return (
        <svg
            height={`${height}px`}
            version='1.1'
            viewBox='0 0 117 69'
            width='30px'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <title>{ text.lineChart }</title>
            <g
                fill='none'
                fillRule='evenodd'
                id='Page-1'
                stroke='none'
                strokeWidth='1'>
                <g
                    id='investing-finance-icons'
                    stroke='#4687F7'
                    strokeWidth='10'
                    transform='translate(-1527.000000, -1146.000000)'>
                    <g
                        id='presentation'
                        transform='translate(1465.000000, 1075.000000)'>
                        <g
                            id='graph'
                            transform='translate(70.000000, 78.000000)'>
                            <rect
                                height='48'
                                id='Rectangle-Copy-4'
                                rx='0.5'
                                transform='translate(17.353553, 37.353553) rotate(45.000000) translate(-17.353553, -37.353553) '
                                width='1'
                                x='16.8535534'
                                y='13.3535534'></rect>
                            <rect
                                height='33'
                                id='Rectangle-Copy-6'
                                rx='0.5'
                                transform='translate(45.792893, 32.207107) rotate(135.000000) translate(-45.792893, -32.207107) '
                                width='1'
                                x='45.2928932'
                                y='15.7071068'></rect>
                            <rect
                                height='60'
                                id='Rectangle-Copy-5'
                                rx='0.5'
                                transform='translate(80.353553, 22.353553) rotate(45.000000) translate(-80.353553, -22.353553) '
                                width='1'
                                x='79.8535534'
                                y='-7.64644661'></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
