// @ts-strict-ignore
import { DynamicModalSize } from 'components/Modals/DynamicModal'
import { EarningsCallTranscript } from '../models/Securities/EarningsCallTranscript'
import { Actions } from './Constants'

export const ShowMiniplayerAction = () => ({ type: Actions.Miniplayer.Show })
export const HideMiniplayerAction = () => ({ type: Actions.Miniplayer.Hide })
export const CloseMiniplayerAction = () => ({ type: Actions.Miniplayer.Close })
export const PauseMiniplayerAction = () => ({ type: Actions.Miniplayer.Pause })
export const ResumeMiniplayerAction = () => ({ type: Actions.Miniplayer.Resume })

export const SetMiniplayerMediaAction = (mediaUrl: string, metadata: Partial<{
    title: string,
    subtitle: string,
    symbol: string,
    date: Date,
    transcript: EarningsCallTranscript,
    id: any
}> = null, autoplay = false) => ({ type: Actions.Miniplayer.SetMedia, data: { url: mediaUrl, autoplay, ...metadata } })

export const SetMiniplayerSizeAction = (data: DynamicModalSize) => ({ type: Actions.Miniplayer.Size, data })

export const SetMiniplayerLoadingAction = (loading = true) => ({ type: Actions.Miniplayer.Loading, data: loading })
