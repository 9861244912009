// @ts-strict-ignore
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomNumberInput, Flex } from '..';
import { useColors } from '../../hooks/UseColors';
import { HitDebounce } from '../../phoenix/util/DebounceManager';
import { FilterableSecurity } from './FilterableSecurity';
import { FilterableSecuritiesFilter } from './FilterableSecurityProps';
import uuid from 'uuid-random';
import { FilterCalculations } from './FilterableSecurityTable';

highchartsAccessibility(Highcharts);

export const FilterableSecurityPriceRangeSelector = React.memo(
    (props: { securities: FilterableSecurity[]; filters: FilterableSecuritiesFilter; onFilterUpdate: (filters: Partial<FilterableSecuritiesFilter>) => any }) => {
        const BUCKET_INCREMENT = 15;
        const MAX_PRICE = 999999;
        const colors = useColors();
        const currentUpperLimit = props.filters?.maxPrice || MAX_PRICE;
        const priceBuckets = Array(Math.floor(currentUpperLimit / BUCKET_INCREMENT))
            .fill(undefined)
            .map((_, key) => key * BUCKET_INCREMENT);
        const [key, setKey] = useState<any>(uuid());

        priceBuckets.push(currentUpperLimit);

        useEffect(() => {
            if (props.filters && !props.filters.minPrice && !props.filters.maxPrice) {
                setKey(uuid());
            }
        }, [props.filters.maxPrice, props.filters.minPrice]);

        const securityList = useMemo(() => {
            if (!props.securities?.length) return [];
            return props.securities?.filter((s) => {
                if (
                    (props.filters.maxPrice ? FilterCalculations.maxPrice(s.price, props.filters.maxPrice) : true) &&
                    (props.filters.minPrice ? FilterCalculations.minPrice(s.price, props.filters.minPrice) : true)
                ) {
                    return true;
                } else return false;
            });
        }, [props.filters, props.securities]);

        const dataSet = priceBuckets.map((bucket, bucketIdx) =>
            securityList.reduce((f, c) => {
                const isHighEnough = c.price >= bucket;
                const isLowEnough = priceBuckets[bucketIdx + 1] ? c.price < priceBuckets[bucketIdx + 1] : true;
                return isHighEnough && isLowEnough ? f + 1 : f;
            }, 0)
        );

        const handleInputChange = async (filterKey: string, value: number) => {
            if (filterKey === 'maxPrice' && value === MAX_PRICE) value = undefined;
            await HitDebounce(
                filterKey,
                { filterKey, value },
                (combined: { filterKey: string; value: any }[]) => {
                    const latest = combined[combined.length - 1];
                    props.onFilterUpdate({ ...props.filters, [latest.filterKey]: latest.value });
                },
                1000
            );
        };

        const blueColor = colors.primaryItemColor;
        const options = {
            credits: {
                enabled: false
            },
            colors: [blueColor],
            chart: {
                backgroundColor: colors.cardBackgroundColor,
                type: 'areaspline',
                height: 120,
                style: {
                    fontFamily: 'Lato'
                }
            },
            title: {
                enabled: false,
                text: null
            },
            xAxis: {
                lineColor: blueColor,
                lineWidth: 2,
                gridLineColor: 'transparent',
                gridLineDashStyle: 'shortdash',
                categories: priceBuckets,
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                title: false,
                opposite: false,
                showFirstLabel: false,
                gridLineColor: 'transparent',
                gridLineDashStyle: 'shortdash',
                showLastLabel: false,
                labels: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    // step: 'center',
                    marker: {
                        enabled: false
                    },
                    states: {
                        hover: { enabled: false }
                    },
                    pointPadding: 0.2,
                    borderWidth: 0,
                    borderRadius: 0,
                    enableMouseTracking: false,
                    lineWidth: 0
                },
                series: {
                    groupPadding: 0,
                    animation: false
                },
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    borderRadius: 0
                }
            },
            series: [
                {
                    name: 'Price Range (USD)',
                    data: dataSet,
                    dataLabels: {
                        enabled: false,
                        color: blueColor
                    }
                }
            ]
        };

        return (
            <Flex key={key} column style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <Flex row justify='space-between' style={{ width: '100%' }}>
                    <div style={{ width: 100 }}>
                        <CustomNumberInput
                            label={'min'}
                            InputLabelProps={{ shrink: true }}
                            sx={{fontSize: 16}}
                            initialValue={props.filters?.minPrice}
                            max={MAX_PRICE}
                            showDollarSign
                            onDollarValueChange={(value) => handleInputChange('minPrice', value)}
                            styles={{ textAlign: 'left', fontSize: 16, padding: '5px' }}
                        />
                    </div>
                    <div style={{ width: 100 }}>
                        <CustomNumberInput
                            label={'max'}
                            InputLabelProps={{ shrink: true }}
                            initialValue={props.filters?.maxPrice}
                            max={MAX_PRICE}
                            maxIsInfinite
                            showDollarSign
                            onDollarValueChange={(value) => handleInputChange('maxPrice', value)}
                            styles={{ textAlign: 'left', fontSize: 16, padding: '5px' }}
                        />
                    </div>
                </Flex>
            </Flex>
        );
    }
);
