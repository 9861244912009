import { getUnixTime } from 'date-fns';
import { useColors } from 'hooks/UseColors';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { isUTCTimestamp, LineWidth } from 'lightweight-charts';
import { ChartRange, DetermineChartKey, StorageKeys } from 'phoenix/constants';
import { SnexChartPoint, SnexChartTimezone, SnexChartTimezoneType } from 'phoenix/constants/ChartTypes';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecuritySymbolChart } from 'phoenix/redux/actions';
import { SecurityChartData } from 'phoenix/redux/models';
import { QuoteAttribute, UniqueBy } from 'phoenix/util';
import { XS } from 'phoenix/xstream/XS';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMultiSeriesConfig } from '../Chart/helpers';
import MultiSeriesChart, { CrosshairUpdateValueWithChange } from '../Chart/MultiSeriesChart';
import { SeriesConfig } from '../Chart/SeriesConfig';
import { getVisibleLogicalRangeOffset } from './Helpers';

interface SecurityChartWrapperInterface {
    bypassBatching?: boolean;
    canScrub?: boolean;
    canToggleSeries?: boolean;
    changeOverride?: number;
    chartLineWidth?: LineWidth;
    chartResolution?: 'hi' | 'lo';
    containerId: string;
    crosshairType?: 'none' | 'vertical' | 'marker';
    filterToMarketOpen?: boolean;
    getCached?: boolean;
    height?: number;
    isDebugging?: boolean;
    lineColor?: string;
    lineColorLighter?: string;
    onCrosshairUpdate?: (value?: CrosshairUpdateValueWithChange | undefined, isScrubbing?: boolean, percChange?: number, valueChange?: number) => void;
    percentChangeOverride?: number;
    range?: ChartRange;
    seriesType?: 'candle' | 'area' | 'line';
    showNoDataLabel?: boolean;
    showWorkingOrders?: boolean;
    symbol: string;
    width?: number;
    withSnapshot?: boolean;
}

export type CrosshairUpdateObject = {
    open?: number;
    high?: number;
    low?: number;
    close?: number;
    volume?: number;
    latestPrice?: number;
    chartPercChange?: number;
    chartValChange?: number;
    value?: number;
};

export type SeriesList = { [key: string]: SeriesConfig };

export const SecurityChartWrapper = React.memo(function SecurityChartWrapperUnmemoized(props: SecurityChartWrapperInterface) {
    const {
        bypassBatching = false,
        canScrub = false,
        canToggleSeries = false,
        chartLineWidth,
        chartResolution = 'hi',
        containerId,
        crosshairType = 'none',
        height = 350,
        lineColor,
        lineColorLighter,
        onCrosshairUpdate = () => undefined,
        range: rangeProp = '1d',
        seriesType = 'line',
        showNoDataLabel = false,
        showWorkingOrders = false,
        symbol,
        width = 970
    } = props;
    const range = DetermineChartKey(rangeProp, chartResolution);
    const dispatch = useDispatch();
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState<SecurityChartData[]>([]);
    const [streamingChartData, setStreamingChartData] = useState<SecurityChartData[]>([]);
    const chartAllRanges = useSnexStore((state) => state.securityChart.bySymbol[symbol]);
    const chart = useMemo(() => chartAllRanges?.[range], [chartAllRanges, range]);
    const xStreamQuote: StandardQuote = XS.Quotes.use(symbol);
    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol]);
    const streamingData: SecurityChartData[] = XS.SecurityCharts.use(symbol);
    const metadata = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);
    const assetClass = useAssetClass(symbol);
    const { formatPrice } = assetClass;
    const rangeIsOneDay = ['1d', '24h'].includes(range);
    const colors = useColors();
    const [showVolume] = useLocalStorage<boolean>(StorageKeys.BigChartShowVolume, true);
    const formatValue = useCallback((value: number) => formatPrice(value, metadata?.data), [formatPrice, metadata?.data]);

    const wrapperCrosshairUpdate = useCallback(
        (value?: CrosshairUpdateValueWithChange, isScrubbing?: boolean) => {
            if (!value) {
                onCrosshairUpdate(value, isScrubbing);
                return;
            }
            if ((!Object.entries(value)?.length && !isLoading) || !isScrubbing) {
                const newValue = Object.entries(value).reduce(
                    (f, v) => ({
                        ...f,
                        [v[0]]: { value: v?.[1]?.value, chartPercChange: v?.[1]?.chartPercChange || null, chartValChange: v?.[1]?.chartValChange || null }
                    }),
                    {}
                );
                onCrosshairUpdate(newValue as CrosshairUpdateValueWithChange, isScrubbing);
            } else {
                onCrosshairUpdate(value, isScrubbing);
            }
        },
        [isLoading, onCrosshairUpdate]
    );

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, [marketTimeSegment]);

    useEffect(() => {
        // Prevent multiple fetches if this hook fires more often than it should
        if (
            !isLoading &&
            !chart?.data?.length &&
            chart?.pristine !== false && // Can be undefined and require a fetch
            symbol
        ) {
            setIsLoading(true);
            const fetchChartDataAsync = async () => {
                dispatch(GetSecuritySymbolChart(symbol, rangeProp, chartResolution, bypassBatching));
            };
            fetchChartDataAsync();
        }
    }, [bypassBatching, chart, chartResolution, dispatch, isLoading, range, rangeProp, symbol]);

    useEffect(() => {
        if (chart?.data?.length && !apiQuote?.pristine) {
            let orderedData = [...chart.data];

            orderedData = orderedData
                .sort((a, b) => Date.parse(QuoteAttribute.getTime(b)) - Date.parse(QuoteAttribute.getTime(a)))
                .filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)) && QuoteAttribute.getPrice(d));
            if (orderedData.length) {
                const newChartData: SecurityChartData[] = orderedData.map((d) => ({
                    time: getUnixTime(new Date(QuoteAttribute.getTime(d))),
                    value: QuoteAttribute.getPrice(d) as number,
                    ...d
                }));
                if (rangeIsOneDay && newChartData?.length) {
                    if (newChartData[newChartData.length - 1] && apiQuote?.data?.previousClose) {
                        newChartData[newChartData.length - 1].value = apiQuote?.data?.previousClose;
                    }
                }
                setIsLoading(false);
                const unique = UniqueBy(newChartData, (item: SecurityChartData) => item.time as number);
                setChartData(unique);
            }
        } else if (!chart?.data?.length && (chart?.error || !chart?.loading) && (!chart?.pristine || !!chart?.data)) {
            setChartData([]);
            setIsLoading(false);
        }
    }, [apiQuote, chart, range, rangeIsOneDay, symbol, seriesType]);

    useEffect(() => {
        const orderedData =
            streamingData
                ?.sort((a, b) => Date.parse(QuoteAttribute.getTime(b)) - Date.parse(QuoteAttribute.getTime(a)))
                .filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)) && QuoteAttribute.getPrice(d)) || [];
        if (orderedData.length) {
            const newChartData = UniqueBy(
                orderedData.map((d) => ({ time: getUnixTime(new Date(QuoteAttribute.getTime(d))), value: QuoteAttribute.getPrice(d) as number, ...d })),
                (item: SecurityChartData) => item.time as number
            );
            if (!chartData.length && rangeIsOneDay && !chart?.data?.length) setChartData(newChartData);
            setStreamingChartData(newChartData);
        }
    }, [chart?.data?.length, chartData.length, rangeIsOneDay, streamingData]);

    // Don't show streaming data for any range besides 1D/24H
    const dataForRange = useMemo(() => (rangeIsOneDay ? [...chartData, ...streamingChartData] : chartData), [chartData, rangeIsOneDay, streamingChartData]);

    // Sometimes streaming data is stored before the rest API returns
    // Only generate series data if the rest request has completed
    const multiSeriesData = useMemo(
        () => (chart?.pristine || chart?.loading ? undefined : (dataForRange as SnexChartPoint[])),
        [chart?.loading, chart?.pristine, dataForRange]
    );

    const segmentScale = useMemo(() => {
        switch (range) {
            case '24h':
            case '1w':
            case '5d':
                return 'day';
            case '1d':
                return assetClass.family === 'equities' ? 'equities-day' : 'day';
            case '1m':
                return 'week';
            case '3m':
            case '6m':
            case '1y':
                return 'month';
            case '5y':
            default:
                return 'year';
        }
    }, [assetClass.family, range]);

    const timezone = (assetClass.family === 'futures' ? SnexChartTimezone.cdt : SnexChartTimezone.edt) as SnexChartTimezoneType;

    const multiSeries = useMemo(
        () =>
            getMultiSeriesConfig({
                colors,
                data: multiSeriesData as SnexChartPoint[],
                formatPrice: formatValue,
                id: symbol,
                ...(lineColor ? { lineColor, lineColorLighter } : {}),
                segmentScale,
                seriesType,
                showVolume,
                timezone,
                withHighLowMarkers: range !== 'loRes'
            }),
        [colors, formatValue, lineColor, lineColorLighter, multiSeriesData, range, segmentScale, seriesType, showVolume, symbol, timezone]
    );

    useEffect(() => {
        if (!chartData?.length && !streamingData?.length && !chart?.loading && !chart?.pristine) onCrosshairUpdate(undefined);
    }, [xStreamQuote, range, chartData?.length, chart, streamingData?.length, onCrosshairUpdate]);

    const expectedNumberOfPoints = getVisibleLogicalRangeOffset(multiSeriesData?.length);

    const logicalRangeOverride =
        chartResolution === 'lo'
            ? 80 // Fixed size for lo-res charts
            : assetClass.family === 'equities' &&
              range === '1d' &&
              // This override exists to make the chart appear as if it's moving right throughout the day
              // It is only relevant when the market is open
              // For premarket this app shows yesterday's chart so that segment is excluded
              ['open', 'postmarket'].includes(marketTimeSegment)
            ? expectedNumberOfPoints
            : // When undefined, the chart will figure out the logical range on its own to display all the data
              undefined;

    return (
        <div id={containerId}>
            <MultiSeriesChart
                canScale={seriesType === 'candle'}
                canScrub={canScrub}
                canToggleSeries={canToggleSeries}
                chartLineWidth={chartLineWidth}
                crosshairType={crosshairType}
                height={height}
                hidePriceLine={range === 'loRes'}
                isLoading={isLoading}
                logicalRangeOverride={logicalRangeOverride}
                multiSeries={multiSeries}
                onCrosshairUpdate={wrapperCrosshairUpdate}
                range={props.range}
                showNoDataLabel={showNoDataLabel}
                showWorkingOrders={showWorkingOrders}
                showVolume={showVolume}
                width={width}
            />
        </div>
    );
});
