import { ApiData } from '../../../models'
import { WatchlistChart } from './WatchlistChart'
import { WatchlistChartPoint } from './WatchlistChartPoint'

export class WatchlistChartState {
    restById: {
        [id: string]: ApiData<WatchlistChart>
    }

    constructor () {
        this.restById = {}
    }
}
