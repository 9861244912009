// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { SystemState } from '../models/System/SystemState'
import { SystemStatus } from '../models/System/SystemStatus'
import { TermsDocument } from '../models/System/TermsDocument'

const permitted = GroupNameChecker([Actions.System])
export const SystemReducer = (state: SystemState = new SystemState(), action: ReduxAction): SystemState => {
    if (!permitted(action)) return state

    const withTermsDocUpdate = (update: (data: ApiData<TermsDocument>) => ApiData<TermsDocument>): SystemState => {
        const newByName = { ...state.termsDocuments.byName, [action.subject]: update(state.termsDocuments.byName[action.subject] || new ApiData()) }
        return { ...state, termsDocuments: { ...state.termsDocuments, byName: newByName } }
    }

    switch (action.type) {
        case Actions.System.GetLanguages.Loading: return { ...state, languages: state.languages.startLoading(state.languages.data) }
        case Actions.System.GetLanguages.Success: return { ...state, languages: state.languages.succeeded(action.data) }
        case Actions.System.GetLanguages.Failure: return { ...state, languages: state.languages.failed(action.error) }

        case Actions.System.GetTimeZones.Loading: return { ...state, timezones: state.timezones.startLoading(state.timezones.data) }
        case Actions.System.GetTimeZones.Success: return { ...state, timezones: state.timezones.succeeded(action.data) }
        case Actions.System.GetTimeZones.Failure: return { ...state, timezones: state.timezones.failed(action.error) }

        case Actions.System.GetStatus.Loading: return { ...state, status: state.status.startLoading(state.status.data) }
        case Actions.System.GetStatus.Success: {
            const status = (action.data as SystemStatus[]).map(x => {
                const casted = { ...x, asOf: new Date(x.asOf), lastAvailable: new Date(x.lastAvailable) }
                // For testing
                // if (x.id === 'functionality/trading' || x.id === 'functionality/funding') return { ...casted, status: 'unavailable' }
                return casted
            })
            return { ...state, status: state.status.succeeded(status) }
        }
        case Actions.System.GetStatus.Failure: return { ...state, status: state.status.failed(action.error) }

        case Actions.System.GetTermsDocument.Loading: return withTermsDocUpdate(d => d.startLoading())
        case Actions.System.GetTermsDocument.Success: return withTermsDocUpdate(d => d.succeeded(action.data))
        case Actions.System.GetTermsDocument.Failure: return withTermsDocUpdate(d => d.failed(action.error))

        case Actions.System.SetTitleSecurity: return { ...state, titleSecurity: action.data }

        default: return state
    }
}
