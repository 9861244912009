import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { ApiTimeInForce } from 'phoenix/models/ApiTradeRequest';
import { TradeableSecurityType } from 'phoenix/util/IsMutualFund';

type MessagesByAsset = {
    [key in TradeableSecurityType]?: {
        [key in string]?: (l: Snex1LanguagePack) => string | undefined;
    };
};

// Interface for storing not-allowed messages
type NotAllowedMessages = {
    editMessages: MessagesByAsset;
    cancelMessages: MessagesByAsset;
};

const notAllowedMessages: NotAllowedMessages = {
    editMessages: {
        equity: {
            Day: (t) => t.tradeTicket.modifyEquitiesDayOrdersHours,
            GTC: (t) => t.tradeTicket.modifyEquitiesGtcOrdersHours
        },
        option: {
            Day: (t) => t.tradeTicket.modifyEquitiesOptionsDayOrdersHours,
            GTC: (t) => t.tradeTicket.modifyEquitiesOptionsGtcOrdersHours
        },
        // Add other asset classes and time-in-force messages as needed
        future: {}
    },
    cancelMessages: {
        equity: {
            Day: (t) => t.tradeTicket.cancelEquityOrdersHours,
            GTC: (t) => t.tradeTicket.cancelEquityOrdersHours
        },
        option: {
            Day: (t) => t.tradeTicket.cancelEquityOptionsOrdersHours,
            GTC: (t) => t.tradeTicket.cancelEquityOptionsOrdersHours
        },
        // Add other asset classes and time-in-force messages as needed
        future: {}
    }
};

// Helper function to get the not-allowed message for a given asset class and time in force
const getNotAllowedMessage = (messages: MessagesByAsset, secType: TradeableSecurityType, timeInForce: ApiTimeInForce, langPack: Snex1LanguagePack): string | null => {
    const assetMessages = messages[secType];
    if (assetMessages) {
        const message = assetMessages[timeInForce];
        if (message) {
            return message(langPack) || null;
        }
    }
    return null;
};

// Function to get the not-allowed edit message if there is one otherwise returns null
export const GetNotAllowedEditMessage = (secType: TradeableSecurityType, timeInForce: ApiTimeInForce, langPack: Snex1LanguagePack): string | null => {
    return getNotAllowedMessage(notAllowedMessages.editMessages, secType, timeInForce, langPack);
};

// Function to get the not-allowed cancel message
export const GetNotAllowedCancelMessage = (secType: TradeableSecurityType, timeInForce: ApiTimeInForce, langPack: Snex1LanguagePack): string | null => {
    return getNotAllowedMessage(notAllowedMessages.cancelMessages, secType, timeInForce, langPack);
};
