// @ts-strict-ignore
import { EarningsCallTranscript } from 'phoenix/redux/models'
import { ApiData } from '../../models'
import { ReduxAction } from '../../models/ReduxAction'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { EarningsCallsState } from '../models/EarningsCalls/EarningsCallsState'

const permitted = GroupNameChecker([Actions.Securities.EarningsCalls])
export const EarningsCallsReducer = (state: EarningsCallsState = new EarningsCallsState(), action: ReduxAction): EarningsCallsState => {
    if (!permitted(action)) return state

    const withUpdatedIndex = (update: (data: ApiData<any>) => ApiData<any>) => ({ ...state.indexBySymbol, [action.subject]: update(state.indexBySymbol[action.subject] || new ApiData()) })
    const withUpdatedDetails = (update: (data: ApiData<any>) => ApiData<any>) => ({ ...state.detailsById, [action.subject.symbol]: update(state.detailsById[action.subject] || new ApiData()) })
    const withUpdatedCallDetails = (update: (data: ApiData<any>) => ApiData<any>) => {
        return { ...state.detailsByCallId, [ action.subject.callId ]: update(state.detailsByCallId[action.subject.callId] || new ApiData()) }
    }

    switch (action.type) {
        case Actions.Securities.EarningsCalls.Index.Loading: return { ...state, indexBySymbol: withUpdatedIndex(u => u.startLoading(u.data)) }
        case Actions.Securities.EarningsCalls.Index.Success: return { ...state, indexBySymbol: withUpdatedIndex(u => u.succeeded(action.data)) }
        case Actions.Securities.EarningsCalls.Index.Failure: return { ...state, indexBySymbol: withUpdatedIndex(u => u.failed(action.error)) }

        case Actions.Securities.EarningsCalls.Next.Loading: return { ...state, nextBySymbol: withUpdatedIndex(u => u.startLoading(u.data)) }
        case Actions.Securities.EarningsCalls.Next.Success: return { ...state, nextBySymbol: withUpdatedIndex(u => u.succeeded(action.data)) }
        case Actions.Securities.EarningsCalls.Next.Failure: return { ...state, nextBySymbol: withUpdatedIndex(u => u.failed(action.error)) }

        case Actions.Securities.EarningsCalls.Details.Loading: return {
            ...state,
            detailsById: withUpdatedDetails(u => u.startLoading(u.data)),
            detailsByCallId: withUpdatedCallDetails(u => u.startLoading(u.data))
        }
        case Actions.Securities.EarningsCalls.Details.Success: return {
            ...state,
            detailsById: withUpdatedDetails(u => u.succeeded(action.data)),
            detailsByCallId: withUpdatedCallDetails(u => u.succeeded(action.data))
        }
        case Actions.Securities.EarningsCalls.Details.Failure: return {
            ...state,
            detailsById: withUpdatedDetails(u => u.failed(action.error)),
            detailsByCallId: withUpdatedCallDetails(u => u.failed(action.error))
        }


        default: return state
    }
}
