import { FeatureFlags } from "phoenix/constants/FeatureFlags";
import { useFeatureFlag } from "./UseFeatureFlag";
import { GlobalState } from "phoenix/redux/GlobalState";
import { useSelector } from "react-redux";

export const UseTickerTape = (): boolean => {
    const tickerTapeFeatureFlag = useFeatureFlag(FeatureFlags.Web.TickerTape);
    const userInfo = useSelector((s: GlobalState) => s.user.myInfo);
    const hasUserEnabledTickerTape = userInfo?.data?.showTradingViewTicker === true
    return !!tickerTapeFeatureFlag && !!hasUserEnabledTickerTape
}