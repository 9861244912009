import { FromEnglish, Ts } from 'phoenix/assets/lang/T';
import { OptionSymbol } from '../redux/models';
import { SecurityMetadata } from '../redux/models/Securities/SecurityMetadata';
import { FormatString } from '../util/FormatString';
import { QualifiedId } from '../util/QualifiedId';
import { IsOffshoreMutualFundByMetadata } from 'phoenix/util';

export type SecurityType =
    | 'Cash & Equivalents'
    | 'Cryptos'
    | 'Equities'
    | 'Fixed Income'
    | 'Futures'
    | 'Futures Options'
    | 'Mutual Funds'
    | 'Options'
    | 'Rights and Warrants';

// Define a type for the keys of SecurityTypes
export type SecurityTypeKeys =
    | 'mutualFunds'
    | 'equities'
    | 'options'
    | 'cash'
    | 'cs'
    | 'oef'
    | 'cef'
    | 'ad'
    | 'et'
    | 'ps'
    | 'rt'
    | 'struct'
    | 'ut'
    | 'wi'
    | 'wt'
    | ''
    | 'Futures'
    | 'FuturesOptions';

export const SecurityTypes: Record<SecurityTypeKeys, SecurityType | string> = {
    mutualFunds: 'Mutual Funds',
    equities: 'Equities',
    options: 'Options',
    cash: 'Cash & Equivalents',
    cs: 'Common Stock',
    oef: 'Mutual Fund',
    cef: 'Mutual Fund',
    ad: 'ADR',
    et: 'ETF',
    ps: 'Preferred Stock',
    rt: 'Right',
    struct: 'Structured Product',
    ut: 'Unit',
    wi: 'When Issued',
    wt: 'Warrant',
    '': 'Miscellaneous',
    Futures: 'Futures',
    FuturesOptions: 'Futures Options'
};

// Define the SecurityTypeEnum for stable identifiers
export enum SecurityTypeEnum {
    OTC_OPTION = 'OtcOption',
    MUTUAL_FUND_CLOSED = 'MutualFundClosed',
    MUTUAL_FUND_OPEN = 'MutualFundOpen',
    OIL_GAS_REIT = 'OilGasReit',
    ESCROW_RECEIPTS_LETTER_GUARANTY = 'EscrowReceiptsLetterGuaranty',
    TREASURIES = 'Treasuries',
    MUNICIPAL_BONDS = 'MunicipleBonds',
    CORPORATE_UIT = 'CorporateUit',
    MUNICIPAL_UIT = 'MunicipalUit',
    UNITS = 'Units',
    PRECIOUS_METALS = 'PreciousMetals',
    PREFERRED_STOCK = 'PreferredStock',
    LISTED_OPTION = 'ListedOption',
    COMMON_STOCK = 'CommonStock',
    CURRENCY = 'Currency',
    WARRANT = 'Warrant',
    FUTURE = 'Future',
    INDEX = 'Index',
    ANNUITY = 'Annuity',
    UNKNOWN = 'Unknown',
    MISCELLANEOUS = 'Miscellaneous',
    OPTION = 'option'
}

export const AlgoliaTypesThatCannotLookAtDeepAnalysis = new Set(['et', 'crypto', 'future', 'oef', 'of']);
export const AlgoliaTypesThatCannotLookAtOptions = new Set(['crypto', 'oef', 'of']);

// These are also from IEX
export const ViewableSecurityTypes = ['cs', 'oef', 'cef', 'ad'];

export const TranslatePositionType = (typeFromApi: string): string => {
    return FromEnglish(
        (s) => s.portfolioScreen.portfolioBreakdown,
        Object.entries(formattedSecurityTypeLabels).find((e) => e[0] === typeFromApi)?.[1] || typeFromApi,
        'value'
    );
};

// https://dev.azure.com/intlanalytics/Vulcan/_git/SecurityMasterService?path=/src/SecurityMasterService.Sdk/Models/Enumerations.cs&version=GBmaster&line=27&lineEnd=28&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
/** @deprecated For web, these values were originally designed to be human-readable in the times before we supported multiple languages. The values from this function are not suitable to code against. */
// Mobile currently uses to display the type of security in its title block.
export const GetSecTypeNameFromMetadata = (meta: SecurityMetadata): string => {
    const text = Ts((s) => s.assetClass);

    if (!meta || !meta?.securityType) return text.miscellaneous;

    const isOffshore = IsOffshoreMutualFundByMetadata(meta);
    const sort = QualifiedId.Class(meta.symbol);

    if (sort === 'crypto') return text.cryptocurrency;
    else if (sort === 'futures') return (meta.securityType === SecurityTypeEnum.OPTION) ? text.futuresOptions : text.future;
    else if (OptionSymbol.IsOptionSymbol(meta.symbol)) return text.equitiesOption;
    else if (isOffshore) return text.offShoreMutualFund;

    // Use SecurityTypeEnum in the switch statement
    switch (meta.securityType) {
        case SecurityTypeEnum.OTC_OPTION: return text.otcOption;
        case SecurityTypeEnum.MUTUAL_FUND_CLOSED: return text.etf;
        case SecurityTypeEnum.MUTUAL_FUND_OPEN: return text.mutualFund;
        case SecurityTypeEnum.OIL_GAS_REIT: return text.oilGasReit;
        case SecurityTypeEnum.ESCROW_RECEIPTS_LETTER_GUARANTY: return text.escrowReceiptsLetterGuaranty;
        case SecurityTypeEnum.TREASURIES: return text.treasury;
        case SecurityTypeEnum.MUNICIPAL_BONDS: return text.municipleBond;
        case SecurityTypeEnum.CORPORATE_UIT: return text.corporateUit;
        case SecurityTypeEnum.MUNICIPAL_UIT: return text.municipalUit;
        case SecurityTypeEnum.UNITS: return text.unit;
        case SecurityTypeEnum.PRECIOUS_METALS: return text.preciousMetals;
        case SecurityTypeEnum.PREFERRED_STOCK:
        case SecurityTypeEnum.LISTED_OPTION:
        case SecurityTypeEnum.COMMON_STOCK: return text.commonStock;
        case SecurityTypeEnum.CURRENCY:
        case SecurityTypeEnum.WARRANT:
        case SecurityTypeEnum.FUTURE:
        case SecurityTypeEnum.INDEX:
        case SecurityTypeEnum.ANNUITY: return text.annuity;
        case SecurityTypeEnum.UNKNOWN:
        case SecurityTypeEnum.MISCELLANEOUS:
        default: return SecurityTypes[meta.securityType as SecurityTypeKeys] || meta.securityType;
    }
};

export const HumanizeSecurityCode = (code: string, name: string): string => {
    // prettier-ignore
    switch (code) {
        case 'C': return 'Stock'
        case 'M': return 'Mutual Fund'
        case 'L': return 'Real Estate / Oil + Gas'
        case 'W': return 'Warrant'
        case null: return (!name || name === 'Unknown') ? 'Miscellaneous' : FormatString.ChangeCamelCaseToSentenceCase(name)
        default: return `${FormatString.ChangeCamelCaseToSentenceCase(name)} (${code})`
    }
};

export const ProductTypes = {
    mutualFunds: 'Mutual Funds',
    uits: 'UITs',
    cashAndEquivalents: 'Cash & Equivalents',
    cryptos: 'Cryptos',
    fixedIncome: 'Fixed Income',
    equities: 'Equities',
    rightsAndWarrents: 'Rights and Warrants',
    options: 'Options',
    futures: 'Futures',
    metals: 'Precious Metals'
};

export const SideBarExcludedPositionTypes = new Set(['Rights and Warrants', 'Cash & Equivalents']);

export const formattedSecurityTypeLabels: { [key: string]: string } = {
    [SecurityTypes.equities]: 'Stocks'
};