import { Link } from '@mui/material';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useOpenChatWindow } from 'hooks/UseChat';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { Environment, ResolveEnvironment, Urls } from 'phoenix/constants';
import { useSupportByVariant } from 'phoenix/constants/Support';
import { IsWhitelabel } from 'phoenix/constants/Whitelabels';
import { useText } from 'phoenix/hooks/UseText';
import { GetVariant } from 'phoenix/util/Variant';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { Flex } from '..';
import PrivacyPolicy from '../../assets/images/PrivacyPolicy.pdf';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { FormattedTextDisplay } from '../FormattedTextDisplay/FormattedTextDisplay';
import './DisclosureFooter.scss';

export interface DisclosureFooterProps {
    advancedChartScreen?: boolean;
    clientExtras?: boolean;
    earningsAndRatingsData?: boolean;
    featuresAndBalances?: boolean;
    miScreen?: boolean;
    ordersAndActivity?: boolean;
    positionsScreen?: boolean;
    projectedIncomeScreen?: boolean;
    securityPricing?: boolean;
    style?: React.CSSProperties;
    technicalAnalysisScreen?: boolean;
    white?: boolean;
    noMargin?: boolean;
}

export const Disclosures = React.memo((props: DisclosureFooterProps) => {
    const environment = ResolveEnvironment();
    return (
        <DisclosuresPresentation
            isClient={GetVariant()?.isClient}
            openChatWindow={useOpenChatWindow()}
            text={useText((t) => t)}
            isWhitelabel={IsWhitelabel()}
            environment={environment}
            {...props}
        />
    );
});

export const DisclosureFooter = TelemetryProvider(
    (props: DisclosureFooterProps) => (
        <DisclosureFooterPresentation
            isClient={GetVariant()?.isClient}
            openChatWindow={useOpenChatWindow()}
            text={useText((t) => t)}
            isWhitelabel={IsWhitelabel()}
            environment={ResolveEnvironment()}
            {...props}
        />
    ),
    TelemetryCategories.disclosures
);

export const DisclosuresPresentation = React.memo((props: DisclosureFooterPresentationProps) => {
    const { text } = props;
    const { disclosuresByEnvironement } = text.footer.global;

    return (
        <Flex column style={{ textAlign: 'justify', whiteSpace: 'normal' }} className='snex-disclosures'>
            <FormattedTextDisplay text={disclosuresByEnvironement(props.environment)} />
        </Flex>
    );
});

interface FooterLinkItemProps {
    href?: string;
    onClick?: () => void;
    noSeparator?: boolean;
    route?: string;
}
const FooterLinkItem = React.memo(({ href, onClick, route, noSeparator, children }: PropsWithChildren<FooterLinkItemProps>) => {
    return (
        <div style={{ marginRight: 10, paddingRight: 10, cursor: 'pointer', borderRight: noSeparator ? undefined : 'solid 1px rgba(0,0,0,0.4)' }}>
            {route ? (
                <Link component={ReactLink} to={route} underline='hover'>
                    {children}
                </Link>
            ) : (
                <Link href={href} target='_blank' onClick={onClick} underline='hover'>
                    {children}
                </Link>
            )}
        </div>
    );
});

export interface DisclosureFooterPresentationProps extends DisclosureFooterProps {
    isClient: boolean;
    openChatWindow: () => void;
    text: Snex1LanguagePack;
    isWhitelabel?: boolean;
    environment: Environment;
    noMargin?: boolean;
}

export const DisclosureFooterPresentation = (props: DisclosureFooterPresentationProps): ReactElement => {
    const [expanded, setExpanded] = useState(false);
    const { email, phone } = useSupportByVariant();
    const global = props.text.footer.global;

    return (
        <footer role='content-info'>
            <Flex column style={{ margin: props.noMargin ? 0 : '50px 0', ...props.style }} {...(expanded ? { onClick: () => setExpanded(!expanded) } : {})}>
                {!props?.isWhitelabel && (
                    <CulledCollapse eventTag='Standard Disclosures' in={expanded}>
                        <DisclosuresPresentation {...props} />
                    </CulledCollapse>
                )}
                <Flex align='flex-start' row wrap style={{ marginBottom: props.noMargin ? 0 : 15, whiteSpace: 'nowrap' }}>
                    {!props?.isWhitelabel && (
                        <>
                            <FooterLinkItem onClick={() => setExpanded(!expanded)}>{global.importantDisclosures}</FooterLinkItem>
                            <FooterLinkItem route={Routes.disclosures()}>{global.termsOfUse}</FooterLinkItem>
                            <FooterLinkItem href={PrivacyPolicy}>{global.privacy}</FooterLinkItem>
                            <FooterLinkItem href={email.mailto}>{email.address}</FooterLinkItem>
                            <FooterLinkItem href={phone?.href}>{phone?.label}</FooterLinkItem>
                            <FooterLinkItem onClick={() => props.openChatWindow()}>{global.chatWithSupport}</FooterLinkItem>{' '}
                        </>
                    )}
                    <FooterLinkItem noSeparator href={Urls.footers.marketData}>
                        {global.marketDataDisclosure}
                    </FooterLinkItem>
                </Flex>
            </Flex>
        </footer>
    );
};
