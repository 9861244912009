// @ts-strict-ignore
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { XstreamDomUpdateActionName } from 'phoenix/xstream/actions/XstreamDomActions';
import { XstreamThrottling } from 'phoenix/xstream/XstreamThrottling';
import { GetMarketTimeSegmentV2 } from '../hooks/useMarketTimeSegment';
import { ReduxAction } from '../models/ReduxAction';
import { OptionQuote } from '../redux/models';
import { MarketTimeSegment } from '../util';
import { XstreamAccountChartUpdateActionName, XstreamPortfolioChartUpdateActionName, XstreamResetAccountValuationActionName } from './actions/XstreamAccountActions';
import { XstreamAccountChartResetActionName, XstreamChartResetActionName, XstreamUpdateChartFrequencyInMinutes } from './actions/XstreamChartActions';
import { XstreamResetStreamingStateActionName, XstreamSetStreamingChartFrequencyActionName } from './actions/XstreamGeneralActions';
import { XstreamOptionQuoteUpdateActionName } from './actions/XstreamOptionActions';
import { XstreamQuoteUpdateActionName } from './actions/XstreamQuoteActions';
import { XstreamState } from './XstreamState';
import { XstreamUpdateAccountChart } from './XstreamUpdateAccountChart';
import { XStreamUpdateChart } from './XStreamUpdateChart';

const marketSegmentsWithoutChartStreaming = new Set<MarketTimeSegment>(['closed', 'holiday', 'loading', 'premarket']);

export const XstreamReducer = (state: XstreamState = new XstreamState(1), action: ReduxAction): XstreamState => {
    // Regular Quotes
    if (action.type === XstreamQuoteUpdateActionName) {
        if (XstreamThrottling.failsThrottle('quotes', action.subject)) return state;

        state.quotes[action.subject] = action.data;
        const currentMarketSegment = GetMarketTimeSegmentV2();
        const assetFamily = GetAssetClassForSecurity(action?.subject)?.family;
        if (assetFamily !== 'equities' || !marketSegmentsWithoutChartStreaming.has(currentMarketSegment)) {
            state.streamingCharts[action.subject] = XStreamUpdateChart(state, action);
        }
    }

    // Option Quotes
    if (action.type === XstreamOptionQuoteUpdateActionName) {
        if (XstreamThrottling.failsThrottle('optionQuote', action.subject)) return state;

        const currentMarketSegment = GetMarketTimeSegmentV2();

        (action.data as OptionQuote[]).forEach((u, i) => {
            state.options[u.symbol] = u;

            const assetFamily = GetAssetClassForSecurity(action?.subject)?.family;
            if (assetFamily !== 'equities' || !marketSegmentsWithoutChartStreaming.has(currentMarketSegment)) {
                state.streamingCharts[u.symbol] = XStreamUpdateChart(state, { ...action, data: action?.data?.[i], subject: action?.data?.[i]?.symbol });
            }
        });
    }

    // Account Valuations
    if (action.type === XstreamResetAccountValuationActionName) {
        if (action.subject) state.accountValuations[action.subject] = null;
        else state.portfolioValuation = null;
    }

    // Account Charts
    if (action.type === XstreamAccountChartResetActionName) {
        if (action.subject) state.streamingAccountCharts[action.subject?.accountNumber] = [];
        else {
            state.streamingAccountCharts = {};
            state.streamingPortfolioChart = [];
        }
    }

    if (action.type === XstreamAccountChartUpdateActionName) {
        if (XstreamThrottling.failsThrottle('accountValuations', action.subject?.accountNumber)) return state;
        
        state.accountValuations[action.subject?.accountNumber] = action.data;
        state.streamingAccountCharts[action.subject?.accountNumber] = XstreamUpdateAccountChart(state, action);
    }

    // Portfolio Charts
    if (action.type === XstreamPortfolioChartUpdateActionName) {
        if (XstreamThrottling.failsThrottle('portfolioValuations', action.subject)) return state;

        state.portfolioValuation = action.data;
        state.streamingPortfolioChart = XstreamUpdateAccountChart(state, action);
    }

    // DOM
    if (action.type === XstreamDomUpdateActionName) {
        if (XstreamThrottling.failsThrottle('domUpdates', action.subject)) return state;
        state.dom[action.subject] = action.data;
    }

    // Control stuff
    if (action.type === XstreamUpdateChartFrequencyInMinutes) state.chartFrequencyInMinutes = action.data;
    if (action.type === XstreamChartResetActionName) state.streamingCharts[action.subject] = [];
    if (action.type === XstreamResetStreamingStateActionName) state = new XstreamState(5);
    if (action.type === XstreamSetStreamingChartFrequencyActionName) state = new XstreamState(action.data);

    return { ...state };
};
