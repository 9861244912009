// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web';
import { SecurityQuote } from '../Securities/SecurityQuote';
import { OptionSymbol } from './OptionSymbol';

export class OptionQuote {
    ask?: number;
    askDate?: string;
    askSize?: number;
    askTime?: string;
    askValue?: number; // futures
    baseSymbol?: string;
    bid?: number;
    bidDate?: string;
    bidSize?: number;
    bidTime?: string;
    bidValue?: number; // futures
    change?: number;
    changePercent?: number;
    close?: number;
    currency?: string;
    date?: string;
    dayTradeMargin?: number;
    exchange?: string;
    expirationDate?: string;
    high?: number;
    initialMargin?: number;
    identity?: string;
    inTheMoney?: boolean;
    last?: number;
    lastSize?: number;
    latestPrice?: number;
    low?: number;
    maintenanceMargin?: number;
    month?: number;
    open?: number;
    openInterest?: number;
    openInterestDate?: string;
    outcome?: string;
    percentChange?: number;
    previousClose?: number;
    previousCloseDate?: string;
    price?: number; // Added to make things easier, since StandardQuote uses `price` not `last`
    strike?: number;
    strikePrice?: number;
    symbol: string;
    symbolMetadata?: OptionSymbol;
    symbologyType?: string;
    time?: string | number;
    timestamp?: number;
    type?: string;
    volume?: number;
    year?: number;
    unitFactor?: number;
    loading?: boolean;

    static FromLightsteamerUpdate(update: ItemUpdate): Partial<OptionQuote> {
        const symbol = update.getValue('symbol');
        const date = update.getValue('date');
        const time = update.getValue('time');
        const ts = new Date(`${date} ${time}`).getTime();

        return {
            ask: parseFloat(update.getValue('ask')),
            askDate: update.getValue('askDate'),
            askSize: parseFloat(update.getValue('askSize')),
            askTime: update.getValue('askTime'),
            baseSymbol: update.getValue('baseSymbol'),
            bid: parseFloat(update.getValue('bid')),
            bidDate: update.getValue('bidDate'),
            bidSize: parseFloat(update.getValue('bidSize')),
            bidTime: update.getValue('bidTime'),
            change: parseFloat(update.getValue('change')),
            close: parseFloat(update.getValue('close')),
            currency: update.getValue('currency'),
            date,
            exchange: update.getValue('exchange'),
            expirationDate: update.getValue('expirationDate'),
            high: parseFloat(update.getValue('high')),
            identity: update.getValue('identity'),
            inTheMoney: update.getValue('inTheMoney') === 'true',
            last: parseFloat(update.getValue('last')),
            price: parseFloat(update.getValue('last')),
            latestPrice: parseFloat(update.getValue('last')),
            lastSize: parseFloat(update.getValue('lastSize')),
            low: parseFloat(update.getValue('low')),
            open: parseFloat(update.getValue('open')),
            openInterest: parseFloat(update.getValue('openInterest')),
            openInterestDate: update.getValue('openInterestDate'),
            outcome: update.getValue('outcome'),
            // These values stream in as basis pts ie: 12.34 = 12.34%
            // We want them to be "base 1" or "decimal format" or .1234 = 12.34%
            changePercent: parseFloat(update.getValue('percentChange')) / 100,
            percentChange: parseFloat(update.getValue('percentChange')) / 100,
            previousClose: parseFloat(update.getValue('previousClose')),
            previousCloseDate: update.getValue('previousCloseDate'),
            strikePrice: parseFloat(update.getValue('strikePrice')),
            symbol,
            symbologyType: update.getValue('symbologyType'),
            time: ts,
            timestamp: ts,
            type: update.getValue('type'),
            volume: parseFloat(update.getValue('volume'))
        };
    }

    static FromSecurityQuote(q: SecurityQuote): OptionQuote {
        if (!q?.symbol) return null;
        const meta = new OptionSymbol(q?.symbol);
        return {
            year: meta.expDate.getFullYear(),
            month: meta.expDate.getMonth(),
            askSize: q.askSize,
            ask: q.ask,
            bidSize: q.bidSize,
            bid: q.bid,
            changePercent: q.changePercent,
            percentChange: q.changePercent,
            change: q.change,
            previousClose: q.previousClose,
            volume: q.volume,
            low: q.low,
            high: q.high,
            close: q.close,
            open: q.open,
            last: q.price,
            price: q.price,
            latestPrice: q.latestPrice,
            strikePrice: meta.strike,
            expirationDate: meta.expDate.toISOString(),
            baseSymbol: meta.underlyingSymbol,
            symbologyType: 'OSI',
            symbol: meta.osiSymbol,
            symbolMetadata: meta,
            unitFactor: q.unitFactor
        };
    }
}
