import { ItemUpdate, LightstreamerClient, Subscription, SubscriptionListener } from "lightstreamer-client-web";
import { SnexLsClient } from "phoenix/ls-shim/models/SnexLsClient";
import { SnexLsClientListener } from "phoenix/ls-shim/models/SnexLsClientListener";
import { SnexLsItemUpdate } from "phoenix/ls-shim/models/SnexLsItemUpdate";
import { SnexLsSubscription } from "phoenix/ls-shim/models/SnexLsSubscription";
import { SnexLsBackEnd } from "phoenix/ls-shim/SnexLsBackEnd";

export class WebLsBackEnd implements SnexLsBackEnd {

    private asWeb(client: SnexLsClient) {
        return client.src as LightstreamerClient;
    }

    createClient(host: string, adapterSet: string): SnexLsClient {
        const ls = new LightstreamerClient(host, adapterSet);
        ls.connectionOptions.setCookieHandlingRequired(true);
        return new SnexLsClient(ls);
    }

    getSessionId(client: SnexLsClient): string { return this.asWeb(client).connectionDetails.getSessionId(); }
    getStatus(client: SnexLsClient): string { return this.asWeb(client).getStatus(); }

    getUser(client: SnexLsClient): string { return this.asWeb(client).connectionDetails.getUser(); }
    setUser(client: SnexLsClient, user: string): void { return this.asWeb(client).connectionDetails.setUser(user); }
    setPassword(client: SnexLsClient, password: string): void { return this.asWeb(client).connectionDetails.setPassword(password); }

    addListener(client: SnexLsClient, listener: SnexLsClientListener): void { this.asWeb(client).addListener(listener); }

    connect(client: SnexLsClient): void { this.asWeb(client).connect(); }
    disconnect(client: SnexLsClient): void { this.asWeb(client).disconnect(); }

    subscribe(client: SnexLsClient, s: SnexLsSubscription): void {
        const sub = new Subscription(s.getMode(), s.getItems(), s.getFields());

        if (s.listeners) {
            s.listeners.forEach(l => {
                const casted: SubscriptionListener = {
                    onSubscriptionError: l.onSubscriptionError,
                    onItemUpdate: (update: ItemUpdate) => l.onItemUpdate(update as unknown as SnexLsItemUpdate),
                    onListenEnd: l.onListenEnd,
                    onSubscription: l.onSubscription,
                    onUnsubscription: l.onUnsubscription
                }
                sub.addListener(casted);
            });
        }

        if (s.getItemGroup()) sub.setItemGroup(s.getItemGroup());
        if (s.getFieldSchema()) sub.setFieldSchema(s.getFieldSchema());
        if (s.getDataAdapter()) sub.setDataAdapter(s.getDataAdapter());
        if (s.getRequestedMaxFrequency()) sub.setRequestedMaxFrequency(s.getRequestedMaxFrequency());
        if (s.getRequestedSnapshot()) sub.setRequestedSnapshot(s.getRequestedSnapshot());

        sub.configure();
        s.setBackendRef(sub);

        this.asWeb(client).subscribe(sub);
    }

    unsubscribe(client: SnexLsClient, subscription: SnexLsSubscription): void {
        this.asWeb(client).unsubscribe(subscription.backendRef as Subscription);
    }

}