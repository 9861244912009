import { PremiumProductMetadata, PremiumProducts, ProdCode, TierLevel } from "phoenix/constants/PremiumProducts"
import { TierInfo } from "phoenix/hooks/UseTier"

export const getTierSettings = (props: {
    pristine: boolean,
    loading: boolean, 
    isPreferredAllowed: boolean,
    hasPremiumEnrollmentFeatureFlag: boolean,
    isPermittedForAlerts: boolean,
    subTier?: string | undefined,
    subPaymentAccountNumber?: string | undefined,
    prefix?: string | undefined, 
    product?: any | undefined,
    productMetadata?: PremiumProductMetadata | undefined,
}): TierInfo => {

    const deepAnalysisProducts = new Set([
        ProdCode('trial', PremiumProducts.premium),
        ProdCode('preferred', PremiumProducts.premium),
        ProdCode('preferred', PremiumProducts.miPremiumBundle)
    ])

    const {pristine, loading, isPreferredAllowed, hasPremiumEnrollmentFeatureFlag, isPermittedForAlerts,
            subTier, subPaymentAccountNumber, prefix, product, productMetadata} = props
    
    if (!isPreferredAllowed) {
        return {
            tierLoading: loading || pristine,
            tierPristine: pristine,
            isPreferred: false,
            isTrial: false,
            isFree: true,
            isPaid: false,
            tier: 'free',
            tierPrefix: 'free',
            product: undefined,
            productMetadata: undefined,
            preferredEnabledBySystemFlag: false,
            permittedForDeepAnalysis: false,
            permittedForAlerts: false,
            paymentAccountNumber: undefined
        }
    }

    return {
        tierLoading: loading || pristine,
        tierPristine: pristine,
        isPreferred: subTier ? /^preferred/.test(subTier) : false,
        isTrial: subTier ? /^trial/.test(subTier) : false,
        isFree: subTier ? subTier === 'free' : true,
        isPaid: subTier ? subTier !== 'free' : false,
        tier: subTier,
        tierPrefix: prefix as TierLevel,
        product,
        productMetadata,
        preferredEnabledBySystemFlag: isPreferredAllowed,
        permittedForDeepAnalysis: hasPremiumEnrollmentFeatureFlag ? !!subTier && deepAnalysisProducts.has(subTier) : true,
        permittedForAlerts: isPermittedForAlerts,
        paymentAccountNumber: subPaymentAccountNumber
    }
}
