import React from 'react';

export const DelayedIcon = ({ flavor = 'light', width, height }: { flavor: 'light' | 'dark'; width?: number; height?: number }): JSX.Element => {
    switch (flavor) {
        case 'dark':
            return (
                <svg width={width || '89'} height={height || '37'} viewBox='0 0 89 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M7.96533 8.8963L10.0291 10.9536' stroke='white' strokeWidth='2' strokeLinecap='round' />
                    <path
                        d='M30.1318 11.2313C27.0383 5.24539 20.8098 2.86903 14.0755 4.66782C6.30183 6.74426 1.70392 14.7668 3.80581 22.5866C5.90771 30.4065 13.9135 35.0624 21.6872 32.986C25.0206 32.0956 27.0758 30.8113 28.9635 28.219'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                    />
                    <rect x='14.6509' y='1.74573' width='6.4504' height='2.21199' rx='1.10599' fill='white' stroke='white' strokeWidth='2' />
                    <path d='M4.01025 18.7544H6.93396' stroke='white' strokeWidth='2' strokeLinecap='round' />
                    <path d='M25.6797 10.9536L28.0874 8.89627' stroke='white' strokeWidth='2' strokeLinecap='round' />
                    <path d='M17.9404 5.12451V8.03908' stroke='white' strokeWidth='2' strokeLinecap='round' />
                    <path d='M17.9404 33.5844V29.8126' stroke='white' strokeWidth='2' strokeLinecap='round' />
                    <path d='M7.96533 28.441L10.0291 26.5551' stroke='white' strokeWidth='2' strokeLinecap='round' />
                    <path
                        d='M37.2273 26H32.75V14.3636H37.1818C38.3788 14.3636 39.4129 14.5966 40.2841 15.0625C41.1591 15.5246 41.8333 16.1913 42.3068 17.0625C42.7841 17.9299 43.0227 18.9697 43.0227 20.1818C43.0227 21.3939 42.786 22.4356 42.3125 23.3068C41.839 24.1742 41.1686 24.8409 40.3011 25.3068C39.4337 25.7689 38.4091 26 37.2273 26ZM35.9091 23.3182H37.1136C37.6894 23.3182 38.1799 23.2254 38.5852 23.0398C38.9943 22.8542 39.3049 22.5341 39.517 22.0795C39.733 21.625 39.8409 20.9924 39.8409 20.1818C39.8409 19.3712 39.7311 18.7386 39.5114 18.2841C39.2955 17.8295 38.9773 17.5095 38.5568 17.3239C38.1402 17.1383 37.6288 17.0455 37.0227 17.0455H35.9091V23.3182ZM44.4844 26V14.3636H52.8707V16.9091H47.6435V18.9091H52.4389V21.4545H47.6435V23.4545H52.848V26H44.4844ZM54.4375 26V14.3636H57.5966V23.4545H62.3011V26H54.4375ZM66.6009 26H63.1918L67.0327 14.3636H71.3509L75.1918 26H71.7827L69.2372 17.5682H69.1463L66.6009 26ZM65.9645 21.4091H72.3736V23.7727H65.9645V21.4091ZM74.6477 14.3636H78.1705L80.4432 19.0909H80.5341L82.8068 14.3636H86.3295L82.0568 22.3409V26H78.9205V22.3409L74.6477 14.3636Z'
                        fill='white'
                    />
                    <path
                        d='M16.0299 14.8937V25.0756H13.2657V17.4392H13.2061L10.9788 18.7716V16.425L13.4845 14.8937H16.0299ZM22.1972 25.2148C21.415 25.2148 20.7206 25.0772 20.1141 24.8021C19.5109 24.5237 19.0352 24.1409 18.6872 23.6537C18.3425 23.1665 18.1669 22.6063 18.1602 21.9733H20.8449C20.8548 22.3246 20.9907 22.6047 21.2526 22.8135C21.5177 23.0223 21.8326 23.1267 22.1972 23.1267C22.4789 23.1267 22.7275 23.067 22.9429 22.9477C23.1584 22.8251 23.3274 22.6527 23.45 22.4307C23.5727 22.2086 23.6323 21.9501 23.629 21.6551C23.6323 21.3568 23.5727 21.0983 23.45 20.8795C23.3274 20.6575 23.1584 20.4868 22.9429 20.3675C22.7275 20.2448 22.4789 20.1835 22.1972 20.1835C21.9055 20.1835 21.6354 20.2548 21.3868 20.3973C21.1415 20.5365 20.9609 20.7304 20.8449 20.979L18.4188 20.5017L18.8165 14.8937H25.6574V17.1011H21.0835L20.8847 19.3483H20.9443C21.1034 19.04 21.3785 18.7848 21.7696 18.5827C22.1607 18.3772 22.6148 18.2744 23.1318 18.2744C23.7384 18.2744 24.2786 18.4153 24.7526 18.697C25.2298 18.9787 25.606 19.3682 25.8811 19.8653C26.1595 20.3592 26.2971 20.9293 26.2938 21.5756C26.2971 22.2848 26.128 22.9146 25.7867 23.4648C25.4486 24.0116 24.973 24.4409 24.3598 24.7524C23.7467 25.0606 23.0258 25.2148 22.1972 25.2148Z'
                        fill='white'
                    />
                </svg>
            );
        case 'light':
        default:
            return (
                <svg width={width || '89'} height={height || '37'} viewBox='0 0 89 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M7.96533 8.8963L10.0291 10.9536' stroke='#333333' strokeWidth='2' strokeLinecap='round' />
                    <path
                        d='M30.1318 11.2313C27.0383 5.24539 20.8098 2.86903 14.0755 4.66782C6.30183 6.74426 1.70392 14.7668 3.80581 22.5866C5.90771 30.4065 13.9135 35.0624 21.6872 32.986C25.0206 32.0956 27.0758 30.8113 28.9635 28.219'
                        stroke='#333333'
                        strokeWidth='2'
                        strokeLinecap='round'
                    />
                    <rect x='14.6509' y='1.74573' width='6.4504' height='2.21199' rx='1.10599' fill='#333333' stroke='#333333' strokeWidth='2' />
                    <path d='M4.01025 18.7544H6.93396' stroke='#333333' strokeWidth='2' strokeLinecap='round' />
                    <path d='M25.6797 10.9536L28.0874 8.89627' stroke='#333333' strokeWidth='2' strokeLinecap='round' />
                    <path d='M17.9404 5.12451V8.03908' stroke='#333333' strokeWidth='2' strokeLinecap='round' />
                    <path d='M17.9404 33.5844V29.8126' stroke='#333333' strokeWidth='2' strokeLinecap='round' />
                    <path d='M7.96533 28.441L10.0291 26.5551' stroke='#333333' strokeWidth='2' strokeLinecap='round' />
                    <path
                        d='M37.2273 26H32.75V14.3636H37.1818C38.3788 14.3636 39.4129 14.5966 40.2841 15.0625C41.1591 15.5246 41.8333 16.1913 42.3068 17.0625C42.7841 17.9299 43.0227 18.9697 43.0227 20.1818C43.0227 21.3939 42.786 22.4356 42.3125 23.3068C41.839 24.1742 41.1686 24.8409 40.3011 25.3068C39.4337 25.7689 38.4091 26 37.2273 26ZM35.9091 23.3182H37.1136C37.6894 23.3182 38.1799 23.2254 38.5852 23.0398C38.9943 22.8542 39.3049 22.5341 39.517 22.0795C39.733 21.625 39.8409 20.9924 39.8409 20.1818C39.8409 19.3712 39.7311 18.7386 39.5114 18.2841C39.2955 17.8295 38.9773 17.5095 38.5568 17.3239C38.1402 17.1383 37.6288 17.0455 37.0227 17.0455H35.9091V23.3182ZM44.4844 26V14.3636H52.8707V16.9091H47.6435V18.9091H52.4389V21.4545H47.6435V23.4545H52.848V26H44.4844ZM54.4375 26V14.3636H57.5966V23.4545H62.3011V26H54.4375ZM66.6009 26H63.1918L67.0327 14.3636H71.3509L75.1918 26H71.7827L69.2372 17.5682H69.1463L66.6009 26ZM65.9645 21.4091H72.3736V23.7727H65.9645V21.4091ZM74.6477 14.3636H78.1705L80.4432 19.0909H80.5341L82.8068 14.3636H86.3295L82.0568 22.3409V26H78.9205V22.3409L74.6477 14.3636Z'
                        fill='#333333'
                    />
                    <path
                        d='M16.0299 14.8937V25.0756H13.2657V17.4392H13.2061L10.9788 18.7716V16.425L13.4845 14.8937H16.0299ZM22.1972 25.2148C21.415 25.2148 20.7206 25.0772 20.1141 24.8021C19.5109 24.5237 19.0352 24.1409 18.6872 23.6537C18.3425 23.1665 18.1669 22.6063 18.1602 21.9733H20.8449C20.8548 22.3246 20.9907 22.6047 21.2526 22.8135C21.5177 23.0223 21.8326 23.1267 22.1972 23.1267C22.4789 23.1267 22.7275 23.067 22.9429 22.9477C23.1584 22.8251 23.3274 22.6527 23.45 22.4307C23.5727 22.2086 23.6323 21.9501 23.629 21.6551C23.6323 21.3568 23.5727 21.0983 23.45 20.8795C23.3274 20.6575 23.1584 20.4868 22.9429 20.3675C22.7275 20.2448 22.4789 20.1835 22.1972 20.1835C21.9055 20.1835 21.6354 20.2548 21.3868 20.3973C21.1415 20.5365 20.9609 20.7304 20.8449 20.979L18.4188 20.5017L18.8165 14.8937H25.6574V17.1011H21.0835L20.8847 19.3483H20.9443C21.1034 19.04 21.3785 18.7848 21.7696 18.5827C22.1607 18.3772 22.6148 18.2744 23.1318 18.2744C23.7384 18.2744 24.2786 18.4153 24.7526 18.697C25.2298 18.9787 25.606 19.3682 25.8811 19.8653C26.1595 20.3592 26.2971 20.9293 26.2938 21.5756C26.2971 22.2848 26.128 22.9146 25.7867 23.4648C25.4486 24.0116 24.973 24.4409 24.3598 24.7524C23.7467 25.0606 23.0258 25.2148 22.1972 25.2148Z'
                        fill='#333333'
                    />
                </svg>
            );
    }
};
