import { Flex } from 'components';
import React, { useEffect } from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useColors } from 'hooks/UseColors';
import { Routes } from 'util/Routes';
import { useText } from 'phoenix/hooks/UseText';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { OnboardingButton, OnboardingPageTitle, OnboardingScreen } from 'components/Onboarding';
import { ChatWithSupportButton } from 'components/Support/ChatWithSuportButton';
import { SnexButton } from 'components/SnexButton';
import { ExitToApp } from '@mui/icons-material';
import { GoToLogout } from 'util/GoToLogin';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';

export function AccountOnboardingSelectionScreen(): JSX.Element {
    const colors = useColors();
    const user = useSnexStore((s) => s.user?.myInfo?.data);
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();
    const futuresOnboardingFlagEnabled = useFeatureFlag(FeatureFlags.FuturesOnboarding);

    useEffect(() => {
        setBaseLayoutProps({ noShow: true });
    }, [setBaseLayoutProps]);

    const selectOnboarding = (type: 'equities' | 'futures') => {
        window.location.href = Routes.accountOnboarding(type, 2);
    };

    const text = useText((t) => t.sharedOnboarding.selectAccount);
    const iconStyle = (color: string) => ({
        border: `1px solid ${color}`,
        borderRadius: 20,
        color: '#fff',
        backgroundColor: color || 'none',
        flex: '0 0 auto',
        width: 42,
        height: 42
    });

    // All legitimately-registered users will have their MyInfo in the Redux store by this point, because our auth flow always fetches it.
    // But if the fetch fails, especially when the user took a weird path to gain access to the app, then all bets are off, and we need to surface an error.
    if (!user) {
        const error = new Error('AccountOnboardingSelectionScreen: User not found in Redux store');
        ErrorLogger.RecordError(error, 'AccountOnboardingSelectionScreen', { info: { user } });
        throw error;
    }

    return (
        <OnboardingScreen
            showDisclaimer={false}
            form={
                <Flex column center>
                    <OnboardingPageTitle title={text.title(user.name)} subtitle={text.subtitle} noMargin />
                    <OnboardingButton
                        handleClick={() => selectOnboarding('equities')}
                        label={text.equitiesButtonLabelTitle}
                        sublabel={text.equitiesButtonLabelSubtitle}
                        icon={
                            <Flex column center align='center' style={{ ...iconStyle(colors.selectListSelectedItemColor), boxSizing: 'border-box' }}>
                                <AccountBalanceWalletIcon />
                            </Flex>
                        }
                    />
                    {futuresOnboardingFlagEnabled && (
                        <OnboardingButton
                            handleClick={() => selectOnboarding('futures')}
                            label={text.futuresButtonLabelTitle}
                            sublabel={text.futuresButtonLabelSubtitle}
                            icon={
                                <Flex column center align='center' style={{ ...iconStyle(colors.futuresColor), boxSizing: 'border-box' }}>
                                    <AccountBalanceWalletIcon />
                                </Flex>
                            }
                        />
                    )}
                    <Flex row center fullWidth style={{ gap: '2rem' }}>
                        <LogoutButton />
                        <ChatWithSupportButton />
                    </Flex>
                </Flex>
            }
        />
    );
}

function LogoutButton(): JSX.Element {
    const colors = useColors();
    const text = useText((s) => s.header);
    const handleClick = () => {
        GoToLogout();
    };
    return (
        <SnexButton eventTag='Support Chat' onClick={handleClick} flavor='secondary-transparent' style={{ color: colors.primaryItemColor }}>
            <Flex row align='center' justify='center'>
                <ExitToApp />
                <span style={{ marginLeft: '1rem', textAlign: 'center' }}>{text.logOut}</span>
            </Flex>
        </SnexButton>
    );
}
