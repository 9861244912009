// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bm7ljx4co5DtCw1fXnBj_{border-radius:2px;border:2px solid #135da3;height:25px;width:25px;margin-right:10px}._1MM6995NPQ6tnOiABv423S{background:#135da3}._1JlXLlzDYoBDOBZl1MjJ5V{transition:200ms;border-radius:5px}._1JlXLlzDYoBDOBZl1MjJ5V:hover{background:rgba(48,48,238,.062)}", ""]);
// Exports
exports.locals = {
	"checkbox": "Bm7ljx4co5DtCw1fXnBj_",
	"selectedBox": "_1MM6995NPQ6tnOiABv423S",
	"row": "_1JlXLlzDYoBDOBZl1MjJ5V"
};
module.exports = exports;
