// @ts-strict-ignore
import React, { useEffect } from 'react';
import { OnboardingEngine } from '../Base/OnboardingEngine';
import { OnboardingViewModel } from '../../../phoenix/onboarding/OnboardingTypes';
import { useRegistrationStore } from './Store';
import { registration_userInfo_screen, registration_userInfo_step } from './Screens/UserInfo/Index';
import { registration_welcome_step, registration_welcome_screen } from './Screens/Welcome/Index';
import { LoadingSpinner } from 'components';
import { registration_password_screen, registration_password_step } from './Screens/Password/Index';
import { registration_personal_screen, registration_personal_step } from './Screens/Personal/Index';
import { registration_verifyEmail_screen, registration_verifyEmail_step } from './Screens/VerifyEmail/Index';
import { registration_userExists_screen } from './Screens/UserExists/Index';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';

export const RegistrationViewModel: OnboardingViewModel = {
    steps: [registration_welcome_step, registration_userInfo_step, registration_personal_step, registration_password_step, registration_verifyEmail_step],
    screens: [
        registration_welcome_screen,
        registration_userInfo_screen,
        registration_userExists_screen,
        registration_password_screen,
        registration_personal_screen,
        registration_verifyEmail_screen
    ],
    stepsDisabled: () => useRegistrationStore.getState().loading || useRegistrationStore.getState().verifying
};

export const RegistrationOnboarding = () => {
    const { selectedScreenId, setValue } = useRegistrationStore();
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    useEffect(() => {
        setBaseLayoutProps({ noShow: true });
    }, [setBaseLayoutProps]);

    const updateSelectedScreen = (value: string) => {
        console.log(`setting screen to ${value}`);
        setValue('selectedScreenId', value);
    };
    return !useRegistrationStore ? (
        <LoadingSpinner />
    ) : (
        <OnboardingEngine viewModel={RegistrationViewModel} selectedScreenId={selectedScreenId} setScreenId={updateSelectedScreen} showDisclosure />
    );
};
