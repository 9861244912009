// @ts-strict-ignore
import { ApiData, ApiOperation, ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AccountOpeningsState } from '../models/AccountOpenings/AccountOpeningsState'

const check = GroupNameChecker([Actions.AccountOpenings])
export const AccountOpeningReducer = (
    state: AccountOpeningsState = new AccountOpeningsState(),
    action: ReduxAction): AccountOpeningsState => {
    if (!check(action)) return state

    switch (action.type) {
        case Actions.AccountOpenings.GetAll.Loading: return { ...state, all: state.all.startLoading(state.all.data) }
        case Actions.AccountOpenings.GetAll.Success: return { ...state, all: state.all.succeeded(action.data) }
        case Actions.AccountOpenings.GetAll.Failure: return { ...state, all: state.all.failed(action.error) }

        default: return state
    }
}
