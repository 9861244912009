// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { SecurityAnalysisState } from '../models/SecurityAnalysis/SecurityAnalysisState'

const permitted = GroupNameChecker([Actions.Securities.Ownership, Actions.Securities.GetPriceTarget])
export const SecurityAnalysisReducer = (state: SecurityAnalysisState = new SecurityAnalysisState(), action: ReduxAction): SecurityAnalysisState => {
    if (!permitted(action)) return state

    const withSymbolUpdate = (subState: string, update: (d: ApiData<any>) => ApiData<any>) => ({
        ...state.bySymbol,
        [action.subject]: {
            ...state.bySymbol[action.subject],
            [subState]: update(state.bySymbol[action.subject]?.[subState] || new ApiData())
        }
    })

    switch (action.type) {
        case Actions.Securities.Ownership.GetInsiders.Loading: return { ...state, bySymbol: withSymbolUpdate('insiders', u => u.startLoading(u.data)) }
        case Actions.Securities.Ownership.GetInsiders.Success: return { ...state, bySymbol: withSymbolUpdate('insiders', u => u.succeeded(action.data)) }
        case Actions.Securities.Ownership.GetInsiders.Failure: return { ...state, bySymbol: withSymbolUpdate('insiders', u => u.failed(action.error)) }

        case Actions.Securities.Ownership.GetInstitutions.Loading: return { ...state, bySymbol: withSymbolUpdate('institutionalOwners', u => u.startLoading(u.data)) }
        case Actions.Securities.Ownership.GetInstitutions.Success: return { ...state, bySymbol: withSymbolUpdate('institutionalOwners', u => u.succeeded(action.data)) }
        case Actions.Securities.Ownership.GetInstitutions.Failure: return { ...state, bySymbol: withSymbolUpdate('institutionalOwners', u => u.failed(action.error)) }

        case Actions.Securities.Ownership.GetFunds.Loading: return { ...state, bySymbol: withSymbolUpdate('fundOwners', u => u.startLoading(u.data)) }
        case Actions.Securities.Ownership.GetFunds.Success: return { ...state, bySymbol: withSymbolUpdate('fundOwners', u => u.succeeded(action.data)) }
        case Actions.Securities.Ownership.GetFunds.Failure: return { ...state, bySymbol: withSymbolUpdate('fundOwners', u => u.failed(action.error)) }

        case Actions.Securities.GetPriceTarget.Loading: return { ...state, bySymbol: withSymbolUpdate('priceTarget', u => u.startLoading(u.data)) }
        case Actions.Securities.GetPriceTarget.Success: return { ...state, bySymbol: withSymbolUpdate('priceTarget', u => u.succeeded(action.data)) }
        case Actions.Securities.GetPriceTarget.Failure: return { ...state, bySymbol: withSymbolUpdate('priceTarget', u => u.failed(action.error)) }

        default: return state
    }
}
