import React, { useEffect } from 'react';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';

export const NeilDebugScreen = (): JSX.Element => {
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Set Base Layout Props
    useEffect(() => {
        setBaseLayoutProps({});
    }, [setBaseLayoutProps]);

    return <></>;
};
