// @ts-strict-ignore
import { Flex } from "components/Flex"
import { useAppTheme } from "hooks/UseAppTheme";
import { AppliedAppThemes } from "phoenix/constants/AppliedAppTheme"
import React from "react"
import xBackground from 'phoenix/assets/images/background.png';
import xBackgroundDark from 'phoenix/assets/images/background_dark.png';

export const XBackgroundScreen = ({children}) => {
    const [appTheme] = useAppTheme();
    return(
    <Flex
            column
            style={{
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '0 0 0 132px',
                backgroundImage: `url(${appTheme === AppliedAppThemes.light ? xBackground : xBackgroundDark})`
            }}
        >
            { children }
    </Flex>
    )
}