import { ReduxAction } from '../../models/ReduxAction';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { MiniplayerState } from '../models/Miniplayer/MiniplayerState';

const permitted = GroupNameChecker([Actions.Miniplayer]);
export const MiniplayerReducer = (state: MiniplayerState = new MiniplayerState(), action: ReduxAction): MiniplayerState => {
    if (!permitted(action)) return state;

    switch (action.type) {
        case Actions.Miniplayer.SetMedia:
            return {
                ...state,
                id: action.data.id,
                mediaUrl: action.data.url,
                autoplay: action.data.autoplay,
                playing: action.data.autoplay,
                open: true,
                mediaTitle: action.data.title,
                mediaSubtitle: action.data.subtitle,
                mediaSymbol: action.data.symbol,
                mediaDate: action.data.date,
                mediaTranscript: action.data.transcript
            };

        case Actions.Miniplayer.Size:
            return { ...state, size: action.data };

        case Actions.Miniplayer.Close:
            return new MiniplayerState();
        case Actions.Miniplayer.Resume:
            return { ...state, playing: true };
        case Actions.Miniplayer.Pause:
            return { ...state, playing: false };
        // case Actions.Miniplayer.Mute: return { ...state, muted: true }
        // case Actions.Miniplayer.Unmute: return { ...state, muted: false }

        case Actions.Miniplayer.Loading:
            return { ...state, loading: action.data, open: true };
        case Actions.Miniplayer.Hide:
            return { ...state, open: false };
        case Actions.Miniplayer.Show:
            return { ...state, open: true };

        default:
            return state;
    }
};
