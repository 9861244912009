import { GetWhitelabel, Whitelabel } from "phoenix/constants/Whitelabels";
import { Urls } from "phoenix/constants";
import { Actions } from "./Constants";
import { ReduxApiGet } from "./Helpers";

export const GetWhitelabelSettings = (whitelabel? : Whitelabel) => 
    ReduxApiGet(Urls.whitelabels.getSettings(whitelabel || GetWhitelabel()), Actions.Whitelabel.GetSettings)
        .withMutex()
        .useStored((s) => s.securities.riskFreeRate?.data)
        .run();
        // .almostRun(true, {
        //     id: Whitelabel.Aiva,
        //     cardText: '<h3>Aiva Whitelabel Text</h3>',
        // })
