// @ts-strict-ignore
export const UniqueBySet = <TItem>(list: TItem[]): TItem[] => Array.from(new Set(list))

export const PutLast = <TItem>(list: TItem[], shouldBeLast: (i: TItem) => boolean): TItem[] => {
    if (!list.some(shouldBeLast)) return list;
    const it = list.find(shouldBeLast)
    const withoutIt = list.filter(i => !shouldBeLast(i))
    return [ ...withoutIt, it ]
}

/**
 *
 * @param list The list that is being filtered
 * @param extractVal Function that is used to pull out the value that the comparison is on.
 * @summary This function will take a list and return a new list with any duplicate values filtered out.
 */
export const UniqueBy = <TItem>(list: TItem[], selector?: (item: TItem) => number | string): TItem[] =>
    list?.reduce
        ? Object.values(
              list.reduce((lookup, next) => {
                  const key = selector(next);
                  return lookup[key] ? lookup : { ...lookup, [key]: next };
              }, {})
          )
        : [];

export const GroupBySelect = <TItem>(list: TItem[], selector: (item: TItem) => string): { [key: string]: TItem[] } => {
    if (!list?.reduce) return {};
    return list.reduce((lookup, item) => {
        const key = selector(item);
        if (lookup[key]) {
            const newLookup = { ...lookup };
            newLookup[key].push(item);
            return newLookup;
        } else {
            return { ...lookup, [key]: [item] };
        }
    }, {});
};

export const GroupBySelectFirst = <TItem>(list: TItem[], selector: (item: TItem) => string): { [key: string]: TItem } => {
    if (!list?.reduce) return {};
    return list.reduce((lookup, item) => {
        const key = selector(item);
        if (lookup[key]) return lookup;
        else return { ...lookup, [key]: item };
    }, {});
};

export const GroupBySelectMap = <TItem, TValue>(
    list: TItem[],
    keySelector: (item: TItem) => string,
    valueSelector: (items: TItem[]) => TValue
): { [key: string]: TValue } => {
    const grouped = GroupBySelect(list || [], keySelector);
    return Object.entries(grouped || {}).reduce(
        (lookup, next) => ({
            ...lookup,
            [next[0]]: valueSelector(next[1])
        }),
        {}
    );
};

export const RemapObjectValues = <TValueOld, TValueNew>(
    object: { [key: string]: TValueOld },
    valueSelector: (old: TValueOld) => TValueNew
): { [key: string]: TValueNew } => {
    return Object.entries(object || {})
        .map(([key, value]) => [key, valueSelector(value)])
        .reduce((out, next: [string, TValueNew]) => ({ ...out, [next[0]]: next[1] }), {});
};

export const RemapObjectKeys = (object: { [key: string]: any }, keySelector: (old: string) => string): { [key: string]: any } => {
    return Object.entries(object || {})
        .map(([key, value]) => [keySelector(key), value])
        .reduce((out, next: [string, any]) => ({ ...out, [next[0]]: next[1] }), {});
};

export const LastIndexOfAny = (haystack: string, needles: string[]): number => {
    const finds = needles.map((n) => haystack.lastIndexOf(n)).filter((x) => x !== -1);
    return finds.length ? Math.max(...finds) : -1;
};

export const Sum = (nums: number[]) => nums?.reduce((sum, next) => sum + next, 0) || 0;
export const NullableSum = (nums: number[]) => nums?.reduce((sum, next) => sum + next, 0) || null;

export const SpokenJoin = (items: string[], andWord = 'and') => {
    if ((items?.length || 0) <= 1) return items[0];
    else if (items.length === 2) return items.join(` ${andWord} `);
    else return items.slice(0, items.length - 1).join(', ') + `, ${andWord} ${items[items.length - 1]}`;
};

export const Chunk = (items: string[], by: 'size' | 'count' = 'count', chunkSize = 100) => {
    if (by === 'size') {
        const chunks = [];
        let wchunk = [];
        let size = 0;
        for (const i in items) {
            wchunk.push(items[i]);
            size += i.length;
            if (size > chunkSize) {
                chunks.push(wchunk);
                wchunk = [];
                size = 0;
            }
        }
        if (wchunk.length) chunks.push(wchunk);
        return chunks;
    } else if (by === 'count') {
        const nChunks = Math.ceil(items.length / chunkSize);
        return new Array(nChunks).fill(0).map((_, idx) => items.slice(idx * chunkSize, (idx + 1) * chunkSize));
    }
};

export const SymbolUrlChunk = (symbols: string[]): string[][] => {
    const filtered = UniqueBy(symbols, (s) => s);
    const chunks = Chunk(filtered, 'size', 200);
    return chunks;
};

export const AllTheSame = (items: any[]): boolean => items.length && items?.every((i) => i === i[0]);

export const First = <TItem>(things: TItem[] | undefined, defaultValue: TItem = null) => (things && things.length >= 0 ? things[0] : defaultValue);
export const Last = <TItem>(things: TItem[] | undefined, defaultValue: TItem = null) => (things && things.length >= 0 ? things[things.length - 1] : defaultValue);
