import { useState, useEffect } from 'react'

function getWindow() {
    const { innerWidth: viewportWidth, 
        innerHeight: viewportHeight } = window
    return {
        viewportWidth,
        viewportHeight
    }
}

// We can extend this to use entire window object in future - PCA

export default function useWindow() {
    const [ windowDimensions, setWindowDimensions ] = useState(getWindow())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindow())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}