import { useCallback, useEffect, useState } from 'react';

function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
    const readValue = useCallback(() => {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key "${key}":`, error);
            return initialValue;
        }
    }, [initialValue, key]);

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(readValue());

    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            // Prevent build error "window is undefined" but keeps working
            if (typeof window === 'undefined') {
                console.warn(`Tried setting localStorage key "${key}" even though environment is not a client`);
            }

            try {
                // Get the current stored value from localStorage
                const currentStoredValue = readValue();

                // Allow value to be a function so we have the same API as useState
                const newValue = value instanceof Function ? value(currentStoredValue) : value;

                // Save to local storage
                window.localStorage.setItem(key, JSON.stringify(newValue));

                // Save state
                setStoredValue(newValue);

                // We dispatch a custom event so every useLocalStorage hook are notified
                window.dispatchEvent(new Event('local-storage'));
            } catch (error) {
                console.warn(`Error setting localStorage key "${key}":`, error);
            }
        },
        [key, readValue]
    );

    useEffect(() => {
        const handleStorageChange = () => {
            setStoredValue(readValue());
        };

        // this only works for other documents, not the current one
        window.addEventListener('storage', handleStorageChange);

        // this is a custom event, triggered in writeValueToLocalStorage
        window.addEventListener('local-storage', handleStorageChange);

        // setStoredValue(readValue());
        const item = window.localStorage.getItem(key);

        if (!item) {
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(initialValue));

            // Save state
            setStoredValue(initialValue);

            // We dispatch a custom event so every useLocalStorage hook are notified
            window.dispatchEvent(new Event('local-storage'));
            // return JSON.parse(item);
        }

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('local-storage', handleStorageChange);
        };
    }, [initialValue, key, readValue]);

    return [storedValue, setValue];
}

export { useLocalStorage };
