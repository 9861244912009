import { Error, Warning } from '@mui/icons-material';
import { Grid } from '@mui/material';
import MaintenanceCallModal from 'components/Modals/MaintenanceCallModal';
import NinetyDayRestrictionModal from 'components/Modals/NinetyDayRestrictionModal';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { T } from 'phoenix/assets/lang/T';
import { ChartRange } from 'phoenix/constants/ChartRange';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetClientAccountsAction } from 'phoenix/redux/actions/AccountsActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Account } from 'phoenix/redux/models';
import { GetMarginCall, GetNinetyDayRestriction } from 'phoenix/util';
import React, { KeyboardEvent, MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { AccountCard } from '../AccountCard';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { ExpandButton } from '../ExpandButton/ExpandButton';
import { Flex } from '../Flex';
import { SectionHeader } from '../SectionHeader';
import { SnexBadge } from '../SnexBadge';
import { AddAccountButton } from './AddAccountButton';
import style from './style.module.scss';

interface AccountListProps {
    onAccountSelect?: (account: Account) => void;
    allowAdd?: boolean;
    range?: ChartRange;
    autoload?: boolean;
    useExpand?: boolean;
    warnMaintenanceCalls?: boolean;
}

export const AccountList = React.memo((props: AccountListProps) => {
    const [marginCallModalOpen, setMarginCallModalOpen] = useState<boolean>(false);
    const [ninetyDayRestrictionModalOpen, setNinetyDayRestrictionModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const accounts = useSelector((s: GlobalState) => s.accounts.all);
    const [appWindowSize] = useAppWindowSize();
    const [expanded, setExpanded] = useState(!props.useExpand);
    const summary = useSnexStore((s) => s.accountSummary.aggregate);
    const text = useText((t) => t.general);

    const rowCount = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return 2;
            case WindowSizes.regular:
            case WindowSizes.small:
                return 3;
            case WindowSizes.large:
            default:
                return 4;
        }
    }, [appWindowSize]);

    const cardWindow = useMemo(
        () => (!props.useExpand || expanded ? accounts.data : accounts.data?.slice(0, rowCount)),
        [props.useExpand, expanded, accounts.data, rowCount]
    );
    const remaining = useMemo(() => (accounts.data?.length || 0) - (cardWindow?.length || 0), [accounts.data, cardWindow?.length]);

    useEffect(() => {
        if (props.autoload) {
            dispatch(GetClientAccountsAction());
        }
    }, [dispatch, props.autoload]);

    const loading = useMemo(() => accounts.loading && !cardWindow?.length, [accounts.loading, cardWindow?.length]);

    const hasMaintenanceCall = useMemo(() => (!loading && summary.data?.maintenanceCallAmount) || GetMarginCall(), [loading, summary]);
    const hasNinetyDayRestriction = useMemo(() => (!loading && summary.data?.bopInfo?.dayNinetyRestriction) || GetNinetyDayRestriction(), [loading, summary]);

    const handleMarginCallClick = (e: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setMarginCallModalOpen(true);
    };

    const handleNinetyDayRestrictionClick = (e: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setNinetyDayRestrictionModalOpen(true);
    };

    return (
        <Flex column className={style.wrapper}>
            <Flex row align='center' justify='space-between'>
                <Flex row align='center' justify='flex-start' style={{ flex: 1 }}>
                    <SectionHeader label={T((s) => s.misc.accounts(accounts?.data?.length || 0))} />
                    {!!hasMaintenanceCall && (
                        <button onClick={handleMarginCallClick} onKeyDown={(e) => e.key === 'Enter' && handleMarginCallClick(e)}>
                            <SnexBadge flavor='orange' iconComponent={Warning} whiteSpace='normal'>
                                {text.maintenanceCallForAccounts}
                            </SnexBadge>
                        </button>
                    )}
                    {!!hasNinetyDayRestriction && (
                        <button onClick={handleNinetyDayRestrictionClick} onKeyDown={(e) => e.key === 'Enter' && handleNinetyDayRestrictionClick(e)}>
                            <SnexBadge flavor='red' iconComponent={Error} whiteSpace='normal'>
                                {text.ninetyDayRestriction.forAccounts}
                            </SnexBadge>
                        </button>
                    )}
                </Flex>
                {props.allowAdd ? (
                    <Flex justify='flex-end' style={{ marginRight: 12 }}>
                        <AddAccountButton disabled={accounts?.loading} />
                    </Flex>
                ) : null}
            </Flex>
            {loading ? (
                <div style={{ marginBottom: 10 }}>
                    <AccountCardList loading loadingCount={rowCount} />
                </div>
            ) : (
                <>
                    <div style={{ marginBottom: 10 }}>
                        <AccountCardList accounts={accounts?.data?.slice(0, rowCount)} {...props} />
                    </div>
                    <CulledCollapse eventTag='Account List' in={expanded}>
                        <AccountCardList accounts={accounts?.data?.slice(rowCount)} {...props} />
                    </CulledCollapse>
                </>
            )}
            {props.useExpand ? (
                <ExpandButton
                    contractedLabel={T((s) => s.misc.showXMore(remaining))}
                    expanded={expanded}
                    overhang={expanded ? -1 : 12}
                    style={{ visibility: loading || (accounts.data?.length || 0) <= rowCount ? 'hidden' : 'visible' }}
                    onClick={() => setExpanded(!expanded)}
                />
            ) : null}
            <MaintenanceCallModal isOpen={marginCallModalOpen} setOpen={setMarginCallModalOpen} />
            <NinetyDayRestrictionModal isOpen={ninetyDayRestrictionModalOpen} setOpen={setNinetyDayRestrictionModalOpen} />
        </Flex>
    );
});

export const AccountCardList = React.memo(
    (props: { accounts?: Account[]; range?: ChartRange; onAccountSelect?: (account: Account) => void; loading?: boolean; loadingCount?: number }): ReactElement => {
        const [appWindowSize] = useAppWindowSize();

        const gridSize = useMemo(() => {
            switch (appWindowSize) {
                case WindowSizes.large:
                    return 3;
                case WindowSizes.tablet:
                    return 6;
                case WindowSizes.regular:
                case WindowSizes.small:
                default:
                    return 4;
            }
        }, [appWindowSize]);

        const chartWidth = useMemo(() => {
            switch (appWindowSize) {
                case WindowSizes.large:
                    return 270;
                case WindowSizes.tablet:
                    return 300;
                case WindowSizes.regular:
                case WindowSizes.small:
                default:
                    return 270;
            }
        }, [appWindowSize]);

        return (
            <Grid container spacing={2}>
                {props.loading
                    ? new Array(props.loadingCount).fill(0).map((_, idx) => {
                          return (
                              <Grid item key={idx} xs={gridSize}>
                                  <AccountCard loading />
                              </Grid>
                          );
                      })
                    : null}
                {props.accounts?.map((a: Account, idx) => {
                    return (
                        <Grid item key={idx} xs={gridSize}>
                            <Link to={Routes.account(a.accountNumber)}>
                                <AccountCard account={a} chartWidth={chartWidth} range={props.range} onClick={() => props.onAccountSelect && props.onAccountSelect(a)} />
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
);
