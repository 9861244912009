// @ts-strict-ignore
import { CancelOutlined } from '@mui/icons-material';
import { CircularProgress, Skeleton, TableRow, Tooltip } from '@mui/material';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { XS } from 'phoenix/xstream/XS';
import React, { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { ActionModal, CircularLogo, Flex, MillionBillion, SnexTableCell, StyledIcon } from '..';
import { useColors } from '../../hooks/UseColors';
import { useAppWindowSize, WindowSizes } from '../../hooks/UseWindowSize';
import { LiveDataNamespaces } from '../../phoenix/constants/LiveDataNamespaces';
import { useText } from '../../phoenix/hooks/UseText';
import { RemoveFromWatchlistAction } from '../../phoenix/redux/actions';
import { ChangeText } from '../../screens/PositionsScreen/ChangeText';
import { Routes } from '../../util/Routes';
import { AutoSubscribeStockQuotes } from '../AutoSubscribe/AutoSubscribe';
import { AddToListModal } from '../Modals/AddToListModal';
import { AddToListIcon } from '../SecurityChartSection/AddToListIcon';
import { FilterableSecurity } from './FilterableSecurity';
import style from './style.module.scss';

export const FilterableSecurityRowSkeleton = (props: { showControls: boolean; showName: boolean }): ReactElement => {
    return (
        <TableRow>
            <SnexTableCell>
                <Skeleton />
            </SnexTableCell>
            {props.showName && (
                <SnexTableCell>
                    <Skeleton />
                </SnexTableCell>
            )}
            <SnexTableCell>
                <Skeleton />
            </SnexTableCell>
            <SnexTableCell>
                <Skeleton />
            </SnexTableCell>
            <SnexTableCell>
                <Skeleton />
            </SnexTableCell>
            <SnexTableCell>
                <Skeleton />
            </SnexTableCell>
            {props.showControls && <SnexTableCell></SnexTableCell>}
        </TableRow>
    );
};

type LazyLoadedFilterableSecurityRowProps = {
    item: FilterableSecurity;
    isAddToWatchlist?: boolean;
    isDeleteFromWatchlist?: boolean;
    primaryColor: React.CSSProperties['color'];
    secondaryColor: React.CSSProperties['color'];
};

export const LazyLoadedFilterableSecurityRow = ({
    item,
    isAddToWatchlist,
    isDeleteFromWatchlist,
    primaryColor,
    secondaryColor
}: LazyLoadedFilterableSecurityRowProps): ReactElement => {
    const [initialized, setInitialized] = useState(false);
    const [visible, setVisible] = useState(false);
    const [appWindowSize] = useAppWindowSize();
    const showName = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.large:
                return true;
            default:
                return false;
        }
    }, [appWindowSize]);

    useEffect(() => {
        if (visible) setInitialized(true);
    }, [visible]);

    return (
        <ReactVisibilitySensor onChange={(value: boolean) => setVisible(value)} key={`visibility_${item.symbol}`}>
            {initialized ? (
                <FilterableSecurityRow
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    showName={showName}
                    item={item}
                    isAddToWatchlist={isAddToWatchlist}
                    isDeleteFromWatchlist={isDeleteFromWatchlist}
                />
            ) : (
                <FilterableSecurityRowSkeleton showName={showName} showControls={isAddToWatchlist || isDeleteFromWatchlist} />
            )}
        </ReactVisibilitySensor>
    );
};

export const FilterableSecurityRow = ({
    showName,
    item,
    isAddToWatchlist,
    isDeleteFromWatchlist,
    primaryColor,
    secondaryColor
}: {
    showName: boolean;
    item: FilterableSecurity;
    isAddToWatchlist?: boolean;
    isDeleteFromWatchlist?: boolean;
    primaryColor?: React.CSSProperties['color'];
    secondaryColor?: React.CSSProperties['color'];
}): ReactElement => {
    const dispatch = useDispatch();
    const { watchlistId } = useParams<{ watchlistId: string }>();
    const [addToListOpen, setAddToListOpen] = useState(false);
    const [itemDeleted, setItemDeleted] = useState(false);
    const navigate = useNavigate();

    const handleDelete = (e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        setItemDeleted(true);
        dispatch(RemoveFromWatchlistAction(watchlistId, item.symbol));
    };
    const colors = useColors();
    const text = useText((s) => s.securityScreen);
    const quote = XS.Quotes.use(item.symbol);
    const { formatPrice } = useAssetClassMetaV2(item.symbol, { fetchMetadata: true });
    const meta = useSecurityMetadataV2().getMetadataBySymbol(item.symbol);
    const isFuture = FuturesSymbol.IsFuturesSymbol(item.symbol);

    return (
        <TableRow className={style.listRow} key={`row_${item.symbol}`} style={{ height: 'inherit' }}>
            <SnexTableCell route={Routes.security(item.symbol)} fontWeight={800} variant='h6' style={{ width: 150, minWidth: 150 }}>
                <Flex row align={'center'} style={{ gap: 2 }}>
                    <CircularLogo size={25} style={{ marginRight: 10 }} symbol={item.symbol} />
                    <AutoSubscribeStockQuotes namespace={LiveDataNamespaces.SectorSecurities} symbol={item.symbol}>
                        <Tooltip title={item.name}>
                            <span>{QualifiedId.RemovePrefix(item.symbol)}</span>
                        </Tooltip>
                    </AutoSubscribeStockQuotes>
                </Flex>
            </SnexTableCell>
            <SnexTableCell onClick={() => navigate(Routes.security(item.symbol))} right style={{ width: 120, minWidth: 120 }}>
                {formatPrice(quote?.price, meta)}
            </SnexTableCell>
            <SnexTableCell onClick={() => navigate(Routes.security(item.symbol))} right style={{ width: 110 }}>
                <div style={{ marginBottom: 6 }}>
                    <ChangeText formatValue={(value) => formatPrice(value, meta)} figure={quote?.change} type={isFuture ? 'decimal' : 'money'} />
                </div>
                <ChangeText figure={quote?.changePercent * 100} type='percent' />
            </SnexTableCell>

            <SnexTableCell route={Routes.security(item.symbol)} right>
                <div style={{ color: primaryColor, marginBottom: 6 }}>{formatPrice(quote?.bid, meta)}</div>
                <div style={{ color: secondaryColor }}>{formatPrice(quote?.ask, meta)}</div>
            </SnexTableCell>
            <SnexTableCell route={Routes.security(item.symbol)} right>
                <div style={{ color: primaryColor, marginBottom: 6 }}>{formatPrice(quote?.low, meta)}</div>
                <div style={{ color: secondaryColor }}>{formatPrice(quote?.high, meta)}</div>
            </SnexTableCell>
            <SnexTableCell route={Routes.security(item.symbol)} right>
                <div style={{ color: primaryColor, marginBottom: 6 }}>{formatPrice(quote?.week52Low, meta)}</div>
                <div style={{ color: secondaryColor }}>{formatPrice(quote?.week52High, meta)}</div>
            </SnexTableCell>
            <SnexTableCell route={Routes.security(item.symbol)} right>
                <MillionBillion value={quote?.volume} />
            </SnexTableCell>
            <SnexTableCell route={Routes.security(item.symbol)} right>
                {!item.marketCap || item.marketCap < 1 ? '--' : <MillionBillion manageExpand={false} value={item.marketCap} />}
            </SnexTableCell>
            {(isAddToWatchlist || isDeleteFromWatchlist) && (
                <SnexTableCell right>
                    <Flex row reverse fullWidth>
                        {isAddToWatchlist && (
                            <>
                                <div className={style.addToListRow}>
                                    <AddToListIcon securityId={item.symbol} onClick={() => setAddToListOpen(true)} />
                                </div>
                                <ActionModal closerStyle='done-button' isOpen={addToListOpen} label={text.addToList} toggleModal={() => setAddToListOpen(false)}>
                                    <AddToListModal symbol={item.symbol} />
                                </ActionModal>
                            </>
                        )}
                        {isDeleteFromWatchlist && (
                            <>
                                {itemDeleted ? (
                                    <CircularProgress size={20} style={{ color: colors.fadedTextColor, opacity: 0.5 }} />
                                ) : (
                                    <StyledIcon
                                        IconComponent={CancelOutlined}
                                        size='20px'
                                        style={{ color: colors.fadedTextColor, opacity: 0.5 }}
                                        onClick={handleDelete}
                                    />
                                )}
                            </>
                        )}
                    </Flex>
                </SnexTableCell>
            )}
        </TableRow>
    );
};
