// @ts-strict-ignore
import _ from 'lodash'
import { Actions, ReduxApiGet } from '.'
import { Urls } from '../../constants'
import { GlobalState } from '../GlobalState'

export const GetStoreSizeAction = () => {
    // const deeperKeys = new Set(['securities', 'securityAnalysis', 'accounts'])
    const deeperKeys = new Set([])

    return async (_, getState: () => GlobalState) => {
        const s = getState()
        let lookup = Object.keys(s).filter(k => !deeperKeys.has(k)).reduce((summary, next) => ({ ...summary, [next]: SizeOf(s[next], 0) }), {})
        deeperKeys.forEach(k => {
            const sublookup = Object.keys(s[k]).reduce((summary, next) => ({ ...summary, [`${k}.${next}`]: SizeOf(s[k][next], 0) }), {})
            lookup = { ...lookup, ...sublookup }
        })
        return { ...lookup, total: SizeOf(s, 0) }
    }
}

export const TriggerUnauthErrorAction = () => ReduxApiGet(Urls.authentication.unauthError(), Actions.UnauthError)
    .withoutToken()
    .withMutex()
    .onError((e: any, dispatch: any) => e)
    .run()

let apiCallCountHandler = null
export const OnApiAction = (handler: (action: string, type: 'Completed' | 'Raced' | 'Cached' | 'Batched') => any) => { apiCallCountHandler = handler }
export const IncrementApiCallCount = (action: string) => (apiCallCountHandler || _.noop)(action, 'Completed')
export const IncrementMutexdCallCount = (action: string) => (apiCallCountHandler || _.noop)(action, 'Raced')
export const IncrementCachedCallCount = (action: string) => (apiCallCountHandler || _.noop)(action, 'Cached')
export const IncrementBatchedCallCount = (action: string) => (apiCallCountHandler || _.noop)(action, 'Batched')

let reduxActionHandler = null
export const OnReduxAction = (handler: (name: string) => any) => { reduxActionHandler = handler }
export const ReportReduxAction = (name: string) => (reduxActionHandler || _.noop)(name)

let namespaceWhitelist: Set<string> = null
export const PublishSubscriptionWhitelist = (namespaces: string[]) => { namespaceWhitelist = new Set(namespaces) }
export const NamespaceIsWhitelisted = (namespace: string) => (namespaceWhitelist?.size || 0) === 0 || namespaceWhitelist.has(namespace)

// Credit: https://stackoverflow.com/a/29387295
const typeSizes = {
    undefined: (_, d) => 0,
    boolean: (_, d) => 4,
    number: (_, d) => 8,
    string: (item, d) => 2 * item.length,
    object: (item, d) => {
        if (d > 20) {
            console.warn('Cycle warning!', item)
            return 999999999999
        }
        return !item ? 0 : Object
            .keys(item)
            .reduce((total, key) => SizeOf(key, d + 1) + SizeOf(item[key], d + 1) + total, 0)
    }
}

const SizeOf = (value, depth) => typeSizes[typeof value]?.(value) || 0
