import { Flex } from "components"
import React from "react"
import { AgreementRow, OnboardingAgreementsSections } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFullyPaidLendingStore } from "../../Store"
import { useText } from "phoenix/hooks/UseText"
import { Typography } from "@mui/material"
import { T } from "phoenix/assets/lang/T"

export const FPL_disclosures_Form = () => {
    const {getValue, setValue} = useFullyPaidLendingStore()
    const text = useText(t => t.fplOnboarding)
    const agreementRows: AgreementRow[] = [
        {label: text.disclosures.importantCharAndRiskAgreement, field: 'data.charAndRisks', docs: [ { text: text.disclosures.importantCharAndRiskAgreementText(), 
            url: T(t=> t.fplOnboarding.disclosures.importantCharAndRiskAgreementPDFUrl)}]},
    ]

    return(
        <Flex column fullWidth center style={{minWidth: '450px'}}>
            <OnboardingAgreementsSections agreementRows={agreementRows} getter={getValue} setter={setValue}/>
        </Flex>
    )
}