export const LiveDataNamespaces = {
    AccountCard: 'NS_ACCT_CARD',
    AccountChart: 'NS_ACCT_BIG_CHART',
    AccountScreen: 'NS_ACCOUNT_SCREEN',
    AdvancedChart: 'NS_ADVANCED_CHART',
    AdvancedDom: 'NS_ADVANCED_DOM',
    AdvancedQuote: 'NS_ADVANCED_QUOTE',
    BrowseScreen: 'NS_BROWSE_SCREEN',
    CryptoScreen: 'NS_CRYPTO_SCREEN',
    Debug: 'NS_DEBUG',
    DeepAnalysisChart: 'NS_DEEP_SCREEN_CHART',
    FuturesTimeSpreadScreen: 'NS_FTS_SCREEN', // Patterned after options, equivalent to the chain namespace
    FuturesTimeSpreadScreenQuote: 'NS_FTS_SCREEN_QUOTE',
    FuturesTimeSpreadDeeperView: 'NS_FTS_DEEPER_VIEW',
    HoldingsTable: 'NS_HOLDINGS_TABLE_OBSOLETE',
    LastUpdate: 'NS_LAST_UPDATE',
    Messages: 'NS_MSG',
    News: 'NS_NEWS',
    OptionContractScreen: 'NS_OP_CON_SCREEN',
    OptionPositionCell: 'NS_OPT_POS_CELL',
    OptionsScreen: 'NS_OPT_SCREEN',
    OptionsScreenChain: 'NS_OPT_SCREEN_CHAIN',
    OptionsScreenQuote: 'NS_OPT_SCREEN_QUOTE',
    OrderRow: 'NS_ORDER_ROW',
    OrderUpdates: 'NS_ORDERS',
    PortfolioChart: 'NS_PORT_BIG_CHART',
    PortfolioGlance: 'NS_PORTFOLIO_GLANCE',
    PositionsScreen: 'NS_POSITIONS_SCREEN',
    SectorSecurities: 'NS_SECTOR_SECURITIES',
    SecurityCell: 'NS_SEC_CELL',
    SecurityCellAccount: (accountNumber: string) => `NS_SEC_CELL_ACCT#${accountNumber}`,
    SecurityCellAlert: 'NS_SEC_CELL_ALERT',
    SecurityCellPortfolio: 'NS_SEC_CELL_PORT',
    SecurityHoldings: 'NS_SEC_HOLDINGS',
    SecurityScreenOptions: 'NS_SEC_SCREEN_OPTIONS',
    SecurityScreenPrimarySymbol: 'NS_SEC_SCREEN_PRIMARY_SYMBOL',
    SecurityScreenRelated: 'NS_SEC_SCREEN_RELATED',
    SidePanel: 'NS_SIDE_PANEL',
    StreamingNews: 'NS_STREAMING_NEWS',
    SummaryScreen: 'NS_SUMMARY_SCREEN',
    TopSecurities: 'NS_SEC_TOP',
    WatchlistCell: 'NS_WATCHLIST_CELL',
    WatchlistDetails: 'NS_WATCHLIST_DETAILS',
    WatchlistDetailsSimilar: 'NS_WATCHLIST_DETAILS_SIMILAR',
    PortfolioGlancePositions: 'NS_PORTFOLIO_GLANCE_POSITIONS',
    OneProNetworkTicker: 'NS_ONE_PRO_NETWORK_TICKER',
    Mobile: {
        SummaryScreenWatchlist: 'NS_SUMMARY_SCREEN_WATCHLIST',
        SummaryScreenPositions: 'NS_SUMMARY_SCREEN_POSITIONS',
        SummaryScreenMovers: 'NS_SUMMARY_SCREEN_MOVERS',
        SecurityScreen: 'NS_SEC_SCREEN',
        SecurityScreenRelated: 'NS_SEC_SCREEN_RELATED',
        OrderDetailsPopover: 'NS_ORDER_DETAILS_POPOVER'
    }
};

export const LiveDataNamespaceFrequenciesSeconds = {
    NS_SEC_CELL: 10,
    NS_OPT_SCREEN: 1,
    NS_SEC_SCREEN_RELATED: 10,
    NS_ADVANCED_CHART: 1,
    NS_ADVANCED_QUOTE: 1,
    NS_SEC_SCREEN_PRIMARY_SYMBOL: 1,
    NS_HOLDINGS_TABLE: 3,
    NS_NEWS: 10,
    NS_PORTFOLIO_GLANCE: 30
};
