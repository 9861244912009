import { AssetClass } from "phoenix/models/AssetClasses/AssetClass";
import { EquitiesAssetClass } from "phoenix/models/AssetClasses/EquitiesAssetClass";

export const AdrAssetClass: AssetClass = {

    ...EquitiesAssetClass,

    type: 'adr',
    algoliaTypes: ['ad']

}