import { ReduxAction } from '../../../models'
import { Actions } from '../../actions'
import { GlobalState } from '../../GlobalState'
import { Watchlist, WatchlistSecurity } from '../../models'
import { WatchlistDetailsBundle, WatchlistIndexBundleEntry } from '../../models/MobileScreens/WatchlistBundles'
import { asData } from '../ReducerUpdateHelpers'
import { MobileStateUpdates } from './MobileStateUpdates'

export const MobileWatchlistsReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    switch (action.type) {
        case Actions.MobileScreens.Watchlists.LoadIndex.Success: return ReduceWatchlistIndex(state, action)
        case Actions.MobileScreens.Watchlists.LoadDetails.Success: return ReduceWatchlistDetails(state, action)
        default: return state
    }
}

const ReduceWatchlistIndex = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as WatchlistIndexBundleEntry[]

    state.watchlists.all = asData(bundle.map(l => <Watchlist>({
        id: l.id,
        name: l.name,
        securities: l.symbols.map(s => <WatchlistSecurity>({ symbol: s }))
    })))

    MobileStateUpdates.addLogos(state, bundle.flatMap(x => x.logos))

    return state
}

const ReduceWatchlistDetails = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as WatchlistDetailsBundle

    if(bundle.logos) MobileStateUpdates.addLogos(state, bundle.logos)
    if(bundle.metadata) MobileStateUpdates.addMetadata(state, bundle.metadata)
    if(bundle.quotesAndCharts) MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts)
    
    state.mobileScreenLoading.watchlistDetails = state.mobileScreenLoading.watchlistDetails.succeeded()

    return state
}
