import { Brightness5, WbSunny } from '@mui/icons-material';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { useColors } from 'hooks/UseColors';
import { MarketTimeSegments } from 'phoenix/constants/MarketTimeSegments';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TimeBadge } from './TimeBadge';
import { useText } from 'phoenix/hooks/UseText';

export type UserAvatarPlusTimeBadgeProps = {
    orientation?: 'top' | 'bottom' | 'left' | 'right';
    avatarSize?: number;
    spinning?: boolean;
    onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
};

export const UserAvatarPlusTimeBadge = (props: UserAvatarPlusTimeBadgeProps): JSX.Element => {
    const [iconX, setIconX] = useState<string>('0px');
    const [iconY, setIconY] = useState<string>('0px');
    const [isTransitioning, setTransitioning] = useState<boolean>(false);
    const [marketTimeSegment] = useMarketTimeSegmentV2();

    const { orientation = 'left', avatarSize = 48, spinning = false, onClick } = props;
    const { containerDirection, slideRules, clipPath, absolutePosition } = useMemo(() => getTransitionSettings(orientation), [orientation]);
    const colors = useColors();
    const text = useText((s) => s.marketTimeBadge);

    const badgeSettings = useMemo(() => {
        switch (marketTimeSegment) {
            case MarketTimeSegments.open:
                return {
                    textColor: colors.green,
                    backgroundColor: colors.greenLightOpaque,
                    icon: WbSunny,
                    text: text.open
                };
            case MarketTimeSegments.premarket:
                return {
                    textColor: colors.orange,
                    backgroundColor: colors.orangeLightOpaque,
                    icon: Brightness5,
                    text: text.premarket
                };
            case MarketTimeSegments.postmarket:
            case 'closed':
            case 'loading':
            default:
                return {
                    textColor: colors.primaryItemColor,
                    backgroundColor: colors.primaryItemBackdropOpaqueColor,
                    icon: Brightness5,
                    text: text.postmarket
                };
        }
    }, [
        colors.green,
        colors.greenLightOpaque,
        colors.orange,
        colors.orangeLightOpaque,
        colors.primaryItemBackdropOpaqueColor,
        colors.primaryItemColor,
        marketTimeSegment,
        text.open,
        text.postmarket,
        text.premarket
    ]);

    const [badgeSettingsState, setBadgeSettingsState] = useState(badgeSettings);

    const [path, setPath] = useState<string>(clipPath.initial);

    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const slideOut = useCallback(() => {
        setIconX(slideRules.initial[0]);
        setIconY(slideRules.initial[1]);
    }, [slideRules.initial]);

    const slideInAndOut = useCallback(
        (func?: () => void) => {
            setTransitioning(true);
            setIconX('0px');
            setIconY('0px');
            setPath(clipPath.initial);
            sleep(1250).then(() => {
                if (func) func();
                setIconX(slideRules.initial[0]);
                setIconY(slideRules.initial[1]);
                setTransitioning(false);
            });
        },
        [clipPath.initial, slideRules.initial]
    );

    const slideExtend = useCallback(
        (hover: boolean) => {
            if (isTransitioning) return;
            if (hover) {
                setIconX(slideRules.extend[0]);
                setIconY(slideRules.extend[1]);
                setPath(clipPath.extend);
            } else {
                slideOut();
                setPath(clipPath.initial);
            }
        },
        [clipPath.extend, clipPath.initial, isTransitioning, slideOut, slideRules.extend]
    );

    useEffect(() => {
        slideOut();
    }, [slideOut]);

    useEffect(() => {
        slideInAndOut(() => {
            setBadgeSettingsState(badgeSettings);
        });
    }, [badgeSettings, slideInAndOut]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: containerDirection,
                alignItems: 'center',
                position: 'relative'
            }}
            className='Test'
        >
            <UserAvatar size={avatarSize} spinning={spinning} onClick={onClick} style={{ zIndex: 1 }} />
            <TimeBadge
                orientation={orientation}
                style={{
                    zIndex: 0,
                    position: 'absolute',
                    transform: `translate(${iconX}, ${iconY})`,
                    clipPath: `circle(${path})`,
                    ...absolutePosition
                }}
                onHover={(isHover) => slideExtend(isHover)}
                {...badgeSettingsState}
            />
        </div>
    );
};

const getTransitionSettings = (orientation: 'left' | 'right' | 'top' | 'bottom') => {
    const styleObj = {
        left: {
            containerDirection: 'row' as const,
            slideRules: {
                initial: ['-33px', '0px'],
                extend: ['-90%', '0px']
            },
            clipPath: {
                initial: '24px at 21px',
                extend: '75% at 50%'
            },
            absolutePosition: {
                left: '3px'
            }
        },
        right: {
            containerDirection: 'row-reverse' as const,
            slideRules: {
                initial: ['33px', '0px'],
                extend: ['90%', '0px']
            },
            clipPath: {
                initial: '24px at calc(100% - 21px)',
                extend: '75% at 50%'
            },
            absolutePosition: {
                right: '3px'
            }
        },
        top: {
            containerDirection: 'column' as const,
            slideRules: {
                initial: ['0px', '-33px'],
                extend: ['0px', '-95%']
            },
            clipPath: {
                initial: '28px at 50% 21px',
                extend: '75% at 50% 50%'
            },
            absolutePosition: {
                top: '3px'
            }
        },
        bottom: {
            containerDirection: 'column-reverse' as const,
            slideRules: {
                initial: ['0px', '33px'],
                extend: ['0px', '95%']
            },
            clipPath: {
                initial: '28px at 50% calc(100% - 21px)',
                extend: '75% at 50% 50%'
            },
            absolutePosition: {
                bottom: '3px'
            }
        }
    };

    return styleObj[orientation || 'left'];
};
