// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { XS } from 'phoenix/xstream/XS';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { IsNonProd, ResolveEnvironment } from 'phoenix/constants';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

export const PageTitleManager = React.memo(() => {
    const titleSec = useSelector((s: GlobalState) => s.system?.titleSecurity);
    const symbol = titleSec?.toString();

    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol])?.data;
    const xStreamQuote = XS.Quotes.use(symbol);

    const isMutualFund = apiQuote?.securityType && apiQuote?.securityType === 'mutual-fund';
    // Stream is returning 0s for some mutual fund prices. They only update once per day, so we should be able to just use the api data.
    const quote = isMutualFund ? apiQuote : { ...apiQuote, ...xStreamQuote };
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const { formatPrice } = useAssetClassMetaV2(symbol);

    useEffect(() => {
        document.title = (() => {
            const environment = ResolveEnvironment();

            let t: string;
            if (!environment || !IsNonProd()) t = 'StoneX One';
            else t = `StoneX One ${environment.charAt(0).toUpperCase() + environment.slice(1)}`;

            const symbol = titleSec;
            if (!symbol || !quote) return t;
            return `${symbol} ${formatPrice(quote?.price, meta)} | ${t}`;
        })();
    }, [apiQuote, xStreamQuote, titleSec, isMutualFund]);

    return null;
});
