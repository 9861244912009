import React, { ReactElement, useCallback, useMemo } from 'react';
import { AccountDropdown, AccountDropdownPresentation, AccountDropdownProps } from './AccountDropdown';
import { useLocation } from 'react-router-dom';
import { makeAccountOptions } from './helpers';
import { useText } from 'phoenix/hooks/UseText';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { OptionSymbol } from 'phoenix/redux/models';
import { UseActiveAndPendingAccountOptionsV2 } from 'phoenix/hooks/useAccounts';
import { getAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { useSelectedAccountByComponent } from './Store/AccountSelectionStore';

// In most cases, the trade ticket account dropdown gets/sets the globally selected account
// On the positions screen, the selected account needs to operate independently of the globally selected account
// This component determines which account selection state to manage

export function TradeTicketAccountDropdown(props: AccountDropdownProps): ReactElement {
    const { pathname } = useLocation();
    return pathname === '/positions' ? <PositionsTradeTicketAccountDropdown {...props} /> : <AccountDropdown {...props} />;
}

function PositionsTradeTicketAccountDropdown(props: AccountDropdownProps) {
    const { allowAll, assetFamily, balanceType = 'buy', filter: filterByProps, numberWhitelist, symbol = '', useOptionsBuyingPower, useAvailableBalance } = props;
    const text = useText((s) => s);
    const [selectedAccountByComponent, setSelectedAccountByComponent] = useSelectedAccountByComponent('positionsScreenTradeTicket');

    const filter = useCallback(
        (a: { accountNumber: string }): boolean => {
            if (!a?.accountNumber) return false;
            if (filterByProps && !filterByProps(a)) return false;
            if (assetFamily && getAccountAssetClass(a.accountNumber).family !== assetFamily) return false;
            if (numberWhitelist && numberWhitelist.size && !numberWhitelist.has(a.accountNumber)) return false;
            return true;
        },
        [filterByProps, assetFamily, numberWhitelist]
    );

    const qtyOwnedLabelLogic = [
        { rule: assetFamily === EquitiesAssetClass.family && !(symbol ? OptionSymbol.IsOptionSymbol(symbol) : false), value: text.general.shares(2) },
        { rule: true, value: text.general.contracts(2) }
    ];

    const qtyOwnedLabel = qtyOwnedLabelLogic.find((x) => x.rule)?.value;

    const { data: accounts = [] } = UseActiveAndPendingAccountOptionsV2({
        filter,
        selectedAccount: selectedAccountByComponent,
        withAllAccountsOption: allowAll,
        withBuyingPower: true,
        withPositionsForSymbol: symbol,
        withRequests: true
    });

    const options = makeAccountOptions({
        accounts,
        balanceType,
        qtyOwnedLabel,
        symbol,
        text,
        useOptionsBuyingPower,
        useAvailableBalance
    });

    const selectedAccountEffective = useMemo(() => {
        return options?.find((a) => a.value === selectedAccountByComponent);
    }, [selectedAccountByComponent, options]);

    const handleSelect = (accountNumber: string) => {
        setSelectedAccountByComponent(accountNumber);
    };

    return <AccountDropdownPresentation onSelect={handleSelect} options={options} value={selectedAccountEffective} />;
}
