import { Mail } from '@mui/icons-material';
import React from 'react';
import { Flex } from '..';
import { useColors } from 'hooks/UseColors';
import { GetSupportInfoByEnv } from 'phoenix/constants/Support';
import { SnexButton } from '../SnexButton/SnexButton';

export function EmailSupportButton(): JSX.Element {
    const supportContactInfo = GetSupportInfoByEnv();
    const supportEmail = supportContactInfo.email;

    return <EmailSupportButtonBase label={supportEmail.address} mailto={supportEmail.mailto} />;
}

export interface EmailSupportButtonDeniedScreenProps {
    userEmail: string | undefined;
}

export function EmailSupportButtonDeniedScreen({ userEmail }: EmailSupportButtonDeniedScreenProps): JSX.Element {
    const supportContactInfo = GetSupportInfoByEnv();
    const label = supportContactInfo.email.address;
    const mailto = supportContactInfo.loginSupportMailto(userEmail ?? '');
    // The UI can't always retrieve the user's email address.
    // if the user sees something like "undefined" or "with unknown email" in the subject line of their new support email, they might feel tempted to fill it out.
    // Ideally they'd always leave the subject line alone, so that Support has a better idea of what email the user was ACTUALLY using.
    // So, better to leave out that info if we don't have it.

    return (
        <EmailSupportButtonBase label={label} mailto={mailto} />
    );
}

interface EmailSupportButtonBaseProps {
    label: string;
    mailto: string;
}

function EmailSupportButtonBase({ label, mailto }: EmailSupportButtonBaseProps): JSX.Element {
    const colors = useColors();

    return (
        <SnexButton href={mailto} flavor='secondary-transparent' style={{ color: colors.primaryItemColor }}>
            <Flex row align='center' justify='center'>
                <Mail />
                <span style={{ minWidth: 250, textAlign: 'center' }}>{label}</span>
            </Flex>
        </SnexButton>
    );
}
