import { ApiData } from '../../../models/ApiData'
import { UserLogin } from './UserLogin'

export class LoginState {
    loggedIn: ApiData<boolean | null>

    constructor () {
        this.loggedIn = new ApiData(false)
    }
}
