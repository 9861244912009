// @ts-strict-ignore
import { InfoOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Relays } from 'constants/Relays';
import { useColors } from 'hooks/UseColors';
import { useProgress } from 'hooks/UseProgress';
import { useTelemetry } from 'hooks/UseTelemetry';
import { ProgressNameKey, ProgressNames } from 'phoenix/constants/ProgressNames';
import { GetSupportInfoByEnv } from 'phoenix/constants/Support';
import { useRelay } from 'phoenix/hooks/UseRelay';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetClientAccountsAction, HasBeenWelcomedAction } from 'phoenix/redux/actions';
import { MyProgress } from 'phoenix/redux/models/Users/MyProgress';
import {
    GetShowLoadingWelcomeScreen,
    GetShowWelcomeScreen,
    GetShowWelcomeScreenWithError,
    SetShowLoadingWelcomeScreen,
    SetShowWelcomeScreen,
    SetShowWelcomeScreenWithError
} from 'phoenix/util';
import { GetVariant } from 'phoenix/util/Variant';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import { UseSecondaryButtonStyles, UseSubmitButtonStyles } from '../../theming';
import { LanguageDropdown } from '../Inputs/LanguageDropdown';
import { LoadingSpinner } from '../LottieAnimations/LoadingSpinner';
import { ActionModal } from '../Modals';
import { StyledIcon } from '../StyledIcon';
const FloatingGuy = React.lazy(() => import('../LottieAnimations/FloatingGuy'));

export const WelcomeModal = () => {
    const colors = useColors();
    const LogEvent = useTelemetry();
    const buttonStyles = UseSubmitButtonStyles(colors)();
    const exploreButtonStyles = UseSecondaryButtonStyles(colors)();
    const dispatch = useDispatch();
    const [hasManuallyClosed, setHasManuallyClosed] = useState(false);
    const [progress, setProgress] = useProgress();
    const [show, setShow] = useRelay<boolean>(Relays.WelcomeModalVisible);

    const welcomed = progress ? progress?.hasBeenWelcomed : true;
    const visitedPortfolio = progress ? progress?.portfolioTour : true;
    const visitedStocks = progress ? progress?.stockScreenTour : true;

    // Hide the modal if any of the following conditions are met.
    const showWelcomeDebug = GetShowWelcomeScreen() || GetShowWelcomeScreenWithError() || GetShowLoadingWelcomeScreen();
    const userIsNew = useMemo(
        () => showWelcomeDebug || (!welcomed && !visitedPortfolio && !visitedStocks),
        [showWelcomeDebug, welcomed, visitedPortfolio, visitedStocks, progress]
    );

    const accounts = useSnexStore((s) => s.accounts.all);

    useEffect(() => {
        if (userIsNew) setShow(true);
        else setShow(false);
        // Welcome modal is acting up in lower envs
        // setShow(false)
    }, [userIsNew, progress, welcomed]);

    const handleDismiss = async (outcome: 'tour' | 'dismiss' | 'none') => {
        /* added to assist with debug */
        if (GetShowWelcomeScreen()) SetShowWelcomeScreen(false);
        if (GetShowWelcomeScreenWithError()) SetShowWelcomeScreenWithError(false);
        if (GetShowLoadingWelcomeScreen()) SetShowLoadingWelcomeScreen(false);

        setHasManuallyClosed(true);
        LogEvent('Welcome Dismiss', { outcome });
        setShow(false);
        if (outcome === 'dismiss') {
            setProgress(MyProgress.dismissTours(progress));
        } else {
            setProgress({ ...progress, [ProgressNameKey(ProgressNames.hasBeenWelcomed)]: true });
        }
        dispatch(HasBeenWelcomedAction());
    };

    const text = useText((t) => t.welcome);

    return (
        <ActionModal closerStyle='none' height={600} isOpen={show} toggleModal={() => handleDismiss('dismiss')} width={800}>
            <Flex column align='center' justify='space-between' style={{ height: '100%', padding: 10, width: '100%', position: 'relative', paddingTop: 30 }}>
                <Flex column align='center' justify='space-between' style={{ flex: 1 }}>
                    <Flex column align='center' justify='center'>
                        <Typography style={{ marginBottom: 20, fontSize: 14, fontWeight: 300 }} variant='body2'>
                            {text.pickLang}
                        </Typography>
                        <LanguageDropdown />
                    </Flex>
                    <AccountExistenceConfirmationDisplay />
                    <Flex row justify='center' style={{ width: '100%', minHeight: 50 }}>
                        {!accounts.loading && !accounts.pristine && !GetShowLoadingWelcomeScreen() && (
                            <>
                                <Button
                                    classes={exploreButtonStyles}
                                    disabled={accounts.loading || accounts.pristine}
                                    style={{ padding: 10, width: 200, height: 45 }}
                                    onClick={() => handleDismiss('dismiss')}
                                >
                                    {text.skipTour}
                                </Button>
                                <Button
                                    classes={buttonStyles}
                                    disabled={accounts.loading || accounts.pristine}
                                    style={{ marginLeft: 15, padding: 10, height: 45, width: 200 }}
                                    variant='contained'
                                    onClick={() => handleDismiss('tour')}
                                >
                                    {text.showMeAround}
                                </Button>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </ActionModal>
    );
};

const StatusText = (props: { children: any }) => {
    const colors = useColors();
    return (
        <Typography style={{ marginBottom: 15, fontSize: 25, color: colors.generalTextColor }} variant='h6'>
            {props.children}
        </Typography>
    );
};

const InfoText = (props: { children: any }) => {
    const colors = useColors();
    return (
        <Typography style={{ lineHeight: 1.2, width: 500, marginBottom: 15, fontSize: 16, color: colors.generalTextColor, fontWeight: 400 }} variant='body1'>
            {props.children}
        </Typography>
    );
};

const AccountExistenceConfirmationDisplay = () => {
    const colors = useColors();
    const { isClient } = GetVariant();
    const { email } = GetSupportInfoByEnv();
    const dispatch = useDispatch();
    const accounts = useSnexStore((s) => s.accounts.all);
    const exploreButtonStyles = UseSecondaryButtonStyles(colors)();

    useEffect(() => {
        dispatch(GetClientAccountsAction());
    }, []);

    const text = useText((t) => t.welcome);
    const debug = {
        LoadingWelcomeScreen: GetShowLoadingWelcomeScreen(),
        ErrorWelcomeScreen: GetShowWelcomeScreenWithError()
    };

    return accounts.loading || accounts.pristine || debug.LoadingWelcomeScreen ? (
        <Flex center column>
            <Flex center style={{ height: '300px' }}>
                <LoadingSpinner />
            </Flex>
            <StatusText>{text.loadingText}</StatusText>
        </Flex>
    ) : debug.ErrorWelcomeScreen || accounts.error ? (
        <Flex center column style={{ padding: 15, paddingBottom: 50 }}>
            <React.Suspense fallback={<div />}>
                <FloatingGuy size={200} />
            </React.Suspense>
            <Flex style={{ minWidth: 300 }}>
                <StyledIcon IconComponent={InfoOutlined} size={30} style={{ color: 'orange', marginRight: 15 }} />
                <InfoText>
                    {text.errorText}{' '}
                    <a href={email.mailto} style={{ padding: '0 5', color: 'orange' }}>
                        {email.address}
                    </a>
                    .
                </InfoText>
            </Flex>
        </Flex>
    ) : !accounts.data?.length ? (
        <Flex center column style={{ padding: 15, paddingBottom: 50 }}>
            <React.Suspense fallback={<div />}>
                <FloatingGuy size={250} />
            </React.Suspense>
            <Flex>
                <StyledIcon IconComponent={InfoOutlined} size={30} style={{ color: 'orange', marginRight: 15 }} />
                <InfoText>
                    {text.noAccountsText}{' '}
                    <a href={email.mailto} style={{ padding: '0 5', color: 'orange' }}>
                        {email.address}
                    </a>
                    .
                </InfoText>
            </Flex>
        </Flex>
    ) : (
        <Flex column align='flex-start'>
            <Flex center column style={{ width: '100%' }}>
                <React.Suspense fallback={<div />}>
                    <FloatingGuy size={300} />
                </React.Suspense>
                <StatusText>{text.successText}</StatusText>
            </Flex>
        </Flex>
    );
};
