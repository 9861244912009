import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { DeviceState } from '../models/Device/DeviceState'

const check = GroupNameChecker([Actions.Device])
export const DeviceReducer = (state: DeviceState = new DeviceState(), action: ReduxAction): DeviceState => {
    if (!check(action)) return state

    switch (action.type) {
        case Actions.Device.Register.Loading: return { ...state, register: state.register.startLoading() }
        case Actions.Device.Register.Success: return { ...state, register: state.register.succeeded() }
        case Actions.Device.Register.Failure: return { ...state, register: state.register.failed(action.error) }

        default: return state
    }
}
