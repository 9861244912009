import { create } from "zustand"

interface GlobalMessageStore {
    message: React.ReactNode | undefined,
    height: number,
    setMessage: (message: React.ReactNode | undefined) => void,
    setHeight: (height: number | undefined) => void
}

export const useGlobalMessageStore = create<GlobalMessageStore>((set, get) => ({ 
    message: undefined,  /* place global message here */
    height: 0,
    setMessage: (message: React.ReactNode | undefined) => set((s) => ({...s, message})),
    setHeight: (height: number | undefined) => set((s) => ({...s, height: height || 0}))
}))