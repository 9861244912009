// @ts-strict-ignore
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { Flex } from ".."
import { SelectList, SelectListItem } from "../SelectList"
import { FilterableSecuritiesFilter } from "./FilterableSecurityProps"
import { MarketCapFilters } from "./FilterableSecurityTable"

export interface MarketCapSelectorProps {
    filters: FilterableSecuritiesFilter,
    onFilterUpdate: (filters: Partial<FilterableSecuritiesFilter>) => any,
    text: any
}

export const FilterableSecurityMarketCapSelector = React.memo((props: MarketCapSelectorProps) => {
    
    const marketCapFilterItems = [
        { label: props.text.large, sublabel: '10B+', value: MarketCapFilters.Large },
        { label: props.text.mid, sublabel: '$2B - $10B', value: MarketCapFilters.Mid },
        { label: props.text.small, sublabel: '$300M - $2B', value: MarketCapFilters.Small },
        { label: props.text.micro, sublabel: '$50M - $300M', value: MarketCapFilters.Micro },
        { label: props.text.nano, sublabel: `50M ${props.text.andBelow}`, value: MarketCapFilters.Nano },
    ]

    const handleChange = (items: SelectListItem[]) => props.onFilterUpdate({ ...props.filters, marketCap: items })

    return (
        <Flex column align={'flex-start'} wrap >
            <SelectList vertical variant='small' multiSelect={true} items={marketCapFilterItems} onChange={handleChange} selectedItems={props.filters.marketCap && props.filters.marketCap.map(f => f.value)} />
        </Flex>
    )
})