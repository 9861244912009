import { Button, OutlinedInput } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import { CreateWatchlistAction } from 'phoenix/redux/actions/WatchlistsActions';
import { UseInputFieldStyles, UseSecondaryButtonStyles, UseSubmitButtonStyles } from '../../theming';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useColors } from 'hooks/UseColors';

export const AddWatchlist = React.memo(({ secondary }: { secondary?: boolean }) => {
    const dispatch = useDispatch();
    const colors = useColors();
    const inputClasses = UseInputFieldStyles(colors, {}, 'search-no-hover')();
    const submitClasses = secondary ? UseSecondaryButtonStyles(colors)() : UseSubmitButtonStyles(colors)();
    const text = useText((s) => s.modals.addToWatchlist);

    const [listName, setListName] = useState('');
    const submitting = useSnexStore((s) => s.watchlists.create?.loading);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(CreateWatchlistAction(listName));
    };

    return (
        <Flex column style={{ padding: '10px 0px', width: '100%' }}>
            <form onSubmit={handleSubmit}>
                <OutlinedInput classes={inputClasses} placeholder={text.newListName} value={listName} onChange={({ target }) => setListName(target.value)} />
                <Button disableRipple classes={submitClasses} disabled={!listName || submitting} style={{ marginTop: 10, padding: 10 }} type='submit'>
                    {submitting ? text.creating : text.create}
                </Button>
            </form>
        </Flex>
    );
});
