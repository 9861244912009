// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T";
import React from "react";
import { AddToast } from "react-toast-notifications";
import { OnboardingButton, OnboardingScreen, OnboardingStep } from "../../../../../phoenix/onboarding/OnboardingTypes";
import { useRegistrationStore } from "../../Store";
import { getPasswordErrors, PasswordIsValid } from "../Password/Validation";
import { getPersonalErrors, PersonalIsValid } from "../Personal/Validation";
import { getUserInfoErrors, UserInfoIsValid } from "../UserInfo/Validation";
import { HandleVerifyAndLoginClick, Registration_VerifyEmail_Form } from "./Form";
import { getVerifyEmailErrors } from "./Validation";

export const registration_verifyEmail_step: OnboardingStep = {
    label: "Verify Email",
    isComplete: () => getVerifyEmailErrors().length < 1,
    isEnabled: () => PersonalIsValid() && PasswordIsValid() &&  UserInfoIsValid()  
        && !!useRegistrationStore.getState().emailVerified && !!useRegistrationStore.getState().isWelcomed,
    isCurrent: () => ['verifyEmail'].includes(useRegistrationStore?.getState()?.selectedScreenId),
    onClickGoToScreenId: 'verifyEmail'
}

export const registration_verifyEmail_nextButton: OnboardingButton = {
    label: "Submit and Login",
    onClick: (addToast: AddToast) => HandleVerifyAndLoginClick(addToast),
    isPrimary: true,
    isLoading: () => useRegistrationStore.getState().verifying,
    isEnabled: () => !useRegistrationStore.getState().verifying && getVerifyEmailErrors().length < 1 
        && PersonalIsValid() && PasswordIsValid() &&  UserInfoIsValid()
        && !!useRegistrationStore.getState().emailVerified
        && !!useRegistrationStore.getState().isWelcomed
}

export const registration_verifyEmail_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useRegistrationStore.getState().setValue('selectedScreenId', 'personal'),
    isPrimary: false,
    isEnabled: () => true
}

export const registration_verifyEmail_screen: OnboardingScreen = {
    id: "verifyEmail",
    validate: getVerifyEmailErrors,
    buttons: [registration_verifyEmail_prevButton, registration_verifyEmail_nextButton],
    title: T(t => t.webRegistration.verifyEmail),
    subtitles: [ T(t => t.webRegistration.verifyYourEmail)],
    body: <Registration_VerifyEmail_Form />
}
