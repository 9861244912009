// @ts-strict-ignore

import { immer } from 'zustand/middleware/immer'
import { DummyData } from "./DummyData"
import { Author, Post } from "phoenix/stores/OneProNetworkStore/OneProNetworkTypes"
import { GetSecurityQuoteAction, GetSecurityMetadataAction, GetSecurityLogoAction } from "phoenix/redux/actions"
import { Dispatch } from "redux"
import { createClearable as create } from 'phoenix/stores/ZustandStore'

export type OneProNetworkPostDataType = {
    featuredPosts: Post[]
    popularPosts: Post[]
    deskAlerts: Post[]
    feedPosts: Post[]
}

export type OneProNetworkAuthorDataType = {
    authors: { [key: string]: Author }

}

export type OneProNetworkDataType = OneProNetworkPostDataType & OneProNetworkAuthorDataType

type ApiAction = {
    loading: boolean
    pristine: boolean
    error: any
    load: (forceReload?: boolean) => Promise<void>;
}

type OneProNetworkStateActions = {
    highLevel: ApiAction
    mainScreen: ApiAction
}

type OneProNetworkStateData = {
    data: OneProNetworkDataType
}

type OneProNetworkDataHelpers = {
    genericLoad: GenericLoadFunction
    getCombinedPosts: () => Post[]
    findPost: (postId: string) => Post | undefined
    findAuthorById: (authorId: string) => Author | undefined
    findAuthorsDeskAlerts?: (authorId: string) => Post[] | undefined
    findAuthorsFeed?: (authorId: string) => Post[] | undefined
    findAuthorsFeaturedPosts?: (authorId: string) => Post[] | undefined
}

type GenericLoadFunction = (
    substateKey: keyof OneProNetworkStateActions,
    fetchData: () => Promise<Partial<OneProNetworkDataType>>,
    forceReload?: boolean
) => Promise<void>;

type OneProNetworkState = OneProNetworkStateActions & OneProNetworkStateData & OneProNetworkDataHelpers

export const useOneProNetworkStore = create()<OneProNetworkState>(
    // @ts-ignore
    immer((set: (mutate: (state: OneProNetworkState) => void) => void, get) => ({
        data: {
            featuredPosts: [],
            popularPosts: [],
            deskAlerts: [],
            feedPosts: [],
            authors: {}
        },
        highLevel: {
            loading: false,
            pristine: true,
            error: null,
            load: () => get().genericLoad('highLevel', FetchHighLevelData)
        },
        mainScreen: {
            loading: false,
            pristine: true,
            error: null,
            load: () => get().genericLoad('mainScreen', FetchMainScreenData)
        },
        getCombinedPosts: () => {
            const { deskAlerts = [], featuredPosts = [], popularPosts = [], feedPosts = [] } = get().data;
            return [...deskAlerts, ...featuredPosts, ...popularPosts, ...feedPosts]
        },
        findPost: (postId) => {
            const combinedPosts = get().getCombinedPosts();
            return combinedPosts.find(post => post.id === postId)
        },


        findAuthorById: (authorId) => {
            return get().data?.authors[authorId]
        },
        findAuthorsDeskAlerts: (authorId) => {
            return get().data?.deskAlerts.filter(d => d.author?.id === authorId)
        },
        findAuthorsFeed: (authorId) => {
            console.log({ authorId, feedPosts: get().data?.feedPosts })
            return get().data?.feedPosts.filter(d => d.author?.id === authorId)
        },
        findAuthorsFeaturedPosts: (authorId) => {
            return get().data?.featuredPosts.filter(d => d.author?.id === authorId)
        },


        genericLoad: async (substateKey: keyof OneProNetworkStateActions, fetchData: () => Promise<Partial<OneProNetworkDataType>>, forceReload = false) => {
            // This load function may need to adjust to account for converting api payload keys differing from the zustand store keys

            set(state => {
                state[substateKey].loading = true;
            });
            try {
                const { authors, ...postData } = await fetchData()

                set(state => {
                    if (forceReload) {

                        state.data.authors = authors

                        Object.keys(postData).forEach((key: keyof OneProNetworkDataType) => {
                            state.data[key] = postData[key];
                        });
                    } else {
                        Object.keys(authors).forEach((key: string) => {
                            state.data.authors[key] = authors[key];
                        })
                        Object.entries(postData).forEach(([key, posts]: [key: keyof OneProNetworkPostDataType, posts: Post[]]) => {
                            mergePosts(state.data[key], posts);
                        });
                    }
                    state[substateKey].loading = false;
                    state[substateKey].pristine = false;
                });
            } catch (error) {
                set(state => {
                    state[substateKey].loading = false;
                    state[substateKey].error = error;
                });
            }
        }
    })))

const mergePosts = (existingPosts: Post[], newPosts: Post[]) => {
    const postMap = new Map(existingPosts.map(post => [post.id, post]));
    newPosts.forEach(post => {
        if (postMap.has(post.id)) {
            Object.assign(postMap.get(post.id), post);
        } else {
            existingPosts.push(post);
        }
    });
}


// #region EXAMPLE REQUEST STUFF... WILL BE REMOVED

type FetchResponseType = Promise<Partial<OneProNetworkDataType>>

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const FetchHighLevelData = async (): FetchResponseType => {
    // Simulate API call
    return new Promise(resolve => setTimeout(() => {
        resolve({
            featuredPosts: DummyData.featuredPosts,
            popularPosts: DummyData.carouselPosts,
            deskAlerts: DummyData.deskAlerts,
            feedPosts: shuffleArray(DummyData.feedPosts),
            authors: DummyData.authors.reduce((final, author) => ({ ...final, [author.id]: author }), {})
        });
    }, 1000));
}

const FetchMainScreenData = async (): FetchResponseType => {
    // Simulate API call
    return new Promise(resolve => setTimeout(() => {
        resolve({
            featuredPosts: DummyData.featuredPosts,
            popularPosts: DummyData.carouselPosts,
            deskAlerts: DummyData.deskAlerts,
            feedPosts: shuffleArray(DummyData.feedPosts),
            authors: DummyData.authors.reduce((final, author) => ({ ...final, [author.id]: author }), {})
        });
    }, 2000));
}


export const TempGetSecurityInfoAction = (dispatch: Dispatch<any>, qsi: string): void => {
    try {
        dispatch(GetSecurityQuoteAction(qsi))
        dispatch(GetSecurityMetadataAction(qsi))
        dispatch(GetSecurityLogoAction(qsi))
    } catch (error) {
        console.log("[TempGetSecurityInfoAction] error: ", error)
    }

}

// #endregion