// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T";
import { OnboardingInputError, ValidateRequired } from "../../../../../phoenix/onboarding/OnboardingTypes";
import { useRegistrationStore } from "../../Store";
import { parsePhoneNumberWithError } from 'libphonenumber-js'

export const allowedPhoneNumberCharsRegex = /^[\d.\-\s()]+$/
export const registrationPhoneRegex = /^\d{10}\d?$/
export const registrationEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const ParsePhone = (value: string) => {
    try
    {
        const ph =  parsePhoneNumberWithError(value, {defaultCountry: 'US'})
        return ph
    }
    catch(err)
    {
        return null
    }
}

export const UserInfoIsValid = () => getUserInfoErrors().length < 1
export const getUserInfoErrors = () : OnboardingInputError[] => {
    const keys = ['verifiedEmail', 'data.firstName', 'data.lastName', 'phoneNumberRaw', 'data.email', 'data.confirmEmail']
    const store = useRegistrationStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.firstName':
            case 'data.lastName':
                _errors.push(ValidateRequired(store, key))
                break
            case 'phoneNumberRaw': {
                _errors.push(ValidateRequired(store, key))
                if(ParsePhone(store.phoneNumberRaw)?.isValid() !== true) _errors.push({key, error: T(t => t.webRegistration.errors.userInfo.invalidPhone)})
                break
            }
            case 'data.email':
                _errors.push(ValidateRequired(store, key))
                if(!registrationEmailRegex.test(store?.data?.email)) _errors.push({key, error: T(t => t.webRegistration.errors.userInfo.invalidEmail)})
                if(store?.existingEmails?.length && store?.existingEmails.includes(store?.data?.email?.toLowerCase())) _errors.push({key, error: T(t=> t.webRegistration.userExists(store?.data?.email))});
                break
            case 'data.confirmEmail':
                _errors.push(ValidateRequired(store, key))
                if(!!store?.data?.email && store?.data?.confirmEmail?.toLowerCase() !== store?.data?.email?.toLowerCase()) _errors.push({key, error: T(t => t.webRegistration.errors.userInfo.emailDoesNotMatch)})
                break
    }})
    return _errors.filter(e => !!e);
}
export const userInfoIsComplete = () => {
    return getUserInfoErrors().length < 1 && useRegistrationStore.getState().isWelcomed
}