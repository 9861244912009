import { Typography , Skeleton } from '@mui/material';

import React from 'react';
import { Flex } from '..';
import { FormatNumber } from 'phoenix/util';
import style from './style.module.scss';

const SecuritySkeletonCellFunction = (props: { hideSecondaryRow?: boolean, title: string, hideLogo?: boolean }) => {

    const Loading = (p: { width?: number, height?: number }) => (
        <Skeleton animation='wave' style={{ width: p.width, height: p.height }} />
    );

    return (<Flex row className={style.wrapper}>
        <Flex row className={style.innerWrapper} style={{ minHeight: props.hideSecondaryRow ? 60 : 84 }}>
            {!props.hideLogo && <Flex className={style.securityLogoWrapper}>
                <Loading height={ 25 } width={ 25 } />
            </Flex>}
            <Flex column className={style.cellContent}>
                <Flex row className={style.mainRow} justify='space-between' style={{ marginLeft: 1 }}>
                    <Flex column style={{ flex: 1 }}>
                        <Typography style={{ maxWidth: 150, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 600, fontSize: 16 }} variant='h5'>
                            { props.title }
                        </Typography>
                        <Loading width={ 96 } />
                    </Flex>
                    <Flex column align='flex-end' style={{ flex: 1 }}>
                        <Loading width={ 50 } />
                        <Loading width={ 30 } />
                    </Flex>
                </Flex>
                { props.hideSecondaryRow ? null : (
                    <Flex row className={style.mainRow} justify='space-between'>
                        <Loading width={ 64 } />
                        <Loading width={ 128 } />
                    </Flex>
                ) }
            </Flex>
            <Flex className={style.deleteAction}>
            </Flex>
        </Flex>
    </Flex>);

};

export const SecuritySkeletonCell = React.memo(SecuritySkeletonCellFunction);