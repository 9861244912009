import { T, Ts } from '../../../assets/lang/T'
import { DocumentTypeIds, DocumentTypeV2Ids, GetDocumentTypeNameV2ById } from '../../../constants'
import { QualifiedId } from '../../../util/QualifiedId'

export interface SnexDocument {
    id: string,
    documentDate: string,
    subNumber: string,
    branchNumber: string,
    repNumber: string,
    accountNumber: string,
    name: string,
    description: string,
    cusip: string,
    symbol: string,
    fileName: string,
    tradeDate: string,
    reference: string
    referenceId: string
}

export interface SnexDocumentV2 extends SnexDocument {
    source: string,
    documentType: string
}

export const MakeDocumentNameV2 = (doc: SnexDocumentV2) => {
    const text = Ts(s => s.documentsScreen.docNames)

    if (QualifiedId.Class(doc?.accountNumber) === 'futures') {
        switch (doc?.documentType) {
            case DocumentTypeV2Ids.taxDocuments: return doc?.name
            default: return GetDocumentTypeNameV2ById(doc?.documentType) || doc?.name
        }
    }

    switch (doc?.documentType) {
        case DocumentTypeV2Ids.tradeConfirmations: return doc.symbol || doc.cusip || doc.referenceId || T(s => s.general.unknown)
        case DocumentTypeV2Ids.accountStatements: return text.statement(doc)
        case DocumentTypeV2Ids.checks: return text.check(doc)
        case DocumentTypeV2Ids.taxDocuments: return text.taxes(doc)
        default: return doc?.referenceId || doc?.name
    }
}

export const MakeDocumentName = (doc: SnexDocument, documentType: string) => {
    const { confirms, statements, checks, taxes, misc } = DocumentTypeIds
    const text = Ts(s => s.documentsScreen.docNames)
    switch (documentType) {
        case confirms: return doc.symbol || doc.cusip || doc.referenceId || T(s => s.general.unknown)
        case statements: return text.statement(doc)
        case checks: return text.check(doc)
        case misc: return text.misc(doc)
        case taxes: return text.taxes(doc)
        default: return doc.referenceId || doc.name
    }
}
