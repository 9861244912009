import React from 'react';
import { getScaledImage } from './getScaledImage';

export const PlayIcon = ({ width, height, style }: { width?: number; height?: number; style?: React.CSSProperties }): JSX.Element => {
    const { height: _h, width: _w } = getScaledImage(52, 52, width, height);

    return (
        <div style={{ ...style }}>
            <svg width={_w} height={_h} viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.6106 14.934C20.6134 13.6631 17.9999 15.0977 17.9999 17.465V34.535C17.9999 36.9023 20.6134 38.3369 22.6106 37.066L36.0227 28.531C37.8752 27.3521 37.8752 24.6479 36.0227 23.469L22.6106 14.934Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2 26C2 12.7452 12.7452 2 26 2V2C39.2548 2 50 12.7452 50 26V26C50 39.2548 39.2548 50 26 50V50C12.7452 50 2 39.2548 2 26V26Z'
                    stroke='white'
                    strokeWidth='3'
                />
            </svg>
        </div>
    );
};
