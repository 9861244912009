// @ts-strict-ignore
import { Typography } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from '../..';
import DeepAnalysisPreview from 'phoenix/assets/images/deepAnalysisPreview.png';
import FloatingIcons from 'phoenix/assets/images/FloatingIcons.png';
import NewsPreview from 'phoenix/assets/images/newsPreview.png';
import TradingViewPreview from 'phoenix/assets/images/tradingView.png';
import AlertsPreview from 'phoenix/assets/images/alertsPreview.png';
import { useShowPremiumUpgradeModal } from 'hooks/UseShowPremiumUpgradeModal';
import { LoadImagesAsync } from 'phoenix/util';
import { useText } from 'phoenix/hooks/UseText';
import MiPremiumPreview from 'phoenix/assets/images/miPremiumPreview.jpeg';
import { ShiningWrapper } from '../../ShiningWrapper';
import { SnexButton } from '../../SnexButton/SnexButton';
import { AnimatedModal } from '../AnimatedModal';
import style from './style.module.scss';
import { ProductCode } from 'phoenix/constants/PremiumProducts';
import { Routes } from 'util/Routes';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useTelemetry } from 'hooks/UseTelemetry';
import { Link } from 'react-router-dom';

export interface ContentSectionProps {
    src?: string;
    title: string;
    content: string;
    reversed?: boolean;
}

const ContentSection = React.memo(({ src, title, content, reversed }: ContentSectionProps) => {
    return (
        <Flex row align='center' className={style.contentRow} reverse={reversed}>
            <Flex align='center' justify={reversed ? 'flex-end' : 'flex-start'} style={{ flex: 1 }}>
                <div className={`${style.contentImageWrapper} ${reversed ? style.imageReveresed : style.imageRegular}`}>
                    {!!src && <img alt={title} src={src} style={{ width: '100%', minHeight: '100%' }} />}
                </div>
            </Flex>
            <Flex row align='flex-start' className={style.contentTextSection} justify='center'>
                <Flex column justify='center' style={{ textAlign: 'left' }}>
                    <Typography className={style.contentHeader} style={{ marginBottom: 12 }} variant='h3'>
                        {title}
                    </Typography>
                    <Typography style={{ lineHeight: 1.5 }} variant='body2'>
                        {content}
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
    );
});

const ModalInnerContent = TelemetryProvider(() => {
    const LogEvent = useTelemetry();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [showFloatingAction, setShowFloatingAction] = useState(false);
    const text = useText((s) => s.premiumModal);
    const [premiumModalOpenForProduct, setShowPremiumModal] = useShowPremiumUpgradeModal();

    const handleUpgradeClick = async () => {
        LogEvent('Elected to Upgrade', { product: premiumModalOpenForProduct });
        setShowPremiumModal(false);
    };

    let scrollListener = null;
    const scrollAmt = 15;

    const scrollDown = (parentElem: HTMLElement) => {
        try {
            parentElem.scrollTop += scrollAmt;
        } catch (e) {
            console.error(e);
        }
    };

    const scrollUp = (parentElem: HTMLElement) => {
        if (parentElem) parentElem.scrollTop -= scrollAmt;
    };

    const handleScroll = () => {
        const elem = document.getElementById('upgrade-content-section');
        const parentElem = document.getElementById('upgrade-foreground');
        if (!showFloatingAction && parentElem.scrollTop >= elem.offsetTop) {
            setShowFloatingAction(true);
        } else if (showFloatingAction && parentElem.scrollTop < elem.offsetTop) {
            setShowFloatingAction(false);
        }
    };

    const handleArrowKeys = (e: KeyboardEvent) => {
        const parentElem = document.getElementById('upgrade-foreground');
        switch (e.code) {
            case 'ArrowDown':
                scrollDown(parentElem);
                break;
            case 'ArrowUp':
                scrollUp(parentElem);
                break;
        }
    };

    useEffect(() => {
        const headerSection = document.getElementById('preferredHeaderSection');
        LogEvent('Premium Upgrade Modal Opened');
        setHeaderHeight(headerSection.clientHeight);
        return () => {
            LogEvent('Premium Upgrade Modal Closed');
        };
    }, []);

    useEffect(() => {
        const scrollingElem = document.getElementById('upgrade-foreground');
        if (window) {
            window.addEventListener('keydown', handleArrowKeys);
            scrollListener = scrollingElem.addEventListener('scroll', handleScroll);
            return () => {
                scrollListener?.removeEventListener('scroll', handleScroll);
            };
        }
    }, [window, showFloatingAction]);

    const PremiumAddOns: ContentSectionProps[] = useMemo(
        () => [
            {
                title: text.deepAnalysis.title,
                content: text.deepAnalysis.content,
                reversed: false,
                src: DeepAnalysisPreview
            },
            {
                title: text.multiCharts.title,
                content: text.multiCharts.content,
                reversed: true,
                src: TradingViewPreview
            },
            {
                title: text.alerts.title,
                content: text.alerts.content,
                reversed: false,
                src: AlertsPreview
            },
        ],
        []
    );

    return (
        <Flex column className={style.wrapper}>
            <Flex className={style.innerWrapper}>
                <Flex className={style.backgroundLayer}>
                    <Flex className={style.bgHeaderSection} justify='center' style={{ height: headerHeight + 30 }}>
                        <img alt='Floating Icons' src={FloatingIcons} />
                    </Flex>
                </Flex>
                <Flex column align='center' className={style.foregroundLayer} id='upgrade-foreground' style={{ visibility: !headerHeight ? 'hidden' : 'visible' }}>
                    <Flex center column className={style.headerSection} id='preferredHeaderSection'>
                        <Typography className={`${style.headerItem} ${style.headerSecondary}`} variant='caption'>
                            {text.ready}
                        </Typography>
                        <Typography className={`${style.headerItem}`} style={{ fontWeight: 800, letterSpacing: 2 }} variant='h2'>
                            {text.unlock}
                        </Typography>
                        <Link to={Routes.premium(premiumModalOpenForProduct as ProductCode)}>
                            <SnexButton
                                className={`${style.headerItem}`}
                                eventTag='Hero Premium Upgrade'
                                onClick={() => handleUpgradeClick()}
                                flavor={'secondary-solid'}
                                style={{ maxWidth: '300px' }}
                            >
                                <ShiningWrapper>
                                    <span style={{ marginRight: 10 }}>{text.upgradeToday}</span>
                                    <ArrowRightAlt style={{ paddingTop: 2 }} />
                                </ShiningWrapper>
                            </SnexButton>
                        </Link>
                    </Flex>
                    <Flex column align='center' className={style.contentSection} id='upgrade-content-section'>
                        <Flex
                            center
                            className={`${style.upgradeContentHeader} ${!!showFloatingAction && style.upgradeHeaderSticky}`}
                            id='upgrade-content-header'
                            style={{ width: '107%' }}
                        >
                            {showFloatingAction ? (
                                <Link to={Routes.premium(premiumModalOpenForProduct as ProductCode)}>
                                    <SnexButton
                                        className={`${style.headerItem} ${style.upgradeButtonSticky}`}
                                        flavor={'secondary-solid'}
                                        eventTag='Floating Premium Upgrade'
                                        onClick={() => handleUpgradeClick()}
                                        style={{ maxWidth: '300px', padding: '12px', height: '48px' }}
                                    >
                                        <ShiningWrapper>
                                            <span style={{ marginRight: 10 }}>{text.upgradeToday}</span>
                                            <ArrowRightAlt style={{ paddingTop: 2 }} />
                                        </ShiningWrapper>
                                    </SnexButton>
                                </Link>
                            ) : (
                                <Typography className={style.contentHeader} variant='h3'>
                                    {text.addTheseTools}
                                </Typography>
                            )}
                        </Flex>
                        <Flex column className={style.addOns}>
                            {PremiumAddOns.map((item, key) => (
                                <ContentSection content={item.content} key={key} reversed={item.reversed} src={item.src} title={item.title} />
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}, TelemetryCategories.premium);

export const PremiumUpgradeModal = React.memo(() => {
    const [isOpen, setIsOpen] = useShowPremiumUpgradeModal();

    useEffect(() => {
        LoadImagesAsync([DeepAnalysisPreview, TradingViewPreview, NewsPreview, AlertsPreview, FloatingIcons]);
    }, []);

    return (
        <AnimatedModal open={!!isOpen} onClose={() => setIsOpen(false)}>
            <ModalInnerContent />
        </AnimatedModal>
    );
});
