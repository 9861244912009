// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { setSelectedScreenId, useFullyPaidLendingStore } from "../../Store"
import { FPL_Agreements_Form } from "./Form"
import React from "react"
import { getFPLAgreementErrors } from "./Validation"


export const fpl_agreements_step: OnboardingStep = {
    label: "Agreements",
    isComplete: () => getFPLAgreementErrors().length < 1,
    isEnabled: () =>  !!useFullyPaidLendingStore?.getState()?.data?.selectedAccount,
    isCurrent: () => ['fplAgreements'].includes(useFullyPaidLendingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'fplAgreements'
}

export const fpl_agreements_nextButton: OnboardingButton = {
    label: "Accept and Submit",
    onClick: () => setSelectedScreenId('fplSubmitting'),
    isPrimary: true,
    isEnabled: () => getFPLAgreementErrors()?.length < 1
}

export const fpl_agreements_prevButton: OnboardingButton = {
    label: "Previous",
    onClick: () => setSelectedScreenId('fplDisclosures'),
    isPrimary: false,
    isEnabled: () => true
}

export const fpl_agreements_screen: OnboardingScreen = {
    id: "fplAgreements",
    validate: getFPLAgreementErrors,
    buttons: [fpl_agreements_prevButton, fpl_agreements_nextButton],
    title: T(t => t.fplOnboarding.agreements.title),
    subtitles: [T(t => t.fplOnboarding.agreements.requiredClick)],
    body: <FPL_Agreements_Form />
}