import { isNotANumber } from 'phoenix/util/FormatNumber';
import { LastIndexOfAny } from './ArrayMutations';

export const Clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

// e.g.,
// "57" => 57
// "57.4" => 57.4
// "57.43" => 57.43
// "57.431" => 57.431
export const ParseCents = (src: string): number => {
    if (!/\./.test(src)) return parseInt(src);
    const dotIdx = LastIndexOfAny(src, ['.']);
    const mantissaLength = src.length - (dotIdx + 1);
    if (mantissaLength <= 0) return parseInt(src);
    return parseInt(src.replace(/\./g, '')) / 10 ** mantissaLength;
};

const Round = (value: number, decimalPlaces: number) => +value.toFixed(decimalPlaces);

const GetPositiveSuffix = (decimal: string) => {
    const exponentialSplit = decimal.split('e+');
    let suffix = '';
    const max = +exponentialSplit[1] - (exponentialSplit[0].includes('.') ? exponentialSplit[0].split('.')[1].length : 0);
    for (let i = 0; i < max; i++) suffix += '0';
    return suffix;
};

const GetNegativePrefix = (exponentialSplit: string[]) => {
    let prefix = '0.';
    for (let i = 0; i < +exponentialSplit[1] - 1; i++) prefix += '0';
    return prefix;
};

export const ExponentToDecimal = (exponential: string) => {
    let decimal = exponential;

    if (decimal.includes('e+')) {
        const exponentialSplit = decimal.split('e+');
        const postfix = GetPositiveSuffix(decimal);
        decimal = exponentialSplit[0].replace('.', '') + postfix;
    }

    if (decimal.includes('e-')) {
        const exponentialSplit = decimal.split('e-');
        const prefix = GetNegativePrefix(exponentialSplit);
        decimal = prefix + exponentialSplit[0].replace('.', '');
    }
    return decimal;
};

const GetDecimalPlacesFromString = (src: string) => src.replace(/^\d+\./, '').replace(/0*$/, '').length;

// TODO -- do this more efficiently with match
export const GetDecimalPlaces = (src: number): number => {
    const str = src?.toString().toLowerCase() || '';

    // Handles scientific notiation
    if (str.includes('e')) {
        const converted = ExponentToDecimal(str);
        return GetDecimalPlacesFromString(converted);
    }

    if (!str.includes('.')) return 0;
    return GetDecimalPlacesFromString(str);
};

export const GetRemainder = (value: number, coefficient: number) => value % coefficient;

export const NumberIsMultiple = (value: number, coefficient: number, round?: boolean) => {
    // Adjust for floating-point precision issues
    const precision = Math.pow(10, -Math.floor(Math.log10(coefficient)) + 1);
    const adjustedValue = Math.round(value / coefficient * precision) / precision;
    return Math.abs(adjustedValue - Math.round(adjustedValue)) < 1 / precision;
};

export const AdjustNumberByMultiple = (value: number, coefficient: number) => {
    const decimalPlaces = GetDecimalPlaces(coefficient);

    const roundedValue = Round(value, decimalPlaces);

    const isMultiple = NumberIsMultiple(roundedValue, coefficient, true);

    if (isMultiple) return roundedValue;

    const remainder = Round(roundedValue % coefficient, decimalPlaces);

    return Round(roundedValue - remainder, decimalPlaces);
};

export const SetMaximumDecimalPlaces = (value: number, places: number) => {

    // Note: using math (e.g., multiplying by 10^p and then dividing) causes floating pt errors (last decimal place rounded down / up)

    // Handle nulls, etc.
    if (isNotANumber(value)) return 0;

    // TODO -- Use a high-precision library of some kind
    let numStr = value.toString() // Might output scientific notation
    if (numStr.includes('e')) numStr = value.toFixed(places) // Might round

    // Cut up the number string into int and decimal parts
    const [int, dec] = numStr.split('.');
    if (isNaN(Number(int))) return 0;
    if (!dec) return value;
    const decimalTrimmed = dec.slice(0, places);
    const result = Number(`${int}.${decimalTrimmed}`);
    return result;

};
