import { Skeleton } from '@mui/material';
import React from "react";

interface LoadingBarProps {
    style?: React.CSSProperties
}

export const LoadingBar = React.memo((props: LoadingBarProps) => {

    return (
        <Skeleton animation='wave' style={{ width: '100%', height: 15, ...props.style }} />
    )

})