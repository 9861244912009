import { Flex } from 'components/Flex';
import React from 'react';
import { useOneProNetworkStore } from 'phoenix/stores/OneProNetworkStore/OneProNetworkStore';
import { Chip, Skeleton, Stack, Typography } from '@mui/material';
import { Post } from 'phoenix/stores/OneProNetworkStore/OneProNetworkTypes';
import { useColors } from 'hooks/UseColors';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { AuthorAvatar } from '../avatar/AuthorAvatar';
import { format, formatDistance } from "date-fns";

export const DeskAlerts = (): JSX.Element => {
    const { data } = useOneProNetworkStore();
    return (
        <Flex column fullWidth style={{ gap: 16 }}>
            <Flex column fullWidth style={{ gap: 16, padding: `8px 12px` }}>
                <Typography variant={'h5'}>Latest Desk Notes</Typography>
                <MediaPost type={'desk'} post={data?.deskAlerts[0]} />
            </Flex>
            <Flex column fullWidth style={{ gap: 16, padding: `8px 12px` }}>
                <Typography variant={'h5'}>What Experts Are Saying</Typography>
                {data?.feedPosts?.map((p) => (
                    <MediaPost key={p.id} type={'default'} post={p} />
                ))}
            </Flex>
        </Flex>
    );
};

export const GetAsOf = (d: string): string => {
    const end = new Date().getTime();
    const start = new Date(d).getTime();

    const diffSeconds = (end - start) / 1000;
    if (diffSeconds < 60) return 'now';
    const diffMins = diffSeconds / 60;
    if (diffMins < 60) return `${Math.round(diffMins)} min ago`;
    const diffHrs = diffMins / 60;
    if (diffHrs < 24) return `${Math.round(diffHrs)} hrs ago`;
    const diffDays = diffHrs / 24;
    if(diffDays < 365) return `${Math.round(diffDays)}d`;
    const diffYears = diffDays / 365
    return `${Math.round(diffDays)}y`;
};

export const MediaPost = ({ type, post, hideAvatar, hideTags }: { type: 'desk' | 'default'; post: Post; hideTags?: boolean; hideAvatar?: boolean }): JSX.Element => {
    const colors = useColors();

    return (
        <Flex
            column
            fullWidth
            style={{
                padding: type === 'default' ? '12px 0' : '12px',
                gap: 8,
                borderRadius: 17,
                backgroundColor: type === 'desk' ? colors.cardSecondaryBackgroundColor : 'transparent'
            }}
        >
            <Flex row fullWidth style={{ gap: 8 }}>
                {type !== 'desk' ? (
                    <Link to={Routes.oneProNetworkAuthor(post?.author?.id)}>
                        {!hideAvatar && <AuthorAvatar url={post?.author?.imageUri} borderColor={colors.selectListSelectedItemColor} />}
                    </Link>
                ) : null}
                <Flex column>
                    {post?.author?.name ? (
                        <Link to={Routes.oneProNetworkAuthor(post?.author?.id)}>
                            <Flex row fullWidth align={'center'} style={{ fontSize: 12, gap: 8 }}>
                                <div>{post?.author?.name}</div>
                                {post?.created ? (
                                    <>
                                        <div>·</div>
                                        <div style={{ fontSize: 10 }}>{GetAsOf(post?.created)}</div>
                                    </>
                                ) : null}
                            </Flex>
                        </Link>
                    ) : (
                        <Skeleton width={90} />
                    )}
                    <Flex row fullWidth style={{ fontSize: 14, marginBottom: 12 }}>
                        {post?.message || <Skeleton width={'100%'} />}
                    </Flex>
                    {post?.tags?.length && !hideTags && (
                        <Stack direction={'row'} spacing={1}>
                            {post?.tags?.map((t, tidx) => (
                                <Chip key={tidx} style={{ fontSize: 10, height: 16, borderRadius: 8 }} label={t} />
                            ))}
                        </Stack>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
