import { ApiData, ApiOperation } from '../../../models'
import { AlertField } from './AlertField'
import { AlertRule } from './AlertRule'

export class AlertsState {
    fields: ApiData<AlertField[]>;
    rules: ApiData<AlertRule[]>;
    createRuleOperation: ApiOperation;
    updateRuleOperation: ApiOperation;
    toggleRuleOperation: ApiOperation;
    deleteRuleOperation: ApiOperation;

    constructor () {
        this.fields = new ApiData([])
        this.rules = new ApiData([])
        this.createRuleOperation = new ApiOperation()
        this.updateRuleOperation = new ApiOperation()
        this.toggleRuleOperation = new ApiOperation()
        this.deleteRuleOperation = new ApiOperation()
    }
}
