import { AssetClass } from "phoenix/models/AssetClasses/AssetClass";
import { EquitiesAssetClass } from "phoenix/models/AssetClasses/EquitiesAssetClass";

export const EtfAssetClass: AssetClass = {

    ...EquitiesAssetClass,

    type: 'etf',
    algoliaTypes: ['et', 'cef'],

}