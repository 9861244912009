export const AccountStatuses: { [key: string]: number } = {
    created: 0,
    pending: 1,
    submit: 2,
    submitting:3,
    submitted: 4,
    queued: 5
}

export const CompletedOnboStatuses = new Set([
    AccountStatuses.submitted, 
    AccountStatuses.submitting, 
    AccountStatuses.submit
])

export const WaitingOnboStatuses = new Set([
    AccountStatuses.queued,
    AccountStatuses.pending
])

export type OnboardingScreenStage = 'application' | 'loading' | 'submitted' | 'failed' | 'queued' | 'pending'