// @ts-strict-ignore
import { AppColorTheme } from './ColorVariants/AppColorTheme';
import shared from './shared';

export const TmpDarkTheme: AppColorTheme = {
    _name: 'DARK',
    disclosureTextColor: '#a7a7a7',
    areaChartNegativeColor: '#8f172769',
    areaChartPositiveColor: '#11792d63',
    blurredAdornmentColor: '#a9a9a9',
    blurredBackgroundColor: '#000000d5',
    boxShadow: 'rgba(51, 96, 148, 0.09)',
    boxShadowLight: 'rgba(0,0,0,0)',
    cardBackgroundColor: '#1c1c1c',
    cardLighterBackgroundColor: '#333333',
    cardTitleBackgroundColor: '#151515',
    cardSecondaryBackgroundColor: '#272727',
    cashAndEquivsWrapperHover: 'rgb(19, 58, 15)',
    cellHoverBackgroundColor: 'rgba(57, 138, 230, 0.2)',
    chartVerticalCrosshairColor: '#474747',
    colorOverlay: 'rgba(0,0,0,0.7)',
    cryptosColor: '#883FF6',
    cryptosLighterColor: '#883FF61A',
    defaultOnboardingButtonBackgroundColor: '#000',
    defaultOnboardingButtonBackgroundHoverColor: '#111',
    defaultOnboardingButtonColor: 'white',
    disabledButtonText: 'rgba(255,255,255,0.3)',
    disclosuresListButtonHoverColor: 'rgba(255, 255, 255, 0.08)',
    dividerColor: 'rgb(155, 155, 155, 0.178)',
    donutChartSegments: [
        '#2fcd8e', // Minty green
        '#ea9d1e', // Gold
        '#2b69ff', // Blue
        '#5E2CA8', // Roman's Purple
        '#AFF3DC', // Light Minty Green
        '#f9379d', // Pink
        '#666666', // Devil's Food Gray
        '#f8342e', // Red
        '#DACE50', // Golden Graham
        '#89C6FF', // Light Blue
        '#D8FF6D' // Light Green
    ],
    greenLightOpaque: '#1d2b1e',
    orangeLightOpaque: '#31291d',
    fadedTextColor: '#e8e8e8',
    focusedAdornmentColor: '#fff',
    futuresBackdropColor: '#28bcd63d',
    futuresColor: '#28BBD6',
    equitiesBackdropColor: '#0073db40',
    equitiesColor: '#0071DB',
    generalTextColor: '#ffffff',
    generalTextReverseColor: '#000',
    greyBackgroundColor: '#1c1c1c',
    holdingsGold: 'rgb(233, 156, 35)',
    holdingsGoldBackground: 'rgba(233, 156, 35, .2)',
    inputBorderActiveColor: 'rgba(213,213,213,0.438)',
    inputBorderColor: 'rgba(213,213,213,0.158)',
    mainBackgroundColor: '#000',
    mainBackgroundReverseColor: '#fff',
    negativeValueColor: 'rgb(212,38,43)',
    negativeValueLighterColor: 'rgba(178,41,46,0.16)',
    negativeValueChartLighterColor: 'rgba(212,38,43, .3)',
    neutralValueColor: 'rgb(218, 206, 80)', // Golden Graham
    neutralValueLighterColor: 'rgba(218, 206, 80, 0.16)',
    moneyGreenLight: 'rgba(19, 58, 15, 0.701)',
    moneyGreenLighter: 'rgba(19, 58, 15, 0.623)',
    notAllowedBackgroundColor: 'rgba(57, 138, 230, 0.2)',
    notAllowedBorderColor: 'rgba(57, 138, 230, 1.0)',
    notAllowedTextColor: '#f7fafc',
    onboardingButtonBackground: 'rgb(19,95,24)',
    onboardingButtonColor: 'white',
    onboardingCardBackground: '#111111',
    onboardingDisabledButtonBackground: '#444',
    onboardingDisabledButtonColor: '#777',
    optionsLabelBlue: '#398AE6',
    optionsLabelBlueLight: '#398AE61F',
    optionsLabelCoral: '#E45F42',
    optionsLabelCoralLight: '#E45F421F',
    optionsLabelOrange: '#E99C23',
    optionsLabelOrangeLight: '#E99C231F',
    positiveValueColor: 'rgb(3,201,59)',
    positiveValueDarkerColor: 'rgb(0,150,8)',
    positiveValueChartLighterColor: 'rgba(3,201,59, .3)',
    positiveValueLighterColor: 'rgba(2,179,52,0.16)',
    primaryItemBackdropColor: 'rgba(50, 103, 168, 0.27)',
    primaryItemBackdropOpaqueColor: 'rgba(34, 48, 65, 1)',
    primaryItemColor: 'rgb(57, 138, 230)',
    primaryItemColorTints: new Array(10)
        .fill(null)
        .map((_, i) => i !== 0 && `rgba(57, 138, 230, ${i === 1 ? 1 : `.${i}`})`)
        .filter((x) => x),
    primaryItemLightColor: '#345f948f',
    requiredBorderColor: '#7f1619',
    scrollbarColor: 'rgb(71,71,71)',
    secondaryButtonBackgroundColor: '#f7fafc2f',
    secondaryButtonBackgroundHoverColor: '#f7fafc2f',
    secondarySolidButtonBackgroundColor: '#f7fafc',
    secondarySolidButtonBackgroundHoverColor: '#f7fafc',
    segmentedControlActiveColor: '#336094',
    segmentedControlBackgroundColor: '#41516373',
    selectedOnboardingButtonBackgroundColor: 'rgb(52, 96, 148)',
    selectedOnboardingButtonBackgroundHoverColor: '#346094',
    selectedOnboardingButtonColor: 'white',
    selectedOnboardingButtonHoverColor: 'white',
    selectListItemColor: 'rgba(51, 96, 148, 0.1)',
    selectListSelectedItemColor: '#346094',
    stickyCard: '#1a3829',
    fundingSourcesBackgroundColor: 'rgba(213,213,213,0.158)',
    ...shared
};
