import axios from 'axios'
import { SnexAxios } from 'phoenix/stores/AxiosHelpers'
import { Urls } from '../constants'
import { ErrorLogger } from 'phoenix/util/ErrorLogger'

interface WrappedJwtResponse { jwt: string }

export const VulcanAuth = {
    UpgradeSecureAuthToken: async (jwt: string) => {
        try {
            const wrapped: WrappedJwtResponse = await SnexAxios.ApiPost<WrappedJwtResponse>(Urls.authentication.vulcanUpgrade(), {data: { jwt }}).run()
            return wrapped
        } catch (e: any) {
            ErrorLogger.RecordError(e, 'Vulcan Token Upgrade Failed', { tags: { category: 'Token Refresh' } })
        }
    }
}
