// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { MarketState } from '../models/Market/MarketState'

const check = GroupNameChecker([Actions.Market])
export const MarketReducer = (state: MarketState = new MarketState(), action: ReduxAction): MarketState => {
    if (!check(action)) return state

    switch (action.type) {
        case Actions.Market.GetHolidays.Loading: return { ...state, holidays: state.holidays.startLoading() }
        case Actions.Market.GetHolidays.Success: return { ...state, holidays: state.holidays.succeeded(action.data) }
        case Actions.Market.GetHolidays.Failure: return { ...state, holidays: state.holidays.failed(action.error) }

        case Actions.Market.UpsertHoliday.Loading: return { ...state, upsertHoliday: state.upsertHoliday.startLoading() }
        case Actions.Market.UpsertHoliday.Success: return { ...state, upsertHoliday: state.upsertHoliday.succeeded() }
        case Actions.Market.UpsertHoliday.Failure: return { ...state, upsertHoliday: state.upsertHoliday.failed(action.error) }

        case Actions.Market.DeleteHoliday.Loading: return { ...state, deleteHoliday: state.deleteHoliday.startLoading() }
        case Actions.Market.DeleteHoliday.Success: return { ...state, deleteHoliday: state.deleteHoliday.succeeded() }
        case Actions.Market.DeleteHoliday.Failure: return { ...state, deleteHoliday: state.deleteHoliday.failed(action.error) }

        case Actions.Market.GetOpenStatus.Loading: return { ...state, status: state.status.startLoading() }
        case Actions.Market.GetOpenStatus.Success: return { ...state, status: state.status.succeeded(action.data) }
        // case Actions.Market.GetOpenStatus.Failure: return { ...state, status: state.status.failed(action.error, true) }

        default: return state
    }
}
