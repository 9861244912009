import { ApiPosition } from 'phoenix/redux/models';

export type SearchPositionsProps = {
    accountNumber: string;
    positions: ApiPosition[];
    qsi: string;
};

// TODO: Move old redux position selectors here
// https://dev.azure.com/intlquadrant/SNEX1/_workitems/edit/115353

export function SearchPositions({ accountNumber, positions = [], qsi }: SearchPositionsProps): ApiPosition[] {
    let results = [...positions];

    // Filter to accountNumber if it exits.
    if (accountNumber) results = results.filter((p) => p.accountNumber === accountNumber);

    // Filter to symbol if it exists.
    if (qsi)
        results = results.filter((p) => {
            // This is because of Positons V1, Replace Eventually
            if (p?.secMasterOptionSymbol) return p?.secMasterOptionSymbol?.replace(/\s/g, '') === qsi.replace(/\s/g, '');
            else return p.symbol === qsi;
        });

    return results;
}
