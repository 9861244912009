import { ApiData } from '../../../models'
import { EtfSecurity } from './ETFSecurity'

export class SectorSecuritiesState {
    byName: { [name: string]: SectorSecuritiesSubstate }

    constructor () {
        this.byName = {}
    }
}

export class SectorSecuritiesSubstate {
    etfs: ApiData<EtfSecurity[]>;

    constructor () {
        this.etfs = new ApiData<EtfSecurity[]>([])
    }
}
