// @ts-strict-ignore
import { ApiData, ApiOperation, ReduxAction } from '../../models'
import { Trim } from '../../util/Trim'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AccountsState, ProjectedIncomeV2 } from '../models'

const permitted = GroupNameChecker([Actions.Accounts, Actions.MediantSSO])
export const AccountsReducer = (state: AccountsState = new AccountsState(), action: ReduxAction): AccountsState => {
    if (!permitted(action)) return state

    const withAccountNumberUpdate = (subState: string, update: (handle: ApiData<any> & ApiOperation) => any, initialValue: any = null): AccountsState => {
        const existing = state.byNumber[action.subject] ? (state.byNumber[action.subject][subState] || new ApiData<any>(initialValue)) : new ApiData<any>(initialValue)

        return {
            ...state,
            byNumber: {
                // TODO -- At some point, we'll want to trim this list down so it doesn't get unmanageably large
                ...state.byNumber,
                [action.subject]: {
                    ...state.byNumber[action.subject],
                    [subState]: update(existing)
                }
            }
        }
    }

    const { GetAll } = Actions.Accounts

    switch (action.type) {
        case GetAll.Loading: return { ...state, all: state.all.startLoading(state.all.data) }
        case GetAll.Success: return {
            ...state,
            all: state.all.succeeded(action.data?.map(a => {
                // TODO -- Make below more generic for all security types using the QualifiedId util
                // If it's a futures account, strip the prefix from name and nickname
                const isFutures = a.accountNumber.match(/^F:/)
                const name = isFutures ? a.name.replace(/^F:/g, '') : a.name
                const nickname = isFutures ? a.nickname.replace(/^F:/g, '') : a.nickname

                return { ...a, effectiveName: nickname || name }
            }))
        }
        case GetAll.Failure: return { ...state, all: state.all.failed(action.error) }

        case Actions.Accounts.GetProjectedIncome.Loading: return withAccountNumberUpdate('projectedIncome', u => u.startLoading(u.data))
        case Actions.Accounts.GetProjectedIncome.Success: return withAccountNumberUpdate('projectedIncome', u => u.succeeded(action.data))
        case Actions.Accounts.GetProjectedIncome.Failure: return withAccountNumberUpdate('projectedIncome', u => u.failed(action.error))

        case Actions.Accounts.GetProjectedIncomeV2.Loading: return withAccountNumberUpdate('projectedIncomeV2', u => u.startLoading(u.data))
        case Actions.Accounts.GetProjectedIncomeV2.Success: {
            // Add IDs
            const makeMonthYearId = (my: { monthBase1: number, year: number }) => `${my.monthBase1 + (my.year * 100)}`
            const incomePayload = action.data as ProjectedIncomeV2
            incomePayload.portfolioProjection = incomePayload.portfolioProjection.map(p => ({ ...p, monthYearId: makeMonthYearId(p) }))
            incomePayload.securityProjections = incomePayload.securityProjections?.map(p => {
                p.projections = p.projections.map(x => ({ ...x, monthYearId: makeMonthYearId(x) }))
                return p
            })
            return withAccountNumberUpdate('projectedIncomeV2', u => u.succeeded(incomePayload))
        }
        case Actions.Accounts.GetProjectedIncomeV2.Failure: return withAccountNumberUpdate('projectedIncomeV2', u => u.failed(action.error))

        case Actions.Accounts.UpdateNickname.Loading: return withAccountNumberUpdate('rename', u => u.startLoading(u.data))
        case Actions.Accounts.UpdateNickname.Success: return {
            ...withAccountNumberUpdate('rename', u => u.succeeded()),
            all: state.all.succeeded(state.all.data?.map(a => a.accountNumber === action.subject ? ({ ...a, nickname: Trim(action.passthrough.newName) || a.name, effectiveName: Trim(action.passthrough.newName) || a.name }) : a))
        }
        case Actions.Accounts.UpdateNickname.Failure: return withAccountNumberUpdate('rename', u => u.failed(action.error))

        case Actions.Accounts.GetMaxNumberOfFuturesAccounts.Loading:
            return { ...state, maxFutures: state.maxFutures.startLoading(state.maxFutures.data) }
        case Actions.Accounts.GetMaxNumberOfFuturesAccounts.Success:
            return { ...state, maxFutures: state.maxFutures.succeeded(action.data) }
        case Actions.Accounts.GetMaxNumberOfFuturesAccounts.Failure:
            return { ...state, maxFutures: state.maxFutures.failed(action.error) }

        case Actions.Accounts.GetFuturesPendingAccounts.Loading:
            return { ...state, pendingFuturesAccounts: state.pendingFuturesAccounts.startLoading(state.pendingFuturesAccounts.data) };
        case Actions.Accounts.GetFuturesPendingAccounts.Success:
            return {...state, pendingFuturesAccounts: state.pendingFuturesAccounts.succeeded(action.data), all: state.all.succeeded([...new Map([...state.all.data, ...action.data].map(a => [a.accountNumber, a])).values()])}
        case Actions.Accounts.GetFuturesPendingAccounts.Failure:
            return {...state, pendingFuturesAccounts: state.pendingFuturesAccounts.failed(action.error)}

        case Actions.MediantSSO.GetUrl.Loading: return withAccountNumberUpdate('mediantSSO', u => u.startLoading(u.data)); 
        case Actions.MediantSSO.GetUrl.Success: return withAccountNumberUpdate('mediantSSO', u => u.succeeded(action.data)); 
        case Actions.MediantSSO.GetUrl.Failure: return withAccountNumberUpdate('mediantSSO', u => u.failed(action.error)); 

        default: return state
    }
}
