// @ts-strict-ignore
const pollingIntervals: { [key: string]: any } = {}
let countHandler = null
let countHandlerInterval = null

export const ReduxPollingStart = (key: string, action: () => {}, intervalSeconds: number) => {
    return async (dispatch: any) => {
        if (pollingIntervals[key]) return
        pollingIntervals[key] = setInterval(() => {
            dispatch(action())
        }, intervalSeconds * 1000)
    }
}

export const ReduxPollingStop = (key: string) => {
    return async (dispatch: any) => {
        if (pollingIntervals[key]) {
            clearInterval(pollingIntervals[key])
            delete pollingIntervals[key]
        }
    }
}

const reportIntervalCount = () => {
    if (countHandler) countHandler(Object.keys(pollingIntervals))
}

export const OnPollingCount = (handler: (count: string[]) => any) => {
    countHandler = handler
    countHandlerInterval = setInterval(reportIntervalCount, 3000)
}
