// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._71z1ScaxMmT-4eC726nfp{padding:15px 0}._71z1ScaxMmT-4eC726nfp .Oy4AThGHKOlg86oDX5FmK{font-size:20px;font-weight:bold;line-height:normal}", ""]);
// Exports
exports.locals = {
	"account-summary-header": "_71z1ScaxMmT-4eC726nfp",
	"account-summary-header-text": "Oy4AThGHKOlg86oDX5FmK"
};
module.exports = exports;
