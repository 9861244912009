import { CallMade } from '@mui/icons-material';
import React from 'react';
import { Flex } from '..';
import { useColors } from 'hooks/UseColors';
import { Spinny } from '../Spinny';

export const ChangeBar = React.memo(
    ({ change, color, percent, spun, style }: { change: string; color: string; percent: string; spun: boolean; style?: React.CSSProperties }) => {
        const colors = useColors();
        return (
            <Flex
                inline
                row
                align='center'
                style={{ backgroundColor: color, borderRadius: 4, color: colors.white, padding: '2px 15px 2px 11px', fontWeight: 500, ...style }}
            >
                <Spinny spun={spun} targetDegrees={90}>
                    <CallMade style={{ fontSize: 15 }} />
                </Spinny>
                <span style={{ margin: '0 10px' }}>{percent}</span>
                {change}
            </Flex>
        );
    }
);
