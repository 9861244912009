export const LiveDataGroups = {
    AccountValuations: 'ACCOUNT_VALUATIONS',
    PortfolioValuations: 'PORTFOLIO_VALUATIONS',
    News: 'NEWS',
    Quotes: 'SECURITY_QUOTES',
    CryptoQuotes: 'CRYPTO_QUOTES',
    DelayedQuotes: 'SECURITY_QUOTES_DELAYED',
    Futures: 'DEFAULT',
    Options: 'OPTIONS',
    Messages: 'MESSAGES'
}
