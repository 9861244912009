import { useState, useEffect, useMemo } from 'react';
import { useDeactivateXStream } from '../../hooks/UseDeactivateXStream';
import { useSnexStore } from '../../hooks/UseSnexStore';
import { GlobalState } from '../../redux/GlobalState';
import { useXstreamStore } from '../useXstreamStore';
import { XstreamState } from '../XstreamState';

interface XstreamResult {
    price?: number;
}

export const useStreamAndSnapshot = <TData extends XstreamResult>(
    snapSelector: (x: GlobalState) => TData,
    streamSelector: (x: XstreamState) => TData,
    symbol: string,
    dataIsUsable?: (data: TData | undefined) => boolean,
    debug?: boolean,
    updateSnapshots?: boolean
): TData => {
    const deactivateStream = useDeactivateXStream();
    const _dataIsUsable = useMemo(() => {
        return dataIsUsable ? (v: TData | undefined) => dataIsUsable(v) : (v: TData | undefined) => !!v;
    }, [dataIsUsable]);

    const [init, setInit] = useState<TData | undefined>();
    const [streamSnap, setStreamSnap] = useState<TData>();

    useEffect(() => setInit(undefined), [symbol]);

    const snap = useSnexStore(init && !updateSnapshots ? undefined : snapSelector);

    useEffect(() => {
        if (_dataIsUsable(init) && !updateSnapshots) {
            if (debug) {
                // eslint-disable-next-line no-console -- Debug only
                console.log('>> Foregoing snap since init is already set, and updateSnapshots = false');
            }
            return;
        }
        if (JSON.stringify(snap) === JSON.stringify(init)) {
            if (debug) {
                // eslint-disable-next-line no-console -- Debug only
                console.log('>> Foregoing snap since init = snap');
            }
            return;
        }
        if (_dataIsUsable(snap)) setInit(snap);
    }, [_dataIsUsable, dataIsUsable, debug, init, snap, updateSnapshots]);

    const stream = useXstreamStore(streamSelector);

    useEffect(() => {
        if (deactivateStream && !streamSnap) setStreamSnap(stream);
    }, [deactivateStream, stream, streamSnap]);

    const streamIsUsable = useMemo(() => _dataIsUsable(stream), [_dataIsUsable, stream]);

    const result = useMemo(() => {
        if (deactivateStream && streamIsUsable) return spread(init, streamSnap);
        else if (init && stream && streamIsUsable) return spread(init, stream);
        else if (streamIsUsable) return stream || init;
        else return init;
    }, [streamIsUsable, deactivateStream, init, stream, streamSnap]);

    useEffect(() => {
        if (debug) {
            // eslint-disable-next-line no-console -- Debug only
            console.log(`deactivated=${deactivateStream}; init?=${!!init}; stream?=${!!stream}; streamSnap?=${!!streamSnap}; price=${result?.price}`);
        }
    }, [debug, deactivateStream, stream, streamSnap, result?.price, init, _dataIsUsable, dataIsUsable, updateSnapshots]);

    return result as TData;
};

// Filter out falsy values so they don't override potentially more relevant ones
const spread = (prev: XstreamResult | undefined = {}, next: XstreamResult | undefined = {}): XstreamResult => {
    const filtered = Object.keys(next).reduce((a, c) => {
        return (!!next[c as keyof XstreamResult] as boolean) ? { ...a, [c]: next[c as keyof XstreamResult] } : a;
    }, {});

    return { ...prev, ...filtered };
};
