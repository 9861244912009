import { OnboardingInputError } from "phoenix/onboarding/OnboardingTypes";
import { useFullyPaidLendingStore } from "../../Store";
import { T } from "phoenix/assets/lang/T";

export const FPLDisclosuressAreValid = () => getFPLDisclosuresErrors()?.length < 1
export const getFPLDisclosuresErrors = () => {
    const _errors = []  as OnboardingInputError[]
    const data = useFullyPaidLendingStore.getState().data

    if(!data.charAndRisks.accepted) _errors.push({key: 'charAndRisks', error: T(t => t.sharedOnboarding.agreementRequired('Fully Paid Lending Important Characteristics and Risks Agreement'))})

    return _errors.filter(e => !!e);
}