import { ApiData, ApiOperation } from '../../../models'
import { SystemStatus } from '../System/SystemStatus'
import { SearchedUserInfo } from './SearchedUserInfo'
import { SymbologyOverride } from './SymbologyOverride';

export class AdminState {
    searchedUser: ApiData<SearchedUserInfo>;
    systemStatus: ApiData<SystemStatus[]>;
    bypassEnrollementOperation: ApiOperation;
    demoteUserToFreeOperation: ApiOperation;
    symbologyOverrides:  ApiData<SymbologyOverride[]>;

    constructor () {
        this.symbologyOverrides = new ApiData([])
        this.systemStatus = new ApiData([])
        this.searchedUser = new ApiData()
        this.bypassEnrollementOperation = new ApiOperation()
        this.demoteUserToFreeOperation = new ApiOperation()
    }
}
