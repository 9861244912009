import React from "react"
import { getScaledImage } from "./getScaledImage"


export const LongFormIcon = ({width, height, style}: {width?: number, height?: number, style?: React.CSSProperties}): JSX.Element => {

    const {height: _h, width: _w} = getScaledImage(16, 16, width, height)

    return (
        <div style={{...style}}>
            <svg width={_w} height={_h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.66665 4H1.33331V13.3333C1.33331 14.0667 1.93331 14.6667 2.66665 14.6667H12V13.3333H2.66665V4Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 1.3335H5.33333C4.6 1.3335 4 1.9335 4 2.66683V10.6668C4 11.4002 4.6 12.0002 5.33333 12.0002H13.3333C14.0667 12.0002 14.6667 11.4002 14.6667 10.6668V2.66683C14.6667 1.9335 14.0667 1.3335 13.3333 1.3335ZM13.3333 8.00017L11.6667 7.00017L10 8.00017V2.66683H13.3333V8.00017Z" fill="white"/>
            </svg>

        </div>
    )
}

