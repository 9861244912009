// @ts-strict-ignore
import { ApiData, ApiOperation, ReduxAction } from '../../models'
import { ActionNames, Actions, GroupNameChecker } from '../actions/Constants'
import { WatchlistsState } from '../models'
import { WatchlistChart } from '../models/WatchlistChart/WatchlistChart'
import { WatchlistChartState } from '../models/WatchlistChart/WatchlistChartState'

const permitted = GroupNameChecker([Actions.WatchlistChart])
export const WatchlistChartReducer = (state: WatchlistChartState = new WatchlistChartState(), action: ReduxAction): WatchlistChartState => {
    if (!permitted(action)) return state

    switch (action.type) {
    // Skip loading, we aren't emitting it anyway
        case Actions.WatchlistChart.Get.Success: {
            const charts: WatchlistChart[] = action.data
            charts.forEach(c => {
                state.restById[c.watchlistId] = new ApiData<WatchlistChart>().succeeded(c)
            })
            return state
        }
        case Actions.WatchlistChart.Get.Failure: {
            const ids: string[] = action.subject
            ids.filter(id => !!state.restById[id]).forEach(id => state.restById[id].failed(action.error))
            return state
        }
    }

    return state
}
