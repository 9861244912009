import { ErrorBoundary } from '@sentry/react';
import { UseSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { DebugModal } from 'components/DebugModal/DebugModal';
import { DisclosureFooter } from 'components/DisclosureFooter/DisclosureFooter';
import { Flex } from 'components/Flex';
import { PortfolioGlance } from 'components/PortfolioGlance';
import { TradingViewMarquee } from 'components/TradingViewMarquee/TradingViewMarquee';
import { useColors } from 'hooks/UseColors';
import { useShowFooter, useShowTickerTape } from 'hooks/UseIsFullscreenRoute';
import React, { ReactElement, useEffect, useRef } from 'react';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import './BaseScreenLayout.scss';

export const FooterBaseLayout = (): ReactElement => {
    const [selectedAccountFromStore] = UseSelectedAccount();
    const {
        disclosures = undefined,
        hideGlance = false,
        selectedAccount = '',
        glanceFilters = {},
        showDebugModal = true,
        noShow = false,
        showTickerTape: baseShowTickerTape = true,
        setBaseLayoutFooterHeight
    } = useBaseScreenLayoutStore((s) => s);
    const colors = useColors();
    const showFooter = useShowFooter();

    const disclosuresRef = useRef<HTMLDivElement>(null);
    const glanceRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const showTickerTape = useShowTickerTape();

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (footerRef.current) {
                setBaseLayoutFooterHeight(footerRef.current.clientHeight);
            }
        });

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [setBaseLayoutFooterHeight]);

    if (noShow || !showFooter) return <></>;
    return (
        <Flex ref={footerRef} column style={{ position: 'sticky', bottom: 0, backgroundColor: colors.mainBackgroundColor, zIndex: 500 }}>
            {' '}
            {disclosures && (
                <Flex ref={disclosuresRef} column center align='flex-end' fullWidth id='disclosures-section' style={{ padding: '12px 0' }}>
                    <DisclosureFooter noMargin {...disclosures} />
                </Flex>
            )}
            {!hideGlance && (
                <Flex ref={glanceRef} column fullWidth id='glance-section'>
                    <PortfolioGlance
                        nonFixed
                        accountNumber={selectedAccount || selectedAccountFromStore}
                        assetClass={glanceFilters?.productType === 'Futures' ? 'futures' : 'equities'}
                    />
                </Flex>
            )}
            {showTickerTape && baseShowTickerTape && (
                <ErrorBoundary>
                    <TradingViewMarquee />
                </ErrorBoundary>
            )}
            {showDebugModal && (
                <ErrorBoundary>
                    <DebugModal />
                </ErrorBoundary>
            )}
        </Flex>
    );
};
