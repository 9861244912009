import { RefCallback, useCallback, useRef } from 'react';

export type SelfCleaningRefCallback<T> = (node: T) => Destructor;
const UNDEFINED_VOID_ONLY: unique symbol = Symbol('undefined-void-only');
// Destructors are only allowed to return void.
type Destructor = () => void | { [UNDEFINED_VOID_ONLY]: never };

/**
 * Callback-style ref that cleans up after itself.
 * Useful for when you want to supply a React-managed DOM node to an imperative library,
 * while making sure to clean up if the DOM node changes.
 * 
 * Taken from:
 * https://github.com/facebook/react/issues/15176#issuecomment-512740882
 * Something similar was later standardized in React, which at the time of writing has yet to be released.
 * https://github.com/facebook/react/pull/25686
 * @param rawCallback The function that should be called when the ref callback is run.
 *                    It should return a cleanup function like you would for useEffect.
 *                    It should be memoized with useCallback, or you might get a rerender-loop.
 * @returns A `RefCallback<T>`, which can be passed to a `ref` prop on some DOM node.
 */
export function useSelfCleaningRef<T extends HTMLElement>(rawCallback: SelfCleaningRefCallback<T>): RefCallback<T> {
    const cleanupRef = useRef<Destructor | null>(null);
    const callback: RefCallback<T> = useCallback(
        (node) => {
            if (cleanupRef.current !== null) {
                cleanupRef.current();
                cleanupRef.current = null;
            }

            if (node !== null) {
                cleanupRef.current = rawCallback(node);
            }
        },
        [rawCallback]
    );

    return callback;
}
