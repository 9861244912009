const parse = (query: string): Record<string, string> => {
    const l = query.replace(/^\?/g, '').split('&')
        .filter(t => t.includes('='))
        .map(t => [t.substring(0, t.indexOf('=')), t.substring(t.indexOf('=') + 1)])
        .reduce((lookup, next) => ({ ...lookup, [next[0]]: decodeURIComponent(next[1]) }), {})
    return l
}

export const QueryString = {

    ParseFromFullUrl: (url: string) => {
        if (!url.includes('?')) return {}
        return parse(url.substring(url.indexOf('?') + 1))
    },

    // TODO -- probably would be best to re-implement this using the `qs` package to support arrays, but for now this will do
    Parse: parse,

    Stringify: (o: object, omitLeadingQuestionMark?: boolean) => {
        const body = Object.entries(o).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')
        return omitLeadingQuestionMark ? body : `?${body}`
    }

}
