// @ts-strict-ignore
import base64 from 'react-native-base64';
import uuid from 'uuid-random'

export type Jwt = { header: any, payload: any, signature: string }
const fromB64 = (src: string) => (base64.decode(src) as string)?.trim().replace(/\}.+$/, '}');

export const DecodeJwt = (jwt: string): Jwt => {
    try {
        const [ headerSource, payloadSource, signature ] = (jwt || '').split('.');
        return {
            header: JSON.parse(fromB64(headerSource || '') || '{}'),
            payload: JSON.parse(fromB64(payloadSource || '') || '{}'),
            signature
        };
    } catch(e) {
        return null;
    }
};

export const JwtHasExpired = (jwt: string) => {
    const exp = GetJwtExpiration(jwt)
    const timeLeft = (new Date()).getTime() - exp
    if (isNaN(timeLeft)) return true;
    return timeLeft > 0;
};

export const GetJwtExpiration = (jwt: string): number => {
    const token = DecodeJwt(jwt);
    if (!token || !token.payload) return NaN;
    const expTime = token.payload.exp;
    return expTime ? Number(token.payload.exp) * 1000 : NaN;
}

export const GetJwtTimeLeftMs = (jwt: string): number => {
    const jwtExp = GetJwtExpiration(jwt);
    const nowMs = new Date().getTime()
    const diff = jwtExp - nowMs;
    return diff
}

export const GetJwtClaim = (jwt: string, claim: string) => DecodeJwt(jwt)?.payload?.[claim];

const fnameClaims = ['FirstNameClaim', 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
const lnameClaims = ['LastNameClaim', 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
const emailClaims = ['EmailClaim', 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
const firstClaimFound = (payload: any, claims: string[]) => {
    if (!payload) return undefined;
    return claims.map(c => payload[c]).find(c => !!c);
}

export const GetJwtPersonName = (jwt: string, format: 'normal' | 'dotted' = 'normal'): string => {
    const p = DecodeJwt(jwt)?.payload;

    const f = firstClaimFound(p, fnameClaims) || 'UnknownFirstName';
    const l = firstClaimFound(p, lnameClaims) || 'UnknownLastName';
    switch (format) {
        case 'dotted': return `${f}.${l}`;
        default: return `${f} ${l}`;
    }
}

export const GetJwtEmail = (jwt: string): string => firstClaimFound(DecodeJwt(jwt)?.payload || {}, emailClaims)

export const GetJwtIssuer = (jwt: string): 'vulcan' | 'okta' | 'secure-auth' | 'external' | 'other' | 'unknown' => {
    const iss = DecodeJwt(jwt)?.payload?.iss as string;
    if (!iss) return 'unknown';
    if (!iss.includes('stonex')) return 'external'
    if (iss.includes('auth')) return 'okta'
    if (iss.includes('idp')) return 'secure-auth'
    if (iss.includes('vulcan')) return 'vulcan'
    else return 'other';
}

export const TryGetSecureAuthCdsId = (jwt: string): string => {

    // Info:
    // Vulcan Tokens put the CDS ID inside an embedded JWT called `secureauth_jwt`, in a claim called `IdentityId`
    // Okta Tokens put the CDS ID in a claim called `IdentityId` (and also one called `cds_id`)

    const p = DecodeJwt(jwt)?.payload;
    const tokenType = (p?.secauth_jwt) ? 'vulcan' : 'okta';
    if (tokenType === 'vulcan') {
        const sa = p?.secauth_jwt;
        if (!sa) return null;
        const sap = DecodeJwt(sa)?.payload
        if (!sap) return null;
        const cdsId: string = sap.IdentityId;
        return cdsId?.toUpperCase() || null;
    } else {
        const cdsId = p?.IdentityId
        return cdsId?.toUpperCase() || null;
    }

}

export const TryGetVulcanId = (jwt: string): string => {
    const p = DecodeJwt(jwt)?.payload;
    if(p) return `${p.sub}@${p.iss}`
    else return `${uuid()}@anonymous`
}

export const Jwt = {
    TryGetVulcanId,
    Decode: DecodeJwt,
    HasExpired: JwtHasExpired,
    MsUntilExpired: GetJwtTimeLeftMs,
    GetExpirationMs: GetJwtExpiration,
    TryGetSecureAuthCdsId,
    TryGetSubject: (jwt: string) => GetJwtClaim(jwt, 'sub'),
    TryGetClaim: GetJwtClaim,
    GetIssuer: GetJwtIssuer,
    GetEmail: GetJwtEmail,
    GetPersonName: GetJwtPersonName
};