import { Urls } from '../../constants';
import { UpsertAlertRule } from '../models';
import { Actions } from './Constants';
import { ReduxApiDelete, ReduxApiGet, ReduxApiPost, ReduxApiPut } from './Helpers';

export const GetAlertRulesAction = () => ReduxApiGet(Urls.alerts.getRules(), Actions.Alerts.GetRules).withLoading().run();
/** @obsolete */
export const GetAlertFieldsAction = () => ReduxApiGet(Urls.alerts.fields(), Actions.Alerts.GetFields).run();

export const CreateAlertRuleAction = (rule: UpsertAlertRule) =>
    ReduxApiPost(Urls.alerts.createRule(), Actions.Alerts.CreateRule, rule)
        .onSuccess((e, dispatch) => {
            dispatch(GetAlertRulesAction());
            return e;
        })
        .withLoading()
        .run();

export const UpdateAlertRuleAction = (rule: UpsertAlertRule) =>
    ReduxApiPut(Urls.alerts.updateRule(rule?.id || ''), Actions.Alerts.UpdateRule, rule)
        .onSuccess((e, dispatch) => {
            dispatch(GetAlertRulesAction());
            return e;
        })
        .withLoading()
        .run();

export const PauseAlertRuleAction = (ruleId: string) => ReduxApiPut(Urls.alerts.pauseRule(ruleId), Actions.Alerts.ToggleRule).showToasts().withLoading().run();

export const ResumeAlertRuleAction = (ruleId: string) => ReduxApiPut(Urls.alerts.resumeRule(ruleId), Actions.Alerts.ToggleRule).showToasts().withLoading().run();

export const DeleteAlertRuleAction = (ruleId: string) =>
    ReduxApiDelete(Urls.alerts.deleteRule(ruleId), Actions.Alerts.DeleteRule)
        .withLoading()
        .onSuccess((e, dispatch) => {
            dispatch(GetAlertRulesAction());
            return e;
        })
        .run();
