import { ApiData, ApiOperation } from '../../../models'
import { SecurityLogo } from './SecurityLogo'

export class SecurityLogoState {
    bySymbol: { [key: string]: ApiData<SecurityLogo> }
    update: ApiOperation

    constructor () {
        this.bySymbol = {}
        this.update = new ApiOperation()
    }
}
