import { Flex } from "components"
import { SnexButton } from "components/SnexButton"
import { useText } from "phoenix/hooks/UseText"
import React, { useMemo } from "react"
import { OnboardingButton, OnboardingYesNo } from "../../../Base/OnboardingControls"
import { useRegistrationStore } from "../../Store"
import { getPersonalErrors } from "./Validation"

export const Registration_Personal_Form = () => {
    const text = useText(t => t.webRegistration)
    const {setValue, data} = useRegistrationStore()
    const errors = useMemo(() => getPersonalErrors(), [data?.usCitizen])
    return(
        <Flex column fullWidth center>
            <OnboardingYesNo column value={data.usCitizen} yesSublabel={text.usCitizenAnswer} 
                noSublabel={text.nonUSCitizenAnswer} handleChange={(val) => setValue('data.usCitizen', val)} 
                errors={ errors?.filter(e=> e.key === 'data.usCitizen') }/>
        </Flex>
    ) 
}