// @ts-strict-ignore
import React, { useEffect, useRef } from 'react';
import { Flex } from 'components';
import { LatestPosts, PopularPosts } from 'components/OneProNetwork/components/postsSection/postsSection';
import { useOneProNetworkStore } from 'phoenix/stores/OneProNetworkStore/OneProNetworkStore';
import { InFrameVideoPlayer } from 'components/OneProNetwork/components/inFrameVideoPlayer/inFrameVideoPlayer';
import { DeskAlerts } from 'components/OneProNetwork/components/deskAlerts/deskAlerts';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import NotFoundScreen from 'screens/NotFoundScreen';
import { QuoteCards } from 'components/OneProNetwork/components/QuoteCard/quoteCard';
import { OneProNetworkLogo } from 'components/OneProNetwork/assets/oneProNetworkLogo';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';

export const OneProNetworkScreen = (): JSX.Element => {
    const oneProFlagEnabled = useFeatureFlag(FeatureFlags.OneProNetwork);
    const { mainScreen, data } = useOneProNetworkStore();
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Set Base Layout Props
    useEffect(() => {
        setBaseLayoutProps({
            sidebar: <DeskAlerts />,
            noCard: true
        });
    }, [setBaseLayoutProps]);

    useEffect(() => {
        if (mainScreen.pristine && !mainScreen.loading) mainScreen.load(true);
    }, [mainScreen]);

    const containerRef = useRef<HTMLDivElement>(null);

    if (!oneProFlagEnabled) return <NotFoundScreen />;

    return (
        <>
            <OneProNetworkLogo height={22} style={{ marginBottom: 16 }} />
            <Flex column style={{ gap: 16, width: '100%', overflowX: 'hidden' }}>
                <Flex row ref={containerRef} fullWidth center align={'center'} style={{ backgroundColor: '#000' }}>
                    <InFrameVideoPlayer containerRef={containerRef} loading={mainScreen.pristine || mainScreen.loading} post={data.featuredPosts[0]} />
                </Flex>
                <QuoteCards
                    id={'networkScreenTicker'}
                    symbols={data?.featuredPosts[0]?.relatedSecurities.map((s) => s.qsi)}
                    scroll={data?.featuredPosts[0]?.relatedSecurities?.length > 4}
                />
                <LatestPosts isShown />
                <PopularPosts isShown />
            </Flex>
        </>
    );
};
