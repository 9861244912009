import { AspectRatio } from '@mui/icons-material';
import { Checkbox, Typography } from '@mui/material';
import { ArrowLink, Flex, SupportedSeriesType } from 'components';
import { CandleLineToggleControls, ChartDisplayType } from 'components/CandleLineToggleControls/CandleLineToggleControls';
import { CrosshairUpdateValueWithChange } from 'components/Charting/Chart/MultiSeriesChart';
import { RangeSelector } from 'components/Charting/RangeSelectors';
import { SecurityChartWrapper } from 'components/Charting/SecurityChartWrapper';
import { setSecurityValues, setSecurityValuesOverride, setShowAdditionalStats, setStartingValue } from 'components/SecurityValues/SecurityValuesViewModel';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { ChartMinuteOffsetsByRange, ChartRange, ChartRanges, StorageKeys } from 'phoenix/constants';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClassColors, useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecurityCompanyInfoAction, GetUpcomingEarningsAction } from 'phoenix/redux/actions';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions/SecurityQuoteActions';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { GetVariant } from 'phoenix/util/Variant';
import { XstreamUpdateChartFrequencyInMinutesAction } from 'phoenix/xstream/actions/XstreamChartActions';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { createContext, Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetDeviceThemeVariant } from 'theming/GetDeviceThemeVariant';
import { Routes } from 'util/Routes';

// Orders can only determined to be visible on the chart by using the imperative lightweight-charts API
// This context will allow the working orders component to inform the HoC whether or not to show the working orders toggle
export interface ISecurityChartContext {
    workingOrdersInRange?: boolean;
    setWorkingOrdersInRange: Dispatch<SetStateAction<boolean>>;
}

export const SecurityChartContext = createContext<ISecurityChartContext>({
    workingOrdersInRange: false,
    setWorkingOrdersInRange: () => undefined
});

export type SecurityChartSectionProps = {
    lineColor?: string;
    lineColorLighter?: string;
    seriesTypes?: SupportedSeriesType[];
    symbol: string;
};

export const SecurityChartSection = ({ lineColor, lineColorLighter, seriesTypes = ['candle', 'line'], symbol }: SecurityChartSectionProps): ReactElement => {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const assetClass = useAssetClassMetaV2(symbol, { fetchMetadata: true });
    const defaultRange = assetClass.securityChartRanges?.[0];

    const [range, setRange] = useState<ChartRange>(defaultRange);
    const appWindowSize = useAppWindowSize()[0];
    const allText = useText((t) => t);
    const text = allText.securityScreen;
    const securityQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol]);
    const optionsQuote = useSnexStore((s) => s.options.quotesByOsi[symbol]);
    const apiQuote = assetClass.derivative === 'option' ? optionsQuote : securityQuote;
    const [seriesType] = useLocalStorage<ChartDisplayType>(StorageKeys.BigChartDisplayType, 'line');
    const [latestChangePercent, setLatestChangePercent] = useState<number | undefined>(undefined);
    const quote = XS.OptionQuotes.use(symbol);
    const { loadData } = useSecurityMetadataV2();
    const chartData = useSnexStore((s) => s.securityChart.bySymbol[symbol]?.[range]?.data);
    const startingValue = range === '1d' ? apiQuote?.data?.previousClose || chartData?.[chartData?.length - 1]?.close : chartData?.[chartData?.length - 1]?.close;

    const showAdvancedChart = assetClass?.advancedChartConfiguration?.supported_resolutions?.length && GetVariant()?.showAdvancedChart;
    const [showWorkingOrders, setShowWorkingOrders] = useLocalStorage<boolean>(StorageKeys.BigChartShowWorkingOrders, true);
    const [workingOrdersInRange, setWorkingOrdersInRange] = useState<boolean>(false);
    const showWorkingOrdersCheckbox = workingOrdersInRange;
    const advancedChartText = assetClass.chartVariants.includes('dom') ? text.advancedDomAndChart : text.advancedChart;
    const { primaryColor } = useAssetClassColors(assetClass, GetDeviceThemeVariant());

    const excludeChartRanges = () => {
        const assetChartRanges = assetClass.securityChartRanges;
        return new Set(Object.values(ChartRanges).filter((range) => !assetChartRanges.includes(range)));
    };

    useEffect(() => {
        if (defaultRange) {
            setRange(defaultRange);
            xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[defaultRange]));
        }
        loadData(symbol);
        dispatch(GetSecurityQuoteAction(symbol));
        dispatch(GetSecurityCompanyInfoAction(symbol));
        if (assetClass.family === 'equities') dispatch(GetUpcomingEarningsAction(symbol));
    }, [assetClass.family, defaultRange, dispatch, loadData, symbol, xdispatch]);

    const chartDimensions = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return { width: 560, height: 320 };
            case WindowSizes.regular:
            case WindowSizes.small:
                return { width: 670, height: 350 };
            case WindowSizes.large:
            default:
                return { width: 1000, height: 350 };
        }
    }, [appWindowSize]);

    const handleCrosshairUpdate = useCallback(
        (update: CrosshairUpdateValueWithChange | undefined, isScrubbing?: boolean) => {
            // (type: 'line' | 'candle' | 'area', update: Record<string, CrosshairUpdateObject>, isScrubbing = false) => {
            const updateObj = update;

            if (!isScrubbing) {
                setSecurityValuesOverride(false);
                setSecurityValues({ ...quote });
                if (startingValue) setStartingValue(startingValue);
                setLatestChangePercent(quote?.changePercent);
                setShowAdditionalStats(false);
                return;
            } else {
                setSecurityValuesOverride(true);
                setSecurityValues({ ...updateObj, price: updateObj?.value, change: updateObj?.chartValChange, changePercent: updateObj?.chartPercChange });
                if (startingValue) setStartingValue(startingValue);
                setLatestChangePercent(updateObj?.chartPercChange);
            }

            if (seriesType === 'line') {
                setShowAdditionalStats(false);
            } else if (
                seriesType === 'candle' &&
                typeof update !== 'number' &&
                !isNaN(updateObj?.open as number) &&
                !isNaN(updateObj?.close as number) &&
                !isNaN(updateObj?.high as number) &&
                !isNaN(updateObj?.low as number)
            ) {
                setShowAdditionalStats(true);
            }
        },
        [seriesType, quote, startingValue]
    );

    const updateRange = useCallback(
        (newRange: ChartRange) => {
            setRange(newRange);
            xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[newRange]));
        },
        [xdispatch]
    );

    return (
        <SecurityChartContext.Provider value={{ workingOrdersInRange, setWorkingOrdersInRange }}>
            <Flex column>
                <div style={{ width: '100%', minHeight: 350 }}>
                    <SecurityChartWrapper
                        bypassBatching
                        canScrub
                        chartResolution='hi'
                        containerId='lalala'
                        crosshairType='vertical'
                        height={chartDimensions.height}
                        lineColor={lineColor}
                        lineColorLighter={lineColorLighter}
                        onCrosshairUpdate={handleCrosshairUpdate}
                        percentChangeOverride={range === '1d' ? latestChangePercent : undefined}
                        range={range}
                        seriesType={seriesType}
                        showNoDataLabel
                        showWorkingOrders
                        symbol={symbol}
                        width={chartDimensions.width}
                    />
                </div>
                <Flex justify='space-between'>
                    <Flex row align='flex-start' style={{ marginTop: 10 }}>
                        <div>
                            <RangeSelector excludeRanges={excludeChartRanges()} selectedRange={range} setRange={updateRange} valueChange={latestChangePercent} />
                        </div>
                        {seriesTypes.includes('candle') && <CandleLineToggleControls />}
                        {!!showWorkingOrdersCheckbox && (
                            <div className='chart-toggle-checkbox' onClick={() => setShowWorkingOrders(!showWorkingOrders)}>
                                <Typography variant='subtitle2'>{allText.misc.showWorkingOrders}</Typography>
                                <Checkbox checked={showWorkingOrders} size='small' style={{ padding: 0, marginLeft: 5 }} />
                            </div>
                        )}
                    </Flex>
                    {!!showAdvancedChart && (
                        <Flex align='center' id='tour-02-advanced-chart-button' justify='flex-end' style={{ flex: 0, paddingTop: 5, whiteSpace: 'nowrap' }}>
                            <ArrowLink
                                noAnimate
                                className={assetClass.family}
                                eventTag='Advanced Chart'
                                route={Routes.advancedChart(symbol)}
                                icon={<AspectRatio style={{ fill: primaryColor, fontSize: 14 }} />}
                                textStyle={primaryColor ? { color: primaryColor } : undefined}
                            >
                                {advancedChartText}
                            </ArrowLink>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </SecurityChartContext.Provider>
    );
};
