// @ts-strict-ignore
import { SnexLsItemUpdate } from './SnexLsItemUpdate';

export type SubscriptionErrorHandler = {
    (code: number, message: string): void;
    isWrapped?: boolean;
};

// Shim class for Lightstreamer SubscriptionListener
export class SnexLsSubscriptionListener {
    onSubscriptionError: SubscriptionErrorHandler;
    onItemUpdate: (update: SnexLsItemUpdate) => void;
    onSubscription: () => void;
    onUnsubscription: () => void;
    onListenEnd: (subscription: any) => void;
}
