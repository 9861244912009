
export const AllowedAmexCountryCodes = new Set(['AA', 'AC', 'AR', 'BB', 'BF', 'BH', 'BL', 'BR', 'CI', 'CJ', 'CO', 'CS', 'DO', 'DR', 'EC', 'ES', 'FG', 'GJ', 'GP', 'GT', 'GY', 'HA', 'HO', 'JM', 'MB', 'MX', 'NS', 'NU', 'PA', 'PE', 'PM', 'SC', 'SP', 'ST', 'SZ', 'TB', 'TD', 'TK', 'UY', 'VC', 'VE', 'VI'])

export const AmexAccountBalanceRanges = {
    green: { min: 50000, max: 74999 },
    gold: { min: 75000, max: 99999 },
    platinum: { min: 100000, max: null }
}

export const AmexSignupUrls = {
    green: 'https://www.americanexpress.com/en-idc/acq/applications/form/new?product=31655905-a757-4e22-84d5-951162b8ec54&origin=P2',
    gold: 'https://www.americanexpress.com/en-idc/acq/applications/form/new?product=9556b371-54d7-4062-acc6-7a102433fd10&origin=P2',
    platinum: 'https://www.americanexpress.com/en-idc/acq/applications/form/new?product=ecfe6ef4-d5c2-40d3-be85-a2fc56cf19b7&origin=P2'
}
