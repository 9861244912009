import React, { useMemo } from 'react';
import { CircularLogo, Flex } from '../..';
import { AssetColors } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import style from '../style.module.scss';

export const SecurityCellLogo = React.memo((props: { symbol: string, description?: string }) => {
    const { symbol, description } = props;

    return (
        <Flex
            className={style.securityLogoWrapper}>
            <CircularLogo
                noSpinner
                altText={ description || symbol }
                size={ 26 }
                symbol={ symbol } />
        </Flex>
    );
});