// @ts-strict-ignore
import { Grid, Link, Tooltip, Typography, IconButton } from '@mui/material';
import React, { useMemo } from 'react';
import { Flex, MillionBillion } from '..';
import { FormatNumber } from 'phoenix/util';
import uuid from 'uuid-random';
import { LoadingBar } from '../LoadingBar/LoadingBar';
import { OpenInNew, HelpOutline } from '@mui/icons-material';
import { useText } from 'phoenix/hooks/UseText';

export interface InfoMatrixGridletModel {
    formatter?: (value: string | number) => string;
    label: string;
    value: string | number | boolean;
    loading?: boolean;
    type?: 'normal' | 'expando-number' | 'expando-money' | 'money' | 'url' | 'decimal' | 'float' | 'ellipsis' | 'boolean';
    linkText?: string;
    linkUnavailableText?: string;
}

interface InfoMatrixGridletProps {
    model: InfoMatrixGridletModel;
    mbGroup: string;
    loading?: boolean;
}

interface InfoMatrixProps {
    data: InfoMatrixGridletModel[];
    loading?: boolean;
    style?: React.CSSProperties;
}

export const InfoMatrix = React.memo((props: InfoMatrixProps) => {
    const mbGroup = useMemo(() => uuid(), []);

    return (
        <Grid container style={props.style}>
            {props?.data?.map((d, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                    <InfoMatrixGridlet model={d} mbGroup={mbGroup} loading={d.loading || props.loading} />
                </Grid>
            ))}
        </Grid>
    );
});

const InfoMatrixGridlet = React.memo((props: InfoMatrixGridletProps) => {
    const m = props.model;
    const allText = useText((s) => s);
    const text = allText.securityScreen.stats;
    const boolText = allText.webRegistration;

    const variant = useMemo(() => {
        if (m.loading || props.loading) return 'loading';
        const t = typeof m.value;

        if (t === 'boolean') return 'boolean';
        // prettier-ignore
        if (t === 'number') { // For number values
            switch (m.type) {
                case 'expando-number': return 'mill-bill';
                case 'expando-money': return 'mill-bill-bux';
                case 'money': return 'money';
                case 'decimal': return 'decimal';
                case 'float': return 'float';
                case 'ellipsis': return 'ellipsis';
                case 'normal':
                default: return 'commas';
            }
        } else { // For string values
            switch (m.type) {
                case 'boolean': return 'boolean'
                case 'url': return 'link';
                case 'ellipsis': return 'ellipsis';
                default: return 'normal';
            }
        }
    }, [m]);

    const formatNumberLogic = {
        commas: FormatNumber.toCommas,
        money: (x) => FormatNumber.toMoney(x as number),
        decimal: (x) => FormatNumber.toDecimal(x as number, 5),
        float: (x) => FormatNumber.toDecimal(x as number, 5, true)
    };

    const formatter = m.formatter || formatNumberLogic[variant];

    return (
        <Flex column style={{ marginBottom: 20, paddingRight: 5 }}>
            <Flex row align='center' style={{ marginBottom: 5 }}>
                <Typography variant='h6' style={{ marginRight: 5, whiteSpace: 'pre-wrap' }}>
                    {m.label}
                </Typography>
                {m.label === text.dayTradeMargin && (
                    <Tooltip title={text.dayTradeMarginToolTip}>
                        <HelpOutline style={{ fontSize: 20 }} />
                    </Tooltip>
                )}
            </Flex>
            {formatter ? (
                <LightText>{formatter(m.value)}</LightText>
            ) : (
                <>
                    {variant === 'boolean' && <LightText>{m.value === undefined ? '---' : m.value ? boolText.yes : boolText.no}</LightText>}
                    {variant === 'loading' && <LoadingBar style={{ width: '80%' }} />}
                    {variant === 'link' && <WebsiteLink model={m} />}
                    {variant === 'normal' && <LightText>{m.value || '---'}</LightText>}
                    {variant === 'ellipsis' && <LightText withEllipsisTooltip>{m.value || '---'}</LightText>}
                    {variant === 'mill-bill' && <MillionBillion value={m.value as number} group={props.mbGroup} />}
                    {variant === 'mill-bill-bux' && <MillionBillion value={m.value as number} group={props.mbGroup} money />}
                </>
            )}
        </Flex>
    );
});

const LightText = (props: { children: any; withEllipsisTooltip?: boolean }) => {
    if (props.withEllipsisTooltip) {
        return (
            <Tooltip placement='bottom' title={props.children}>
                <Typography variant='h6' style={{ maxWidth: '90%', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 400, whiteSpace: 'nowrap' }}>
                    {props.children}
                </Typography>
            </Tooltip>
        );
    }

    return (
        <Typography variant='h6' style={{ fontWeight: 400, whiteSpace: 'pre-wrap' }}>
            {props.children}
        </Typography>
    );
};

const WebsiteLink = (props: { model: InfoMatrixGridletModel }) => {
    const m = props.model;
    const text = useText((s) => s.general);

    if (!m.value) {
        return (
            <Typography variant='h6' style={{ fontWeight: 400 }} color='textSecondary'>
                {m.linkUnavailableText || '---'}
            </Typography>
        );
    }

    return (
        <LightText>
            <Link color='textPrimary' href={m.value as string} target='_blank' style={{ textDecoration: 'underline' }} underline='hover'>
                {m.linkText || text.open} <OpenInNew style={{ fontSize: 10 }} />
            </Link>
        </LightText>
    );
};
