let timers: { [key: string]: Date } = {}

export const SetCacheTime = (key: string): void => {
    timers[key] = new Date()
}

export const CheckCacheTime = (key: string, secondsToAllow: number): 'valid' | 'expired' => {
    if (!timers[key]) return 'expired'
    const msAlive = ((new Date()).getTime() - timers[key].getTime())
    const msLeft = !timers[key] ? NaN : ((secondsToAllow * 1000) - msAlive)
    return msLeft <= 0 ? 'expired' : 'valid'
}

export const ResetCacheAll = () => { timers = {} }

export const ResetCache = (key: string) => {
    if (timers[key]) delete timers[key]
}
