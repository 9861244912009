// @ts-strict-ignore
import { combineReducers } from 'redux'
import {
    TaxlotsReducer,
    OrdersReducer,
    AccountsReducer,
    PositionsReducer,
    SecuritiesReducer
} from './reducers'
import { FundingReducer } from './reducers/FundingReducer'
import { AccountSummaryReducer } from './reducers/AccountSummaryReducer'
import { LoginReducer } from './reducers/LoginReducer'
import { NewsReducer } from './reducers/NewsReducer'
import { WatchlistsReducer } from './reducers/WatchlistsReducer'
import { AccountChartReducer } from './reducers/AccountChartReducer'
import { AccountApplicationStatusReducer } from './reducers/AccountApplicationStatusReducer'
import { SecurityChartReducer } from './reducers/SecurityChartReducer'
import { UserReducer } from './reducers/UserReducer'
import { AccountBalancesReducer } from './reducers/AccountBalancesReducer'
import { SecurityQuoteReducer } from './reducers/SecurityQuoteReducer'
import { TradingReducer } from './reducers/TradingReducer'
import { OptionsReducer } from './reducers/OptionsReducer'
import { SecurityLogoReducer } from './reducers/SecurityLogoReducer'
import { EarningsCallsReducer } from './reducers/EarningsCallsReducer'
import { MiniplayerReducer } from './reducers/MiniplayerReducer'
import { FinancialStatementsReducer } from './reducers/FinancialStatementsReducer'
import { SecurityAnalysisReducer } from './reducers/SecurityAnalysisReducer'
import { ErrorsReducer } from './reducers/ErrorsReducer'
import { DocumentsReducer } from './reducers/DocumentsReducer'
import { TransactionsReducer } from './reducers/TransactionsReducer'
import { MessagesReducer } from './reducers/MessagesReducer'
import { SystemReducer } from './reducers/SystemReducer'
import { RelayReducer } from './reducers/RelayReducer'
import { FeatureFlagReducer } from './reducers/FeatureFlagReducer'
import { SubscriptionReducer } from './reducers/SubscriptionReducer'
import { AlertsReducer } from './reducers/AlertsReducer'
import { WatchlistChartReducer } from './reducers/WatchlistChartReducer'
import { AcatsReducer } from './reducers/AcatsReducer'
import { FundsReducer } from './reducers/FundsReducer'
import { AdminReducer } from './reducers/AdminReducer'
import { MarketReducer } from './reducers/MarketReducer'
import { DeviceReducer } from './reducers/DeviceReducer'
import { SectorSecuritiesReducer } from './reducers/SectorSecuritiesReducer'
import { Actions } from './actions'
import { GlobalState } from './GlobalState'
import { MobileScreensReducer } from './reducers/MobileScreens/MobileScreensReducer'
import { ReduxAction } from '../models'
import { MobileScreenLoadingState } from './models/MobileScreens/MobileScreenLoadingState'
import { FuturesOnboardingState } from './models/FuturesOnboarding/FuturesOnboardingState'
import { FuturesOnboardingReducer } from './reducers/FuturesOnboardingReducer'
import { AccountOpeningReducer } from './reducers/AccountOpeningReducer'
import { WhitelabelReducer } from './reducers/WhitelabelReducer'
import { AuthorizationSettingsReducer } from './reducers/AuthorizationSettingsReducer'
import { WeatherReducer } from './reducers/WeatherReducers'

const appReducer = combineReducers({
    acats: AcatsReducer,
    accountApplicationStatuses: AccountApplicationStatusReducer,
    accountBalances: AccountBalancesReducer,
    accountChart: AccountChartReducer,
    accountOpenings: AccountOpeningReducer,
    accountSummary: AccountSummaryReducer,
    accounts: AccountsReducer,
    admin: AdminReducer,
    alerts: AlertsReducer,
    device: DeviceReducer,
    documents: DocumentsReducer,
    earningsCalls: EarningsCallsReducer,
    errors: ErrorsReducer,
    featureFlags: FeatureFlagReducer,
    financialStatements: FinancialStatementsReducer,
    funding: FundingReducer,
    funds: FundsReducer,
    futuresOnboarding: FuturesOnboardingReducer,
    login: LoginReducer,
    logos: SecurityLogoReducer,
    market: MarketReducer,
    messages: MessagesReducer,
    miniplayer: MiniplayerReducer,
    mobileScreenLoading: (state: MobileScreenLoadingState) =>
        state || new MobileScreenLoadingState(),
    news: NewsReducer,
    options: OptionsReducer,
    orders: OrdersReducer,
    positions: PositionsReducer,
    relay: RelayReducer,
    sectorSecurities: SectorSecuritiesReducer,
    securities: SecuritiesReducer,
    securityAnalysis: SecurityAnalysisReducer,
    securityChart: SecurityChartReducer,
    securityQuote: SecurityQuoteReducer,
    subscription: SubscriptionReducer,
    system: SystemReducer,
    taxlots: TaxlotsReducer,
    trading: TradingReducer,
    transactions: TransactionsReducer,
    user: UserReducer,
    watchlistChart: WatchlistChartReducer,
    watchlists: WatchlistsReducer,
    weather: WeatherReducer,
    whitelabel: WhitelabelReducer,
    authorizationSettings: AuthorizationSettingsReducer,
})

let rnum = 0
let currentScreen = '(Unknown screen)'
export const rootReducer = (state: GlobalState, action: ReduxAction): GlobalState => {
    // Keep this so you can easily track the number of redux actions flowing through the app
    // console.log(`REDUX: ${ currentScreen } action #${ ++rnum }: ${ action?.type } ${ action?.subject ? 'Subject:' + action.subject : '' }`)

    if (!state) return new GlobalState()
    else if (action?.type === Actions.ClearStore) return new GlobalState()
    else if (action?.type === Actions.Logout.Success) return new GlobalState()
    else if (/^MOBILE_SCREEN/.test(action?.type || '')) {
        currentScreen = action.subject || action.type
        rnum = 0
        return MobileScreensReducer(state, action)
    } else return appReducer(state, action)
}
