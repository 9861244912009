// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import { OnboardingInputError, ValidateRequired } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"

export const PasswordIsValid = () => getPasswordErrors().length < 1
export const getPasswordErrors = () : OnboardingInputError[] => {
    const keys = ['data.password', 'data.confirmPassword']
    const data = useRegistrationStore.getState()
    const _errors = []  as OnboardingInputError[]

    keys.forEach(key => {
        switch(key) {
            case 'data.password':
                _errors.push(ValidateRequired(data, key))
                if(data?.data?.password?.length < 14) _errors.push({key, error: T(t => t.webRegistration.errors.password.invalidPassword)})
                break
            case 'data.confirmPassword':
                _errors.push(ValidateRequired(data, key))
                if(!!data?.data?.password && data?.data?.confirmPassword?.toLowerCase() !== data?.data?.password?.toLowerCase()) _errors.push({key, error: T(t => t.webRegistration.errors.password.passwordsDoNotMatch)})
                break
    }})

    return _errors.filter(e => !!e);
}
