// @ts-strict-ignore
import { Dispatch } from 'redux';
import { LightstreamerSubscriptionMode } from '../../constants';
import { SnexLsItemUpdate } from '../../ls-shim/models/SnexLsItemUpdate';
import { SnexLsSubscription } from '../../ls-shim/models/SnexLsSubscription';
import { SnexLsSubscriptionListener } from '../../ls-shim/models/SnexLsSubscriptionListener';
import { ExtendedSubscription } from '../../models/ExtendedSubscription';
import { GetDisableGainStreaming, GetDisableSnexStreaming, GetShowDebugHud, Lightstreamer, LSUpstreamSource, MutexEnter, MutexExit } from '../../util';
import { TicketManager } from '../../util/TicketManager';
import { XstreamState } from '../XstreamState';

// const DEBUG_ITEM = 'quote:AAPL'
const DEBUG_ITEM = undefined;

export const XstreamReduxSubscribe = (
    groupName: string,
    itemNames: string[],
    actionName: string,
    fields: string[],
    onUpdate?: (item: any, dispatch: Dispatch, getState: () => XstreamState) => any,
    options: Partial<{
        mode: LightstreamerSubscriptionMode;
        maxFrequencySeconds: number;
        subject: any;
        includeSnapshot: boolean;
        namespace: string;
        upstream: LSUpstreamSource;
    }> = {}
): (dispatch: Dispatch, getState: () => XstreamState) => Promise<string | null> => {
    const trace = !DEBUG_ITEM
        ? () => undefined
        : (m: string) => {
              const first = itemNames?.[0];
              if (first !== DEBUG_ITEM) return;
              console.log(`[XS-DEBUG] ${first}: ${m}`);
          };

    return async (dispatch: Dispatch, getState: () => XstreamState) => {
        const unLockMutex = async () => await Promise.all(itemNames.map((item) => MutexExit(item)));
        await Promise.all(itemNames.map((item) => MutexEnter(item)));

        const ns = options.namespace || 'default';
        trace(`Subscribing to items ${itemNames.join(',')} in ns=${ns}`);
        trace(`... fields=${fields.join(',')}`);

        // Check with TicketManager if subscription already exists. If one does, you'll get a "ticket"
        // indicating your stake in the subscription
        let ticket = TicketManager.PutTicketIfExists(itemNames, ns);
        if (ticket) {
            trace('Subscription already exists! Adding self to list of subscription consumers (aka, grabbed a ticket)');
            await unLockMutex();
            return ticket;
        }

        // Otherwise make the whole subscription and report it to the ticket manager
        const scrubbedItemNames = itemNames.filter((i) => i.indexOf(' ') === -1);
        if (!scrubbedItemNames.length) {
            trace('No valid items to subscribe to');
            await unLockMutex();
            return null;
        }

        const sub = new SnexLsSubscription(options.mode || 'DISTINCT', itemNames, fields);

        const listener = new SnexLsSubscriptionListener();

        listener.onSubscriptionError = (code: number, message: string) => {
            // TODO -- handle errors
            trace(`ERROR! ${code}/${message}`);
        };

        listener.onItemUpdate = async (update: SnexLsItemUpdate) => {
            if (GetShowDebugHud()) TicketManager.TickUpdate(itemNames, ns);
            let item = onUpdate ? onUpdate(update, dispatch, getState) : update;
            if (typeof item?.then === 'function') item = await item;
            const action = { type: actionName, data: item, subject: options.subject };
            trace(`Update for ${options.subject}`);
            dispatch(action);
        };

        sub.setDataAdapter(groupName);
        sub.setRequestedMaxFrequency(options.maxFrequencySeconds || 1);
        sub.setRequestedSnapshot(options.includeSnapshot ? 'yes' : 'no');
        sub.addListener(listener);
        trace(`Listener and subscription configured, groupName = ${groupName}`);

        // @ts-ignore
        const esub: ExtendedSubscription = sub;
        esub.namespace = ns;
        esub.upstream = options?.upstream || 'snex';
        trace(`Extended args: upstream=${esub.upstream}; ns=${esub.namespace}`);

        // Report new subscription to TicketManager, and get a new ticket for this consumer
        TicketManager.PutSubscription(esub);
        ticket = TicketManager.PutTicketIfExists(itemNames, ns);
        trace(`Got ticket! ${ticket}`);

        try {
            const disabled = options.upstream === 'gain' ? GetDisableGainStreaming() : GetDisableSnexStreaming();
            if (!disabled) Lightstreamer.Subscribe(esub);
            else console.log(`[XS] Streaming for ${options.upstream} disabled, not subscribing`);
            await unLockMutex();
            return ticket;
        } catch (error) {
            console.log({ _me: '[XS] Subscription error', error, groupName, itemNames, actionName, options });
            await unLockMutex();
            return null;
        }
    };
};
