import _ from 'lodash';
import { SubscriptionErrorHandler } from 'phoenix/ls-shim/models/SnexLsSubscriptionListener';
import { ExtendedSubscription } from 'phoenix/models/ExtendedSubscription';
import { DebugDumpManager } from '../DebugDumpManager';

interface MaybePatchGainRetryArgs {
    /**
     * The subscription object to modify, adding retry support.
     */
    subscription: ExtendedSubscription;
    /**
     * Dependency injection for Lightstreamer.Subscribe.
     */
    onRequestSubscribe: (sub: ExtendedSubscription) => void;
    /**
     * Dependency injection for Lightstreamer.Unsubscribe.
     */
    onRequestUnsubscribe: (sub: ExtendedSubscription) => void;
}

/**
 * Safely wraps the given subscription's {@link SubscriptionErrorHandler}s to retry the subscription if the error and affected item(s) are retryable.
 * This function only modifies the subscription if relevant:
 * This does nothing if the given subscription is not a GAIN subscription, or if the subscription wouldn't be affected by a "Not entitled" error to the extent that it
 * would benefit from being retried as delayed data.
 * @param args
 */
function maybePatchGainRetry(args: MaybePatchGainRetryArgs): void {
    const { subscription, onRequestSubscribe, onRequestUnsubscribe } = args;
    if (subscription.upstream === 'gain') {
        // if any item in the given subscription is retryable, then the whole subscription might benefit from being retried, with the affected items switched to delayed.
        const isAnyRetryable = subscription.items.some(isGainRetryable);

        if (isAnyRetryable) {
            _.forEach(subscription.listeners, (listener) => {
                const zuper = listener.onSubscriptionError;
                if (zuper.isWrapped) {
                    return;
                }
                listener.onSubscriptionError = createRetryableOnSubscriptionError({ subscription, zuper, onRequestSubscribe, onRequestUnsubscribe });
                listener.onSubscriptionError.isWrapped = true;
            });
        }
    }
}

/**
 * Checks if the given GAIN subscription item is retryable.
 * A subscription item is retryable if it is
 * - delayable (ie. supports specifying a streaming type), and
 * - isn't already delayed (ie. ends with :delayed), and
 * - isn't explicitly expecting live data (ie. ends with :live).
 * @param item
 * @returns true if the given subscription item is retryable, false otherwise.
 */
function isGainRetryable(item: string): boolean {
    const gainDelayableItems = ['userorders', 'price', 'tick', 'bar', 'daybar', 'weeklybar', 'monthlybar', 'volumebar', 'rangebar', 'nlinebreakbar'];
    const isDelayableItem = gainDelayableItems.some((di) => item.startsWith(di));
    return isDelayableItem && !item.toLowerCase().endsWith(':delayed') && !item.toLowerCase().endsWith(':live');
}

interface CreateRetryableOnSubscriptionErrorArgs {
    /**
     * The subscription this listener is attached to.
     */
    subscription: ExtendedSubscription;
    /**
     * The original listener's {@link SubscriptionErrorHandler} function.
     */
    zuper: SubscriptionErrorHandler;
    /**
     * Dependency injection for Lightstreamer.Subscribe.
     */
    onRequestSubscribe: (sub: ExtendedSubscription) => void;
    /**
     * Dependency injection for Lightstreamer.Unsubscribe.
     */
    onRequestUnsubscribe: (sub: ExtendedSubscription) => void;
}

/**
 * Creates a new function that wraps the given {@link SubscriptionErrorHandler} handler.
 * The new function--instead of calling the original handler--will retry the subscription if the error is a
 * "Not entitled" error for a retryable item.
 * @param args
 * @returns the new error handler function.
 */
function createRetryableOnSubscriptionError(args: CreateRetryableOnSubscriptionErrorArgs): SubscriptionErrorHandler {
    const { subscription, zuper, onRequestSubscribe, onRequestUnsubscribe } = args;
    return (code: number, message: string) => {
        if (code !== -100) {
            // Unknown error code. Pass it up.
            return zuper(code, message);
        }

        // The error message from gain may look like this:
        // [price:ZCH24] Not entitled|[price:LBRH24] Not entitled
        const messages = message.split('|');
        const parsedMessages = messages.map((errorMessageGroup) => {
            // /[\s\S]/ is like /./, but matches newlines too.
            const groups = errorMessageGroup.match(/^\[(?<item>.*)\] (?<itemMessage>[\s\S]*)$/)?.groups;
            const item = groups?.item;
            const itemMessage = groups?.itemMessage;
            if (item === undefined || itemMessage === undefined) {
                // Could not parse, so may as well not save anything.
                return null;
            }
            return {
                // eg. price:ZCH24
                item,
                // eg. Not entitled
                itemMessage
            };
        });

        const defined = parsedMessages.filter((x): x is { item: string; itemMessage: string } => x !== null);
        if (defined.length !== parsedMessages.length) {
            // At least one error was not parsed. Pass the error up.
            return zuper(code, message);
        }

        const allNotEntitled = defined.filter((x): x is { item: string; itemMessage: 'Not entitled' } => x.itemMessage === 'Not entitled');
        if (allNotEntitled.length !== defined.length) {
            // At least one item was different from "Not entitled". Pass the error up.
            return zuper(code, message);
        }

        const retryableItems = allNotEntitled.filter((x) => isGainRetryable(x.item));
        if (retryableItems.length !== allNotEntitled.length) {
            // At least one item mentioned in `message` does not satisfy isGainRetryable.
            // Pass the error up.
            return zuper(code, message);
        }

        DebugDumpManager.RecordEvent({
            event: 'GAIN Retry As Delayed',
            item: subscription.getItems().join('/'),
            other: `NS - ${subscription.namespace}`
        });

        // This subscription contains retryable items.
        onRequestUnsubscribe(subscription);
        for (const { item } of retryableItems) {
            // Append :delayed.
            // This is safe because we already ensured :delayed and :live are not specified.
            const allItems = subscription.getItems();
            const index = allItems.indexOf(item);
            allItems[index] = `${item}:delayed`;
            subscription.setItems(allItems);
        }
        onRequestSubscribe(subscription);
    };
}

export { maybePatchGainRetry };
