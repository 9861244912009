import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { LoadingSpinner } from '..';
import { useColors } from 'hooks/UseColors';
import { Flex } from '../Flex';

interface LoadingBlockProps {
    height: number,
    loading?: boolean,
    noDataText?: string,
    loadingText?: string
}

export const LoadingBlock = (props: LoadingBlockProps) => {
    const colors = useColors();
    return (
        <Flex column align='center' justify='center' style={{ height: props.height, background: colors.mainBackgroundColor, marginTop: 10, borderRadius: 10 }}>
            { props.loading ? <><LoadingSpinner size={100} /></> : <Typography color='textSecondary' variant='subtitle1'>{ props.noDataText }</Typography> }
        </Flex>
    );
};
