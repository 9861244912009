import { DisclosureFooterProps } from 'components/DisclosureFooter/DisclosureFooter';
import React, { ReactNode } from 'react';
import { PositionScreenFilters } from 'screens/PositionsScreen/HoldingsTableCommonProps';
import { create } from 'zustand';

type BaseScreenLayoutProperties = {
    style?: React.CSSProperties;
    backgroundColor?: string;
    hideHeader?: boolean;
    disclosures?: DisclosureFooterProps;
    hideGlance?: boolean;
    selectedAccount?: string;
    glanceFilters?: PositionScreenFilters;
    sidebarId?: string;
    sidebar?: ReactNode;
    sidebarLeft?: boolean;
    sidebarWidth?: number;
    showTickerTape?: boolean;
    fullWidthContent?: boolean;
    noCard?: boolean;
    noShow?: boolean;
    showDebugModal?: boolean;
};

type BaseScreenLayoutMethods = {
    getBaseLayoutProps: () => BaseScreenLayoutProperties;
    setBaseLayoutProps: (props: BaseScreenLayoutProperties) => void;
    setBaseLayoutHeaderHeight: (headerHeight: number) => void;
    setBaseLayoutFooterHeight: (footerHeight: number) => void;
};

type BaseScreenRefs = {
    headerHeight: number;
    footerHeight: number;
};

type BaseScreenLayoutStore = BaseScreenLayoutProperties & BaseScreenLayoutMethods & BaseScreenRefs;

const BaseScreenLayoutDefaultProps = {
    style: {},
    backgroundColor: '',
    hideHeader: false,
    disclosures: undefined,
    hideGlance: false,
    selectedAccount: '',
    glanceFilters: {},
    sidebarId: '',
    sidebar: null,
    sidebarLeft: false,
    sidebarWidth: 0,
    showTickerTape: true,
    fullWidthContent: false,
    noCard: false,
    noShow: false,
    showDebugModal: false
};

const useBaseScreenLayoutStore = create<BaseScreenLayoutStore>((set, get) => ({
    ...BaseScreenLayoutDefaultProps,
    headerHeight: 0,
    footerHeight: 0,
    getBaseLayoutProps: () => {
        const state = get();
        return {
            style: state.style,
            backgroundColor: state.backgroundColor,
            hideHeader: state.hideHeader,
            disclosures: state.disclosures,
            hideGlance: state.hideGlance,
            selectedAccount: state.selectedAccount,
            glanceFilters: state.glanceFilters,
            sidebarId: state.sidebarId,
            sidebar: state.sidebar,
            sidebarLeft: state.sidebarLeft,
            sidebarWidth: state.sidebarWidth,
            fullWidthContent: state.fullWidthContent,
            noCard: state.noCard,
            noShow: state.noShow,
            showDebugModal: state.showDebugModal
        };
    },
    setBaseLayoutProps: (props: BaseScreenLayoutProperties) => set({ ...BaseScreenLayoutDefaultProps, ...props }),
    setBaseLayoutHeaderHeight: (headerHeight: number) => set({ headerHeight }),
    setBaseLayoutFooterHeight: (footerHeight: number) => set({ footerHeight })
}));

export default useBaseScreenLayoutStore;
