// @ts-strict-ignore
import axios, { AxiosError } from 'axios'
import { IsEnvironment, IsNonProd, MakeVulcanIdUrl, ResolveEnvironment, Urls } from '../constants'
import * as CryptoJS from 'crypto-js'
import { QueryString } from './QueryString'
import { UserLogin, UserLoginWithVulcan } from '../redux/models'
import { ErrorLogger } from './ErrorLogger'
import { VulcanAuth } from './VulcanAuth'

type CodeAndVerifier = { authorizationCode: string; codeVerifier: string }

export type SignInWithCredentialsStep = 'get-session-token' | 'get-token-from-proxy' | 'upgrade-vulcan-token'

const charsets = {
    url: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~',
    alphanum: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
}

// Ref: https://developer.okta.com/docs/guides/implement-grant-type/authcodepkce/main/#create-the-proof-key-for-code-exchange
const createPkcePair = (): {
    code_verifier: string
    code_challenge: string
} => {
    const verifier = generateNonce(128, charsets.url)
    const hash = CryptoJS.SHA256(verifier)
    const challenge = hash.toString(CryptoJS.enc.Base64url)

    return { code_verifier: verifier, code_challenge: challenge }
}

const generateNonce = (length: number, charset: string) => {
    let i = length
    const out: string[] = []
    const l = charset.length
    while (i-- > 0) out.push(charset.charAt(Math.floor(Math.random() * l)))
    return out.join('')
}

let injectedJwt = ''

export const Okta = {
    GetClientId: () => (IsNonProd() ? '0oa4uyn5p1iibabNJ1d7' : '0oa2r3qklcGuHfVJd697'),
    GetHost: () => (IsNonProd() ? 'https://auth.preview.stonex.com' : 'https://auth.stonex.com'),
    GetAuthServerId: () => (IsNonProd() ? 'aus1lrb9ihMLQIvmd1d7' : 'ausc0uj2v6KKUirNz696'),
    GetAuthServerUrl: () => `${Okta.GetHost()}/oauth2/${Okta.GetAuthServerId()}`,

    SetInjectedToken: (injected: string) => { injectedJwt = injected },

    SignInWithCredentials: async (
        username: string,
        password: string,
        options?: Partial<{
            onProgress: (step: SignInWithCredentialsStep) => void
        }>
    ): Promise<UserLoginWithVulcan> => {
        const failed = (debug: string, error?: any) => {
            const result = {
                accessToken: null,
                success: false,
                _debug: debug
            } as any
            console.log('[OKTA] Login failed', result)
            ErrorLogger.RecordError(error || new Error(`User was unable to log in: ${ debug }`), null, { info: { ...result, username } })
            return result;
        }
        try {
            options?.onProgress('get-session-token')
            const session = await Okta._getSessionToken(username, password)
            if (!session?.data) return failed(`Unable to retrieve session token from Okta, check credentials. ${JSON.stringify(session?.error.message)}`)
            
            options?.onProgress('get-token-from-proxy')
            const token = await Okta._getTokenFromSessionViaProxy(session.data.sessionToken, false)
            if (injectedJwt) token.access_token = injectedJwt;
            options?.onProgress('upgrade-vulcan-token')
            const vulcanToken = await VulcanAuth.UpgradeSecureAuthToken(token.access_token);
    
            // const token = await Okta._getTokenFromSessionViaProxy(session.data.sessionToken, true)
            if (!token?.access_token) return failed(`No token granted: ${JSON.stringify(token)}`)
            return Okta.ConvertOktaTokenToUserLogin(token, vulcanToken?.jwt)
        } catch (error) {
            return failed(`No token granted due to unexpected error`, error)
        }
        
    },

    ForgotPassword: {

        Start: async (username: string): Promise<{ success: boolean }> => {
            try {
                await axios.post(Urls.authentication.okta.forgotPassword.sendCode(), { email: username })
                return { success: true }
            } catch (e) {
                console.log('[OKTA] Password reset failure', JSON.stringify(e))
                return { success: false }
            }
        },

        // callbackUrl should be something like https://one.dev.stonex.com/api/auth/forgot-password/callback?otp=12345&state=abcdefghijklmnop
        RetrieveSessionFromCallbackDeeplink: async (callbackUrl: string): Promise<SessionKeyResponse> => {
            const apiUrl = `${callbackUrl}&format=json`
            try {
                const response = await axios.get<SessionKeyResponse>(apiUrl)
                return { sessionKey: response.data?.sessionKey }
            } catch (e) {
                console.log('[OKTA] Session retrieval failure', JSON.stringify(e))
                return { sessionKey: '' }
            }
        },

        UpdatePassword: async (sessionKey: string, newPassword: string): Promise<{ success: boolean, error?: any }> => {
            try {
                const response = await axios.post<SessionKeyResponse>(Urls.authentication.okta.forgotPassword.updatePassword(), { sessionKey, newPassword })
                return { success: true }
            } catch (e) {
                console.log('[OKTA] Password update failure', JSON.stringify(e))
                return { success: false, error: e }
            }
        }

    },

    SelfUnlock: async (username: string, factorType: 'EMAIL' | 'SMS' = 'EMAIL'): Promise<{ success: boolean }> => {
        try {
            const response = await axios.post(
                Urls.authentication.okta.unlock(),
                { username, factorType },
                { headers: { Accept: 'application/json' } }
            )
            console.log({ response: response?.data })
            return { success: true }
        } catch (e) {
            console.log('[OKTA] Password reset failure', JSON.stringify(e))
            return { success: false }
        }
    },

    ChangePassword: async (jwt: string, oldPassword: string, newPassword: string): Promise<{ success: boolean }> => {
        try {
            await axios.put(
                Urls.authentication.okta.changePasswordViaProxy(),
                { oldPassword, newPassword },
                { headers: { Accept: 'application/json', Authorization: `Bearer ${jwt}` } }
            )
            return { success: true }
        } catch (e) {
            const ae = e as AxiosError
            console.log('[OKTA] Password update failure', {
                response: ae.response?.data,
                status: ae.response?.status
            })
            return { success: false }
        }
    },

    RefreshToken: async (refreshToken: string): Promise<UserLoginWithVulcan> => {
        try {
            const response = await axios.get(Urls.authentication.okta.refreshViaProxy(refreshToken, false))
            if (response.data?.access_token) {
                const vulcanToken = await VulcanAuth.UpgradeSecureAuthToken(response.data?.access_token)
                if(!vulcanToken?.jwt) ErrorLogger.RecordMessage(`No vulcanToken found when upgrading secure auth token`, 'Token Refresh Failed', { info: { refreshToken, data: response.data, response, vulcanToken } })
                return Okta.ConvertOktaTokenToUserLogin(response.data as OktaTokenResponse, vulcanToken?.jwt)
            } else {
                ErrorLogger.RecordMessage(`No access_token found in the okta response`, 'Token Refresh Failed', { info: { refreshToken, data: response.data, response } })
                throw Error(`Unsuccessful refresh response: ${JSON.stringify(response.data)}`)
            }
        } catch (e) {
            console.log('[OKTA] Refresh Failure', JSON.stringify(e))
            ErrorLogger.RecordError(e, 'Token Refresh Failed')
            return null
        }
    },

    Mfa: {
        Sms: {
            Enroll: async (jwt: string, phone: string) => {
                await axios.post(Urls.authentication.okta.mfa.sms.enroll(), { smsPhone: phone }, { headers: { Authorization: `Bearer ${jwt}` } })
            },
            Challenge: async (jwt: string, phone?: string) => {
                await axios.post(Urls.authentication.okta.mfa.challenge(), { smsPhone: phone || '' }, { headers: { Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' } })
            },
            Verify: async (jwt: string, passCode: string) => {
                await axios.post(Urls.authentication.okta.mfa.verify(), { passCode }, { headers: { Authorization: `Bearer ${jwt}` } })
            }
        }
    },

    CheckIfLocked: async (email: string): Promise<boolean> => {
        try {
            const result = await axios.get<{isLocked: boolean}>(Urls.authentication.okta.checkIfLockedViaProxy(email))
            return result.data?.isLocked;
        } catch {
            return false
        }
    },

    ConvertOktaTokenToUserLogin: (o: OktaTokenResponse, vulcanToken?: string): UserLoginWithVulcan => {
        return {
            accessToken: o.access_token,
            expirationInSeconds: o.expires_in.toString(),
            refreshToken: o.refresh_token,
            role: undefined,
            tokenScheme: o.tokenType,
            success: !!o.access_token,
            vulcanToken
        }
    },

    _getSessionToken: async (username: string, password: string): Promise<OktaResponseWrapper<OktaSessionTokenResponse, OktaErrorResponseSimple>> => {
        try {
            const url = Urls.authentication.okta.authn();
            const res = await axios.post(url, {
                username,
                password,
                options: { multiOptionalFactorEnroll: false, warnBeforePasswordExpired: false }
            })

            if (res.data?.errorCode) return { error: res.data as OktaErrorResponseSimple }
            else if (res.data?.sessionToken) return { data: res.data as OktaSessionTokenResponse }
            else return { error: { message: `Okta response was unrecognizeable: ${ JSON.stringify(res.data || null) }; url=${ url }`, name: 'Error', stack: '' } }
        } catch (e) {
            console.log('[OKTA] Authentication Failure', JSON.stringify(e))
            return { error: e }
        }
    },

    _getTokenFromSessionViaProxy: async (sessionToken: string, upgradeToVulcan?: boolean): Promise<OktaTokenResponse> => {
        const url = Urls.authentication.okta.getTokenViaProxy(sessionToken, upgradeToVulcan)
        console.log(`[OKTA] Proxy token URL: ${url}`)
        try {
            const response = await axios.get<OktaTokenResponse>(url)
            console.log('[OKTA] Token success', response.data)
            return response.data
        } catch (e) {
            const ae = e as AxiosError
            console.log('[OKTA] Token failure', ae?.response?.data)
            return ae?.response?.data as OktaTokenResponse
        }
    },

    _getAuthorizationCodeDirect: async (clientId: string, sessionToken: string): Promise<CodeAndVerifier> => {
        const pkce = createPkcePair()
        const query = {
            client_id: clientId,
            response_type: 'code',
            scope: ['offline_access', 'openid', 'profile'].join('+'),
            redirect_uri: 'com.oktapreview.stonex-ciam:/callback',
            state: `state-${generateNonce(48, charsets.alphanum)}`,
            code_challenge_method: 'S256',
            code_challenge: pkce.code_challenge,
            sessionToken
        }

        try {
            const url = Urls.authentication.okta.authorize(query)
            console.log(`[OKTA] DEBUG: Authorization URL: ${url}`)
            const res = await fetch(url)
            // const res = await axios.get(url)
            console.log(`[OKTA] Authorization Code Failure: Expected redirect, got HTTP ${res.status}`)
        } catch (e) {
            // Weird Axios quirk-- if Axios gets a 302 (HTTP redirect), it interprets it as an error
            // thus why we actually return the successful code from here.
            // Sample location header: com.oktapreview.stonex-ciam:/callback?code=RO_bGVoxZcRUl8FFFI9EvZZ8JVnv2sqbuCrLMTirIwI&state=state-OEDnIRIjEJPYKStjyz7VSAzodZ0nBTztJ24w5kn3ddtEkMHX
            console.log('[OKTA] Error', e)
            const ae = e as AxiosError
            const location = ae.response?.headers?.location // Even if it isn't a 302 redirect we'll still try
            const code = QueryString.ParseFromFullUrl(location || '')?.['code']
            if (!code) {
                console.log('[OKTA] Unable to extract authorization code from header', JSON.stringify(e))
                return null
            }
            return {
                authorizationCode: code,
                codeVerifier: pkce.code_verifier
            }
        }

        return null
    },

    GetTokenFromCode: async (code: string, codeVerifier: string, redirectUri: string): Promise<UserLogin> => {
        try {
            const body = QueryString.Stringify(
                {
                    grant_type: 'authorization_code',
                    client_id: Okta.GetClientId(),
                    redirect_uri: redirectUri,
                    code,
                    code_verifier: codeVerifier
                },
                true
            )
            const response = await axios.post(Urls.authentication.okta.getToken(), body, {
                headers: {
                    accept: 'application/json',
                    'cache-control': 'no-cache'
                }
            })

            return Okta.ConvertOktaTokenToUserLogin(response.data)
        } catch (e) {
            const ae = e as AxiosError
            console.log('[OKTA] Token fetch error', ae?.response?.data)
        }

        return null
    },

    _generateNonce: generateNonce,
    _generatePkcePair: createPkcePair
}

export type OktaResponseWrapper<TSuccess, TError = OktaErrorResponse> = {
    error?: TError
    data?: TSuccess
}

export type OktaErrorResponseSimple = {
    message: string
    name: 'Error' | string
    stack: string
}

export type OktaErrorResponse = {
    errorCode: string
    errorSummary: string
    errorLink: string
    errorId: string
    errorCauses: any[]
}

export type OktaSessionTokenResponse = {
    stateToken?: string
    expiresAt: string
    status: 'SUCCEEDED' | 'FAILED' // Note: non-success value might not be FAILED, just guessing
    sessionToken: string
}

export type OktaTokenResponse = {
    access_token: string
    expires_in: number
    id_token?: string
    refresh_token: string
    scope: string
    tokenType: string
}

type SessionKeyResponse = {
    sessionKey: string
}

// From the Okta docs:
// Example output for PkcePair function: the below "verifier" string should result in the "challenge" string beneath it
// {
//     "code_verifier": "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag",
//     "code_challenge": "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es",
// }

// Sample authorizte URL
// https://auth.preview.stonex.com/oauth2/default/v1/authorize
//     ?client_id=0oa4uyn5p1iibabNJ1d7
//     &response_type=code
//     &scope=offline_access+openid+profile
//     &redirect_uri=com.oktapreview.stonex-ciam:/callback
//     &state=state-8600b31f-52d1-4dca-987c-386e3d8967e9
//     &code_challenge_method=S256
//     &code_challenge=3XIZVANNagmkWnJ3EDz00lt3Pvc_jk0OmLMTlcRRSjc
//     &sessionToken=
