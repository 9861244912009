// @ts-strict-ignore
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { AssetClass } from './AssetClass';
import { FuturesAssetClass } from './FuturesAssetClass';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { FormatNumber } from 'phoenix/util';
import { ChartRanges } from 'phoenix/constants';
import { Ts } from 'phoenix/assets/lang/T';
import { SecurityMetadataMix } from 'phoenix/stores/SecurityMetadataV2Store';

const FuturesTimeSpreadAssetClass: AssetClass = {
    ...FuturesAssetClass,
    derivative: 'time-spread',
    derivatives: [],
    type: 'futures-time-spread',
    canWatchlist: false,
    actionStyle: 'near-far',
    negativePriceAllowed: true,

    flags: {
        mobile: {
            view: FeatureFlags.FuturesTimeSpreads,
            trade: FeatureFlags.FuturesTrading
        },
        web: {
            view: FeatureFlags.FuturesTimeSpreads,
            trade: FeatureFlags.FuturesTrading
        }
    },

    hasDerivatives: () => FuturesTimeSpreadAssetClass?.derivatives.length > 1,
    getSymbolName: (s, lang, variant) => {
        const fsym = new FuturesSymbol(s);
        const near = new FuturesSymbol(fsym.nearMonthContract);
        const far = new FuturesSymbol(fsym.farMonthContract);
        switch (variant) {
            case 'month-only':
                return `${lang.general.monthsShort[near.month]} ${near.year & 100} - ${lang.general.monthsShort[far.month]} ${far.year % 100}`;
            case 'extra-short':
            case 'short':
                // Same as full, but the years are truncated to two digits: ZC May 23 - Dec 25
                return `${fsym.baseContractNoPrefix} ${lang.general.monthsShort[near.month]} ${near.year % 100} - ${lang.general.monthsShort[far.month]} ${
                    far.year % 100
                }`;
            case 'full':
            default:
                // e.g., ZC May 2023 - Dec 2025
                return `${fsym.baseContractNoPrefix} ${lang.general.monthsShort[near.month]} ${near.year} - ${lang.general.monthsShort[far.month]} ${far.year}`;
        }
    },
    getSymbolNameLines: (s, lang) => [FuturesTimeSpreadAssetClass?.getSymbolName(s, lang, 'full')],
    getBaseSymbol: (s) => new FuturesSymbol(s)?.baseContract,
    getConcreteSymbol: (s) => new FuturesSymbol(s)?.nearMonthContract,
    formatPrice: (price: number, meta?: SecurityMetadataMix) => FormatNumber.toMoneyOpt2(price, FuturesTimeSpreadAssetClass.getPriceFormatInfo(meta).moneyFormatOptions),
    getPriceFormatInfo: (meta: SecurityMetadataMix) => {
        const futuresInfo = FuturesAssetClass.getPriceFormatInfo(meta);

        return {
            ...futuresInfo,
            moneyFormatOptions: {
                ...futuresInfo.moneyFormatOptions,
                allowNegative: FuturesTimeSpreadAssetClass.negativePriceAllowed
            }
        };
    },

    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.oneDay, value: ChartRanges.oneDay },
            { label: text.fiveDay, value: ChartRanges.fiveDays },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.threeMonths, value: ChartRanges.threeMonths },
            { label: text.oneYear, value: ChartRanges.oneYear },
            { label: text.fiveYear, value: ChartRanges.fiveYears }
        ];
    },
    accountChartRanges: FuturesAssetClass?.accountChartRanges,
    securityChartRanges: FuturesAssetClass?.securityChartRanges,
};

export default FuturesTimeSpreadAssetClass;
