import { ReduxAction } from '../../models'
import { GetEnableLightstreamerErrorLogging } from '../../util'
import { Actions } from '../actions/Constants'
import { ErrorsState } from '../models/Errors/ErrorsState'

const MAX_ERROR_STORAGE = 50

export type Reducer<TState> = (state: TState, action: ReduxAction) => TState;

export const ErrorsReducer: Reducer<ErrorsState> = (state = new ErrorsState(), action) => {
    if (action.type === Actions.Errors.ReportApiError) {
        const e = { ...action.error, requestBody: action.requestBody, subject: action.subject, display: action.errorDisplay }
        return {
            ...state,
            apiErrors: {
                latest: e,
                all: [e, ...state.apiErrors.all.slice(0, MAX_ERROR_STORAGE - 1)]
            }
        }
    }

    if (action.type === Actions.Errors.Clear) {
        return new ErrorsState()
    }

    // Also print out stream terminations
    if (GetEnableLightstreamerErrorLogging() && /_TERMINATED$/.test(action.type)) {
        const e = { errorMessage: `Subscription for ${JSON.stringify(action.subject)} was terminated`, ...action.error, requestBody: action.requestBody, subject: action.subject, display: 'toast' }
        return {
            ...state,
            apiErrors: {
                latest: e,
                all: [e, ...state.apiErrors.all.slice(0, MAX_ERROR_STORAGE - 1)]
            }
        }
    }

    return state
}
