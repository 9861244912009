// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AdminState } from '../models/Admin/AdminState'
import { SystemStatus } from '../models/System/SystemStatus'

const permitted = GroupNameChecker([Actions.Admin])

export const AdminReducer = (state: AdminState = new AdminState(), action: ReduxAction): AdminState => {
    if (!permitted(action)) return state

    switch (action.type) {
        case Actions.Admin.GetSearchedUser.Loading: return { ...state, searchedUser: state.searchedUser.startLoading() }
        case Actions.Admin.GetSearchedUser.Success: return { ...state, searchedUser: state.searchedUser.succeeded(action.data) }
        case Actions.Admin.GetSearchedUser.Failure: return { ...state, searchedUser: state.searchedUser.failed(action.error) }

        case Actions.Admin.UpdateSearchedUser.Loading: return { ...state, searchedUser: state.searchedUser.startLoading() }
        case Actions.Admin.UpdateSearchedUser.Success: return { ...state, searchedUser: state.searchedUser.succeeded(action.data) }
        case Actions.Admin.UpdateSearchedUser.Failure: return { ...state, searchedUser: state.searchedUser.failed(action.error) }

        case Actions.Admin.BypassEnrollment.Loading: return { ...state, bypassEnrollementOperation: state.bypassEnrollementOperation.startLoading() }
        case Actions.Admin.BypassEnrollment.Success: return { ...state, bypassEnrollementOperation: state.bypassEnrollementOperation.succeeded() }
        case Actions.Admin.BypassEnrollment.Failure: return { ...state, bypassEnrollementOperation: state.bypassEnrollementOperation.failed(action.error) }

        case Actions.Admin.DemoteUserToFree.Loading: return { ...state, demoteUserToFreeOperation: state.demoteUserToFreeOperation.startLoading() }
        case Actions.Admin.DemoteUserToFree.Success: return { ...state, demoteUserToFreeOperation: state.demoteUserToFreeOperation.succeeded() }
        case Actions.Admin.DemoteUserToFree.Failure: return { ...state, demoteUserToFreeOperation: state.demoteUserToFreeOperation.failed(action.error) }

        case Actions.Admin.GetFullSystemStatus.Loading: return { ...state, systemStatus: state.systemStatus.startLoading(state.systemStatus.data) }
        case Actions.Admin.GetFullSystemStatus.Success: {
            const status = (action.data as SystemStatus[]).map(x => {
                return { ...x, asOf: new Date(x.asOf), lastAvailable: new Date(x.lastAvailable) }
            })
            return { ...state, systemStatus: state.systemStatus.succeeded(status) }
        }
        case Actions.Admin.GetFullSystemStatus.Failure: return { ...state, systemStatus: state.systemStatus.failed(action.error) }
        case Actions.Admin.GetSymbologyOverrides.Loading: return { ...state, symbologyOverrides: state.symbologyOverrides.startLoading() }
        case Actions.Admin.GetSymbologyOverrides.Success: return { ...state, symbologyOverrides: state.symbologyOverrides.succeeded(action.data) }
        case Actions.Admin.GetSymbologyOverrides.Failure: return { ...state, symbologyOverrides: state.symbologyOverrides.failed(action.error) }
      
        default: return state
    }
}
