// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions } from '../actions/Constants'
import { PositionsState, PositionsSummary } from '../models/Positions'
import { orNew } from './ReducerUpdateHelpers'

export const PositionsReducer = (state: PositionsState = new PositionsState(), action: ReduxAction): PositionsState => {
    switch (action.type) {
        case Actions.Positions.GetAll.Loading: return { ...state, all: state.all.startLoading(state.all.data) }
        case Actions.Positions.GetAll.Success:
            return {
                ...state,
                all: state.all.succeeded(action.data.map((p: PositionsSummary) => ({
                    ...p,
                    positions: p.positions.map(pos => ({
                        ...pos,
                        gainLossBasis: pos.todaysGL && pos.marketValue ? (pos.marketValue - Number(pos.todaysGL)) : pos.marketValue
                    }))
                })))
            }
        case Actions.Positions.GetAll.Failure: return { ...state, all: state.all.failed(action.error) }

        case Actions.Positions.GetAllUnpriced.Success: {
            const newList = orNew(state.all).succeeded(action.data)
            return { ...state, all: newList }
        }

        case Actions.Positions.Closed.Loading: return { ...state, closed: state.closed.startLoading(state.closed.data) }
        case Actions.Positions.Closed.Success: return { ...state, closed: state.closed.succeeded(action.data) }
        case Actions.Positions.Closed.Failure: return { ...state, closed: state.closed.failed(action.error) }

        case Actions.Positions.GetRealizedPnl.Loading: return {
            ...state,
            closed: state.closed.succeeded((state.closed.data || []).map(group => group.symbol === action.subject
                ? ({ ...group, items: group.items.startLoading() })
                : group
            ))
        }
        case Actions.Positions.GetRealizedPnl.Success: return {
            ...state,
            closed: state.closed.succeeded((state.closed.data || []).map(group => group.symbol === action.subject
                ? ({ ...group, items: group.items.succeeded(action.data) })
                : group
            ))
        }
        case Actions.Positions.GetRealizedPnl.Failure: return {
            ...state,
            closed: state.closed.succeeded((state.closed.data || []).map(group => group.symbol === action.subject
                ? ({ ...group, items: group.items.failed(action.error) })
                : group
            ))
        }
        default: return state
    }
}
