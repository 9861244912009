import { Check } from '@mui/icons-material';
import { Button, CardContent, TextField, Typography } from '@mui/material';
import { useAppDispatch } from 'AppRoot';
import { Card, Flex, LoadingSpinner } from 'components';
import Logo from 'components/Logo';
import { XBackgroundScreen } from 'components/XBackgroundScreen/XBackgroundScreen';
import { DecodeJwt } from 'phoenix/util';
import React, { useEffect, useState } from 'react';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import { Routes } from 'util/Routes';
import { SubmitLocalJwt, NormalizeJwtSource } from 'util/TokenUtil';

export const LocalStartScreen = (): JSX.Element => {
    const [jwt, setJwt] = useState('');
    const [jwtSuccessMessage, setJwtSuccessMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const canSubmit = !!jwt || loading;
    const dispatch = useAppDispatch();
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Set Base Layout Props
    useEffect(() => {
        setBaseLayoutProps({ noShow: true });
    }, [setBaseLayoutProps]);

    const handleSubmit = async () => {
        setLoading(true);
        await SubmitLocalJwt(jwt, dispatch);
        setLoading(false);
        window.location.href = Routes.portfolio();
    };

    useEffect(() => {
        const normalizedJwt = NormalizeJwtSource(jwt);
        const token = normalizedJwt ? DecodeJwt(normalizedJwt.toString()) : null;
        if (token && token.payload.sub) setJwtSuccessMessage(`Recognized subject ${token.payload.sub}`);
    }, [jwt]);

    return (
        <XBackgroundScreen>
            <Flex column style={{ height: '100vh', width: '100vw' }} center align='center'>
                <Card>
                    <CardContent>
                        <Flex column fullWidth align='center' center style={{ alignItems: 'center' }}>
                            <div style={{ width: 300 }}>
                                <Logo />
                            </div>
                        </Flex>
                    </CardContent>
                    <CardContent>
                        <Flex column fullWidth align='center' center style={{ alignItems: 'center' }}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <Flex column style={{ width: 460 }}>
                                    <TextField
                                        multiline
                                        label='JWT or Token JSON'
                                        rows={6}
                                        style={{ margin: '16px 0', fontFamily: 'monospace' }}
                                        variant='outlined'
                                        onChange={(x) => setJwt(x.target.value)}
                                    />
                                    <Button color='primary' disabled={!canSubmit} style={{ margin: '16px 0' }} type='submit' variant='contained'>
                                        <Flex row>
                                            <Typography variant='h5'>Log In</Typography>
                                            {loading ? <LoadingSpinner size={18} /> : null}
                                        </Flex>
                                    </Button>
                                    {jwtSuccessMessage && (
                                        <Flex row fullWidth center align='center' style={{ color: 'green' }}>
                                            <Check />
                                            <Typography> {jwtSuccessMessage} </Typography>
                                        </Flex>
                                    )}
                                </Flex>
                            </form>
                        </Flex>
                    </CardContent>
                </Card>
            </Flex>
        </XBackgroundScreen>
    );
};
