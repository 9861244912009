import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { MessagesStore_HandleMessageUpdate } from './MessagesStore';

const newOrderMessage: SnexMessage = {
    id: 'TestOrderUpdate',
    type: 'DebugMessage',
    payloadType: 'OrderUpdate:New',
    imageUrl: 'https://snex1storage.blob.core.windows.net/$web/logos/SNEX.jpg.png',
    message: 'Your limit buy order for 1.00 x TEST on account ****TEST is now open.',
    title: 'New Order',
    sendDate: new Date().toString(),
    data: {
        type: 'OrderUpdate',
        placedDate: new Date().toString(),
        completedDate: null,
        accountNumber: '28584836',
        symbol: 'ZVZZT',
        cusip: '999998883',
        isin: 'US9999988831',
        securityNumber: '2796289',
        securityName: 'TEST',
        orderId: 'TestOrderUpdate',
        orderNumber: 'TestOrderUpdate',
        rootOrderId: null,
        orderStatus: 'Open',
        orderQuantity: 1,
        quantityQualifier: null,
        cost: null,
        leavesQuantity: 1,
        filledQuantity: 0,
        lastFilledQuantity: null,
        action: 'Buy',
        limitPrice: 5,
        stopPrice: null,
        filledPrice: null,
        averageTradePriceAmount: null,
        averageUnitPrice: null,
        timeInForce: 'NTE',
        commissionAmount: null,
        canCancel: true,
        isCancelable: true,
        enteredBy: 'Roman Chvalbo',
        unitQuantity: 1,
        floorNote: '',
        notHeld: false,
        strategy: 'SOR',
        onClose: false,
        fills: [],
        isOptionOrder: false,
        orderWarnings: ['DEBUG ORDER']
    }
};

const partialFillOrderMessage: SnexMessage = {
    id: 'TestOrderUpdate',
    type: 'DebugMessage',
    payloadType: 'OrderUpdate:PartialFilled',
    title: 'Partial Fill Order',
    imageUrl: 'https://snex1storage.blob.core.windows.net/$web/logos/SNEX.jpg.png',
    message: 'Your limit buy order for 3.00 x TEST on account ****TEST has been partially filled.',
    sendDate: new Date().toString(),
    data: {
        type: 'OrderUpdate',
        placedDate: new Date().toString(),
        completedDate: new Date().toString(),
        accountNumber: '28584836',
        symbol: 'ZVZZT',
        cusip: '999998883',
        isin: 'US9999988831',
        securityNumber: '2796289',
        securityName: 'TEST',
        orderId: 'TestOrderUpdate',
        orderNumber: 'TestOrderUpdate',
        rootOrderId: null,
        orderStatus: 'Open',
        orderQuantity: 3,
        quantityQualifier: null,
        cost: 68,
        leavesQuantity: 1,
        filledQuantity: 2,
        lastFilledQuantity: null,
        action: 'Buy',
        limitPrice: 34,
        stopPrice: null,
        filledPrice: null,
        averageTradePriceAmount: 34,
        averageUnitPrice: 34,
        timeInForce: 'GoodTillCancel',
        commissionAmount: 0,
        canCancel: true,
        isCancelable: true,
        enteredBy: 'Pravin Chavan',
        unitQuantity: 1,
        parentOrderIds: [],
        floorNote: '',
        notHeld: false,
        strategy: 'SOR',
        onClose: false,
        fills: [
            {
                symbol: null,
                quantity: 2,
                price: 34,
                sequenceNumber: 1,
                timestamp: '2024-08-14T16:01:29.33655Z'
            }
        ],
        isOptionOrder: false,
        orderWarnings: ['DEBUG ORDER']
    }
};

const filledOrderMessage: SnexMessage = {
    id: 'TestOrderUpdate',
    type: 'notification',
    payloadType: 'OrderUpdate:Filled',
    title: 'Filled Order',
    imageUrl: 'https://snex1storage.blob.core.windows.net/$web/logos/SNEX.jpg.png',
    message: 'Your limit buy order for 3.00 x TEST on account ****TEST has been filled.',
    sendDate: new Date().toString(),
    data: {
        type: 'OrderUpdate',
        placedDate: new Date().toString(),
        completedDate: new Date().toString(),
        accountNumber: '28584836',
        symbol: 'ZVZZT',
        cusip: '999998883',
        isin: 'US9999988831',
        securityNumber: '2796289',
        securityName: 'TEST',
        orderId: 'TestOrderUpdate',
        orderNumber: 'TestOrderUpdate',
        rootOrderId: null,
        orderStatus: 'Filled',
        orderQuantity: 3,
        quantityQualifier: null,
        cost: 68,
        leavesQuantity: 3,
        filledQuantity: 3,
        lastFilledQuantity: null,
        action: 'Buy',
        limitPrice: 34,
        stopPrice: null,
        filledPrice: null,
        averageTradePriceAmount: 34,
        averageUnitPrice: 34,
        timeInForce: 'GoodTillCancel',
        commissionAmount: 0,
        canCancel: true,
        isCancelable: true,
        enteredBy: 'Pravin Chavan',
        unitQuantity: 1,
        parentOrderIds: [],
        floorNote: '',
        notHeld: false,
        strategy: 'SOR',
        onClose: false,
        fills: [
            {
                symbol: null,
                quantity: 3,
                price: 34,
                sequenceNumber: 1,
                timestamp: '2024-08-14T16:01:29.33655Z'
            }
        ],
        isOptionOrder: false,
        orderWarnings: ['DEBUG ORDER']
    }
};

const cancelledOrderMessage: SnexMessage = {
    id: 'TestOrderUpdate',
    type: 'notification',
    payloadType: 'OrderUpdate:Canceled',
    title: 'Cancelled Order',
    imageUrl: 'https://snex1storage.blob.core.windows.net/$web/logos/SNEX.jpg.png',
    message: 'Your limit buy order for 3.00 x TEST on account ****TEST has been cancelled.',
    sendDate: new Date().toString(),
    data: {
        type: 'OrderUpdate',
        placedDate: new Date().toString(),
        completedDate: new Date().toString(),
        accountNumber: '28584836',
        symbol: 'ZVZZT',
        cusip: '999998883',
        isin: 'US9999988831',
        securityNumber: '2796289',
        securityName: 'TEST',
        orderId: 'TestOrderUpdate',
        orderNumber: 'TestOrderUpdate',
        rootOrderId: null,
        orderStatus: 'Cancelled',
        orderQuantity: 3,
        quantityQualifier: null,
        cost: 68,
        leavesQuantity: 3,
        filledQuantity: 0,
        lastFilledQuantity: null,
        action: 'Buy',
        limitPrice: 34,
        stopPrice: null,
        filledPrice: null,
        averageTradePriceAmount: 34,
        averageUnitPrice: 34,
        timeInForce: 'GoodTillCancel',
        commissionAmount: 0,
        canCancel: true,
        isCancelable: true,
        enteredBy: 'Pravin Chavan',
        unitQuantity: 1,
        parentOrderIds: [],
        floorNote: '',
        notHeld: false,
        strategy: 'SOR',
        onClose: false,
        fills: [],
        isOptionOrder: false,
        orderWarnings: ['DEBUG ORDER']
    }
};

export const SendMockOrderUpdateMessage = async (mock: 'New' | 'Filled' | 'Partial Fill' | 'Canceled', orderId?: string, symbol?: string): Promise<void> => {
    let message: SnexMessage;
    switch (mock) {
        case 'New':
            message = structuredClone(newOrderMessage);
            break;
        case 'Partial Fill':
            message = structuredClone(partialFillOrderMessage);
            break;
        case 'Filled':
            message = structuredClone(filledOrderMessage);
            break;
        case 'Canceled':
            message = structuredClone(cancelledOrderMessage);
            break;
        default:
            message = structuredClone(newOrderMessage);
    }

    message.sendDate = new Date().toString();
    message.data.placedDate = new Date().toString();
    message.data.completedDate = mock === 'New' ? null : new Date().toString();
    message.data.symbol = symbol || message.data.symbol;
    message.data.orderId = orderId || message.data.orderId;
    message.data.orderNumber = orderId || message.data.orderNumber;

    const opts = {
        fetchPositionsOnOrderUpdates: true,
        fetchOpenOrdersOnRebook: true
    };
    await MessagesStore_HandleMessageUpdate(message, opts);
};
