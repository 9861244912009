// Telemetry
import { GetEnableDebugLogging } from 'phoenix/util';
import { Environment, ResolveEnvironment } from "phoenix/constants";

let _env: Environment | null = null
let _useDebug = false

const AppIds = {
    pro: 'stonexonepro',
    default: 'stonexone'
}

const GetAppId = () => {
    switch(_env) {
        case 'pro': return AppIds.pro
        default: return AppIds.default
    }
}

const Init = () => {
    if (!_env) {
        _env = ResolveEnvironment();
        _useDebug = GetEnableDebugLogging();
    }
}

export const LogEvent = (eventLabel: string, properties?: Record<string, unknown>, debug?: Record<string, unknown>, category?: string): void => {    
    Init();
    const ev = { event: 'eventTracker', ...properties, env: _env, appID: GetAppId(), debug: { ...debug }, eventLbl: eventLabel, eventCat: category };
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push(ev);
    // @ts-ignore
    if (_useDebug) console.log(`[TELE] ${ eventLabel }`, ev, window.dataLayer);
}


// Still need to update this for GA 360
export const LogError = (error: Error, type?: string, properties?: any) => {
    Init();
    const ev = { event: 'error', error, type, ...properties };
    if (_useDebug) console.log(`[TELE] ERROR ${ type }`, ev);
}

/* would love to expand this to use debug levels */
export const LogDebug = (message?: unknown, ...optionalParams: unknown[]): void => {
    if(_useDebug) console.log(message, ...optionalParams);
}