// @ts-strict-ignore
import { T } from '../../assets/lang/T';
import { GetConfig, Urls } from '../../constants';
import { ApiError, ReduxAction } from '../../models';
import { QualifiedSecurityId } from '../../models/QualifiedSecurityId';
import { IsMutualFundByMetadata, UniqueBy } from '../../util';
import { TradeHelpers } from '../../util/TradeHelpers';
import { Order } from '../models';
import { NormalizeTrade, TradeRequest } from '../models/Trading/TradeRequest';
import { Actions } from './Constants';
import { ReduxApiDelete, ReduxApiGet, ReduxApiPost, ReduxApiPut, ReduxApiResponse } from './Helpers';
import { GetSingleOrderAction } from './OrdersActions';

/** @deprecated Please use TradeHelpers.submitTrade helper functions */
export const SubmitTradeAction = (trade: Partial<TradeRequest> | TradeRequest): ReduxApiResponse<Order> =>
    ReduxApiPost<Order>(Urls.trading.submit(), Actions.Trading.Submit, NormalizeTrade(trade))
        .onSuccess((e: any) => {
            const cleanTrade = { ...trade };
            delete cleanTrade.accountNumber;
            return e;
        })
        .onError((e: any) => {
            const cleanTrade = { ...trade, error: e.errorMessage || e.errorCode };
            delete cleanTrade.orderId;
            delete cleanTrade.accountNumber;
            return { ...e, failed: true };
        })
        .withLoading()
        .withAsyncOperationIndicator()
        .withErrorMessage(T((s) => s.errors.trade.generalSubmit))
        .run();

// Note: this blacklist is no longer used, but is kept for reference
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- see above
const orderWarningBlacklist = [
    /^6128/, // Fund status indicator changed to "Exists"
    /^6003/, // Off-conflict, branch + wire code don't match
    /^9004/, // State registration not found for rep
    /^5737/, // A fee will apply to this order
    /^5306/, // Order going to "N queue"
    /^8032/, // Margin position
    /^8118/, // Order may result in a wash sale
    /^0000/, // Order After Cutoff
    /^6015/, // Past order cutoff time
    /^5841/, // "No stck rec, cln sh com not calculated"
    /^8008/, // "Requries 100%"
    /^8028/, // "Cash Position"
    /^8042/, // "Option Position"
    /^8093/, // Past exchange cutoff... order change to overnight
    /^8031/, // Margin position.
    /^6832/ // Order > Total Position
];

/** @deprecated Please use TradeHelpers.validate* helper functions */
export const VerifyTradeAction = (trade: Partial<TradeRequest> | TradeRequest) =>
    // prettier-ignore
    (trade.edit ? ReduxApiPut : ReduxApiPost)(
        trade?.edit ? Urls.trading.verifyUpdate(trade.orderId) : Urls.trading.verify(),
        Actions.Trading.Verify,
        NormalizeTrade(trade)
    )
        .onBeforeSend(() => {
            const qsi = QualifiedSecurityId.FromTradeRequest(trade).toString();
            const meta = GetConfig()?.Store?.getState()?.securities.bySymbol[qsi]?.metadata?.data;

            const invalidStopOrder = TradeHelpers.IsInvalidStopOrder(qsi, trade);
            if (invalidStopOrder) throw <ApiError>{ errorCode: trade.action === 'Sell' ? 'INVALID_SELL_STOP_ORDER' : 'INVALID_BUY_STOP_ORDER' };

            // Note: for edits, we always assume affordability since otherwise we'd get into buying power hell;
            // we just let the back-end handle it. Same story with covered calls. Beta will catch put sells... so those get a pass as well
            const canAfford = trade.allowShort && trade.action === 'Sell' ? true : TradeHelpers.UserCanAffordTrade(qsi, trade, meta, false);
            if (!canAfford) throw <ApiError>{ errorCode: trade.action === 'Sell' ? 'CANNOT_AFFORD_SELL' : 'CANNOT_AFFORD_BUY' };

            if (TradeHelpers.ExceedsUsersMaxNotionalValue(qsi, trade, meta)) throw <ApiError>{ errorCode: 'MAX_NOTIONAL_VALUE_EXCEEDED' };

            // handle mutual fund minimum investments
            if (trade.action === 'Buy') {
                // splitting if statements to cut down on non-required calls for speed
                if (IsMutualFundByMetadata(meta)) {
                    if (trade.sharesHeld < 1 && trade.estimatedTotalCost < trade.minimumInvestment) {
                        throw <ApiError>{ errorCode: 'FUND_MINIMUM_NOT_MET' };
                    }
                }
            }
        })
        .onError((e: ApiError) => ({ ...e, failed: true }))
        .onSuccess((o: Order) => {
            o.orderWarnings = UniqueBy(
                (o.orderWarnings || [])
                    .map((w) => {
                        if (/Allowed Short Sale/.test(w)) {
                            return T((s) => s.warnings.trade.isShort);
                        }
                        if (/POSSIBLE DUPLICATE/.test(w) && !trade.edit) return T((s) => s.warnings.trade.possibleDuplicate);
                        if (/^8046/.test(w)) return T((s) => s.warnings.trade.accountChangedToMargin);
                        if (/^8047/.test(w)) return trade.allowShort ? null : T((s) => s.warnings.trade.accountChangedToCash);
                        if (/^8070/.test(w)) return T((s) => s.warnings.trade.optionLevel);
                        else return null;
                    })
                    .filter((w) => !!w),
                (i) => i
            );

            // if options and low volume add low volume warning
            if (TradeHelpers.IsLowVolumeOption(trade)) {
                o.orderWarnings.push(T((s) => s.warnings.trade.optionsLowVolumeWarning));
            }

            // Warn the user of residual settlements
            if (TradeHelpers.OrderWillTriggerResidualSettlement(trade)) {
                o.orderWarnings.push(T((s) => s.tradeTicket.misc.residualSharesWillBeSettled));
            }

            return o
        })
        .withLoading()
        .withErrorMessage(T(s => s.errors.trade.generalVerify))
        .withErrorTransform('INVALID_BRANCH', T(s => s.errors.trade.sxDenied))
        .withErrorTransform('ORD_GENERAL', T(s => s.errors.trade.generalVerify))
        .withErrorTransform('INSUFF_OPTION_LEVEL', T(s => s.errors.trade.optionLevel))
        .withErrorTransform('ORD_OUT_OF_SESSION', T(s => s.errors.trade.securityOutOfSession))
        .withErrorTransform('ORD_CANT_AFFORD', T(s => s.warnings.trade.cannotAfford(trade)))
        .withErrorTransform('ORD_MINIMUM_NOT_MET', T(s => s.warnings.trade.minimumNotMet))
        .withErrorTransform('ORD_NICKEL', T(s => s.errors.trade.nickelProduct))
        .withErrorTransform('ACCT_RESTRICTED', T(s => s.errors.trade.accountRestricted))
        .withErrorTransform('NOT_FOUND_PRICE', T(s => s.errors.trade.securityUnavailable))
        .withErrorTransform('CANNOT_AFFORD_BUY', T(s => s.warnings.trade.cannotAfford(trade)))
        .withErrorTransform('SHORT_NOTPERMITTED_LONG', T(s => s.warnings.trade.shortsNotPermittedWhenLong(trade)))
        .withErrorTransform('BUY_LONG_EXCEEDS_SHORTS_HELD', T(s => s.warnings.trade.buyExceedsShortsHeld(trade)))
        .withErrorTransform('CANNOT_AFFORD_SELL', T(s => s.warnings.trade.cannotAfford(trade)))
        .withErrorTransform('ACCT_FOREIGN', T(s => s.warnings.trade.foreignAcctDomesticFunds))
        .withErrorTransform('FUND_FORBID', T(s => s.errors.trade.fundTradingDenied))
        .withErrorTransform('TRD_FORBID', T(s => s.errors.trade.basicTradingDenied))
        .withErrorTransform('ORD_NO_BUY', T(s => s.errors.trade.noBuy))
        .withErrorTransform('ORD_OPT_AGREEMENT', T(s => s.errors.trade.optionAgreement))
        .withErrorTransform('ORD_OPT_LEVEL', T(s => s.errors.trade.optionLevel))
        .withErrorTransform('ORD_OPT_PERM', T(s => s.errors.trade.optionPermission))
        .withErrorTransform('ORD_OPT_POSITION', T(s => s.errors.trade.noOptionPosition))
        .withErrorTransform('ORD_OFF_HOURS', T(s => s.errors.trade.offHours))
        .withErrorTransform('FUND_MINIMUM_NOT_MET', T(s => s.tradeTicket.misc.minimumNotMetSubtext(trade.minimumInvestment)))
        .withErrorTransform('ORD_CLOSED_TO_NEW', T(s => s.tradeTicket.misc.closedToNewInvestors))
        .withErrorTransform('ORD_SELL_STOP_LESSTHAN_MARKET', T(s => s.errors.trade.sellStopLessThanMarket))
        .withErrorTransform('MAX_NOTIONAL_VALUE_EXCEEDED', T(s=>s.tradeTicket.input.advisories.notionalValueExceeded.subText))
        .run();

export const ResetVerifyTradeAction = () => ({ type: Actions.Trading.ResetVerify });

/** @deprecated Please use TradeHelpers.updateTrade */
export const EditTradeAction = (trade: Partial<TradeRequest> | TradeRequest) =>
    ReduxApiPut(Urls.trading.edit(trade.orderId), Actions.Trading.Edit, NormalizeTrade(trade))
        .withAsyncOperationIndicator()
        .withSubject(trade.orderId)
        .showToasts()
        .withLoading()
        .run();

/** @deprecated Please use TradeHelpers.cancelTrade */
export const CancelTradeAction = (orderId: string) =>
    ReduxApiDelete(Urls.trading.cancel(orderId), Actions.Trading.Cancel)
        .withSubject(orderId)
        .withAsyncOperationIndicator()
        .onSuccess((_, dispatch: any) => {
            dispatch(GetSingleOrderAction(orderId, false));
        })
        .showToasts()
        .withLoading()
        .run();

export const RemoveOrderFromReduxStoreAction = (orderId: string): Partial<ReduxAction> => ({ type: Actions.Trading.RemoveOrder, subject: orderId });

export const GetLoanRateAction = (symbol: string) => ReduxApiGet(Urls.trading.getStockLoanRate(symbol), Actions.Trading.GetLoanRate).run();
// .almostRun(true, {
//    processDate: "2022-11-22T20:15:32.494Z",
//    timestamp:"2022-11-22T20:15:32.494Z",
//    symbol: "TSLA",
//    secNo: 0,
//    cusip: "",
//    sedol: "",
//    isin: "",
//    description: "string",
//    retailLoanRateAvg: 0,
//    adjustedRate: Math.random() * 10
// })
