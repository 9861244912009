// @ts-strict-ignore
import { SnexLsClient } from './models/SnexLsClient'
import { SnexLsClientListener } from './models/SnexLsClientListener'
import { SnexLsSubscription } from './models/SnexLsSubscription'
import { SnexLsBackEnd } from './SnexLsBackEnd'

export class NoopLsBackEnd implements SnexLsBackEnd {
    user: string;
    password: string;

    createClient (host: string, adapterSet: string): SnexLsClient { return new SnexLsClient({ name: 'dummy-client', host, adapterSet }) }

    getSessionId (client: SnexLsClient): string { return 'DUMMY_SESSION_ID' }
    getStatus (client: SnexLsClient): string { return 'DISCONNECTED' }

    getUser (client: SnexLsClient): string { return this.user }
    setUser (client: SnexLsClient, user: string): void { squawk('setting the LS user'); this.user = user }
    setPassword (client: SnexLsClient, password: string): void { squawk('setting the LS password'); this.password = password }

    addListener (client: SnexLsClient, listener: SnexLsClientListener): void { squawk('add a listener') }
    connect (client: SnexLsClient): void { squawk('connecting') }
    disconnect (client: SnexLsClient): void { squawk('disconnect') }
    subscribe (client: SnexLsClient, subscription: SnexLsSubscription): void { squawk(`adding a subscription to ${subscription.getItems().join('/')}`) }
    unsubscribe (client: SnexLsClient, subscription: SnexLsSubscription): void { squawk(`unsubscribing from ${subscription.getItems().join('/')}`) }
}

const squawk = (thingYouJustDid: string) => { console.log(`Notice! You are using the default no-op lightstreamer backend; ${thingYouJustDid} is not going to do anything.`) }
