import React from 'react';
import { LoadingSpinner } from 'components';
import { useRelay } from 'phoenix/hooks/UseRelay';
import { Relays } from 'constants/Relays';
import DeniedScreen from 'screens/DeniedScreen/DeniedScreen';
import Logo from 'components/Logo';
import './style.css';

const SplashScreen = () => {
    const [loginDead] = useRelay(Relays.DeadLogin);

    return loginDead ? (
        <DeniedScreen />
    ) : (
        <section className='splash-screen'>
            <div className='image-wrapper'>
                <Logo />
                <LoadingSpinner size={75} />
            </div>
        </section>
    );
};

export default SplashScreen;
