// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { FeatureFlag } from '../models/FeatureFlags/FeatureFlag'
import { FeatureFlagState } from '../models/FeatureFlags/FeatureFlagState'

const check = GroupNameChecker([Actions.FeatureFlags])
export const FeatureFlagReducer = (state: FeatureFlagState = new FeatureFlagState(), action: ReduxAction): FeatureFlagState => {
    if (!check(action)) return state

    switch (action.type) {
        case Actions.FeatureFlags.GetAll.Loading: return { ...state, all: state.all.startLoading(state.all.data) }
        case Actions.FeatureFlags.GetAll.Success: {
            const flags = action.data as FeatureFlag[]
            return {
                ...state,
                all: state.all.succeeded(flags),
                byId: flags.reduce((lookup, next) => ({ ...lookup, [next.id]: next }), {})
            }
        }
        case Actions.FeatureFlags.GetAll.Failure: return { ...state, all: state.all.failed(action.error) }

        default: return state
    }
}
