import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { StorageKeys } from 'phoenix/constants';
import { FormatNumber, ChangeColor } from 'phoenix/util';
import { GetColors } from 'phoenix/theming/Colors';

export const ChangeText = React.memo(
    (props: {
        bold?: boolean;
        colorNaNOnly?: boolean;
        decimalPlaces?: number;
        figure?: number;
        formatValue?: (value: number) => string;
        toolTipText?: string;
        type?: 'money' | 'percent' | 'decimal';
    }) => {
        const { decimalPlaces = 2 } = props;
        const thresh = props.type === 'money' ? 0.01 : 0.0001;
        const handler =
            props?.formatValue ||
            (props.type !== 'percent' ? (value) => FormatNumber.toLocaleDecimal({ decimalPlaces, value, signDisplay: props.type === 'money' }) : FormatNumber.toPercent);

        const { text, color } = (() => {
            const appTheme = window.localStorage.getItem(StorageKeys.AppTheme);
            const colors = GetColors(JSON.parse(appTheme || ''));
            if (!props.figure || !isFinite(props.figure)) return { color: 'grey', text: handler(NaN) };
            else {
                return {
                    color: props.figure < thresh && props.figure > -thresh ? 'grey' : props.colorNaNOnly ? colors.generalTextColor : ChangeColor(props.figure),
                    text: handler(props.figure)
                };
            }
        })();

        if (props.toolTipText) {
            return (
                <Tooltip title={props.toolTipText}>
                    <Typography style={{ color }} variant={props.bold ? 'h6' : 'body2'}>
                        {text}
                    </Typography>
                </Tooltip>
            );
        }
        return (
            <Typography style={{ color }} variant={props.bold ? 'h6' : 'body2'}>
                {text}
            </Typography>
        );
    }
);
