import { create as _create, StateCreator } from "zustand";

type Category = "all" | "mobile" | "phoenix";

const storeResetFns = new Map<Category, Set<() => void>>();

/**
 * Wrapper over the create method of Zustand to keep track of categories of stores,
 * allowing them to be easily cleared later.
 * 
 * @param categories - An array of categories the store belongs to.
 * @returns A function that creates a Zustand store with the specified state creator.
 */
export const createClearable = (categories: Category[] = []) =>
	(<T>(f: StateCreator<T> | undefined) => {
		if (f === undefined) return createClearable;
		const store = _create(f);
		const initialState = store.getState();
		const allCategories = Array.from(new Set([...categories, 'all' as Category]));
		for (const category of allCategories) {
			const list = storeResetFns.get(category) ?? new Set();
			list.add(() => {
				store.setState(initialState, true);
			});
			storeResetFns.set(category, list);
		}

		return store;
	}) as typeof _create;

/**
 * Resets all Zustand stores by the specified categories.
 * 
 * @param categories - An array of categories to reset the stores for.
 * Example usage: resetZustandStores() (reset zustand store in 'all' category) or resetZustandStores(['phoenix'])
 */
export const resetZustandStores = (categories: Category[] = []) => {
	const allCategories = Array.from(new Set([...categories, 'all' as Category]));
	for (const category of allCategories) {
		const list = storeResetFns.get(category);
		if (list !== undefined) {
			for (const fn of list) {
				fn();
			}
		}
	}
};