// @ts-strict-ignore
import _ from 'lodash';
import { GetConfig } from '../constants/Config';
import { SetRelayValueAction } from '../redux/actions/RelayActions';
import { useSnexStore } from './UseSnexStore';
import { createClearable as create } from 'phoenix/stores/ZustandStore';

export const useRelay = <TValue>(topic: string): [TValue, (val: TValue) => void] => {
    if (!topic) return [null, _.noop];
    const update = (value: TValue) => SetRelay(topic, value);
    const value = useSnexStore((s) => s.relay[topic]);
    return [value, update];
};

export const SetRelay = <TValue>(topic: string, value: TValue) => {
    if (GetRelay(topic) !== value) GetConfig().Store.dispatch(SetRelayValueAction(topic, value));
};
export const GetRelay = <TValue>(topic: string): TValue => GetConfig().Store.getState().relay[topic] as any;

(window as any).Rx = { set: SetRelay, get: GetRelay };

// Mobile For Now
interface useRelayState {
    relay: Record<string, any>
}

interface useRelayActions {
    actions: {
        setRelay: <TValue>(topic: string, value: TValue) => void;
        setRelayValue: <TValue>(topic: string) => (value: TValue) => void;
    }
}

export const useRelayStore = create()<useRelayState & useRelayActions>((set, get) => ({
    relay: {},
    actions: {
        setRelay: <TValue>(topic: string, value: TValue) => set({ [topic]: value }),
        setRelayValue: <TValue>(topic: string) => (value: TValue) => set({ [topic]: value }),
    }
}))

export const useRelayValue = <TValue>(topic: string): TValue => useRelayStore(s => s[topic]);
export const useSetRelay = <TValue>(topic: string) => useRelayStore(s => s.actions.setRelayValue<TValue>(topic));
export const useRelayActions = () => useRelayStore(s => s.actions);