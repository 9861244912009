import { Typography } from '@mui/material';
import React from 'react';
import { Flex } from '../../Flex';
import { LoadingSpinner } from '../../LottieAnimations';

export const TradeLoadingPage = React.memo(() => (
    <Flex center column style={{ height: 300 }}>
        <LoadingSpinner />
        <Typography variant='h6'>Loading...</Typography>
    </Flex>
));