import { FormatString } from './FormatString'

export const WithoutNulls = (src: any) => Object.entries(src)
    .filter(e => !!e[1]).reduce((final, next) => ({ ...final, [next[0]]: next[1] }), {})

export const WithoutNaNs = (src: any) => Object.entries(src)
    .filter(e => !isNaN(e[1] as any)).reduce((final, next) => ({ ...final, [next[0]]: next[1] }), {})

export const ObjectMap = <TValue>(o: {[key: string]: TValue}, valueSelector: (entry: [string, TValue]) => TValue): {[key: string]: TValue} => Object.entries(o)
    .reduce((lookup, next) => ({ ...lookup, [next[0]]: valueSelector(next) }), {})

export const ObjectFilter = <TValue>(o: {[key: string]: TValue}, predicate: (entry: [string, TValue]) => boolean, valueSelector?: (entry: [ string, TValue ]) => TValue): object => Object.entries(o)
    .filter(predicate)
    .reduce((lookup, next) => ({ ...lookup, [next[0]]: valueSelector ? valueSelector(next) : next[1] }), {})

export const LowercaseObjectKeys = (o: any): any => {
    return Object.entries(o).reduce((result, [key, value]) => ({
        [FormatString.LowercaseFirstOnly(key)]: value, ...result
    }), {})
}

export const ObjectFlat = (o: object, subkey = ''): object => {
    if (typeof o !== 'object' || !o) return {}
    return Object.entries(o).reduce((lookup, next) => {
        const [key, value] = next
        const eKey = subkey ? subkey + ':' + key : key
        if (typeof value === 'object' && !Array.isArray(value)) return { ...lookup, ...ObjectFlat(value, eKey) }
        else return { ...lookup, [eKey]: value }
    }, {})
}