// @ts-strict-ignore
import { GetConfig, Urls } from '../../constants';
import { ChartRange } from '../../constants/ChartRange';
import { NoActionThunk } from '../../constants/NoAction';
import { SymbolUrlChunk, UniqueBy } from '../../util';
import { GlobalState } from '../GlobalState';
import { OptionSymbol, SecurityChartData } from '../models';
import { FuturesSymbol } from '../models/Futures/FuturesSymbol';
import { Actions } from './Constants';
import { ReduxApiGet, ReduxApiResponse } from './Helpers';

export const GetOptionChart = (symbol: string, range: ChartRange = '1d', res = 'hi'): ReduxApiResponse<unknown> => {
    if (!OptionSymbol.IsOptionSymbol(symbol)) return;
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const osi = new OptionSymbol(symbol).toOsiSymbol();
    const effectiveSymbol = isFuture ? symbol : osi;
    return ReduxApiGet<unknown, unknown, { symbol: string; range: ChartRange; res: string }[]>(
        Urls.options.getChart(effectiveSymbol, range, res),
        Actions.Options.GetChart
    )
        .withSubject([{ symbol: effectiveSymbol, range, res }])
        .withMutex(() => `optChart:${symbol}@${range}`)
        .useStored((s) => range !== '1d' && s.securityChart.bySymbol[effectiveSymbol]?.[range]?.data)
        .run();
};

export const ResetSecurityChartsAction = () => ({
    type: Actions.Securities.Chart.Reset
});

export const GetSecuritySymbolChart = (
    symbol: string,
    range: ChartRange = '1d',
    res: 'hi' | 'lo' | 'special-lo-market-xlo-extended' = 'lo',
    bypassBatching?: boolean,
    bypassCaching?: boolean
): ReduxApiResponse<unknown> => {
    if (OptionSymbol.IsOptionSymbol(symbol)) return GetOptionChart(symbol, range, res);
    if (!symbol) return;

    const dataSet = GetConfig().Store.getState().securityChart.bySymbol[symbol]?.[range]?.data;
    const alreadyStored = !!dataSet?.length && !['1d', '24h'].includes(range);
    const useBatching = !alreadyStored && !bypassBatching;
    const url = useBatching ? symbol : Urls.securities.core.getChart(symbol, range, res);
    let r = ReduxApiGet<unknown, unknown, { symbol: string; range: ChartRange; res: 'hi' | 'lo' | 'special-lo-market-xlo-extended' }>(url, Actions.Securities.Chart.Get)
        .withSubject({ symbol, range, res: res === 'special-lo-market-xlo-extended' ? 'lo' : res })
        // .withLoading()
        .withMutex(() => `oldchart:${symbol}@${range}`)
        .useStored((s: GlobalState) => {
            return alreadyStored && !bypassCaching ? dataSet : null;
        });

    if (useBatching) {
        r = r.withBatching(
            `adhoc-symbol-chart@${range}`,
            (symbols: string[]) => {
                return SymbolUrlChunk(symbols).map((c) => Urls.securities.core.getV2MultiSecurityChartBatch(c, range, res));
            },
            750
        );
        r = r.onError((e: any, dispatch: any) => {
            if (Array.isArray(e?.subject)) UniqueBy(e?.subject, (item: any) => item.symbol)?.map((s) => dispatch(GetSecuritySymbolChart(s.symbol, s.range, res, true)));
        });
    }
    return r.run();
};

export const GetMultiSecurityChartsForRange = (symbols: string[], range: ChartRange = '1d'): ReduxApiResponse<unknown> =>
    ReduxApiGet<unknown, unknown, { symbols: string[]; range: ChartRange }>(
        SymbolUrlChunk(symbols).map((s) => Urls.securities.core.getMultiSecurityChartBatch(s, range, 'special-lo-market-xlo-extended')),
        Actions.Securities.GetMultiSecurityChartBatch
    )
        .withSubject({ symbols, range })
        .onError((_, dispatch: any) => {
            symbols.map((s) => dispatch(GetSecuritySymbolChart(s, range, 'special-lo-market-xlo-extended')));
        })
        .withMutex(() => (symbols?.length ? `multiSecBatch:${symbols?.join(',')}@All` : null))
        .run();

export const GetSymbolSecurityCustomChart = ({
    countBack,
    from,
    resolution,
    symbol,
    to
}: {
    countBack: number;
    from: number;
    resolution: string;
    symbol: string;
    to: number;
}): ReduxApiResponse<SecurityChartData[]> => {
    if (!symbol || symbol?.indexOf(' ') !== -1) return;

    return ReduxApiGet<SecurityChartData[], SecurityChartData[], { symbol: string; resolution: string }>(
        Urls.securities.core.getCustomChart({ countBack, from, resolution, symbol, to }),
        Actions.Securities.Chart.GetCustom
    )
        .withSubject({ symbol, resolution })
        .run();
};
