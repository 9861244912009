import { Typography } from '@mui/material';
import { Flex } from 'components';
import { BigTextLink } from 'components/BigTextLink/BigTextLink';
import { SnexButton } from 'components/SnexButton';
import { useColors } from 'hooks/UseColors';
import Lottie from 'lottie-react';
import { useText } from 'phoenix/hooks/UseText';
import React, { ReactElement, ReactNode } from 'react';
import { useOpenChatWindow } from 'hooks/UseChat';
import animationData from 'phoenix/assets/animations/Gears.json';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { v4 as uuidv4 } from 'uuid';

/* implemented to help with chunk errors which can occur when a new release is pushed */
/* https://stackoverflow.com/questions/68663106/how-to-solve-chunk-load-error-in-create-react-app-project */
const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false') as boolean;

    if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(PageHasBeenForceRefreshed, 'true');
        return window.location.reload();
    } else {
        window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
    }
};

// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export class ErrorBoundary extends React.Component<{ inheritHeight?: boolean; rootLevel?: boolean }, { hasError: boolean; errorId?: string; errorInfo: unknown }> {
    constructor(props: { inheritHeight?: boolean; rootLevel?: boolean }) {
        super(props);
        this.state = { hasError: false, errorId: undefined, errorInfo: null };
    }

    static getDerivedStateFromError(): { hasError: boolean } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: { componentStack?: unknown }): void {
        /* attempts to reload page if a chunk error ocurrs */
        if (JSON.stringify(error)?.toLocaleLowerCase().includes('chunkloaderror')) retryPageLoading();

        const errorId = uuidv4();

        this.setState({ errorId, errorInfo });
        ErrorLogger.RecordError(error, errorId, { info: { stack: errorInfo?.componentStack } });
        // console.log({ errorInfo, error });
    }

    render(): ReactNode | undefined {
        if (this.state?.hasError) {
            // You can render any custom fallback UI
            try {
                return this.props.rootLevel ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100vw',
                            height: '100vh',
                            background: 'white'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 800,
                                height: 500,
                                background: 'rgb(240,240,240)',
                                borderRadius: 10,
                                padding: '0px 20px'
                            }}
                        >
                            <h1 style={{ textAlign: 'center' }}>Something unexpected happened</h1>
                            <h3 style={{ textAlign: 'center', marginTop: 10 }}>Reach out to support for more information, sorry for the inconvenience.</h3>
                            <h3 style={{ marginTop: 40, textAlign: 'center', color: '#0071DB' }}>Contact us at: +1 (866)-443-5847</h3>
                        </div>
                    </div>
                ) : (
                    <ErrorBoundaryMessage inheritHeight={this.props.inheritHeight} errorId={this.state.errorId} />
                );
            } catch (error) {
                return;
            }
        }

        return this.props.children;
    }
}

export const ErrorBoundaryMessage = (props: { inheritHeight?: boolean; errorId?: string }): ReactElement => {
    const colors = useColors();
    const openChatWindow = useOpenChatWindow();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const text = useText((t) => t.errorBoundary);
    return (
        <Flex column fullWidth center style={{ padding: '4rem 2rem', height: props.inheritHeight ? 'inherit' : '100%' }}>
            <Lottie {...defaultOptions} style={{ height: 75, width: 75 }} />
            <Typography variant='h4' style={{ fontWeight: 'bold', padding: '.5rem 0' }}>
                {text.title}
            </Typography>
            <Typography variant='h6' style={{ padding: '0 0 1rem' }}>
                {text.sorryMessage}
            </Typography>
            <Typography variant='subtitle2' style={{ padding: '0 0 1rem' }}>
                {props.errorId}
            </Typography>
            <Flex row center fullWidth>
                <SnexButton
                    flavor='secondary-transparent'
                    style={{
                        width: '100%',
                        maxWidth: '250px',
                        color: colors.generalTextColor,
                        border: `1px solid ${colors.generalTextColor}`,
                        alignItems: 'center',
                        backgroundColor: 'transparent'
                    }}
                    onClick={() => {
                        location.reload();
                    }}
                >
                    {text.tryAgain}
                </SnexButton>
            </Flex>
            <BigTextLink color={colors.primaryItemColor} onClick={() => openChatWindow()}>
                {text.contactSupport}
            </BigTextLink>
        </Flex>
    );
};

export const TestErrorComponent = (): ReactElement => {
    const data = null;
    return <h1>{data}</h1>;
};

export const errorWrap = (element: JSX.Element, simulateError?: boolean, inheritHeight?: boolean): JSX.Element => (
    <ErrorBoundary inheritHeight={inheritHeight}>
        {simulateError ? <TestErrorComponent /> : null}
        {element}
    </ErrorBoundary>
);
