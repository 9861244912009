// @ts-strict-ignore
import { Grid, Skeleton, Typography } from '@mui/material';
import { Flex, SectionHeader } from 'components';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useText } from 'phoenix/hooks/UseText';
import { GetRiskFreeRate } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { FormatNumber } from 'phoenix/util';
import { XS } from 'phoenix/xstream/XS';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GetBreakEvenFigures } from '../OptionsScreen/OptionContractList/BreakEvenCalculations';

export const OptionStatsSection = React.memo(({ symbol }: { symbol: string }) => {
    const dispatch = useDispatch();
    const optSym = new OptionSymbol(symbol);
    const quote = XS.OptionQuotes.use(symbol); // Subscriptions in OptionContractScreen
    const uQuote = XS.Quotes.use(optSym.underlyingSymbol);
    const text = useText((s) => s.optionContractScreen.stats);
    const [time] = useMarketTimeSegmentV2();
    const { loadData, getMetadataBySymbol } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(symbol);
    const { strikePrice = optSym.strike } = meta?.option || {};
    const be = GetBreakEvenFigures(optSym, quote, uQuote?.price, strikePrice);

    useEffect(() => {
        loadData(symbol);
        dispatch(GetRiskFreeRate());
    }, [dispatch, loadData, symbol]);

    const close = useMemo(() => (time === 'open' ? quote?.previousClose : quote?.close), [time, quote]);

    const InfoNugget = (p: { label: string; children: ReactNode }) => {
        const showLoading = !quote || quote.loading;
        return (
            <Grid item style={{ minHeight: 48 }} xs={3}>
                <Flex column>
                    <Typography variant='h6'>{p.label}</Typography>
                    {showLoading ? (
                        <Skeleton animation='wave' style={{ height: 14 }} />
                    ) : (
                        <Typography style={{ opacity: showLoading ? 0.4 : 1, fontWeight: 'normal' }} variant='h6'>
                            {p.children}
                        </Typography>
                    )}
                </Flex>
            </Grid>
        );
    };

    return (
        <Flex column>
            <SectionHeader label={text.stats} />
            <Grid container spacing={3}>
                <InfoNugget label={text.bid}>
                    {FormatNumber.toMoney(quote?.bid)} &times; {FormatNumber.toCommas(quote?.bidSize, false)}
                </InfoNugget>
                <InfoNugget label={text.ask}>
                    {FormatNumber.toMoney(quote?.ask)} &times; {FormatNumber.toCommas(quote?.askSize, false)}
                </InfoNugget>
                <InfoNugget label={text.previousClose}>{FormatNumber.toMoney(close)}</InfoNugget>
                <InfoNugget label={text.breakEven}>{FormatNumber.toMoney(be.price)}</InfoNugget>
                <InfoNugget label={text.high}>{FormatNumber.toMoney(quote?.high)}</InfoNugget>
                <InfoNugget label={text.low}>{FormatNumber.toMoney(quote?.low)}</InfoNugget>
                <InfoNugget label={text.volume}>{FormatNumber.toCommas(quote?.volume, false)}</InfoNugget>
                <InfoNugget label={text.openInterest}>{FormatNumber.toCommas(quote?.openInterest, false)}</InfoNugget>
            </Grid>
        </Flex>
    );
});
