import React from 'react';
import Lottie from 'lottie-react';
import animationData from 'phoenix/assets/animations/FailAnimation.json';

const AnimatedFailure = (props: { size?: number }) => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        initialSegment: [30, 120] as [number, number]
    };
    return (
        <div>
            <Lottie
                {...defaultOptions}
                style={{
                    height: props.size || 100,
                    width: props.size || 100
                }}
            />
        </div>
    );
};

export default AnimatedFailure;
