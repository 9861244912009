import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { FocusEvent, MouseEvent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import omit from 'lodash/omit';
import {
    UseActionLinkButtonStyles,
    UseCancelButtonStyles,
    UseCardButtonStyles,
    UseDeleteButtonStyles,
    UseNotAllowedButtonStyles,
    UseOnboardingButtonStyles,
    UserRadioButtonStyles,
    UseSecondaryButtonStyles,
    UseSecondarySolidButtonStyles,
    UseSecondaryTransparentButtonStyles,
    UseSelectedOnboardingButtonStyles,
    UseSubmitButtonStyles
} from '../../theming';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';

export interface SnexButtonProps {
    onBlur?: (e: FocusEvent<HTMLButtonElement>) => void;
    onFocus?: (e: FocusEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    eventTag?: string;
    flavor?:
        | 'submit'
        | 'card'
        | 'secondary'
        | 'secondary-solid'
        | 'secondary-transparent'
        | 'delete'
        | 'action-link'
        | 'cancel'
        | 'radio'
        | 'single-tab'
        | 'not-allowed'
        | 'custom'
        | 'onboarding'
        | 'onboarding-selected'
        | 'loading';
    route?: string;
}

/**
 * SnexButton (StoneX Button) extends Button from Mateiral UI.
 *
 * To review other routes, you can {@link Routes | go to /src/constants/Routes.tsx}.
 *
 * ```
 * <SnexButton onClick={()=>{}}>button text</SnexButton>
 * ```
 */
export const SnexButtonComponent = (props: ButtonProps & SnexButtonProps): ReactElement => {
    const LogEvent = useTelemetry();

    // Remove props which do not conform to the underlying button component interface
    const filteredProps = omit(props, ['eventTag']);

    const buttonProps = {
        ...filteredProps,
        style: props.style,
        onBlur: props.onBlur,
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
            if (props.eventTag) LogEvent(`${props.eventTag} button click`);
            props.onClick && props.onClick(e);
        },
        onFocus: props.onFocus,
        route: props.route
    };

    return <SnexButtonPresentation {...buttonProps} />;
};

export const SnexButtonPresentation = (props: ButtonProps & SnexButtonProps): ReactElement => {
    const defaultButton = (
        <Button {...props} className={props.className} fullWidth variant='contained'>
            {props.children}
        </Button>
    );

    switch (props.flavor) {
        case 'action-link':
            return <SnexActionLinkButton {...props} />;
        case 'cancel':
            return <SnexCancelButton {...props} />;
        case 'card':
            return <SnexCardButton {...props} />;
        case 'delete':
            return <SnexDeleteButton {...props} />;
        case 'not-allowed':
            return <SnexNotAllowedButton {...props} />;
        case 'onboarding':
            return <SnexOnboardingButton {...props} />;
        case 'onboarding-selected':
            return <SnexSelectedOnboardingButton {...props} />;
        case 'radio':
            return <SnexRadioButton {...props} />;
        case 'secondary':
            return <SnexSecondaryButton {...props} />;
        case 'secondary-solid':
            return <SnexSecondarySolidButton {...props} />;
        case 'secondary-transparent':
            return <SnexSecondaryTransparentButton {...props} />;
        case 'submit':
            return <SnexSubmitButton {...props} />;
        case 'loading':
            return <SnexLoadingButton {...props} />;
        case 'custom':
        default:
            return props.route ? <Link to={props.route}>{defaultButton}</Link> : defaultButton;
    }
};

export const SnexButton = React.memo(SnexButtonComponent);

const SnexSubmitButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseSubmitButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexCardButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseCardButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexSecondaryButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseSecondaryButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexSecondarySolidButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseSecondarySolidButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexSecondaryTransparentButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseSecondaryTransparentButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexDeleteButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseDeleteButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexActionLinkButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseActionLinkButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexCancelButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseCancelButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexRadioButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const classes = UserRadioButtonStyles()();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return button;
});

const SnexNotAllowedButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseNotAllowedButtonStyles(colors)();
    const button = (
        <Button {...props} disabled={props.disabled || true} classes={{ ...classes }} className={`not-allowed${props.className ? ` ${props.className}` : ''}`}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexOnboardingButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseOnboardingButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexSelectedOnboardingButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseSelectedOnboardingButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children}
        </Button>
    );

    return props.route ? (
        <Link to={props.route} style={{ width: props?.style?.width }}>
            {button}
        </Link>
    ) : (
        button
    );
});

const SnexLoadingButton = React.memo((props: ButtonProps & SnexButtonProps) => {
    const colors = useColors();
    const classes = UseSubmitButtonStyles(colors)();
    const button = (
        <Button {...props} classes={{ ...classes }}>
            {props.children} <CircularProgress style={{ color: 'white', height: '1rem', width: '1rem', margin: '0 0 0 1rem', padding: 0 }} />
        </Button>
    );
    return button;
});
