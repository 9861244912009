// @ts-strict-ignore
import React from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import style from './style.module.scss';
import { Flex } from 'components/Flex';
import { VideoPlayerHelpers, useModalVideoPlayerStore } from './videoPlayerHelpers';
import ReactPlayer from 'react-player';
import { Tooltip } from '@mui/material';
import { PlayIcon } from 'components/OneProNetwork/assets/playIcon';
import { Previous10Icon } from 'components/OneProNetwork/assets/previous10Icon';
import { Next10Icon } from 'components/OneProNetwork/assets/next10Icon';
import { PauseIcon } from 'components/OneProNetwork/assets/pauseIcon';

export const Control = ({ onClick, children, title }: { onClick: (e) => void; children: any; title?: string }): JSX.Element => {
    return (
        <Tooltip title={title}>
            <div className={style['video-control-button']} onClick={onClick}>
                {children}
            </div>
        </Tooltip>
    );
};

export const SliderControl = ({ playerRef }: { playerRef?: React.MutableRefObject<ReactPlayer> }): JSX.Element => {
    const { state, actions } = useModalVideoPlayerStore();
    const helpers = VideoPlayerHelpers();

    const handleSeekMouseDown = (e) => {
        actions.setSeeking(true);
        actions.setPlaying(true);
    };

    const handleSeekChange = (e) => {
        helpers.skipTo(e.target.value, playerRef);
        actions.setCurrentTime(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = (e) => {
        actions.setSeeking(false);
    };

    const formatDuration = (value: number) => {
        const minute = Math.floor(value / 60);
        const secondLeft = Math.round(value - minute * 60);
        return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    };

    return (
        <Flex column fullWidth style={{ padding: '0 8px' }}>
            <Flex row fullWidth className={style['video-slider']}>
                <input
                    style={{ width: '100%' }}
                    type='range'
                    min={0}
                    max={state.duration || 0}
                    step='any'
                    value={state.currentTime}
                    onChange={handleSeekChange}
                    onMouseDown={handleSeekMouseDown}
                    onMouseUp={handleSeekMouseUp}
                />
            </Flex>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    height: '12px',
                    marginTop: -12,
                    opacity: 0.45
                }}
            >
                <div>{formatDuration(state.currentTime)}</div>
                <div>-{formatDuration(state.duration - state.currentTime)}</div>
            </div>
        </Flex>
    );
};

export const Controls = ({ style }: { style?: React.CSSProperties }): JSX.Element => {
    const { state } = useModalVideoPlayerStore();
    const actions = VideoPlayerHelpers();
    const iconSizes = {
        base: 16,
        sm: 16,
        md: 32,
        lg: 64
    };

    return (
        <Flex row className={style['video-player-controls']} center align={'center'} fullWidth style={style}>
            <Flex row id={'video-controls-sound'} align={'center'} style={{ flex: 0 }}>
                {state.isMuted ? (
                    <Control onClick={() => actions.unmute()}>
                        <VolumeOffIcon style={{ height: iconSizes.lg, width: iconSizes.md }} />
                    </Control>
                ) : (
                    <Control onClick={() => actions.mute()}>
                        <VolumeUpIcon style={{ height: iconSizes.lg, width: iconSizes.md }} />
                    </Control>
                )}
            </Flex>
            <Flex row align={'center'} center style={{ flex: '1 1 auto', gap: iconSizes.lg / 2 }}>
                <Control onClick={() => actions.skipPrev()}>
                    <Previous10Icon height={30} />
                </Control>
                {!state.isPlaying ? (
                    <Control onClick={() => actions.play()}>
                        <PlayIcon height={48} />
                    </Control>
                ) : (
                    <Control onClick={() => actions.pause()}>
                        <PauseIcon height={48} />
                    </Control>
                )}
                <Control onClick={() => actions.skipNext()}>
                    <Next10Icon height={30} />
                </Control>
            </Flex>
            <Flex row align={'center'} style={{ flex: 0 }}>
                {state.variant === 'modal' ? (
                    <Control onClick={() => actions.expand()}>
                        <FullscreenIcon style={{ height: iconSizes.lg, width: iconSizes.md }} />
                    </Control>
                ) : (
                    <Control onClick={() => actions.collapse()}>
                        <FullscreenExitIcon style={{ height: iconSizes.lg, width: iconSizes.md }} />
                    </Control>
                )}
            </Flex>
        </Flex>
    );
};
