// @ts-strict-ignore
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { setSelectedScreenId, useFullyPaidLendingStore } from "../../Store"
import React from "react"
import { FPLWelcomeForm } from "./Form"


export const fpl_welcome_step: OnboardingStep = {
    label: "Welcome",
    isComplete: () => !!useFullyPaidLendingStore?.getState()?.welcomed,
    isEnabled: () => true,
    isCurrent: () => ['welcome'].includes(useFullyPaidLendingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'welcome'
}

export const fpl_welcome_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useFullyPaidLendingStore.getState().setValue('welcomed', true)
        setSelectedScreenId('fplInfo')
    },
    isPrimary: true,
    isEnabled: () => true
}

export const fpl_welcome_screen: OnboardingScreen = {
    id: "welcome",
    validate: NoValidation,
    buttons: [fpl_welcome_nextButton],
    body: <FPLWelcomeForm />
}