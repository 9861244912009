// @ts-strict-ignore
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetAllPositions } from 'phoenix/redux/actions';
import { TradeRequest } from 'phoenix/redux/models';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useDispatch } from 'react-redux';
import { Sum } from './ArrayMutations';
import { GetSecurityTypeFromStore } from './IsMutualFund';

export const IsTradeShortSell = (trade: Partial<TradeRequest>): boolean => IsShortSell(trade.securityId, trade.accountNumber, trade.action, trade.quantity);

export const IsShortSell = (symbol: string, accountNumber: string, action: 'Buy' | 'Sell', quantity: number): boolean => {
    const positions = usePositionsStore.getState().positions;
    const secId = QualifiedSecurityId.Parse(symbol);
    const secType = GetSecurityTypeFromStore(secId.id);
    const relevantPositions = () => positions?.filter((p) => secId.MatchesPosition(p) && p.accountNumber === accountNumber);
    const sharesHeld = Sum((relevantPositions() || []).map((p) => p.quantity));
    // Besides 'Sell', there are also variants, like 'SellShort'
    const isShort = (() => (action?.includes('Sell') && ['equity', 'etf'].includes(secType) ? sharesHeld <= 0 || sharesHeld - quantity < 0 : false))();
    return isShort;
};

export const IsShortSellWithLoadPositions = async (symbol: string, accountNumber: string, action: 'Buy' | 'Sell', quantity: number): Promise<boolean> => {
    const dispatch = useDispatch();
    await dispatch(GetAllPositions('default-caching'));
    return IsShortSell(symbol, accountNumber, action, quantity);
};
