

export const TelemetryCategories = {
    general: 'General Interaction',
    account: 'Account Interaction',
    buyingPower: 'Buying Power Interactions',
    chart: 'Chart Interactions',
    disclosures: 'Disclosures Interaction',
    funding: 'Funding Interaction',
    header: 'Header Interaction',
    headerProfileMenu: 'Header Profile Menu Interaction',
    margin: 'Margin Interfaction',
    messages: 'Messages Interaction',
    navigation: 'Navigation Interaction',
    news: 'News Interaction',
    positions: 'Positions Screen Interaction',
    premium: 'Premium Interaction',
    search: 'Search Interaction',
    security: 'Security Screen Interaction',
    summary: 'Summary Screen Interaction',
    support: 'Support Interaction',
    tour: 'Tour Interaction',
    history: 'Trade History Interaction',
    trade: 'Trade Ticket Interaction',
    tradingSettings: 'Trading Interaction',
    watchlist: 'Watchlist Interaction',
    documents: 'Documents Interaction',
    orders: 'Orders Interaction',
    transactions: 'Transactions Interaction',
    projectedIncome: 'Projected Income Interaction',
    sector: 'Sector Interaction',
    videoPlayer: 'Video Player Interaction'
}