import { Tooltip } from '@mui/material';
import { useText } from 'phoenix/hooks/UseText';
import React from 'react';
import { DelayedIcon } from 'components/Icons/DelayedIcon';
import { useAppTheme } from 'hooks/UseAppTheme';
import { GetMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { ThemeVariants } from 'phoenix/theming/ThemeVariants';

export const DelayedBadge = (): JSX.Element => {
    const text = useText((s) => s.general);

    const [theme] = useAppTheme();
    let colorTheme = theme;

    if (theme === ThemeVariants.market) {
        colorTheme = GetMarketTimeSegmentV2() === 'open' ? 'light' : 'dark';
    }

    return (
        <Tooltip title={text.delayedPricing} placement='right'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DelayedIcon flavor={colorTheme} height={30} width={72} />
            </div>
        </Tooltip>
    );
};
