import { T } from "phoenix/assets/lang/T";
import { Urls } from "phoenix/constants";
import { Account } from "phoenix/redux/models";
import { SnexAxios } from "phoenix/stores/AxiosHelpers";

const SubmitFullyPaidLendingRequest = async (snexAccountNumber: string): Promise<void> => {
    const FPLMasterLoanAgreementUrl = T(t => t.fplOnboarding.agreements.loanAgreementPDFUrl)
    const FPLAccountControlAgreementUrl = T(t => t.fplOnboarding.agreements.fplAccountControlAgreementPDFUrl)
    const FPLRiskAgreementUrl = T(t => t.fplOnboarding.disclosures.importantCharAndRiskAgreementPDFUrl)
    const url = Urls.fullyPaidLending.submit();
    const request = {
        snexAccountNumber,
        masterLoanAgreementName: FPLMasterLoanAgreementUrl.substring(FPLMasterLoanAgreementUrl.lastIndexOf('/') + 1),
        accountControlAgreementName: FPLAccountControlAgreementUrl.substring(FPLAccountControlAgreementUrl.lastIndexOf('/') + 1),
        riskAgreementName: FPLRiskAgreementUrl.substring(FPLRiskAgreementUrl.lastIndexOf('/') + 1)
    }

    await SnexAxios.ApiPost(url, { data: request }).run()
}



export const FullyPaidLendingHelpers =
{
    SubmitFullyPaidLendingRequest,
    GetFPLStatus: async (account: Account): Promise<string> => account?.fplStatus || 'None',
    GetFPLSubmittedDate: async (account: Account): Promise<any> => account.fplSubmissionDate 
}