// @ts-strict-ignore
// https://react-redux.js.org/api/hooks#custom-context

import React, { useEffect, useRef, useState } from 'react'
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { XstreamReducer } from './XstreamReducer'

const XstreamContext = React.createContext(null)

export const useXstreamDispatch = createDispatchHook(
    XstreamContext
) as () => any
export const useXstreamSelector = createSelectorHook(XstreamContext)

const streamingStore = createStore(
    XstreamReducer,
    (() => compose(applyMiddleware(thunk)))()
)
export const GetXstreamStore = () => streamingStore

export const XstreamProvider = React.memo(
    (props: { children: any; overrideStore?: any }) => {
        const contextRef = useRef(XstreamContext)
        const [overrideStreamingContext, setOverrideStreamingContext] =
            useState<any>()

        useEffect(() => {
            setOverrideStreamingContext(
                props.overrideStore ? contextRef.current : undefined
            )
        }, [props.overrideStore])

        return (
            <Provider
                context={overrideStreamingContext || XstreamContext}
                store={props.overrideStore || streamingStore}
            >
                {props.children}
            </Provider>
        )
    }
)
