// @ts-strict-ignore
import React from 'react';
import { Modal, Slide, Backdrop } from '@mui/material';
import { IsFirefox } from 'util/BrowserId';
import { useAppTheme } from 'hooks/UseAppTheme';

export const AnimatedModal = (props: { onClose?: (e) => any; timeout?: number; open?: boolean; children: any }) => {
    const [appTheme] = useAppTheme();

    const { onClose = () => undefined, timeout = 500, open = false, children } = props;
    return (
        <Modal
            className={`${appTheme} animated-modal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ sx: { backdropFilter: 'blur(5px)', zIndex: -1 }, timeout }}
            open={open}
            style={{ zIndex: 999998, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClose={onClose}
        >
            {!IsFirefox() ? (
                <Slide direction='up' in={open} style={{ outline: 'none' }}>
                    <div>{children}</div>
                </Slide>
            ) : (
                <div>{children}</div>
            )}
        </Modal>
    );
};
