export interface SnexChartPoint {
    x?: number; // time in seconds
    y?: number; // value
    open?: number;
    high?: number;
    low?: number;
    close?: number;
    volume?: number;
    [key: string]: number | string | undefined;
}

export type SnexChartTimezoneType = 'America/New_York' | 'America/Chicago';

export const SnexChartTimezone = {
    edt: 'America/New_York', // Non-futures
    cdt: 'America/Chicago' // Futures
};

export type SnexSegmentScale =
    | 'equities-day' // Equities market segments include pre-market, market open, and post-market
    | 'day'
    | 'week'
    | 'month'
    | 'year';
