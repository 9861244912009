import { ApiData } from '../../../models'
import { AccountOpening } from './AccountOpening'

export class AccountOpeningsState {
    all: ApiData<AccountOpening[]>;

    constructor () {
        this.all = new ApiData<AccountOpening[]>([])
    }
}
