import { Snex1English } from "phoenix/assets/lang/Snex1English";
import { Dark, Light, ShadedTextList, Snex1LanguagePack } from "phoenix/assets/lang/Snex1LanguagePack";
import { ApiTradeRequest } from "phoenix/models/ApiTradeRequest";
import { FormatNumber } from "../FormatNumber";
import { OptionSymbol, OptionsPutCallAbbreviation } from "phoenix/redux/models";
import { getWeekOfMonth } from "date-fns";
import { GetPriceFormatInfo, PriceFormatInfo } from "phoenix/hooks/UsePriceFormatInfo";
import { isSecurityMetadataV2, isSecurityMetadata, SecurityMetadataMix } from "phoenix/stores/SecurityMetadataV2Store";
import { SafeFormatLocale } from "../DateFormatting";

export const interpolateTemplate = <T>(template: string, parts: T): string => {
    let output = template
    //@ts-ignore
    const keys: string[] = Object.keys(parts)
    keys.forEach((key: string) => {
        /* use this to remove props which are inherited */
        if(Object.prototype.hasOwnProperty.call(parts, key)) {
            //@ts-ignore
            output = String.prototype.replace.call(output, new RegExp(String.raw`\{${key}\}`, 'g'), parts[key])
        }
    })
    return output
}

export const GetShadedText = <T>(template: string, textParts: T): ShadedTextList => {

    const interpolatedText = interpolateTemplate(template, textParts)
    const splitText = interpolatedText.split(/(\/\*|\*\/)/)
    let type = 'light'
    const result = splitText.map(t => {

        if(t === '/*') {
            type = 'dark'
            return null
        }
        else if(t === '*/') {
            type = 'light'
            return null
        }
        else {
            const text = t.replace(/(\/\*|\*\/)/g, '')
            return type === 'dark' ? Dark(text) : Light(text)
        }
    })
    return (result?.filter(r => !!r) || []) as ShadedTextList
}


export const SimplifiedReviewText = (trade: ApiTradeRequest, text: Snex1LanguagePack['tradeReviewTextTemplates'], optionAction?: string, secMeta?: SecurityMetadataMix): ShadedTextList => 
{
    const optSym = new OptionSymbol(trade.securityId);
    if(!optSym.isOption)  return SimplifiedReviewTextPresentation(trade, text)
    const strike = isSecurityMetadataV2(secMeta) ? secMeta?.option?.strikePrice : isSecurityMetadata(secMeta) ? secMeta?.strikePrice : optSym.strike;
    const tickSize = isSecurityMetadataV2(secMeta) ? secMeta?.sizing?.minimumIncrement : isSecurityMetadata(secMeta) ? secMeta?.tickSize : undefined;
    const strikeFormat = secMeta && tickSize ? GetPriceFormatInfo(trade.securityId, tickSize) : undefined;
    const formattedStrike = strike? FormatNumber.toMoneyOpt2(strike, strikeFormat?.moneyFormatOptions): '$--.--';

    return SimplifiedReviewTextPresentation(trade, text, optionAction, formattedStrike)
}

export const SimplifiedReviewTextPresentation = (trade: ApiTradeRequest, text: Snex1LanguagePack['tradeReviewTextTemplates'], optionAction?: string, 
   formattedStrike?: string) : ShadedTextList => {

    const getOrderType = () => {
        switch(trade.orderType)
        {
            case 'stop': return 'stop'
            case 'stoplimit': return 'stoplimit'
            case 'limit': return 'limit'
            default: return 'market'
        }
    }

    const getTiming = () => {
        switch(trade.timeInForce)
        {
            case 'GTC': return 'GTC'
            case 'GTXPre': return 'GTXPre'
            case 'GTXPost': return 'GTXPost'
            default: return 'Day'
        }
    }

    const optSym = new OptionSymbol(trade.securityId)
    const isOption = optSym.isOption
    const { underlyingSymbol, expDate } = optSym;
    const term = getWeekOfMonth(expDate, { weekStartsOn: 6 }) !== 3 ? 'Weekly' : 'Monthly';
    const locale = Snex1English.general.dateFnsLocale;
    const putness = optSym.putCall ? Snex1English.general.options.putCallWord(optSym.putCall as OptionsPutCallAbbreviation) : undefined;
    const symbol = !isOption? Snex1English.general.symbolName(trade.securityId) :
         `${underlyingSymbol} ${SafeFormatLocale({ date: expDate, formatString: 'MM/dd/yy', locale })} ${term} ${formattedStrike || '$--.--'} ${putness}`
              
    const action = (() => {
        if(!optionAction) return trade.action
        switch(optionAction.toLowerCase())
        {
            case 'selltoopen': return 'Sell to Open'
            case 'selltoclose': return 'Sell to Close'
            case 'buytoclose': return 'Buy to Close'
            case 'buytoopen': 
            default: return 'Buy to Open'
        }
    })()

    const textParts = {
        action,
        quantity: trade.quantity,
        symbol,
        limitPrice:  trade.orderPrice ? FormatNumber.addTrailingZeroes(trade.orderPrice,2) : '', 
        stopLimitPrice: trade.stopLimitPrice? FormatNumber.addTrailingZeroes(trade.stopLimitPrice,2) : '',
        unit: !isOption? trade.quantity >= 1 ? text.shareOf : text.sharesOf : ''
    }

    const template = text[getOrderType()][getTiming()]
    const reviewText =  GetShadedText(template, textParts)
    return reviewText

}