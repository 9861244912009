import { StorageKeys } from 'phoenix/constants';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { UpdateCurrentUserThemeAction } from 'phoenix/redux/actions';
import { ThemeVariant, ThemeVariants } from 'phoenix/theming/ThemeVariants';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from './UseLocalStorage';

export const useAppThemePreference = (): readonly [ThemeVariant, (value: ThemeVariant) => void] => {
    const dispatch = useDispatch();
    const [local, setLocal] = useLocalStorage<ThemeVariant | null>(StorageKeys.AppThemePreference, null);
    const { theme } = useSnexStore((s) => s.user?.myInfo?.data) || {};

    if (!local && theme) setLocal(theme);

    const appThemePreference = local || theme || ThemeVariants.market;

    const setAppThemePreference = (value: ThemeVariant) => {
        setLocal(value);
        dispatch(UpdateCurrentUserThemeAction(value));
    };

    return [appThemePreference, setAppThemePreference] as const;
};
