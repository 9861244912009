// @ts-strict-ignore
import { ApiData } from '../../models'
import { ReduxAction } from '../../models/ReduxAction'
import { GroupBySelectMap } from '../../util'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { News, NewsState } from '../models/News'

const permitted = GroupNameChecker([Actions.News, Actions.Users.UpdateMyPrefs])
export const NewsReducer = (state: NewsState = new NewsState(), action: ReduxAction): NewsState => {
    if (!permitted(action)) return state

    const updateAccount = (updater: (d: ApiData<News[]>) => ApiData<News[]>) => ({
        ...state, byAccount: { ...state.byAccount, [action.subject]: updater(state.byAccount[action.subject] || new ApiData<News[]>()) }
    })

    const updateSymbol = (updater: (d: ApiData<News[]>) => ApiData<News[]>) => ({
        ...state, bySymbol: { ...state.bySymbol, [action.subject]: updater(state.bySymbol[action.subject] || new ApiData<News[]>()) }
    })

    switch (action.type) {
        case Actions.News.ForMarket.Loading: return { ...state, marketNews: state.marketNews.startLoading(state.marketNews.data) }
        case Actions.News.ForMarket.Success: return { ...state, marketNews: state.marketNews.succeeded(action.data) }
        case Actions.News.ForMarket.Failure: return { ...state, marketNews: state.marketNews.failed(action.error) }

        case Actions.News.ForPortfolio.Loading: return { ...state, portfolioNews: state.portfolioNews.startLoading(state.portfolioNews.data) }
        case Actions.News.ForPortfolio.Success: return { ...state, portfolioNews: state.portfolioNews.succeeded(action.data) }
        case Actions.News.ForPortfolio.Failure: return { ...state, portfolioNews: state.portfolioNews.failed(action.error) }

        case Actions.News.ForAccount.Loading: return updateAccount(d => d.startLoading(d.data))
        case Actions.News.ForAccount.Success: return updateAccount(d => d.succeeded(action.data))
        case Actions.News.ForAccount.Failure: return updateAccount(d => d.failed(action.error))

        case Actions.News.ForSymbol.Loading: return updateSymbol(d => d.startLoading(d.data))
        case Actions.News.ForSymbol.Success: return updateSymbol(d => d.succeeded(action.data))
        case Actions.News.ForSymbol.Failure: return updateSymbol(d => d.failed(action.error))

        case Actions.News.ShowStreamed: {
            const bySym = state.bySymbol
            Object.entries(state.newArticlesBySymbol).forEach(([symbol, articles]) => {
                if (!bySym[symbol]) bySym[symbol] = new ApiData()
                bySym[symbol].succeededConcat(articles, 'prepend', 25)
            })

            return {
                ...state,
                marketNews: state.marketNews.succeededConcat(state.newMarketArticles, 'prepend', 25),
                bySymbol: bySym,
                newArticlesBySymbol: {},
                newMarketArticles: []
            }
        }
        case Actions.News.Streaming.Update: {
            const article: News = action.data
            const affectedSymbols = article.related?.split(',').map(x => x.trim()) || []
            const symbolState = GroupBySelectMap(affectedSymbols, s => s, ss => [article, ...(state.newArticlesBySymbol[ss[0]] || [])].slice(0, 25))
            return {
                ...state,
                newMarketArticles: [article, ...(state.newMarketArticles || [])].slice(0, 25),
                newArticlesBySymbol: { ...symbolState, ...state.newArticlesBySymbol }
            }

            // When the user changes their language we need to clear the news so it's in the right language
        }
        case Actions.Users.UpdateMyPrefs.Loading: return {
            ...state,
            marketNews: state.marketNews.reset(),
            portfolioNews: state.portfolioNews.reset(),
            byAccount: {},
            bySymbol: {}
        }

        default: return state
    }
}
