import { Typography, Skeleton } from '@mui/material';
import React from 'react';
import { OptionButton } from '../';
import { Flex } from 'components';
import { useColors } from 'hooks/UseColors';
import './OptionContractList.scss';

const Loading = React.memo((p: { width?: number; height?: number }) => <Skeleton animation='wave' style={{ width: p.width, height: p.height }} />);

export const ContractListSkeletonRow = ({ className, label }: { className?: string; label: string }) => {
    const colors = useColors();
    return (
        <Flex row align='center' className={`contract-row contract-row-skeleton${className ? ` ${className}` : ''}`} style={{ width: '100%', margin: '4px 0' }}>
            <Typography className='option-column-field' style={{ fontWeight: 'bold', textAlign: 'right' }}>
                {label}
            </Typography>
            <Typography className='option-column-field'>
                <Loading width={50} />
            </Typography>
            <Typography className='option-column-field'>
                <Loading width={50} />
            </Typography>
            <Typography className='option-column-field'>
                <Loading width={50} />
            </Typography>
            <Typography className='option-column-field'>
                <Loading width={50} />
            </Typography>
            <OptionButton color={colors.generalTextColor} style={{ minWidth: '137px', background: colors.greyBackgroundColor }}>
                ---
            </OptionButton>
        </Flex>
    );
};
