// @ts-strict-ignore
import { Urls } from 'phoenix/constants';
import { SnexError } from 'phoenix/models/SnexError';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { createClearable as create } from 'phoenix/stores/ZustandStore'
import { SnexAxios } from './AxiosHelpers';

export type BuyingPowerFigures = {
    cashAvailable?: number;
    optionsBuyingPower?: number;
    cashPosition?: number;
    buyingPower?: number;
    cashAndEquivalents?: number;
    marginCash?: number;
    withdrawalFundsAvailable?: number;
    nyseExcess?: number;
    futuresCashAvailable?: number;
    futuresInitialMargin?: number;
    futuresNetLiquidity?: number;
    errors?: SnexError[];
};

export type AggregateBuyingPower = BuyingPowerFigures & {
    accounts?: AccountBuyingPower[];
    deficient?: boolean /* are any of the accounts missing a buyingPower value */;
};

export type AccountBuyingPower = BuyingPowerFigures & {
    accountNumber?: string;
};

type BuyingPowerStoreData = {
    isLoading: boolean;
    summary: BuyingPowerFigures;
    allAccounts: AccountBuyingPower[];
};

export type BuyingPowerStoreState = BuyingPowerStoreData & {
    findByAccount: (accountnumber?: string) => AccountBuyingPower;

    // All stores that load data from somewhere should have at least one "load" method on them.
    // If there is only one load, then it should be called "load"
    load: (forceReload?: boolean) => Promise<void>;
    clearAll: () => void;
};

const convertToBuyingPowerStoreData = (data: AggregateBuyingPower): BuyingPowerStoreData => {
    return {
        isLoading: false,
        summary: {
            ...((data as BuyingPowerFigures) || {}),
            cashAvailable: data?.withdrawalFundsAvailable || (data?.cashAndEquivalents || 0) + (data?.futuresCashAvailable || 0)
        },
        allAccounts:
            data?.accounts?.map((a) => ({
                ...a,
                cashAvailable: FuturesSymbol.IsFuturesSymbol(a.accountNumber) ? a?.futuresCashAvailable : a?.cashAndEquivalents
            })) || []
    };
};

export const useBuyingPowerStore = create()<BuyingPowerStoreState>((set, get) => ({
    isLoading: false,
    summary: {},
    allAccounts: [],

    findByAccount: (accountNumber?: string) => get()?.allAccounts?.find((a) => a.accountNumber === accountNumber),
    load: async (forceReload?: boolean) => {
        const run = async () => {
            set((s: BuyingPowerStoreState) => ({ ...s, isLoading: true }));
            const data = await SnexAxios.ApiGet<AggregateBuyingPower>(Urls.accountSummaries.getAggregateBuyingPower()).withMutex('zustand-agg-buying-power').run();
            set((s: BuyingPowerStoreState) => ({ ...s, isLoading: false, ...convertToBuyingPowerStoreData(data) }));
        };
        if (!!forceReload || (!get()?.allAccounts?.length && !get()?.isLoading)) await run(); /* prevents data calls from happening while one is running */
    },
    clearAll: () => set({
        isLoading: false,
        summary: {},
        allAccounts: [],
    }),
}));

// Globally exported convenience functions must be prefixed with the store they operate on to reduce confusion
export const BuyingPowerStore_Load = (forceReload?: boolean) => useBuyingPowerStore?.getState()?.load(forceReload);
export const BuyingPowerStore_GetByAccount = (accountNumber: string) => useBuyingPowerStore?.getState()?.findByAccount(accountNumber);
