import { ApiError } from './ApiError';
import { SnexError } from './SnexError';

// TODO: This is a workaround for passing whatever we want into the "failed" method
// Make real type definitions for what we're passing into that method and remove this bandaid
interface BadErrorType extends SnexError {
    authenticated?: boolean;
}

// Second type parameter is for an individual item in an array
// Example: ApiData<Watchlist[], Watchlist>
export class ApiData<T, U = Record<string, unknown>> {
    pristine: boolean;
    loading: boolean;
    data: T | undefined;
    /** @deprecated Please use errors */ error: ApiError | null;
    errors: ApiError[] | null;
    private initialValue: T | undefined;
    private loadingValue: T | undefined;

    constructor(initialValue?: T, loadingValue?: T | undefined) {
        this.pristine = true;
        this.loading = false;
        this.data = initialValue;
        this.error = null;
        this.errors = null;
        this.initialValue = initialValue;
        this.loadingValue = loadingValue;
    }

    // For below methods: cannot spread because it leaves off the methods

    startLoading(loadingValue: T | null = null): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        out.pristine = this.pristine;
        out.loading = true;
        out.errors = null;
        out.data = loadingValue || this.loadingValue;
        return out;
    }

    succeeded(data: T, errors?: ApiError[]): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        out.pristine = false;
        out.data = data;
        if (errors) out.errors = errors;
        return out;
    }

    succeededSpread(data: T): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        out.pristine = false;
        out.data = { ...(this.data || ({} as T)), ...(data || {}) };
        return out;
    }

    succeededConcat(data: T, end: 'prepend' | 'append', max = 0): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        const typedData = (data as Array<unknown> | undefined) || [];
        const thisData = (this.data as Array<unknown> | undefined) || [];
        out.pristine = false;
        if (end === 'append') out.data = [...thisData, ...typedData] as T;
        else out.data = [...typedData, ...thisData] as T;
        if (max) out.data = (out.data as Array<unknown>).slice(0, max) as T;
        return out;
    }

    succeededReplace(mutate: (exiting: T | undefined) => T | undefined): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        out.pristine = false;
        out.data = mutate(this.data);
        return out;
    }

    succeededMutateOne(filter: (item: U) => boolean, mutate: (exiting: U) => void): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        out.pristine = false;
    
        if (Array.isArray(this.data)) {
            out.data = this.data.map((i) => {
                if (filter(i)) {
                    mutate(i);
                }
                return i;
            }) as T;
        } else {
            console.warn({ me: 'mutate one', warning: `Did not mutate because the data wasn't a list`, data: this.data });
            out.data = this.data;
        }
    
        return out;
    }

    failed(error?: BadErrorType, keepData?: boolean): ApiData<T, U> {
        const out = new ApiData<T, U>(this.initialValue, this.loadingValue);
        out.data = keepData ? this.data : this.initialValue;
        out.pristine = false;
        out.error = error as BadErrorType;
        out.errors = [error as BadErrorType];
        return out;
    }

    reset(): ApiData<T, U> {
        return new ApiData<T, U>(this.initialValue, this.loadingValue);
    }
}
