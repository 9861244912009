// @ts-strict-ignore
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { GetSecurityTypeFromStore, IsMutualFundByMetadata, Sum, TradeableSecurityType } from '.';
import { GetConfig } from '../constants';
import { QuoteSelector, SinglePositionSelector } from '../constants/ReduxSelectors';
import { QualifiedSecurityId } from '../models/QualifiedSecurityId';
import { OptionSymbol, Order, TradeAction, TradeRequest } from '../redux/models';
import { FuturesSymbol } from '../redux/models/Futures/FuturesSymbol';

export const LOW_VOLUME_THRESHOLD = 50;

/** @deprecated Use new validation functions */
export const IsLowVolumeOption = (trade: Partial<TradeRequest> | TradeRequest) => {
    const optionSymbol = new OptionSymbol(trade?.securityId);
    const optionQuote = optionSymbol.selectQuote(GetConfig().Store.getState());

    if (!optionSymbol.isOption) return false; // only test options
    const volume = optionQuote?.volume || 0;
    return volume < LOW_VOLUME_THRESHOLD;
};

/** @deprecated Use new validation functions */
export const UserCanAffordTrade = (
    symbol: string,
    trade: Partial<TradeRequest>,
    meta?: SecurityMetadata,
    /** @deprecated No effect */
    permitShorts?: boolean
): boolean => {
    // Return true for multi-leg options Upstream will figure out bp/margin requirements.
    if (trade?.leg2Action) return true;

    // Futures can be sold naked
    if (FuturesSymbol.IsFuturesSymbol(trade.securityId)) return true;

    // if is dvp allow
    if (trade?.isDvp) return true;

    // For short sells, anything goes!
    // TODO -- For now, we actually aren't permitting short selling.
    // if (permitShorts && trade.action === 'Sell') return true;

    // We do permit apparent short sells for calls though, since it might be a covered call-- the API will tell us
    // We also let the user sell puts whenever they want
    const optSym = new OptionSymbol(trade?.securityId);
    if (trade.action === 'Sell' && optSym.isOption) return true;

    // TODO -- Make this work for B:2334130, use unitQuantity instead of sizesByOsi.
    const secId = QualifiedSecurityId.Parse(symbol)?.id;
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const marketPrice = QuoteSelector(symbol)(GetConfig().Store.getState())?.price || 0;

    if (trade.liquidate) return true;

    const positions = SinglePositionSelector(secId, trade.accountNumber)(GetConfig().Store.getState());
    const marketValueShares = Sum(positions?.map((p) => p.quantity));

    // For buys, compare cost in dollars
    if (trade.action === 'Buy') {
        const buyingPowerObject = GetConfig()
            .Store.getState()
            .accountSummary.buyingPower?.data?.accounts.find((a) => a.accountNumber === trade.accountNumber);
        const buyingPower = (isOption ? buyingPowerObject?.optionsBuyingPower : buyingPowerObject?.buyingPower) || 0;

        const purchasePrice = (() => {
            if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;
            const effectiveUnitPrice = trade.orderType === 'market' ? marketPrice : trade.orderPrice;
            return meta?.deliverableCount * trade.quantity * effectiveUnitPrice;
        })();

        const longBuy = buyingPower >= purchasePrice;
        // Allow buying up to quantity of shares the account is currently short (negative)
        const shortBuyBack = marketValueShares < 0 && trade.quantity <= Math.abs(marketValueShares);
        const canAfford = !!trade.orderId || longBuy || shortBuyBack;
        return canAfford;
    }

    // For sells, compare units (shares, contracts, etc) or dollars, depending on qualifier
    else if (trade.action === 'Sell') {
        const marketValueDollars = meta?.deliverableCount * marketValueShares * marketPrice;
        const canTrade = trade.quantityQualifier === 'EvenDollar' ? trade.quantity <= marketValueDollars : trade.quantity <= marketValueShares;
        return canTrade;
    } else return undefined;
};

/** @deprecated Use new validation functions */
export const InvalidStopOrder = (symbol: string, trade: Partial<TradeRequest>): boolean => {
    if (!['stop', 'stoplimit'].includes(trade.orderType)) return false;

    const marketPrice = QuoteSelector(symbol)(GetConfig().Store.getState())?.price || Infinity;
    return trade.action === 'Buy' ? trade.orderPrice < marketPrice : trade.orderPrice > marketPrice;
};

/** @deprecated Normalize your platform's viewmodel using platform-side functions */
export const PurifyTrade = (trade: Partial<TradeRequest>): Partial<TradeRequest> => {
    const clone = { ...trade };
    if (clone.orderType !== 'stoplimit') delete clone.stopLimitPrice;
    if (clone.orderType === 'market') {
        delete clone.orderPrice;
        delete clone.timeInForce;
    }
    return clone;
};

/** @deprecated Use new TradeHelpers.ConvertOrderToTradeRequest function */
export const ConvertOrderToTrade = (order: Order): Partial<TradeRequest> => {
    if(!order) return null;
    let tif = order?.timeInForce === 'GoodTillCancel' ? 'GTC' : order?.timeInForce;
    const isExtendedTif = tif === 'NTE' || tif === 'GTXPre' || tif === 'NTE';
    if (!['Day', 'GTC', 'FOK', 'IOC', 'NTE', 'GTXPre'].some((x) => x === tif)) {
        console.log(`Warning: order TIF was ${tif}, defaulting to Day`);
        tif = 'Day';
    }

    const orderType = DetermineOrderType(order);
    const qsi = QualifiedSecurityId.FromOrder(order);

    return {
        edit: true,
        orderId: order.orderId,
        securityId: qsi.toString(),
        securityIdType: qsi.ToOrderCompatibleSecurityType(), // Not strictly necessary, but we can do it so why not?
        securityNumber: order.securityNumber,
        accountNumber: order.accountNumber,
        action: GetValidTradeActionFromOrder(order) as any,
        quantity: order.orderQuantity,
        orderPrice: /^stop/.test(orderType) ? order.stopPrice : order.limitPrice,
        stopLimitPrice: orderType === 'stoplimit' ? order.limitPrice : undefined,
        timeInForce: tif as any,
        extendedTimeInForce: isExtendedTif ? (order.timeInForce as any) : undefined,
        quantityQualifier: order.quantityQualifier, // Only for mutual funds
        floorNote: order.floorNote,
        notHeld: order.notHeld,
        orderType,
        onClose: order?.onClose
    };
};

export const DetermineOrderType = (order: Order): 'market' | 'limit' | 'stop' | 'stoplimit' => {
    if (order.limitPrice && !order.stopPrice) return 'limit';
    else if (!order.limitPrice && !order.stopPrice) return 'market';
    else if (order.limitPrice && order.stopPrice) return 'stoplimit';
    else if (!order.limitPrice && order.stopPrice) return 'stop';
    else return 'market';
};

/** @deprecated Function privatized in new validate / submit setup */
export const GetValidTradeActionFromOrder = (order: Order) => {
    switch (order.action) {
        case 'BuyToOpen':
            return 'Buy';
        case 'BuyToClose':
            return 'Buy';
        case 'SellToOpen':
            return 'Sell';
        case 'SellToClose':
            return 'Sell';
        default:
            return order.action;
    }
};

/**
 * For equity positions only:
 * If you sell all but the fractional part (the "residual") of your position, that remaining residual
 * will be closed after a day or two. e.g., If you're long 5.5 shares and sell 5, it will trigger a residual
 * sell of your remaining 0.5 shares. Not if you sell just 4 shares though-- it has to be all but the
 * residual part
 */
const equityTypes: Set<TradeableSecurityType> = new Set(['adr', 'equity', 'etf']);
const OrderWillTriggerResidualSettlement = (trade: Partial<TradeRequest>) => {
    const type = GetSecurityTypeFromStore(trade.securityId);
    if (!equityTypes.has(type)) return false;

    const secId = QualifiedSecurityId.Parse(trade.securityId)?.id;
    const positions = SinglePositionSelector(secId, trade.accountNumber)(GetConfig().Store.getState());
    const sharesHeld = Math.abs(Sum(positions?.map((p) => p.quantity))); // might be contracts, bonds, etc. but w/e
    const diff = Math.abs(trade.quantity - sharesHeld);
    return diff > 0 && diff < 1;
};

const OrderIsShort = ({ quantity, selectedAccountNumber, symbol, tradeAction }: { quantity: number, selectedAccountNumber: string, symbol: string, tradeAction: TradeAction, }): boolean => {
    if (tradeAction === 'Buy') return false;
    const type = GetSecurityTypeFromStore(symbol);
    if (!equityTypes.has(type)) return false;

    const secId = QualifiedSecurityId.Parse(symbol)?.id;
    const positions = SinglePositionSelector(secId, selectedAccountNumber)(GetConfig().Store.getState());
    const sharesHeld = Math.abs(Sum(positions?.map((p) => p.quantity))); // might be contracts, bonds, etc. but w/e
    const result = sharesHeld - quantity < 0;
    return result;
};

/** this will return the current shares held for an order */
const GetOrderSharesHeld = (trade: Partial<TradeRequest>): number => {
    const type = GetSecurityTypeFromStore(trade.securityId);
    if (!equityTypes.has(type)) return 0;

    const secId = QualifiedSecurityId.Parse(trade.securityId)?.id;
    const positions = SinglePositionSelector(secId, trade.accountNumber)(GetConfig().Store.getState());
    const sharesHeld = Math.abs(Sum(positions?.map((p) => p.quantity))); // might be contracts, bonds, etc. but w/e
    return sharesHeld;
};

const ExceedsUsersMaxNotionalValue = (symbol: string, trade: Partial<TradeRequest>, meta?: SecurityMetadata): boolean => {
    // For now excluding multileg
    if (trade?.leg2Action) return false;

    if (!trade?.action?.toLowerCase()?.includes('buy') || FuturesSymbol.IsFuturesSymbol(trade.securityId) || IsMutualFundByMetadata(meta)) return false;

    const myInfo = GetConfig().Store.getState().user.myInfo;
    const marketPrice = QuoteSelector(symbol)(GetConfig().Store.getState())?.price || 0;

    const purchasePrice = (() => {
        if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;

        const getEffectiveUnitPrice = (): number => {
            switch (trade.orderType) {
                case 'market': return marketPrice;
                case 'stoplimit': return trade?.stopLimitPrice;
                default: return trade?.orderPrice;
            }
        }

        return meta?.deliverableCount * trade?.quantity * getEffectiveUnitPrice();
    })();

    if (myInfo?.data?.maxNotionalValue && myInfo?.data?.maxNotionalValue < purchasePrice) return true;

    return false;
};

/** @deprecated Please use new TradeHelpers located in phoenix/util/Trading/TradeHelpers.ts */
export const TradeHelpers = {
    /** @deprecated */ GetValidTradeActionFromOrder,
    /** @deprecated */ DetermineOrderType,
    /** @deprecated */ ConvertOrderToTrade,
    /** @deprecated */ RemoveExcessPrices: PurifyTrade,
    /** @deprecated */ IsInvalidStopOrder: InvalidStopOrder,
    /** @deprecated */ UserCanAffordTrade,
    /** @deprecated */ IsLowVolumeOption,
    /** @deprecated */ OrderWillTriggerResidualSettlement,
    /** @deprecated */ GetOrderSharesHeld,
    /** @deprecated */ OrderIsShort,
    /** @deprecated */ ExceedsUsersMaxNotionalValue
};
