import { Urls } from 'phoenix/constants';
import { AccountChartPoint } from 'phoenix/redux/models';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { Last, First } from 'phoenix/util';
import { create } from 'zustand';

interface PLStore {
    todaysPL: { [accountNumber: string | 'all']: number | undefined };
    todaysPLPercent: { [accountNumber: string | 'all']: number | undefined };
    setTodaysPL: (accountNumber?: string, value?: number) => void;
    setTodaysPLPercent: (accountNumber?: string, value?: number) => void;
    updateTodaysPL: (accountNumber?: string) => void;
}

const MatchSign = (toMatch: number, toChange: number) => {
    return toMatch < 0 ? -Math.abs(toChange) : Math.abs(toChange);
};

const removeLeadZerosPoints = (points: AccountChartPoint[]) => {
    const _points = [...points];
    while (_points.length > 0 && _points[0].value === 0) {
        _points.shift();
    }
    return _points;
};

const calcChangeFromPoints = (chartData: AccountChartPoint[]): { change: number | undefined; percent100: number | undefined } => {
    const points = chartData.sort((a, b) => a.timestamp - b.timestamp);
    const last = Last(points)?.value;
    const first = First(points)?.value;
    const change = last - first;
    let percent = first ? MatchSign(change, change / first) : NaN;

    if (Number.isNaN(percent)) {
        const filteredPoints = removeLeadZerosPoints(points);
        const filteredFirst = First(filteredPoints)?.value;
        const filteredChange = last - filteredFirst;
        percent = MatchSign(filteredChange, filteredChange / filteredFirst);
    }
    const percent100 = percent * 100;
    return { change, percent100 };
};

export const usePLStore = create<PLStore>((set, get) => {
    return {
        todaysPL: { all: undefined },
        todaysPLPercent: { all: undefined },
        setTodaysPL: (accountNumber?: string, value?: number) => set((s) => ({ ...s, todaysPL: { ...s.todaysPL, [accountNumber || 'all']: value } })),
        setTodaysPLPercent: (accountNumber?: string, value?: number) =>
            set((s) => ({ ...s, todaysPLPercent: { ...s.todaysPLPercent, [accountNumber || 'all']: value } })),
        updateTodaysPL: (accountNumber?: string) => {
            if (accountNumber) {
                SnexAxios.ApiGet<{ accountNumber: string; data: AccountChartPoint[] }>(Urls.accounts.getTodaysPnlChart(accountNumber))
                    .run()
                    .then((res) => {
                        const { change, percent100 } = calcChangeFromPoints(res?.data);
                        get().setTodaysPL(accountNumber, change);
                        get().setTodaysPLPercent(accountNumber, percent100);
                    });
            } else {
                SnexAxios.ApiGet<AccountChartPoint[]>(Urls.accounts.getTodaysPnlChart())
                    .run()
                    .then((res) => {
                        const { change, percent100 } = calcChangeFromPoints(res);
                        get().setTodaysPL('all', change);
                        get().setTodaysPLPercent('all', percent100);
                    });
            }
        }
    };
});
