// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '../..';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { ApiPosition } from 'phoenix/redux/models';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { DescriptionLabel, SymbolLabel } from '../Shared/Labels';
import { SecurityCellChart } from '../Shared/SecurityCellChart';
import { SecurityNameSection } from '../Shared/SecurityNameSection';
import style from '../style.module.scss';
import { PositionValue } from './PositionValue';
import { SecondaryRow } from './SecondaryRow';
import { useDispatch } from 'react-redux';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { QualifiedId } from 'phoenix/util/QualifiedId';

export interface FundPositionCellProps {
    getCached: boolean,
    hideAccountName?: boolean
    position: ApiPosition,
    streamingNamespace: string,
    symbol: string;
}

export const FundPositionCell = React.memo((props: FundPositionCellProps) => {

    const dispatch = useDispatch();
    const { getCached, position, hideAccountName, symbol } = props;

    useEffect(() => {
        const getData = async () => {
            if (!getCached) await dispatch(GetSecurityQuoteAction(symbol));
        };
        getData();
    }, []);

    const [latestChartPercChange] = useState(null);
    const [latestChartPrice] = useState(null);


    return (
        <>
            <Flex
                column
                className={style.cellContent}>
                <Flex className={style.mainRow}>
                    <SecurityNameSection>
                        <SymbolLabel tooltipText={`${position.description}`}>{position.description}</SymbolLabel>
                        <DescriptionLabel>{QualifiedId.RemovePrefix(symbol)}</DescriptionLabel>
                    </SecurityNameSection>
                    <PositionValue withQuoteOverride chartPercChange={latestChartPercChange} chartPrice={latestChartPrice} position={position} symbol={symbol} />
                </Flex>
                <SecondaryRow hideAccountName={hideAccountName} position={position} secType='mutual-fund' />
            </Flex>
        </>
    );
});