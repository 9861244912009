// @ts-strict-ignore
import { ApiData } from '../../models'
import { ReduxAction } from '../../models/ReduxAction'
import { Actions } from '../actions/Constants'
import { BankBalance } from '../models/Funding/BankBalance'
import { FundingState } from '../models/Funding/FundingState'

/** @deprecated use FundingSourcesStore, FundingHistoryStore, FundingLimitsStore and PlaidStore */
export const FundingReducer = (state: FundingState = new FundingState(), action: ReduxAction): FundingState => {
    switch (action.type) {
        case Actions.Funding.LinkedAccounts.GetAll.Loading: return { ...state, linkedAccounts: state.linkedAccounts.startLoading(state.linkedAccounts.data) }
        case Actions.Funding.LinkedAccounts.GetAll.Success: return { ...state, linkedAccounts: state.linkedAccounts.succeeded(action.data) }
        case Actions.Funding.LinkedAccounts.GetAll.Failure: return { ...state, linkedAccounts: state.linkedAccounts.failed(action.error) }
        case Actions.Funding.LinkedAccounts.Add.Loading: return { ...state, addLinkedAccount: state.addLinkedAccount.startLoading() }
        case Actions.Funding.LinkedAccounts.Add.Success: return { ...state, addLinkedAccount: state.addLinkedAccount.succeeded(action.data) }
        case Actions.Funding.LinkedAccounts.Add.Failure: return { ...state, addLinkedAccount: state.addLinkedAccount.failed(action.error) }
        case Actions.Funding.LinkedAccounts.Void.Loading: return { ...state, voidLinkedAccount: state.voidLinkedAccount.startLoading() }
        case Actions.Funding.LinkedAccounts.Void.Success: return { ...state, voidLinkedAccount: state.voidLinkedAccount.succeeded(action.data) }
        case Actions.Funding.LinkedAccounts.Void.Failure: return { ...state, voidLinkedAccount: state.voidLinkedAccount.failed(action.error) }

        case Actions.Funding.Plaid.GetToken.Loading: return { ...state, plaidLinkToken: state.plaidLinkToken.startLoading() }
        case Actions.Funding.Plaid.GetToken.Success: return { ...state, plaidLinkToken: state.plaidLinkToken.succeeded(action.data) }
        case Actions.Funding.Plaid.GetToken.Failure: return { ...state, plaidLinkToken: state.plaidLinkToken.failed(action.error) }

        case Actions.Funding.Plaid.GetAuthorization.Loading: return { ...state, plaidAuthorization: state.plaidAuthorization.startLoading() }
        case Actions.Funding.Plaid.GetAuthorization.Success: return { ...state, plaidAuthorization: state.plaidAuthorization.succeeded(action.data) }
        case Actions.Funding.Plaid.GetAuthorization.Failure: return { ...state, plaidAuthorization: state.plaidAuthorization.failed(action.error) }

        case Actions.Funding.Plaid.UpdateAuthorization.Loading: return { ...state, updatePlaidAuthorization: state.updatePlaidAuthorization.startLoading() }
        case Actions.Funding.Plaid.UpdateAuthorization.Success: return { ...state, updatePlaidAuthorization: state.updatePlaidAuthorization.succeeded() }
        case Actions.Funding.Plaid.UpdateAuthorization.Failure: return { ...state, updatePlaidAuthorization: state.updatePlaidAuthorization.failed(action.error) }

        case Actions.Funding.Plaid.GetVerificationStatus.Loading: return { ...state, plaidVerification: state.plaidVerification.startLoading() }
        case Actions.Funding.Plaid.GetVerificationStatus.Success: return { ...state, plaidVerification: state.plaidVerification.succeeded(action.data) }
        case Actions.Funding.Plaid.GetVerificationStatus.Failure: return { ...state, plaidVerification: state.plaidVerification.failed(action.error) }

        case Actions.Funding.Transfer.Deposit.Loading: return { ...state, submitDeposit: state.submitDeposit.startLoading() }
        case Actions.Funding.Transfer.Deposit.Success: return { ...state, submitDeposit: state.submitDeposit.succeeded() }
        case Actions.Funding.Transfer.Deposit.Failure: return { ...state, submitDeposit: state.submitDeposit.failed(action.error) }
        case Actions.Funding.Transfer.Withdraw.Loading: return { ...state, submitWithdraw: state.submitWithdraw.startLoading() }
        case Actions.Funding.Transfer.Withdraw.Success: return { ...state, submitWithdraw: state.submitWithdraw.succeeded() }
        case Actions.Funding.Transfer.Withdraw.Failure: return { ...state, submitWithdraw: state.submitWithdraw.failed(action.error) }
        case Actions.Funding.Transfer.Limits.Loading: return { ...state, dailyLimits: state.dailyLimits.startLoading() }
        case Actions.Funding.Transfer.Limits.Success: return { ...state, dailyLimits: state.dailyLimits.succeeded(action.data) }
        case Actions.Funding.Transfer.Limits.Failure: return { ...state, dailyLimits: state.dailyLimits.failed(action.error) }

        case Actions.Funding.LinkedAccounts.Limits.Loading: return { ...state, managementLimits: state.managementLimits.startLoading() }
        case Actions.Funding.LinkedAccounts.Limits.Success: return { ...state, managementLimits: state.managementLimits.succeeded(action.data) }
        case Actions.Funding.LinkedAccounts.Limits.Failure: return { ...state, managementLimits: state.managementLimits.failed(action.error) }

        case Actions.Funding.LinkedAccounts.Balance.Loading:
            state.balances[action.subject] = new ApiData<BankBalance>().startLoading()
            return state
        case Actions.Funding.LinkedAccounts.Balance.Success:
            state.balances[action.subject] = (state.balances[action.subject] || new ApiData<BankBalance>()).succeeded(action.data)
            return state
        case Actions.Funding.LinkedAccounts.Balance.Failure:
            state.balances[action.subject] = (state.balances[action.subject] || new ApiData<BankBalance>()).failed(action.error)
            return state

        case Actions.Funding.FundingSources.GetAll.Loading: return { ...state, fundingSources: state.fundingSources.startLoading(state.fundingSources.data) };
        case Actions.Funding.FundingSources.GetAll.Success: return { ...state, fundingSources: state.fundingSources.succeeded(action.data) };
        case Actions.Funding.FundingSources.GetAll.Failure: return { ...state, fundingSources: state.fundingSources.failed(action.error) };

        // No success handlers for these since they both have success callbacks that just cause the accounts to load again

        case Actions.Funding.FundingSources.Add.Loading: return { ...state, fundingSources: state.fundingSources.startLoading() };
        case Actions.Funding.FundingSources.Add.Failure: return { ...state, fundingSources: state.fundingSources.failed(action.error, true) };

        case Actions.Funding.FundingSources.Void.Loading: return { ...state, fundingSources: state.fundingSources.startLoading() };
        case Actions.Funding.FundingSources.Void.Failure: return { ...state, fundingSources: state.fundingSources.failed(action.error) };

        case Actions.Funding.Ains.GetVoids.Loading: return { ...state, voidHistory: state.voidHistory.startLoading() };
        case Actions.Funding.Ains.GetVoids.Success: return { ...state, voidHistory: state.voidHistory.succeeded(action.data) };
        case Actions.Funding.Ains.GetVoids.Failure: return { ...state, voidHistory: state.voidHistory.failed(action.error) };

        case Actions.Funding.Ains.GetDepositFailures.Loading: return { ...state, depositFailuresHistory: state.depositFailuresHistory.startLoading() };
        case Actions.Funding.Ains.GetDepositFailures.Success: return { ...state, depositFailuresHistory: state.depositFailuresHistory.succeeded(action.data)};
        case Actions.Funding.Ains.GetDepositFailures.Failure: return { ...state, depositFailuresHistory: state.depositFailuresHistory.failed(action.error) };

        case Actions.Funding.Ains.GetWithdrawFailures.Loading: return { ...state, withdrawFailuresHistory: state.withdrawFailuresHistory.startLoading() };
        case Actions.Funding.Ains.GetWithdrawFailures.Success: return { ...state, withdrawFailuresHistory: state.withdrawFailuresHistory.succeeded(action.data)};
        case Actions.Funding.Ains.GetWithdrawFailures.Failure: return { ...state, withdrawFailuresHistory: state.withdrawFailuresHistory.failed(action.error) };

        case Actions.Funding.Ains.ClearVoidsAndDepositFailures.Success: return { ...state, voidHistory: state.voidHistory.succeeded(null), 
            depositFailuresHistory: state.depositFailuresHistory.succeeded(null),
            withdrawFailuresHistory: state.withdrawFailuresHistory.succeeded(null) };

        default: return state;
    }
}
