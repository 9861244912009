// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web';
import { SnexChartPoint } from '../../../constants/ChartTypes';
import moment from 'moment';

export class AccountChartPoint {
    accountId?: string;
    value: number;
    timestamp: number;
    [key: string]: number | string | undefined;

    static FromLiveData = (update: ItemUpdate): AccountChartPoint => {
        const acctUpdate = {
            accountId: update.getValue('id'),
            value: parseFloat(update.getValue('value')),
            // Assuming the incoming timestamp is in the format "MM/DD/YYYY HH:mm:ss" and in UTC
            timestamp: moment.utc(update.getValue('timestamp'), 'MM/DD/YYYY HH:mm:ss').valueOf()
        };

        return acctUpdate;
    };

    static toSnexChartPoint = (point: AccountChartPoint): SnexChartPoint => {
        const p: SnexChartPoint = {
            x: point.timestamp,
            y: point.value
        };
        return p;
    };
}
