import { Flex } from "components"
import { OnboardingInputRow } from "components/Onboarding"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { OnboardingTextField } from "../../../Base/OnboardingControls"
import { useRegistrationStore } from "../../Store"
import { getPasswordErrors } from "./Validation"

export const Registration_Password_Form = () => {
    const {data, setValue} = useRegistrationStore()
    const text = useText(t => t.webRegistration)

    const handleChange = (key: string, value?: any) => {
        setValue(key, value)
    }

    const getErrors = (key: string) => {
        return getPasswordErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }

    return(
        <Flex column fullWidth center>
            <OnboardingInputRow noPadding>  
                <OnboardingTextField name='data.password' secret label={text.password} value={data.password} handleChange={handleChange} errors={getErrors('data.password')} />    
            </OnboardingInputRow>
            <OnboardingInputRow>  
                <OnboardingTextField name='data.confirmPassword' secret label={text.confirmPassword} value={data.confirmPassword} handleChange={handleChange} errors={getErrors('data.confirmPassword')} />    
            </OnboardingInputRow>
        </Flex>
        
    )
}