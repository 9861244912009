import { addMonths } from 'date-fns'
import { SafeFormat } from '.'

export type YyyyMmDd = string;

export const GetCurrentFiscalQuarer = (): { quarter: number, year: number, serialNumber: number } => {
    return EstimateFiscalQuarterDate(new Date())
}

export const GetNextFiscalQuarer = (): { quarter: number, year: number, serialNumber: number } => {
    return EstimateFiscalQuarterDate(addMonths(new Date(), 3))
}

export const EstimateFiscalQuarterDate = (date: Date): { quarter: number, year: number, shortYear: string, serialNumber: number } => {
    const quarter = Number(SafeFormat(date, 'q'))
    const year = Number(SafeFormat(date, 'yyyy'))
    const serialNumber = year * 10 + quarter
    return { quarter, year, serialNumber, shortYear: SafeFormat(date, 'yy') }
}

export const EstimateFiscalQuarter = (date: YyyyMmDd): { quarter: number, year: number, serialNumber: number } => {
    // Q1: January 1 -> March 31
    // Q2: April 1 -> June 30
    // Q3: July 1 -> September 30
    // Q4: October 1 -> End of Year

    // Handling this date strictly symbolically, since between timezones it can shift
    const nums = date.replace(/[^\d]/g, '')
    const year = parseInt(nums.substr(0, 4))
    const m = parseInt(nums.substr(4, 2))
    const quarter = (() => {
        if (m < 4) return 1 // Before April
        else if (m < 7) return 2 // Before July
        else if (m < 10) return 3 // Before October
        else return 4 // Anything after
    })()
    const serialNumber = year * 10 + quarter

    return { year, quarter, serialNumber }
}
