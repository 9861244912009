import { usePositionsStore } from "phoenix/stores/PositionsStore";
import { useMemo } from "react";

export const useFlattenedPositions = (accountNumber?: string) => {
    const allPositions = usePositionsStore(s => s.positions)
  
    const positions = useMemo(
        () => {
            const flat = allPositions?.flatMap?.(s => s) || []
            const result = flat?.filter(p => p.productType !== 'Cash & Equivalents' && accountNumber ? p.accountNumber === accountNumber : true) || [];
            return result;
        }, [allPositions, accountNumber]
    );
    return positions
}