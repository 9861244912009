// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models';
import { ActionNames, Actions, GroupNameChecker } from '../actions/Constants';
import { OptionChainResult } from '../models';
import { OptionQuote } from '../models/Options/OptionQuote';
import { OptionsState } from '../models/Options/OptionsState';

const permitted = GroupNameChecker([Actions.Options]);
export const OptionsReducer = (state: OptionsState = new OptionsState(), action: ReduxAction): OptionsState => {
    if (!permitted(action)) return state;

    const ddata = <TData>(d?: ApiData<TData>) => d || new ApiData<TData>();
    const updateData = <TData>(names: ActionNames, d: ApiData<TData>): ApiData<TData> => {
        switch (action.type) {
            case names.Loading:
                return d.startLoading(d.data);
            case names.Success:
                return d.succeeded(action.data);
            case names.Failure:
                return d.failed(action.error);
            default:
                return d;
        }
    };

    // Get Months
    if (Actions.Options.GetMonths.matches(action.type)) {
        const updated = updateData(Actions.Options.GetMonths, ddata(state.monthsByUnderlying[action.subject]));
        state.monthsByUnderlying[action.subject] = updated;
        return state;
    }

    // Get Single Quote
    if (Actions.Options.GetSingleQuote.matches(action.type)) {
        if (!(action?.data?.bid && action?.data?.ask)) return state;
        const updated = updateData(Actions.Options.GetSingleQuote, ddata(state.quotesByOsi[action.subject]));
        state.quotesByOsi[action.subject] = updated;
        return state;
    }

    // Get Chain (only respond to success)
    if (Actions.Options.GetChain.matches(action.type)) {
        if (action.type !== Actions.Options.GetChain.Success) return state;
        const result = action.data as OptionChainResult;

        const resultsBySymbol = result?.calls.concat(result?.puts).reduce((lookup, next) => {
            const merged = ddata(state.quotesByOsi[next.symbol]).succeededSpread(next);
            merged.data = { ...merged.data, changePercent: merged?.data?.percentChange / 100, percentChange: merged?.data?.percentChange / 100 };
            return {
                ...lookup,
                [next.symbol]: merged
            };
        }, {});

        state.quotesByOsi = { ...state.quotesByOsi, ...resultsBySymbol };
        return state;
    }

    // Get Size
    if (Actions.Options.GetContractSize.matches(action.type)) {
        const updated = updateData(Actions.Options.GetContractSize, ddata(state.sizesByOsi[action.subject]));
        // replace entire state object, else selectors based on object props won't update
        const newState = { ...state, sizesByOsi: { ...state.sizesByOsi, [action.subject]: updated } };
        return newState;
    }

    // Live Option Update (SNEX)
    if (action.type === Actions.Options.StreamQuotes.Update) {
        const update = action.data as OptionQuote;
        state.quotesByOsi[update.symbol] = state.quotesByOsi[update.symbol]?.succeededSpread(update);
        return state;
    }

    // Live Option Update (GAIN)
    if (action.type === Actions.Options.StreamFuturesQuotes.Update) {
        const updates = (action.data || []) as OptionQuote[];
        for (const u of updates) {
            state.quotesByOsi[u.symbol] = state.quotesByOsi[u.symbol]?.succeededSpread(u);
        }
        return state;
    }

    return state;
};
