import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Relays } from 'constants/Relays'
import { useRelay } from 'phoenix/hooks/UseRelay'
import { AnimatedModal } from '../AnimatedModal'
import { AddManyToListContent } from './AddManyToListContent'

export const AddManyToListModal = ({ isOpen, toggleModal, watchlistId }: { isOpen: boolean, toggleModal: (isOpen: boolean) => any, watchlistId?:string}) => {
    const  watchlistIdFromParams  = (useParams<{watchlistId: string}>())?.watchlistId
    return (
        <AnimatedModal open={isOpen} onClose={() => toggleModal(false)}>
            <AddManyToListContent toggleModal={toggleModal} watchlistId={watchlistId || watchlistIdFromParams} />
        </AnimatedModal>
    )
}