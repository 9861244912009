// @ts-strict-ignore
import { Grid, Skeleton, Typography } from '@mui/material';
import { Flex, SectionHeader } from 'components';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetRiskFreeRate } from 'phoenix/redux/actions';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { XS } from 'phoenix/xstream/XS';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const FuturesOptionStatsSection = React.memo(({ symbol }: { symbol: string }) => {
    const dispatch = useDispatch();
    const quote = XS.OptionQuotes.use(symbol); // Subscription in OptionContractScreen
    const text = useText((s) => s.optionContractScreen.stats);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const { formatPrice } = useAssetClassMetaV2(symbol);

    useEffect(() => {
        dispatch(GetRiskFreeRate());
    }, [symbol]);

    const InfoNugget = (p: { label: string; children: any }) => {
        const showLoading = !quote || quote?.loading;
        return (
            <Grid item style={{ minHeight: 48 }} xs={4}>
                <Flex column>
                    <Typography variant='h6'>{p.label}</Typography>
                    {showLoading ? (
                        <Skeleton animation='wave' style={{ height: 14 }} />
                    ) : (
                        <Typography style={{ opacity: showLoading ? 0.4 : 1, fontWeight: 'normal' }} variant='h6'>
                            {p.children}
                        </Typography>
                    )}
                </Flex>
            </Grid>
        );
    };

    return (
        <Flex column>
            <SectionHeader label={text.stats} />
            <Grid container spacing={3}>
                <InfoNugget label={text.bid}>{formatPrice(quote?.bid, meta)}</InfoNugget>
                <InfoNugget label={text.ask}>{formatPrice(quote?.ask, meta)}</InfoNugget>
                <InfoNugget label={text.open}>{formatPrice(quote?.open, meta)}</InfoNugget>
                <InfoNugget label={text.high}>{formatPrice(quote?.high, meta)}</InfoNugget>
                <InfoNugget label={text.low}>{formatPrice(quote?.low, meta)}</InfoNugget>
            </Grid>
        </Flex>
    );
});
