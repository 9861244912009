import { ItemUpdate } from "lightstreamer-client-web"
import { LiveDataGroups } from "phoenix/constants"
import { FuturesSymbol } from "phoenix/redux/models/Futures/FuturesSymbol"
import { XstreamReduxSubscribe } from "phoenix/xstream/actions/XstreamActionShared"

export const XstreamDomUpdateActionName = 'XSTREAM_DOM_UPDATE'

interface DomStreamData {
    bids: any;
    asks: any;
}

export const XstreamDomSubscribeAction = (qsi: string, namespace: string, callback?: (domData: DomStreamData) => void) => {
    if (!FuturesSymbol.IsFuturesSymbol(qsi)) return
    const fsym = new FuturesSymbol(qsi)

    return XstreamReduxSubscribe(LiveDataGroups.Futures, [`dom:${fsym.noPrefix}`], XstreamDomUpdateActionName, ['Asks', 'Bids'], (update: ItemUpdate) => {
        try {
            const bidAsks = {
                bids: JSON.parse(update.getValue('Bids')),
                asks: JSON.parse(update.getValue('Asks'))
            }
            if (callback) callback(bidAsks)
            return bidAsks
        } catch (error) {
            console.log('cant do that')
        }
    }, {
        mode: 'MERGE',
        subject: qsi,
        namespace,
        upstream: 'gain'
    })
}