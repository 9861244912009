// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web'

export class PortfolioChartPoint {
    timestamp: number
    totalValue?: number

    static FromLiveData = (update: ItemUpdate): PortfolioChartPoint => {
        return {
            totalValue: parseFloat(update.getValue('totalValue')),
            timestamp: Number(update.getValue('timestamp'))
        }
    }
}
