import React from 'react';
import { useColors } from '../../hooks/UseColors';

export const KeyboardKeyDisplay = React.memo((props: { children: any }) => {

    const colors = useColors();

    return (
        <kbd style={{
            borderRadius: 3, border: `solid 1px ${colors.inputBorderColor}`,
            padding: '1px 4px', margin: '0 4px'
        }}>
            { props.children }
        </kbd>
    );
});
