// @ts-strict-ignore
import { CenterFocusStrong } from "@mui/icons-material"
import { display, flexbox } from "@mui/system"
import { ErrorBoundary } from "@sentry/react"
import { LoadingSpinner } from "components/LottieAnimations"
import { useSnexStore } from "phoenix/hooks/UseSnexStore"
import { GetWhitelabelSettings } from "phoenix/redux/actions/WhitelabelActions"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import SanitizedHTML from 'react-sanitized-html';

export const WhitelabelCard = () => {
    const dispatch = useDispatch()
    const settings = useSnexStore(s => s.whitelabel.settings)

    useEffect(() => {
       dispatch(GetWhitelabelSettings())
    })

    return(
        <ErrorBoundary> {
        settings?.loading || settings?.pristine  ?  null :
            <SanitizedHTML style={{maxWidth: 250, minWidth: 150, margin: 'auto 0', wordBreak: 'break-all' }} html={settings?.data?.cardText} /> 
        }
        </ErrorBoundary>
    )
}