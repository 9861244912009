import { Typography } from '@mui/material'
import React from 'react'
import { Flex } from '..'


export const FilterHeader = (props: { variant?: 'text'|'element', children: any }) => {
    return (
        <Flex align='center' style={{ textAlign: 'left', marginBottom: 5, minHeight: '42px', width: '100%', boxSizing: 'border-box', paddingRight: 10 }}>
            { props.variant === 'element' ? props.children : <Typography variant='h6'>{ props.children }</Typography> }
        </Flex>
    )
}

