import { OnboardingInputError } from 'phoenix/onboarding/OnboardingTypes';
import { useFullyPaidLendingStore } from '../../Store';
import { T } from 'phoenix/assets/lang/T';

export const FPLAgreementsAreValid = () => getFPLAgreementErrors()?.length < 1;
export const getFPLAgreementErrors = () => {
    const keys = [
        'data.acceptedLoanAgreement.viewed',
        'data.acceptedLoanAgreement.accepted',
        'data.acceptedAccountControlAgreement.viewed',
        'data.acceptedAccountControlAgreement.accepted',
        'data.axosAcknowledgement'
    ];
    const _errors = [] as OnboardingInputError[];
    const data = useFullyPaidLendingStore.getState().data;

    if (!data.acceptedAccountControlAgreement.accepted)
        _errors.push({ key: 'acceptedAccountControlAgreement', error: T((t) => t.sharedOnboarding.agreementRequired('Fully Paid Lending Account Control Agreement')) });
    if (!data.acceptedLoanAgreement.accepted)
        _errors.push({ key: 'acceptedLoanAgreement', error: T((t) => t.sharedOnboarding.agreementRequired('Fully Paid Master Securities Loan Agreement')) });
    if (!data.axosAcknowledgement.accepted) _errors.push({ key: 'axosAcknowledgement', error: T((t) => t.sharedOnboarding.agreementRequired('Axos Acknowledgement')) });

    return _errors.filter((e) => !!e);
};
