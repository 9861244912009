import { useLocalStorage } from './UseLocalStorage';
import { MyProgress } from 'phoenix/redux/models/Users/MyProgress';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useEffect } from 'react';
import { isEqual } from 'lodash';

export const useProgress = (): [MyProgress | null, (value: MyProgress) => void] => {
    const userInfo = useSnexStore((s) => s.user.myInfo);
    const [progress, setProgress] = useLocalStorage<MyProgress | null>('USER_PROGRESS', null);

    useEffect(() => {
        if (!userInfo?.loading && !userInfo?.pristine && userInfo?.data) {
            const newProgress = progress === null ? new MyProgress(userInfo.data?.hasBeenWelcomed) : progress;
            if (userInfo.data?.initialFundsRecieved) newProgress.initialFundsRecieved = true;
            if (!isEqual(progress, newProgress)) setProgress(newProgress);
        }
    }, [progress, setProgress, userInfo.data, userInfo?.loading, userInfo?.pristine]);

    return [progress, setProgress];
};
