
export class MyProgress {
    portfolioTour?: boolean
    premiumStockTour?: boolean
    stockScreenTour?: boolean
    hasTraded?: boolean
    hasBeenWelcomed?: boolean
    initialFundsRecieved?: boolean
    tickerBarTour?: boolean
    cryptoTour?: boolean

    constructor (defaultValue = false) {
        this.portfolioTour = defaultValue
        this.premiumStockTour = defaultValue
        this.stockScreenTour = defaultValue
        this.hasTraded = defaultValue
        this.hasBeenWelcomed = defaultValue
        this.initialFundsRecieved = defaultValue
        this.tickerBarTour = defaultValue
        this.cryptoTour = defaultValue
    }

    static resetTours (currentProgress?: MyProgress) {
        const out = currentProgress || new MyProgress(true)
        out.portfolioTour = false
        out.premiumStockTour = false
        out.stockScreenTour = false
        out.tickerBarTour = false
        out.cryptoTour = false
        console.log('Latest progress: ', out)
        return out
    }

    static dismissTours (currentProgress?: MyProgress) {
        const out = currentProgress || new MyProgress(true)
        out.portfolioTour = true
        out.premiumStockTour = true
        out.stockScreenTour = true
        out.tickerBarTour = true
        out.cryptoTour = true
        return out
    }
}
