import { IconButton, Tooltip, Badge } from '@mui/material'
import React, { useCallback } from 'react'
import { Flex } from '..'
import './index.module.css';

export interface ActionItem { iconComponent: any, onClick?: () => any, toolTipText?: string, badgeContent?: string | number, badgeColor?: 'primary' |'secondary', showZeroBadge?: boolean }

export const ActionButtonGroup = React.memo(({ actionItems }: { actionItems: ActionItem[] }) => {

    const Item = useCallback(({ item }: { item: ActionItem }) => {
        if (item.toolTipText)
            return (
                <Tooltip placement='right' title={item.toolTipText}>
                    <Badge badgeContent={item.badgeContent} color={item.badgeColor} showZero={item.showZeroBadge}>
                        <IconButton className={'icon-button'} size='small' onClick={ item.onClick }>
                            <item.iconComponent />
                        </IconButton>
                    </Badge>
                </Tooltip>
            )

        return (
            <Badge badgeContent={item.badgeContent} color={item.badgeColor} showZero={item.showZeroBadge}>
                <IconButton className={'icon-button'} size='small' onClick={ item.onClick }>
                    <item.iconComponent />
                </IconButton>
            </Badge>
        )
    }, [])

    return (
        <Flex row align='flex-end' justify='flex-end' style={{ paddingTop: 10 }}>
            {actionItems.map((item, key) => <div key={key} style={{ marginLeft: 10 }}><Item item={item} /></div>)}
        </Flex>
    )
})