import { ApiData } from '../../../models'
import { AcatDeliveringFirm } from './AcatDeliveringFirm'
import { AcatResponse } from './AcatResponse'

export class AcatsState {
    deliveringFirms: ApiData<AcatDeliveringFirm[]>
    submit: ApiData<AcatResponse | null>

    constructor () {
        this.deliveringFirms = new ApiData([])
        this.submit = new ApiData(null)
    }
}
