import { Urls } from '../../constants';
import { QualifiedSecurityId } from '../../models/QualifiedSecurityId';
import { Actions } from './Constants';
import { ReduxApiGet } from './Helpers';

export const GetLanguagesAction = () =>
    ReduxApiGet(Urls.system.getLanguages(), Actions.System.GetLanguages)
        .useStored((s) => (s.system.languages.data?.length ? s.system.languages.data : null))
        .withMutex()
        .run();

export const GetTimeZonesAction = () =>
    ReduxApiGet(Urls.system.getTimeZones(), Actions.System.GetTimeZones)
        .useStored((s) => (s.system.timezones.data?.length ? s.system.timezones.data : null))
        .withMutex()
        .run();

export const GetSystemAvailabilityAction = () => ReduxApiGet(Urls.system.getStatus(), Actions.System.GetStatus).withMutex().run();
// .almostRun(true, [
//     {
//         id: 'functionality/system-general',
//         type: 'functionality',
//         status: 'unavailable',
//         lastAvailable: '2022-07-07T00:00:00.000Z',
//         asOf: new Date().toISOString(),
//     }
// ], false)

export const GetTermsDocument = (documentName: string) =>
    ReduxApiGet(Urls.system.getTermsDocument(documentName), Actions.System.GetTermsDocument)
        .withMutex()
        .useStored((s) => s.system.termsDocuments.byName[documentName]?.data)
        .withSubject(documentName)
        .showToasts()
        .run();

export const SetTitleSecurityAction = (securityId: QualifiedSecurityId | null) => ({ type: Actions.System.SetTitleSecurity, data: securityId });
