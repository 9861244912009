import { Typography, TypographyVariant } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Relays } from 'constants/Relays';
import { useRelay } from 'phoenix/hooks/UseRelay';
import { FormatNumber } from 'phoenix/util';
import './MillionBillion.css';

export interface MillionBillion2Props {
    decimal?: boolean;
    expanded?: boolean;
    group?: string;
    manageExpand?: boolean;
    money?: boolean;
    onExpand?: (value: boolean) => void;
    style?: React.CSSProperties;
    value: number;
    variant?: TypographyVariant;
}

export const MillionBillion = React.memo((props: MillionBillion2Props) => {
    const { decimal, expanded = false, group = '', manageExpand, money, onExpand, style, value, variant } = props;
    const [groupExpand, setGroupExpanded] = useRelay(Relays.MillionBillion(group));
    const [_exp, _setExp] = useState(false);

    // Expanding is sort of wild here
    // -> if implementor has asked for us (the component) manage the expand as a `group`, then that's what governs _exp
    // -> if implementor has asked for us to `manageExpand` without a group, then we govern _exp ourselves and ignore the `expanded` prop
    // -> otherwise, just set _exp according the `expanded` prop
    useEffect(() => {
        if (group) _setExp(!!groupExpand);
        else if (!manageExpand) _setExp(expanded);
    }, [manageExpand, group, expanded, groupExpand]);

    const { min, suffix } = useMemo(
        () =>
            [
                { min: 1000000000, suffix: 'B' },
                { min: 1000000, suffix: 'M' },
                { min: 1000, suffix: 'K' }
            ].find((c) => Math.abs(value) > c.min) || {
                min: 1,
                suffix: ''
            },
        [value]
    );

    const formatted = useMemo(() => {
        if (_exp) {
            if (money) return FormatNumber.toMoney(value);
            else if (decimal) return FormatNumber.toDecimal(value);
            else return FormatNumber.toCommas(value);
        } else {
            const reduced = decimal ? FormatNumber.toDecimal(value / min, 2) : Math.floor(value / min);
            if (money) return `$${reduced}${suffix}`.replace(/^\$-/, '-$');
            else return `${reduced}${suffix}`;
        }
    }, [_exp, money, value, decimal, min, suffix]);

    const tooltip = useMemo(() => {
        if (money) return FormatNumber.toMoney(value);
        else if (decimal) return FormatNumber.toDecimal(value);
        else return FormatNumber.toCommas(value);
    }, [money, decimal, value]);

    const handleClick = useCallback(() => {
        if (group) setGroupExpanded(!_exp);
        else if (manageExpand) _setExp(!_exp);
        onExpand && onExpand(_exp);
    }, [_exp, group, manageExpand, onExpand, setGroupExpanded]);

    const clickable = useMemo(() => !!handleClick || !!onExpand, [handleClick, onExpand]);

    return (
        <Typography
            className={`million-billion-text-${clickable ? 'clickable' : 'normal'}`}
            style={{ fontWeight: 400, ...style }}
            title={tooltip}
            variant={variant || 'h6'}
            onClick={handleClick}
        >
            {formatted}
        </Typography>
    );
});
