import { UseTickerTape } from 'phoenix/hooks/UseTickerTape';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from 'util/Routes';

const useIsFullscreenRoute = () => {
    const location = useLocation();

    const isFullScreenRoute = useMemo(() => {
        const fullScreenRoutes = [
            Routes.positions(),
            Routes.fplOnboarding(),
            '/onboarding',
            Routes.onboardingSelect(),
            '/neil',
            '/registration',
            Routes.deadLogin(),
            Routes.localLogin(),
            Routes.loggedOut(),
            Routes.register()
        ];
        return (
            location?.pathname?.toLowerCase() === Routes.portfolio() ||
            fullScreenRoutes.some((x) => location?.pathname?.toLowerCase().includes(x.toString().toLowerCase()))
        );
    }, [location.pathname]);

    return isFullScreenRoute;
};

const useShowHeader = (): boolean => {
    const location = useLocation();
    const isFullScreenRoute = useIsFullscreenRoute();

    const fullScreenExceptions = useMemo(() => [Routes.positions()], []);

    const showHeader = useMemo(() => {
        return (
            !isFullScreenRoute ||
            location.pathname === Routes.portfolio() ||
            fullScreenExceptions.some((x) => location.pathname.toLowerCase().includes(x.toString().toLowerCase()))
        );
    }, [fullScreenExceptions, isFullScreenRoute, location.pathname]);

    return showHeader;
};

const useShowTickerTape = (): boolean => {
    const location = useLocation();
    const isFullScreenRoute = useIsFullscreenRoute();

    const fullScreenExceptions = useMemo(() => [Routes.portfolio(), Routes.positions()], []);

    const tickerTapeEnabled = UseTickerTape();
    const showTickerTape = useMemo(() => {
        return tickerTapeEnabled && (!isFullScreenRoute || fullScreenExceptions.some((x) => location?.pathname?.toLowerCase().includes(x.toString().toLowerCase())));
    }, [tickerTapeEnabled, isFullScreenRoute, fullScreenExceptions, location?.pathname]);

    return showTickerTape;
};

const useShowFooter = (): boolean => {
    const location = useLocation();
    const isFullScreenRoute = useIsFullscreenRoute();

    const fullScreenExceptions = useMemo(() => [Routes.positions()], []);

    const showFooter = useMemo(() => {
        return (
            !isFullScreenRoute ||
            location.pathname === Routes.portfolio() ||
            fullScreenExceptions.some((x) => location.pathname.toLowerCase().includes(x.toString().toLowerCase()))
        );
    }, [fullScreenExceptions, isFullScreenRoute, location.pathname]);

    return showFooter;
};

export { useShowHeader, useIsFullscreenRoute, useShowTickerTape, useShowFooter };
