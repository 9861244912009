import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';
import style from './style.module.scss';

interface MenuItemProps {
    ariaLabel?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
    withTooltip?: boolean;
}

export const MenuItem = ({ ariaLabel = '', children, disabled, onClick, withTooltip }: MenuItemProps): ReactElement => {
    return (
        <button
            {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
            className={`${style.menuItemWrapper}${disabled ? ` ${style.disabled}` : ''}${withTooltip ? ` ${style.withTooltip}` : ''}`}
            onClick={!disabled && onClick ? (e) => onClick(e) : undefined}
            tabIndex={!disabled || ariaLabel ? 0 : undefined} // Sometimes aria-label is used as a11y alternative to hover tooltip, so make this focusable
        >
            {children}
        </button>
    );
};
