import { Flex } from 'components/Flex';
import { GlobalMessageBanner } from 'components/GlobalMessageBanner/globalMessageBanner';
import { useGlobalMessageStore } from 'components/GlobalMessageBanner/store';
import { Header } from 'components/Header';
import { useShowHeader } from 'hooks/UseIsFullscreenRoute';
import React, { ReactElement, useEffect, useRef } from 'react';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import './BaseScreenLayout.scss';

export const HeaderBaseLayout = (): ReactElement => {
    const { hideHeader, noShow, setBaseLayoutHeaderHeight } = useBaseScreenLayoutStore((s) => s);
    const showHeader = useShowHeader();

    const headerRef = useRef<HTMLDivElement>(null);

    const { message: globalMessage } = useGlobalMessageStore();

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (headerRef.current) {
                setBaseLayoutHeaderHeight(headerRef.current.clientHeight);
            }
        });

        if (headerRef.current) {
            observer.observe(headerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [setBaseLayoutHeaderHeight]);

    if (noShow || !showHeader) return <></>;
    return (
        <>
            {!hideHeader && (
                <Flex ref={headerRef} column fullWidth id='header-section'>
                    <Header />
                </Flex>
            )}
            {globalMessage && (
                <div id={'global-banner-section'} style={{ boxSizing: 'border-box', width: '100%', padding: '0 32px 12px' }}>
                    <GlobalMessageBanner />
                </div>
            )}
        </>
    );
};
