// @ts-strict-ignore
export type TierLevel = 'free' | 'trial' | 'preferred'
export type ProductCode = 'sx1' | 'mi' | 'mi+sx1'

export type PremiumProductMetadata = {
    code: ProductCode,
    name: string,
    price: number,
}

export const ProdCode = (tier: TierLevel, prod: PremiumProductMetadata) => `${tier}:${prod.code}`

// Doing the types this way so that we get nice intellisense
export const PremiumProducts = {
    premium: { code: 'sx1', name: 'StoneX One Premium', price: 10 } as PremiumProductMetadata,
    mi: { code: 'mi', name: 'MI Premium', price: 50 } as PremiumProductMetadata,
    miPremiumBundle: { code: 'mi+sx1', name: 'StoneX One + MI Premium', price: 70 } as PremiumProductMetadata
}

export const GetProductByCode = (code: string): PremiumProductMetadata | null => {
    return Object.values(PremiumProducts).find(x => x.code === code)
}
