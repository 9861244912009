// @ts-strict-ignore
import { ApiData } from '../../../models/ApiData'
import { AggregateBuyingPower } from './AccountBuyingPower'
import { AccountSummary } from './AccountSummary'

export type AccountAssetBreakdown = {
    positionSummary: {
        name: string
        value: number
        quantity: number
    }[],
    industrySummary: {
        name: string
        marketValue: number
    }[]
}

export class AccountSummaryState {
    aggregate: ApiData<AccountSummary>;
    all: ApiData<AccountSummary[]>;
    byNumber: { [key: string]: ApiData<AccountSummary> };
    buyingPower: ApiData<AggregateBuyingPower>;

    // Separating these out for mobile since we have two endpoints overwriting each other...
    aggregateBreakdown: ApiData<AccountAssetBreakdown>
    breakdownByNumber: { [key: string]: ApiData<AccountAssetBreakdown> }

    constructor () {
        this.aggregate = new ApiData()
        this.all = new ApiData()
        this.byNumber = {}
        this.buyingPower = new ApiData()
    }
}
