
const memos: Record<string, string> = {}

const memoJournal: string[] = []

export const GetMemo = (key: string) => memos[key]
export const SetMemo = (key: string, value: string, setter?: string) => {
    if (setter) memoJournal.push(`[${new Date().toISOString()}] ${setter} set ${key} to from ${memos[key]} to ${value}`)
    memos[key] = value
}
export const DeleteMemo = (key: string, setter?: string) => {
    if (setter) memoJournal.push(`[${new Date().toISOString()}] ${setter} deleted memo ${key}, whose value was ${memos[key]}`)
    delete memos[key]
}

export const DEBUGDumpMemosAndJournal = () => {
    console.log({ memos, memoJournal })
}

export const DEBUGGetMemosAndJournal = () => {
    return { memos, memoJournal }
}
