// @ts-strict-ignore
import { ApiData } from '../../models'
import { ReduxAction } from '../../models/ReduxAction'
import { GroupBySelectFirst, UniqueBy } from '../../util'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { SecurityLogoState } from '../models/SecurityLogo/SecurityLogoState'

const permitted = GroupNameChecker([Actions.Securities.GetLogo, Actions.Positions.GetAll, Actions.Securities.SetLogo])
export const SecurityLogoReducer = (state: SecurityLogoState = new SecurityLogoState(), action: ReduxAction): SecurityLogoState => {
    if (!permitted) return state

    const quoteData = (state.bySymbol[action.subject] || new ApiData(null))

    switch (action.type) {
        case Actions.Securities.GetLogo.Loading: return { ...state, bySymbol: { ...state.bySymbol, [action.subject]: quoteData.startLoading() } }
        case Actions.Securities.GetLogo.Success: {
            const requestedSymbols = UniqueBy(action.subject as string[], x => x)
            const responseLookup = GroupBySelectFirst(<{ symbol: string, logo: string }[]>action.data, x => x.symbol)
            if (action.data.url) return { ...state, bySymbol: { ...state.bySymbol, [action.subject]: quoteData.succeeded(action.data) } }
            const logoLookup = requestedSymbols.reduce((lookup, symbol) => ({
                ...lookup, [symbol]: new ApiData<{ url: string }>().succeeded({ url: responseLookup[symbol]?.logo || null })
            }), {})
            return { ...state, bySymbol: { ...state.bySymbol, ...logoLookup } }
        }
        case Actions.Securities.GetLogo.Failure: return { ...state, bySymbol: { ...state.bySymbol, [action.subject]: quoteData.succeeded({ url: null }) } }

            // We also get logos when the positions call comes back, fold those into the store
        case Actions.Positions.GetAll.Success: {
            // @ts-ignore, good job TS :) Maps symbols to logos
            const bySymbolLookup: { [key: string]: ApiData<{ url: string }> } = UniqueBy((<PositionsSummary[]>action.data).flatMap(s => s.positions)
                .filter(s => !!s.symbol && !!s.url), x => x.symbol) // added url check to keep from clearing out urls which have already been loaded if null
                .reduce((lookup, next) => ({ ...lookup, [next.symbol]: new ApiData().succeeded({ url: next.logoUrl }) }), {})
            return { ...state, bySymbol: { ...state.bySymbol, ...bySymbolLookup } }
        }

            // Note: we used to also do the same thing with watchlists, but it created race-condition issues. Now, we don't!

        case Actions.Securities.SetLogo.Loading: return { ...state, update: state.update.startLoading() }
        case Actions.Securities.SetLogo.Success: return {
            ...state,
            update: state.update.succeeded(),
            bySymbol: {
                ...state.bySymbol,
                [action.subject.symbol]: quoteData.succeeded(action.subject.logoUrl)
            }
        }
        case Actions.Securities.SetLogo.Failure: return { ...state, update: state.update.error(action.error) }

        default: return state
    }
}
