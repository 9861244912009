import { ReduxAction } from '../../models'
import { Actions } from '../actions'
import { SectorSecuritiesState, SectorSecuritiesSubstate } from '../models/Sectors/SectorSecuritiesState'
import { asData } from './ReducerUpdateHelpers'

export const SectorSecuritiesReducer = (state: SectorSecuritiesState = new SectorSecuritiesState(), action: ReduxAction): SectorSecuritiesState => {
    if (action.type === Actions.Sectors.GetEtfSecuritiesBySector.Success) {
        if (!state.byName[action.subject]) state.byName[action.subject] = new SectorSecuritiesSubstate()
        state.byName[action.subject].etfs = asData(action.data)
    }

    return state
}
