// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3lJpSJw3cFxU7IX6-it8Vx{cursor:pointer}._3lJpSJw3cFxU7IX6-it8Vx:hover{overflow:hidden;border-radius:10.5px;background-color:var(--cellHoverBackgroundColor)}._3HUi3IrX8b0cwuKsZXkfwr{border-bottom:1px solid var(--dividerColor);justify-content:space-between;align-items:center;box-sizing:border-box;width:100%;padding:0px 18px;min-height:40px;position:relative}._1JwYK24I32hjClAfTvanpz{display:flex;flex-direction:column;width:100%;box-sizing:border-box;padding:0}._1JwYK24I32hjClAfTvanpz button{background:inherit;border:none;color:inherit;cursor:pointer;display:flex;flex-wrap:nowrap;justify-content:space-between;min-height:40px;padding:8px 18px;text-align:left;width:100%}._1jqdXf5qayV65PghneXeWx{border-radius:5px;box-sizing:border-box;transition:200ms;height:100%}._20nRLbLAk795w4_i2OpEWF:hover{background:var(--cellHoverBackgroundColor)}._1-pEnkgvvM-YqdMGSTR0c6,h5._1-pEnkgvvM-YqdMGSTR0c6{font-size:20px;font-weight:800;margin:0}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094",
	"listRow": "_3lJpSJw3cFxU7IX6-it8Vx",
	"subListHeaderWrapper": "_3HUi3IrX8b0cwuKsZXkfwr",
	"wrapper": "_1JwYK24I32hjClAfTvanpz",
	"listName": "_1jqdXf5qayV65PghneXeWx",
	"hoverable": "_20nRLbLAk795w4_i2OpEWF",
	"headerTitle": "_1-pEnkgvvM-YqdMGSTR0c6"
};
module.exports = exports;
