// @ts-strict-ignore
import { format } from 'date-fns';
import { ItemUpdate } from 'lightstreamer-client-web';
import { LiveDataGroups } from '../../constants';
import { SnexLsItemUpdate } from '../../ls-shim/models/SnexLsItemUpdate';
import { OptionQuote, OptionSymbol } from '../../redux/models';
import { FuturesSymbol } from '../../redux/models/Futures/FuturesSymbol';
import { FuturesOptionChainUpdate } from '../../redux/models/Options/FuturesOptionChainUpdate';
import { XstreamReduxSubscribe } from './XstreamActionShared';
import { LSUpstreamSource, SafeFormat } from '../../util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { FuturesQuoteFields, XstreamQuoteSubscribeAction, createQuoteFromGainUpdate } from './XstreamQuoteActions';
import { XstreamState } from '../XstreamState';

export const XstreamOptionQuoteUpdateActionName = 'XSTREAM_OPTION_UPDATE';

// prettier-ignore
const RegularOptionFields = [
    'askSize', 'ask', 'bidSize', 'bid', 'percentChange', 'change', 'previousClose', 'openInterest',
    'volume', 'low', 'high', 'close', 'open', 'lastSize', 'last', 'strikePrice', 'inTheMoney',
    'outcome', 'type', 'exchange', 'askTime', 'askDate', 'bidTime', 'bidDate', 'previousCloseDate',
    'openInterestDate', 'time', 'date', 'currency', 'expirationDate', 'baseSymbol', 'symbologyType',
    'symbol', 'identity'
]

export const XstreamOptionSubscribeAction = (osiSymbols: string[], namespace: string) => {
    const sort = QualifiedId.Class(osiSymbols?.[0]); // Expect all symbols provided are of the same asset class
    const base = osiSymbols.length ? new OptionSymbol(osiSymbols[0]).underlyingSymbol : '';

    const { fields, groupName, items, updateFn, upstream } =
        sort === 'futures'
            ? {
                  fields: FuturesQuoteFields,
                  groupName: LiveDataGroups.Futures,
                  items: osiSymbols.map((symbol) => `price:${new FuturesSymbol(new OptionSymbol(symbol)?.toGain('_'))?.noPrefix}`), // Gain LS syntax requires underscore: OE4BK23_C4205
                  updateFn: (u: SnexLsItemUpdate, _, getState: () => XstreamState) => [
                      { ...createQuoteFromGainUpdate(u, osiSymbols?.[0], getState, 'options'), symbol: osiSymbols?.[0] }
                  ],
                  upstream: 'gain' as LSUpstreamSource
              }
            : {
                  fields: RegularOptionFields,
                  groupName: LiveDataGroups.Options,
                  items: osiSymbols.map((s) => `opt:${s}`),
                  updateFn: (update: ItemUpdate) => {
                      return [OptionQuote.FromLightsteamerUpdate(update)];
                  },
                  upstream: 'snex' as LSUpstreamSource
              };

    return XstreamReduxSubscribe(groupName, items, XstreamOptionQuoteUpdateActionName, fields, updateFn, {
        includeSnapshot: true,
        mode: 'MERGE',
        subject: base,
        namespace,
        upstream
    });
};

// prettier-ignore
const FuturesOptionFields = [
    'Call1', 'Call2', 'Call3', 'Call4', 'Call5', 'Call6', 'Call7', 'Call8', 'Call9', 'Call10',
    'Call11', 'Call12', 'Call13', 'Call14', 'Call15', 'Call16', 'Call17', 'Call18', 'Call19', 'Call20',
    'Put1', 'Put2', 'Put3', 'Put4', 'Put5', 'Put6', 'Put7', 'Put8', 'Put9', 'Put10',
    'Put11', 'Put12', 'Put13', 'Put14', 'Put15', 'Put16', 'Put17', 'Put18', 'Put19', 'Put20'
]

export const XstreamFutureOptionChainSubscribeAction = (baseSymbol: string, expirationDate: Date, namespace: string, numberOfStrikes?: number) => {
    const dateSymbol = format(expirationDate, 'yyyy-MM-dd');
    const item = `optionchain:${new FuturesSymbol(baseSymbol).noPrefix}:near:${numberOfStrikes ?? 10}:${dateSymbol}`;
    return XstreamReduxSubscribe(
        LiveDataGroups.Futures,
        [item],
        XstreamOptionQuoteUpdateActionName,
        FuturesOptionFields,
        (item: SnexLsItemUpdate) => {
            const getStoreKey = (strikePrice: number, upstreamSymbol: string) => {
                const putCall = upstreamSymbol.split(' ')[1].charAt(0);
                const dateComponent = SafeFormat(expirationDate, 'yyMMdd');
                return `F:${baseSymbol}${dateComponent}${putCall}${String(strikePrice * 100).padStart(7, '0')}0@F:${upstreamSymbol}`;
            };

            const list = [];
            item.forEachChangedField((fieldName: string, fieldPos: number, value: string) => {
                const update: FuturesOptionChainUpdate = JSON.parse(value);
                const storeKey = getStoreKey(update.Strike, update?.Symbol);

                const casted: OptionQuote = {
                    ask: update.Ask,
                    askValue: update.AskValue,
                    baseSymbol,
                    bid: update.Bid,
                    bidValue: update.BidValue,
                    change: update.Change,
                    changePercent: update.ChangePercent,
                    close: update.Close,
                    high: update.High,
                    last: update.LastPrice,
                    low: update.Low,
                    open: update.Open,
                    price: update.LastPrice,
                    symbol: storeKey
                };
                list.push(casted);
            });
            return list;
        },
        { mode: 'MERGE', includeSnapshot: false, namespace, upstream: 'gain' }
    );
};
