import { ApiData } from '../../../models'
import { FundOwner } from './FundOwner'
import { Insider } from './Insider'
import { InstitutionalOwner } from './InstitutionalOwner'
import { PriceTarget } from './PriceTarget'

export class SecurityAnalysisState {
    bySymbol: {
        [key: string]: {
            insiders: ApiData<Insider[]>,
            institutionalOwners: ApiData<InstitutionalOwner[]>,
            fundOwners: ApiData<FundOwner[]>,
            priceTarget: ApiData<PriceTarget>
        }
    }

    constructor () {
        this.bySymbol = {}
    }
}
