import React, { ReactElement } from 'react'
import { Typography } from '@mui/material';
import { ActionModal } from 'components';
import { useText } from 'phoenix/hooks/UseText';

export default ({ isOpen, setOpen }: { isOpen: boolean, setOpen: (x: boolean) => void
 }): ReactElement => {
    const text = useText(t => t.general)

    return (
        <ActionModal
            isOpen={ isOpen }
            label={ text.maintenanceCall}
            showScrollbar
            toggleModal={ () => setOpen(false) }
            width={ 400 }
        >
        <Typography variant='body1' style={{ fontSize: 14 }}>
            { text.maintenanceCallTooltip }
        </Typography>
        </ActionModal>
    )
}