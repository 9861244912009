// @ts-strict-ignore
import { ThrottleManager } from 'phoenix/util/ThrottleManager';
import uuid from 'uuid-random';

class TicketedSet {
    actors: Set<string>;

    constructor() {
        this.actors = new Set();
    }

    count() {
        return this.actors.size;
    }

    any() {
        return this.actors.size > 0;
    }

    enter() {
        const ticket = uuid();
        this.actors.add(ticket);
        return ticket;
    }

    leave(ticket: string) {
        this.actors.delete(ticket);
    }
}

class XstreamThrottlingTopic {
    timeoutMs: number; // Minimum number of milliseconds each streaming topic may emit from XStream store
    pausers: TicketedSet; // Any actors in the app that want this topic to temporarily stop streaming

    constructor(timeoutMs: number) {
        this.timeoutMs = timeoutMs;
        this.pausers = new TicketedSet();
    }
}

interface XstreamThrottlingState {
    quotes: XstreamThrottlingTopic;
    optionQuote: XstreamThrottlingTopic;
    accountValuations: XstreamThrottlingTopic;
    portfolioValuations: XstreamThrottlingTopic;
    domUpdates: XstreamThrottlingTopic;
}

export const XstreamThrottling = {
    timeouts: {
        quotes: new XstreamThrottlingTopic(1200),
        optionQuote: new XstreamThrottlingTopic(0),
        domUpdates: new XstreamThrottlingTopic(1000),
        accountValuations: new XstreamThrottlingTopic(5000),
        portfolioValuations: new XstreamThrottlingTopic(5000)
    } as XstreamThrottlingState,

    configure: (timeouts: Partial<Record<keyof XstreamThrottlingState, number>>) => {
        Object.entries(timeouts).forEach(([key, value]) => {
            (XstreamThrottling.timeouts[key] as XstreamThrottlingTopic).timeoutMs = value;
        });
    },

    pauseQuotes: (): Partial<Record<keyof XstreamThrottlingState, string>> => {
        return {
            quotes: XstreamThrottling.timeouts.quotes.pausers.enter(),
            optionQuote: XstreamThrottling.timeouts.optionQuote.pausers.enter(),
            domUpdates: XstreamThrottling.timeouts.domUpdates.pausers.enter()
        };
    },

    resumeQuotes: (tickets: Partial<Record<keyof XstreamThrottlingState, string>>) => {
        Object.entries(tickets).forEach(([key, value]) => {
            const t = XstreamThrottling.timeouts[key] as XstreamThrottlingTopic;
            t.pausers.leave(value);
        });
    },

    failsThrottle: (kind: keyof XstreamThrottlingState, subject: string) => {
        const t = XstreamThrottling.timeouts[kind];
        if (t.pausers.any()) return true; // Paused by at least one actor
        return !ThrottleManager.bounce(`xs:${kind}:${subject}`, t.timeoutMs);
    },

    _dump: () => {
        Object.entries(XstreamThrottling.timeouts).forEach(([key, value]: [string, XstreamThrottlingTopic]) => {
            console.log(`[XS Throttling] ${key} :: ${value.timeoutMs}ms throttle, ${value.pausers.any() ? `paused by ${value.pausers.count()} actors` : 'not paused'}`);
        });
    }
};
