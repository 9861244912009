// @ts-strict-ignore
import { ApiData } from '../../../models'
import { GroupBySelectFirst, GroupBySelectMap, RemapObjectValues } from '../../../util'
import { GlobalState } from '../../GlobalState'
import { AccountSummary, OptionQuote, OptionSymbol, SecurityChartData, SecurityLogo, SecurityLogoState, SecurityQuote } from '../../models'
import { AggregateBuyingPower } from '../../models/AccountSummary/AccountBuyingPower'
import { AccountValuation, AggregateAccountValuationResponse } from '../../models/AccountSummary/AccountValuation'
import { ChartQuoteBatch } from '../../models/Securities/ChartQuoteBatch'
import { SecurityMetadata } from '../../models/Securities/SecurityMetadata'
import { asData, orNew } from '../ReducerUpdateHelpers'
import { EffectiveSymbolForLogo } from 'phoenix/util/EffectiveSymbolForLogo'

export class MobileStateUpdates {
    static addLogos = (state: GlobalState, logos: { symbol: string, logo: string }[]) => {
        const logosWithBase = logos.reduce((final, current) => {
            const effectiveSymbol = EffectiveSymbolForLogo(current?.symbol);

            if (effectiveSymbol !== current?.symbol)
                final.push({symbol: effectiveSymbol, logo: current.logo})

            final.push(current)
            return final
        }, [])
        
        state.logos.bySymbol = {
            ...state.logos.bySymbol,
            ...(GroupBySelectMap(logosWithBase, x => x.symbol, x => orNew<SecurityLogo>(state.logos.bySymbol[x[0].symbol]).succeeded({ url: x[0].logo })))
        }
        return state
    }

    static addBuyingPower = (state: GlobalState, buyingPower: AggregateBuyingPower) => {
        const updatedBuyingPower: AggregateBuyingPower = buyingPower ? { ...buyingPower, optionsBuyingPower: buyingPower?.cashAndEquivalents } : undefined
        state.accountSummary.buyingPower = orNew(state.accountSummary.buyingPower).succeeded(updatedBuyingPower)
        return state
    }

    static addQuotesAndCharts = (state: GlobalState, quotesAndChartsRaw: ChartQuoteBatch[]) => {
        const buildChartStateUpdate = (quoteAndChartBatch: ChartQuoteBatch) => {
            // If no charts to update, then return empty obj.
            if (!quoteAndChartBatch?.charts) return {}

            const { charts } = quoteAndChartBatch
            const chartUpdate = { charts }
            if (charts['1d']) chartUpdate.charts.loRes = charts['1d']
            else if (charts['24h']) chartUpdate.charts.loRes = charts['24h']
            return chartUpdate
        }

        const quotesAndCharts = quotesAndChartsRaw.some(b => b?.charts)
            ? quotesAndChartsRaw.map(b => ({ ...b, ...buildChartStateUpdate(b) })) : quotesAndChartsRaw

        // Make sure we don't override what's in the store with a null quote
        const nonNullQuotes = quotesAndCharts.filter(b => b.quote) || []
        state.securityQuote.bySymbol = {
            ...state.securityQuote.bySymbol,
            ...(GroupBySelectMap(nonNullQuotes, b => b.symbol, b => orNew<SecurityQuote>(state.securityQuote.bySymbol[b[0].symbol]).succeeded(b[0].quote)))
        }

        if (quotesAndCharts?.some(qc => qc.charts)) {
            const newCharts = GroupBySelectMap(
                quotesAndCharts,
                b => b.symbol,
                batch => ({ ...state.securityChart.bySymbol[batch[0].symbol], ...RemapObjectValues(batch[0].charts, c => new ApiData<SecurityChartData[]>().succeeded(SecurityChartData.ManyFromApiData(c))) })
            )

            state.securityChart.bySymbol = {
                ...state.securityChart.bySymbol,
                ...newCharts
            }
        }

        // TODO -- Eventually, we'd love to have option- and non-option-quotes all in the same place. But for now we also have to take all the options we get back and
        // duplicate them into their own part of the state
        const options = nonNullQuotes.filter(x => OptionSymbol.IsOptionSymbol(x.symbol))
        if (!options?.length) return state

        state.options.quotesByOsi = {
            ...state.options.quotesByOsi,
            ...(GroupBySelectMap(options, b => b.symbol, b => orNew<OptionQuote>(state.options.quotesByOsi[b[0].symbol]).succeeded(OptionQuote.FromSecurityQuote(b[0].quote))))
        }

        return state
    }

    static addMetadata = (state: GlobalState, metadata: SecurityMetadata[]) => {
        const addIn = (key: string, value: SecurityMetadata) => {
            if (key) state.securities.bySymbol[key] = { ...state.securities.bySymbol[key], metadata: asData(value) }
        }
        if (metadata?.length) {
            for (const m of metadata) {
                if (m?.futureBaseSymbol) addIn(m?.futureBaseSymbol, m)
                addIn(m.symbol, m)
                addIn(`I:${m.isin}`, m)
                addIn(`C:${m.cusip}`, m)
                addIn(`B:${m.securityNumber}`, m)
            }
        }

        return state
    }

    static addValuations = (state: GlobalState, valuationResponse: AggregateAccountValuationResponse) => {
        const asTorchValuationModel = (val: AccountValuation): AccountSummary => {
            return {
                accountNumber: val.accountNumber,
                totalAccountValueCurrent: val.value,
                totalCashCurrent: val.cashAndEquivalents,
                totalLongMarketCurrent: val.longMarketValue,
                totalShortMarketCurrent: val.shortMarketValue,
                todaysGainLoss: val.todaysGainLoss,
                industries: [],
                positionSummary: []
            }
        }


        const list = orNew(state.accountSummary.all)
        const listLookup = GroupBySelectFirst(list.data, a => a.accountNumber)

        if (!valuationResponse?.accounts) {
            state.accountSummary.all.succeeded([])
            return state;
        }

        const newList: AccountSummary[] = []
        for (const a of valuationResponse?.accounts) {
            const current = listLookup[a.accountNumber] || {}
            const model = { ...current, ...asTorchValuationModel(a) };
            newList.push(model);
            const byNumberData = {...state.accountSummary?.byNumber?.[a.accountNumber]?.data || {}, ...model}
            state.accountSummary.byNumber[a.accountNumber] = asData(byNumberData);
        }

        state.accountSummary.all.succeeded(newList)

        return state;

    }
}
