// @ts-strict-ignore
import { create } from "zustand";
import { BaseOnboardingStoreData } from "../Base/BaseOnboardingStore";
import _ from "lodash";
import { FullyPaidLendingHelpers } from "phoenix/util/FullyPaidLendingHelpers";

interface FullyPaidLendingData extends BaseOnboardingStoreData{
    selectedScreenId: string,
    verifying?: boolean;
    loading?: boolean;
    welcomed?: boolean;
    isComplete: boolean;
    data: {
        selectedAccount?: string,
        axosAcknowledgement: {
            accepted: boolean,
            viewed: boolean
        }
        acceptedLoanAgreement: {
            accepted: boolean,
            viewed: boolean
        },
        acceptedAccountControlAgreement: {
            accepted: boolean,
            viewed: boolean
        },
        charAndRisks: {
            accepted: boolean,
            viewed: boolean
        },
    },
    getValue: (key: string) => any,
    setValue: (key: string, value?: any) => void
    submit: () => void
}

export const useFullyPaidLendingStore = create<FullyPaidLendingData>()((set, get) => ({ 
    selectedScreenId: 'welcome',
    isComplete: false,
    data: {
        selectedAccount: null,
        axosAcknowledgement: {
            accepted: false,
            viewed: true
        },
        acceptedLoanAgreement: {
            accepted: false,
            viewed: false
        },
        acceptedAccountControlAgreement: {
            accepted: false,
            viewed: false
        },
        charAndRisks: {
            accepted: false,
            viewed: false
        },
    },
    loading: false,
    verifying: false,
    welcomed: false,
    getValue: (key: string) => _.get(get(), key),
    setValue: (key: string, value?: any) => 
        set((s) => {
            return {..._.set(s, key, value)}
        }),
    submit: async () => {
        const snexAccountNumber = get().data?.selectedAccount
        await FullyPaidLendingHelpers.SubmitFullyPaidLendingRequest(snexAccountNumber);
        setValue('isComplete', true)
    }
}))

export const setValue = (key: string, value: any) : void => {
    const setValue = useFullyPaidLendingStore.getState().setValue
    setValue(key, value)
}
export const setSelectedScreenId = (id: string): void => {
    const setValue = useFullyPaidLendingStore.getState().setValue
    setValue('selectedScreenId', id)
}