import { create } from 'zustand'

/** @deprecated Please use useAsyncOperationIndicatorStore */
export const useOperationSubmitting = (): [ number ] => {
    const s = useAsyncOperationIndicatorStore()
    return [ s.nSubmitting ]
}

interface AsyncOperationIndicatorStore {
    nSubmitting: number
    submitting: boolean
    up: () => void
    down: () => void
}

export const useAsyncOperationIndicatorStore = create<AsyncOperationIndicatorStore>((set, get) => ({

    nSubmitting: 0,
    submitting: false,

    up: () => {
        set({ nSubmitting: get().nSubmitting + 1, submitting: true })
    },

    down: () => {
        const newN = Math.max(0, get().nSubmitting - 1)
        set({ nSubmitting: newN, submitting: newN > 0 })
    },

}))

export const UpOperationSubmitting = () => useAsyncOperationIndicatorStore.getState().up()
export const DownOperationSubmitting = () => useAsyncOperationIndicatorStore.getState().down()
