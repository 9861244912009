// @ts-strict-ignore
import { DEBUG_JWT_KEY, DEBUG_JWT_NAME, GetConfig, ResolveEnvironment, Urls } from 'phoenix/constants';
import { Routes } from './Routes';

export const GoToLogin = (): void => {
    if (['local','development'].includes(ResolveEnvironment())) {
        window.location.href = Routes.localLogin(); /* local login is handled in the authorization logic */
        return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectParam = 'redirectUrl';

    /* if the current location already includes the redirectUrl param then we have already attempted to login
       and we should redirect to the dead login as there is an issue with the user's login */
    if (urlParams.has(redirectParam)) window.location.href = Routes.deadLogin();
    window.location.href = Urls.authentication.loginPage(window.location.href.toLowerCase() === Routes.loggedOut().toLowerCase()? Routes.portfolio() : window.location.href);
};

export const GoToLogout = async (): Promise<void> => {
    const config = GetConfig();
    console.log('Logging the user out, the auth scheme is', config.AuthenticationScheme);
    if (config.AuthenticationScheme === 'cookies') {
        window.location.href = Urls.authentication.logout();
    } else {
        localStorage.removeItem(DEBUG_JWT_KEY);
        localStorage.removeItem(DEBUG_JWT_NAME);
        console.log({JWT_KEY: localStorage.getItem(DEBUG_JWT_KEY), JWT_NAME: localStorage.getItem(DEBUG_JWT_NAME),})
        if(window.location.href.toLowerCase() !== Routes.loggedOut().toLowerCase()) window.location.href = Routes.loggedOut()
    }
};

