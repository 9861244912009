// @ts-strict-ignore
export const getScaledImage = (defaultWidth: number, defaultHeight: number, newWidth?: number, newHeight?: number): {width: number, height: number} => {
    const ratio = defaultWidth / defaultHeight
    let _w = defaultWidth
    let _h = defaultHeight

    if(newWidth) {
        _w = newWidth
        _h = newWidth / ratio;
    } else {
        _h = newHeight
        _w = newHeight * ratio
    }
    
    return {width: Math.round(_w), height: Math.round(_h)}
}