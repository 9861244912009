import { ApiData, ApiOperation } from '../../../models'
import { MyInfo } from './MyInfo'
import { MyPrefs } from './MyPrefs'
import { MyTaxInfo } from './MyTaxInfo'
import { MyToken } from './MyToken'
import { PublicUserInfo } from './PublicUserInfo'
import { UserFeature } from './UserFeature'
import { WebTosAcceptanceResponse } from './WebTosAcceptanceResponse'

export class UserState {
    myInfo: ApiData<MyInfo | null>
    myPrefs: ApiData<MyPrefs | null>
    myTaxInfo: ApiData<MyTaxInfo | null>
    myToken: ApiData<MyToken | null>
    myFeatures: ApiData<UserFeature[]>
    webTosAcceptance: ApiData<WebTosAcceptanceResponse>

    byId: {
        [key: string]: {
            info: ApiData<PublicUserInfo>
        }
    }

    updatePrefs: ApiOperation
    updateTaxInfo: ApiOperation
    updateProgress: ApiOperation
    updateReviewStatus: ApiOperation

    updatePhone: ApiOperation
    verifyPhone: ApiOperation

    constructor () {
        this.myInfo = new ApiData()
        this.myPrefs = new ApiData()
        this.myTaxInfo = new ApiData()
        this.myToken = new ApiData()
        this.myFeatures = new ApiData([])
        this.webTosAcceptance = new ApiData()
        this.byId = {}
        this.updatePrefs = new ApiOperation()
        this.updateTaxInfo = new ApiOperation()
        this.updateProgress = new ApiOperation()
        this.updateReviewStatus = new ApiOperation()
        this.updatePhone = new ApiOperation()
        this.verifyPhone = new ApiOperation()
    }
}
