// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import { ResolveFrontend } from "phoenix/constants"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"


export const registration_userExists_nextButton: OnboardingButton = {
    label: "Login",
    onClick: () => {
        const redirectUrl = ResolveFrontend()
        window.location.href = redirectUrl
    },
    isPrimary: true,
    isEnabled: () => true
}

export const registration_userExists_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useRegistrationStore.getState().setValue('selectedScreenId', 'userInfo'),
    isPrimary: false,
    isEnabled: () => true
}

export const registration_userExists_screen: OnboardingScreen = {
    id: "userExists",
    validate: NoValidation,
    buttons: [ registration_userExists_prevButton, registration_userExists_nextButton],
    title: T(t => t.webRegistration.alreadyExists),
    subtitles: [T(t => t.webRegistration.wouldYouLikeToLogin)],
    body: null
}