import { EquityDeepAnalysisScreenReducer } from 'phoenix/redux/reducers/MobileScreens/EquityDeepAnalysisScreenReducer'
import { ReduxAction } from '../../../models'
import { Actions } from '../../actions'
import { GlobalState } from '../../GlobalState'
import { MobileScreenLoadingState } from '../../models/MobileScreens/MobileScreenLoadingState'
import { AppStartupReducer } from './AppStartupReducer'
import { BrowseScreenReducer } from './BrowseScreenReducer'
import { LandingScreenReducer } from './LandingScreenReducer'
import { MobileWatchlistsReducer } from './MobileWatchlistsReducer'
import { ProfileScreenReducer } from './ProfileScreenReducer'
import { SectorScreenReducer } from './SectorScreenReducer'
import { SecurityScreenReducer } from './SecurityScreenReducer'
import { SummaryScreenReducer } from './SummaryScreenReducer'

export const MobileScreensReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const withScreenLoading = (screenName: keyof MobileScreenLoadingState) => ({
        ...state, mobileScreenLoading: { ...state.mobileScreenLoading, [screenName]: state.mobileScreenLoading[screenName].startLoading() }
    })

    const withScreenFailed = (screenName: keyof MobileScreenLoadingState, error: any) => ({
        ...state, mobileScreenLoading: { ...state.mobileScreenLoading, [screenName]: state.mobileScreenLoading[screenName].failed(error) }
    })

    if (action.type === Actions.MobileScreens.LoadLandingScreen.Loading) return withScreenLoading('landingScreen')
    if (action.type === Actions.MobileScreens.LoadLandingScreen.Failure) return withScreenFailed('landingScreen', action.error)
    if (action.type === Actions.MobileScreens.LoadLandingScreen.Success) return LandingScreenReducer(state, action)

    // Startup
    if (action.type === Actions.MobileScreens.LoadAppStartup.Loading) return withScreenLoading('appStartup')
    if (action.type === Actions.MobileScreens.LoadAppStartup.Failure) return withScreenFailed('appStartup', action.error)
    if (action.type === Actions.MobileScreens.LoadAppStartup.Success) return AppStartupReducer(state, action)
    if (action.type === Actions.MobileScreens.LoadAppStartupPartTwo.Success) return AppStartupReducer(state, action)

    // Summary Screen
    if (action.type === Actions.MobileScreens.LoadSummaryScreen.Loading) return withScreenLoading('summaryScreen')
    if (action.type === Actions.MobileScreens.LoadSummaryScreen.Failure) return withScreenFailed('summaryScreen', action.error)
    if (action.type === Actions.MobileScreens.LoadSummaryScreen.Success) return SummaryScreenReducer(state, action)
    if (action.type === Actions.MobileScreens.LoadSummaryScreenPartTwo.Success) return SummaryScreenReducer(state, action)

    // Watchlists
    if (action.type === Actions.MobileScreens.Watchlists.LoadIndex.Loading) return withScreenLoading('watchlists')
    if (action.type === Actions.MobileScreens.Watchlists.LoadIndex.Failure) return withScreenFailed('watchlists', action.error)
    if (action.type === Actions.MobileScreens.Watchlists.LoadIndex.Success) return MobileWatchlistsReducer(state, action)
    if (action.type === Actions.MobileScreens.Watchlists.LoadDetails.Loading) return withScreenLoading('watchlistDetails')
    if (action.type === Actions.MobileScreens.Watchlists.LoadDetails.Failure) return withScreenFailed('watchlistDetails', action.error)
    if (action.type === Actions.MobileScreens.Watchlists.LoadDetails.Success) return MobileWatchlistsReducer(state, action)

    // Security Screen
    if (action.type === Actions.MobileScreens.LoadSecurityScreen.Loading) return withScreenLoading('securityScreen')
    if (action.type === Actions.MobileScreens.LoadSecurityScreen.Failure) return withScreenFailed('securityScreen', action.error)
    if (action.type === Actions.MobileScreens.LoadSecurityScreen.Success) return SecurityScreenReducer(state, action)
    if (action.type === Actions.MobileScreens.LoadSecurityScreenPartTwo.Success) return SecurityScreenReducer(state, action)
    if (action.type === Actions.MobileScreens.LoadSecurityScreenForFuture.Success) return SecurityScreenReducer(state, action)

    // Equity Deep Analysis
    if (action.type === Actions.MobileScreens.LoadEquityDeepAnalysisScreen.Loading) return withScreenLoading('equityDeepAnalysis')
    if (action.type === Actions.MobileScreens.LoadEquityDeepAnalysisScreen.Failure) return withScreenFailed('equityDeepAnalysis', action.error)
    if (action.type === Actions.MobileScreens.LoadEquityDeepAnalysisScreen.Success) return EquityDeepAnalysisScreenReducer(state, action)

    // Browse Screen
    if (action.type === Actions.MobileScreens.LoadBrowseScreen.Loading) return withScreenLoading('browseScreen')
    if (action.type === Actions.MobileScreens.LoadBrowseScreen.Failure) return withScreenFailed('browseScreen', action.error)
    if (action.type === Actions.MobileScreens.LoadBrowseScreen.Success) return BrowseScreenReducer(state, action)

    // Profile Screen
    if (action.type === Actions.MobileScreens.LoadProfileScreen.Loading) return withScreenLoading('profileScreen')
    if (action.type === Actions.MobileScreens.LoadProfileScreen.Failure) return withScreenFailed('profileScreen', action.error)
    if (action.type === Actions.MobileScreens.LoadProfileScreen.Success) return ProfileScreenReducer(state, action)

    if (action.type === Actions.MobileScreens.LoadSectorScreen.Loading) return withScreenLoading('sectorScreen')
    if (action.type === Actions.MobileScreens.LoadSectorScreen.Failure) return withScreenFailed('sectorScreen', action.error)
    if (action.type === Actions.MobileScreens.LoadSectorScreen.Success) return SectorScreenReducer(state, action)

    return state
}
