import { IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { Flex } from '..';
import { DebugDumpManager } from 'phoenix/util/DebugDumpManager';
import { CloudDownload } from '@mui/icons-material';
import { WithScrollbar } from '../WithScrollbar/WithScrollbar';
import { GetDisableGainStreaming, GetDisableSnexStreaming, Lightstreamer } from '../../phoenix/util';
import { useColors } from 'hooks/UseColors';
import { CrossPlatformRelays } from 'phoenix/constants/CrossPlatformRelays';
import { useRelay } from 'phoenix/hooks/UseRelay';
import { GetMemo } from 'util/Utils';

function StreamingDebuggerComponent() {
    const events = DebugDumpManager.GetDebugEvents();
    const colors = useColors();

    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Streaming_Debug',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    };

    const handleDownload = () => {
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(events);
    };

    const [snexRelay] = useRelay(CrossPlatformRelays.SnexLsState);
    const [gainRelay] = useRelay(CrossPlatformRelays.GainLsState);
    const snexState = GetDisableSnexStreaming() ? '<Disabled per Debug Preferences>' : snexRelay;
    const gainState = GetDisableGainStreaming() ? '<Disabled per Debug Preferences>' : gainRelay;

    return (
        <Flex
            column
            style={{
                height: '80vh',
                background: colors.cardBackgroundColor,
                width: '80vw',
                overflow: 'hidden',
                padding: 20,
                boxSizing: 'border-box',
                marginTop: 20,
                borderRadius: 20
            }}
        >
            <Flex column style={{ width: '100%', background: colors.cardBackgroundColor, paddingBottom: 10 }}>
                {/* Header */}
                <Flex row align='center' justify='space-between' style={{ width: '100%' }}>
                    <Typography variant='h3'>StreamingDebugger:</Typography>
                    <Flex row align='center' justify='center' style={{ minWidth: 30 }}>
                        <Flex column style={{ marginRight: 20, textAlign: 'right' }}>
                            <Typography>SNEX LS Status: {snexState}</Typography>
                            <Typography>Gain LS Status: {gainState}</Typography>
                        </Flex>
                        <Flex column>
                            <Typography>UI Session ID: {GetMemo('SESSION_ID')}</Typography>
                            <Typography>SNEX LS Session ID: {Lightstreamer.GetCurrentSessionId('snex')}</Typography>
                            <Typography>Gain LS Session ID: {Lightstreamer.GetCurrentSessionId('gain')}</Typography>
                        </Flex>
                        <Tooltip placement='left' title='Download Subscription Events'>
                            <IconButton onClick={handleDownload} size='large'>
                                <CloudDownload />
                            </IconButton>
                        </Tooltip>
                    </Flex>
                </Flex>

                {/* Header Row */}
                <Flex row style={{ width: '100%', marginTop: 20 }}>
                    <span style={{ width: '15%', textAlign: 'left', fontSize: 20, fontWeight: 600 }}>TimeStamp</span>
                    <span style={{ width: '15%', textAlign: 'left', fontSize: 20, fontWeight: 600 }}>Item</span>
                    <span style={{ width: '15%', textAlign: 'left', fontSize: 20, fontWeight: 600 }}>Event</span>
                    <span style={{ width: '55%', textAlign: 'left', fontSize: 20, fontWeight: 600 }}>Other</span>
                </Flex>
            </Flex>

            <WithScrollbar>
                {events.map((e, key) => (
                    <Flex key={key} style={{ width: '100%', padding: '10px 0px' }}>
                        <span style={{ display: 'block', textAlign: 'left', overflow: 'hidden', width: '15%', whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                            {e.timestamp}
                        </span>
                        <span
                            style={{
                                display: 'block',
                                textAlign: 'left',
                                overflow: 'hidden',
                                width: '15%',
                                whiteSpace: 'break-spaces',
                                wordWrap: 'break-word',
                                maxHeight: 100,
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {e.item}
                        </span>
                        <span style={{ display: 'block', textAlign: 'left', overflow: 'hidden', width: '15%', whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                            {e.event}
                        </span>
                        <span style={{ display: 'block', textAlign: 'left', overflow: 'hidden', width: '55%', whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                            {e.other}
                        </span>
                    </Flex>
                ))}
            </WithScrollbar>
        </Flex>
    );
}

export const StreamingDebugger = React.memo(StreamingDebuggerComponent);
