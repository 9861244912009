// @ts-strict-ignore
import React from 'react'
import { Flex } from '..'
import { SelectList, SelectListItem } from '../SelectList'
import { FilterableSecuritiesFilter } from './FilterableSecurityProps'
import { ChangeFilters } from './FilterableSecurityTable'


export interface ChangeSelectorProps {
    filters: FilterableSecuritiesFilter, 
    onFilterUpdate: (filters: Partial<FilterableSecuritiesFilter>) => any,
    text: any
}

export const FilterableSecurityChangeSelector = React.memo((props: ChangeSelectorProps) => {

    const changeSelectorFilterItems = [
        {label:props.text.rising, value:ChangeFilters.Rising},  
        {label:props.text.falling, value:ChangeFilters.Falling}, 
    ]

    const handleChange = (items: SelectListItem[]) => {
        
        props.onFilterUpdate({ ...props.filters, change: items })
    }

    return (
        <Flex column align={'flex-start'} wrap >
            <SelectList vertical variant='small' items={changeSelectorFilterItems} onChange={handleChange} selectedItems={props.filters.change && props.filters.change.map(f=> f.value)}/>
        </Flex>
    )
})