import { ApiData } from '../../../models'
import { BalanceSheetResponse } from './BalanceSheet'
import { CashFlowReportResponse } from './CashFlowReport'
import { IncomeStatementResponse } from './IncomeStatement'

export class FinancialStatementsState {
    bySymbol: {
        [key: string]: {
            balanceSheet: { annual: ApiData<BalanceSheetResponse>, quarterly: ApiData<BalanceSheetResponse> },
            cashFlow: { annual: ApiData<CashFlowReportResponse>, quarterly: ApiData<CashFlowReportResponse> },
            income: { annual: ApiData<IncomeStatementResponse>, quarterly: ApiData<IncomeStatementResponse> }
        }
    }

    constructor () {
        this.bySymbol = {}
    }
}
