import { Flex } from 'components/Flex';
import React, { PropsWithChildren, ReactElement, ReactNode, useRef } from 'react';

export const SlidingComponent = ({
    children,
    minHeight,
    gap,
    slideIndex,
    transitionInSeconds
}: PropsWithChildren<{
    minHeight: number;
    gap?: number;
    slideIndex: number;
    transitionInSeconds?: number;
}>): ReactElement => {
    const slideRef = useRef<HTMLDivElement>(null);
    const slideWidth = slideRef?.current?.clientWidth || 0;
    const _gap = (gap || 16) * 2;
    const _transitionInSeconds = transitionInSeconds || 0.25;
    return (
        <Flex ref={slideRef} column style={{ width: '100%', minHeight, position: 'relative', overflowX: 'clip' }}>
            {slideRef?.current && (
                <Flex
                    row
                    style={{
                        minHeight,
                        width: slideWidth * (children as ReactNode[])?.length + _gap,
                        gap: _gap,
                        left: slideIndex !== 0 ? `-${(slideWidth + _gap) * slideIndex}px` : 0,
                        top: 0,
                        position: 'absolute',
                        transition: `all ${_transitionInSeconds}s ease`
                    }}
                >
                    {((children as ReactNode[]) || []).map((c, cindex) => (
                        <div key={`slidingComponent-child-${cindex}`} style={{ width: slideWidth, minHeight }}>
                            {c}
                        </div>
                    ))}
                </Flex>
            )}
        </Flex>
    );
};
