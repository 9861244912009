// @ts-strict-ignore
import { SnexAccountOption } from 'phoenix/hooks/useAccounts';
import { T } from 'phoenix/assets/lang/T';
import { ConvertErrorCodeToErrorText, GetFundingErrorTextFromErrorCode } from 'phoenix/constants/FundingErrorsV2';
import { ApiTransferRequest } from 'phoenix/models/Funding/ApiTransferRequest'
import { TransferResult } from 'phoenix/models/Funding/TransferResult';
import { SnexError } from 'phoenix/models/SnexError';
import { useFundingLimitsStore } from 'phoenix/stores/FundingLimitsStore';
import { FundingHelpers } from 'phoenix/util/Funding/v2/FundingHelpers';
import { create } from 'zustand'
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { Urls } from 'phoenix/constants';
import React from 'react';


export type FundingStatus = 'Pending' | 'Completed' | 'Failed' | 'Working'
export type SnexFundingJobRequest =
{
    id?: string,
    runImmediately?: boolean,
    jobStatus?: FundingStatus,
    jobErrorCode?: string,
    errorMessage?: string,
    lastRun?: Date,
    attempts?: number,
    maxRetry?: number,
    bankName?: string,
    bankAccountNumber?: string,
    processOnOrAfter?: Date,
    FundingSourceId?: string,
    accountNumber?: string,
    amount?: number,
    direction?: string,
    details?: JSX.Element | string
}

export type FundingTicketStoreData =
{
    transfer?: Partial<ApiTransferRequest>;
    ticketState: 'input' | 'review' | 'receipt'; 
    formattedAmount?: string;
    selectedSource?: string;
    selectedAccount?: SnexAccountOption
    validationResult?: TransferResult;
    isSubmitting?: boolean;
    transferErrors?: SnexError[],
} 

export type FundingTicketStoreMethods = 
{
    setTransferAccount: (accountNumber: string) => void
    setTicketState: (ticketState: 'input' | 'review' | 'receipt') => void
    setFormattedAmount: (formattedAmount: string) => void
    setTransferValue: (key: string, value: any) => void
    selectSource: (fundingSourceId: string) => void
    setSelectedAccount?: (option: SnexAccountOption) => void
    canSubmit: () => boolean
    getTransferErrors: () => void
    setValidationResult: (result: TransferResult) => void
    clearValidationResult: () => void
    setSubmitting: (isSubmitting: boolean) => void
    initialize: () => void
}

export type FundingTicketStore = FundingTicketStoreData & FundingTicketStoreMethods

export const useFundingTicketStore = create<FundingTicketStore>((set, get) => {
   
    return {
        transfer: {},
        transferErrors: [],
        ticketState: 'input',
        initialize: () => {
            set((s) => ({ ticketState: 'input', transfer: { direction: 'deposit' }, formattedAmount: '' }))
            get().getTransferErrors()
        },
        setTransferAccount: (accountNumber: string) => set((s) => ({...s, transfer:{...s.transfer, accountNumber}})),
        setTicketState: (ticketState: 'input' | 'review' | 'receipt') => set((s) => ({ ...s, ticketState })),
        setFormattedAmount: (formattedAmount: string) => set((s) => ({ ...s, formattedAmount })),
        setTransferValue: (key: string, value: any) => {
            set((s) => ({ ...s, transfer: { ...s.transfer, [key]: value } }));
        },
        selectSource: (fundingSourceId: string) => set((s) => ({ ...s, selectedSource: fundingSourceId })),
        setSelectedAccount: (option: SnexAccountOption) => set((s) => ({ ...s, selectedAccount: option })),
        canSubmit: () => get().transferErrors?.length < 1,
        getTransferErrors: () => {
            const errors: SnexError[] = [];
            const transfer = get().transfer;
            const validationResult = get().validationResult;
            const minDeposit = FundingHelpers.GetMinimumAllowedDeposit(transfer?.accountNumber)
            if (!transfer?.fundingSourceId) errors.push({errorCode: 'FNDX000', errorMessage: 'Please select a funding source.'});
            if (!transfer?.accountNumber) errors.push({errorCode: 'FNDX000', errorMessage: 'Please select a StoneX One account.'});
            if (transfer?.direction === 'deposit' && transfer?.accountNumber && !(transfer?.amount >= minDeposit ?? 1))
                errors.push({errorCode: 'FNDX000', errorMessage: T(t=> t.bankingScreen.minimumDepositNotMet.title(minDeposit ?? 1))})
            if (transfer?.direction === 'withdrawal' && !(transfer?.amount > 0))
                errors.push({errorCode: 'FNDX000', errorMessage: 'Withdrawal amount must be greater than 0'})
            if (validationResult?.success === false) validationResult?.error ? errors.push(validationResult?.error) : 
                errors.push({errorCode: 'FNDX000', errorMessage: 'Failed validation result'});
    
            set((s) => ({...s, transferErrors: errors}))
        },
        setValidationResult: (result: TransferResult) => {
            set((s) => ({ ...s, validationResult: result }));
        },
        clearValidationResult: () => set((s) => ({ ...s, validationResult: null })),
        setSubmitting: (isSubmitting: boolean) => set((s) => ({ ...s, isSubmitting })),
    }
})