// @ts-strict-ignore
const _debounces: { [key: string]: {
    res: (result: any) => any,
    rej: (error: any) => any,
    promise: Promise<any[]>,
    parameters: any[],
    lastId: number,
    timeout: any
} } = {}

let i = 0
const GetUniqueTicketNumber = (_: string) => ++i

export type DebounceContinuationDirective = 'continue' | 'halt';

export const HitDebounce = async (key: string, parameter?: any, continuation?: (parameters: any[]) => any, timeoutMs?: number): Promise<DebounceContinuationDirective> => {
    // The final bounce is the one that goes through and gets the 'continue' directive

    const myId = GetUniqueTicketNumber(key)
    let result = null
    const ms = timeoutMs || 50

    // On first bounce
    if (!_debounces[key]) {
        let res = null
        let rej = null
        const p = new Promise<any[]>((resolve, reject) => { res = resolve; rej = reject })
        _debounces[key] = {
            res,
            rej,
            promise: p,
            parameters: (parameter === undefined ? [] : [parameter]),
            timeout: setTimeout(() => { res([parameter]) }, ms),
            lastId: myId
        }
        result = await p

    // On subsequent bounces
    } else {
    // @ts-ignore
        clearTimeout(_debounces[key].timeout)
        _debounces[key].timeout = setTimeout(() => {
            // React Native: sometimes by the time we finally bounce, the debounce key no longer exists.
            // for now, we just shrug out shoulders when that happens.
            if (_debounces[key]) _debounces[key].res(_debounces[key].parameters)
        }, ms)
        if (parameter !== undefined) _debounces[key].parameters.push(parameter)
        _debounces[key].lastId = myId
        result = await _debounces[key].promise
    }

    // Let the final bounce go through, since that will have the most recent request
    if (_debounces[key].lastId === myId) {
        delete _debounces[key]
        if (continuation) continuation(result)
        return 'continue'
    } else return 'halt'
}
