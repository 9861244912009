import { Login } from '@mui/icons-material';
import { Flex } from 'components/Flex';
import { SnexButton } from 'components/SnexButton';
import { T } from 'phoenix/assets/lang/T';
import React from 'react';
import { GoToLogin } from 'util/GoToLogin';
import { useColors } from 'hooks/UseColors';

export const LoginButton = React.memo(() => {
    const colors = useColors();

    return (
        <SnexButton onClick={() => GoToLogin()} flavor='submit'>
            <Flex row align='center' justify='center'>
                <Login />
                <span style={{ minWidth: 250, textAlign: 'center' }}>{T((t) => t.webRegistration.login)}</span>
            </Flex>
        </SnexButton>
    );
});
