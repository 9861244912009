import { Account } from "phoenix/redux/models/Accounts"

export type MyAccountApplicationStatus = 'InProgress' | 'VerificationFailed' | 'Submitted' | 'DocumentsRequired' | 'Rejected' | 'Completed'

export const PendingApplicationStatuses = new Set<MyAccountApplicationStatus>(['InProgress', 'Submitted', 'DocumentsRequired'])

export interface AccountOpening extends Account {
    accountNumber: string
    applicationId?: string
    openType?: number
    marginRequested?: boolean
    optionsRequested?: boolean
    accountApplicationStatus?: MyAccountApplicationStatus
    onboardingStatus?: number
}
