export const AccountCardPlaceholderData = [
    { time: 1626437042, value: 0 },
    { time: 1626437342, value: 0 },
    { time: 1626437642, value: 0 },
    { time: 1626437942, value: 0 },
    { time: 1626438242, value: 0 },
    { time: 1626438542, value: 0 },
    { time: 1626438842, value: 0 },
    { time: 1626439142, value: 0 },
    { time: 1626439442, value: 0 },
    { time: 1626439742, value: 0 },
    { time: 1626440042, value: 0 },
    { time: 1626440342, value: 0 },
    { time: 1626440642, value: 0 },
    { time: 1626440942, value: 0 },
    { time: 1626441242, value: 0 },
    { time: 1626441542, value: 0 },
    { time: 1626441842, value: 0 },
    { time: 1626442142, value: 0 },
    { time: 1626442442, value: 0 },
    { time: 1626442742, value: 0 },
    { time: 1626443042, value: 0 },
    { time: 1626443342, value: 0 },
    { time: 1626443642, value: 0 },
    { time: 1626443942, value: 0 },
    { time: 1626444242, value: 0 },
    { time: 1626444542, value: 0 },
    { time: 1626444842, value: 0 },
    { time: 1626445142, value: 0 },
    { time: 1626445442, value: 0 },
    { time: 1626445742, value: 0 },
    { time: 1626446042, value: 0 },
    { time: 1626446342, value: 0 },
    { time: 1626446642, value: 0 },
    { time: 1626446942, value: 0 },
    { time: 1626447242, value: 0 },
    { time: 1626447542, value: 0 },
    { time: 1626447842, value: 0 },
    { time: 1626448142, value: 0 },
    { time: 1626448442, value: 0 },
    { time: 1626448742, value: 0 },
    { time: 1626449042, value: 0 },
    { time: 1626449342, value: 0 },
    { time: 1626449642, value: 0 },
    { time: 1626449942, value: 0 },
    { time: 1626450242, value: 0 },
    { time: 1626450542, value: 0 },
    { time: 1626450842, value: 0 },
    { time: 1626451142, value: 0 },
    { time: 1626451442, value: 0 },
    { time: 1626451742, value: 0 },
    { time: 1626452042, value: 0 },
    { time: 1626452342, value: 0 }
]
