import { Environment, Urls } from 'phoenix/constants';
import { getSimulatedEnvironment } from 'phoenix/constants/Support';
import { isWhitelabel, WhitelabelFormattedNames } from 'phoenix/constants/Whitelabels';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { useEffect, useState } from 'react';
import { CapitalizeFirstOnly } from 'util/Utils';

/**
 * @returns The email of the user attempting to log in, or undefined if it has not yet been retrieved
 */
export default function useLastResortEmail(): string | undefined {
    const [email, setEmail] = useState<string | undefined>();

    useEffect(() => {
        async function getEmail() {
            const userEmail = await getLastResortEmail();
            if (userEmail) {
                setEmail(userEmail);
                triggerAuthorizationAlert(userEmail);
            }
        }

        getEmail();
    }, []);

    return email;
}

function isEmailResponse(response: unknown): response is EmailResponse {
    return typeof response === 'object' && response !== null && 'email' in response && typeof response.email === 'string';
}

export async function getLastResortEmail(): Promise<string | undefined> {
    try {
        const response = await SnexAxios.ApiGet<unknown>(Urls.users.getMyTokenEmail()).withoutToken().run();
        if (isEmailResponse(response) && response.email.length > 0) {
            return response.email;
        } else {
            // we didn't get an email back
            const error = new Error("Failed to get user's email for denied screen");
            ErrorLogger.RecordError(error, 'Access Restricted: Found No User Email', { info: { response } });
        }
    }
    catch (e) {
        const error = e as Error; // ErrorLogger should be refactored tbh, since we could be throwing around any type of object
        // normally we'd log extra info saying who this user is, but we just failed at that, and the auth cookie is HttpOnly
        ErrorLogger.RecordError(error, 'Access Restricted: Error Getting User Email');
    }
    return undefined;
}

interface EmailResponse {
    email: string;
}

function triggerAuthorizationAlert(userEmail: string) {
    const simEnvironment = getSimulatedEnvironment();
    const environmentName = getShortEnvironmentName(simEnvironment);
    ErrorLogger.RecordMessage(`${environmentName} Web Access Restricted Alert Triggered`, 'Access Restricted', {
        tags: { email: userEmail },
        info: { email: userEmail }
    });
}

function getShortEnvironmentName(environment: Environment): string {
    if (isWhitelabel(environment)) {
        return WhitelabelFormattedNames[environment];
    }
    return CapitalizeFirstOnly(environment);
}
