// @ts-strict-ignore
import { Button, OutlinedInput } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { EditWatchlistAction } from 'phoenix/redux/actions';
import { Watchlist } from 'phoenix/redux/models';
import { UseInputFieldStyles, UseSubmitButtonStyles } from 'theming';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ActionModal } from '../ActionModal';
import { useColors } from 'hooks/UseColors';

interface EditListModalProps {
    listId: string;
    isOpen: boolean;
    toggleModal: (nextOpenState: boolean) => void;
}

export const EditListModal = (props: EditListModalProps) => {

    const dispatch = useDispatch();
    const watchlists = useSnexStore(s => s.watchlists.all);
    const colors = useColors();

    const inputClasses = UseInputFieldStyles(colors)();
    const buttonClasses = UseSubmitButtonStyles(colors)();

    const { isOpen, toggleModal, listId } = props;

    const [ listName, setListName ] = useState('');
    const [ listDescription, setListDescription ] = useState('');
    
    const saving = useSnexStore(s => s.watchlists.updatesById[listId]);
    const text = useText(s => s.modals.editWatchlistDetails);
    const activeWatchlist = useMemo(() => props.listId && isOpen ? watchlists.data.find(list => list.id === listId) : null, [ saving, listId, isOpen ]);

    useEffect(() => {
        if (saving?.success) toggleModal(false)
    }, [ saving ])

    useEffect(() => {
        setListName(activeWatchlist?.name)
        setListDescription(activeWatchlist?.description)
    }, [activeWatchlist])


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(EditWatchlistAction(listId, listName, listDescription));
    };



    return (
        <ActionModal
            isOpen={isOpen}
            label={ text.editList(activeWatchlist?.name) }
            toggleModal={toggleModal}>
            <form style={{ margin: 0, padding: 0 }} onSubmit={ handleSubmit }>
                <Flex align='center' style={{ width: '100%', flex: 1, margin: '25px 0 25px 0' }}>
                    <OutlinedInput classes={{ ...inputClasses }} defaultValue={ activeWatchlist?.name } placeholder={ text.name } onChange={({ target }) => setListName(target.value)} />
                </Flex>
                <Flex align='center' style={{ width: '100%', flex: 1, margin: '0 0 25px 0' }}>
                    <OutlinedInput multiline classes={{ ...inputClasses }} defaultValue={ activeWatchlist?.description } placeholder={ text.description } onChange={({ target }) => setListDescription(target.value)} />
                </Flex>
                <Flex align='center' justify='flex-end' style={{ width: '100%' }}>
                    <Button classes={{ ...buttonClasses }} disabled={ saving?.loading } type='submit'>
                        { saving?.loading ? text.saving : text.save }
                    </Button>
                </Flex>
            </form>
        </ActionModal>
    );
};