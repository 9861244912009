// @ts-strict-ignore
import { LightstreamerSubscriptionMode } from 'phoenix/constants';
import { SnexLsSubscriptionListener } from './SnexLsSubscriptionListener'

// Shim class for Lightstreamer Subscription
export class SnexLsSubscription {
    mode: LightstreamerSubscriptionMode;
    items: string[]
    itemGroup: string
    fields: string[]
    fieldSchema: string
    dataAdapter: string
    requestedMaxFrequency: number
    requestedSnapshot: string
    listeners: SnexLsSubscriptionListener[];

    backendRef: any;

    constructor (mode: LightstreamerSubscriptionMode, items: string[], fields: string[]) {
        this.mode = mode
        this.items = items
        this.fields = fields
        this.listeners = []
    }

    isActive (): boolean {
        if (!this.backendRef) return true
        try { return this.backendRef.isActive() } catch { return false }
    }

    addListener (listener: SnexLsSubscriptionListener): void { this.listeners.push(listener) }

    getItems (): string[] { return this.items }
    getItemGroup (): string { return this.itemGroup }
    getFields (): string[] { return this.fields }
    getFieldSchema (): string { return this.fieldSchema }
    getMode (): LightstreamerSubscriptionMode { return this.mode }
    getDataAdapter (): string { return this.dataAdapter }
    getRequestedMaxFrequency (): number { return this.requestedMaxFrequency }
    getRequestedSnapshot (): string { return this.requestedSnapshot }

    setItems (items: string[]): void { this.items = items }
    setItemGroup (group: string): void { this.itemGroup = group }
    setFields (fields: string[]): void { this.fields = fields }
    setFieldSchema (schema: string): void { this.fieldSchema = schema }
    setDataAdapter (adapter: string): void { this.dataAdapter = adapter }
    setRequestedMaxFrequency (freq: number): void { this.requestedMaxFrequency = freq }
    setRequestedSnapshot (requested: string): void { this.requestedSnapshot = requested }

    setBackendRef (ref: any): void { this.backendRef = ref }
}
