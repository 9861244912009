// @ts-strict-ignore
import { PaginationOffsets } from 'phoenix/redux/models/Paging/Page';
import { Order } from '.'
import { ApiData } from '../../../models'

export class OrdersState {

    openOrders: ApiData<Order[]>;
    searchResults: ApiData<Order[]>;
    nextOffsets: ApiData<PaginationOffsets>;
    noMoreResults: boolean;

    constructor () {
        this.openOrders = new ApiData([])
        this.searchResults = new ApiData([])
        this.nextOffsets = new ApiData(null)
        this.noMoreResults = false
    }
}
