// @ts-strict-ignore
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import React, { ReactElement, ReactNode } from 'react';
import { Flex, StyledIcon } from '..';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

export const ComponentWithAnimatedBackArrow = (props: { children: ReactNode; route: string }): ReactElement => (
    <Link to={props.route}>
        <Flex row align='center' className={style.wrapperSection} role='button'>
            <div className={style.backButton}>
                <StyledIcon IconComponent={KeyboardArrowLeftRounded} size={35} />
            </div>
            {!!props.children && props.children}
        </Flex>
    </Link>
);

export const DeeperViewTitle = ({ children, route, withBackArrow = true }: { children: ReactNode; route?: string; withBackArrow?: boolean }): ReactElement =>
    withBackArrow ? (
        <ComponentWithAnimatedBackArrow route={route}>{children}</ComponentWithAnimatedBackArrow>
    ) : (
        <Flex row align='center' className={style.wrapperSection} role='button'>
            {!!children && children}
        </Flex>
    );
