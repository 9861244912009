// @ts-strict-ignore
import { Ts } from '../assets/lang/T';
import { ApiPosition, OptionSymbol, TradeRequest } from '../redux/models';
import { Sum } from '../util';
import { GetConfig } from './Config';
import { TradeActions } from './Trade';

export type OptionsLevelType =
    | 'Covered Calls' // "Options Level 1"
    | 'Purchase Calls and Puts' // "Options Level 2"
    | 'Spreads/Long Straddles' // "Options Level 3"
    | 'Equity Put Writing' // "Options Level 4"
    | 'Uncovered Call Writing and Index Put Writing/Short Straddles' // "Options Level 5"
    | 'Uncovered Index Options'; // "Options Level 6"

// A separate array so that LevelOrBetter has a guaranteed order (Objects are not 100% guaranteed to be ordered)
export const OptionsLevelValuesOrdered: ReadonlyArray<OptionsLevelType> = [
    'Covered Calls',
    'Purchase Calls and Puts',
    'Spreads/Long Straddles',
    'Equity Put Writing',
    'Uncovered Call Writing and Index Put Writing/Short Straddles',
    'Uncovered Index Options'
];

export const GetLocalizedOptionsLevelLabel = (levelReal: OptionsLevelType): string[] => {
    const { level1, level2, level3, level4, level5, level6, disallowed } = Ts((s) => s.profileScreen.accounts.setup.optionsLevel);
    const topIdx = OptionsLevelValuesOrdered.findIndex((x) => x === levelReal);

    if (topIdx === -1) return [disallowed];
    return [level1, level2, level3, level4, level5, level6].slice(0, topIdx + 1);
};

export const OptionsLevel = {
    CoveredCalls: OptionsLevelValuesOrdered[0],
    CallsAndPuts: OptionsLevelValuesOrdered[1],
    SpreadsLongStraddles: OptionsLevelValuesOrdered[2],
    WriteEquityPuts: OptionsLevelValuesOrdered[3],
    LevelFive: OptionsLevelValuesOrdered[4],
    UncoveredIndexOptions: OptionsLevelValuesOrdered[5]
};

export const AccountHasNoOptions = (levelReal: OptionsLevelType): boolean => !OptionsLevelValuesOrdered.some((l) => l === levelReal);

// TODO: Type levelReal as OptionsLevelType
export const LevelOrBetter = (levelReal?: string, levelCompare?: string): boolean => {
    if (!levelReal || !levelCompare) return false;
    // True if the user's permission (levelReal) is as permissive or more than levelCompare
    const idxUser = Object.values(OptionsLevelValuesOrdered).findIndex((l) => l === levelReal);
    const idxCompare = Object.values(OptionsLevelValuesOrdered).findIndex((l) => l === levelCompare);
    return idxUser >= idxCompare;
};

export const AccountOptionLevel = (accountNumber: string): OptionsLevelType => {
    return GetConfig()
        .Store.getState()
        .accounts.all.data?.find((a) => a.accountNumber === accountNumber)?.optionsLevel;
};

export const AccountPermittedToOptionsLevel = (accountNumber: string | null, level: OptionsLevelType): boolean => {
    return LevelOrBetter(AccountOptionLevel(accountNumber), level);
};

export const _DebugExplainOptionsLevelInEnglish = (level: string): string => {
    const idx = Object.entries(OptionsLevel).findIndex((l) => l[1] === level);
    if (idx === -1) return `This account is not permitted to trade options because its options level is "${level}"`;
    const permitted = Object.entries(OptionsLevel)
        .slice(0, idx + 1)
        .map((e) => e[0]);
    const denied = Object.entries(OptionsLevel)
        .slice(idx + 1)
        .map((e) => e[0]);
    return `This account is level "${level}". With it the user may exercise the following options trading capabilities: ${permitted.join(
        ';'
    )}. The user may NOT do the following: ${denied.join(';')}.`;
};

type OptionsTradeWithPositionsPermittedArgs = {
    positions: ApiPosition[]; // Represents all options positions for a security
    sharesPerContract: number;
    trade: Partial<TradeRequest>;
    underlyingPositions: ApiPosition[];
};

/**
 * @deprecated this util is abstractly stateful and not unit tested
 * for stateful options permissions, use useOptionsOrderPermitted {@link file://./../hooks/useOptionsOrderPermitted.ts}
 * for stateless use GetOptionsOrderPermitted {@link file://./../util/OptionsHelpers.ts}
 * */

export const OptionsTradeWithPositionsPermitted = ({ positions, sharesPerContract, trade, underlyingPositions }: OptionsTradeWithPositionsPermittedArgs): boolean => {
    const { accountNumber, action, quantity, securityId } = trade;
    const { putCall, expDate } = new OptionSymbol(securityId);

    const matchingPutCall = positions.filter((p) => new OptionSymbol(p.secMasterOptionSymbol || p.symbol)?.putCall);
    // It's possible to leg into spreads for positions with farther out expiration dates
    const positionsWithValidExpirations = matchingPutCall.filter((p) => {
        const positionExpiration = new OptionSymbol(p.secMasterOptionSymbol || p.symbol)?.expDate;
        return expDate <= positionExpiration;
    });
    // Quantity of the exact option positions (exact expiration, put/call, etc)
    const positionQty = Sum(positions.filter((p) => new OptionSymbol(p.secMasterOptionSymbol || p.symbol).osiSymbol === trade?.securityId).map((p) => p.quantity));

    // Quantity of any qualifying options positions (same put/call, expiration same or farther out than order request)
    const spreadQty = Sum(positionsWithValidExpirations.map((p) => p.quantity));

    // User can always close out some or all of an open position
    if (quantity <= positionQty && action === TradeActions.Sell) return true; // Sell to close
    if (positionQty < 0 && quantity <= Math.abs(positionQty) && action === TradeActions.Buy) return true; // Buy to close

    // If user is entitled for spreads
    // they can leg into a spread based on an existing position with an expiration that is the same or later
    if (quantity <= spreadQty && action === TradeActions.Sell) return AccountPermittedToOptionsLevel(accountNumber, OptionsLevel.SpreadsLongStraddles);
    // if (spreadQty < 0 && quantity <= Math.abs(spreadQty) && action === TradeActions.Buy)
    //     return AccountPermittedToOptionsLevel(accountNumber, OptionsLevel.SpreadsLongStraddles);

    const underlyingQty = Sum(underlyingPositions?.map((u) => u.quantity));
    const canBeCovered = underlyingQty / sharesPerContract;
    const uncovered = quantity - positionQty - canBeCovered > 0;

    switch (true) {
        case action === 'Buy':
            return AccountPermittedToOptionsLevel(accountNumber, OptionsLevel.CallsAndPuts);
        case putCall === 'P':
            return AccountPermittedToOptionsLevel(accountNumber, OptionsLevel.WriteEquityPuts);
        case putCall === 'C' && uncovered:
            return AccountPermittedToOptionsLevel(accountNumber, OptionsLevel.LevelFive);
        default:
            return true;
    }
};
