import { Urls } from '../../constants'
import { ProductCode } from '../../constants/PremiumProducts'
import { Actions } from './Constants'
import { ReduxApiDelete, ReduxApiGet, ReduxApiPost, ReduxApiPut } from './Helpers'

export const SubscribeToPremiumAction = (productId: string, accountNumber: string) => ReduxApiPost(Urls.subscription.subscribe(), Actions.Subscription.Subscribe, {
    productId, // e.g., sx1 (not a tier, like preferred:sx1)
    paymentAccountNumber: accountNumber
})
    .showToasts()
// @ts-ignore
    .onSuccess((_, dispatch) => dispatch(GetMySubscriptionInfoAction()))
    .run();

export const GetMySubscriptionInfoAction = () => ReduxApiGet(Urls.subscription.getMine(), Actions.Subscription.GetMyInfo)
    .withMutex().withLoading().run()

export const GetStripeCheckoutUrlAction = (productCode: ProductCode) => ReduxApiGet(Urls.subscription.getStripeCheckoutUrl(productCode), Actions.Subscription.GetStripeCheckoutUrl)
    .showToasts().withLoading().run()

export const GetStripePortalUrlAction = () => ReduxApiGet(Urls.subscription.getStripePortalUrl(), Actions.Subscription.GetStripePortalUrl)
    .showToasts().withLoading().run()

export const CancelSubscriptionAction = () => ReduxApiDelete(Urls.subscription.cancel(), Actions.Subscription.Cancel)
    .showToasts().withLoading()
// @ts-ignore
    .onSuccess((_, dispatch) => dispatch(GetMySubscriptionInfoAction()))
    .run()

export const UncancelSubscriptionAction = () => ReduxApiPut(Urls.subscription.uncancel(), Actions.Subscription.Uncancel)
    .showToasts()
// @ts-ignore
    .onSuccess((_, dispatch) => dispatch(GetMySubscriptionInfoAction()))
    .run();

export const UpdateSubscriptionTierAction = (tier: string) => ReduxApiPut(Urls.subscription.setMine(tier), Actions.Subscription.SetMyTier)
    .showToasts().withLoading()
// @ts-ignore
    .onSuccess((_, dispatch) => dispatch(GetMySubscriptionInfoAction()))
    .run()

export const UpdateSubscriptionPaymentAccountAction = (accountNumber: string) => ReduxApiPut(Urls.subscription.updatePaymentAccount(), Actions.Subscription.UpdatePaymentAccount, {
    paymentAccountNumber: accountNumber
})
    .showToasts().withLoading()
// @ts-ignore
    .onSuccess((_, dispatch) => dispatch(GetMySubscriptionInfoAction()))
    .run()
