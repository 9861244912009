import _ from "lodash";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { BaseOnboardingStoreData } from "../Base/BaseOnboardingStore";

interface RegistrationData extends BaseOnboardingStoreData {
    isWelcomed?: boolean,
    emailVerified?: boolean,
    existingEmails?: string[];
    phoneNumberRaw?: string;
    verificationCode?: string;
    codeIsVerified?: boolean;
    verifying?: boolean;
    loading?: boolean;
    data: {
        firstName?: string;
        lastName?: string;  
        email?: string;
        confirmEmail?: string;
        countryCode?: number;
        phone?: string;
        usCitizen?: boolean;
        password?: string;
        confirmPassword?: string;
    }
    setValue: (key: string, value?: any) => void
    clearData: () => void
}

export const useRegistrationStore = create<RegistrationData>()(persist((set, get) => ({ 
   selectedScreenId: 'welcome',
   isWelcomed: false,
   data: {},
   loading: false,
   setValue: (key: string, value?: any) => set((s) => ({..._.set(s, key, value)})),
   clearData: () => set((s) => ({
        selectedScreenId: 'welcome',
        isWelcomed: false,
        data: {},
        loading: false,
        emailVerified: undefined,
        existingEmails: [],
        phoneNumberRaw: undefined,
        verificationCode: undefined,
        codeIsVerified: undefined
    }))
}),{ name: 'webRegistration',  storage: createJSONStorage(() => localStorage) }))
