// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web';
import { GetConfig, LiveDataGroups, Urls } from '../../constants';
import { SymbolUrlChunk, UniqueBy } from '../../util';
import { OptionSymbol } from '../models';
import { FuturesSymbol } from '../models/Futures/FuturesSymbol';
import { Actions } from './Constants';
import { ReduxApiGet, ReduxApiResponse } from './Helpers';
import { GetSingleOptionQuoteAction } from './OptionsActions';
import { ReduxLiveSubscribe } from 'phoenix/redux/actions/StreamingHelpers';

export const GetSecurityQuoteAction = (
    symbol: string,
    bypassBatching?: boolean,
    bypassCache?: boolean,
    typeHint?: string,
    bypassMutexReuse?: boolean
): ReduxApiResponse<unknown> => {
    if (OptionSymbol.IsOptionSymbol(symbol)) return GetSingleOptionQuoteAction(symbol);
    const alreadyStored = !!GetConfig().Store.getState().securityQuote.bySymbol[symbol]?.data;
    const useBatching = !bypassBatching && (bypassCache || !alreadyStored);
    const url = useBatching ? `${symbol}/${typeHint}` : Urls.securities.core.getQuote(symbol, typeHint);

    let r = ReduxApiGet(url, Actions.Securities.Quote.Get).withSubject(symbol).withMutex(symbol, !!bypassMutexReuse);

    if (!bypassCache) {
        r = r.useStored((s) => {
            const currentData = s.securityQuote.bySymbol[symbol]?.data;
            const shouldUseStore = alreadyStored && currentData.previousClose;
            return shouldUseStore ? currentData : null;
        });
    }

    if (useBatching) {
        r = r.withBatching(
            'ad-hoc-sec-quote',
            (symbolTypes) => {
                const tuples = symbolTypes.map((s) => s.split('/'));
                const symbols = tuples.map((t) => t[0]);
                return SymbolUrlChunk(symbols).map((s) => Urls.securities.core.getV2MultiSecurityQuoteBatch(s));
            },
            500
        );
        r = r.onError((e: any, dispatch: any) => {
            if (Array.isArray(e?.subject)) {
                UniqueBy(e?.subject, (item: any) => item.symbol)?.map((s) => dispatch(GetSecurityQuoteAction(s.symbol, true, bypassCache)));
            }
            return e;
        });
    }

    return r.run();
};

export const GetV2SecurityQuoteAction = (symbol: string, bypassBatching?: boolean, bypassCache?: boolean, typeHint?: string, bypassMutexReuse?: boolean) => {
    if (OptionSymbol.IsOptionSymbol(symbol)) return GetSingleOptionQuoteAction(symbol);
    const alreadyStored = !!GetConfig().Store.getState().securityQuote.bySymbol[symbol]?.data;
    const useBatching = !bypassBatching && (bypassCache || !alreadyStored);
    const url = useBatching ? `${symbol}/${typeHint}` : Urls.securities.core.getQuote(symbol, typeHint);

    let r = ReduxApiGet(url, Actions.Securities.Quote.Get).withSubject(symbol).withMutex(symbol, !!bypassMutexReuse);

    if (!bypassCache) {
        r = r.useStored((s) => {
            const currentData = s.securityQuote.bySymbol[symbol]?.data;
            const shouldUseStore = alreadyStored && currentData.previousClose;
            return shouldUseStore ? currentData : null;
        });
    }

    if (useBatching) {
        r = r.withBatching(
            'ad-hoc-sec-quote',
            (symbolTypes) => {
                const tuples = symbolTypes.map((s) => s.split('/'));
                const symbols = tuples.map((t) => t[0]);
                return SymbolUrlChunk(symbols).map((s) => Urls.securities.core.getV2MultiSecurityQuoteBatch(s));
            },
            500
        );
        r = r.onError((e: any, dispatch: any) => {
            console.log('ERROR!!!', { e });
            if (Array.isArray(e?.subject)) {
                UniqueBy(e?.subject, (item: any) => item.symbol)?.map((s) => dispatch(GetSecurityQuoteAction(s.symbol, true, bypassCache)));
            }
            return e;
        });
    }

    return r.run();
};

/** @deprecated Please use GetSecurityQuoteAction */
export const GetMultiSecurityQuotesAction = (symbols: string[]): ReduxApiResponse<unknown> =>
    ReduxApiGet<unknown, unknown, { symbols: string[] }>(
        SymbolUrlChunk(symbols).map((s) => Urls.securities.core.getMultiSecurityQuoteBatch(s)),
        Actions.Securities.GetMultiSecurityQuoteBatch
    )
        .onError((error, dispatch) => {
            console.warn('Quote batch failed for following symbols', symbols, 'with the following error', error);
            return error;
        })
        .withMutex()
        .withSubject({ symbols })
        .run();

/** @deprecated Please use XStream.Dom */
export const SubscribeToFuturesDomAction = (futuresSymbol: string, namespace: string, callback?: (update: any) => void) => {
    if (!FuturesSymbol.IsFuturesSymbol(futuresSymbol)) return;
    const fsym = new FuturesSymbol(futuresSymbol);
    return ReduxLiveSubscribe(
        LiveDataGroups.Futures,
        [`dom:${fsym.noPrefix}`],
        Actions.Securities.Dom.LiveUpdates,
        ['Asks', 'Bids'],
        (update: ItemUpdate) => {
            try {
                const bidAsks = {
                    bids: JSON.parse(update.getValue('Bids')),
                    asks: JSON.parse(update.getValue('Asks'))
                };
                if (callback) callback(bidAsks);
                return bidAsks;
            } catch (error) {
                console.log('cant do that');
            }
        },
        {
            mode: 'MERGE',
            subject: { symbol: fsym.withPrefix },
            namespace,
            upstream: 'gain'
        }
    );
};
