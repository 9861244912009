import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';

const margin = '5px 0';

export const UseNotAllowedButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.notAllowedBackgroundColor,
            border: `1px dashed ${colors.notAllowedBorderColor}`,
            width: '100%',
            margin,
            padding: 15,
            color: colors.notAllowedTextColor,
            textTransform: 'none',
            boxShadow: 'none',
            '&:disabled': {
                color: colors.notAllowedTextColor
            }
        }
    });

export const UseOnboardingButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.defaultOnboardingButtonBackgroundColor,
            boxShadow: 'none',
            margin,
            width: '100%',
            // minWidth: '100px',
            // padding: '7px 0',
            boxSizing: 'border-box',
            padding: 12,
            color: colors.defaultOnboardingButtonColor,
            textTransform: 'none',
            '&:hover': {
                background: colors.defaultOnboardingButtonBackgroundHoverColor
            }
        }
    });

export const UseSelectedOnboardingButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.selectedOnboardingButtonBackgroundColor,
            boxShadow: 'none',
            margin,
            width: '100%',
            // minWidth: '100px',
            // padding: '7px 0',
            boxSizing: 'border-box',
            padding: 12,
            color: colors.selectedOnboardingButtonColor,
            textTransform: 'none',
            '&:hover': {
                background: colors.selectedOnboardingButtonBackgroundHoverColor,
                color: colors.selectedOnboardingButtonHoverColor
            }
        }
    });

export const UseSubmitButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.primaryItemColor,
            border: 'none',
            width: '100%',
            margin,
            padding: 15,
            color: 'white',
            textTransform: 'none',
            boxShadow: 'none',
            whiteSpace: 'pre-wrap',
            '&:hover': {
                background: colors.submitButton
            },
            '&:disabled': {
                color: 'white',
                opacity: 0.2
            },
            '&.MuiButtonBase-root': {
                background: colors.primaryItemColor,
                color: 'white',
                textAlign: 'center'
            }
        }
    });

export const UseCardButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.primaryItemColor,
            border: `1px solid black ${colors.cardButtonBorder}`,
            boxShadow: 'none',
            width: '100%',
            margin,
            // padding: '7px 0',
            padding: 15,
            color: 'white',
            textTransform: 'none',
            '&:hover': {
                background: colors.segmentedControlActiveColor
            },
            '&:disabled': {
                color: 'white',
                opacity: 0.2
            }
        }
    });

export const UseSecondaryButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.secondaryButtonBackgroundColor,
            boxShadow: 'none',
            margin,
            width: '100%',
            boxSizing: 'border-box',
            padding: 12,
            color: colors.primaryItemColor,
            textTransform: 'none',
            '&:hover': {
                background: colors.secondaryButtonBackgroundHoverColor
            }
        }
    });

    export const UseSecondarySolidButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.secondarySolidButtonBackgroundColor,
            boxShadow: 'none',
            margin,
            width: '100%',
            boxSizing: 'border-box',
            padding: 12,
            color: colors.primaryItemColor,
            textTransform: 'none',
            '&:hover': {
                background: colors.secondarySolidButtonBackgroundHoverColor
            }
        }
    });

export const UseSecondaryTransparentButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: 'transparent',
            boxShadow: 'none',
            margin,
            padding: 12,
            color: colors.primaryItemColor,
            textTransform: 'none',
            '&:hover': {
                boxShadow: 'none',
                background: colors.secondaryButtonBackgroundHoverColor
            }
        }
    });

export const UseDeleteButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: colors.deleteButton,
            boxShadow: 'none',
            border: `1px solid ${colors.deleteButton}`,
            width: '100%',
            // padding: '7px 0',
            padding: 12,
            margin,
            color: colors.white,
            textTransform: 'none',
            '&:hover': {
                background: colors.deleteButtonDark
            }
        }
    });

export const UseActionLinkButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: 'transparent',
            border: 'none',
            boxShadow: 'none',
            margin,
            color: colors.primaryItemColor,
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            // padding: '7px 0',
            padding: 0,
            '&:hover': {
                background: 'transparent'
            }
        }
    });

export const UseCancelButtonStyles = (colors: AppColorTheme) =>
    makeStyles({
        root: {
            background: 'transparent',
            width: '100%',
            boxShadow: 'none',
            // padding: '7px 0',
            padding: 12,
            color: colors.generalTextColor,
            margin,
            textTransform: 'none',
            border: `1px solid ${colors.generalTextColor}`
        }
    });

export const UserRadioButtonStyles = () =>
    makeStyles({
        label: {
            fontSize: 15,
            fontWeight: 500
        }
    });

export const UseTabGroupStyles = (color?: string, indicatorColor?: string) =>
    makeStyles(() => ({
        indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > span': {
                width: '85%',
                backgroundColor: indicatorColor || 'orange'
            }
        },
        root: {
            color
        }
    }));

export const UseSingleTabStyles = () =>
    makeStyles((theme: Theme) => ({
        root: {
            textTransform: 'none',
            'font-weight': theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            '&:focus': {
                opacity: 1
            }
        }
    }));

export const UseSwitchStyles = (colors: AppColorTheme) =>
    makeStyles(() => ({
        root: {
            '& .MuiSwitch-switchBase.Mui-checked': {
                color: colors.primaryItemColor
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: colors.primaryItemColor
            }
        }
    }));
