// @ts-strict-ignore
import { Typography } from '@mui/material';
import React from 'react';
import { Flex } from '..';
import { FormatNumber, GetValueChangeColor } from 'phoenix/util';
import { DirectionalArrow } from '../DirectionalArrow';

interface PercentChangeProps {
    percentChange?: number;
    size?: number;
}

export const PercentChange = ({
    percentChange,
    size = 10
}: PercentChangeProps) => {
    return (
        <Flex
            align='center'
            justify='flex-end'> 
            <DirectionalArrow height={size} valueChange={percentChange} />
            <Typography
                style={{ 
                    paddingLeft: 6, 
                    fontWeight: 500, 
                    fontSize: size, 
                    ...GetValueChangeColor(percentChange) 
                }}
                variant='body1'>
                {FormatNumber.toPercent(percentChange, 'never')}</Typography>
        </Flex>
    );
};
