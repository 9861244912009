import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { DndItems, DndTypes, WatchlistDnd } from 'constants/DndTypes';
import { ReorderWatchlistAction } from 'phoenix/redux/actions';
import { Watchlist } from 'phoenix/redux/models';
import './WatchlistReorderDropZone.scss';

interface WatchlistReorderDropZoneProps {
    watchlists: Watchlist[];
    index: number;
}

export const WatchlistReorderDropZone = React.memo((props: PropsWithChildren<WatchlistReorderDropZoneProps>) => {
    const dispatch = useDispatch();

    const handleItemDrop = async (item: WatchlistDnd) => {
        const fromIdx = item.index;
        let toIdx = props.index;
        const movedPositions = fromIdx !== toIdx;
        if (!movedPositions) return console.warn('Item not moved, bailing');

        let reordered = [...props.watchlists.sort((a, b) => (a?.sequence ?? 0) - (b?.sequence ?? 0))];
        if (fromIdx < toIdx) --toIdx;
        const toMove = reordered[fromIdx];
        reordered.splice(fromIdx, 1);
        reordered.splice(toIdx, 0, toMove);
        reordered = reordered.map((x, xindex) => {
            x.sequence = xindex;
            return x;
        });
        dispatch(ReorderWatchlistAction(reordered));
    };

    const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
        accept: DndTypes.Watchlist,
        canDrop: () => true,
        drop: handleItemDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }));

    const [, dragRef] = useDrag(() => ({
        type: DndTypes.Watchlist,
        item: DndItems.Watchlist(props.index),
        collect: (monitor) => ({
            isDrag: monitor?.isDragging()
        })
    }));

    return (
        <div
            ref={dropRef}
            className={classNames({
                'watchlist-reorder-drop-zone': true,
                'watchlist-reorder-drop-zone-hover': isOver,
                'watchlist-reorder-drop-zone-hidden': !canDrop
            })}
        >
            <div ref={dragRef}>{props.children}</div>
        </div>
    );
});
