import { PaddedLabel } from 'components/PaddedLabel/PaddedLabel';
import { useText } from 'phoenix/hooks/UseText';
import React, { useMemo, useState } from 'react';
import { useColors } from 'hooks/UseColors';

interface ExpiresLabelProps {
    expiryDate: Date;
    style?: React.CSSProperties;
    size?: 'small' | 'large';
    textAlign?: 'left' | 'center' | 'right';
}

export const ExpiresLabel = React.memo(function ExpiresLabelUnmemoized(props: ExpiresLabelProps) {
    const colors = useColors();
    const expiresSoonText = useText((t) => t.general.expiresSoon);
    const expiresTodayText = useText((t) => t.general.expiresToday);
    const expiresTomorrowText = useText((t) => t.general.expiresTomorrow);
    const [currentDate] = useState(new Date());

    const { label, color, backgroundColor } = useMemo(() => {
        // Convert current date to Central Time
        const currentUTCTime = new Date(currentDate.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

        // Get start and end of current day in Central Time
        const currentDayStart = new Date(currentUTCTime.getFullYear(), currentUTCTime.getMonth(), currentUTCTime.getDate());
        const currentDayEnd = new Date(currentDayStart.getTime() + 24 * 60 * 60 * 1000 - 1);

        // Check if it expires today
        if (props.expiryDate >= currentDayStart && props.expiryDate <= currentDayEnd) {
            return { label: expiresTodayText, color: colors.optionsLabelCoral, backgroundColor: colors.optionsLabelCoralLight };
        }

        // Get start and end of next day in Central Time
        const nextDayStart = new Date(currentDayStart.getTime() + 24 * 60 * 60 * 1000);
        const nextDayEnd = new Date(nextDayStart.getTime() + 24 * 60 * 60 * 1000 - 1);

        // Check if it expires tomorrow
        if (props.expiryDate > currentDayEnd && props.expiryDate <= nextDayEnd) {
            return { label: expiresTomorrowText, color: colors.optionsLabelOrange, backgroundColor: colors.optionsLabelOrangeLight };
        }

        // Get difference in time between current time and expiration time
        const diffInMilliseconds = props.expiryDate.getTime() - currentUTCTime.getTime();
        const diffInDays = diffInMilliseconds / (1000 * 3600 * 24);

        // Check if it expires soon (within the next 4 days)
        if (diffInDays <= 4 && diffInDays >= 0) {
            return { label: expiresSoonText, color: colors.optionsLabelBlue, backgroundColor: colors.optionsLabelBlueLight };
        }

        // For cases further than 4 days
        return { label: null, color: undefined, backgroundColor: undefined };
    }, [currentDate, props.expiryDate, expiresSoonText, expiresTodayText, expiresTomorrowText]);

    if (!label) return null;

    return (
        <PaddedLabel
            label={label}
            style={{
                padding: '3px 6px',
                whiteSpace: 'nowrap',
                ...(color ? { color: color as React.CSSProperties['color'] } : {}),
                ...(backgroundColor ? { backgroundColor } : {}),
                ...props.style
            }}
            size={props.size || 'large'}
            textAlign={props.textAlign || 'center'}
        />
    );
});
