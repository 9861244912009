import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useTelemetry } from 'hooks/UseTelemetry';
import { FromEnglish } from 'phoenix/assets/lang/T';
import { ChartRange, ChartRanges } from 'phoenix/constants/ChartRange';
import { QuoteSelector } from 'phoenix/constants/ReduxSelectors';
import { ChangeColor } from 'phoenix/util';
import { useSecurityValues } from 'components/SecurityValues/SecurityValuesViewModel';

interface RangeSelectorProps {
    disabled?: boolean;
    excludeRanges?: Set<ChartRange | string>;
    selectedRange: ChartRange;
    setRange: (range: ChartRange) => void;
    valueChange?: number;
}

export const RangeSelector = (props: RangeSelectorProps): ReactElement => {
    const { valueChange = 0, selectedRange, setRange, disabled, excludeRanges } = props;
    const LogEvent = useTelemetry();

    const determineRangeButtonColorClass = (chg: number) => {
        const className = 'range-label-active';
        if (chg >= 0) return className + ' range-label-positive';
        else if (!chg) {
            return className + ' range-label-neutral';
        } else return className + ' range-label-negative';
    };

    return (
        <div className='range-list'>
            {Object.entries(ChartRanges)
                .filter((r) => !excludeRanges || !excludeRanges.has(r[1]))
                .map((range, key) => (
                    <button
                        key={key}
                        className={`range-label ${selectedRange === range[1] ? determineRangeButtonColorClass(valueChange) : 'range-label-non-active'}`}
                        onClick={
                            disabled
                                ? () => undefined
                                : () => {
                                      LogEvent('Chart range change', { range: range[1] });
                                      setRange(range[1] as ChartRange);
                                  }
                        }
                    >
                        {FromEnglish((s) => s.misc.ranges, range[1].toUpperCase())}
                    </button>
                ))}
        </div>
    );
};
