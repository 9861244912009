// @ts-strict-ignore
import { Urls } from '../../constants'
import { GlobalState } from '../GlobalState'
import { OptionSymbol, TaxlotAggregateItem } from '../models'
import { Actions } from './Constants'
import { ReduxApiGet } from './Helpers'

export const GetOpenTaxlotsAction = () => ReduxApiGet(Urls.accountSummaries.getTaxlotAggregate('Open'), Actions.Taxlots.GetOpen)
    .withMutex()
    .onSuccess((res: TaxlotAggregateItem) => ProcessTaxLots(res))
    .useStored((s: GlobalState) => s.taxlots.openTaxlots?.data, 'taxl:open', 60)
    .run()

export const GetClosedTaxlotsAction = () => ReduxApiGet(Urls.accountSummaries.getTaxlotAggregate('Closed'), Actions.Taxlots.GetClosed)
    .withMutex()
    .withLoading()
    .onSuccess((res: TaxlotAggregateItem) => ProcessTaxLots(res))
    .useStored((s: GlobalState) => s.taxlots.closedTaxlots?.data, () => 'taxl:closed', 60)
    .run()

const ProcessTaxLots = (agg: TaxlotAggregateItem) => {
    agg.taxlGroupItems.filter(g => g.items.some(i => !!i.optionSymbol)).forEach(g => {
        g.items.forEach(i => {
            const optSym = new OptionSymbol(i.optionSymbol)
            i.optionSymbolMetadata = optSym
            i.optionOsiSymbol = optSym.toOsiSymbol()
            i.optionOccSymbol = optSym.toOccSymbol()
        })
    })
    return agg
}
