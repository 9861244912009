// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { AddToast } from "react-toast-notifications"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"
import { Registration_UserInfo_Form, VerifyUserDoesNotExist } from "./Form"
import { getUserInfoErrors, UserInfoIsValid } from "./Validation"

export const registration_userInfo_step: OnboardingStep = {
    label: "User Info",
    isComplete: () => UserInfoIsValid() && !!useRegistrationStore.getState().isWelcomed,
    isEnabled: () => !!useRegistrationStore.getState().isWelcomed,
    isCurrent: () => ['userInfo', 'userExists'].includes(useRegistrationStore?.getState()?.selectedScreenId),
    onClickGoToScreenId: 'userInfo'
}

export const registration_userInfo_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => VerifyUserDoesNotExist(),
    isPrimary: true,
    isLoading: () => !!useRegistrationStore.getState().verifying,
    isEnabled: () =>  !useRegistrationStore.getState().verifying 
        && getUserInfoErrors().filter(e => !e.error.toLowerCase().includes('already exists')).length < 1 
        && !!useRegistrationStore.getState().isWelcomed
}

export const registration_userInfo_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useRegistrationStore.getState().setValue('selectedScreenId', 'welcome'),
    isPrimary: false,
    isEnabled: () => true
}

export const registration_userInfo_screen: OnboardingScreen = {
    id: "userInfo",
    validate: getUserInfoErrors,
    buttons: [registration_userInfo_prevButton, registration_userInfo_nextButton],
    title: T(t => t.webRegistration.getUserInfo),
    subtitles: [],
    body: <Registration_UserInfo_Form />
}
