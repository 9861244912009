// Total Profit/Loss for a futures position
type FuturesTotalProfitLossProps = {
    currentPrice: number; // SecurityQuote.price or ApiPosition.lastPrice - last available price for the contract
    costBasis: number; // ApiPosition.netPrice - Average cost of each contract in a position
    quantity: number; // ApiPosition.netVolume - number of contracts in the position
    unitFactor: number; // SecurityMetadata.unitFactor - Used to convert point value to currency value
};

export function getFuturesTotalProfitLoss({ currentPrice, costBasis, quantity, unitFactor }: FuturesTotalProfitLossProps) {
    if (!currentPrice || !costBasis || !quantity || !unitFactor) return null;
    return (currentPrice - costBasis) * quantity * unitFactor;
}
