// @ts-strict-ignore
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { StorageKeys } from 'phoenix/constants';
import { AppliedAppTheme, AppliedAppThemes } from 'phoenix/constants/AppliedAppTheme';
import { GetColors } from 'phoenix/theming/Colors';
import { ThemeVariants } from 'phoenix/theming/ThemeVariants';
import { GetMarketTime } from 'phoenix/util';
import { GetDeviceThemeVariant } from './GetDeviceThemeVariant';

const fontResetStyles = {
    margin: 0,
    padding: 0,
    lineHeight: 1.3
};

const CreateCoreThemeOptions = (theme?: 'light' | 'dark'): ThemeOptions => ({
    palette: {
        mode: theme || 'light',
        background: {
            default: GetColors(theme || 'light').mainBackgroundColor
        },
        primary: {
            main: GetColors(theme || 'light').primaryItemColor
        },
        secondary: {
            main: GetColors(theme || 'light').segmentedControlActiveColor
        }
    },
    typography: {
        fontFamily: 'Lato',
        h1: { ...fontResetStyles },
        h2: { ...fontResetStyles, fontSize: '28px', fontWeight: 500 },
        h3: { ...fontResetStyles, fontSize: '25px', fontWeight: 500 },
        h4: { ...fontResetStyles, fontSize: '21px', fontWeight: 500 },
        h5: { ...fontResetStyles, fontSize: '17px', fontWeight: 700 },
        h6: { ...fontResetStyles, fontSize: '15px', fontWeight: 600 },
        body1: { ...fontResetStyles, fontSize: '13px' },
        body2: { ...fontResetStyles, fontWeight: 400, fontSize: '13px' },
        button: { ...fontResetStyles, fontWeight: 700, textTransform: 'capitalize' },
        caption: { ...fontResetStyles, fontWeight: 300 }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                html {
                    -webkit-font-smoothing: auto;
                    margin: 0;
                    padding: 0;
                }
                h6 {
                    margin: 0;
                    padding: 0;
                }
            `
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    lineHeight: '1.1876em' // somehow set before upgrading to MUI 5
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    lineHeight: '1.1876em' // somehow set before upgrading to MUI 5
                }
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '10px'
                }
            }
        }
    }
});

const DarkTheme = createTheme({ ...CreateCoreThemeOptions('dark') });
const LightTheme = createTheme({ ...CreateCoreThemeOptions('light') });

export const GetMuiTheme = (appliedAppTheme: AppliedAppTheme) => {
    return appliedAppTheme === AppliedAppThemes.dark ? DarkTheme : LightTheme;
};

export const GetAppTheme = (_appThemePreference?: string): AppliedAppTheme => {
    const deviceThemeVariant = GetDeviceThemeVariant();
    const appThemePreference = _appThemePreference || JSON.parse(window.localStorage.getItem(StorageKeys.AppThemePreference));
    // console.log(`Getting applied app theme for ${appThemePreference}`)
    const marketTheme = GetMarketTime() === 'open' ? AppliedAppThemes.light : AppliedAppThemes.dark;
    const deviceTheme = deviceThemeVariant === ThemeVariants.dark ? AppliedAppThemes.dark : AppliedAppThemes.light;

    switch (appThemePreference) {
        case ThemeVariants.light:
            return AppliedAppThemes.light;
        case ThemeVariants.dark:
            return AppliedAppThemes.dark;
        case ThemeVariants.device:
            return deviceTheme;
        case ThemeVariants.market:
        default:
            return marketTheme;
    }
};
