import { Relays } from 'constants/Relays';
import { GetRelay, SetRelay, useRelay } from 'phoenix/hooks/UseRelay';

export type WindowSize = 'mobile' | 'tablet' | 'regular-display' | 'large-display' | 'small-display';

export const WindowSizes = {
    mobile: 'mobile' as WindowSize,
    tablet: 'tablet' as WindowSize,
    small: 'small-display' as WindowSize,
    regular: 'regular-display' as WindowSize,
    large: 'large-display' as WindowSize
};

export const useAppWindowSize = () => useRelay<WindowSize>(Relays.AppWindowSize)
export const SetAppWindowSize = (size: WindowSize) => SetRelay(Relays.AppWindowSize, size)
export const GetAppWindowSize = () => GetRelay(Relays.AppWindowSize)

export const useAppWindowSizeVariable = <TValue>(
    choose: Partial<{
        mobile: TValue;
        tablet: TValue;
        sm: TValue;
        md: TValue;
        lg: TValue;
        def: TValue;
    }>
) => {
    const [size, _] = useAppWindowSize();
    switch (size) {
        case 'mobile':
            return choose.mobile || choose.def;
        case 'tablet':
            return choose.tablet || choose.def;
        case 'small-display':
            return choose.sm || choose.def;
        case 'regular-display':
            return choose.md || choose.def;
        case 'large-display':
            return choose.lg || choose.def;
        default:
            return choose.def;
    }
};
