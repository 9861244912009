// @ts-strict-ignore
import { ChartRange } from 'phoenix/constants';
import { create } from 'zustand';

export type SnexChartSeriesType = 'line' | 'candle' | 'area'

export interface SecurityChartStore {
    changePercentValue: number;
    changeValue: number;
    close?: number;
    high?: number;
    loading?: boolean;
    low?: number;
    open?: number;
    range: ChartRange;
    seriesType?: SnexChartSeriesType;
    setChartValues: (chartValues: Partial<SecurityChartStore>) => void;
    showBarLabels?: boolean;
    showVolume?: boolean;
    value: number;
    volume?: number;
}

export const useSecurityChartStore = create<SecurityChartStore>((set) => ({
    changeValue: null,
    changePercentValue: null,
    range: '1d',
    seriesType: 'line',
    value: null,
    setChartValues: (chartValues: Partial<SecurityChartStore>) => set((s) => ({ ...s, ...chartValues }))
}));
