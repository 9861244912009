// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { TaxlotState } from '../models/Taxlots'

const permitted = GroupNameChecker([Actions.Taxlots])
export const TaxlotsReducer = (state: TaxlotState = new TaxlotState(), action: ReduxAction): TaxlotState => {
    if (!permitted(action)) return state

    switch (action.type) {
        case Actions.Taxlots.GetAggregate.Loading: return { ...state, aggregate: state.aggregate.startLoading(state.aggregate.data) }
        case Actions.Taxlots.GetAggregate.Success: return { ...state, aggregate: state.aggregate.succeeded(action.data) }
        case Actions.Taxlots.GetAggregate.Failure: return { ...state, aggregate: state.aggregate.failed(action.error) }

        case Actions.Taxlots.GetOpen.Loading: return { ...state, openTaxlots: state.openTaxlots.startLoading(state.openTaxlots.data) }
        case Actions.Taxlots.GetOpen.Success: return { ...state, openTaxlots: state.openTaxlots.succeeded(action.data) }
        case Actions.Taxlots.GetOpen.Failure: return { ...state, openTaxlots: state.openTaxlots.failed(action.error) }
        case Actions.Taxlots.GetClosed.Loading: return { ...state, closedTaxlots: state.closedTaxlots.startLoading(state.closedTaxlots.data) }
        case Actions.Taxlots.GetClosed.Success: return { ...state, closedTaxlots: state.closedTaxlots.succeeded(action.data) }
        case Actions.Taxlots.GetClosed.Failure: return { ...state, closedTaxlots: state.closedTaxlots.failed(action.error) }
        default: return state
    }
}
