// @ts-strict-ignore

import { useState, useEffect, useMemo } from 'react'
import { useDeactivateXStream } from '../../hooks/UseDeactivateXStream'
import { useSnexStore } from '../../hooks/UseSnexStore'
import { AccountSummary } from '../../redux/models'
import { XstreamResetAccountValuationAction } from '../actions/XstreamAccountActions'
import { useXstreamStore } from '../useXstreamStore'
import { useXstreamDispatch } from '../XstreamProvider'

export const useAccountStreamAndSnapshot = (accountNumber?: string) => {
    const deactivateXStream = useDeactivateXStream()
    const xDispatch = useXstreamDispatch()

    const [init, setInit] = useState<AccountSummary>()
    useEffect(() => setInit(null), [accountNumber])
    const snap = useSnexStore(s => accountNumber ? s.accountSummary.byNumber[accountNumber] : s.accountSummary.aggregate)
    const stream = useXstreamStore(s => accountNumber ? s.accountValuations[accountNumber] : s.portfolioValuation)

    useEffect(() => {
        const val = snap?.data?.totalAccountValueCurrent;
        if (!!val || val === 0) {
            xDispatch(XstreamResetAccountValuationAction(accountNumber))
            setInit(snap?.data)
        }
    }, [snap?.data, accountNumber])

    if (!stream || deactivateXStream) return init

    const oldValue = init?.totalAccountValueCurrent
    const oldGl = init?.todaysGainLoss
    const basisKnown = [oldValue, oldGl].every(x => x !== null && x !== undefined)
    const glBasis = oldValue - oldGl
    const newGl = basisKnown ? stream?.value - glBasis : NaN
    const newGlPct = basisKnown ? newGl / glBasis : NaN
    return {
        ...init,
        totalAccountValueCurrent: stream?.value,
        todaysGainLoss: newGl,
        todaysGainLossPercentage: newGlPct
    }
}
