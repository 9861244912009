export const IndustryGroups = {
    cashAndEquivalents: 0,
    automotiveAndTrucks: 140,
    broadcastingRadioAndTv: 190,
    conglomerates: 260,
    miscellaneous: 900,
    mutualFunds: 540,
    metalsAndMining: 520,
    consumerProducts: 265,
    commercialPaper: 30,
    treasuryBillsFlat: 40,
    corporateBonds: 50,
    mortgageBackedBonds: 55,
    govermentBonds: 60,
    municipalBonds: 70,
    municipalBondsPut: 75,
    preferredStocks: 80,
    aerospace: 100,
    advertising: 102,
    airTransport: 110,
    aluminum: 120,
    autoParts: 130,
    banks: 150,
    beveragesBrewers: 160,
    beveragesDistillers: 170,
    beveragesSoftDrinks: 180,
    biotechnology: 185,
    brokerageFirms: 200,
    buildingMaterials: 210,
    businessServices: 215,
    cement: 220,
    chemicalDiversified: 230,
    chemicalSpecialties: 231,
    coal: 240,
    copper: 250,
    containersMetalGlass: 270,
    containersPlastic: 280,
    cosmetics: 290,
    dataProcessing: 300,
    drugs: 310,
    electricEquipment: 320,
    ermergingGrowth: 325,
    entertainment: 330,
    finance: 340,
    fertilizers: 350,
    foods: 360,
    foodServiceAndVending: 370,
    forestProducts: 380,
    gaming: 385,
    healthCareFacilities: 390,
    homeFurnishings: 400,
    hospitalSupplies: 410,
    hotelsMotels: 420,
    householdAppliances: 430,
    industrialServices: 435,
    instrumentation: 440,
    insurance: 450,
    investmentCompanies: 460,
    leisureTime: 470,
    machinery: 480,
    machineTools: 490,
    manufacturing: 500,
    metalsFabricating: 510,
    mobileHomes: 530,
    officeEquipment: 550,
    oilAndGas: 560,
    oilService: 570,
    paper: 580,
    photography: 590,
    printingAndPublishing: 600,
    railroads: 610,
    railroadEquipment: 620,
    realEstate: 630,
    restaurants: 640,
    retailStores: 650,
    retailFoodChains: 660,
    retailDrugChains: 670,
    retailMerchandising: 680,
    savingsAndLoan: 685,
    shoes: 690,
    soaps: 700,
    steels: 710,
    telecommunications: 720,
    textiles: 730,
    tiresAndRubber: 740,
    tobacco: 750,
    toys: 760,
    transportation: 770,
    trucking: 780,
    utilitiesElectric: 790,
    utilitiesGas: 800,
    utilitiesOther: 810,
    options: 910
}
