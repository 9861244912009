// @ts-strict-ignore
export interface SecurityChart {
    symbol: string;
    data: SecurityChartData[];
}

export class SecurityChartData {
    ask?: number;
    bid?: number;
    change?: number;
    changeOverTime?: number;
    changePercent?: number;
    close?: number;
    date?: Date | string; // Might not be a date, maybe a UTC string I guess??
    extendedChange?: number;
    extendedChangePercent?: number;
    extendedPrice?: number;
    extendedPriceTime?: number;
    high?: number;
    isUpdate?: boolean;
    lastTradeTime?: number;
    latestPrice?: number;
    latestVolume?: number;
    low?: number;
    open?: number;
    unadjustedClose?: number;
    unadjustedHigh?: number;
    unadjustedLow?: number;
    unadjustedOpen?: number;
    unadjustedVolume?: number;
    volume?: number;

    // Stuff we added on the front-end
    timestamp?: number;

    // Required properties for use with lightweight-charts
    time?: number;
    value?: number;

    static ManyFromApiData(points: SecurityChartData[]): SecurityChartData[] {
        const out = points.map((p) => ({ ...p, date: new Date(p.date).toUTCString(), timestamp: new Date(p.date).getTime() }));
        out.sort((a, b) => b.timestamp - a.timestamp);
        return out;
    }
}
