// @ts-strict-ignore
import { ReduxAction } from "phoenix/models"
import { Actions } from "../actions"
import { WhitelabelState } from "../models/Whitelabels/WhitelabelState"

export const WhitelabelReducer = (state: WhitelabelState = new WhitelabelState(), action: ReduxAction): WhitelabelState => {
    switch (action.type) {
        case Actions.Whitelabel.GetSettings.Loading: return { ...state, settings: state.settings.startLoading() }
        case Actions.Whitelabel.GetSettings.Success: return { ...state, settings: state.settings.succeeded(action.data) }
        case Actions.Whitelabel.GetSettings.Failure: return { ...state, settings: state.settings.failed(action.error) }
        default: return state
    }
}
