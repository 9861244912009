export type ChartRange = '1d' | '24h' | '5d' | '1w' | '1m' | '3m' | '6m' | '1y' | '5y' | 'prevday' | 'All';

export const ChartRanges: Record<string, ChartRange> = {
    oneDay: '1d',
    twentyFourHours: '24h',
    fiveDays: '5d',
    oneWeek: '1w',
    oneMonth: '1m',
    threeMonths: '3m',
    sixMonths: '6m',
    oneYear: '1y',
    fiveYears: '5y',
    all: 'All'
};

// Figure out the correct ammount for these new fields.
export const ChartSecondOffsetsByRange = {
    [ChartRanges.twentyFourHours]: 600,
    [ChartRanges.oneDay]: 600,
    [ChartRanges.fiveDays]: 4500,
    [ChartRanges.oneMonth]: 40000,
    [ChartRanges.threeMonths]: 100000,
    [ChartRanges.oneYear]: 604800,
    [ChartRanges.fiveYears]: 1209600
};

// TODO: Get actual business requirements for these - they are a guess based on current chart behavior
export const ChartMinuteOffsetsByRange = {
    [ChartRanges.twentyFourHours]: 1,
    [ChartRanges.oneDay]: 1,
    [ChartRanges.fiveDays]: 10,
    [ChartRanges.oneWeek]: 60,
    [ChartRanges.oneMonth]: 30,
    [ChartRanges.threeMonths]: 1440,
    [ChartRanges.sixMonths]: 1440,
    [ChartRanges.oneYear]: 1440,
    [ChartRanges.fiveYears]: 1440
};

export const DetermineChartKey = (range: ChartRange, res: 'hi' | 'lo' = 'lo') => {
    if ([ChartRanges.oneDay, ChartRanges.twentyFourHours].includes(range) && res === 'lo') return 'loRes';
    else return range;
};
