import { AuthorizationSettingValues } from 'phoenix/redux/models/AuthorizationSettings/AuthorizationSetting';
import { useSnexStore } from './UseSnexStore';
import { ResolveEnvironment } from 'phoenix/constants';
import { useEffect } from 'react';
import { GetAuthorizationSettings } from 'phoenix/redux/actions/AuthorizationSettingsActions';
import { useDispatch } from 'react-redux';

export interface UseAuthorizationSettings {
    isLoading: boolean;
    data: AuthorizationSettingValues | undefined;
}

export const UseAuthorizationSettings = (): UseAuthorizationSettings => {
    let variant = ResolveEnvironment();
    const dispatch = useDispatch();
    if (variant.toLowerCase() === 'local') variant = 'test';
    const userSettings = useSnexStore((s) => s.authorizationSettings?.settings);
    const isLoading = userSettings?.pristine || userSettings?.loading;
    const data = (userSettings?.data || [])?.find((x) => x.env.toLowerCase() === variant.toLowerCase())?.settings;

    useEffect(() => {
        dispatch(GetAuthorizationSettings());
    }, [dispatch]);

    return { isLoading, data };
};
