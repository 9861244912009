import { create } from 'zustand';

type SidePanelStore = {
    expanded?: boolean;
    setExpanded: (bool: boolean) => void;
    openOrdersExpanded: boolean;
    setOpenOrdersExpanded: (bool: boolean) => void;
    futuresExpanded: boolean;
    setFuturesExpanded: (bool: boolean) => void;
    mutualFundsExpanded: boolean;
    setMutualFundsExpanded: (bool: boolean) => void;
    equitiesExpanded: boolean;
    setEquitiesExpanded: (bool: boolean) => void;
};

export default create<SidePanelStore>((set) => ({
    expanded: false,
    setExpanded: (bool: boolean) => set((s) => ({ ...s, expanded: bool })),
    openOrdersExpanded: false,
    setOpenOrdersExpanded: (bool: boolean) => set((s) => ({ ...s, openOrdersExpanded: bool })),
    futuresExpanded: false,
    setFuturesExpanded: (bool: boolean) => set((s) => ({ ...s, futuresExpanded: bool })),
    mutualFundsExpanded: false,
    setMutualFundsExpanded: (bool: boolean) => set((s) => ({ ...s, mutualFundsExpanded: bool })),
    equitiesExpanded: false,
    setEquitiesExpanded: (bool: boolean) => set((s) => ({ ...s, equitiesExpanded: bool }))
}));
