// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3x5WRoasBcKukrcOiyIaER{background:linear-gradient(to bottom right, #358cd8, #016FD0);height:300px;width:750px;border-radius:20px;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"wrapper": "_3x5WRoasBcKukrcOiyIaER"
};
module.exports = exports;
