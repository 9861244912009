import { useMemo } from 'react'
import { FeatureFlags } from '../constants/FeatureFlags'
import { GetProductByCode, PremiumProductMetadata, ProductCode, TierLevel } from '../constants/PremiumProducts'
import { useSnexStore } from './UseSnexStore'
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag'
import { getTierSettings } from 'phoenix/util/Tiers/GetTierSettings'

export interface TierInfo {
    tierLoading: boolean
    tierPristine: boolean
    isPreferred: boolean
    isTrial: boolean
    isFree: boolean
    isPaid: boolean
    tier: string | undefined // e.g., free, trial:sx1, preferred:mi+sx1
    tierPrefix: TierLevel,
    product: ProductCode | undefined,
    productMetadata: PremiumProductMetadata | undefined,
    permittedForDeepAnalysis: boolean,
    permittedForAlerts: boolean,
    preferredEnabledBySystemFlag: boolean,
    paymentAccountNumber: string | undefined
}


export const useTier = (): TierInfo => {
    const preferredAllowed = useSnexStore(s => s.featureFlags.byId[FeatureFlags.Preferred]?.enabled || false)
    const subApiData = useSnexStore(s => s.subscription.mySubscription)
    const sub = useMemo(() => subApiData?.data, [subApiData])
    const premiumEnrollmentFeatureFlag = useFeatureFlag(FeatureFlags.PremiumEnrollment);

    const { product, prefix, productMetadata } = useMemo(() => {
        const none = { product: undefined, prefix: sub?.tier, productMetadata: undefined }
        if (!sub?.tier) return none
        const cidx = sub.tier.indexOf(':')
        if (cidx <= 0) return none
        const parts = sub.tier.split(':')
        const meta = GetProductByCode(parts[1])
        return { product: parts[1], prefix: parts[0], productMetadata: meta || undefined}
    }, [sub])

    const permittedForAlerts = useMemo(() => premiumEnrollmentFeatureFlag ? sub?.tier !== 'free' : true, [premiumEnrollmentFeatureFlag, sub])
    return getTierSettings({
        pristine: subApiData.pristine,
        loading: subApiData.loading,
        isPreferredAllowed: !!preferredAllowed,
        hasPremiumEnrollmentFeatureFlag: !!premiumEnrollmentFeatureFlag,
        isPermittedForAlerts: !!permittedForAlerts,
        subTier: sub?.tier,
        subPaymentAccountNumber: sub?.paymentAccountNumber,
        prefix,
        product,
        productMetadata
    })
    
}
