import { Tooltip, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import React, { ReactNode } from 'react';

const symbolLabelStyles: React.CSSProperties = {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
    // fontWeight: 600,
    // fontSize: 16
};

export const SymbolLabel = (props: { children: string; tooltipText?: string }) =>
    props.tooltipText ? (
        <Tooltip title={props.tooltipText}>
            <Typography style={symbolLabelStyles} variant='h5'>
                {props.children}
            </Typography>
        </Tooltip>
    ) : (
        <Typography style={symbolLabelStyles} variant='h5'>
            {props.children}
        </Typography>
    );

export const CaptionLabel = (props: { children: ReactNode }) => (
    <Typography style={{ width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'capitalize' }} variant='h6'>
        {props.children}
    </Typography>
);

export const DescriptionLabel = (props: { children: string }) => {
    const { grayText } = useColors();
    return (
        <Typography
            style={{ width: '100%', color: grayText, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'capitalize' }}
            variant='body1'
        >
            {props.children}
        </Typography>
    );
};
