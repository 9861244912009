// @ts-strict-ignore
import React from 'react';
import { LinearProgress } from '@mui/material';
import { Card } from 'components';
import './DynamicModal.scss';

export type DynamicModalSize = 'closed' | 'small' | 'expanded' | 'tucked' | 'fullscreen';

export default React.memo(
    ({
        align = 'left',
        children,
        expandedWidth = '50%',
        loading = false,
        open,
        setSize,
        size
    }: {
        align?: 'left' | 'right';
        children: JSX.Element | JSX.Element[];
        expandedWidth?: string;
        loading?: boolean;
        open: boolean;
        setSize?: (size: DynamicModalSize) => void;
        size?: DynamicModalSize;
    }) => {
        const subclass = ['fullscreen', 'small', 'closed'].includes(size) ? `${size}` : `${align}-${size}`;
        return (
            <div
                className={`dynamic-modal ${!open ? `dynamic-modal-closed${align? '-' + align : ''} ` : ''}dynamic-modal-${subclass}`}
                style={{ '--expandedWidth': expandedWidth } as React.CSSProperties}
            >
                <Card>
                    <LinearProgress variant='indeterminate' style={{ visibility: loading ? 'visible' : 'hidden', borderRadius: '4px 4px 0 0' }} />
                    <div onClick={() => size === 'tucked' && setSize('expanded')}>{children}</div>
                </Card>
            </div>
        );
    }
);
