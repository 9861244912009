import { Skeleton, SvgIcon, Typography } from '@mui/material';
import { AddRounded, MoreHoriz } from '@mui/icons-material';
import React, { FocusEvent, ReactElement, useState } from 'react';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { Flex, Spinny, StyledIcon } from '../';
import style from './style.module.scss';
import { TabSelect } from '../TabSelect';
import { useColors } from 'hooks/UseColors';
import { SnexPopover } from 'components/Popovers/SnexPopover';

interface ListHeaderProps {
    title: string | JSX.Element;
    loading?: boolean;
    headerFontSyle?: React.CSSProperties;
    headerContainerStyle?: React.CSSProperties;
    collapsable?: boolean;
    isAddOpen?: boolean;
    moreMenuItems?: {
        label: string;
        iconComponent?: typeof SvgIcon;
        options: { label: string; value: string }[];
        storageKey: string;
        isSortable?: boolean;
    }[];
    // TODO -- Get rid of this one
    onOpenToggle?: (nextValue: boolean) => void;
    isCollapsed?: boolean;
    onCollapseExpand?: (nextState: boolean) => void;
    horizontalPopover?: 'left' | 'right';
}

export const ListHeader = (props: ListHeaderProps): ReactElement => {
    const {
        title,
        headerContainerStyle = {},
        collapsable = false,
        isAddOpen = false,
        onOpenToggle,
        isCollapsed = false,
        onCollapseExpand = () => undefined,
        moreMenuItems = [],
        loading,
        horizontalPopover = 'right'
    } = props;

    const colors = useColors();
    const [isHeaderFocused, setIsHeaderFocused] = useState(false);
    const [isHeaderHovered, setIsHeaderHovered] = useState(false);
    const [anchor, setAnchor] = React.useState<{ left?: number; right?: number; top?: number }>({ left: 0, right: 0, top: 0 });
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    const shouldRenderMoreMenuIcon = moreMenuItems?.length > 0;
    const shouldDisplayMoreMenuIcon = isHeaderFocused || isHeaderHovered || popoverOpen;

    const handleMenuOpen = (event: React.MouseEvent | React.KeyboardEvent) => {
        const { top } = event.currentTarget.getBoundingClientRect();
        setAnchor({ right: -75, top });
        setPopoverOpen(true);
    };

    return onOpenToggle ? (
        <Flex align='center' className={style.header} justify='space-between' style={{ ...headerContainerStyle }} wrap={false}>
            <button className={style.listIconWrapper} onClick={() => onOpenToggle(!isAddOpen)}>
                {typeof title === 'string' ? (
                    <Typography className={style.headerTitle} variant='h5'>
                        {title}
                    </Typography>
                ) : (
                    title
                )}
                <StyledIcon IconComponent={AddRounded} style={{ transform: isAddOpen ? 'rotate(45deg)' : 'rotate(0deg)', color: colors.fadedTextColor }} />
            </button>
        </Flex>
    ) : (
        <Flex align='center' className={style.header} justify='space-between' style={{ ...headerContainerStyle }}>
            <button
                onClick={() => onCollapseExpand(!isCollapsed)}
                onFocus={() => {
                    setIsHeaderFocused(true);
                    setPopoverOpen(false);
                }}
                onBlur={() => setIsHeaderFocused(false)}
                onMouseEnter={() => setIsHeaderHovered(true)}
                onMouseLeave={() => setIsHeaderHovered(false)}
                data-watchlist={title}
                id={`watchlist-${title}`}
                style={{ ...headerContainerStyle }}
            >
                {loading ? (
                    <Skeleton animation='wave' width={100} />
                ) : (
                    <Typography className={style.headerTitle} variant='h5'>
                        {title}
                    </Typography>
                )}
            </button>
            <div className={style.actionsWrapper}>
                {shouldRenderMoreMenuIcon && (
                    <button
                        onFocus={() => setIsHeaderFocused(true)}
                        onBlur={() => setIsHeaderFocused(false)}
                        onMouseEnter={() => setIsHeaderHovered(true)}
                        onMouseLeave={() => setIsHeaderHovered(false)}
                        onClick={handleMenuOpen}
                        type='button'
                    >
                        <StyledIcon IconComponent={MoreHoriz} style={{ fill: shouldDisplayMoreMenuIcon ? colors.fadedTextColor : 'none' }} />
                    </button>
                )}
                {!!moreMenuItems?.length && (
                    <SnexPopover
                        onClose={() => {
                            setIsHeaderFocused(false);
                            setPopoverOpen(false);
                        }}
                        open={popoverOpen}
                        paperStyles={{ maxWidth: '160px' }}
                        right={horizontalPopover === 'right' ? anchor.right : undefined}
                        left={horizontalPopover === 'left' ? anchor.left : undefined}
                        top={anchor?.top}
                    >
                        {moreMenuItems.map((item, key) => (
                            <ListOptionSection
                                IconComponent={item.iconComponent}
                                isSortable={item.isSortable}
                                key={key}
                                label={item.label}
                                onBlur={(e) => {
                                    if ((e?.relatedTarget as HTMLElement).tagName !== 'BUTTON' || (e?.relatedTarget as HTMLElement).dataset.watchlist) {
                                        setIsHeaderFocused(false);
                                        setPopoverOpen(false);
                                    }
                                }}
                                options={item.options}
                                storageKey={item.storageKey}
                            />
                        ))}
                    </SnexPopover>
                )}
                {collapsable && (
                    <button
                        onClick={() => onCollapseExpand(!isCollapsed)}
                        onMouseEnter={() => setIsHeaderHovered(true)}
                        onMouseLeave={() => setIsHeaderHovered(false)}
                        tabIndex={0}
                    >
                        <Spinny spun={isCollapsed} />
                    </button>
                )}
            </div>
        </Flex>
    );
};

interface ListOptionSection {
    onBlur?: (e?: FocusEvent<HTMLDivElement>) => void;
    storageKey: string;
    label: string;
    IconComponent?: typeof SvgIcon;
    options: { label: string; value: string }[];
    isSortable?: boolean;
}

const ListOptionSection = (props: ListOptionSection) => {
    const { storageKey, options, IconComponent, label } = props;
    const [selected, setSelected] = useLocalStorage(storageKey, 'price');
    const { boxShadowLight, primaryItemColor } = useColors();

    return (
        <Flex column onBlur={props.onBlur} style={{ padding: 8 }}>
            <Flex align='center' justify='flex-start'>
                {IconComponent && <StyledIcon IconComponent={IconComponent} />}
                <Typography variant='h6'>{label}</Typography>
            </Flex>
            <TabSelect
                buttons
                buttonStyle={{
                    button: { padding: 7, fontSize: 15 },
                    active: { color: 'white', backgroundColor: primaryItemColor },
                    inactive: { color: primaryItemColor, backgroundColor: boxShadowLight }
                }}
                defaultSelected={selected}
                isSortable={props.isSortable}
                style={{ margin: '5 0' }}
                values={options}
                onSelect={(value) => setSelected(value.toString())}
            />
        </Flex>
    );
};
