// @ts-strict-ignore
import { Button, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { Urls } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { SnexError } from 'phoenix/models/SnexError';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { useMessagesStore } from 'phoenix/stores/MessagesStore';
import { GroupBySelectFirst } from 'phoenix/util';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';
import { MessageRow } from 'screens/MessagesScreen/MessageRow';
import { GoToLogin } from 'util/GoToLogin';
import { Flex } from '../Flex';
import { shouldShowToast } from './helpers';

export const ToastEmitter = () => {
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const text = useText((s) => s.toasts);
    // Latest Messages
    const { latestMessage, systemControls, getSystemMessageControls } = useMessagesStore();

    const messageControlLookup = useMemo(() => GroupBySelectFirst(systemControls, (c) => c.messageType), [systemControls]);

    // Error Handling
    const latestErrors = useSelector((s: GlobalState) => s.errors.apiErrors.latest);
    const ErrorToast = () => (
        <Flex column>
            <Typography variant='h6'>{text.error}</Typography>
            <Typography variant='body1'>
                {latestErrors?.errorMessage || '---'}
                {latestErrors?.errorId ? ` (#${latestErrors?.errorId})` : null}
            </Typography>
        </Flex>
    );

    const UnauthErrorToast = () => {
        const { primaryItemColor } = useColors();
        return (
            <Flex column>
                <Typography variant='h6'>{text.sessionExpired}</Typography>
                <Flex center column style={{ height: 70 }}>
                    <Button
                        style={{ backgroundColor: primaryItemColor, color: 'white', paddingLeft: 20, paddingRight: 20 }}
                        variant='contained'
                        onClick={() => GoToLogin()}
                    >
                        {text.logInAgain}
                    </Button>
                </Flex>
            </Flex>
        );
    };

    useEffect(() => {
        if (latestErrors?.display === 'toast') {
            if (latestErrors?.errorCode === 'UNAUTH_RELOGIN') addToast(<UnauthErrorToast />, { appearance: 'info', autoDismiss: false });
            else addToast(<ErrorToast />, { appearance: 'error' });
        }
    }, [latestErrors]);

    useEffect(() => {
        const run = async () => {
            await getSystemMessageControls();
        };
        run();
    }, [dispatch, getSystemMessageControls, latestMessage]);

    const showToast = useCallback(
        (message?: SnexMessage) => {
            const evs = message ? messageControlLookup[message?.payloadType]?.events : undefined;
            return shouldShowToast(evs, message);
        },
        [messageControlLookup]
    );

    useEffect(() => {
        LogMessageReceived(latestMessage);
        if (!showToast(latestMessage)) return;
        const appearance: AppearanceTypes = ((): AppearanceTypes => {
            switch (latestMessage.disposition) {
                case 'urgent':
                    return 'warning';
                case 'error':
                    return 'error';
                case 'normal':
                default:
                    return 'info';
            }
        })();

        const handleClick = () => {
            if (latestMessage.appLink) navigate(latestMessage.appLink);
        };

        addToast(<MessageRow dense toast message={latestMessage} onClick={handleClick} />, { appearance });
    }, [latestMessage]);

    return null;
};

const LogMessageReceived = (snexMessage: SnexMessage) => {
    if (!snexMessage) return;
    if (!snexMessage?.correlationId) {
        ErrorLogger.RecordError({ name: 'Missing correlation id', message: 'The snexMessage is missing the correlation id' }, 'FAILED_TO_LOG_MESSAGE_RECEIVED', {
            info: { ...snexMessage }
        });
    } else {
        SnexAxios.ApiPost(Urls.messages.logReceipt(snexMessage?.correlationId), { data: snexMessage })
            .run()
            .catch((error: SnexError) => {
                ErrorLogger.RecordError({ name: error.errorTitle, message: error.errorMessage }, 'FAILED_TO_LOG_MESSAGE_RECEIVED', {
                    info: { ...snexMessage, ...error }
                });
            });
    }
};
