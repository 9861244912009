import { ReduxAction } from 'phoenix/models/ReduxAction';
import { createStore, Reducer, Store, StoreEnhancer } from 'redux';
import { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { GlobalState } from './GlobalState';
import { rootReducer } from './RootReducer';

/**
 * Similar to {@link Reducer} but (1) more specifically typed, and (2) the state parameter is never undefined.
 */
export type SnexReducer = (state: GlobalState, action: ReduxAction) => GlobalState;

export type SnexThunkMiddleware = ThunkMiddleware<GlobalState, ReduxAction, undefined>;

export type SnexThunkAction<ReturnType> = ThunkAction<ReturnType, GlobalState, undefined, ReduxAction>;

export type SnexThunkDispatch = ThunkDispatch<GlobalState, undefined, ReduxAction>;

// e.g., CreateStore(compose(applyMiddleware(thunk)))
// e.g., CreateStore(compose(applyMiddleware(thunk, createLogger({ collapsed: true }))))
export function CreateStore<Ext>(enhancerFactory: () => StoreEnhancer<Ext>, customRootReducer?: SnexReducer): Store<GlobalState, ReduxAction> & Ext {
    const defaultRootReducer: SnexReducer = rootReducer;
    const realRootReducer = customRootReducer ?? defaultRootReducer;
    return createStore(realRootReducer as Reducer<GlobalState, ReduxAction>, {} as GlobalState, enhancerFactory());
}
