import { Flex } from 'components/Flex';
import { useColors } from 'hooks/UseColors';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { useGlobalMessageStore } from './store';

export const GlobalMessageBanner = (): ReactElement | null => {
    const { message } = useGlobalMessageStore();
    const isShown = useMemo(() => !!message, [message]);
    const colors = useColors();
    const bannerRef = useRef<HTMLDivElement>(null);
    const { setHeight } = useGlobalMessageStore();

    useEffect(() => {
        setHeight(bannerRef?.current?.offsetHeight || 0);
    }, [setHeight]);

    return isShown ? (
        <Flex
            ref={bannerRef}
            id={'global-message'}
            row
            center
            style={{
                padding: 16,
                backgroundColor: '#ffc107',
                backdropFilter: 'blur(10px)',
                borderRadius: 8,
                color: colors.black,
                fontSize: '16px',
                boxSizing: 'border-box',
                margin: '8px 0',
                width: '100%'
            }}
        >
            {message}
        </Flex>
    ) : null;
};
