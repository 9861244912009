import { Snex1LanguagePack } from "phoenix/assets/lang/Snex1LanguagePack";
import { PriceVariant } from "phoenix/models/AssetClasses/AssetClass";
import { OptionSymbol } from "phoenix/redux/models";
import { SafeFormat } from "phoenix/util"

export const formatOptionSymbolName = (optSym: OptionSymbol, lang: Snex1LanguagePack, strike: string, variant: PriceVariant) => {
    const putCallWord = optSym.putCall === 'P' ? lang.general.options.put : lang.general.options.call;

    switch (variant) {
        case 'no-date-short':
            return `${ optSym.underlyingSymbol } ${ strike } ${ putCallWord }`
        case 'extra-short':
            return `${ optSym.underlyingSymbol } ${ putCallWord } ${lang.general.productTypes.option.upperSingular}`
        case 'short':
            return `${ optSym.underlyingSymbol } ${ strike } ${ putCallWord } ${ SafeFormat(optSym.expDate, 'MM/dd') }`
        case 'full':
        default:
            return `${ optSym.underlyingSymbol } ${ strike } ${ putCallWord } Exp. ${ SafeFormat(optSym.expDate, 'MM/dd/yy') }`
    }
}

export const formatOptionExpiration = (symbol: string) => {
    const optSym = new OptionSymbol(symbol);
    return `Exp ${SafeFormat(optSym.expDate, 'dd MMM yyyy')}`
}