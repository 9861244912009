import { Fill, Order } from 'phoenix/redux/models';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from 'phoenix/redux/models/Messages/SnexOrderUpdateMessagePayload';
import { useOrdersStore } from './OrdersStore';

export const matchOrder = (order: Partial<Order>, updatedOrder: Partial<Order>) => {
    const orderIdMatch = order?.orderId && updatedOrder?.orderId && order?.orderId === updatedOrder?.orderId;
    // Color palette order updates lack order id
    const orderNumberMatch = order?.orderNumber && updatedOrder?.orderNumber && order?.orderNumber === updatedOrder?.orderNumber;
    return !!orderIdMatch || !!orderNumberMatch;
};

export const updateOrder = (order: Order, update: Partial<Order>): Order => {
    const isUpdatePartialFill = update?.leavesQuantity && update?.leavesQuantity > 0 && order.leavesQuantity && order.leavesQuantity > update.leavesQuantity;
    const isOrderPartiallyFilled = order.leavesQuantity && order.leavesQuantity !== order.orderQuantity;

    // Delete fields we want to exclude from merge of update
    const preparedUpdate = Object.entries(update)
        .filter(([key, value]) => value !== undefined)
        .filter(([key, value]) => value !== null)
        .filter(([key, value]) => !(key === 'fills' && !(value as Fill[])?.length)) // if the fills array is empty delete it too.
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const updatedOrder = {
        ...order,
        ...preparedUpdate,
        ...(isUpdatePartialFill ? { orderStatus: 'Open' } : {}) // Partial fills come back from color palette with a 'Filled' status, change to 'Open' but only if the update does not complete the fills.
    };

    // Switch from limit to stop price if update changes order type
    // Update is limit order
    if (update.limitPrice && !update.stopPrice && order.stopPrice && !order.limitPrice) {
        updatedOrder.limitPrice = update.limitPrice;
        delete updatedOrder.stopPrice;
    }
    // Update is stop order
    if (update.stopPrice && !update.limitPrice && order.limitPrice && !order.stopPrice) {
        updatedOrder.stopPrice = update.stopPrice;
        delete updatedOrder.limitPrice;
    }

    // We don't get fills from color pallete but we do get last fill info
    if ((isUpdatePartialFill || isOrderPartiallyFilled) && update?.lastFilledQuantity && order?.fills && updatedOrder?.fills?.length === order?.fills?.length) {
        const newFill: Fill = {
            symbol: order?.symbol,
            quantity: update?.lastFilledQuantity,
            price: update?.filledPrice ?? 0,
            sequenceNumber: order?.fills?.length + 1,
            timestamp: update?.completedDate ?? new Date().toISOString()
        };

        updatedOrder.fills.push(newFill);
        const pricesAsCentsAvg = Math.round(updatedOrder.fills.map((f) => f.price * 100).reduce((acc, n) => acc + n) / updatedOrder.fills.length);
        updatedOrder.averageTradePriceAmount = pricesAsCentsAvg / 100;
    }

    // remove duplicate fills
    const uniqueFills = updatedOrder.fills.reduce((acc, fill) => {
        if (!acc.find((f) => f.timestamp === fill.timestamp)) {
            acc.push(fill);
        }
        return acc;
    }, [] as Fill[]);

    return { ...updatedOrder, fills: uniqueFills };
};

export const withUpdateOrder = (orders: Order[], update: Order, orderNumber?: string) => {
    if (!update) return;

    const preparedUpdate: Order = {
        ...update,
        orderNumber: orderNumber || update.orderNumber,
        canCancel: update.isCancelable || update.canCancel
    };

    return findAndUpdateOrder(orders, preparedUpdate);
};

export const findAndUpdateOrder = (orders: Order[], updatedOrder: Order): Order[] => {
    const idx = orders.findIndex((o) => matchOrder(o, updatedOrder));
    const updatedOrders = [...orders];
    if (idx === -1) return orders;

    updatedOrders[idx] = updateOrder(orders[idx], updatedOrder);

    return updatedOrders;
};

export const isMessageRelevant = (payload: SnexMessage): boolean => {
    const updatedOrder = payload?.data as SnexOrderUpdateMessagePayload & Order;
    const isNotOrderUpdate = !payload?.payloadType?.includes('OrderUpdate') && updatedOrder?.type !== 'OrderUpdate';
    return !isNotOrderUpdate;
};
