// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XCU3MndjNeESNMNcaTqBN{transition:200ms;margin-left:5}._2syRvpgw6ePBiesqL-BtWb{color:var(--generalTextColor)}._2syRvpgw6ePBiesqL-BtWb:hover ._3XCU3MndjNeESNMNcaTqBN{transform:translateX(-5px)}", ""]);
// Exports
exports.locals = {
	"backButton": "_3XCU3MndjNeESNMNcaTqBN",
	"wrapperSection": "_2syRvpgw6ePBiesqL-BtWb"
};
module.exports = exports;
