import { Urls } from '../../constants';
import { Actions, ReduxApiGet, ReduxApiResponse } from '.';
import { Authorize } from '../models/Startup/Authorize';
import { UserAuthorization, UserAuthorizationType } from '../models/Login/UserAuthorization';
import { SnexError } from 'phoenix/models/SnexError';

export const GetStartupAuthorizationAction = (
    options?: Partial<{
        skipRedirectCheck: boolean;
        hasOnboarded?: boolean;
    }>
): ReduxApiResponse<Authorize> =>
    ReduxApiGet<Authorize, UserAuthorizationType>(Urls.startup.authorize(options), Actions.Startup.Authorize)
        .onSuccess((res: UserAuthorizationType) => {
            const newState: Authorize = {
                userAuthorization: res
            };

            return newState;
        })
        .onError((err: unknown) => {
            if ((err as SnexError)?.errorCode === '401') {
                return { userAuthorization: UserAuthorization.GoToLogin };
            } else {
                return { userAuthorization: UserAuthorization.Denied };
            }
        })
        .run(true);
