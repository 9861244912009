const _throttles: { [key: string]: number } = { }

export const ThrottleManager = {

    bounce: (key: string, minWait: number): boolean => {

        const now = new Date().getTime()

        if (!_throttles[key] || ((now - _throttles[key]) > minWait)) {
            _throttles[key] = now
            return true
        }

        return false
    }

}