import { Card } from 'components';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { ReactElement, useEffect } from 'react';

export const MattDebugScreen = (): ReactElement => {
    const xDispatch = useXstreamDispatch();
    const symbol = 'X:BTC';
    const ns = LiveDataNamespaces.SecurityScreenPrimarySymbol;

    useEffect(() => {
        xDispatch(XS.Quotes.start(symbol, ns));

        return () => XS.stopNs(ns);
    }, [ns, xDispatch]);

    return (
        <Card>
            <div />
        </Card>
    );
};
