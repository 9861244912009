import { IPriceLine, PriceLineOptions } from 'lightweight-charts';
import { useContext, useEffect, useRef, useState } from 'react';
import { LightweightSeriesApiContext } from './LightweightSeries';

type LightweightPriceLineProps = {
    options: PriceLineOptions;
};

// TODO This seems like a lot of extra heavy lifting if all we want to do is show the price line for a given series

/**
 * Represents a *custom* price line, created by seriesApi.createPriceLine().
 * Not to be confused with the default price line that is created by setting priceLineVisible = true on a series.
 * @param props
 * @returns
 */
export const LightweightPriceLine = (props: LightweightPriceLineProps): null => {
    const { options } = props;

    const seriesApi = useContext(LightweightSeriesApiContext);

    // custom price lines are not partial, so we don't need to merge with defaults
    const initialOptionsRef = useRef(options);

    const [priceLineApi, setPriceLineApi] = useState<IPriceLine | null>(null);

    // create price line
    useEffect(() => {
        if (seriesApi !== null) {
            const newPriceLine = seriesApi.createPriceLine(initialOptionsRef.current);
            setPriceLineApi(newPriceLine);

            return () => {
                seriesApi.removePriceLine(newPriceLine);
                setPriceLineApi(null);
            };
        }
    }, [seriesApi]);

    // apply changes in options to price line
    useEffect(() => {
        if (priceLineApi !== null) {
            priceLineApi.applyOptions(options);
        }
    }, [options, priceLineApi]);

    return null;
};
