// @ts-strict-ignore
import { AccountChartPoint, AccountSummary, OptionQuote, SecurityChartData } from 'phoenix/redux/models';
import { SecurityDom } from 'phoenix/redux/models/Securities/SecurityDom';
import { XstreamDomSubscribeAction, XstreamDomUpdateActionName } from 'phoenix/xstream/actions/XstreamDomActions';
import { StandardQuote } from '../constants/ReduxSelectors';
import { TicketManager } from '../util/TicketManager';
import {
    XstreamAccountChartUpdateActionName,
    XstreamPortfolioChartUpdateActionName,
    XstreamSubscribeToLiveAccountChartAction,
    XstreamSubscribeToLivePortfolioChartAction
} from './actions/XstreamAccountActions';
import { XstreamFutureOptionChainSubscribeAction, XstreamOptionQuoteUpdateActionName, XstreamOptionSubscribeAction } from './actions/XstreamOptionActions';
import { XstreamQuoteSubscribeAction, XstreamQuoteUpdateActionName } from './actions/XstreamQuoteActions';
import { useAccountStreamAndSnapshot } from './hooks/useAccountStreamAndSnapshot';
import { useStreamAndSnapshot } from './hooks/useStreamAndSnapshot';
import { useXstreamStore } from './useXstreamStore';

type XstreamOptions = {
    updateSnapshots?: boolean; // Use if snapshot source may be more up-to-date than current stream data
};

// Please adhere to rules of hooks when adding new 'use' functions
const useQuoteStream = (qsi: string, { updateSnapshots = false }: XstreamOptions = {}): StandardQuote =>
    useStreamAndSnapshot<StandardQuote>(
        (s) => {
            const d = s.securityQuote.bySymbol[qsi]?.data;
            if (!d) return null;
            const q: StandardQuote = {
                ...d,
                price: d.latestPrice || d.price || d.ask || d.bid,
                change: d.change,
                changePercent: d.changePercent,
                high: d.high,
                low: d.low,
                week52Low: d.week52Low,
                week52High: d.week52High,
                open: d.open,
                close: d.close,
                previousClose: d.previousClose || d.close,
                bid: d.bid,
                ask: d.ask,
                volume: d.volume,
                lastTradeTime: d.lastTradeTime,
                companyName: d.companyName,
                unitFactor: d.unitFactor,
                pristine: false,
                error: null,
                loading: false
            };
            return q;
        },
        (s) => s.quotes[qsi],
        qsi,
        dataIsUsableWithoutPrice,
        false,
        updateSnapshots
    );

const useOptionQuoteStream = (osi: string, { updateSnapshots }: XstreamOptions = {}): OptionQuote & { loading?: boolean } =>
    useStreamAndSnapshot<OptionQuote & { loading?: boolean }>(
        (s) => {
            const d = s.options.quotesByOsi[osi]?.data;
            return d ? { ...d, changePercent: d?.percentChange, price: d?.price || d?.last || d?.ask || d?.bid } : null;
        },
        (s) => {
            const truePrice = s?.options[osi]?.price || s?.options[osi]?.last || s?.options[osi]?.ask || s?.options[osi]?.bid;
            if (!truePrice) return undefined;
            // Only return a new object if we need to assign a price, otherwise we risk infinite rerenders
            return s.options[osi]?.price ? s.options[osi] : { ...s.options[osi], price: truePrice };
        },
        osi,
        dataIsUsableWithoutPrice,
        false,
        updateSnapshots
    );

const useSecurityChartStream = (qsi: string): SecurityChartData[] => useXstreamStore((s) => s.streamingCharts[qsi]);
const useDomStream = (qsi: string): SecurityDom => useXstreamStore((s) => s.dom[qsi]);
const useAccountValuationStream = (accountNumber: string): AccountSummary => useAccountStreamAndSnapshot(accountNumber);
const usePortfolioValuationStream = (): AccountSummary => useAccountStreamAndSnapshot();
const useAccountChartStream = (accountNumber: string): AccountChartPoint[] => useXstreamStore((s) => s.streamingAccountCharts[accountNumber]);
const usePortfolioChartStream = (): AccountChartPoint[] => useXstreamStore((s) => s.streamingPortfolioChart);

const dataIsUsableWithoutPrice = (q: StandardQuote | OptionQuote) => q && (!!q?.price || !!q?.last || !!q?.ask || !!q?.bid || !!q?.close || !!q?.previousClose);
export const XS = {
    stop: (ticket: string): Promise<void> => TicketManager.CloseTicket(ticket),
    stopNs: (namespace: string): void => TicketManager.CloseNamespace(namespace),

    Quotes: {
        actionName: XstreamQuoteUpdateActionName,
        use: useQuoteStream,
        start: XstreamQuoteSubscribeAction
    },
    SecurityCharts: { use: useSecurityChartStream },

    OptionQuotes: {
        actionName: XstreamOptionQuoteUpdateActionName,
        use: useOptionQuoteStream,

        /** @important Common Gotcha: make sure you xdispatch (from useXstreamDispatch) this! */
        start: XstreamOptionSubscribeAction,
        startFuturesChain: XstreamFutureOptionChainSubscribeAction
    },

    Dom: {
        actionName: XstreamDomUpdateActionName,
        use: useDomStream,
        start: XstreamDomSubscribeAction
    },

    AccountValuations: {
        actionName: XstreamAccountChartUpdateActionName,
        use: useAccountValuationStream,
        start: XstreamSubscribeToLiveAccountChartAction
    },

    PortfolioValuation: {
        actionName: XstreamPortfolioChartUpdateActionName,
        use: usePortfolioValuationStream,
        start: XstreamSubscribeToLivePortfolioChartAction
    },

    AccountCharts: {
        use: useAccountChartStream
    },

    PortfolioCharts: {
        use: usePortfolioChartStream
    }

    // Please add new keys in PascaleCase
};
