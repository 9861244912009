import { Snex1English } from 'phoenix/assets/lang/Snex1English';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { Snex1Portuguese } from 'phoenix/assets/lang/Snex1Portuguese';
import { Snex1Spanish } from 'phoenix/assets/lang/Snex1Spanish';
import { Snex1Mandarin } from 'phoenix/assets/lang/Snex1Mandarin';
import { GetConfig } from 'phoenix/constants';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { FormatString } from 'phoenix/util';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createClearable as create } from 'phoenix/stores/ZustandStore'

import { Snex1German } from 'phoenix/assets/lang/Snex1German';

interface TextActions {
    setText: (text: Snex1LanguagePack) => void;
    getText: <TSection>(selector: (languagePack: Snex1LanguagePack) => TSection) => TSection;
    reset: () => void;
    setWithLanguageCheck: (withLanguageCheck: boolean) => void;
    updateTextByLanguageCode: (language: string | undefined) => void;
    getTextString: (
        select: (l: Snex1LanguagePack) => string,
        opts?: Partial<{
            case: 'title' | 'sentence' | 'lower' | 'default';
        }>
    ) => string;
    checkLanguageAndUpdateText: () => void;
}

interface TextState extends TextActions {
    text: Snex1LanguagePack;
    withLanguageCheck: boolean;
}

export const useTextStore = create()<TextState>((set, get) => ({
    text: Snex1English,
    withLanguageCheck: false,

    setWithLanguageCheck: (value?: boolean) => set({ withLanguageCheck: value }),

    setText: (text: Snex1LanguagePack) => set({ text }),
    getText: (selector) => {
        const { withLanguageCheck, text, checkLanguageAndUpdateText } = get();
        if (withLanguageCheck) checkLanguageAndUpdateText();
        return selector(text);
    },
    reset: () => set({ text: Snex1English }),

    checkLanguageAndUpdateText: () => {
        const language = GetConfig()?.Store?.getState().user.myPrefs.data?.preference?.language?.code;
        if (language) get().updateTextByLanguageCode(language);
    },

    updateTextByLanguageCode: async (language: string | undefined) => {
        let text = get().text;

        if (!language && GetConfig()?.AuthenticationScheme === 'jwt') language = 'en-US';

        if (text?.general?.localeLong === language) return;

        switch (language) {
            case 'es-US':
                text = { ...Snex1English, ...Snex1Spanish };
                break;
            case 'pt-BR':
                text = { ...Snex1English, ...Snex1Portuguese };
                break;
            case 'zh-CN':
                text = { ...Snex1English, ...Snex1Mandarin };
                break;
            case 'de-DE':
                text = { ...Snex1English, ...Snex1German };
                break;
            case 'en-US':
                text = Snex1English;
                break; // change to spanish for testing.
            default:
                text = get().text;
        }

        set({ text });
    },

    getTextString: (
        select: (l: Snex1LanguagePack) => string,
        opts?: Partial<{
            case: 'title' | 'sentence' | 'lower' | 'default';
        }>
    ): string => {
        const content = get().getText(select);
        switch (opts?.case || 'default') {
            case 'title':
                return FormatString.ChangeCaseToTitleCase(content);
            case 'lower':
                return content?.toLowerCase();
            case 'sentence':
                return FormatString.CapitalizeFirstOnly(content);
            default:
                return content;
        }
    }
}));

export const useText = <TSection>(select: (l: Snex1LanguagePack) => TSection): TSection => {
    // memoize the selector because it's likely an arrow function that constantly rerenders
    const selectInitial = useRef(select);
    
    const { text, withLanguageCheck, updateTextByLanguageCode } = useTextStore();
    const locale = useSelector((s: GlobalState) => s.user.myPrefs?.data?.preference?.language?.code);
    useEffect(() => {
        if (withLanguageCheck) updateTextByLanguageCode(locale);
    }, [locale, updateTextByLanguageCode, withLanguageCheck]);

    return useMemo(() => selectInitial.current(text), [text]);
};
