import SvgIcon from '@mui/material/SvgIcon';
import React, { MouseEvent, ReactElement } from 'react';

interface StyledIconProps {
    IconComponent: typeof SvgIcon;
    style?: React.CSSProperties;
    size?: string | number;
    onClick?: (e?: MouseEvent<HTMLSpanElement>) => void;
    variant?: 'span' | 'div';
}

export const StyledIcon = ({ style = {}, IconComponent, onClick = () => undefined, ...props }: StyledIconProps): ReactElement | null => {
    if (!IconComponent) return null;
    return (
        <span onClick={onClick} role='button' {...props} style={{ display: props.variant === 'div' ? 'block' : 'inline-block', height: props.size, width: props.size }}>
            <IconComponent
                style={{
                    transition: '0.2s',
                    ...(props.size ? { height: props.size, width: props.size } : {}),
                    ...style
                }}
            />
        </span>
    );
};
