import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { SnexAccountOption } from 'phoenix/hooks/useAccounts';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { getAccountAssetClass, GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import { OptionSymbol } from 'phoenix/redux/models';
import { FormatNumber } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React from 'react';

export interface AccountOption extends DropdownOption {
    leftLabel: string;
    leftValue: React.ReactChild;
    rightLabel?: string;
    rightValue?: React.ReactChild;
    label: string;
    value: string;
    isAccountPending?: boolean;
    pendingText?: Snex1LanguagePack['general']['pending'];
}

export function makeAccountOptions({
    accounts,
    balanceType,
    qtyOwnedLabel,
    symbol,
    text,
    useOptionsBuyingPower,
    useAvailableBalance
}: {
    accounts: SnexAccountOption[];
    balanceType?: 'buy' | 'sell' | 'custom' | string;
    qtyOwnedLabel?: string;
    symbol?: string;
    text: Snex1LanguagePack;
    useOptionsBuyingPower?: boolean;
    useAvailableBalance?: boolean;
}): AccountOption[] {
    const isOption = symbol ? OptionSymbol.IsOptionSymbol(symbol) : false;
    const ownedValue = isOption ? text.general.contracts(2) : text.general.shares(2);
    const { formatQuantity } = symbol ? GetAssetClassForSecurity(symbol) : EquitiesAssetClass;

    const allAccountsLabel = (a: SnexAccountOption) =>
        symbol ? `${text.misc.allAccounts}: ${a.sharesOwned} ${text.general.shares(a.sharesOwned || 0)}` : text.misc.allAccounts;

    return accounts?.map((a) => {
        const isAllAccounts = a.accountNumber === 'all';

        const balanceLabel = balanceType === 'buy' ? text.general.buyingPower : ownedValue;
        const sellBalance = typeof a.sharesOwned === 'number' ? `${FormatNumber.toCommas(a?.sharesOwned)}` : null;
        const buyBalance = typeof a.buyingPower?.buyingPower === 'number' ? `${FormatNumber.toMoneyOpt2(a.buyingPower?.buyingPower)}` : null;
        const balance = balanceType === 'buy' ? buyBalance : sellBalance;

        const buyingPowerString = useOptionsBuyingPower
            ? FormatNumber.toMoneyOpt2(a.buyingPower?.optionsBuyingPower || 0)
            : FormatNumber.toMoneyOpt2(a.buyingPower?.buyingPower || 0);
        const assetClass = getAccountAssetClass(a.accountNumber);

        const buyingPowerValue = useAvailableBalance
            ? assetClass && assetClass.type === FuturesAssetClass.type
                ? FormatNumber.toMoneyOpt2(a.buyingPower?.futuresCashAvailable || 0)
                : FormatNumber.toMoneyOpt2(a.buyingPower?.cashAvailable || 0)
            : a.buyingPower
            ? buyingPowerString
            : null;

        // Typically is the BuyingPowerLabel and BuyingPowerValue, but can be custom per asset class
        const leftLabel = useAvailableBalance ? text.general.balance : text.general.buyingPower;
        const leftValue = buyingPowerValue;

        // Typically is the PositionsLabel and SharesOwnedValue, but can be custom per asset class
        let rightValue: string | null = formatQuantity(a?.sharesOwned || 0);
        /* should be empty if we already show the account as the effective name */
        const rightValueDisplayedAccount = a.effectiveName !== QualifiedId.RemovePrefix(a.accountNumber) ? QualifiedId.RemovePrefix(a.accountNumber) : '';
        if (!symbol) rightValue = rightValueDisplayedAccount;
        if (rightValue === 'all') rightValue = null;

        let rightLabel: string | null = text.tradeTicket.position;
        if (!symbol || rightValue === null || assetClass === FuturesAssetClass) rightLabel = null;

        return {
            label: isAllAccounts ? allAccountsLabel(a) : `${a.effectiveName || a.accountNumber}`,
            value: a.accountNumber,
            isAccountPending: a.isPending,
            pendingText: text.general.pending,
            leftLabel,
            leftValue,
            rightLabel,
            rightValue
        } as AccountOption;
    });
}
