import React, { MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { TableCell, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';

interface SnexTableHeaderCellProps {
    align?: 'right' | 'left' | 'center';
    colSpan?: number;
    style?: React.CSSProperties;
    onClick?: (event: MouseEvent<HTMLTableCellElement>) => void;
    width?: React.CSSProperties['width'];
    withinCard?: boolean;
}

interface SnexTableHeaderCellDualTitleProps extends SnexTableHeaderCellProps {
    primaryText: string;
    secondaryText: string;
    primaryColor?: React.CSSProperties['color'];
    secondaryColor?: React.CSSProperties['color'];
    dividerColor?: React.CSSProperties['color'];
}

export const SnexTableHeaderCell = (props: PropsWithChildren<SnexTableHeaderCellProps>): ReactElement => {
    const colors = useColors();

    return (
        <TableCell
            align={props.align}
            colSpan={props.colSpan}
            style={{
                backgroundColor: props.withinCard ? colors.cardBackgroundColor : colors.mainBackgroundColor,
                fontWeight: 700,
                cursor: 'pointer',
                fontSize: 15,
                padding: '0 16px',
                paddingBottom: '15px',
                ...props.style,
                borderBottom: `1px solid ${colors.dividerColor}`
            }}
            onClick={props.onClick}
        >
            <div style={{ width: props.width || undefined }}>
                <Typography style={{ fontWeight: 700, textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: colors.generalTextColor }} variant='h6'>
                    {props.children}
                </Typography>
            </div>
        </TableCell>
    );
};
