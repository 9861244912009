import { ApiData } from '../../../models'
import { FeatureFlag } from './FeatureFlag'

export class FeatureFlagState {
    all: ApiData<FeatureFlag[]>
    byId: { [id: string]: FeatureFlag }

    constructor () {
        this.all = new ApiData([])
        this.byId = {}
    }
}
