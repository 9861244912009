// @ts-strict-ignore
import { GetConfig } from 'phoenix/constants';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { ApiPosition, OptionSymbol } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

/** @deprecated Use useRelevantPositionsV2 for SecurityMetadataV2 and spelling concerns */
export const useRelavantPositions = (secId: QualifiedSecurityId, accountNumber: string): ApiPosition[] => {
    const isFuture = FuturesSymbol.IsFuturesSymbol(secId.id);
    const positions = usePositionsStore((s) => s.positions) || [];
    if (!accountNumber || !secId) return undefined;

    const isOption = OptionSymbol.IsOptionSymbol(secId.id);
    const optSym = isOption && new OptionSymbol(secId.id);
    const fsym = isFuture && isOption && new FuturesSymbol(optSym.underlyingSymbol);

    const metaSymbol = isFuture && isOption ? fsym.baseContract : secId.id;
    const meta = GetConfig().Store.getState().securities.bySymbol[metaSymbol]?.metadata;
    const secIdByNumber = QualifiedSecurityId.Parse(`B:${meta?.data?.securityNumber}`);
    const relevantPositions = positions?.filter((p) => (secId.MatchesPosition(p) || secIdByNumber.MatchesPosition(p)) && p.accountNumber === accountNumber) || [];

    return relevantPositions;
};

export const useRelevantPositionsV2 = (secId: QualifiedSecurityId, accountNumber: string): ApiPosition[] => {
    const isFuture = FuturesSymbol.IsFuturesSymbol(secId.id);
    const positions = usePositionsStore((s) => s.positions) || [];
    if (!accountNumber || !secId) return undefined;

    const isOption = OptionSymbol.IsOptionSymbol(secId.id);
    const optSym = isOption && new OptionSymbol(secId.id);
    const fsym = isFuture && isOption && new FuturesSymbol(optSym.underlyingSymbol);

    const metaSymbol = isFuture && isOption ? fsym.baseContract : secId.id;
    const meta = useSecurityMetadataV2.getState().getMetadataBySymbol(metaSymbol);
    const secIdByNumber = QualifiedSecurityId.Parse(`B:${meta?.equity?.securityNumber}`);
    const relevantPositions = positions?.filter((p) => (secId.MatchesPosition(p) || secIdByNumber.MatchesPosition(p)) && p.accountNumber === accountNumber) || [];

    return relevantPositions;
};
