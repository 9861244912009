// @ts-strict-ignore
import { parseISO } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { ChartRange, ChartRanges, DetermineChartKey } from '../../constants';
import { ApiData } from '../../models';
import { ReduxAction } from '../../models/ReduxAction';
import { GetNewYorkTimeChunks, IsMarketOpen, QuoteAttribute } from '../../util';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { SecurityChartData } from '../models';
import { SecurityChartState } from '../models/SecurityChart/SecurityChartState';

const permitted = GroupNameChecker([Actions.Securities.GetMultiSecurityChartBatch, Actions.Securities.Chart, Actions.Options.GetChart]);
export const SecurityChartReducer = (state: SecurityChartState = new SecurityChartState(), action: ReduxAction): SecurityChartState => {
    if (!permitted) return state;

    // const subj = action.subject as { symbol: string[], range: string } // <-- for batch calls

    // { symbol: string, range: string }[] // <-- desired subject type

    let symbolData = state.bySymbol[action.subject?.symbol] ? state.bySymbol[action.subject?.symbol] : {};

    symbolData = Object.entries({ ...ChartRanges, loRes: 'loRes' }).reduce(
        (final, current) => ({
            ...final,
            [current[1]]: state.bySymbol[action.subject?.symbol]?.[current[1]] || new ApiData([])
        }),
        {}
    );

    const chartData = state.bySymbol[action.subject?.symbol]?.[DetermineChartKey(action.subject?.range, action.subject?.res)] || new ApiData([]);

    const multiSymbolData = () => {
        if (action.subject?.symbols?.length) {
            const data = action.subject?.symbols?.reduce(
                (f, s) =>
                    state.bySymbol[s]
                        ? {
                              ...f,
                              [s]: {
                                  loRes: new ApiData([]),
                                  ...state.bySymbol[s]
                              }
                          }
                        : {
                              ...f,
                              [s]: {
                                  ...Object.entries(ChartRanges).reduce(
                                      (final, current) => ({
                                          ...final,
                                          [current[1]]: state.bySymbol[s]?.[current[1]] || new ApiData([])
                                      }),
                                      { loRes: new ApiData([]) }
                                  )
                              }
                          },
                {}
            );
            return data;
        } else return null;
    };

    const ChartRangesLargerThanDay = { ...ChartRanges };
    delete ChartRangesLargerThanDay.oneDay;
    symbolData[DetermineChartKey(action.subject?.range, action.subject?.res) || action.subject?.range] = chartData;

    const normalizeBatchSecurityCharts = (
        subject: any,
        actionMethod: (q: ApiData<SecurityChartData[] | null>, symbol?: string) => ApiData<SecurityChartData[] | null>
    ) => {
        const newCharts = subject.reduce(
            (f: any, c: any) => ({
                ...f,
                [c.symbol]: {
                    ...state.bySymbol[c.symbol],
                    [DetermineChartKey(c.range, c.res)]: actionMethod(
                        state.bySymbol[c.symbol]?.[DetermineChartKey(c.range, c.res)] || new ApiData<SecurityChartData[] | null>(),
                        c
                    )
                }
            }),
            {}
        );
        return newCharts;
    };

    switch (action.type) {
        case Actions.Options.StreamQuotes.Update:
        case Actions.Securities.Chart.LiveUpdates.Update: {
            const streamingData = state.streamBySymbol[action.subject?.symbol] || [];

            const chartKey = DetermineChartKey(action.subject?.range, action.subject?.res);

            const chartData = state.bySymbol[action.subject.symbol]?.[chartKey] || new ApiData<SecurityChartData[]>([]);

            const sortedVals = streamingData.length
                ? [
                      ...streamingData.sort(
                          (a, b) => Date.parse(new Date(QuoteAttribute.getTime(b)).toUTCString()) - Date.parse(new Date(QuoteAttribute.getTime(a)).toUTCString())
                      )
                  ]
                : [];

            const hasChartData = chartData.data && chartData.data?.length;
            const lastPoint = sortedVals.length ? sortedVals[0] : hasChartData && chartData.data?.[0];
            const { minute: lastMinute, hour: lastHour } = GetNewYorkTimeChunks(new Date(QuoteAttribute.getTime(lastPoint)));

            const { minute: currentMinute, hour: currentHour } = GetNewYorkTimeChunks(new Date(QuoteAttribute.getTime(action?.data)));
            const diffInHours = currentHour - lastHour;
            const diffInMinutes = diffInHours > 0 && currentMinute - lastMinute < 0 ? 1 : currentMinute - lastMinute;
            const interval = DetermineChartKey(action.subject?.range, action.subject?.res) === 'loRes' ? 5 : 1;
            let newData = [];
            let bar;
            if ((isNaN(action?.data?.latestPrice) && isNaN(action?.data?.extendedPrice)) || diffInMinutes < -1 || (diffInHours < 0 && diffInMinutes >= 0)) {
                newData = [...(sortedVals || [])];
            } else if (
                (diffInMinutes < (interval || 1) || isNaN(QuoteAttribute.getTime(action?.data)) || isNaN(diffInMinutes)) &&
                QuoteAttribute.getPrice(action?.data)
            ) {
                const { latestPrice, extendedPrice, extendedPriceTime, extendedChange, extendedChangePercent } = action?.data || {};
                newData = streamingData.length
                    ? sortedVals
                    : [
                          {
                              ...action?.data,
                              timestamp: Date.parse(new Date(QuoteAttribute.getTime(lastPoint)).toUTCString()),
                              high: QuoteAttribute.getPrice(action?.data),
                              low: QuoteAttribute.getPrice(action?.data),
                              close: QuoteAttribute.getPrice(action?.data),
                              open: QuoteAttribute.getPrice(action?.data)
                          }
                      ];

                bar = {
                    ...newData[0],
                    latestPrice: QuoteAttribute.getPrice(action?.data) || 0,
                    high: Math.max(newData[0].high, QuoteAttribute.getPrice(action?.data)),
                    low: Math.min(newData[0].low, QuoteAttribute.getPrice(action?.data)),
                    close: QuoteAttribute.getPrice(action?.data)
                };

                // Problematic code...
                if (isNaN(diffInMinutes)) {
                    bar.timestamp = QuoteAttribute.getTime(action?.data);
                    bar.lastTradeTime = QuoteAttribute.getTime(action?.data);
                }
                if (!isNaN(extendedPrice) && !isNaN(extendedPriceTime) && !isNaN(extendedChange) && !isNaN(extendedChangePercent)) {
                    bar.extendedPrice = extendedPrice && !isNaN(extendedPrice) ? extendedPrice : latestPrice;
                    bar.extendedChange = extendedChange && !isNaN(extendedChange) ? extendedChange : 0;
                    bar.extendedChangePercent = extendedChangePercent && !isNaN(extendedChangePercent) ? extendedChangePercent : 0;
                }
                newData[0] = bar;
            } else {
                if (QuoteAttribute.getTime(action.data) && QuoteAttribute.getPrice(action?.data)) {
                    bar = {
                        ...action?.data,
                        timestamp: Date.parse(new Date(QuoteAttribute.getTime(action?.data)).toUTCString()),
                        high: QuoteAttribute.getPrice(action?.data),
                        low: QuoteAttribute.getPrice(action?.data),
                        close: QuoteAttribute.getPrice(action?.data),
                        open: QuoteAttribute.getPrice(action?.data)
                    };
                    newData = [...(sortedVals || []), bar];
                } else {
                    newData = [...(sortedVals || [])];
                }
            }
            newData = newData.sort((a, b) => Date.parse(new Date(b.timestamp).toUTCString()) - Date.parse(new Date(a.timestamp).toUTCString()));
            return {
                ...state,
                ...(!chartData?.data?.length && !chartData?.loading && !chartData?.pristine
                    ? {
                          bySymbol: {
                              ...state.bySymbol,
                              [action.subject.symbol]: {
                                  ...state.bySymbol[action.subject.symbol],
                                  [chartKey]: chartData.succeeded(newData)
                              }
                          }
                      }
                    : {}),
                streamBySymbol: {
                    ...state.streamBySymbol,
                    [action.subject.symbol]: [...newData]
                }
            };
        }

        case Actions.Securities.GetMultiSecurityChartBatch.Loading: {
            const newSymbolData = Object.entries(multiSymbolData() || {}).reduce(
                (final, current: any) => ({
                    ...final,
                    [current[0]]: {
                        ...current[1],
                        [DetermineChartKey(action.subject.range, 'lo')]: current[1]?.[DetermineChartKey(action.subject.range, 'lo')].startLoading(
                            current[1][DetermineChartKey(action.subject.range, 'lo')].data
                        )
                    }
                }),
                {}
            );
            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    ...newSymbolData
                }
            };
        }

        case Actions.Securities.GetMultiSecurityChartBatch.Success: {
            const updatedSymbolResults = action.data?.reduce((finalSymbols, currentSymbol) => {
                const symbolRangeResults = Object.entries(currentSymbol.charts).reduce((final, current) => {
                    const sortedValues = [...(current[1] as any[])].sort(
                        (a, b) => Date.parse(new Date(b.date).toUTCString()) - Date.parse(new Date(a.date).toUTCString())
                    );
                    const formattedChartData = sortedValues.map((d) => ({
                        ...d,
                        latestPrice: d.close,
                        timestamp: Date.parse(new Date(d.date).toUTCString())
                    }));
                    return {
                        ...final,
                        [DetermineChartKey(current[0] as ChartRange, action.subject?.res)]:
                            multiSymbolData()[currentSymbol.symbol]?.[DetermineChartKey(current[0] as ChartRange, action.subject?.res)]?.succeeded(formattedChartData)
                    };
                }, {});

                return {
                    ...finalSymbols,
                    [currentSymbol.symbol]: {
                        ...multiSymbolData()[currentSymbol.symbol],
                        ...symbolRangeResults
                    }
                };
            }, {});

            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    ...updatedSymbolResults
                }
            };
        }

        case Actions.Securities.GetMultiSecurityChartBatch.Failure:
            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    ...Object.entries(multiSymbolData() || {}).reduce(
                        (final, current: any) => ({
                            ...final,
                            [current[0]]: {
                                ...current[1],
                                [DetermineChartKey(action.subject.range as ChartRange, action.subject?.res)]: current[1][
                                    DetermineChartKey(action.subject.range as ChartRange, action.subject?.res)
                                ].failed(action.error)
                            }
                        }),
                        {}
                    )
                }
            };
        case Actions.Securities.Chart.GetAllRanges.Loading:
            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    [action.subject.symbol]: {
                        ...symbolData,
                        ...Object.entries(ChartRangesLargerThanDay).reduce((final, current) => {
                            return {
                                ...final,
                                [current[1]]: symbolData[current[1]].startLoading(symbolData[current[1]].data)
                            };
                        }, {})
                    }
                }
            };
        case Actions.Options.GetChart.Loading:
        case Actions.Securities.Chart.Get.Loading:
            if (!action?.subject) return state;
            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    ...(typeof action.subject.symbol === 'string'
                        ? {
                              [action.subject.symbol]: {
                                  ...state.bySymbol[action.subject.symbol],
                                  [DetermineChartKey(action.subject.range, action.subject?.res)]: symbolData[
                                      DetermineChartKey(action.subject.range, action.subject?.res)
                                  ].startLoading(symbolData[DetermineChartKey(action.subject.range, action.subject?.res)].data)
                              }
                          }
                        : normalizeBatchSecurityCharts(action.subject, (s) => s.startLoading(s.data)))
                }
            };
        case Actions.Options.GetChart.Success:
        case Actions.Securities.Chart.Get.Success:
            try {
                const newState = { ...state };

                const sortedValues = [...(action.data[0]?.data || action.data)].sort((a, b) =>
                    a && b ? Date.parse(new Date(b?.date).toUTCString()) - Date.parse(new Date(a?.date).toUTCString()) : a || b
                );

                if (DetermineChartKey(action.subject?.range, action.subject?.res) === '1d' && sortedValues[0] && sortedValues[1]) {
                    const intervalInMilliseconds = Date.parse(new Date(sortedValues[0].date).toUTCString()) - Date.parse(new Date(sortedValues[1].date).toUTCString());
                    const intervalInMinutes = Math.round(intervalInMilliseconds / 1000 / 60);
                    newState.intervalInMinutes = intervalInMinutes >= 1 ? intervalInMinutes : 1;
                }
                const formattedChartData = sortedValues.map((d) => {
                    const asDate = toDate(new Date(d.date), {
                        timeZone: 'America/New_York'
                    });
                    return {
                        ...d,
                        latestPrice: d.close,
                        timestamp: Date.parse(asDate.toUTCString())
                    };
                });

                return {
                    ...newState,
                    bySymbol: action.subject?.symbol
                        ? {
                              ...state.bySymbol,
                              [action.subject.symbol]: {
                                  ...symbolData,
                                  [DetermineChartKey(action.subject.range, action.subject?.res)]:
                                      symbolData[DetermineChartKey(action.subject.range, action.subject?.res)].succeeded(formattedChartData)
                              }
                          }
                        : {
                              ...state.bySymbol,
                              ...action.data.reduce((finalSymbols, currentSymbol) => {
                                  const symbolRangeResults = currentSymbol?.charts
                                      ? Object.entries(currentSymbol.charts).reduce((final, current) => {
                                            const relatedSubject = action.subject.find((a) => a.symbol === currentSymbol.symbol);
                                            const sortedValues = [...(current[1] as any[])]
                                                .filter((d) => !!d.date && (relatedSubject?.range === '1d' && IsMarketOpen() ? IsMarketOpen(d.date, true) : true))
                                                .sort((a, b) => Date.parse(parseISO(b.date).toUTCString()) - Date.parse(parseISO(a.date).toUTCString()));
                                            const formattedChartData = sortedValues.map((d) => {
                                                const asDate = toDate(parseISO(d.date), {
                                                    timeZone: 'America/New_York'
                                                });
                                                return {
                                                    ...d,
                                                    latestPrice: d.close,
                                                    timestamp: Date.parse(asDate.toUTCString())
                                                };
                                            });

                                            return {
                                                ...final,
                                                [DetermineChartKey(current[0] as ChartRange, relatedSubject?.res)]:
                                                    symbolData[DetermineChartKey(current[0] as ChartRange, relatedSubject?.res)]?.succeeded(formattedChartData) ||
                                                    new ApiData().succeeded(formattedChartData)
                                            };
                                        }, {})
                                      : {};
                                  return {
                                      ...finalSymbols,
                                      [currentSymbol.symbol]: {
                                          ...state.bySymbol[currentSymbol.symbol],
                                          ...symbolRangeResults
                                      }
                                  };
                              }, {})
                          },
                    streamBySymbol: action.subject?.symbol
                        ? {
                              ...state.streamBySymbol,
                              [action.subject.symbol]: []
                          }
                        : {
                              ...state.streamBySymbol,
                              ...action.subject.reduce((f, c) => ({ ...f, [c.symbol]: [] }), {})
                          }
                };
            } catch (error) {
                console.log('ERROR', error);
            }
            break;
        case Actions.Securities.Chart.Reset:
            return {
                ...state,
                bySymbol: {
                    ...Object.entries(state.bySymbol).reduce(
                        (f, c) => ({
                            ...f,
                            [c[0]]: {
                                ...c[1],
                                loRes: new ApiData().succeeded([]),
                                [ChartRanges.oneDay]: new ApiData().succeeded([])
                            }
                        }),
                        {}
                    )
                },
                streamBySymbol: {
                    ...Object.entries(state.streamBySymbol).reduce((f, c) => ({ ...f, [c[0]]: [] }), {})
                }
            };
        case Actions.Options.GetChart.Failure:
        case Actions.Securities.Chart.Get.Failure:
            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    ...(typeof action.subject.symbol === 'string'
                        ? {
                              [action.subject.symbol]: {
                                  ...symbolData,
                                  [DetermineChartKey(action.subject.range, action.subject?.res)]: symbolData[
                                      DetermineChartKey(action.subject.range, action.subject?.res)
                                  ].failed(action.error)
                              }
                          }
                        : normalizeBatchSecurityCharts(action.subject, (s) => s.failed(action.error, true)))
                }
            };
        default:
            return state;
    }
};
