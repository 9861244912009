import React from 'react';
import { getScaledImage } from './getScaledImage';

export const Next10Icon = ({ width, height, style }: { width?: number; height?: number; style?: React.CSSProperties }): JSX.Element => {
    const { height: _h, width: _w } = getScaledImage(30, 30, width, height);

    return (
        <div style={{ ...style }}>
            <svg width={_w} height={_h} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <mask id='mask0_22_120' style={{ maskType: 'luminance' }} maskUnits='userSpaceOnUse' x='0' y='0' width='30' height='30'>
                    <path fillRule='evenodd' clipRule='evenodd' d='M30 0H0V30H30V0Z' fill='white' />
                </mask>
                <g mask='url(#mask0_22_120)'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M15 6.25V1.25L21.25 7.5L15 13.75V8.75C10.875 8.75 7.5 12.125 7.5 16.25C7.5 20.375 10.875 23.75 15 23.75C19.125 23.75 22.5 20.375 22.5 16.25H25C25 21.75 20.5 26.25 15 26.25C9.5 26.25 5 21.75 5 16.25C5 10.75 9.5 6.25 15 6.25ZM16.4417 20H17.5V15.9204L18.7634 16.3123V15.4517L16.5552 14.6606H16.4417V20ZM11.1206 17.7954C11.1206 18.5327 11.2732 19.0967 11.5784 19.4873C11.8835 19.8779 12.3303 20.0732 12.9187 20.0732C13.4998 20.0732 13.9441 19.8816 14.2517 19.4983C14.5593 19.115 14.7168 18.5657 14.7241 17.8503V16.8689C14.7241 16.1243 14.5697 15.5591 14.2609 15.1733C13.952 14.7876 13.5071 14.5947 12.926 14.5947C12.345 14.5947 11.9006 14.7858 11.593 15.1678C11.2854 15.5499 11.1279 16.0986 11.1206 16.814V17.7954ZM12.179 16.7188C12.179 16.2769 12.2394 15.9552 12.3602 15.7538C12.4811 15.5524 12.6697 15.4517 12.926 15.4517C13.175 15.4517 13.3588 15.5475 13.4772 15.7391C13.5956 15.9308 13.6584 16.2305 13.6658 16.6382V17.9346C13.6658 18.3691 13.6066 18.692 13.4882 18.9032C13.3698 19.1144 13.1799 19.22 12.9187 19.22C12.6599 19.22 12.4731 19.1187 12.3584 18.916C12.2437 18.7134 12.1838 18.4033 12.179 17.9858V16.7188Z'
                        fill='white'
                        fillOpacity='0.73'
                    />
                </g>
                <rect x='11' y='14' width='9' height='7' fill='black' />
                <path
                    d='M13.6275 14.9091V20H12.5511V15.9308H12.5213L11.3555 16.6616V15.707L12.6158 14.9091H13.6275ZM16.8335 20.1119C16.406 20.1102 16.0381 20.005 15.7298 19.7962C15.4233 19.5874 15.1871 19.2849 15.0214 18.8888C14.8573 18.4928 14.7761 18.0163 14.7778 17.4595C14.7778 16.9044 14.8598 16.4312 15.0239 16.0401C15.1896 15.649 15.4258 15.3516 15.7323 15.1477C16.0406 14.9422 16.4076 14.8395 16.8335 14.8395C17.2594 14.8395 17.6257 14.9422 17.9323 15.1477C18.2405 15.3532 18.4775 15.6515 18.6432 16.0426C18.8089 16.4321 18.891 16.9044 18.8893 17.4595C18.8893 18.018 18.8064 18.4953 18.6407 18.8913C18.4767 19.2874 18.2413 19.5898 17.9347 19.7987C17.6282 20.0075 17.2611 20.1119 16.8335 20.1119ZM16.8335 19.2195C17.1252 19.2195 17.358 19.0728 17.532 18.7795C17.7061 18.4862 17.7922 18.0462 17.7906 17.4595C17.7906 17.0734 17.7508 16.7519 17.6713 16.495C17.5934 16.2382 17.4823 16.0451 17.3382 15.9158C17.1956 15.7866 17.0274 15.7219 16.8335 15.7219C16.5435 15.7219 16.3115 15.867 16.1375 16.157C15.9635 16.447 15.8757 16.8812 15.874 17.4595C15.874 17.8506 15.913 18.1771 15.9909 18.4389C16.0704 18.6991 16.1823 18.8946 16.3264 19.0256C16.4706 19.1548 16.6396 19.2195 16.8335 19.2195Z'
                    fill='#BABABA'
                />
            </svg>
        </div>
    );
};
