// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3lyaAUnBxl8pQmUL3wRemI{height:90px;border-radius:17px;background-position:center;background-size:cover;overflow:hidden;background-color:var(--cardSecondaryBackgroundColor);position:relative;cursor:default}._1-J-EauaIhYykAn6hmGBGC{content:\"\";height:150%;border-radius:17px;width:150%;opacity:.7;background:#000;transform:translate(-25%, -25%)}._2vXk_3YWPHIbWoO9TZmUnu{height:100%;padding:12px;position:absolute;border-radius:17px;top:0;left:0}.sRDv9w7kbaSRtV4aLcJtQ{height:126px;border-radius:17px;background-position:center;background-size:cover;overflow:hidden;background-color:var(--cardSecondaryBackgroundColor);position:relative;cursor:pointer}._3wWiHScbxAINWfMEyLLfQf{content:\"\";height:150%;border-radius:17px;width:150%;background:linear-gradient(to bottom, transparent 20%, #000);transform:translate(-25%, -25%)}._1Yg2UwE_cgwepITHEoyzSq{height:100%;padding:12px;position:absolute;border-radius:17;top:0;left:0}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094",
	"skeleton-post-card-container": "_3lyaAUnBxl8pQmUL3wRemI",
	"skeleton-post-card-shaded-overlay": "_1-J-EauaIhYykAn6hmGBGC",
	"skeleton-post-card-content": "_2vXk_3YWPHIbWoO9TZmUnu",
	"post-card-container": "sRDv9w7kbaSRtV4aLcJtQ",
	"post-shaded-overlay": "_3wWiHScbxAINWfMEyLLfQf",
	"post-card-content": "_1Yg2UwE_cgwepITHEoyzSq"
};
module.exports = exports;
