import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useTelemetry } from 'hooks/UseTelemetry';
import { OrderStatusInput } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { ConvertTradeableSecurityTypeToApiTradeableSecurityType } from 'phoenix/redux/actions/OrdersActions';
import { useOrdersStore } from 'phoenix/stores/OrdersStore';
import { GetVariant } from 'phoenix/util/Variant';
import React, { useEffect, useMemo } from 'react';
import { Routes } from 'util/Routes';
import { Flex, SectionHeader } from '..';
import './index.scss';
import { OrderList } from './OrderList';

interface OpenOrdersProps {
    symbol?: string;
    accountNumber?: string;
    label?: string;
    status?: OrderStatusInput;
    securityKnown?: boolean;
    unexpandedOrders?: number;
    noHistoryLink?: boolean;
}

// should probably move the status-based inclusion logic to to the reducer
const openStatuses = [
    'Open',
    'Working', // futures
    'NotProcessed', // mutual funds,
    'Pending'
];

export const OpenOrders = React.memo((props: OpenOrdersProps) => {
    const LogEvent = useTelemetry();
    const { searchResults, searchResultsLoading, openOrders, openOrdersLoading, searchOrders, getAllOpenOrders } = useOrdersStore();
    const [orders, ordersLoading] = useMemo(() => {
        if (props.symbol) return [searchResults, searchResultsLoading];
        return [openOrders, openOrdersLoading];
    }, [openOrders, openOrdersLoading, props.symbol, searchResults, searchResultsLoading]);

    const ordersRouteSymbol = props.symbol ? encodeURIComponent(props.symbol) : undefined;
    const symbolAssetClass = useAssetClassMetaV2(props?.symbol || '');
    const symbolSecurityType = symbolAssetClass ? symbolAssetClass?.type : undefined;

    const text = useText((t) => t);

    useEffect(() => {
        const run = async () => {
            if (props.symbol) {
                const searchFilters = {
                    symbol: props.symbol,
                    take: 100,
                    status: props.status,
                    tradeableSecurityType: ConvertTradeableSecurityTypeToApiTradeableSecurityType(symbolSecurityType)
                };
                await searchOrders(searchFilters);
            } else await getAllOpenOrders();
        };
        run();
    }, [getAllOpenOrders, props.status, props.symbol, searchOrders, symbolSecurityType]);

    const filteredOrders = useMemo(
        () =>
            orders?.filter((o) => {
                if (props.symbol) {
                    if (props.status === 'All') return props.symbol === o.symbol;
                    else return props.symbol === o.symbol && openStatuses.includes(o?.orderStatus);
                }
                if (props.status === 'All') return true;
                if (props.accountNumber && props.accountNumber !== 'all' && o.accountNumber !== props.accountNumber) return false;
                return openStatuses.includes(o?.orderStatus);
            }),
        [orders, props?.accountNumber, props.status, props.symbol]
    );

    const hideHeader = !(ordersLoading || !filteredOrders?.length);
    const { canViewTradeHistory } = GetVariant();

    return (
        <Flex column className='open-orders'>
            {hideHeader && (
                <SectionHeader
                    actionLabel={canViewTradeHistory && !props.noHistoryLink ? text.orders.orderHistory : undefined}
                    assetFamily={symbolAssetClass.family}
                    label={props.label ? props.label : text.orders.openOrders}
                    route={canViewTradeHistory && !props.noHistoryLink ? Routes.orders(ordersRouteSymbol, undefined, props.accountNumber) : undefined}
                    onAction={
                        canViewTradeHistory && !props.noHistoryLink
                            ? () => LogEvent('Trade History button click', undefined, false, TelemetryCategories.history)
                            : undefined
                    }
                />
            )}
            <ErrorBoundary>
                <OrderList
                    allowExpand
                    expandedSize={50}
                    loading={ordersLoading || !orders}
                    orders={filteredOrders}
                    securityKnown={props.securityKnown}
                    unexpandedSize={props.unexpandedOrders || 3}
                />
            </ErrorBoundary>
        </Flex>
    );
});
