export const Relays = {
    DeadLogin: 'DEAD_LOGIN',
    PageNotFound: 'IS_PAGE_NOT_FOUND',
    ShowAmexCardModal: 'SHOW_AMEX_CARD_MODAL',
    WelcomeModalVisible: 'WELCOME_MODAL_VIZ',
    AppWindowSize: 'APP_WINDOW_SIZE',
    SelectedAccount: 'SELECTED_ACCOUNT',
    SelectedAccountByAssetClass: (assetClass: string)=> `SELECTED_ACCOUNT${assetClass}`,
    AddManyToListModalVisible: 'ADD_MANY_LIST_MODAL_VISIBLE',
    RouteLocation: 'ROUTE_LOCATION',
    ShowPremiumUpgradeModal: 'MODAL_SHOW_PREMIUM_UPGRADE',
    MillionBillion: (group: string) => `MillBill/${ group }`
}