// @ts-strict-ignore
import { ApiData, ApiOperation } from '../../../models'
import { AdminMessageDeliveryControls, ClientMessageDeliveryControl, SystemMessageDeliveryControls } from './ClientMessageDeliveryControls'
import { SnexMessage } from './SnexMessage'
import { UnreadMessagesStats } from './UnreadMessagesStats'

export class MessagesState {
    latest: SnexMessage
    all: ApiData<SnexMessage[]>
    byId: { [key: string]: ApiData<SnexMessage> }
    unreadCount: ApiData<number>
    unreadStats: ApiData<UnreadMessagesStats>
    broadcast: ApiOperation

    systemControls: ApiData<SystemMessageDeliveryControls[]> // System-level MDC controls, as visible to the client
    clientControls: ApiData<ClientMessageDeliveryControl[]> // Client-level MDC controls

    adminControls: ApiData<AdminMessageDeliveryControls[]> // System-level MDC controls, as visible to the admin (includes documentation, other possibly sensitive stuff)
    updateAdminControls: ApiOperation

    constructor () {
        this.latest = null
        this.all = new ApiData<SnexMessage[]>([])
        this.byId = {}
        this.unreadCount = new ApiData(0)
        this.unreadStats = new ApiData()
        this.broadcast = new ApiOperation()

        this.systemControls = new ApiData([])
        this.clientControls = new ApiData([])

        this.adminControls = new ApiData([])
        this.updateAdminControls = new ApiOperation()
    }
}
