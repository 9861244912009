import { T } from "phoenix/assets/lang/T"
import { OnboardingInputError, ValidateRequired } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"

export const PersonalIsValid = () => getPersonalErrors().length < 1
export const getPersonalErrors = () : OnboardingInputError[] => {
    const keys = ['data.usCitizen']
    const data = useRegistrationStore.getState()
    const _errors = []  as OnboardingInputError[]

    keys.forEach(key => {
        switch(key) {
            case 'data.usCitizen':
                if(data?.data?.usCitizen === undefined ) _errors.push({key: 'data.usCitizen', error: T(t => t.webRegistration.errors.required)})
                if(data?.data?.usCitizen === false) _errors.push({key: 'data.usCitizen', error: T(t => t.webRegistration.usCitizenAlert)})
                break;
    }})

    return _errors.filter(e => !!e);
}