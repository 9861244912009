// @ts-strict-ignore
import React from "react"
import { OnboardingScreen, OnboardingStep } from "phoenix/onboarding/OnboardingTypes"
import { Fpl_Submitting_Form } from "./Form"
import { useFullyPaidLendingStore } from "../../Store"
import { getFPLAgreementErrors } from "../Agreements/Validation"
import { getFPLDisclosuresErrors } from "../Disclosures/Validation"


const isReadyToSubmit = getFPLAgreementErrors()?.length < 1 && getFPLDisclosuresErrors()?.length < 1

export const fpl_submitting_step: OnboardingStep = {
    label: "Submit",
    isComplete: () => useFullyPaidLendingStore.getState().isComplete,
    isEnabled: () => isReadyToSubmit,
    isCurrent: () => ['fplSubmitting'].includes(useFullyPaidLendingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'fplSubmitting'
}


export const fpl_submitting_screen: OnboardingScreen = {
    id: "fplSubmitting",
    validate: () => [],
    buttons: [],
    title: null,
    subtitles: null,
    body: <Fpl_Submitting_Form/>
}