import { ApiData, ApiOperation } from '../../../models'
import { PaperlessPref } from './PaperlessPref'
import { PaperlessPrefUpdate } from './PaperlessPrefUpdate'
import { SnexDocument, SnexDocumentV2 } from './SnexDocument'
import { SnexDocumentType, SnexDocumentTypeV2 } from './SnexDocumentType'

export class DocumentsState {
    types: ApiData<SnexDocumentType[]>
    searchResults: ApiData<SnexDocument[]>
    typesV2: ApiData<Record<string, SnexDocumentTypeV2> | null>
    typesV2ByAssetFamily: { [key: string]: ApiData<Record<string, SnexDocumentTypeV2> | null> }
    searchResultsV2: ApiData<SnexDocumentV2[]>
    paperlessPrefs: ApiData<PaperlessPref[]>
    paperlessUpdate: ApiOperation
    paperlessCompletely: ApiData<PaperlessPrefUpdate | null>
    download: ApiData<{
        documentTypeId: string,
        documentId: string,
        metadata: SnexDocument,
        metadataV2: SnexDocumentV2,
        blob: Blob | null
    } | null>

    constructor() {
        this.typesV2ByAssetFamily = {
            equities: new ApiData(null),
            futures: new ApiData(null),
            cryptos: new ApiData(null)
        }
        this.types = new ApiData([])
        this.searchResults = new ApiData([])
        this.typesV2 = new ApiData(null)
        this.searchResultsV2 = new ApiData([])
        this.paperlessPrefs = new ApiData([])
        this.paperlessUpdate = new ApiOperation()
        this.paperlessCompletely = new ApiData(null)
        this.download = new ApiData(null)
    }
}
