import { ApiData, ApiOperation } from '../../../models'
import { Account } from '.'
import { ProjectedIncome, ProjectedIncomeV2 } from './ProjectedIncome'
import { MaxNumberOfFuturesAccounts } from '../FuturesOnboarding/MaxNumberOfFuturesAccounts';
import { MediantSSOUrl } from './MediantSSOUrl';


export class AccountsState {
    all: ApiData<Account[]>;
    maxFutures: ApiData<MaxNumberOfFuturesAccounts>
    pendingFuturesAccounts: ApiData<Account[]>
    byNumber: {
        [key: string]: {
            projectedIncome: ApiData<ProjectedIncome>,
            projectedIncomeV2: ApiData<ProjectedIncomeV2>, // Adding this new subfield here for backwards compat, since the new model doesn't use ISO
            rename: ApiOperation,
            mediantSSO: ApiData<MediantSSOUrl>
        }
    }

    constructor () {
        this.all = new ApiData<Account[]>([])
        this.maxFutures = new ApiData<MaxNumberOfFuturesAccounts>()
        this.pendingFuturesAccounts = new ApiData<Account[]>([])
        this.byNumber = {}
    }
}
