import { ActionNames } from '../redux/actions/Constants'
import { ReduxAction } from './ReduxAction'

export class ApiOperation {
    loading: boolean;
    success: boolean;
    error: any;
    pristine: boolean;

    constructor () {
        this.loading = false
        this.success = false
        this.error = null
        this.pristine = true
    }

    // For below methods: cannot spread because it leaves off the methods

    startLoading (): ApiOperation {
        const out = new ApiOperation()
        out.pristine = this.pristine
        out.loading = true
        return out
    }

    succeeded (): ApiOperation {
        const out = new ApiOperation()
        out.success = true
        out.pristine = false
        return out
    }

    failed (error: any): ApiOperation {
        const out = new ApiOperation()
        out.success = false
        out.pristine = false
        out.error = error
        return out
    }

    respond (action: ReduxAction): ApiOperation {
        if (ActionNames.IsLoading(action.type)) return this.startLoading()
        else if (ActionNames.IsSuccess(action.type)) return this.succeeded()
        else if (ActionNames.IsFailure(action.type)) return this.failed(action.error)
        else return this
    }

    reset (): ApiOperation {
        return new ApiOperation()
    }
}
