// @ts-strict-ignore
import { ResolveEnvironment } from '../constants';
import { GetConfig } from '../constants/Config';
import { LsErrorIsRecoverable } from '../constants/LightstreamerErrorCodes';
import { SnexLsClient } from '../ls-shim/models/SnexLsClient';
import { SnexLsClientListener } from '../ls-shim/models/SnexLsClientListener';
import { NoopLsBackEnd } from '../ls-shim/NoopLsBackEnd';
import { SnexLsBackEnd } from '../ls-shim/SnexLsBackEnd';
import { ExtendedSubscription } from '../models/ExtendedSubscription';
import { ReportErrorAction } from '../redux/actions/ErrorActions';
import { SetRelayValueAction } from '../redux/actions/RelayActions';
import { DebugDumpManager } from './DebugDumpManager';
import { GetEnableDebugLogging } from './DebugMode';
import { CrossPlatformRelays } from '../constants/CrossPlatformRelays';
import { GetDisableGainStreaming, GetDisableSnexStreaming } from '.';
import { ItemUpdate } from 'lightstreamer-client-web';
import { SnexLsItemUpdate } from 'phoenix/ls-shim/models/SnexLsItemUpdate';
import { maybePatchGainRetry } from './LightstreamerClientManagerPatches/GainRetryDelayed';

export const LSEvents = {
    LSServerError: '⚠ LS Server Error',
    LSReconnect: 'LS Reconnect',
    LSGiveUp: 'LS Give Up',
    LSListenEnd: 'LS Listen End',
    LSListenStart: 'LS Listen Start',
    LSPropertyChange: 'LS Property Change',
    LSShareAbort: 'LS Share Abort',
    LSStatusChange: 'LS Status Change',
    LSStartupWarning: '⚠ LS Startup Warning',
    DebugConnectDisabled: 'Debug: Connect Disabled',
    UserAlreadyDefined: 'User already defined',
    LSSetCredentials: 'LS Set Credentials',
    CarryingOverUserIdentity: 'Carrying over user identity from reset',
    Subscribed: 'Subscribed',
    Unsubscribed: 'Unsubscribed'
} as const;

export type LSEventTypes = typeof LSEvents[keyof typeof LSEvents];

export type OnSubscriptionTelemetryHandler = (counts: { active: number; total: number }) => void;
export type LSUpstreamSource = 'gain' | 'snex';

const clients: Map<LSUpstreamSource, SnexLsClient> = new Map();
const users: Map<LSUpstreamSource, { user: string; password: string }> = new Map();
let subscriptions: ExtendedSubscription[] = [];
let countHandler: OnSubscriptionTelemetryHandler | null = null;
let countHandlerInterval: NodeJS.Timer | null = null;

let _backend: SnexLsBackEnd = new NoopLsBackEnd();

const SetBackEnd = (backend: SnexLsBackEnd): void => {
    _backend = backend;
};
let _displayErrorToasts = true;

export const SetEnableLightstreamerErrorToastsInNonProd = (enable: boolean): void => {
    _displayErrorToasts = enable;
};

const GetClient = (upstream: LSUpstreamSource, autoConnect = true): SnexLsClient | null => {
    const log = GetEnableDebugLogging();
    const env = ResolveEnvironment();
    const isProd = env === 'production' || env === 'beta' || env === 'staging' || env === 'pro';

    const clientCached = clients.get(upstream);

    if (clientCached === undefined) {
        const config = GetConfig()?.Lightstreamer?.[upstream];
        if (!config) {
            clients.delete(upstream);
            if (log) console.log(`[!!!] ${upstream}: upstream LS host not configured. Add Lightstreamer.${upstream}.Host to app configuration.`);
            return null;
        }
        if (log) console.log(`Creating lightstreamer client connected to host ${config.Host} using adapter set ${config.AdapterSet}`);

        const client = _backend.createClient(config.Host, config.AdapterSet);

        const l = (event: string, msg: string, toast = false) => {
            DebugDumpManager.RecordEvent({ event, item: `<SESSION:${upstream}>`, other: msg });
            if (toast && !isProd && _displayErrorToasts) GetConfig()?.Store.dispatch(ReportErrorAction(`${event}@${upstream}: ${msg}`, 'toast'));
        };

        const listener: SnexLsClientListener = {
            onServerError: (code, message) => {
                const user = _backend.getUser(client) || 'UNSET';
                const msg = `Code ${code} / ${message}. Username = ${user}`;
                l('⚠ LS Server Error', msg, true);

                const status = _backend.getStatus(client);
                const recoverable = LsErrorIsRecoverable(code);

                if (status === 'DISCONNECTED' && recoverable) {
                    if (log) console.log(`[i] ${upstream} Lightstreamer has disconnected due to error ${code} (${message}). Reconnecting shortly...`);
                    setTimeout(() => {
                        _backend.connect(client);
                        l('LS Reconnect', 'Reconnecting');
                        if (log) console.log(`[i] ${upstream} Lightreamer reconnecting`);
                    }, 3000);
                } else {
                    l('LS Give Up', `Not attempting to reconnect; status = ${status}, recoverable? = ${recoverable}`);
                }
            },
            onListenEnd: (_) => l('LS Listen End', ''),
            onListenStart: (_) => l('LS Listen Start', ''),
            onPropertyChange: (the) => l('LS Property Change', the),
            onShareAbort: () => l('LS Share Abort', ''),
            onStatusChange: (change) => {
                l('LS Status Change', change);
                const topic = upstream === 'gain' ? CrossPlatformRelays.GainLsState : CrossPlatformRelays.SnexLsState;
                GetConfig()?.Store.dispatch(SetRelayValueAction(topic, change));
            }
        };
        _backend.addListener(client, listener);

        clients.set(upstream, client);
        if (autoConnect) {
            const disabled = (upstream === 'gain' && GetDisableGainStreaming()) || (upstream === 'snex' && GetDisableSnexStreaming());
            if (!disabled) {
                if (!_backend.getUser(client)) l('⚠ LS Startup Warning', 'You are auto-connecting, but have not defined a user!', true);
                _backend.connect(client);
            } else {
                DebugDumpManager.RecordEvent({ item: `<SESSION:${upstream}>`, event: 'Debug: Connect Disabled', other: 'Refusing to connect per debug options' });
            }
        }
        return client;
    }
    return clientCached;
};

const GetCurrentSessionId = (upstream: LSUpstreamSource): string | null => {
    const c = GetClient(upstream);
    if (c !== null) {
        return _backend.getSessionId(c);
    }
    return null;
};

const Subscribe = (subscription: ExtendedSubscription): void => {
    patchToRetry(subscription);
    subscriptions.push(subscription);
    reportSubCount();
    const client = GetClient(subscription.upstream);
    if (client !== null) {
        _backend.subscribe(client, subscription);
    }
};

/**
 * Some upstream sources require us to try a subscription, fail, and then retry the subscription with a modification.
 * This function modifies the subscription directly, to add "retry" logic at the most transparent level.
 * This is safe to pass every subscription through; it won't modify subscriptions that don't need it.
 * @param subscription - The subscription object to modify.
 * @private
 */
function patchToRetry(subscription: ExtendedSubscription): void {
    maybePatchGainRetry({
        subscription,
        onRequestSubscribe(sub) {
            Subscribe(sub);
        },
        onRequestUnsubscribe(sub) {
            Unsubscribe(sub);
        }
    });
}

const Unsubscribe = (subscription: ExtendedSubscription): void => {
    subscriptions = subscriptions.filter((s) => s !== subscription);
    try {
        const client = GetClient(subscription.upstream);
        if (client !== null) {
            _backend.unsubscribe(client, subscription);
        }
        reportSubCount();
    } catch (e) {}
};

const UnsubscribeAll = (group?: string): void => {
    if (!group) subscriptions.forEach((s) => Unsubscribe(s));
    else {
        subscriptions
            .filter((s) => {
                const g = s.getDataAdapter();
                return g === group;
            })
            .forEach((s) => Unsubscribe(s));
    }
    reportSubCount();
};

const SetUser = (upstream: LSUpstreamSource, username: string, password: string, reinitialize = false): void => {
    const disabled = upstream === 'gain' ? GetDisableGainStreaming() : GetDisableSnexStreaming();
    if (disabled) {
        DebugDumpManager.RecordEvent({ item: `<SESSION:${upstream}>`, event: 'Debug: Connect Disabled', other: 'Not connecting due to debug options' });
        return;
    }

    const usernameExisting = users.get(upstream)?.user;
    if (!reinitialize && usernameExisting === username) {
        DebugDumpManager.RecordEvent({ item: `<SESSION:${upstream}>`, event: 'User already defined', other: 'Not redefining user existing user' });
        return;
    }
    DebugDumpManager.RecordEvent({ event: 'LS Set Credentials', item: `<SESSION:${upstream}>`, other: `User = ${username}` });
    const c = GetClient(upstream, false);
    if (c !== null) {
        _backend.disconnect(c);
        _backend.setUser(c, username);
        _backend.setPassword(c, password);
        _backend.connect(c);
    }
    users.set(upstream, { user: username, password });
};

const GetCurrentUserId = (upstream: LSUpstreamSource = 'snex'): string | undefined => users.get(upstream)?.user;

const reportSubCount = () => {
    if (countHandler) {
        const nActive = subscriptions.filter((s) => s.isActive()).length;
        const nTotal = subscriptions.length;
        countHandler({ active: nActive, total: nTotal });
    }
};

function OnSubscriptionTelemetry(handler: OnSubscriptionTelemetryHandler): void {
    countHandler = handler;
    if (countHandlerInterval) clearInterval(countHandlerInterval);
    countHandlerInterval = setInterval(() => reportSubCount(), 2000);
}

// This is called when the environment changes
const ResetAll = (): void => {
    UnsubscribeAll(); // Hang up all subscriptions

    // Disconnect all clients
    clients.forEach((c) => {
        c.src?.disconnect?.();
    });
    clients.clear();

    // Reinitialize all clients with currently configured users
    users.forEach((user, upstream) => {
        // DebugDumpManager.RecordEvent({ item: `<SESSION:${upstream}>`, event: 'Carrying over user identity from reset', other: JSON.stringify(user) })
        SetUser(upstream, user.user, user.password, true);
    });
};

const GetSubscriptions = (): ExtendedSubscription[] => subscriptions;

// For some unfathomable reason, LS throws errors when you ask for data from a field
// that isn't included in the streaming update. This makes this a bit more sane.
const getValueSafe = (update: SnexLsItemUpdate | ItemUpdate, key: string): string => {
    try {
        return update.getValue(key);
    } catch (e) {
        console.log(`ERROR GETTING VALUE FOR ${key}`);
    }
    return '';
};

export const Lightstreamer = {
    SetUser,
    SetBackEnd,
    Subscribe,
    Unsubscribe,
    UnsubscribeAll,
    ResetAll,
    OnSubscriptionTelemetry,
    GetCurrentUserId,
    GetCurrentSessionId,
    GetSubscriptions,
    Helpers: { getValueSafe }
};
