import { Actions, ReduxApiDelete, ReduxApiGet, ReduxApiPut, ReduxPollingStart } from '.'
import { Urls } from '../../constants'
import { SetHolidayCache } from '../../util'
import { MarketHoliday } from '../models/Market/MarketHoliday'

export const GetMarketHolidaysAction = () => ReduxApiGet(Urls.market.getHolidays(), Actions.Market.GetHolidays)
    .withLoading()
    .useStored(s => s.market.holidays.data)
    .onSuccess(res => { SetHolidayCache(res as MarketHoliday[]); return res })
    .run()

export const UpsertMarketHolidayAction = (id: string, holiday: MarketHoliday) => ReduxApiPut(Urls.market.modifyHoliday(id), Actions.Market.UpsertHoliday, holiday).run()
export const DeleteMarketHolidayAction = (id: string) => ReduxApiDelete(Urls.market.modifyHoliday(id), Actions.Market.DeleteHoliday).run()

export const GetMarketOpenStatusAction = () => ReduxApiGet(Urls.market.getOpenStatus(), Actions.Market.GetOpenStatus)
    .withoutOutput().run()

export const StartPollingMarketStatusAction = () => ReduxPollingStart('market-status', GetMarketOpenStatusAction, 10);