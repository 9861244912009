import { ArrowUpward } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { QuoteSelector } from 'phoenix/constants/ReduxSelectors';
import { QuoteAttribute } from 'phoenix/util';
import React, { ElementType, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { OptionButton } from 'screens/OptionsScreen';
import { UseTabGroupStyles } from 'theming';
import { Spinny, StyledIcon } from '..';
import './TabSelect.scss';

export type TabSelectItem = { label: string | JSX.Element; value: string | number };

export interface TabSelectProps {
    TabComponent?: (props: TabSelectItem) => JSX.Element;
    buttonStyle?: ButtonStyle;
    buttons?: boolean;
    defaultSelected?: string | number;
    disabled?: boolean;
    isSortable?: boolean;
    onSelect?: (value: string | number) => void;
    scrollButtons?: boolean;
    style?: React.CSSProperties;
    symbol?: string;
    values: TabSelectItem[];
    route?: (route: string) => string;
}

export type ButtonColor = 'neutral' | 'positive' | 'negative';
type ButtonStyle = { button?: React.CSSProperties; active?: React.CSSProperties; inactive?: React.CSSProperties; color?: ButtonColor };

const TabSelectComponent = (props: TabSelectProps) => {
    const quote = useSelector(QuoteSelector(props.symbol || ''));
    let buttonColor: ButtonColor = 'neutral';

    if (props.symbol) {
        const getButtonColor = () => {
            const c = QuoteAttribute.getChangePercent(quote, true) || 0;
            if (c === 0) return 'neutral';
            return c > 0 ? 'positive' : 'negative';
        };
        buttonColor = getButtonColor();
    }

    const buttonStyle = props.buttonStyle || { button: {}, active: {}, inactive: {}, color: buttonColor };

    return <TabSelectPresentation {...{ ...props, buttonStyle }} />;
};

export const TabSelect = React.memo(TabSelectComponent);

type TabSelectPresentationProps = {
    TabComponent?: (props: TabSelectItem) => JSX.Element;
    buttonStyle?: ButtonStyle;
    buttons?: boolean;
    defaultSelected?: string | number;
    disabled?: boolean;
    isSortable?: boolean;
    onSelect?: (value: string | number) => void;
    scrollButtons?: boolean;
    style?: React.CSSProperties;
    values: TabSelectItem[];
    textColorOverride?: string;
    route?: (route: string) => string;
};

export const TabSelectPresentation: React.FC<TabSelectPresentationProps> = (props: TabSelectPresentationProps) => {
    const colors = useColors();
    const resolveValueFromIndex = (index: number) => (index >= props.values.length ? props.values[0].value : props.values[index].value);
    const resolveIndexFromValue = useCallback((value: string | number): number => props.values.findIndex((x) => x.value === value), [props.values]);

    const [selectedIndex, setSelectedIndex] = useState<number>(resolveIndexFromValue(props.defaultSelected || ''));
    const [sort, setSort] = useLocalStorage('POSITION_SORT_DIR', '_desc');

    useEffect(() => {
        if (props.defaultSelected) setSelectedIndex(resolveIndexFromValue(props.defaultSelected));
    }, [props.defaultSelected, resolveIndexFromValue]);

    const handleSelect = (value: string | number) => {
        if (props.disabled) return;
        props.onSelect && props.onSelect(value);
        setSelectedIndex(resolveIndexFromValue(value));
    };

    const handleSelectIndex = (index: number) => {
        if (props.disabled) return;
        props.onSelect && props.onSelect(resolveValueFromIndex(index));
        setSelectedIndex(index);
    };

    if (props.buttons) {
        let buttonColor = 'neutral';
        if (props.buttonStyle?.color) buttonColor = props.buttonStyle.color;

        let extraActiveStyles = {};
        let extraInactiveStyles = {};
        if (props.buttonStyle?.active) {
            extraActiveStyles = { ...extraActiveStyles, ...props.buttonStyle.active };
        }

        if (props.buttonStyle?.inactive) {
            extraInactiveStyles = { ...extraInactiveStyles, ...props.buttonStyle?.inactive };
        }
        return (
            <div className='tab-select-buttons' role='tablist' style={props.style}>
                {props.values.map((v, idx) => (
                    <OptionButton
                        color={buttonColor}
                        key={idx}
                        selected={selectedIndex === idx}
                        style={{
                            ...(idx !== props.values.length - 1 ? { marginRight: 12, fontSize: 20 } : { fontSize: 20 }),
                            ...(selectedIndex === idx ? extraActiveStyles : extraInactiveStyles),
                            ...(props.buttonStyle?.button || {})
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.isSortable) {
                                const newSort = sort === '' && selectedIndex === idx ? '_desc' : '';
                                setSort(newSort);
                            }
                            handleSelect(v.value);
                        }}
                        route={props.route ? props.route(v.value.toString()) : undefined}
                    >
                        {v.label}
                        {props.isSortable && selectedIndex === idx && (
                            <Spinny spun={sort === '_desc'}>
                                <StyledIcon IconComponent={ArrowUpward} size='18px' style={{ color: colors.white }} />
                            </Spinny>
                        )}
                    </OptionButton>
                ))}
            </div>
        );
    }

    return (
        <Tabs
            classes={UseTabGroupStyles(props.textColorOverride || colors.generalTextColor, props.style?.color || colors.segmentedControlActiveColor)()}
            className='tab-select'
            scrollButtons={props.scrollButtons}
            textColor='inherit'
            style={{ ...props.style }}
            TabIndicatorProps={{ children: <span />, style: { backgroundColor: 'transparent' } }}
            TabScrollButtonProps={{ style: { color: props.style?.color || colors.segmentedControlActiveColor } }}
            value={selectedIndex}
            variant={props.scrollButtons ? 'scrollable' : undefined}
            onChange={(_, newValue) => handleSelectIndex(newValue)}
        >
            {props.values.map((v, idx) => (
                <Tab
                    disableRipple
                    className={idx === undefined || idx === null || idx === selectedIndex ? 'selected' : undefined}
                    disabled={props.disabled}
                    key={idx}
                    label={props.TabComponent ? props.TabComponent(v) : v?.label}
                    {...(props.route && { component: Link, to: props.route(v.value.toString()) })}
                ></Tab>
            ))}
        </Tabs>
    );
};
