import { SvgIconTypeMap, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Flex } from '..';
import { useColors } from 'hooks/UseColors';
import { LoadingBar } from '../LoadingBar/LoadingBar';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface SnexBadgeProps {
    flavor?: 'blue' | 'orange' | 'green' | 'red' | 'delayed';
    iconComponent?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
        muiName: string;
    };
    onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDelete?: (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    style?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    loading?: boolean;
    whiteSpace?: React.CSSProperties['whiteSpace'];
    noMargin?: boolean;
}

export const SnexBadge = (props: PropsWithChildren<SnexBadgeProps>): JSX.Element => {
    const { style = {}, labelStyle = {}, whiteSpace = 'nowrap', iconComponent: IconComponent, ...restProps } = props;
    const [hovered, setHovered] = useState(false);

    const { primaryItemColor, primaryItemBackdropColor, green, greenLight, orange, orangeLight, red, redLight, white } = useColors();

    const TRANSITION_SPEED = '100ms';
    const hoveredTextColor = 'white';

    const color = (() => {
        switch (props.flavor) {
            case 'blue':
                return {
                    reg: primaryItemColor,
                    light: primaryItemBackdropColor
                };
            case 'green':
                return { reg: green, light: greenLight };
            case 'orange':
                return { reg: orange, light: orangeLight };
            case 'red':
                return { reg: red, light: redLight };
            case 'delayed':
                return { reg: white, light: orange };
            default:
                return { reg: green, light: greenLight };
        }
    })();

    const hoverProps = useMemo(
        () =>
            props.onClick
                ? {
                      onMouseEnter: () => setHovered(true),
                      onMouseLeave: () => setHovered(false)
                  }
                : {},
        [props.onClick]
    );

    if (props.loading)
        return (
            <div onClick={props.onClick}>
                <LoadingBar
                    style={{
                        width: 150,
                        height: 45,
                        margin: '-5px 10px 0 10px',
                        borderRadius: 7
                    }}
                />
            </div>
        );

    return (
        <Flex
            {...hoverProps}
            {...restProps}
            row
            align='center'
            style={{
                background: hovered ? color.reg : color.light,
                padding: '6px 15px',
                borderRadius: 7,
                margin: props.noMargin ? 0 : '5px 10px',
                minHeight: 28,
                transition: TRANSITION_SPEED,
                ...style
            }}
            onClick={(e) => {
                if (props.onClick) {
                    e?.stopPropagation();
                    props.onClick(e);
                }
            }}
            tabIndex={props.onClick ? 0 : -1}
        >
            {IconComponent && (
                <IconComponent
                    style={{
                        color: hovered ? hoveredTextColor : color.reg,
                        fontSize: 15,
                        transition: TRANSITION_SPEED,
                        ...(props.children ? { marginRight: 10 } : {}),
                        ...labelStyle
                    }}
                />
            )}
            {props.children && (
                <Typography
                    style={{
                        color: hovered ? hoveredTextColor : color.reg,
                        whiteSpace,
                        overflow: 'hidden',
                        transition: TRANSITION_SPEED,
                        ...labelStyle
                    }}
                    variant='body2'
                >
                    {props.children}
                </Typography>
            )}
            {props.onDelete && (
                <Close
                    style={{
                        color: hovered ? hoveredTextColor : color.reg,
                        fontSize: 15,
                        transition: TRANSITION_SPEED,
                        ...(props.children ? { marginLeft: 10 } : {}),
                        cursor: 'pointer',
                        ...labelStyle
                    }}
                    onClick={(e) => {
                        if (props.onDelete) {
                            e.stopPropagation();
                            props.onDelete();
                        }
                    }}
                />
            )}
        </Flex>
    );
};
