export const CrossPlatformRelays = {
    ShowPremiumUpgradeModal: 'MODAL_PREMIUM',
    AsyncOperation: 'OP_ASYNC',
    AsyncOperationOutcome: 'OP_ASYNC_OUTCOME',
    SnexLsState: 'LS_STATE_SNEX',
    GainLsState: 'LS_STATE_GAIN',
    TradeMessageOverlay: 'TRADE_MESSAGE_OVERLAY',
    MarketTimeSegment: 'MARKET_TIME_SEG'
}

export type AsyncOperationOutcome = {
    success: 'success' | 'failure'
}
