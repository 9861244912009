// @ts-strict-ignore
import { GetConfig } from 'phoenix/constants';
import { useSecurityTypeFromStoreV2 } from 'phoenix/hooks/UseSecurityType';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';

export const useIsDelayedPricing = (qsi: string) => {
    const userDelayed = useSnexStore((s) => s.user.myInfo.data?.hasDelayedData);
    const isFuturesDelayed = useSnexStore((s) => s.user.myInfo?.data?.isFuturesDataDelayed);
    const configDelayed = useSnexStore((s) => s.system?.config?.data?.usesDelayedPricing);
    const hasFuturesAccount = useSnexStore((s) => s.accounts.all?.data?.some((a) => FuturesSymbol.IsFuturesSymbol(a.accountNumber)));
    const secType = useSecurityTypeFromStoreV2(qsi);

    // Futures are delayed if you don't have a futures account
    if (FuturesSymbol.IsFuturesSymbol(qsi)) return !hasFuturesAccount || isFuturesDelayed;

    // Mutual funds are never "delayed" as they are priced once per day
    if (secType === 'mutual-fund') return false;

    // Equities are delayed if the system config or your user info indicates that you have delayed data
    return userDelayed || configDelayed;
};

export const DelayedPricing = {
    equitiesAreDelayed: () => {
        const configDelayed = GetConfig().Store.getState().system.config?.data?.usesDelayedPricing;
        const userDelayed = GetConfig().Store.getState().user.myInfo?.data?.hasDelayedData;
        return configDelayed || userDelayed;
    },
    futuresAreDelayed: () => {
        const isFuturesDelayed = GetConfig().Store.getState().user.myInfo?.data?.isFuturesDataDelayed;
        return isFuturesDelayed ?? true;
    }
};
