import { ReduxAction } from "phoenix/models"
import { Actions } from "phoenix/redux/actions"
import { GlobalState } from "phoenix/redux/GlobalState"
import { EquityDeepAnalysisBundle } from "phoenix/redux/models/MobileScreens/EquityDeepAnalysisBundle";
import { MobileStateUpdates } from "phoenix/redux/reducers/MobileScreens/MobileStateUpdates";
import { asData } from "phoenix/redux/reducers/ReducerUpdateHelpers";

export const EquityDeepAnalysisScreenReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {

    if (action.type !== Actions.MobileScreens.LoadEquityDeepAnalysisScreen.Success) return state;


    const s = { ...state }
    const bundle = action.data as EquityDeepAnalysisBundle
    const qsi = bundle.symbol

    s.financialStatements.bySymbol[qsi] = {
        balanceSheet: {
            annual: asData(bundle.annualDocuments.balanceSheet),
            quarterly: asData(bundle.quarterlyDocuments.balanceSheet),
        },
        income: {
            annual: asData(bundle.annualDocuments.incomeStatement),
            quarterly: asData(bundle.quarterlyDocuments.incomeStatement),
        },
        cashFlow: {
            annual: asData(bundle.annualDocuments.cashFlow),
            quarterly: asData(bundle.quarterlyDocuments.cashFlow),
        }
    }

    if(!s.securities.bySymbol[qsi]) s.securities.bySymbol[qsi] = {}

    s.securities.bySymbol[qsi].companyStats = asData(bundle.companyStats);
    s.securities.bySymbol[qsi].revenueEstimates = asData(bundle.revenueEstimate);
    s.securities.bySymbol[qsi].dividends = asData(bundle.dividends);
    s.securities.bySymbol[qsi].splits = asData(bundle.splits);

    s.securityAnalysis.bySymbol[qsi] = {
        fundOwners: asData(bundle.fundOwners),
        insiders: asData(bundle.insiders),
        institutionalOwners: asData(bundle.institutionalOwners),
        priceTarget: asData(bundle.priceTarget)
    }

    s.earningsCalls.indexBySymbol[qsi] = asData({ symbol: qsi, transcripts: [ bundle.latestEarningsCall ] })

    MobileStateUpdates.addMetadata(s, [bundle.metadata])
    MobileStateUpdates.addLogos(s, [bundle.logo])

    s.securityQuote.bySymbol[qsi] = asData(bundle.quote)
    s.mobileScreenLoading.equityDeepAnalysis = s.mobileScreenLoading.equityDeepAnalysis.succeeded()

    return s

}