// @ts-strict-ignore
import { AcatRequest } from "phoenix/redux/models/Acats/AcatRequest";
import { AcatPage } from "./AcatTicketStore";

export type CanSubmitTransferResponse = {
    why: string | undefined | null,
    canSubmit: boolean
}

export const assetsDefined = (transfer: AcatRequest) : boolean => {
    if (!transfer) return false;
    if (transfer.type === 'Full') return true;
    else {
        // Partial transfers may only go through if they've elected to transfer something
        const cashDefined = transfer.cashAssets?.quantity !== 'None';
        const positionsDefined = !!transfer.positions?.length;
        return cashDefined || positionsDefined;
    }
}

export const canSubmitTransfer = (transfer: Partial<AcatRequest>) : CanSubmitTransferResponse => {
    const bail = (why: string) : CanSubmitTransferResponse => ({ why : `Submit no good: ${ why }`, canSubmit: false})

    if (!transfer) return bail('Transfer was null');

    // Check type
    if([ 'Full', 'Partial' ].every(t => t !== transfer.type)) return bail(`Transfer type "${ transfer.type }" is not valid`);

    // Check accounts
    const e = transfer.externalAccount || {};
    if(!(e?.deliveringFirmId && e?.accountNumber && e?.title && e?.subtitle)) return bail('External account is missing firm, account number, title, or subtitle');
    if (!transfer.snexAccountNumber) return bail('Snex account number not defined');

    if (!assetsDefined(transfer)) return bail('No asset elected');

    if (transfer.type === 'Partial') {
        // Also, check that custom quantities were defined if required
        if (transfer.cashAssets?.quantity === 'Custom' && !transfer?.cashAssets?.quantityDollars) return bail('Custom cash has no amount defined')
        if (!(transfer.positions?.every(p => p.fullQuantity || !!p.quantity))) return bail('Not all custom positions have an amount defined');
    }

    return {why: 'Submit good!', canSubmit: true}
}

export const canSubmitPage = (transfer: Partial<AcatRequest>, page: AcatPage) : boolean => {
    const accountSelect = [
        transfer?.snexAccountNumber,
        transfer?.externalAccount?.deliveringFirmId,
        transfer?.externalAccount?.accountNumber,
        transfer?.externalAccount?.title,
        transfer?.externalAccount?.subtitle
    ];
    const transferType = [
       transfer?.type
    ]

    switch(page)
    {
        case 'account-select' : return accountSelect.length && accountSelect.every(x => !!x)
        case 'transfer-type': return transferType.length && transferType.every(x => !!x)
        case 'cash-options' : return transfer?.cashAssets?.quantity === 'Custom' ? 
                                transfer?.cashAssets?.quantityDollars > 0 : 
                                !!transfer?.cashAssets?.quantity
        // case 'confirm': {
        //     const result = canSubmitTransfer(transfer)
        //     set((s) => ({...s, canSubmitReason: result.why}))
        //     // eslint-disable-next-line no-console -- writing confirmation reason to console for debugging by support
        //     if(result?.why) console.log(result.why) 
        //     return result.canSubmit
        // }
        default: return true
    }
}