// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react';
import { ActionModal } from '../ActionModal';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Flex } from '../..';
import { UseDeleteButtonStyles } from 'theming';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { DeleteWatchlistAction } from 'phoenix/redux/actions/WatchlistsActions';
import { useText } from 'phoenix/hooks/UseText';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { FormattedTextDisplay } from '../../FormattedTextDisplay/FormattedTextDisplay';
import { useColors } from 'hooks/UseColors';

interface DeleteListModalProps {
    isOpen: boolean;
    listId: string;
    toggleModal: (nextOpenState: boolean) => void;
}

export const DeleteListModal = React.memo((props: DeleteListModalProps) => {
    const { isOpen, toggleModal, listId } = props;
    const colors = useColors();

    const buttonClasses = UseDeleteButtonStyles(colors)();

    const dispatch = useDispatch();
    const watchlists = useSnexStore(s => s.watchlists.all);
    const deleting = useSnexStore((s: GlobalState) => s.watchlists.deletesById[listId]);
    const text = useText(s => s.modals.deleteWatchlist)
    
    const handleDeleteList = useCallback(async () => {
        const result: any = await dispatch(DeleteWatchlistAction(listId));
        if (!result.failed) toggleModal(false)
    }, [ listId ]);

    const activeList = useMemo(() => props.listId && isOpen ? watchlists.data.find(list => list.id === listId) : null, [ deleting, listId, isOpen ]);

    return (
        <ActionModal height='auto' isOpen={isOpen} label={ text.deleteThisList } toggleModal={toggleModal}>
            <>
                <Flex align='center' style={{ flex: 1, padding: '15px 0', margin: '50px 0 25px 0' }}>
                    <FormattedTextDisplay text={ text.areYouSure(activeList?.name) } />
                </Flex>
                <Flex>
                    <Button classes={{ ...buttonClasses }} disabled={ deleting?.loading } onClick={handleDeleteList}>
                        { (deleting?.loading) ? text.deleting : text.yesDeleteList(activeList?.name) }
                    </Button>
                </Flex>
            </>
        </ActionModal>
    );
});