import { Logical, LogicalRange } from 'lightweight-charts';
import _ from 'lodash';

/**
 * Type-safe(r) version of {@link _.merge}.
 * Ensures returned object is non-partial.
 */
export function merge<T, U>(base: T, onTop: U): T {
    return _.merge({}, base, onTop);
}

// Determine whether or not to consider the chart to currently be scaled by the user
// Sometimes the chart scales the visible range on its own
// possibly due to rounding errors while plotting time to coordinates
// Check to see that the original and the new ranges are within specified tolerance
// This may allow a user to scale without the chart detecting it so keep the tolerance as small as possible
export function getShouldSetScalingActive({
    canScale,
    currentRange,
    newRange,
    tolerance = 1 // Represents one data point difference in the visible range
}: {
    canScale: boolean;
    currentRange?: LogicalRange;
    newRange?: LogicalRange | null;
    tolerance?: number;
}): boolean {
    if (!canScale || !newRange || !currentRange) return false;

    const exceedsFrom = Math.abs((newRange?.from || 0) - (currentRange?.from || 0)) > tolerance;
    const exceedsTo = Math.abs((newRange?.to || 0) - (currentRange?.to || 0)) > tolerance;
    const exceedsTolerance = exceedsFrom || exceedsTo;

    return exceedsTolerance;
}
