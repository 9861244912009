// @ts-strict-ignore
import { Urls } from '../../constants';
import { GlobalState } from '../GlobalState';
import { ApiPosition, OptionSymbol, PositionsSummary, RealizedPnlItem } from '../models';
import { Actions } from './Constants';
import { ReduxApiGet } from './Helpers';
import uuid from 'uuid-random';
import { GroupBySelect } from '../../util';
import { ConvertUnquotedPositionsToLegacyPositionsSummary } from '../models/Positions/UnquotedPosition';
import { SnexApiClosedPositionGroup } from '../models/Positions/ClosedPosition';
import { ApiData, ApiError } from '../../models';

type GroupKeyFormat = 'account-security-longness' | 'security-longness';
const prefixes: Record<GroupKeyFormat, (p: ApiPosition, longness: 'short' | 'long') => string> = {
    'account-security-longness': (p, long) => `${p.accountNumber}:${long}`,
    'security-longness': (p, long) => long
};

// Creates a group key for a position according to the provided format
export const MakePositionGroupKey = (p: ApiPosition, group: GroupKeyFormat = 'account-security-longness'): string => {
    const longness = p?.quantitySummary?.short ? 'short' : 'long';
    const prefix = prefixes[group](p, longness);

    switch (true) {
        case p.productType === 'Cash & Equivalents':
            return `${prefix}:${p.currency}`;

        // Futures do not have security numbers
        // TODO: Make productType and securityType more intuitive
        // Eg: Futures options are productType "Options" and securityType "Futures"
        case p.productType === 'Cryptos' || p.productType === 'Futures' || p.securityType === 'Futures':
            // return `${p.accountNumber}:${p?.secMasterOptionSymbol || p.symbol}:${longness}`
            return `${prefix}:${p?.secMasterOptionSymbol || p.symbol}`;

        default:
            return `${prefix}:${p.securityNumber}`;
    }
};

const naNAdd = (n1: number, n2: number) => (isNaN(n1) ? 0 : n1) + (isNaN(n2) ? 0 : n2);
export const MergePositions = (p: ApiPosition[]): ApiPosition => {
    return p.reduce(
        (merged, p) =>
            <ApiPosition>{
                ...p,
                quantity: naNAdd(merged.quantity, p.quantity),
                marketValue: naNAdd(merged.marketValue, p.marketValue),
                todaysGL: naNAdd(merged.todaysGL, p.todaysGL),
                quantitySummary: {
                    cash: naNAdd(merged.quantitySummary?.cash, p.quantitySummary?.cash),
                    margin: naNAdd(merged.quantitySummary?.margin, p.quantitySummary?.margin),
                    short: naNAdd(merged.quantitySummary?.short, p.quantitySummary?.short),
                    total: naNAdd(merged.quantitySummary?.total, p.quantitySummary?.total)
                },
                longPrice: naNAdd(merged.longPrice, p.longPrice),
                shortPrice: naNAdd(merged.shortPrice, p.shortPrice),
                longVolume: naNAdd(merged.longVolume, p.longVolume),
                shortVolume: naNAdd(merged.shortVolume, p.shortVolume),
            },
        {} as ApiPosition
    );
};

const ProcessPositions = (res: PositionsSummary[]): PositionsSummary[] => {
    return (
        res?.map((s) => ({
            ...s,
            // Merge positions with the same sec number and acct number up into one
            positions: Object.entries(GroupBySelect(s.positions, (p) => MakePositionGroupKey(p))).map((e) => {
                const positions = e[1];

                const merged = MergePositions(positions);

                if (merged.productType !== 'Options') return merged;
                if (merged.securityType === 'Futures') {
                    const fOptSym = new OptionSymbol(merged.secMasterOptionSymbol);
                    merged.optionSymbol = fOptSym;
                    merged.optionOccSymbol = fOptSym.toOccSymbol();
                    merged.optionOsiSymbol = fOptSym.toOsiSymbol();
                    merged.id = `${merged.secMasterOptionSymbol}-${merged.accountNumber}-${uuid()}`;
                    return merged;
                }
                const splitOptionSymbol = merged.secMasterOptionSymbol?.split(/ +/);
                merged.secMasterOptionSymbol =
                    splitOptionSymbol?.length > 1 ? `${merged.symbol}${splitOptionSymbol[1]}` : merged.secMasterOptionSymbol?.replace(/\s*/, '');

                let optSym = null;
                if (OptionSymbol.IsOptionSymbol(merged.symbol)) optSym = new OptionSymbol(merged.symbol);
                else {
                    optSym = new OptionSymbol(merged.secMasterOptionSymbol || merged.description, merged.symbol);
                }
                merged.optionSymbol = optSym;
                merged.optionOccSymbol = optSym.toOccSymbol();
                merged.optionOsiSymbol = optSym.toOsiSymbol();
                merged.id = `${merged.securityNumber}-${merged.accountNumber}-${uuid()}`;
                return merged;
            })
        })) || []
    );
};

/* @deprecated Move from using redux actions to the usePositionsStore hook. */
export const GetAllPositions = (useCache: 'always-use-cache' | 'never-use-cache' | 'default-caching' = 'default-caching') => {
    const url = Urls.positions.all();
    let r = ReduxApiGet(url, Actions.Positions.GetAll)
        .withMutex(() => 'all-positions', true)
        .onSuccess((res: { data: PositionsSummary[]; errors: ApiError[] }) => ProcessPositions(res?.data));

    const sel = (s: GlobalState) => (s.positions.all.data?.length ? s.positions.all.data : null);
    switch (useCache) {
        case 'always-use-cache':
            r = r.useStored(sel);
            break;
        case 'never-use-cache':
            break;
        default:
            r = r.useStored(sel, () => 'all-positions', 15);
            break;
    }

    return r.run();
};

/* @deprecated Move from using redux actions to the usePositionsStore hook. */
export const GetClosedPositions = (year: number = new Date().getFullYear(), useCache: 'always-use-cache' | 'never-use-cache' | 'default-caching' = 'default-caching') => {
    const url = Urls.positions.closed(year);
    let r = ReduxApiGet(url, Actions.Positions.Closed)
        .withLoading()
        .withMutex(() => `closed-positions-${year}`, true)
        .onSuccess((res: SnexApiClosedPositionGroup[]) => res.map((g) => ({ ...g, items: new ApiData<RealizedPnlItem[]>(g.items || []) })));

    const sel = (s: GlobalState) => (s.positions.closed.data?.length ? s.positions.closed.data : null);
    switch (useCache) {
        case 'always-use-cache':
            r = r.useStored(sel);
            break;
        case 'never-use-cache':
            break;
        default:
            r = r.useStored(sel, () => 'all-positions', 15);
            break;
    }

    return r.run();
};

/* Seemingly unused */
/* @deprecated Move from using redux actions to the usePositionsStore hook. */
export const GetAllPositionsUnpriced = () =>
    ReduxApiGet(Urls.positions.allUnpriced(), Actions.Positions.GetAllUnpriced)
        .onSuccess((res: any) => ProcessPositions(ConvertUnquotedPositionsToLegacyPositionsSummary(res)))
        .run();

/* Not deprecated as RealizedPnLAction is tied to futures symbol metadata. */
export const GetRealizedPnlAction = (symbol: string) =>
    ReduxApiGet(Urls.positions.realizedPnl(symbol), Actions.Positions.GetRealizedPnl)
        .withLoading()
        .useStored((s) => s.positions.closed?.data.find((p) => p.symbol === symbol)?.items?.data[0])
        .withSubject(symbol)
        .run();
