// @ts-strict-ignore
import React from 'react';

export const AuthorAvatar = ({
    url,
    borderColor,
    onClick,
    size,
    borderRadius
}: {
    url?: string;
    borderColor: string;
    onClick?: () => void;
    size?: number;
    borderRadius?: number;
}): JSX.Element => {
    if (!url) return null;
    return (
        <div
            style={{
                height: size || 48,
                width: size || 48,
                minWidth: size || 48,
                borderRadius: borderRadius || (size ? size * 0.5 : 24),
                border: `2px solid ${borderColor}`,
                boxSizing: 'border-box',
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                cursor: onClick ? 'pointer' : 'default'
            }}
            onClick={onClick}
        />
    );
};
