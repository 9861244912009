import { AddCircleOutlineOutlined } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { GetConfig, Urls } from 'phoenix/constants';
import { GetVariant } from 'phoenix/util/Variant';
import { Flex, LoadingSpinner } from 'components';
import { SnexPopover } from 'components/Popovers/SnexPopover';
import { useColors } from 'hooks/UseColors';
import { SelectListItem } from 'components/SelectList';
import { Link, Typography } from '@mui/material';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useDispatch } from 'react-redux';
import { GetMaxNumberOfFuturesAccounts } from 'phoenix/redux/actions/AccountsActions';
import style from './style.module.scss';
import { useText } from 'phoenix/hooks/UseText';
import { UseAuthorizationSettings } from 'phoenix/hooks/UseAuthorizationSettings';
import { useActiveAndPendingAccounts } from 'phoenix/hooks/useActiveAndPendingAccounts';
import { GetAuthorizationSettings } from 'phoenix/redux/actions/AuthorizationSettingsActions';

export const AddAccountButton = React.memo((props: { disabled?: boolean; style?: React.CSSProperties }) => {
    const { canAddAccount, canAddFuturesAccount } = GetVariant();
    const [isOpen, setIsOpen] = useState(false);
    const allowFuturesOnboarding = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.FuturesOnboarding]);
    const dispatch = useDispatch();
    const maxFutures = useSnexStore((s) => s.accounts.maxFutures);
    const hasReachedMaxFuturesAccounts = maxFutures?.data?.hasReachedMax || false;
    const text = useText((t) => t.misc);
    const loading = maxFutures?.pristine || maxFutures?.loading;

    const colors = useColors();
    const {data: variantSettings} = UseAuthorizationSettings()

    useEffect(() => {
        if(GetConfig()?.Store.getState().authorizationSettings?.settings?.pristine )
        dispatch(GetAuthorizationSettings());
    }, [dispatch]);

    const addAccountOptions = [
        variantSettings?.allowedOnboardingTypesForEnvironment.some(x => x.toLowerCase() === "equities") ?
            { label: text.addEquitiesAccount, value: Urls.registration.addAccount() }: null,
        variantSettings?.allowedOnboardingTypesForEnvironment.some(x => x.toLowerCase() === "futures") ?
        { label: text.addFuturesAccount, value: Urls.futuresOnboarding.addAccount() } : null
    ].filter(x => x !== null);
    const handleSelectChange = (items: SelectListItem[]) => {
        const selectedItem = items[0].value.toString();
        switch (selectedItem) {
            case Urls.registration.addAccount():
                window.open(selectedItem, '_blank');
                break;
            case Urls.futuresOnboarding.addAccount():
            default:
                window.location.href = selectedItem;
                break;
        }
    };

    const activeAndPendingAccounts = useActiveAndPendingAccounts({ withRequests: true });
    const hasAnyFuturesAccounts = activeAndPendingAccounts.accountTypes.has('futures');

    useEffect(() => {
        dispatch(GetMaxNumberOfFuturesAccounts());
    }, [dispatch]);

    const showAddFuturesAccount = canAddFuturesAccount && allowFuturesOnboarding?.enabled && !hasReachedMaxFuturesAccounts && !hasAnyFuturesAccounts;
    const TextAndButton = useCallback(
        () => (
            <Flex row style={{ gap: '12px', cursor: loading ? 'auto' : 'pointer' }}>
                {showAddFuturesAccount ? (
                    <Typography variant='h6' color='primary'>
                        {text.addAccount}
                    </Typography>
                ) : (
                    <Typography variant='h6' color='primary'>
                        {text.addEquitiesAccount}
                    </Typography>
                )}
                <AddCircleOutlineOutlined style={{ color: colors.primaryItemColor, fontSize: 22, cursor: 'pointer' }} />
            </Flex>
        ),
        [loading, showAddFuturesAccount, text.addAccount, text.addEquitiesAccount, colors.primaryItemColor]
    );


    if (!canAddAccount || !addAccountOptions.length) return null;
    if (showAddFuturesAccount) {
        return (
            <>
                {loading ? (
                    <LoadingSpinner size={22} />
                ) : (
                    <div style={{ position: 'relative' }} onClick={() => !loading && setIsOpen(true)}>
                        <TextAndButton />
                        <SnexPopover open={isOpen} paperStyles={{ width: 380, cursor: 'pointer' }} left={0} top={isOpen ? 32 : 0} onClose={() => setIsOpen(false)}>
                            <Flex column justify='center' style={{ height: '100%', width: '100%' }}>
                                {addAccountOptions?.map((item, index) => (
                                    <Flex
                                        className={style['add-account-select-list-item']}
                                        row
                                        fullWidth
                                        key={index}
                                        onClick={() => handleSelectChange([item as SelectListItem])}
                                        style={{ padding: '7px 15px' }}
                                    >
                                        <Typography style={{ fontWeight: 600, fontSize: '15px', color: colors.primaryItemColor }}>{item?.label}</Typography>
                                    </Flex>
                                ))}
                            </Flex>
                        </SnexPopover>
                    </div>
                )}
            </>
        );
    }
    return (
        <Link href={Urls.registration.addAccount()} style={props.style} target='_blank'>
            <TextAndButton />
        </Link>
    );
});
