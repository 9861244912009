// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { DocumentsState } from '../models/Documents/DocumentsState'
import { PaperlessElectionType } from '../models/Documents/PaperlessPref'

const permitted = GroupNameChecker([Actions.Documents])
export const DocumentsReducer = (state: DocumentsState = new DocumentsState(), action: ReduxAction): DocumentsState => {
    if (!permitted(action)) return state

    const withPaperlessPrefUpdate = (election: string): DocumentsState => {
        if (!election) return state
        const { accountNumber, documentType } = (action.subject || {})
        return {
            ...state,
            paperlessPrefs: state.paperlessPrefs.succeeded(state.paperlessPrefs.data?.map(p => p.accountNumber === accountNumber && p.deliveryElection.key === documentType
                ? { ...p, electionType: <PaperlessElectionType>election }
                : p)
            )
        }
    }

    const oppositeElection = action.subject?.election === 'Mail' ? 'eDelivery' : 'Mail'

    switch (action.type) {
        case Actions.Documents.GetTypes.Loading: return { ...state, types: state.types.startLoading(state.types.data) }
        case Actions.Documents.GetTypes.Success: return { ...state, types: state.types.succeeded(action.data) }
        case Actions.Documents.GetTypes.Failure: return { ...state, types: state.types.failed(action.error) }

        case Actions.Documents.GetTypesV2.Loading: return { ...state, typesV2: state.typesV2.startLoading(state.typesV2.data) }
        case Actions.Documents.GetTypesV2.Success: return { 
            ...state, 
            typesV2: state.typesV2.succeeded(action.data),
            typesV2ByAssetFamily: action.subject ? {...state.typesV2ByAssetFamily, [action.subject] : state.typesV2.succeeded(action.data)} : state.typesV2ByAssetFamily
        }
        case Actions.Documents.GetTypesV2.Failure: return { ...state, typesV2: state.typesV2.failed(action.error) }

        case Actions.Documents.Search.Loading: return { ...state, searchResults: state.searchResults.startLoading(state.searchResults.data) }
        case Actions.Documents.Search.Success: {
            const nextData = state.searchResults.succeeded(action.passthrough.append ? (state.searchResults.data || []).concat(action.data) : action.data)
            return { ...state, searchResults: nextData }
        }
        case Actions.Documents.Search.Failure: return { ...state, searchResults: state.searchResults.failed(action.error) }

        case Actions.Documents.SearchV2.Loading: return { ...state, searchResultsV2: state.searchResultsV2.startLoading(state.searchResultsV2.data) }
        case Actions.Documents.SearchV2.Success: {
            const nextDataV2 = state.searchResultsV2.succeeded(action.passthrough.append ? (state.searchResultsV2.data || []).concat(action.data) : action.data)
            return { ...state, searchResultsV2: nextDataV2 }
        }
        case Actions.Documents.SearchV2.Failure: return { ...state, searchResults: state.searchResults.failed(action.error) }

        case Actions.Documents.Download.Loading: return { ...state, download: state.download.startLoading(action.data) }
        case Actions.Documents.Download.Success: return { ...state, download: state.download.succeeded(action.data) }
        case Actions.Documents.Download.Failure: return { ...state, download: state.download.failed(action.error) }

        case Actions.Documents.GetPaperlessPrefs.Loading: return { ...state, paperlessPrefs: state.paperlessPrefs.startLoading(state.paperlessPrefs.data) }
        case Actions.Documents.GetPaperlessPrefs.Success: return { ...state, paperlessPrefs: state.paperlessPrefs.succeeded(action.data) }
        case Actions.Documents.GetPaperlessPrefs.Failure: return { ...state, paperlessPrefs: state.paperlessPrefs.failed(action.error) }

        case Actions.Documents.UpdatePaperlessPrefs.Loading: return { ...withPaperlessPrefUpdate(action.subject?.election), paperlessUpdate: state.paperlessUpdate.startLoading() }
        case Actions.Documents.UpdatePaperlessPrefs.Success: return { ...withPaperlessPrefUpdate(action.subject?.election), paperlessUpdate: state.paperlessUpdate.succeeded() }
        case Actions.Documents.UpdatePaperlessPrefs.Failure: return { ...withPaperlessPrefUpdate(oppositeElection), paperlessUpdate: state.paperlessUpdate.failed(action.data) }

        case Actions.Documents.GoCompletelyPaperless.Loading: return {
            ...state,
            paperlessPrefs: state.paperlessPrefs.succeeded(state.paperlessPrefs.data.map(p => ({ ...p, electionType: action.subject?.election }))),
            paperlessCompletely: state.paperlessCompletely.startLoading()
        }
        case Actions.Documents.GoCompletelyPaperless.Success: return { ...state, paperlessCompletely: state.paperlessCompletely.succeeded(action.data) }
        case Actions.Documents.GoCompletelyPaperless.Failure: return { ...state, paperlessCompletely: state.paperlessCompletely.failed(action.data) }

        default: return state
    }
}
