// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AcatsState } from '../models/Acats/AcatsState'

const check = GroupNameChecker([Actions.Acats])
export const AcatsReducer = (state: AcatsState = new AcatsState(), action: ReduxAction): AcatsState => {
    if (!check(action)) return state

    switch (action.type) {
        case Actions.Acats.GetDeliveringFirms.Loading: return { ...state, deliveringFirms: state.deliveringFirms.startLoading() }
        case Actions.Acats.GetDeliveringFirms.Success: return { ...state, deliveringFirms: state.deliveringFirms.succeeded(action.data) }
        case Actions.Acats.GetDeliveringFirms.Failure: return { ...state, deliveringFirms: state.deliveringFirms.failed(action.error) }

        case Actions.Acats.Submit.Loading: return { ...state, submit: state.submit.startLoading() }
        case Actions.Acats.Submit.Success: return { ...state, submit: state.submit.succeeded(action.data) }
        case Actions.Acats.Submit.Failure: return { ...state, submit: state.submit.failed(action.error) }
        case Actions.Acats.ResetSubmit: return { ...state, submit: new ApiData(null) }

        default: return state
    }
}
