import { GetDisablePrestage } from 'phoenix/util';
import React, { ReactNode, useEffect, useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';

interface PrestageProps {
    delayShowMs?: number;
    delayAnimMs?: number;
    leadingSpace?: number;
    minHeight?: number;
    debugTitle?: string;
    children: ReactNode;
}

const PrestageFunction = (props: PrestageProps) => {
    const [elapsed, setElapsed] = useState(false);
    const [visible, setVisible] = useState(false);
    const show = visible && elapsed;

    useEffect(() => {
        setTimeout(() => {
            setElapsed(true);
        }, props.delayShowMs || 1);
    }, [props.delayShowMs]);

    if (GetDisablePrestage()) return <>{props.children}</>;

    // Only update visibility once-- once the component is in, it's in
    const handleVisibilityChange = (value: boolean) => {
        if (!visible && value) setVisible(true);
    };

    return (
        <ReactVisibilitySensor partialVisibility onChange={handleVisibilityChange}>
            <div
                style={{
                    minHeight: !show ? props.minHeight || 250 : 0,
                    transitionProperty: 'opacity',
                    transitionDuration: '100ms',
                    transitionDelay: `${props.delayAnimMs || 200}ms`
                }}
            >
                {visible && props.children}
            </div>
        </ReactVisibilitySensor>
    );
};

export const Prestage = React.memo(PrestageFunction);
