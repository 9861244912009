// @ts-strict-ignore
import { DEBUG_JWT_KEY, DEBUG_JWT_NAME, GetConfig, SetConfig, StorageKeys } from 'phoenix/constants';
import { LogInWithToken, LogOutWithToken } from 'phoenix/redux/actions';
import { JwtHasExpired, TryGetSecureAuthCdsId } from 'phoenix/util';
import { Dispatch } from 'redux';
import { SetMemo } from './Utils';

export interface WebTokenResult {
    exists?: boolean;
    isActive?: boolean;
    token?: string;
}

export const NormalizeJwtSource = (jwt: string): string | null => {
    try {
        if (!jwt) return null;
        return jwt[0] === '{' ? JSON.parse(jwt).accessToken : jwt;
    } catch {
        return null;
    }
};

export const GetLocalToken = (): WebTokenResult => {
    const token = localStorage.getItem(DEBUG_JWT_KEY);
    if (!token) return { exists: false, isActive: false, token: null };
    const tokenSource = NormalizeJwtSource(token);
    if (JwtHasExpired(tokenSource)) return { exists: true, isActive: false, token };
    return { exists: true, isActive: true, token };
};
export const HasActiveToken = (): boolean => {
    const tokenResult = GetLocalToken();
    return tokenResult.exists && tokenResult.isActive;
};

export const SubmitLocalJwt = async (jwt: string, dispatch: Dispatch<{ type: string; data: string }>): Promise<void> => {
    const tokenSource = NormalizeJwtSource(jwt);
    if (JwtHasExpired(tokenSource)) alert('Warning: Signing in with an expired JWT');
    dispatch(LogInWithToken(tokenSource));
    localStorage.setItem(DEBUG_JWT_KEY, tokenSource);
    localStorage.setItem(DEBUG_JWT_NAME, TryGetSecureAuthCdsId(tokenSource));
    SetMemo(StorageKeys.JwtMemo, tokenSource);
    SetConfig({ ...GetConfig(), UserIdToken: tokenSource });
};

export const ClearLocalJwt = (jwt: string, dispatch: Dispatch<{ type: string; data: null }>): void => {
    dispatch(LogOutWithToken());
    localStorage.removeItem(DEBUG_JWT_KEY);
    SetConfig({ ...GetConfig(), UserIdToken: null });
};
