// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { FinancialStatementsState } from '../models/FinancialStatements/FinancialStatementsState'

const permitted = GroupNameChecker([Actions.FinancialStatements])
export const FinancialStatementsReducer = (state: FinancialStatementsState = new FinancialStatementsState(), action: ReduxAction): FinancialStatementsState => {
    if (!permitted(action)) return state

    const withDocumentUpdate = (docSubstate: string, update: (data: ApiData<any>) => ApiData<any>) => {
        const sym = state.bySymbol[action.subject] || {}
        const doc = sym[docSubstate] || {}
        const apiData = doc[action.passthrough.period] || new ApiData(null)

        return {
            ...state.bySymbol,
            [action.subject]: {
                ...(state.bySymbol[action.subject] || { balanceSheet: {}, cashFlow: {}, income: {} }),
                [docSubstate]: {
                    ...doc,
                    [action.passthrough.period]: update(apiData)
                }
            }
        }
    }

    switch (action.type) {
        case Actions.FinancialStatements.BalanceSheet.Loading: return { ...state, bySymbol: withDocumentUpdate('balanceSheet', u => u.startLoading(u.data)) }
        case Actions.FinancialStatements.BalanceSheet.Success: return { ...state, bySymbol: withDocumentUpdate('balanceSheet', u => u.succeeded(action.data)) }
        case Actions.FinancialStatements.BalanceSheet.Failure: return { ...state, bySymbol: withDocumentUpdate('balanceSheet', u => u.failed(action.error)) }

        case Actions.FinancialStatements.CashFlow.Loading: return { ...state, bySymbol: withDocumentUpdate('cashFlow', u => u.startLoading(u.data)) }
        case Actions.FinancialStatements.CashFlow.Success: return { ...state, bySymbol: withDocumentUpdate('cashFlow', u => u.succeeded(action.data)) }
        case Actions.FinancialStatements.CashFlow.Failure: return { ...state, bySymbol: withDocumentUpdate('cashFlow', u => u.failed(action.error)) }

        case Actions.FinancialStatements.Income.Loading: return { ...state, bySymbol: withDocumentUpdate('income', u => u.startLoading(u.data)) }
        case Actions.FinancialStatements.Income.Success: return { ...state, bySymbol: withDocumentUpdate('income', u => u.succeeded(action.data)) }
        case Actions.FinancialStatements.Income.Failure: return { ...state, bySymbol: withDocumentUpdate('income', u => u.failed(action.error)) }

        default: return state
    }
}
