import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { ActionModal } from 'components';
import { useText } from 'phoenix/hooks/UseText';

export default ({ isOpen, setOpen }: { isOpen: boolean; setOpen: (x: boolean) => void }): ReactElement => {
    const text = useText((t) => t.general.ninetyDayRestriction);

    return (
        <ActionModal isOpen={isOpen} label={text.title} showScrollbar toggleModal={() => setOpen(false)} width={400}>
            <Typography variant='body1' style={{ fontSize: 14 }}>
                {text.description}
                <ul>
                    {text.list.map((x, i) => (
                        <li key={i}>{x}</li>
                    ))}
                </ul>
                {text.secondParagraph}
            </Typography>
        </ActionModal>
    );
};
