import React from 'react';
import { useText } from 'phoenix/hooks/UseText';

export const CandleStickButton = ({ height = 25 }: {height?: number}) => {
    const text = useText(s => s.securityScreen)
    return (
        <svg
            height={`${height}px`}
            version='1.1'
            viewBox='0 0 240 240'
            width='30px'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <title>{ text.candlestick }</title>
            <g
                fill='none'
                fillRule='evenodd'
                id='Page-1'
                stroke='none'
                strokeWidth='1'>
                <g
                    id='investing-finance-icons'
                    transform='translate(-685.000000, -1075.000000)'>
                    <g
                        id='trade'
                        transform='translate(685.000000, 1075.000000)'>
                        <g id='bounding-box'>
                            <polygon
                                id='shape'
                                points='0 0 240 0 240 240 0 240'></polygon>
                        </g>
                        <rect
                            fillRule='nonzero'
                            height='35'
                            id='Rectangle'
                            rx='8'
                            stroke='#F74646'
                            strokeWidth='10'
                            width='20'
                            x='175'
                            y='70'></rect>
                        <rect
                            fill='#1BB82E'
                            fillRule='nonzero'
                            height='20'
                            id='line'
                            width='10'
                            x='60'
                            y='150'></rect>
                        <rect
                            fill='#F74646'
                            fillRule='nonzero'
                            height='20'
                            id='line-copy'
                            width='10'
                            x='100'
                            y='40'></rect>
                        <rect
                            fill='#F74646'
                            fillRule='nonzero'
                            height='20'
                            id='line-copy-2'
                            width='10'
                            x='100'
                            y='180'></rect>
                        <rect
                            fill='#1BB82E'
                            fillRule='nonzero'
                            height='20'
                            id='line-copy-3'
                            width='10'
                            x='140'
                            y='60'></rect>
                        <rect
                            fill='#1BB82E'
                            fillRule='nonzero'
                            height='20'
                            id='line-copy-4'
                            width='10'
                            x='140'
                            y='155'></rect>
                        <rect
                            fill='#F74646'
                            fillRule='nonzero'
                            height='20'
                            id='line-copy-5'
                            width='10'
                            x='180'
                            y='45'></rect>
                        <rect
                            fill='#F74646'
                            fillRule='nonzero'
                            height='20'
                            id='line-copy-6'
                            width='10'
                            x='180'
                            y='110'></rect>
                        <rect
                            fill='#1BB82E'
                            fillRule='nonzero'
                            height='20'
                            id='line'
                            width='10'
                            x='60'
                            y='210'></rect>
                        <rect
                            fillRule='nonzero'
                            height='65'
                            id='Rectangle-Copy'
                            rx='8'
                            stroke='#1BB82E'
                            strokeWidth='10'
                            width='20'
                            x='135'
                            y='85'></rect>
                        <rect
                            fillRule='nonzero'
                            height='110'
                            id='Rectangle-Copy-2'
                            rx='8'
                            stroke='#F74646'
                            strokeWidth='10'
                            width='20'
                            x='95'
                            y='65'></rect>
                        <rect
                            fillRule='nonzero'
                            height='32'
                            id='Rectangle-Copy-3'
                            rx='8'
                            stroke='#1BB82E'
                            strokeWidth='10'
                            width='20'
                            x='55'
                            y='173'></rect>
                    </g>
                </g>
            </g>
        </svg>
    );
};
