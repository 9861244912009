// @ts-strict-ignore
import React, { useEffect, useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Controls, SliderControl } from './controls';
import { useModalVideoPlayerStore } from './videoPlayerHelpers';
import { Flex } from 'components/Flex';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import style from './style.module.scss';
import { PostTitleCard } from './titleCard';

export const VideoPlayer = (): JSX.Element => {
    const { actions, state, data } = useModalVideoPlayerStore();
    const playerRef = useRef(null);

    useEffect(() => {
        actions.setVisible(state.isVisible);
    }, [state.isVisible, actions]);
    const isModal = useMemo(() => state.variant === 'modal', [state.variant]);

    return (
        <div id={'modal-video-player'} style={{ visibility: state.isVisible ? 'visible' : 'hidden' }}>
            <div className={style['modal-overlay']} />
            <div className={!isModal ? style['videoPlayer-modal-wrapper-fullscreen'] : style['videoPlayer-modal-wrapper']}>
                {isModal && (
                    <ModalHeader
                        onClose={() => {
                            actions.reset();
                        }}
                    />
                )}
                {!data?.post?.url ? (
                    <SkeletonVideo />
                ) : (
                    <>
                        <Flex
                            column
                            className={style['video-frame']}
                            style={{
                                backgroundImage: state.isPlaying !== null ? 'none' : `url(${data.post.thumbnail})`,
                                height: isModal ? 360 : '100%',
                                width: isModal ? 640 : '100%',
                                visibility: state.isVisible ? 'visible' : 'hidden'
                            }}
                        >
                            <ReactPlayer
                                url={data?.post?.url}
                                ref={playerRef}
                                playing={state.isPlaying}
                                loop={false}
                                controls={false}
                                muted={state.isMuted}
                                onBuffer={() => actions.setBuffering(true)}
                                onBufferEnd={() => actions.setBuffering(false)}
                                onDuration={(e) => actions.setDuration(e)}
                                onProgress={(e) => {
                                    if (!state.isSeeking) actions.setCurrentTime(e.playedSeconds);
                                }}
                                height={'100%'}
                                width={'auto'}
                                style={{
                                    opacity: (state.isPlaying === null && data?.post?.thumbnail) || !state.isVisible ? 0 : 1,
                                    transition: !state.isVisible ? 'none' : '500ms ease all'
                                }}
                                config={{
                                    youtube: {
                                        playerVars: {
                                            controls: 0,
                                            autoplay: 0,
                                            isMuted: state.isMuted? 1: 0,
                                            modestbranding: 1,
                                            rel: 0,
                                            fs: 0,
                                            disablekb: 0,
                                            showinfo: 0,
                                        }
                                    },
                                    vimeo: {
                                        playerOptions: {
                                            controls: false,
                                            muted: state.isMuted,
                                            progressBar: false,
                                            autoplay: false
                                        }
                                    }
                                }}
                            />
                        </Flex>

                        <PostTitleCard
                            {...{
                                style: { padding: '0 8px' },
                                maxWidth: isModal ? 640 : null,
                                title: data?.post?.title,
                                authorIcon: data?.author?.imageUri,
                                authorName: data?.author?.name,
                                authorTitle: data?.author?.title
                            }}
                        />
                        <SliderControl playerRef={playerRef} />
                        <Controls style={{ padding: '0 8px' }} />
                    </>
                )}
            </div>
        </div>
    );
};

const ModalHeader = ({ onClose }: { onClose: () => void }): JSX.Element => {
    return (
        <Flex
            id={'video-modal-handle'}
            row
            align={'center'}
            justify={'flex-end'}
            fullWidth
            style={{ cursor: 'pointer', flex: 0, paddingBottom: '8px', boxSizing: 'border-box', borderRadius: '8 8 0 0' }}
        >
            <HighlightOffIcon style={{ height: 24, width: 24 }} onClick={onClose} />
        </Flex>
    );
};

export const SkeletonVideo = ({ height }: { height?: number | string }): JSX.Element => {
    return (
        <Flex
            column
            center
            align={'center'}
            fullWidth
            style={{ height: height || '100%', flex: '1 1 auto', margin: '0 auto', borderRadius: 8, opacity: 0.5, boxSizing: 'border-box' }}
        >
            <Flex column center className={style['animate-flicker']}>
                <OndemandVideoIcon style={{ height: 64, width: 64 }} />
            </Flex>
        </Flex>
    );
};
