// @ts-strict-ignore
import { T, Ts } from "phoenix/assets/lang/T";
import { GetConfig } from "phoenix/constants";

/** @deprecated please use v2 */
export const FundingErrors = {
    UnknownError: 'FNDX000',
    FundingNotAllowed: 'FNDX001',
    FailedDailyFundingLimitCheck: 'FNDX002',
    CosmosPlaidAccessFailed: 'FNDX003',
    PlaidBalanceCheckFailed: 'FNDX004',
    CosmosPlaidLinkIdMatchFailed: 'FNDX005',
    PlaidIdentityInfoFailed: 'FNDX006',
    LinkKindNameCheckFailed: 'FNDX007',
    PreviouslyLinkedAccountIdLookupFailed: 'FNDX008',
    FailedToLinkAccount: 'FNDX009',
    TransferWindowClosed: 'FNDX010',
    TransferFailed: 'FNDX011',
    InsufficientWithdrawalFunds: 'FNDX012',
    DepositsLockedError: 'FNDX013',
    MinimumDepositNotMet: 'FNDX014',
    MaximumAccountsReached: 'FNDX015',
    AinsInstructionsInactive: 'FNDX016',
    MissingUserToken: 'FNDX017',
    MissingUserEmail: 'FNDX018',
    MissingUserId: 'FNDX019',
    RequestMissingFundingSourceId: 'FNDX020',
    RequestMissingSnexAccountNumber: 'FNDX021',
    UnableToFindExternalAccountInfo: 'FNDX022',
    InsufficientAvailableBalance: 'FNDX023',
    WithdrawLocked: 'FNDX024',
    MaximumDepositsReached: 'FNDX025',
    MaximumDepositAmountExceeded: 'FNDX026',
    MaximumWithdrawAmountExceeded: 'FNDX027',
    AccountIsPending: 'FNDX028',
    PlaidReauthNeeded: 'FNDX029',
}
/** @deprecated please use v2 */
export const PlaidFailureCodes: Set<string> = new Set([
    FundingErrors.PlaidBalanceCheckFailed,
    FundingErrors.PlaidIdentityInfoFailed,
    FundingErrors.CosmosPlaidAccessFailed,
    FundingErrors.CosmosPlaidLinkIdMatchFailed
])

/** @deprecated please use v2 */
export const GetFundingErrorTextFromErrorCode = (errorCode: string) => {
    const store = GetConfig().Store;
    const fundingLimits = store.getState().funding.managementLimits;
    const minAllowedDeposit = fundingLimits?.data?.minAllowedDeposit;
    const maxDeposits = fundingLimits?.data?.maxDepositCount;
    const maxDepositDays = fundingLimits?.data?.maxDepositDays;
    console.log({ errorCode })

    const text = Ts(t => t.bankingScreen);

    const t = (() => {
        switch (errorCode) {
            case 'FNDX000' : return text.unknownError;
            case 'FNDX001' : return text.fundingNotAllowed;
            case 'FNDX002' : return text.failedDailyFundingLimitCheck;
            case 'FNDX003' : return text.cosmosPlaidAccessFailed;
            case 'FNDX004' : return text.plaidBalanceCheckFailed;
            case 'FNDX005' : return text.cosmosPlaidLinkIdMatchFailed;
            case 'FNDX006' : return text.plaidIdentityInfoFailed;
            case 'FNDX007' : return text.likeKindNameCheckFailed;
            case 'FNDX008' : return text.previouslyLinkedAccountIdLookupFailed;
            case 'FNDX009' : return text.failedToLinkAccount;
            case 'FNDX010' : return text.transferWindowClosed;
            case 'FNDX011' : return text.transferFailed;
            case 'FNDX012' : return { title: text.errors.insufficientWithdrawalBalanceTitle, subtitle: text.errors.insufficientWithdrawalBalance }
            case 'FNDX013' : return text.depositsLockedError;
            case 'FNDX014' : return { title: text.minimumDepositNotMet.title(minAllowedDeposit), subtitle: text.minimumDepositNotMet.subtitle }
            case 'FNDX015' : return text.maximumAccountsReached;
            case 'FNDX016' : return text.ainsInstructionsInactive;
            case 'FNDX023' : return {title: text.amountExceedsAccountBalance, subtitle: null }
            case 'FNDX025' : return {title: text.maximumDepositsReached.title, subtitle: text.maximumDepositsReached.subtitle(maxDeposits, maxDepositDays) }
            case 'FNDX026' : return {title: text.amountExceedsLimit, subtitle: null};
            case 'FNDX028' : return {title: text.accountIsPending, subtitle: null}
            case 'FNDX029' : return {title: text.plaidReauthNeeded, subtitle: null}
            default: return { title: T(t => t.general.sorrySomethingWentWrong), subtitle: null }
        }
    })()

    return t
}