import React from 'react';
import { getScaledImage } from './getScaledImage';

export const PauseIcon = ({ width, height, style }: { width?: number; height?: number; style?: React.CSSProperties }): JSX.Element => {
    const { height: _h, width: _w } = getScaledImage(68, 65, width, height);

    return (
        <div style={{ ...style }}>
            <svg width={_w} height={_h} viewBox='0 0 68 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect x='2' y='2' width='64' height='61' rx='30.5' stroke='white' strokeWidth='4' />
                <rect x='21' y='19' width='12' height='28' rx='3' fill='white' />
                <rect x='35' y='19' width='12' height='28' rx='3' fill='white' />
            </svg>
        </div>
    );
};
