import { Author, Post } from "phoenix/stores/OneProNetworkStore/OneProNetworkTypes"

export const DummyData: {
    featuredPosts: Post[],
    carouselPosts: Post[],
    deskAlerts: Post[],
    feedPosts: Post[],
    authors: Author[]
} = {
    "featuredPosts": [
        {
            "id": "post1003",
            "channel": {
                "id": "channel103",
                "name": "Executive Insights",
                "imageUri": "https://example.com/logos/executive_insights.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-22T09:30:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera on Upcoming Economic Trends for Q2",
            "message": "A deep dive into the economic trends expected to shape the next quarter, including GDP growth, employment rates, and consumer spending.",
            "tags": ["Economy", "Forecast", "Quarterly Report"],
            "relatedSecurities": [
                {
                    "qsi": "SNEX",
                    "timeSeconds": 180
                },
                {
                    "qsi": "TSLA",
                    "timeSeconds": 180
                },
                {
                    "qsi": "NIO",
                    "timeSeconds": 180
                },
                {
                    "qsi": "BABA",
                    "timeSeconds": 180
                },
                {
                    "qsi": "AMZN",
                    "timeSeconds": 180
                }
            ],
            "thumbnailUri": "https://images.unsplash.com/photo-1618044733300-9472054094ee?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "media": {
                "type": "video",
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post1004",
            "channel": {
                "id": "channel104",
                "name": "Global Markets",
                "imageUri": "https://example.com/logos/global_markets.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong on Early Market Trends in Europe",
            "message": "An overview of early movers in the European stock markets, highlighting significant changes and potential impacts.",
            "tags": ["European markets", "Stocks", "Analysis"],
            "relatedSecurities": [
                {
                    "qsi": "GOOG"
                }
            ],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/Thumbnail.png",
            "media": {
                "type": "video",
                "lengthSeconds": 300,
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post21434",
            "channel": {
                "id": "channel101",
                "name": "Market Analysis",
                "imageUri": "https://example.com/logos/market_analysis.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-22T08:45:00Z",
            "type": "basic-post",
            "title": "Fixed Income - Debt Capital Markets - Advisory Desk Notes",
            "message": "A comprehensive overview of today’s stock market performance, focusing on major indices like the S&P 500 and Dow Jones.",
            "tags": ["Stock Market", "Financial Analysis", "Indices"],
            "relatedSecurities": [
                {
                    "qsi": "MSFT",
                    "timeSeconds": 120
                },
                {
                    "qsi": "TSLA"
                }
            ],
            "thumbnailUri": "https://uat.micms.stonex.com/cdn-cgi/image/quality=100/sites/default/files/2023-04/Abstract_financial_chart_candlestick.jpg",
            "media": {
                "type": "html",
                "lengthSeconds": 600,
                "content": ["F:2973f2dd-624b-4b3a-bf98-04f61c0e570f"]
            }
        },
        {
            "id": "post21434",
            "channel": {
                "id": "channel101",
                "name": "Market Analysis",
                "imageUri": "https://example.com/logos/market_analysis.jpg"
            },
            "author": {
                id: "author106",
                name: "Bob Smith",
                title: "Finance Correspondent",
                imageUri: "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg",
            },
            "created": "2024-05-22T08:45:00Z",
            "type": "basic-post",
            "title": "Fixed Income - Debt Capital Markets - Advisory Desk Notes",
            "message": "A comprehensive overview of today’s stock market performance, focusing on major indices like the S&P 500 and Dow Jones.",
            "tags": ["Stock Market", "Financial Analysis", "Indices"],
            "relatedSecurities": [
                {
                    "qsi": "MSFT",
                    "timeSeconds": 120
                },
                {
                    "qsi": "TSLA"
                }
            ],
            "thumbnailUri": "https://uat.micms.stonex.com/cdn-cgi/image/quality=100/sites/default/files/2023-04/Abstract_financial_chart_candlestick.jpg",
            "media": {
                "type": "html",
                "lengthSeconds": 600,
                "content": ["F:2973f2dd-624b-4b3a-bf98-04f61c0e570f"]
            }
        }
    ],
    "carouselPosts": [
        {
            "id": "post1001",
            "channel": {
                "id": "channel101",
                "name": "Market Analysis",
                "imageUri": "https://example.com/logos/market_analysis.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-22T08:45:00Z",
            "type": "basic-post",
            "title": "Emily Tran Discusses Today's Major Stock Movements",
            "message": "A comprehensive overview of today’s stock market performance, focusing on major indices like the S&P 500 and Dow Jones.",
            "tags": ["Stock Market", "Financial Analysis", "Indices"],
            "relatedSecurities": [
                {
                    "qsi": "MSFT",
                    "timeSeconds": 120
                },
                {
                    "qsi": "TSLA"
                }
            ],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/FinancePapers.png",
            "media": {
                "type": "document",
                "lengthSeconds": 600,
                "content": [
                    "https://micms.stonex.com/sites/default/files/2024-04/StoneX%20Market%20Strategy_Gold%20Profit%20Taking%20and%20Looking%20for%20Entry%20Points%20in%20Miners.pdf",
                ]
            }
        },
        {
            "id": "post1004",
            "channel": {
                "id": "channel104",
                "name": "Global Markets",
                "imageUri": "https://example.com/logos/global_markets.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong on Early Market Trends in Europe",
            "message": "An overview of early movers in the European stock markets, highlighting significant changes and potential impacts.",
            "tags": ["European markets", "Stocks", "Analysis"],
            "relatedSecurities": [
                {
                    "qsi": "GOOG"
                }
            ],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/Thumbnail.png",
            "media": {
                "type": "video",
                "lengthSeconds": 300,
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post21434",
            "channel": {
                "id": "channel101",
                "name": "Market Analysis",
                "imageUri": "https://example.com/logos/market_analysis.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg",
            },
            "created": "2024-05-22T08:45:00Z",
            "type": "basic-post",
            "title": "Fixed Income - Debt Capital Markets - Advisory Desk Notes",
            "message": "A comprehensive overview of today’s stock market performance, focusing on major indices like the S&P 500 and Dow Jones.",
            "tags": ["Stock Market", "Financial Analysis", "Indices"],
            "relatedSecurities": [
                {
                    "qsi": "MSFT",
                    "timeSeconds": 120
                },
                {
                    "qsi": "TSLA"
                }
            ],
            "thumbnailUri": "https://uat.micms.stonex.com/cdn-cgi/image/quality=100/sites/default/files/2023-04/Abstract_financial_chart_candlestick.jpg",
            "media": {
                "type": "html",
                "lengthSeconds": 600,
                "content": ["F:2973f2dd-624b-4b3a-bf98-04f61c0e570f"]
            }
        }
    ],
    "deskAlerts": [
        {
            "id": "post1002",
            "channel": {
                "id": "channel102",
                "name": "Urgent Financial Alerts",
                "imageUri": "https://example.com/logos/urgent_financial_alerts.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Katheryn Rooney Vera",
                "title": "Financial News Editor",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-23T11:00:00Z",
            "type": "desk-alert",
            "title": "Katheryn Rooney Vera Reports on Sudden Federal Reserve Rate Hike",
            "message": "The Federal Reserve has announced an unexpected interest rate hike, effective immediately. Full details to follow.",
            "tags": ["Federal Reserve", "Interest Rates", "Breaking News"],
            "relatedSecurities": [],
            "media": {
                "type": null,
                "content": []
            }
        },
        {
            "id": "post2143",
            "channel": {
                "id": "channel103",
                "name": "Executive Insights",
                "imageUri": "https://example.com/logos/executive_insights.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-22T09:30:00Z",
            "type": "desk-alert",
            "title": "Urgent: Market Volatility",
            "message": "A sudden spike in market volatility has been observed. Investors are advised to exercise caution.",
            "tags": ["Market Volatility", "Urgent"],
            "relatedSecurities": [],
            "media": {
                "type": null,
                "content": []
            }
        },
        {
            "id": "post1006",
            "channel": {
                "id": "channel106",
                "name": "Finance Daily",
                "imageUri": "https://example.com/logos/finance_daily.jpg"
            },
            "author": {
                "id": "author106",
                "name": "Bob Smith",
                "title": "Finance Correspondent",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/cramer.jpg"
            },
            "created": "2024-05-23T03:00:00Z",
            "type": "desk-alert",
            "title": "Alert: Bitcoin Surges",
            "message": "Bitcoin's value rose 5% today, fueled by increased institutional investment.",
            "tags": ["Bitcoin", "Cryptocurrency", "Investment"],
            "relatedSecurities": [],
            "media": {
                "type": null,
                "content": []
            }
        },
        {
            "id": "post1007",
            "channel": {
                "id": "channel107",
                "name": "Economic Insights",
                "imageUri": "https://example.com/logos/economic_insights.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-24T09:00:00Z",
            "type": "desk-alert",
            "title": "Alert: Inflation Concerns",
            "message": "Rising inflation rates are causing concern among investors and consumers.",
            "tags": ["Inflation", "Economic Analysis"],
            "relatedSecurities": [],
            "media": {
                "type": null,
                "content": []
            }
        },
        {
            "id": "post1008",
            "channel": {
                "id": "channel108",
                "name": "Market Forecast",
                "imageUri": "https://example.com/logos/market_forecast.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-25T10:00:00Z",
            "type": "desk-alert",
            "title": "Forecast Alert: Market Trends",
            "message": "Predicted market trends indicate potential volatility in the coming weeks.",
            "tags": ["Market Trends", "Forecast"],
            "relatedSecurities": [],
            "media": {
                "type": null,
                "content": []
            }
        }
    ],
    "feedPosts": [
        // Emily Tran's Posts
        {
            "id": "post2001",
            "channel": {
                "id": "channel201",
                "name": "Equity Research",
                "imageUri": "https://example.com/logos/equity_research.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-20T09:00:00Z",
            "type": "basic-post",
            "title": "Emily Tran on Tech Stock Performance",
            "message": "Earnings season is upon us! Keep an eye on the tech giants reporting this week.",
            "tags": ["Tech Stocks", "Market Analysis"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/tech_stock_performance.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/tech_stock_performance.jpg"
                ]
            }
        },
        {
            "id": "post2002",
            "channel": {
                "id": "channel202",
                "name": "Market News",
                "imageUri": "https://example.com/logos/market_news.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-21T10:00:00Z",
            "type": "basic-post",
            "title": "Emily Tran on Market Volatility",
            "message": "The Fed's latest decision is likely to have a major impact on interest rates. Stay tuned for updates.",
            "tags": ["Market Volatility", "Investor Insights"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/market_volatility.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/market_volatility.jpg"
                ]
            }
        },
        {
            "id": "post2003",
            "channel": {
                "id": "channel203",
                "name": "Investment Strategies",
                "imageUri": "https://example.com/logos/investment_strategies.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Emily Tran's Top Investment Picks",
            "message": "Inflation data released today shows a slight increase. How will this affect your portfolio?",
            "tags": ["Investment", "Stock Picks"],
            "relatedSecurities": [],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/featureImageExample.png",
            "media": {
                "type": "video",
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post2004",
            "channel": {
                "id": "channel204",
                "name": "Global Markets",
                "imageUri": "https://example.com/logos/global_markets.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-23T12:00:00Z",
            "type": "basic-post",
            "title": "Emily Tran on Global Market Trends",
            "message": "Today's market volatility highlights the importance of diversification. Don't put all your eggs in one basket.",
            "tags": ["Global Markets", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/global_market_trends.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/global_market_trends.jpg"
                ]
            }
        },
        {
            "id": "post2005",
            "channel": {
                "id": "channel205",
                "name": "Economic Insights",
                "imageUri": "https://example.com/logos/economic_insights.jpg"
            },
            "author": {
                "id": "author101",
                "name": "Emily Tran",
                "title": "Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg"
            },
            "created": "2024-05-24T13:00:00Z",
            "type": "basic-post",
            "title": "Emily Tran on Economic Outlook",
            "message": "Q3 economic outlook is looking strong. What are your thoughts?",
            "tags": ["Economy", "Forecast"],
            "relatedSecurities": [],
            "thumbnailUri": "https://static.chasecdn.com/content/services/structured-image/image.desktopLarge.jpg/articles/thumbnail-image-large/how-does-the-stock-market-work-2560x1440.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/economic_outlook.jpg"
                ]
            }
        },
        // Kathryn Rooney Vera's Posts
        {
            "id": "post3000",
            "channel": {
                "id": "channel301",
                "name": "Global Economy",
                "imageUri": "https://example.com/logos/global_economy.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-20T09:00:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera on best buy right now",
            "message": "The best buy right now is 'protection'",
            "tags": ["Global Economy", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/featureImageExample.png",
            "media": {
                "type": "video",
                "content": [
                    "https://www.youtube.com/embed/yykpGFApbfg?si=HKwDzbBG3d4nHWZ4"
                ]
            }
        },
        {
            "id": "post3001",
            "channel": {
                "id": "channel301",
                "name": "Global Economy",
                "imageUri": "https://example.com/logos/global_economy.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-20T09:00:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera on Global Economic Trends",
            "message": "Cryptocurrencies are seeing a surge today after new regulations were announced.",
            "tags": ["Global Economy", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/featureImageExample.png",
            "media": {
                "type": "video",
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post3002",
            "channel": {
                "id": "channel302",
                "name": "Market Insights",
                "imageUri": "https://example.com/logos/market_insights.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-21T10:00:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera on Market Dynamics",
            "message": "Oil prices are climbing again. What does this mean for energy stocks?",
            "tags": ["Market Dynamics", "Analysis"],
            "relatedSecurities": [],
            "thumbnailUri": "https://static.chasecdn.com/content/services/structured-image/image.desktopLarge.jpg/articles/thumbnail-image-large/how-does-the-stock-market-work-2560x1440.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/market_dynamics.jpg"
                ]
            }
        },
        {
            "id": "post3003",
            "channel": {
                "id": "channel303",
                "name": "Investment Advice",
                "imageUri": "https://example.com/logos/investment_advice.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera's Investment Tips",
            "message": "Tech stocks continue to outperform. Are you taking advantage of this trend?",
            "tags": ["Investment", "Tips"],
            "relatedSecurities": [],
            "thumbnailUri": "https://static.chasecdn.com/content/services/structured-image/image.desktopLarge.jpg/articles/thumbnail-image-large/how-does-the-stock-market-work-2560x1440.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/investment_tips.jpg"
                ]
            }
        },
        {
            "id": "post3004",
            "channel": {
                "id": "channel304",
                "name": "Economic Forecasts",
                "imageUri": "https://example.com/logos/economic_forecasts.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-23T12:00:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera on Economic Forecasts",
            "message": "Jobless claims fell to a new low, indicating a strengthening labor market.",
            "tags": ["Economic Forecasts", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://static.chasecdn.com/content/services/structured-image/image.desktopLarge.jpg/articles/thumbnail-image-large/how-does-the-stock-market-work-2560x1440.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/economic_forecasts.jpg"
                ]
            }
        },
        {
            "id": "post3005",
            "channel": {
                "id": "channel305",
                "name": "Financial Analysis",
                "imageUri": "https://example.com/logos/financial_analysis.jpg"
            },
            "author": {
                "id": "author103",
                "name": "Kathryn Rooney Vera",
                "title": "Chief Economist",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg"
            },
            "created": "2024-05-24T13:00:00Z",
            "type": "basic-post",
            "title": "Kathryn Rooney Vera on Financial Analysis",
            "message": "Gold prices are holding steady despite market turbulence. Is gold a safe haven for your investments?",
            "tags": ["Financial Analysis", "Market Movements"],
            "relatedSecurities": [],
            "thumbnailUri": "https://img.money.com/2022/05/News-Plunging-Stocks-401k.jpg?quality=60&w=1600",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/financial_analysis.jpg"
                ]
            }
        },
        // Richard Kwong's Posts
        {
            "id": "post4001",
            "channel": {
                "id": "channel401",
                "name": "European Markets",
                "imageUri": "https://example.com/logos/european_markets.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg"
            },
            "created": "2024-05-20T09:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong on European Market Trends",
            "message": "International markets are showing mixed signals. Stay informed on global economic trends.",
            "tags": ["European Markets", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://img.money.com/2022/05/News-Plunging-Stocks-401k.jpg?quality=60&w=1600",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/european_market_trends.jpg"
                ]
            }
        },
        {
            "id": "post4002",
            "channel": {
                "id": "channel402",
                "name": "Asian Markets",
                "imageUri": "https://example.com/logos/asian_markets.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg"
            },
            "created": "2024-05-21T10:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong on Asian Market Developments",
            "message": "The housing market is still hot with prices rising in major cities. Should you invest in real estate?",
            "tags": ["Asian Markets", "Developments"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/asian_market_developments.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/asian_market_developments.jpg"
                ]
            }
        },
        {
            "id": "post4003",
            "channel": {
                "id": "channel403",
                "name": "International Finance",
                "imageUri": "https://example.com/logos/international_finance.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong on International Financial Trends",
            "message": "Small-cap stocks are gaining attention with higher growth potential. Do you have any in your portfolio?",
            "tags": ["International Finance", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/international_financial_trends.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/international_financial_trends.jpg"
                ]
            }
        },
        {
            "id": "post4004",
            "channel": {
                "id": "channel404",
                "name": "Market Forecasts",
                "imageUri": "https://example.com/logos/market_forecasts.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg"
            },
            "created": "2024-05-23T12:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong's Market Forecasts",
            "message": "Corporate debt levels are rising. What risks does this pose to the market?",
            "tags": ["Market Forecasts", "Analysis"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/market_forecasts.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/market_forecasts.jpg"
                ]
            }
        },
        {
            "id": "post4005",
            "channel": {
                "id": "channel405",
                "name": "Financial Insights",
                "imageUri": "https://example.com/logos/financial_insights.jpg"
            },
            "author": {
                "id": "author104",
                "name": "Richard Kwong",
                "title": "International Market Analyst",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg"
            },
            "created": "2024-05-24T13:00:00Z",
            "type": "basic-post",
            "title": "Richard Kwong on Financial Insights",
            "message": "S&P 500 hits a new high! Are you positioned to benefit from the bull market?",
            "tags": ["Financial Insights", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/financial_insights.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/financial_insights.jpg"
                ]
            }
        },
        // Anna Zhou's Posts
        {
            "id": "post5001",
            "channel": {
                "id": "channel501",
                "name": "Market Updates",
                "imageUri": "https://example.com/logos/market_updates.jpg"
            },
            "author": {
                "id": "author105",
                "name": "Anna Zhou",
                "title": "Junior Reporter",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/AnnaZhou.jpg"
            },
            "created": "2024-05-20T09:00:00Z",
            "type": "basic-post",
            "title": "Anna Zhou on Market Updates",
            "message": "Green energy stocks are on the rise as demand for sustainable investments grows.",
            "tags": ["Market Updates", "News"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/market_updates.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/market_updates.jpg"
                ]
            }
        },
        {
            "id": "post5002",
            "channel": {
                "id": "channel502",
                "name": "Financial News",
                "imageUri": "https://example.com/logos/financial_news.jpg"
            },
            "author": {
                "id": "author105",
                "name": "Anna Zhou",
                "title": "Junior Reporter",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/AnnaZhou.jpg"
            },
            "created": "2024-05-21T10:00:00Z",
            "type": "basic-post",
            "title": "Anna Zhou on Financial News",
            "message": "The latest GDP report shows strong economic growth. How will this affect your investment strategy?",
            "tags": ["Financial News", "Updates"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/financial_news.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/financial_news.jpg"
                ]
            }
        },
        {
            "id": "post5003",
            "channel": {
                "id": "channel503",
                "name": "Stock Market",
                "imageUri": "https://example.com/logos/stock_market.jpg"
            },
            "author": {
                "id": "author105",
                "name": "Anna Zhou",
                "title": "Junior Reporter",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/AnnaZhou.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Anna Zhou on Stock Market Movements",
            "message": "Bond yields are fluctuating. What does this mean for fixed-income investors?",
            "tags": ["Stock Market", "Movements"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/stock_market_movements.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/stock_market_movements.jpg"
                ]
            }
        },
        {
            "id": "post5004",
            "channel": {
                "id": "channel504",
                "name": "Economic News",
                "imageUri": "https://example.com/logos/economic_news.jpg"
            },
            "author": {
                "id": "author105",
                "name": "Anna Zhou",
                "title": "Junior Reporter",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/AnnaZhou.jpg"
            },
            "created": "2024-05-23T12:00:00Z",
            "type": "basic-post",
            "title": "Anna Zhou on Economic News",
            "message": "Financial sector stocks are rebounding. Is it time to invest in banks and financial services? ",
            "tags": ["Economic News", "Developments"],
            "relatedSecurities": [],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/featureImageExample.png",
            "media": {
                "type": "video",
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post5005",
            "channel": {
                "id": "channel505",
                "name": "Financial Insights",
                "imageUri": "https://example.com/logos/financial_insights.jpg"
            },
            "author": {
                "id": "author105",
                "name": "Anna Zhou",
                "title": "Junior Reporter",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/AnnaZhou.jpg"
            },
            "created": "2024-05-24T13:00:00Z",
            "type": "basic-post",
            "title": "Anna Zhou on Financial Insights",
            "message": "Emerging markets offer high growth potential but come with risks. Are they worth it? ",
            "tags": ["Financial Insights", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/financial_insights.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/financial_insights.jpg"
                ]
            }
        },
        // Bob Smith's Posts
        {
            "id": "post6001",
            "channel": {
                "id": "channel601",
                "name": "Market Analysis",
                "imageUri": "https://example.com/logos/market_analysis.jpg"
            },
            "author": {
                "id": "author106",
                "name": "Bob Smith",
                "title": "Finance Correspondent",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg"
            },
            "created": "2024-05-20T09:00:00Z",
            "type": "basic-post",
            "title": "Bob Smith on Market Analysis",
            "message": "Current market trends are showing mixed signals yet corporate earnings are exceeding expectations this quarter. Which companies are leading the charge?",
            "tags": ["Market Analysis", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/market_analysis.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/market_analysis.jpg"
                ]
            }
        },
        {
            "id": "post6002",
            "channel": {
                "id": "channel602",
                "name": "Financial News",
                "imageUri": "https://example.com/logos/financial_news.jpg"
            },
            "author": {
                "id": "author106",
                "name": "Bob Smith",
                "title": "Finance Correspondent",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg"
            },
            "created": "2024-05-21T10:00:00Z",
            "type": "basic-post",
            "title": "Bob Smith on Financial News",
            "message": "The trade deficit is widening. What impact will this have on the economy?",
            "tags": ["Financial News", "Updates"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/financial_news.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/financial_news.jpg"
                ]
            }
        },
        {
            "id": "post6003",
            "channel": {
                "id": "channel603",
                "name": "Stock Market",
                "imageUri": "https://example.com/logos/stock_market.jpg"
            },
            "author": {
                "id": "author106",
                "name": "Bob Smith",
                "title": "Finance Correspondent",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg"
            },
            "created": "2024-05-22T11:00:00Z",
            "type": "basic-post",
            "title": "Bob Smith on Stock Market Movements",
            "message": "Interest in ESG (Environmental, Social, Governance) investing continues to grow. Are you on board?",
            "tags": ["Stock Market", "Movements"],
            "relatedSecurities": [],
            "thumbnailUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/featureImageExample.png",
            "media": {
                "type": "video",
                "content": [
                    "https://vimeo.com/545620196"
                ]
            }
        },
        {
            "id": "post6004",
            "channel": {
                "id": "channel604",
                "name": "Economic Insights",
                "imageUri": "https://example.com/logos/economic_insights.jpg"
            },
            "author": {
                "id": "author106",
                "name": "Bob Smith",
                "title": "Finance Correspondent",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg"
            },
            "created": "2024-05-23T12:00:00Z",
            "type": "basic-post",
            "title": "Bob Smith on Economic Insights",
            "message": "Volatility in the stock market can create buying opportunities. Are you ready to capitalize?",
            "tags": ["Economic Insights", "Trends"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/economic_insights.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/economic_insights.jpg"
                ]
            }
        },
        {
            "id": "post6005",
            "channel": {
                "id": "channel605",
                "name": "Investment Strategies",
                "imageUri": "https://example.com/logos/investment_strategies.jpg"
            },
            "author": {
                "id": "author106",
                "name": "Bob Smith",
                "title": "Finance Correspondent",
                "imageUri": "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg"
            },
            "created": "2024-05-24T13:00:00Z",
            "type": "basic-post",
            "title": "Bob Smith on Investment Strategies",
            "message": "The semiconductor shortage is impacting multiple industries. How are companies adapting?",
            "tags": ["Investment Strategies", "Market Conditions"],
            "relatedSecurities": [],
            "thumbnailUri": "https://example.com/images/investment_strategies.jpg",
            "media": {
                "type": "image",
                "content": [
                    "https://example.com/images/investment_strategies.jpg"
                ]
            }
        }
    ],
    "authors": [
        {
            id: "author101",
            name: "Emily Tran",
            title: "Market Analyst",
            imageUri: "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/woman_2_headshot.jpg",
            bio: "Emily Tran is a Market Analyst at StoneX Group, specializing in equity research and market trends. With a focus on data-driven insights, Emily provides comprehensive market analysis to help clients make informed investment decisions. She holds a Master's in Finance from the University of Chicago and has over a decade of experience in the financial sector."
        },
        {
            id: "author103",
            name: "Kathryn Rooney Vera",
            title: "Chief Economist",
            imageUri: "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/KRVheadshot.jpg",
            bio: "Kathryn is a Chief Strategist at StoneX Group, a leading financial services firm that provides global market access and customized solutions across asset classes. With over 22 years of experience in global economics and investment management, Kathryn brings a wealth of knowledge and insight to her role, where she works closely with institutional teams and clients across diverse markets and sectors. Kathryn's core competencies include macroeconomic forecasting, global portfolio allocation, market commentary, and investment strategy. She is a recognized expert in emerging markets, especially Latin America, where she has developed comprehensive and out-of-consensus recommendations that have consistently produced double-digit returns for her clients. Kathryn is also a high-frequency contributor to major media outlets, a frequent keynote speaker at conferences, and a former adjunct professor of Global Economics at the University of Miami. Kathryn holds an MBA from New York University and a BA from Rutgers University, and is Series 7, 63, 65 certified.",
            details: [
                {title: 'Subscribers', value: '5350', format: 'number'},
                {title: 'Next Appearance', value: '9/5/2024', format: 'date'}
            ]
        },
        {
            id: "author104",
            name: "Richard Kwong",
            title: "International Market Analyst",
            imageUri: "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/RichardKwong.jpg",
            bio: "Richard Kwong is an International Market Analyst at StoneX Group, focusing on global financial markets and economic trends. With expertise in European and Asian markets, Richard provides strategic analysis and market forecasts. He has a PhD in Economics from the London School of Economics and extensive experience in international finance."
        },
        {
            id: "author105",
            name: "Anna Zhou",
            title: "Junior Reporter",
            imageUri: "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/AnnaZhou.jpg",
            bio: "Anna Zhou is a Junior Reporter at StoneX Group, covering quick updates and breaking financial news. She has a background in economics and journalism, holding a BA in Economics from Stanford University. Anna's reporting focuses on delivering concise and accurate market news.",
            details: [
                {title: 'Subscribers', value: '1500', format: 'number'},
                {title: 'Next Appearance', value: '6/17/2024', format: 'date'}
            ]
        },
        {
            id: "author106",
            name: "Bob Smith",
            title: "Finance Correspondent",
            imageUri: "https://snex1storage.blob.core.windows.net/$web/OPNTestFiles/BobSmith.jpg",
            bio: "Bob Smith is a Finance Correspondent at StoneX Group, providing in-depth coverage of financial markets and economic policies. With over 20 years of experience, Bob is known for his thorough analysis and insightful reporting. He holds an MBA from Wharton School of Business and a Bachelor's in Economics from MIT."
        }
    ]
}
