import { Typography } from '@mui/material';
import React from 'react';
import { Flex, SectionHeader } from 'components';
import { FundSectorExposureSection } from 'components/FundSectorExposureSection';
import { useColors } from 'hooks/UseColors';

export const RomanDebugScreen = () => {
    const colors = useColors();

    return (
        <Flex column justify="flex-start" align="center" style={{ flex: 1, background: colors.mainBackgroundColor, width: '100vw', overflow: 'scroll' }}>
            <Typography variant="h1" style={{ marginBottom: 40 }}>Roman&apos;s Sandbox</Typography>
            <Flex column style={{ width: 870, height: 500 }}>
                <FundSectorExposureSection symbol='VTSAX' />
            </Flex>
        </Flex>
    );
};
