// @ts-strict-ignore
import { Flex } from 'components';
import { OnboardingPageTitle } from 'components/Onboarding';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OnboardingLottieForm } from 'screens/OnboardingScreens/Base/OnboardingControls';
import animationData from 'phoenix/assets/animations/ProcessingCard.json';
import Lottie from 'lottie-react';
import useWindow from 'hooks/UseWindow';
import { useOpenChatWindow } from 'hooks/UseChat';
import { useText } from 'phoenix/hooks/UseText';
import { Chat } from '@mui/icons-material';
import { SnexButton } from 'components/SnexButton';
import { Typography } from '@mui/material';
import { useFullyPaidLendingStore } from '../../Store';
import { Routes } from 'util/Routes';
import { ShadedTextDisplay } from 'components/ShadedTextDisplay/ShadedTextDisplay';
import { useColors } from 'hooks/UseColors';
import { T } from 'phoenix/assets/lang/T';
import { GetClientAccountsAction } from 'phoenix/redux/actions';
import { useAppDispatch } from 'AppRoot';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';

type SubmissionStates = 'pristine' | 'loading' | 'error' | 'success';
export const Fpl_Submitting_Form = () => {
    const { viewportWidth } = useWindow();
    const chatText = useText((s) => s.misc.chatWithSupport);
    const [submitResult, setSubmitResult] = useState<SubmissionStates>('pristine');
    const { submit, data } = useFullyPaidLendingStore();

    const openChatWindow = useOpenChatWindow();

    const handleChatClick = useCallback((e: any) => {
        if (e) e.preventDefault();
        openChatWindow();
    }, []);

    const dispatch = useAppDispatch();

    const handleSubmit = async () => {
        try {
            await submit();
            dispatch(GetClientAccountsAction(true)); /* update the accounts cache so we get the submitted date and status */
            setSubmitResult('success');
        } catch (err) {
           ErrorLogger.RecordError(err, 'fully-paid-lending', {info: {...data}})
           setSubmitResult('error');
        }
    };

    const lottie = () => (
        <Lottie
            loop={true}
            autoplay={true}
            animationData={animationData}
            rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice'
            }}
            width={'100%'}
            height={'auto'}
            style={{
                opacity: 1,
                transition: '1s'
            }}
        />
    );

    const completeOnboarding = () => {
        window.location.href = Routes.portfolio();
    };

    const buttonText = useText((t) => t.futuresOnboarding);
    const text = useText((t) => t.fplOnboarding.submitting);

    const errorControls = useMemo(
        () => (
            <>
                <SnexButton flavor={'secondary'} onClick={handleChatClick} style={{ margin: '1rem 0' }}>
                    <Chat />
                    <span style={{ marginLeft: '32px' }}>{chatText}</span>
                </SnexButton>
                <SnexButton color='primary' onClick={() => useFullyPaidLendingStore.getState().setValue('selectedScreenId', 'fplAgreements')}>
                    {'Go Back'}
                </SnexButton>
            </>
        ),
        [handleChatClick, chatText]
    );

    const loadingControls = useMemo(
        () => (
            <>
                <SnexButton flavor={'secondary'} onClick={handleChatClick} style={{ margin: '1rem 0' }}>
                    <Chat />
                    <span style={{ marginLeft: '32px' }}>{chatText}</span>
                </SnexButton>
            </>
        ),
        [handleChatClick, chatText]
    );

    const successControls = useMemo(
        () => (
            <>
                <SnexButton flavor={'secondary'} onClick={handleChatClick} style={{ margin: '1rem 0' }}>
                    <Chat />
                    <span style={{ marginLeft: '32px' }}>{chatText}</span>
                </SnexButton>
                <SnexButton color='primary' onClick={completeOnboarding}>
                    {buttonText.okButton}
                </SnexButton>
            </>
        ),
        []
    );

    const statusTitle = useMemo(() => {
        if (submitResult === 'pristine' || submitResult === 'loading') return <ShadedTextDisplay segments={text.loadingMessage()} />;
        if (submitResult === 'success') return <ShadedTextDisplay segments={text.successMessage()} />;
        return text.errorMessage;
    }, [submitResult, text]);

    const statusControls = useMemo(() => {
        if (submitResult === 'pristine' || submitResult === 'loading') return loadingControls;
        if (submitResult === 'success') return successControls;
        return errorControls;
    }, [errorControls, loadingControls, submitResult, successControls]);

    const { setValue, isComplete } = useFullyPaidLendingStore();

    useEffect(() => {
        if (submitResult === 'pristine') {
            setSubmitResult('loading');
            handleSubmit();
        }
    }, [submitResult]);

    const colors = useColors();

    return (
        <Flex column fullWidth style={{ gap: '1rem' }}>
            <OnboardingLottieForm data={animationData} loop={['pristine', 'loading'].includes(submitResult)}>
                <Flex column fullWidth align={'flex-start'}>
                    <Typography variant='h3' style={{ color: colors.selectListSelectedItemColor, textAlign: 'left' }}>
                        {isComplete ? T((t) => t.fplOnboarding.submitting.completedTitle) : T((t) => t.fplOnboarding.submitting.title)}
                    </Typography>
                    <OnboardingPageTitle title={statusTitle} />
                    {isComplete ? (
                        <Typography variant='body1' style={{ fontSize: '19px', marginLeft: '4px', textAlign: 'left', marginTop: '-16px' }}>
                            {T((t) => t.fplOnboarding.submitting.axosMessage)}
                        </Typography>
                    ) : null}
                    {viewportWidth < 1100 ? lottie() : null}
                    {statusControls}
                </Flex>
            </OnboardingLottieForm>
        </Flex>
    );
};
