// @ts-strict-ignore
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DynamicModal, { DynamicModalSize } from 'components/Modals/DynamicModal';
import 'components/Modals/DynamicModal/DynamicModal.scss';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { CloseMiniplayerAction, SetMiniplayerSizeAction } from 'phoenix/redux/actions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import { NewMiniplayerAudio } from './Audio/NewMiniplayerAudio';

export interface NewMiniplayerContentProps {
    size: DynamicModalSize;
    onChangeSize: (size: DynamicModalSize) => void;
}

export const NewMiniplayer = React.memo(() => {
    const dispatch = useDispatch();
    const [size, setSize] = useState<DynamicModalSize>('small');
    const state = useSnexStore((s) => s.miniplayer);
    const text = useText((s) => s.miniplayer);

    // TODO -- This will have to be better / maybe come from the state at some point but for now we're good
    const contentType = useMemo(() => {
        if (!state.mediaUrl) return 'none';
        if (state.mediaUrl.includes('urlType=transcripts-audio')) return 'audio';
        if (/.*\.(ogg|mp4|mp3|wav|aiff|au|flac|m4a|wma|webm)/.test(state.mediaUrl || '')) return 'audio';
        return 'video';
    }, [state.mediaUrl]);

    const handleSizeChange = useCallback(
        (size: DynamicModalSize) => {
            dispatch(SetMiniplayerSizeAction(size));
            setSize(size);
        },
        [dispatch]
    );

    const content = useMemo(() => {
        if (!state.open) return null;
        if (state.loading) {
            return (
                <Flex column center style={{ height: '100%', width: '100%' }}>
                    <Typography variant='h5'>{text.loading(state?.mediaTitle)}</Typography>
                </Flex>
            );
        }
        if (contentType === 'audio') return <NewMiniplayerAudio size={size} onChangeSize={handleSizeChange} />;
        if (contentType === 'none') {
            return (
                <Flex row center style={{ height: '100%', width: '100%', gap: 10, padding: '4px' }}>
                    <Typography variant='h5' sx={{ paddingLeft: '4px' }}>
                        {text.noMediaAvailable}
                    </Typography>
                    <Close onClick={() => dispatch(CloseMiniplayerAction())} style={{ cursor: 'pointer' }} />
                </Flex>
            );
        }
    }, [contentType, dispatch, handleSizeChange, size, state.loading, state?.mediaTitle, state.open, text]);

    useEffect(() => {
        if (size === 'tucked') handleSizeChange('expanded');
    }, [handleSizeChange, size, state.mediaUrl]);

    return (
        <DynamicModal align='left' loading={state.loading} open={state.open} setSize={handleSizeChange} size={size}>
            {content}
        </DynamicModal>
    );
});
