import { add } from 'date-fns';
import { ReduxAction } from '../models';
import { AccountChartPoint } from '../redux/models';
import { XstreamState } from './XstreamState';

export const XstreamUpdateAccountChart = (state: XstreamState, action: ReduxAction): AccountChartPoint[] => {
    const { data } = action;
    const streamingData = action.subject?.accountNumber ? state.streamingAccountCharts[action.subject.accountNumber] : state.streamingPortfolioChart;

    const lastStreamPoint = streamingData?.[0] || undefined;
    let nextPointDate = new Date(data?.timestamp);

    const diffBetweenLastPointAndNewPoint = lastStreamPoint ? nextPointDate.getTime() - Date.parse(new Date(lastStreamPoint?.timestamp).toUTCString()) : 0;
    const diffBetweenPointsInMinutes = diffBetweenLastPointAndNewPoint / 1000 / 60;

    if (diffBetweenPointsInMinutes > 1 && diffBetweenPointsInMinutes < 2) {
        const lastPointDate = lastStreamPoint ? new Date(lastStreamPoint?.timestamp) : add(new Date(), { minutes: -1 });
        lastPointDate.setSeconds(0);
        nextPointDate = add(lastPointDate, { minutes: 1 });
        nextPointDate.setSeconds(0);
    }

    const newPoint: AccountChartPoint = { timestamp: nextPointDate.getTime(), value: data.value, accountId: action.subject?.accountNumber || '' };
    const diffBetweenNowAndLastPoint = lastStreamPoint ? Date.parse(new Date().toUTCString()) - Date.parse(new Date(lastStreamPoint?.timestamp).toUTCString()) : 0;

    const diffInMinutes = diffBetweenNowAndLastPoint / 1000 / 60;
    let newData: AccountChartPoint[] = [];

    if (diffInMinutes < 1 && streamingData?.length) {
        newData = streamingData;
        newData[0].value = newPoint?.value || 0;
    } else if (diffInMinutes >= 1 || !streamingData?.length) {
        newData = [...(streamingData || []), newPoint];
    }
    newData = newData.sort((a, b) => Date.parse(new Date(b.timestamp).toUTCString()) - Date.parse(new Date(a.timestamp).toUTCString()));
    return newData;
};
