// @ts-strict-ignore
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Flex } from '../..';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { ApiPosition } from 'phoenix/redux/models';
import { FormatNumber, TradeableSecurityType } from 'phoenix/util';
import { GetGainLossFigures, GetGainLossFiguresV2 } from 'phoenix/util/HoldingsCalculations';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import style from '../style.module.scss';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useColors } from 'hooks/UseColors';
import { QuantityWithShortIconByAccountNumber, QuantityWithoutShortIcon } from 'components/QuantityWithShortIcon/QuantityWithShortIcon';
import { getAccountAssetClass, useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

export const SecondaryRowComponent = (props: { secType: TradeableSecurityType; position: ApiPosition; hideAccountName?: boolean }): JSX.Element => {
    const { secType, position, hideAccountName } = props;
    const text = useText((t) => t.general);
    const { quantity } = GetGainLossFiguresV2(position);
    const relatedAccount = useSnexStore((s) => s.accounts.all.data?.find((a) => a.accountNumber === position.accountNumber));
    const secId = useMemo(() => QualifiedSecurityId.FromPosition(position), [position]);
    const secMeta = useSecurityMetadataV2()?.getMetadataBySymbol(secId.id);
    const assetClass = useAssetClassMetaV2(secId.id);
    const accounts = useSnexStore((s) => s.accounts.all)?.data;
    const accountsByAssetClass = accounts?.filter((a) => getAccountAssetClass(a.accountNumber).family === assetClass.family);
    const hideAccount = hideAccountName || accountsByAssetClass.length === 1; // Hide account if the user only has one
    const accountDisplayName = secType === 'future' ? new FuturesSymbol(relatedAccount?.effectiveName).noPrefix : relatedAccount?.effectiveName;
    const { grayText, holdingsGold } = useColors();

    const quantityComponent = useMemo(() => {
        // 1 = "1", 3.256 = "3.256", 1.5000 = "1.5"
        const quantityString = FormatNumber.removeDecimalsIfZero(assetClass.formatQuantity(quantity, secMeta));
        // console.log({ quantity, quantityString });
        if (assetClass.showShortPositionIcon) {
            return (
                <QuantityWithShortIconByAccountNumber
                    accountNumber={position.accountNumber}
                    quantity={quantityString}
                    text={text.unit(secId?.id, quantity, position.productType)}
                />
            );
        } else return <QuantityWithoutShortIcon quantity={quantityString} text={text.unit(secId.id, quantity, position.productType)} />;
    }, [text, secId, quantity, position, assetClass, secMeta]);

    return (
        <Flex className={style.secondaryRow}>
            <div style={{ color: holdingsGold, whiteSpace: 'nowrap', flex: 1.5 }}>{!!quantity && quantityComponent}</div>

            {hideAccount ? null : (
                <Typography
                    style={{
                        color: grayText,
                        flex: 1,
                        textAlign: 'right',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textTransform: 'capitalize'
                    }}
                    variant='body2'
                >
                    {accountDisplayName || null}
                </Typography>
            )}
        </Flex>
    );
};

export const SecondaryRow = React.memo(SecondaryRowComponent);
