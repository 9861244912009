// @ts-strict-ignore
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AccountBalancesState } from '../models'

const check = GroupNameChecker([Actions.AccountBalances])
export const AccountBalancesReducer = (state: AccountBalancesState = new AccountBalancesState(), action: ReduxAction): AccountBalancesState => {
    if (!check(action)) return state

    switch (action.type) {
        case Actions.AccountBalances.getBalances.Loading: return { ...state, all: state.all.startLoading() }
        case Actions.AccountBalances.getBalances.Success: return { ...state, all: state.all.succeeded(action.data) }
        case Actions.AccountBalances.getBalances.Failure: return { ...state, all: state.all.failed(action.error) }

        default: return state
    }
}
