import { Typography } from '@mui/material';
import { Flex } from 'components/Flex';
import { SnexButton } from 'components/SnexButton';
import { useText } from 'phoenix/hooks/UseText';
import React from 'react';
import { ModalViewHeader, PaddedSection } from '../Shared';

export default function AlertsDisabledView(props: { toggleModal: (open: boolean) => any }): JSX.Element {
    const text = useText((t) => t.errors.alerts);
    const secText = useText((t) => t.securityScreen.stats);

    return (
        <Flex column style={{ width: '100%', height: '100%' }}>
            <ModalViewHeader title={text.temporarilyUnavailable} />
            <PaddedSection flex column center style={{ padding: '0px 30px', gap: 15 }}>
                <Typography variant='h6'>{text.alertsTemporarilyUnavailableMessage}</Typography>
                <SnexButton flavor='submit' onClick={() => props.toggleModal(false)}>
                    {secText.close}
                </SnexButton>
            </PaddedSection>
        </Flex>
    );
}
