import { Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { SafeFormat, SafeFormatToNow } from "phoenix/util";
import { useText } from "phoenix/hooks/UseText";

interface DateTimeTextProps {
    style?: React.CSSProperties
    format?: string
    prefix?: string
    suffix?: string
    variant?: 'normal' | 'to-now'
    date: Date | string
}

export const DateTimeText = React.memo((props: DateTimeTextProps) => {

    const text = useText(s => s.general);
    const { format, variant, date, style, prefix, suffix } = props
    const content = useMemo(() => {
        if (!date) return text.dateUnknown;
        const d = (variant || 'normal') === 'normal' ? SafeFormat(date, format || 'dd MMM yyyy') : SafeFormatToNow(date)
        return `${ prefix || '' } ${ d } ${ suffix || '' }`.trim();
    }, [ format, variant, date ])

    return (
        <Tooltip title={ SafeFormat(date, 'PPPPpppp') }>
            <Typography variant='h6' style={ style }>
                    { content }
            </Typography>
        </Tooltip>
    )

})