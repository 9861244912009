import { ShadedText, ShadedTextList } from 'phoenix/assets/lang/Snex1LanguagePack';

const endsWithSpace = (s: string) => /\s+$/.test(s);
const startsWithSpace = (s: string) => /^\s+/.test(s);

export const ShadedTextUtils = {
    // All adjacent items with the same shade are merged
    combine: (lists: ShadedTextList[]): ShadedText[] => {
        const combined = lists.flatMap((l) => l);
        // Using new object reference for last element to avoid mutating the original (Mobile)
        let last: ShadedText = Object.create(combined[0]);
        const result = [last];

        for (const n of combined) {
            // Using new object reference for last element to avoid mutating the original (Mobile)
            const next = Object.create(n);

            // Special case: first element
            if (next.shade === last.shade && next.text === last.text) continue;

            // If joining two same-shaded elements, combine them
            if (next.shade === last.shade) {
                // Put a space between if neither them have a space there already.
                const space = !endsWithSpace(last.text) && !startsWithSpace(next.text) ? ' ' : '';

                // Exception: If next text is a period, don't place a space.
                if (next.text !== '.') last.text = `${last.text}${space}${next.text}`;
                else last.text = `${last.text}${next.text}`;
            }
            // Majority case: append
            else {
                result.push(next);
                last = next;
            }
        }
        return result;
    }
};
