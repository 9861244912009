import { ApiData } from '../../../models'
import { FundCountryExposure } from './FundCountryExposure'
import { FundHolding } from './FundHolding'
import { FundProfile } from './FundProfile'
import { FundReinvestmentPreferences } from './FundReinvestmentPreferences'
import { FundSectorExposure } from './FundSectorExposure'

export class FundsSubState {
    holdings: ApiData<FundHolding[]>
    profile: ApiData<FundProfile>
    sectorExposure: ApiData<FundSectorExposure[]>
    countryExposure: ApiData<FundCountryExposure[]>
    reinvestmentPreferences: {
        [accountNumber: string]: ApiData<FundReinvestmentPreferences>
    }

    constructor () {
        this.holdings = new ApiData([])
        this.profile = new ApiData()
        this.countryExposure = new ApiData([])
        this.sectorExposure = new ApiData([])
        this.reinvestmentPreferences = {}
    }
}

export class FundsState {
    byQsi: { [ qsi: string ]: Partial<FundsSubState> }
    constructor () { this.byQsi = {} }
}
