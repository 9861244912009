import { ApiData } from '../../../models'
import { AccountApplicationStatus } from './AccountApplicationStatus'

export class AccountApplicationStatusesState {
    byAccountNumber: { [accountNumber: string]: ApiData<AccountApplicationStatus> };

    constructor () {
        this.byAccountNumber = {}
    }
}
