// @ts-strict-ignore
import _ from "lodash"
import { Urls } from "phoenix/constants"
import { DropdownOption } from "phoenix/models/DropdownOptions"
import { AcatDeliveringFirm } from "phoenix/redux/models/Acats/AcatDeliveringFirm"
import { AcatPosition, AcatRequest } from "phoenix/redux/models/Acats/AcatRequest"
import { SnexAxios } from "phoenix/stores/AxiosHelpers"
import { ErrorLogger } from "phoenix/util/ErrorLogger"
import { create } from "zustand"
import { canSubmitPage } from "./AcatHelpers"


export type AcatPage = 'intro' | 'account-select' | 'transfer-type' | 'cash-options' | 'position-options' | 'confirm' | 'lost' 


export interface AcatTicketProps {
    transfer: Partial<AcatRequest>,
    page: AcatPage,
    firms: DropdownOption[],
    firmsLoading: boolean,
    canSubmitReason: string,
    canSubmit: boolean,
    isSubmitComplete: boolean
    isSubmitError: boolean
}

export interface AcatTicketFunctions {
    getFirms: () => void,
    setTransfer: (transfer: Partial<AcatRequest>) => void,
    setTransferValue: (key: string, value: unknown) => void,
    resetTicket: () => void
    setPage: (page: AcatPage) => void,
    setPositions: (positions: AcatPosition[]) => void,
    submit: () => void,
}

export interface AcatTicketStore extends AcatTicketFunctions, AcatTicketProps {}


const ticketDefaults: AcatTicketProps = {
    transfer: {},
    page: 'intro',
    firmsLoading: false,
    canSubmitReason: '',
    firms: [],
    isSubmitComplete: false,
    canSubmit: true,
    isSubmitError: false,
} 

export const useAcatTicketStore = create<AcatTicketStore>((set, get) => {
   
    return {
        ... ticketDefaults,
        getFirms: async () => {
            try
            {
               set((x) => ({...x, firmsLoading: true}))
               const response = await SnexAxios.ApiGet<AcatDeliveringFirm[]>(Urls.acats.getDeliveringFirms()).run()
               const firmOptions =  response?.map(f => ({ label: f.name, value: f.id })).sort((a,b) => a.label.localeCompare(b.label))
               set((x) => ({...x, firms: firmOptions, page: 'account-select', firmsLoading: false}))
            }
            catch(err)
            {
                ErrorLogger.RecordError(err, 'GET_ACATS_DELIVERING_FIRM')
                set((x) => ({...x, firms: [], page: 'lost', firmsLoading: false}))
            }
        }, 
        setTransfer: (transfer: Partial<AcatRequest>) => 
            set((t) => ({...t, transfer, canSubmit: canSubmitPage(transfer, get().page)})),
        setTransferValue: (key: keyof AcatRequest, value: unknown) =>          
            set((s) => {
                const storeToUpdate = {...s}
                _.set(storeToUpdate, `transfer.${key}`, value)
                _.set(storeToUpdate, 'canSubmit', canSubmitPage(storeToUpdate.transfer, storeToUpdate.page))
                return {...storeToUpdate}
            }),
        setPage: (page: AcatPage) => set((p) => ({...p, page, canSubmit: canSubmitPage(get().transfer, page)})),
        setPositions: (positions: AcatPosition[]) => set((x) => ({...x, transfer: {...x.transfer, positions}})),
        submit: () => {
            // to be implemented
            // dispatch(SubmitAcatAction(t))
            set((s) => ({...s, isSubmitComplete: true}))
        },
        resetTicket: () => set((s) => ({...ticketDefaults}))
    }
})