import { ReduxAction } from '../../../models'
import { Actions } from '../../actions'
import { GlobalState } from '../../GlobalState'
import { SectorScreenBundle } from '../../models/MobileScreens/SectorScreenBundle'
import { SectorSecuritiesSubstate } from '../../models/Sectors/SectorSecuritiesState'
import { asData } from '../ReducerUpdateHelpers'
import { MobileStateUpdates } from './MobileStateUpdates'

export const SectorScreenReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as SectorScreenBundle

    if (!state.sectorSecurities.byName[action.subject]) state.sectorSecurities.byName[action.subject] = new SectorSecuritiesSubstate()
    state.sectorSecurities.byName[action.subject].etfs = asData(bundle.etfSecurities)

    MobileStateUpdates.addLogos(state, bundle.logos)
    MobileStateUpdates.addMetadata(state, bundle.metadata)
    MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts)
    state.mobileScreenLoading.sectorScreen = state.mobileScreenLoading.sectorScreen.succeeded()

    return { ...state }
}
