import React from 'react';
import MultiLegOptionTradeInputPage from './MultiLegOptionTradeInputPage';
import { ApiData } from 'phoenix/models';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { Card } from '@mui/material';
import { TradeReceiptPage } from 'components/TradeTicket/Shared/TradeReceiptPage';
import { TradeReviewPage } from 'components/TradeTicket/Shared/TradeReviewPage';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { OptionsTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';

type MultiLegOptionTradeTicketProps = {
    logo: ApiData<SecurityLogo>;
    orderAgain?: boolean
};

export const MultiLegOptionTradeTicket = TelemetryProvider(({ logo, orderAgain }: MultiLegOptionTradeTicketProps) => {
    const { state } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();

    return (
        <Card className='trade-ticket'>
            {(() => {
                switch (state) {
                    case 'input':
                        return <MultiLegOptionTradeInputPage orderAgain={orderAgain} logo={logo} />;
                    case 'review':
                        return <TradeReviewPage />;
                    case 'done':
                        return <TradeReceiptPage />;
                }
            })()}
        </Card>
    );
}, TelemetryCategories.trade);
