// @ts-strict-ignore
import ReactPlayer from "react-player"
import { create } from "zustand"

type VideoPlayerStore = {
    state: VideoPlayerState
    actions: VideoPlayerStateActions & VideoPlayerDataActions
    data: VideoPlayerData
}

const defaultState: VideoPlayerState = {
    isSeeking: false,
    isLoading: null,
    isBuffering: false,
    isPlaying: false,
    isMuted: true,
    isVisible: false,
    variant: 'modal',
    currentTime: 0,
    duration: 0
}

const defaultData: VideoPlayerData = {
    post : {
        title: 'Kathryn Rooney Vera on CNBC',
        url: 'https://vimeo.com/545620196',
        thumbnail: 'https://roost.nbcuni.com/bin/viewasset.html/content/dam/Peacock/Landing-Pages/collections/msnbc-hub/msnbc-social-new.jpg/_jcr_content/renditions/original.JPEG'
      },
      author: {
        title: 'Chief Market Economist - StoneX',
        name: 'Katheryn Rooney Vera',
        imageUri: 'https://i.vimeocdn.com/portrait/33899248_75x75'
      }
 }

export const useModalVideoPlayerStore = create<VideoPlayerStore>((set, get) => {
    return {
        state: { ...defaultState },
        actions: {
            setSeeking: (isSeeking: boolean) => set(s => ({...s, state:{...s.state, isSeeking}})),
            setLoading: (isLoading: boolean) => set(s => ({...s, state:{...s.state, isLoading}})),
            setBuffering: (isBuffering: boolean) => set(s => ({...s, state:{...s.state, isBuffering}})),
            setPlaying: (isPlaying: boolean) => set(s => ({...s, state:{...s.state, isPlaying}})),
            setMuted: (isMuted: boolean) => set(s => ({...s, state:{...s.state, isMuted}})),
            setVariant: (variant: VideoPlayerVariant) => set(s => ({...s, state: {...s.state, variant}})),
            setUrl: (url: string) => { 
                get().actions.reset(); 
                set(s => ({...s, data: {...s.data, url}})) 
            },
            reset: () => set(s => ({...s, state: defaultState, data: defaultData})),
            setVisible: (isVisible: boolean) =>  set(s => ({...s, state: {...s.state, isVisible}})),
            setData: (data: Partial<VideoPlayerData>) => set(s => ({...s, data: {...s.data, ...data}})),
            setCurrentTime: (num: number) => set(s => ({...s, state: {...s.state, currentTime: num}})),
            setDuration: (num: number) => set(s => ({...s, state: {...s.state, duration: num}})),
        },
        data: { ...defaultData }
    }
})

export const VideoPlayerHelpers  = () : {
    play: VoidAction,
    pause: VoidAction,
    mute: VoidAction,
    unmute: VoidAction,
    startBuffering: VoidAction,
    stopBuffering: VoidAction,
    expand: VoidAction,
    collapse: VoidAction,
    skipPrev: VoidAction,
    skipNext: VoidAction,
    load: (url: string) => void
    skipTo: (num: number, playerRef: React.MutableRefObject<ReactPlayer>) => void
} => {

    const { actions, state } = useModalVideoPlayerStore()

    return {
        play: () => state.isBuffering? null : actions.setPlaying(true),
        pause: () => state.isBuffering? null : actions.setPlaying(false),

        mute: () => actions.setMuted(true),
        unmute: () => actions.setMuted(false),

        startBuffering: () => actions.setBuffering(true),
        stopBuffering: () => actions.setBuffering(false),

        expand: () => actions.setVariant('fullscreen'),
        collapse: () => actions.setVariant('modal'),

        skipPrev: () => state.isBuffering? null : actions.setCurrentTime(state.currentTime - 10),
        skipNext: () => state.isBuffering? null : actions.setCurrentTime(state.currentTime + 10),

        load: (url: string) => () => actions.setUrl(url),

        skipTo: (num: number, playerRef: React.MutableRefObject<ReactPlayer>) => state.isBuffering? null : playerRef?.current.seekTo(num)
       
    }
}

