// @ts-strict-ignore
import { IconButton, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React, { useCallback, useMemo, useState } from 'react';
import { ActionModal, Flex } from '..';
import { useColors } from 'hooks/UseColors';
import { FormattedTextList, InfoIconBundle } from 'phoenix/assets/lang/Snex1LanguagePack';
import { useText } from 'phoenix/hooks/UseText';
import { GetVariant } from 'phoenix/util/Variant';
import { FormattedTextDisplay } from '../FormattedTextDisplay/FormattedTextDisplay';

interface InfoIconProps {
    title: string;
    label?: string;
    message?: string;
    messagePath?: (lang: InfoIconBundle) => FormattedTextList;
    modalWidth?: number;
    children?: any;
    style?: React.CSSProperties;
}

export const InfoIcon = React.memo((props: InfoIconProps) => {
    const colors = useColors();
    const [open, setOpen] = useState(false);
    const variant = GetVariant();
    const bundle = useText((s) => s.infoIconHelpText[variant.isClient ? 'client' : 'one']);

    const messageViaPath = bundle && props.messagePath ? props.messagePath(bundle) : null;
    const messageViaProp = props.message;
    const messageViaChildren = props.children;
    const message = useMemo(() => {
        if (messageViaChildren) return messageViaChildren;
        else if (messageViaProp) return <Typography variant='body1'>{messageViaProp}</Typography>;
        else if (messageViaPath) return <FormattedTextDisplay text={messageViaPath} />;
        else return null;
    }, [messageViaChildren, messageViaPath, messageViaProp]);

    const handleClick = useCallback(
        (e: React.MouseEvent, show: boolean) => {
            if (e) e.bubbles = false;
            e?.preventDefault();
            setOpen(show);
        },
        [setOpen]
    );

    if (!message) return null;

    return (
        <>
            <Flex className='info-icon' row align='center' style={{ margin: '0 10px', ...props.style }} onClick={(e) => handleClick(e, true)}>
                <IconButton size='large'>
                    <InfoOutlined style={{ fill: colors.blurredAdornmentColor, fontSize: 18 }} />
                </IconButton>
                {props.label ? (
                    <Typography variant='h6' style={{ marginLeft: 5, whiteSpace: 'nowrap' }} color='textSecondary'>
                        {props.label}
                    </Typography>
                ) : null}
            </Flex>
            <ActionModal isOpen={open} label={props.title} showScrollbar toggleModal={(_, e) => handleClick(e, false)} width={props.modalWidth || 400}>
                {message}
            </ActionModal>
        </>
    );
});
