import { useState, useEffect } from 'react';
import { XS } from 'phoenix/xstream/XS';

export const useSubscribeOnVisibility = (subscribe: () => any, visible?: boolean) => {
    const [subscription, setSubscription] = useState(null);
    const [visibility, setVisibility] = useState(visible || false);

    useEffect(() => {
        (async () => {
            if (visibility) setSubscription(await subscribe());
            else if (!visibility && subscription) XS.stop(subscription);
        })();
    }, [visibility]);

    useEffect(() => {
        return () => {
            if (subscription) XS.stop(subscription);
        };
    }, [subscription]);

    return [subscription, setVisibility];
};
