// @ts-strict-ignore
import { addMilliseconds } from 'date-fns'

const mutexLookup: { [key: string]: { promise: Promise<any>, resolve: (val: any) => any, reject: (val: any) => any, expire: Date } } = {}

const EXPIRE_MS_DEFAULT = 5000
const MUTEX_DEBUG_LOGS = false
const log = (key: string, message: string) => {
    if (!MUTEX_DEBUG_LOGS) return
    console.log(`[MUTEX@${key}] ${message} / lookup value`, mutexLookup[key])
}

export const MutexEnter = async <TResult>(key: string, expireMs: number = EXPIRE_MS_DEFAULT): Promise<TResult> => {
    if (!mutexLookup[key] || (mutexLookup[key].expire < new Date())) {
        log(key, 'Permitting')
        let r = null
        let j = null
        const p = new Promise((resolve, reject) => {
            r = resolve; j = reject
        })
        mutexLookup[key] = {
            promise: p,
            resolve: r,
            reject: j,
            expire: addMilliseconds(new Date(), expireMs)
        }
        return null
    } else {
        log(key, 'Deferring...')
        const result = await mutexLookup[key].promise
        log(key, `Unlocking with result: ${result}`)
        return result
    }
}

export const MutexExit = <TResult>(key: string, result?: TResult) => {
    if (!mutexLookup[key]) return
    mutexLookup[key].resolve(result || null)
    mutexLookup[key].expire = new Date() // Instead of deleting a possible awaited promise, simply expire the mutex so no one gets hung up on it
    log(key, 'Exiting')
}

export const MutexFail = <TError>(key: string, error: TError) => {
    if (!mutexLookup[key]) return
    mutexLookup[key].reject(error)
    mutexLookup[key].expire = new Date() // Instead of deleting a possible awaited promise, simply expire the mutex so no one gets hung up on it
    log(key, 'Failing')
}
