// @ts-strict-ignore
import { animateScroll as scroll } from 'react-scroll'

type ScrollSpeedProfile = 'slow' | 'fast' | 'instant';

const ScrollOpts = (speed: ScrollSpeedProfile, containerId: string) => {
    const smooth = speed !== 'instant'
    const duration = (() => {
        switch (speed) {
            case 'slow': return 500
            case 'fast': return 150
            default: return 0
        }
    })()
    return { smooth, duration, containerId }
}

export const ScrollSidebarToTop = (speed: ScrollSpeedProfile = 'instant') => scroll.scrollTo(0, ScrollOpts(speed, 'sidebar-anchor'))

export const ScrollPageToTop = (speed: ScrollSpeedProfile = 'instant') => {
    const opts = ScrollOpts(speed, 'root')
    scroll.scrollTo(0, opts) // <-- Works on Firefox
    window.scroll({ top: 0, left: 0, behavior: opts.smooth ? 'smooth' : 'auto' }) // <-- Works on Chrome
}
