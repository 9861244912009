import React from "react"
import { OnboardingPageTitle } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useText } from "phoenix/hooks/UseText"


export const FPLWelcomeForm = () => {

    const text = useText(t => t.fplOnboarding.welcome)

    return(
        <OnboardingPageTitle title={text.title} subtitles={[text.text]} />
        )
}