import {
    AreaStyleOptions,
    BarStyleOptions,
    CandlestickStyleOptions,
    ChartOptions,
    ColorType,
    CrosshairMode,
    CrosshairOptions,
    DeepPartial,
    GridOptions,
    HistogramStyleOptions,
    LayoutOptions,
    LineStyle,
    LineStyleOptions,
    LineType,
    PriceLineOptions,
    PriceLineSource,
    PriceScaleMode,
    PriceScaleOptions,
    SeriesOptionsCommon,
    TimeScaleOptions,
    WatermarkOptions
} from 'lightweight-charts';

// These constants are copied and de-uglified from lightweight-charts.esm1.development.js.
// They should be up-to-date as of lightweight-charts v3.2.0.
// Default values are essential for reactive components, but they are not exported from the library.
// So we'll do it ourselves!

export const CrosshairOptionsDefaults: CrosshairOptions = {
    vertLine: {
        color: '#758696',
        width: 1,
        style: LineStyle.LargeDashed,
        visible: true,
        labelVisible: true,
        labelBackgroundColor: '#4c525e'
    },
    horzLine: {
        color: '#758696',
        width: 1,
        style: LineStyle.LargeDashed,
        visible: true,
        labelVisible: true,
        labelBackgroundColor: '#4c525e'
    },
    mode: CrosshairMode.Magnet
};

export const GridOptionsDefaults: GridOptions = {
    vertLines: {
        color: '#D6DCDE',
        style: LineStyle.Solid,
        visible: true
    },
    horzLines: {
        color: '#D6DCDE',
        style: LineStyle.Solid,
        visible: true
    }
};

const defaultFontFamily = "'Trebuchet MS', Roboto, Ubuntu, sans-serif";

export const LayoutOptionsDefaults: LayoutOptions = {
    background: { type: ColorType.Solid, color: 'transparent' },
    textColor: '#191919',
    fontSize: 11,
    fontFamily: defaultFontFamily
};

export const PriceScaleOptionsDefaults: PriceScaleOptions = {
    alignLabels: true,
    autoScale: true,
    borderColor: '#2B2B43',
    borderVisible: true,
    entireTextOnly: false,
    invertScale: false,
    minimumWidth: 0,
    mode: PriceScaleMode.Normal,
    scaleMargins: {
        bottom: 0.1,
        top: 0.2
    },
    ticksVisible: false,
    visible: false
};

export const TimeScaleOptionsDefaults: DeepPartial<TimeScaleOptions> = {
    barSpacing: 0.5,
    borderColor: '#2B2B43',
    borderVisible: true,
    fixLeftEdge: false,
    fixRightEdge: false,
    lockVisibleTimeRangeOnResize: false,
    minBarSpacing: 0,
    minimumHeight: 0,
    rightBarStaysOnScroll: false,
    rightOffset: 0,
    secondsVisible: false,
    shiftVisibleRangeOnNewBar: false,
    ticksVisible: false,
    timeVisible: false,
    uniformDistribution: true,
    visible: true
};

export const WatermarkOptionsDefaults: WatermarkOptions = {
    color: 'rgba(0, 0, 0, 0)',
    visible: false,
    fontSize: 48,
    fontFamily: defaultFontFamily,
    fontStyle: '',
    text: '',
    horzAlign: 'center',
    vertAlign: 'center'
};

export const PriceLineOptionsDefaults: PriceLineOptions = {
    color: '#FF0000',
    price: 0,
    lineStyle: LineStyle.Dashed,
    lineWidth: 1,
    axisLabelVisible: true,
    title: '',
    lineVisible: false,
    axisLabelColor: '',
    axisLabelTextColor: ''
};

/**
 * Defaults for charts.
 * @constant
 */
export const ChartOptionsDefaults: DeepPartial<ChartOptions> = {
    trackingMode: { exitMode: 1 },
    kineticScroll: { touch: true, mouse: false },
    autoSize: true,
    width: 0,
    height: 0,
    layout: LayoutOptionsDefaults,
    crosshair: CrosshairOptionsDefaults,
    grid: GridOptionsDefaults,
    overlayPriceScales: { ...PriceScaleOptionsDefaults },
    leftPriceScale: { ...PriceScaleOptionsDefaults, visible: false },
    rightPriceScale: { ...PriceScaleOptionsDefaults, visible: true },
    timeScale: TimeScaleOptionsDefaults,
    watermark: WatermarkOptionsDefaults,
    localization: {
        locale: navigator.language,
        dateFormat: "dd MMM 'yy"
    },
    handleScroll: {
        mouseWheel: true,
        pressedMouseMove: true,
        horzTouchDrag: true,
        vertTouchDrag: true
    },
    handleScale: {
        axisPressedMouseMove: {
            time: true,
            price: true
        },
        axisDoubleClickReset: true,
        mouseWheel: true,
        pinch: true
    }
};

/**
 * Common defaults for all series types. Usually not used directly.
 * @constant
 */
export const SeriesOptionsDefaults: SeriesOptionsCommon = {
    title: '',
    visible: true,
    lastValueVisible: true,
    priceLineVisible: true,
    priceLineSource: PriceLineSource.LastBar,
    priceLineWidth: 1,
    priceLineColor: '',
    priceLineStyle: LineStyle.Dashed,
    baseLineVisible: true,
    baseLineWidth: 1,
    baseLineColor: '#B2B5BE',
    baseLineStyle: LineStyle.Solid,
    priceFormat: {
        type: 'price',
        precision: 2,
        minMove: 0.01
    }
};

const CandlestickSpecificStyleDefaults: CandlestickStyleOptions = {
    upColor: '#26a69a',
    downColor: '#ef5350',
    wickVisible: true,
    borderVisible: true,
    borderColor: '#378658',
    borderUpColor: '#26a69a',
    borderDownColor: '#ef5350',
    wickColor: '#737375',
    wickUpColor: '#26a69a',
    wickDownColor: '#ef5350'
};

/**
 * Defaults for 'Candlestick' series.
 * @constant
 */
export const CandlestickStyleDefaults: CandlestickStyleOptions & SeriesOptionsCommon = {
    ...CandlestickSpecificStyleDefaults,
    ...SeriesOptionsDefaults
};

const BarSpecificStyleDefaults: BarStyleOptions = {
    upColor: '#26a69a',
    downColor: '#ef5350',
    openVisible: true,
    thinBars: true
};

/**
 * Defaults for 'Bar' series.
 * @constant
 */
export const BarStyleDefaults: BarStyleOptions & SeriesOptionsCommon = {
    ...BarSpecificStyleDefaults,
    ...SeriesOptionsDefaults
};

const LineSpecificStyleDefaults: LineStyleOptions = {
    color: '#2196f3',
    crosshairMarkerBackgroundColor: '',
    crosshairMarkerBorderColor: '',
    crosshairMarkerBorderWidth: 2,
    crosshairMarkerRadius: 4,
    crosshairMarkerVisible: true,
    lastPriceAnimation: 0,
    lineStyle: LineStyle.Solid,
    lineType: LineType.Simple,
    lineVisible: true,
    lineWidth: 3,
    pointMarkersVisible: false
};

/**
 * Defaults for 'Line' series.
 * @constant
 */
export const LineStyleDefaults: LineStyleOptions & SeriesOptionsCommon = {
    ...LineSpecificStyleDefaults,
    ...SeriesOptionsDefaults
};

const AreaSpecificStyleDefaults: AreaStyleOptions = {
    bottomColor: 'rgba( 40, 221, 100, 0)',
    crosshairMarkerBackgroundColor: '',
    crosshairMarkerBorderColor: '',
    crosshairMarkerBorderWidth: 2,
    crosshairMarkerRadius: 4,
    crosshairMarkerVisible: true,
    invertFilledArea: false,
    lastPriceAnimation: 0,
    lineColor: '#33D778',
    lineStyle: LineStyle.Solid,
    lineType: LineType.Simple,
    lineVisible: true,
    lineWidth: 3,
    pointMarkersVisible: false,
    topColor: 'rgba( 46, 220, 135, 0.4)'
};

/**
 * Defaults for 'Area' series.
 * @constant
 */
export const AreaStyleDefaults: AreaStyleOptions & SeriesOptionsCommon = {
    ...AreaSpecificStyleDefaults,
    ...SeriesOptionsDefaults
};

const HistogramSpecificStyleDefaults: HistogramStyleOptions = {
    color: '#26a69a',
    base: 0
};

/**
 * Defaults for 'Histogram' series.
 * @constant
 */
export const HistogramStyleDefaults: HistogramStyleOptions & SeriesOptionsCommon = {
    ...HistogramSpecificStyleDefaults,
    ...SeriesOptionsDefaults
};
