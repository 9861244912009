import { ApiData } from '../../../models'
import { Order } from '../Orders'
import { StockLoanRate } from '../StockLoanRate/StockLoanRate';

export class TradingState {
    submit: ApiData<Order | null>;
    verify: ApiData<Order | null>;
    edit: ApiData<Order | null>;
    cancel: ApiData<string | null>;
    stockLoanRate: ApiData<StockLoanRate | null>;

    constructor () {
        this.submit = new ApiData()
        this.verify = new ApiData()
        this.edit = new ApiData()
        this.cancel = new ApiData(null)
        this.stockLoanRate = new ApiData()
    }
}
