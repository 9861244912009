// @ts-strict-ignore
import { ReduxAction } from '../../models/ReduxAction'
import { Actions } from '../actions/Constants'
import { TradingState } from '../models/Trading/TradingState'

export const TradingReducer = (state: TradingState = new TradingState(), action: ReduxAction): TradingState => {
    switch (action.type) {
        case Actions.Trading.Submit.Loading: return { ...state, submit: state.submit.startLoading() }
        case Actions.Trading.Submit.Success: return { ...state, submit: state.submit.succeeded(action.data) }
        case Actions.Trading.Submit.Failure: return { ...state, submit: state.submit.failed(action.error) }

        case Actions.Trading.Verify.Loading: return { ...state, verify: state.verify.startLoading() }
        case Actions.Trading.Verify.Success: return { ...state, verify: state.verify.succeeded(action.data) }
        case Actions.Trading.Verify.Failure: return { ...state, verify: state.verify.failed(action.error) }
        case Actions.Trading.ResetVerify: return { ...state, verify: state.verify.reset() }

        case Actions.Trading.Edit.Loading: return { ...state, edit: state.edit.startLoading() }
        case Actions.Trading.Edit.Success: return { ...state, edit: state.edit.succeeded(action.data) }
        case Actions.Trading.Edit.Failure: return { ...state, edit: state.edit.failed(action.error) }

        case Actions.Trading.Cancel.Loading: return { ...state, cancel: state.cancel.startLoading(action.subject) }
        case Actions.Trading.Cancel.Success: return { ...state, cancel: state.cancel.succeeded(action.subject) }
        case Actions.Trading.Cancel.Failure: return { ...state, cancel: state.cancel.failed(action.error) }

        case Actions.Trading.GetLoanRate.Loading: return { ...state, stockLoanRate: state.stockLoanRate.startLoading() }
        case Actions.Trading.GetLoanRate.Success: return { ...state, stockLoanRate: state.stockLoanRate.succeeded(action.data) }
        case Actions.Trading.GetLoanRate.Failure: return { ...state, stockLoanRate: state.stockLoanRate.failed(action.error) }

        default: return state
    }
}
