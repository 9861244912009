import { LoadingSpinner } from 'components';
import { T } from 'phoenix/assets/lang/T';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { GetFundReinvestmentPreferences, SetFundReinvestmentPreferences } from 'phoenix/redux/actions/FundActions';
import { UpdateReinvestmentOptionsRequest } from 'phoenix/redux/models/Funds/UpdateReinvestmentOptionsRequest';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BigTradeButton } from '../Shared/TradeFormComponents';
import { ReinvestmentOptions, ReinvestmentOptionsControls } from './ReinvestmentOptionControls';

export const EditReinvestmentOptionsPage = (props: { symbol: string; accountNumber: string }): ReactElement => {
    const dispatch = useDispatch();
    const { symbol, accountNumber } = props;
    const [opts, setOpts] = useState<ReinvestmentOptions>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const fundState = useSnexStore((s) => s.funds.byQsi[symbol]);
    const { reinvestmentPreferences } = fundState;

    useEffect(() => {
        const prefs = reinvestmentPreferences?.[accountNumber]?.data;
        setOpts({
            dividends: prefs?.reinvestDividends || false,
            longTermGains: prefs?.reinvestLongTermGains || false,
            shortTermGains: prefs?.reinvestShortTermGains || false
        });
    }, [accountNumber, reinvestmentPreferences]);

    useEffect(() => {
        if (symbol && accountNumber) dispatch(GetFundReinvestmentPreferences(symbol, accountNumber));
    }, [accountNumber, dispatch, symbol]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const body: UpdateReinvestmentOptionsRequest = {
            reinvestDividends: opts?.dividends || false,
            reinvestLongTermGains: opts?.longTermGains || false,
            reinvestShortTermGains: opts?.shortTermGains || false
        };
        await dispatch(SetFundReinvestmentPreferences(symbol, accountNumber, body));
        await dispatch(GetFundReinvestmentPreferences(symbol, accountNumber));
        setIsSubmitting(false);
    };

    const buttonText = T((s) => s.tradeTicket.reinvestmentOptions.saveButton);

    return reinvestmentPreferences?.[accountNumber]?.loading || reinvestmentPreferences?.[accountNumber]?.pristine || !opts || isSubmitting ? (
        <LoadingSpinner />
    ) : (
        <div style={{ boxSizing: 'border-box' }}>
            <ReinvestmentOptionsControls isFund accountNumber={accountNumber} options={opts} symbol={symbol} onOptionsChange={setOpts} />
            <BigTradeButton onClick={handleSubmit}>{buttonText}</BigTradeButton>
        </div>
    );
};
