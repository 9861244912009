import { Sum } from './ArrayMutations'

const adjectives1 = [
    'Angry', 'Bored', 'Chilly', 'Devious', 'Excellent', 'Fluorescent', 'Giant', 'Huge', 'Iridescent', 'Jovial', 'Kurt', 'Lugubrious', 'Manic',
    'Nice', 'Opulent', 'Precious', 'Quiet', 'Radiant', 'Slimy', 'Tall', 'Underwhelming', 'Violet', 'Waxy', 'Yellow', 'Zany'
]

const animals1 = [
    'Pangolin', 'Badger', 'Coyote', 'Deer', 'Elephant', 'Ferret', 'Giraffe', 'Hyena', 'Ibis', 'Jackal', 'Lombax', 'Lemur', 'Mongoose',
    'Nyala', 'Octopus', 'Possum', 'Quail', 'Rabbit', 'Salamander', 'Tarantula', 'Ungulate', 'Vole', 'Wolf', 'Xenomorph', 'Yak', 'Zebra'
]

const verbs1 = [
    'Advancing', 'Bouncing', 'Crumbling', 'Dancing', 'Foraging', 'Gesturing', 'Hopping', 'Inspiring', 'Jumping', 'Knitting', 'Languishing', 'Meddling',
    'Narrating', 'Organizing', 'Paralyzing', 'Quibbling', 'Raving', 'Snorkeling', 'Twinkling', 'Underestimating', 'Vaporizing', 'Wrestling', 'Xeroxing', 'Yodeling', 'Zigzagging'
]

const frontSeries = [adjectives1]
const middleSeries = [animals1]
const endSeries = [verbs1]

const generateNumber = (seed: string, limit: number) => {
    const codes = new Array(seed.length).fill(0).map((_, idx) => seed.charCodeAt(idx))
    return Sum(codes) % limit
}

const pickOf = <TItem>(seed: string, options: TItem[]): TItem => options[generateNumber(seed, options.length)]

export const GenerateFancifulName = (seed: string) => {
    const length = seed?.length || 0
    if (length < 3) return 'ErroroneousElephant!'
    const chunkLength = Math.floor(length / 3)
    const [c1, c2, c3] = [
        seed.slice(0, chunkLength),
        seed.slice(chunkLength, chunkLength * 2),
        seed.slice(chunkLength * 2, chunkLength * 3)
    ]

    const pickOfSeries = (chunk: string, series: string[][]) => pickOf(seed, pickOf(chunk, series))
    return `${pickOfSeries(c1, frontSeries)}${pickOfSeries(c2, middleSeries)}${pickOfSeries(c3, endSeries)}`
}
