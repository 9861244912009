import { Card } from 'components/Card';
import { Flex } from 'components/Flex';
import React, { ReactElement, ReactNode, useRef } from 'react';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import './BaseScreenLayout.scss';

export const ContentBaseLayout = ({ children }: { children: ReactNode }): ReactElement => {
    const { sidebarId = '', sidebar = null, sidebarLeft = false, fullWidthContent = false, noCard = false, footerHeight, noShow } = useBaseScreenLayoutStore((s) => s);

    const contentRef = useRef<HTMLDivElement>(null);
    const contentGap = 16;

    if (noShow) return <>{children}</>;

    return (
        <Flex
            ref={contentRef}
            row
            fullWidth
            reverse={sidebarLeft}
            style={{
                boxSizing: 'border-box',
                height: '100%',
                padding: '0 32px',
                gap: contentGap,
                overflow: 'hidden',
                maxWidth: fullWidthContent ? undefined : 1450 + 64 /* max width + padding */
            }}
            id='content-section'
        >
            <div id={'main-content'} style={{ paddingBottom: footerHeight }}>
                {children}
            </div>
            {sidebar && (
                <div id={sidebarId} className='sidebar-content' style={{ paddingBottom: footerHeight }}>
                    {noCard ? sidebar : <Card borderRadius={16}>{sidebar}</Card>}
                </div>
            )}
        </Flex>
    );
};
