// @ts-strict-ignore
import { ReduxAction } from '../../models/ReduxAction'
import { Actions } from '../actions/Constants'
import { FuturesOnboardingState } from '../models/FuturesOnboarding/FuturesOnboardingState'

/* @deprecated This is for mobile use only. For web use the useFuturesOnboardingStore */
export const FuturesOnboardingReducer = (state: FuturesOnboardingState = new FuturesOnboardingState(), action: ReduxAction): FuturesOnboardingState => {
    switch (action.type) {
        case Actions.FuturesOnboarding.GetFuturesOnboarding.Loading:
        case Actions.FuturesOnboarding.UpdateFuturesOnboarding.Loading:
            return { ...state, all: state.all.startLoading(state.all.data), result: state.result.startLoading(state.result.data) }

        case Actions.FuturesOnboarding.GetFuturesOnboarding.Success:
        case Actions.FuturesOnboarding.UpdateFuturesOnboarding.Success:
            return { ...state, all: state.all.succeeded(action.data), result: state.result.startLoading(state.result.data) };
        case Actions.FuturesOnboarding.SubmitFuturesOnboarding.Success: 
            return { ...state, all: state.all.succeeded(action.data.onboardingData), result: state.result.succeeded(action.data) } 
       

        case Actions.FuturesOnboarding.GetFuturesOnboarding.Failure: 
        case Actions.FuturesOnboarding.UpdateFuturesOnboarding.Failure: 
        case Actions.FuturesOnboarding.SubmitFuturesOnboarding.Failure: 
            return { ...state, all: state.all.failed(action.error),  result: state.result.failed(action.error)};
      
            
        default: return state;
    }
}
