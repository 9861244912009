// @ts-strict-ignore
import { ApiData } from '../../models';
import { ReduxAction } from '../../models/ReduxAction';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { AccountSummary } from '../models';
import { AccountSummaryState } from '../models/AccountSummary/AccountSummaryState';

const permitted = GroupNameChecker([Actions.AccountSummaries, Actions.AccountCharts.LiveUpdates]);
export const AccountSummaryReducer = (state: AccountSummaryState = new AccountSummaryState(), action: ReduxAction): AccountSummaryState => {
    if (!permitted(action)) return state;

    const makeSummary = () => state.byNumber[action.subject.accountNumber || action.subject] || new ApiData<AccountSummary>();
    const or = <TData>(d?: ApiData<TData>) => d || new ApiData<TData>();

    // Handle batch up here since it's a bit more involved
    const shift = Actions.AccountSummaries.GetBatch.matches(action.type);
    if (shift) {
        const numbers = action.subject || Object.entries(state.byNumber)?.map((n) => n[0]) || [];
        const results = action.data?.data as AccountSummary[];
        switch (shift) {
            case 'loading':
                numbers.forEach((n) => (state.byNumber[n] = or(state.byNumber[n]).startLoading()));
                break;
            case 'success':
                results.forEach((s) => (state.byNumber[s.accountNumber] = or(state.byNumber[s.accountNumber])?.succeeded(s)));
                break;
            case 'failure':
                numbers.forEach((n) => (state.byNumber[n] = or(state.byNumber[n]).failed(action.error)));
                break;
        }
        return state;
    }

    switch (action.type) {
        case Actions.AccountSummaries.GetAggregate.Loading:
            return { ...state, aggregate: state.aggregate.startLoading(state.aggregate.data) };
        case Actions.AccountSummaries.GetAggregate.Success:
            return { ...state, aggregate: state.aggregate.succeeded(action.data) };
        case Actions.AccountSummaries.GetAggregate.Failure:
            return { ...state, aggregate: state.aggregate.failed(action.error) };

        case Actions.AccountSummaries.GetBuyingPower.Loading:
            return { ...state, buyingPower: state.buyingPower.startLoading(state.buyingPower.data) };
        case Actions.AccountSummaries.GetBuyingPower.Success:
            return { ...state, buyingPower: state.buyingPower.succeeded({ ...action.data, optionsBuyingPower: action.data.cashAndEquivalents }) };
        case Actions.AccountSummaries.GetBuyingPower.Failure:
            return { ...state, buyingPower: state.buyingPower.failed(action.error) };

        case Actions.AccountCharts.LiveUpdates.Update: {
            const { accountNumber } = action.subject;
            const point: { value: number } = action.data;
            const d: ApiData<AccountSummary> = makeSummary();
            const oldValue = d?.data?.totalAccountValueCurrent;
            const oldGl = d?.data?.todaysGainLoss;
            const basisKnown = [oldValue, oldGl].every((x) => x !== null && x !== undefined);
            const glBasis = oldValue - oldGl;
            const newGl = basisKnown ? point.value - glBasis : NaN;
            const newGlPct = basisKnown ? newGl / glBasis : NaN;
            const newState = {
                ...state,
                byNumber: {
                    ...state.byNumber,
                    [accountNumber]: d.succeededSpread({
                        totalAccountValueCurrent: point.value,
                        todaysGainLoss: newGl,
                        todaysGainLossPercentage: newGlPct
                    } as AccountSummary)
                }
            };
            return newState;
        }
        default:
            return state;
    }
};
