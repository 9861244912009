import { ApiData } from '../../../models'
import { EarningsCallIndex, UpcomingEarnings } from '../Securities/EarningsCallIndex'
import { EarningsCallTranscript } from '../Securities/EarningsCallTranscript'

export class EarningsCallsState {
    indexBySymbol: { [key: string]: ApiData<EarningsCallIndex> }
    nextBySymbol: { [symbol: string]: ApiData<UpcomingEarnings> }
    /** @deprecated This identifies calls by symbol, not by call ID. Please use detailsByCallId instead */ detailsById: { [key: string]: ApiData<EarningsCallTranscript> }
    detailsByCallId: { [callId: string]: ApiData<EarningsCallTranscript> }

    constructor () {
        this.indexBySymbol = {}
        this.nextBySymbol = {}
        this.detailsById = {}
        this.detailsByCallId = {}
    }
}
