import { GetConfig, ResolveEnvironment } from '../constants'
import { GetUseLimitedAccess } from './DebugMode'

// This is a temporary system until we integrate with curator, at which point we'll actually authorize on a per-user basis. For now it's just
// a presentational mechanism

export interface VariantFlags {
    showAlerts: boolean
    quoteStreamTimeliness: 'realtime' | 'delayed'
    showTradeTicket: boolean
    showAdvancedChart: boolean
    showDeepAnalysis: boolean
    showOptions: boolean
    showWithdraw: boolean
    showVulcanButton: boolean
    canViewOptionContract: boolean
    canAddAccount: boolean
    canStreamNews: boolean
    canViewTradeHistory: boolean
    showFirstTradePrompt: boolean
    showNotificationSettings: boolean
    showPremiumControls: boolean
    showAmexMessage: boolean
    isClient: boolean
    canAddFuturesAccount: boolean,
    canViewOneProNetwork: boolean
}

export const allAccess: VariantFlags = {
    showAlerts: true,
    quoteStreamTimeliness: 'realtime',
    showTradeTicket: true,
    showAdvancedChart: true,
    showDeepAnalysis: true,
    showOptions: true,
    showWithdraw: true,
    showVulcanButton: false,
    canViewOptionContract: true,
    canAddAccount: true,
    canViewTradeHistory: true,
    showFirstTradePrompt: true,
    showNotificationSettings: true,
    showPremiumControls: true,
    showAmexMessage: false,
    canStreamNews: true,
    isClient: false,
    canAddFuturesAccount: true,
    canViewOneProNetwork: true
}

export const clientAccess: VariantFlags = {
    showAlerts: false,
    quoteStreamTimeliness: 'delayed',
    showTradeTicket: false,
    showAdvancedChart: false,
    showDeepAnalysis: false,
    showOptions: false,
    showWithdraw: false,
    showVulcanButton: true,
    canViewOptionContract: false,
    canAddAccount: false,
    canViewTradeHistory: false,
    showFirstTradePrompt: false,
    showNotificationSettings: false,
    showPremiumControls: false,
    showAmexMessage: true,
    canStreamNews: false,
    isClient: true,
    canAddFuturesAccount: false,
    canViewOneProNetwork: true
}

export const GetVariant = (): VariantFlags => {
    const env = ResolveEnvironment()
    if (GetUseLimitedAccess()) return clientAccess
    if (GetConfig()?.ApiEnvironment === 'local') return allAccess
    if (env === 'beta') return clientAccess
    return allAccess
}
