// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownComponent } from '..';
import { UpdateCurrentUserPrefsAction } from 'phoenix/redux/actions';
import { GetLanguagesAction } from 'phoenix/redux/actions/SystemActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Language } from 'phoenix/redux/models/System/Language';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import _ from 'lodash';

const GetLanguageFromSystem = (): string => {
    const code = window.navigator.language;
    switch (code) {
        case 'es-US':
            return 'es-US';
        default:
            return 'en-US';
    }
};

type LanguageOption = {
    label: string;
    value: string;
};

const LanguageDropdownComponent = () => {
    const dispatch = useDispatch();
    const prefs = useSelector((s: GlobalState) => s.user.myPrefs);
    const update = useSelector((s: GlobalState) => s.user.updatePrefs);
    const languages = useSnexStore((s) => s.system.languages)?.data;
    const languageOptions: LanguageOption[] =
        [
            { label: 'Spanish', value: 'es-US' },
            { label: 'Portuguese', value: 'pt-BR' },
            { label: 'Chinese (Simplified)', value: 'zh-CN' },
            { label: 'German', value: 'de-DE' },
            ...languages.map((l) => ({ label: l.displayName, value: l?.code }))
        ] || [];
    const uniqueLanguageOptions: LanguageOption[] = _.uniqBy(languageOptions, 'value');

    const langSort = (a: LanguageOption, b: LanguageOption) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        // English is prioritized, then alphabetical
        return labelA === 'ENGLISH' || labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    };

    useEffect(() => {
        dispatch(GetLanguagesAction());
    }, []);

    const handleUpdate = (lang: Language) => {
        console.log('lang: ', lang);
        // Set document lang
        document.documentElement.lang = lang.code;
        dispatch(UpdateCurrentUserPrefsAction({ preference: { language: lang } }));
    };

    return (
        <DropdownComponent
            disabled={update.loading}
            options={uniqueLanguageOptions.sort(langSort)}
            value={prefs.data?.preference?.language?.code || GetLanguageFromSystem()}
            onChange={(v: { label: string; value: any }) => handleUpdate({ code: v.value, displayName: v.label })}
        />
    );
};

export const LanguageDropdown = React.memo(LanguageDropdownComponent);
