// @ts-strict-ignore
import { ApiData, ApiOperation, ReduxAction } from '../../models'
import { ActionNames, Actions, GroupNameChecker } from '../actions/Constants'
import { WeatherState } from '../models/Weather'

const permitted = GroupNameChecker([Actions.Weather])
export const WeatherReducer = (state: WeatherState = new WeatherState(), action: ReduxAction): WeatherState => {
    if (!permitted(action)) return state

    switch (action.type) {

        case Actions.Weather.GetVideos.Loading:
            return { ...state, videos: state.videos.startLoading() };
        case Actions.Weather.GetVideos.Success:
            return { ...state, videos: state.videos.succeeded(action.data) };
        case Actions.Weather.GetVideos.Failure:
            return { ...state, videos: state.videos.failed(action.error) };
    }
}