// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useRegistrationStore } from "../../Store"
import { getPasswordErrors, PasswordIsValid } from "../Password/Validation"
import { getUserInfoErrors, userInfoIsComplete, UserInfoIsValid } from "../UserInfo/Validation"
import { Registration_Personal_Form } from "./Form"
import { getPersonalErrors, PersonalIsValid } from "./Validation"

export const registration_personal_step: OnboardingStep = {
    label: "Personal Info",
    isComplete: () => PersonalIsValid(),
    isEnabled: () => UserInfoIsValid() && !!useRegistrationStore.getState().isWelcomed && !!useRegistrationStore.getState().emailVerified,
    isCurrent: () => ['personal'].includes(useRegistrationStore?.getState()?.selectedScreenId),
    onClickGoToScreenId: 'personal'
}

export const registration_personal_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => useRegistrationStore.getState().setValue('selectedScreenId', 'password'),
    isPrimary: true,
    isEnabled: () => PersonalIsValid()
        && UserInfoIsValid() && !!useRegistrationStore.getState().isWelcomed
}

export const registration_personal_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useRegistrationStore.getState().setValue('selectedScreenId', 'userInfo'),
    isPrimary: false,
    isEnabled: () => true
}

export const registration_personal_screen: OnboardingScreen = {
    id: "personal",
    validate: getPersonalErrors,
    buttons: [registration_personal_prevButton, registration_personal_nextButton],
    title: T(t => t.webRegistration.usCitizenQuestion),
    subtitles: [],
    body: <Registration_Personal_Form />
}
