// @ts-strict-ignore
import { LoadingSpinner } from 'components';
import { OnboardingViewModel } from 'phoenix/onboarding/OnboardingTypes';
import React, { useEffect } from 'react';
import { OnboardingEngine } from '../Base/OnboardingEngine';
import { fpl_agreements_screen, fpl_agreements_step } from './Screens/Agreements/Index';
import { fpl_fplinfo_screen, fpl_fplinfo_step } from './Screens/FPLInfo/Index';
import { useFullyPaidLendingStore } from './Store';
import { useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import { fpl_welcome_screen, fpl_welcome_step } from './Screens/Welcome/Index';
import { fpl_disclosures_screen, fpl_disclosures_step } from './Screens/Disclosures/Index';
import { fpl_submitting_screen, fpl_submitting_step } from './Screens/Submitting/Index';
import { useFullyPaidLending } from 'phoenix/hooks/UseFullyPaidLending';
import { Routes } from 'util/Routes';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';

export const FullyPaidLendingViewModel: OnboardingViewModel = {
    steps: [fpl_welcome_step, fpl_fplinfo_step, fpl_disclosures_step, fpl_agreements_step, fpl_submitting_step],
    screens: [fpl_welcome_screen, fpl_fplinfo_screen, fpl_agreements_screen, fpl_disclosures_screen, fpl_submitting_screen],
    stepsDisabled: () => useFullyPaidLendingStore.getState().loading || useFullyPaidLendingStore.getState().verifying,
    handleLogoClick: () => {
        window.location.href = Routes.portfolio();
    }
};

export const FullyPaidLendingOnboarding = () => {
    const { data, selectedScreenId, loading, setValue } = useFullyPaidLendingStore();
    const { fullyPaidLendingEligibleAccounts } = useFullyPaidLending();
    const updateSelectedScreen = (value: string) => {
        console.log(`setting screen to ${value}`);
        setValue('selectedScreenId', value);
    };

    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    useEffect(() => {
        setBaseLayoutProps({ noShow: true });
    }, [setBaseLayoutProps]);

    useEffect(() => {
        useBuyingPowerStore.getState().load();
    }, []);
    useEffect(() => console.log({ loading, selectedScreenId }), [loading, selectedScreenId]);

    console.log({ selectedScreenId, loading, fullyPaidLendingEligibleAccounts });

    return !useFullyPaidLendingStore || loading ? (
        <LoadingSpinner />
    ) : (
        <OnboardingEngine viewModel={FullyPaidLendingViewModel} selectedScreenId={selectedScreenId} setScreenId={updateSelectedScreen} />
    );
};
