import { OptionQuote, OptionSymbol } from 'phoenix/redux/models';

export const GetBreakEvenFigures = (symbol: OptionSymbol, quote: OptionQuote, sharePrice?: number, strike?: number) => {
    if (!quote || !sharePrice || quote?.last === undefined) return { price: NaN, percent: NaN };
    else {
        const price = symbol.putCall === 'C' ? (strike || symbol.strike) + quote?.last : symbol.strike - quote?.last;
        const percent = quote?.last === 0 ? NaN : (price / sharePrice - 1) * 100;
        return { price, percent };
    }
};
