import { GetMarketTime } from '../util'
import { AppColorTheme } from './ColorVariants/AppColorTheme'
import { ThemeVariant, ThemeVariants } from './ThemeVariants'
import { TmpLightTheme } from './TmpLightTheme'
import { TmpDarkTheme } from './TmpDarkTheme'

// map to strings to inject into stylesheets
const mapProp = (str: string, entry: readonly [string, string | string[]]): string =>
    typeof entry[1] === 'string'
        ? `${str}--${entry[0]}: ${entry[1]};\n`
        : `${str}${entry[1].reduce((acc, s, i) => `${acc}--${entry[0]}${i + 1}: ${s};\n`, '')}`

const mapVariables = (theme: AppColorTheme): AppColorTheme => {
    const _cssVariables = Object.entries(theme)
        .filter((x) => x[0].charAt(0) !== '_')
        .reduce(mapProp, '')
    return { ...theme, _cssVariables }
}

const light = mapVariables(TmpLightTheme)
const dark = mapVariables(TmpDarkTheme)

export const GetColors = (appTheme?: ThemeVariant): AppColorTheme => {
    switch (appTheme) {
        case ThemeVariants.dark:
            return dark
        case ThemeVariants.market:
            return GetMarketTime() === 'open' ? light : dark
        default:
            return light
    }
}
