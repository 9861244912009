// @ts-strict-ignore
import { OpenOrders, SectionHeader, SecurityChartSection } from 'components';
import { BigTextLink } from 'components/BigTextLink/BigTextLink';
import { FuturesTimeSpreadDeeperViewHeader } from 'components/DeeperViewHeader';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { InfoMatrix, InfoMatrixGridletModel } from 'components/InfoMatrix/InfoMatrix';
import 'components/TradeTicket/Shared/index.scss';
import { FuturesTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { getDefaultOrderProps, useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { TradeTicket } from 'components/TradeTicket/TradeTicket';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import FuturesTimeSpreadsAssetClass from 'phoenix/models/AssetClasses/FuturesTimeSpreadsAssetClass';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { AssetColors } from 'phoenix/util';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useBaseScreenLayoutStore from 'store/BaseScreenLayout';
import { Routes } from 'util/Routes';
import './style.css';

export default function (): ReactElement {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const navigate = useNavigate();
    const { symbol } = useParams<{ symbol: string }>();
    const text = useText((t) => t);
    const fsym = new FuturesSymbol(symbol);
    const { setViewModel, symbol: symbolFromStore } = useTradeTicketViewModel<FuturesTradeTicketViewModel>();
    const featureFlags = useSnexStore((s) => s.featureFlags);
    const spreadsFlag = featureFlags?.byId[FeatureFlags.FuturesSpreads];
    const { loadData, getMetadataBySymbol, loading: metaLoading } = useSecurityMetadataV2();
    const underlyingMeta = getMetadataBySymbol(fsym.baseContract);
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const quote = XS.Quotes.use(symbol);
    const meta = getMetadataBySymbol(symbol);
    const formatter = (value: number) => FuturesTimeSpreadsAssetClass.formatPrice(value, meta);
    const loading = !quote || quote?.loading || quote?.pristine || !meta || metaLoading[symbol];
    const { setBaseLayoutProps } = useBaseScreenLayoutStore();

    // Set Base Layout Props
    useEffect(() => {
        setBaseLayoutProps({
            hideGlance: true,
            noCard: true,
            sidebar: (
                <div className='trade-sidebar futures ticket-links-container'>
                    <TradeTicket />
                    <BigTextLink color={AssetColors.Future} route={Routes.security(fsym.baseContract)}>
                        {text.optionListScreen.tradeFutures}
                    </BigTextLink>
                    <BigTextLink color={AssetColors.Future} route={Routes.futuresSpreads(`${fsym.baseContract}`, fsym.nearMonthContract)}>
                        {text.securityScreen.viewAllSpreads}
                    </BigTextLink>
                </div>
            )
        });
    }, [fsym.baseContract, fsym.nearMonthContract, setBaseLayoutProps, text.optionListScreen.tradeFutures, text.securityScreen.viewAllSpreads]);

    useEffect(() => {
        if (featureFlags?.all?.data && underlyingMeta && (!spreadsFlag?.enabled || !underlyingMeta?.future?.hasTimeSpreads)) {
            navigate(Routes.security(fsym.baseContract), { replace: true });
            return;
        }

        if (!loading && symbolFromStore !== symbol) {
            setViewModel({
                ...getDefaultOrderProps({
                    assetClass: FuturesTimeSpreadsAssetClass,
                    marketTimeSegment,
                    quote
                }),
                symbol
            });
        }
    }, [featureFlags, fsym.baseContract, loading, marketTimeSegment, meta, navigate, quote, setViewModel, spreadsFlag?.enabled, symbol, symbolFromStore, underlyingMeta]);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
        let ticket: string;
        let uTicket: string;
        const run = async () => {
            await loadData(symbol);
            await loadData(fsym.baseContract);

            ticket = await xdispatch(XS.Quotes.start(symbol, LiveDataNamespaces.FuturesTimeSpreadDeeperView));
            uTicket = await xdispatch(XS.Quotes.start(fsym.nearMonthContract, LiveDataNamespaces.FuturesTimeSpreadDeeperView));
        };
        run();

        return () => {
            XS.stop(ticket);
            XS.stop(uTicket);
        };
    }, [dispatch, fsym.baseContract, fsym.nearMonthContract, loadData, symbol, xdispatch]);

    const matrixData: InfoMatrixGridletModel[] = [
        { label: text.optionContractScreen.stats.bid, value: quote?.bid, type: 'normal', formatter },
        { label: text.optionContractScreen.stats.ask, value: quote?.ask, type: 'normal', formatter },
        { label: text.optionContractScreen.stats.open, value: quote?.open, type: 'normal', formatter },
        { label: text.optionContractScreen.stats.high, value: quote?.high, type: 'normal', formatter },
        { label: text.optionContractScreen.stats.low, value: quote?.low, type: 'normal', formatter }
    ];

    return (
        <>
            <div className='deeper-view-main'>
                <FuturesTimeSpreadDeeperViewHeader />
                <SecurityChartSection symbol={symbol} />
                <section className='stats'>
                    <SectionHeader label={text.securityScreen.stats.stats} />
                    <ErrorBoundary>
                        <InfoMatrix data={matrixData} loading={quote?.loading} />
                    </ErrorBoundary>
                </section>
                <OpenOrders securityKnown label={text.optionContractScreen.history} status='Open' symbol={symbol} />
            </div>
        </>
    );
}
