import { ApiData } from '../../../models'
import { SecurityDom } from '../Securities/SecurityDom'
import { SecurityQuote } from '../Securities/SecurityQuote'

export class SecurityQuoteState {
    domBySymbol: {[key: string]: ApiData<SecurityDom>}
    bySymbol: {[key: string]: ApiData<SecurityQuote>}

    constructor () {
        this.bySymbol = {}
        this.domBySymbol = {}
    }
}
