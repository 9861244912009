import { ApiData, ApiOperation } from '../../../models'
import { SnexWeatherVideos } from './SnexWeatherVideos';

export class WeatherState {
    videos: ApiData<SnexWeatherVideos>;

    constructor () {
        this.videos = new ApiData<SnexWeatherVideos>();
    }
}
