export const DndTypes = {
    WatchlistItem: 'WATCHLIST_ITEM',
    Watchlist: 'WATCHLIST'
}

export const DndItems = {
    WatchlistItem: (watchlistId: string, qsi: string, index: number): WatchlistDndItem => ({ watchlistId, qsi, index }),
    Watchlist: (index: number): WatchlistDnd => ({ index })
}

export interface WatchlistDndItem { watchlistId: string, qsi: string, index: number }
export interface WatchlistDnd { index: number }