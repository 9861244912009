import { ApiData, ApiOperation } from '../../../models';
import { Account } from '../Accounts';
import { OnboardingData, OnboardingResult } from './OnboardingData';


/* @deprecated This is for mobile use only. For web use the useFuturesOnboardingStore */
export class FuturesOnboardingState {
    all: ApiData<OnboardingData>;
    result: ApiData<OnboardingResult>;
    
    constructor() {
        this.all = new ApiData<OnboardingData>();
        this.result = new ApiData<OnboardingResult>();
    }
}
