import React, { ReactElement } from 'react';
import { TradeReceiptPage } from '../Shared/TradeReceiptPage';
import { TradeReviewPage } from '../Shared/TradeReviewPage';
import { FundTradeInputPage } from './FundTradeInputPage';
import { ReinvestmentOptionsPage } from './ReinvestmentOptionsPage';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { MutualFundTradeTicketViewModel } from '../Store/TradeTicketViewModel';

export const FundTradeTicket = (): ReactElement => {
    const { state } = useTradeTicketViewModel<MutualFundTradeTicketViewModel>();

    switch (state) {
        case 'reinvest':
            return <ReinvestmentOptionsPage />;
        case 'review':
            return <TradeReviewPage />;
        case 'done':
            return <TradeReceiptPage />;
        case 'input':
        default:
            return <FundTradeInputPage />;
    }
};
