import { ApiData, ApiOperation } from '../../../models'
import { MySubscriptionInfo } from './MySubscriptionInfo'
import { StripePortalUrl } from './StripePortalUrl'
import { SubscriptionCheckoutUrl } from './SubscriptionCheckoutUrl'

export class SubscriptionState {
    mySubscription: ApiData<MySubscriptionInfo>
    stripeCheckoutUrl: ApiData<SubscriptionCheckoutUrl>
    stripePortalUrl: ApiData<StripePortalUrl>
    updatePaymentAccount: ApiOperation
    cancel: ApiOperation

    constructor () {
        this.mySubscription = new ApiData()
        this.stripeCheckoutUrl = new ApiData()
        this.stripePortalUrl = new ApiData()
        this.updatePaymentAccount = new ApiOperation()
        this.cancel = new ApiOperation()
    }
}
