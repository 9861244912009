// @ts-strict-ignore
import { SnexError } from 'phoenix/models/SnexError';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { createClearable as create } from 'phoenix/stores/ZustandStore'
import { SnexAxios } from './AxiosHelpers';
import { Urls } from 'phoenix/constants';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';

export type FundingLimitsStoreData = {
    isLoading: boolean;
    limits?: FundingLimits;
    errors?: SnexError[];
};

export type FundingLimitsStoreMethods = {
    load: (bypassCache?: boolean) => Promise<FundingLimits>;
    clearAll: () => void;
};

export type FundingLimitsStore = FundingLimitsStoreData & FundingLimitsStoreMethods;

export const useFundingLimitsStore = create()<FundingLimitsStore>((set, get) => {
    return {
        isLoading: false,
        limits: null,
        errors: null,

        load: async () => {
            try {
                set({ isLoading: true });
                const response = await SnexAxios.ApiGet<FundingLimits>(Urls.funding.v2.limits()).withMutex('funding-helpers-managmentLimits').run();
                const limits = response;
                set({ limits, isLoading: false });

                return limits;
            } catch (e) {
                ErrorLogger.RecordError(e, 'GetFundingLimits');
                throw e;
            }
        },
        clearAll: () =>
            set({
                isLoading: false,
                limits: null,
                errors: null
            })
    };
});
