import React from 'react';
import { Flex } from '../..';

export const PaddedSection = React.memo((props: { children: any; flex?: boolean; column?: boolean; center?: boolean; style?: React.CSSProperties }) => {
    if (props.flex)
        return (
            <Flex column={props.column} center={props.center} style={{ width: '100%', boxSizing: 'border-box', padding: '0px 15px', flex: 1, ...props.style }}>
                {props.children}
            </Flex>
        );

    return <div style={{ width: '100%', boxSizing: 'border-box', padding: '0px 15px', ...props.style }}>{props.children}</div>;
});
