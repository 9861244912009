import { Button, darken } from '@mui/material';
import { Add, Check, Remove } from '@mui/icons-material';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { Flex } from 'components';
import { ChangeColor } from 'phoenix/util';
import { useColors } from 'hooks/UseColors';
import { Link } from 'react-router-dom';

interface OptionButtonProps {
    alignIcon?: 'left' | 'right';
    children?: any;
    className?: string;
    color?: 'positive' | 'negative' | 'neutral' | 'disabled' | string;
    disabled?: boolean;
    icon?: 'check' | 'plus' | 'minus';
    onClick?: (e: any) => any;
    role?: string;
    selected?: boolean;
    style?: CSSProperties;
    route?: string;
}

export const OptionButton = (props: OptionButtonProps) => {
    let background = '';
    let textColor = '';
    const { greyBackgroundColor, grayText, inputBorderColor, white } = useColors();

    switch (props.color) {
        case 'positive':
            background = ChangeColor(1, !props.selected);
            break;
        case 'negative':
            background = ChangeColor(-1, !props.selected);
            break;
        case 'neutral':
        case 'disabled':
            background = greyBackgroundColor;
            break;
        default:
            if (props?.color) background = darken(props.color, props.selected ? 0.4 : 0);
            break;
    }

    if (props.selected) textColor = white;
    else if (props.color === 'positive' || props.color === 'negative') textColor = ChangeColor(props.color === 'positive' ? 1 : -1, false);
    else textColor = grayText;

    const style: React.CSSProperties = {
        opacity: props.disabled ? '0.85' : '1',
        backgroundColor: props.disabled ? 'none' : background,
        ...(props?.color ? { color: props.disabled ? undefined : textColor } : {}),
        // fontSize: 18,
        padding: '6px 0px',
        boxSizing: 'border-box',
        justifyContent: props.icon ? 'flex-end' : undefined,
        ...props.style
    };

    const Icon = useCallback(
        (iconProps: Partial<OptionButtonProps>) => {
            const border =
                iconProps.alignIcon === 'left'
                    ? { borderRight: `solid 1px ${props.selected ? textColor : inputBorderColor}` }
                    : { borderLeft: `solid 1px ${props.selected ? textColor : inputBorderColor}` };

            return (
                <div style={{ ...border }}>
                    {props.icon === 'check' ? <Check style={{ color: props.disabled || !props?.color ? undefined : textColor, fontSize: 22, margin: '0 5px' }} /> : null}
                    {props.icon === 'plus' ? <Add style={{ color: props.disabled || !props?.color ? undefined : textColor, fontSize: 22, margin: '0 5px' }} /> : null}
                    {props.icon === 'minus' ? <Remove style={{ color: props.disabled || !props?.color ? undefined : textColor, fontSize: 22, margin: '0 5px' }} /> : null}
                </div>
            );
        },
        [props.icon, textColor, props.selected]
    );

    const button = (
        <Button
            disableElevation
            disableRipple
            disabled={props.disabled}
            className={props.className + ` option-button${props.icon ? ' with-icon' : ''}`}
            role={props.role || 'button'}
            style={style}
            variant='contained'
            onClick={props.onClick}
        >
            <Flex center row justify='center' style={{ width: '100%' }}>
                {props.alignIcon === 'left' && <Icon alignIcon={props.alignIcon} />}
                <Flex center style={{ flex: 1 }}>
                    {props.children}
                </Flex>
                {props.alignIcon === 'right' && <Icon alignIcon={props.alignIcon} />}
            </Flex>
        </Button>
    );

    return props.route ? <Link to={props.route}>{button}</Link> : button;
};
