// @ts-strict-ignore
import {
    AccountBalancesState,
    AccountChartState,
    AccountsState,
    AccountSummaryState,
    DocumentsState,
    EarningsCallsState,
    ErrorsState,
    FinancialStatementsState,
    FundingState,
    LoginState,
    MiniplayerState,
    NewsState,
    OptionsState,
    OrdersState,
    PositionsState,
    RealizedPnlState,
    SecuritiesState,
    SecurityAnalysisState,
    SecurityChartState,
    SecurityLogoState,
    TaxlotState,
    TradingState,
    UserState,
    WatchlistsState,
    AlertsState,
    WeatherState
} from './models'
import { AcatsState } from './models/Acats/AcatsState'
import { AdminState } from './models/Admin/AdminState'
import { DeviceState } from './models/Device/DeviceState'
import { SectorSecuritiesState } from './models/Sectors/SectorSecuritiesState'
import { FeatureFlagState } from './models/FeatureFlags/FeatureFlagState'
import { FundsState } from './models/Funds/FundsState'
import { MarketState } from './models/Market/MarketState'
import { MessagesState } from './models/Messages/MessagesState'
import { SecurityQuoteState } from './models/SecurityQuote'
import { SubscriptionState } from './models/Subscription/SubscriptionState'
import { SystemState } from './models/System/SystemState'
import { TransactionsState } from './models/Transactions/TransactionsState'
import { WatchlistChartState } from './models/WatchlistChart/WatchlistChartState'
import { MobileScreenLoadingState } from './models/MobileScreens/MobileScreenLoadingState'
import { FuturesOnboardingState } from './models/FuturesOnboarding/FuturesOnboardingState'
import { AccountApplicationStatusesState } from './models/AccountApplicationStatus/AccountApplicationStatusesState'
import { AccountOpeningsState } from './models/AccountOpenings/AccountOpeningsState'
import { WhitelabelState } from './models/Whitelabels/WhitelabelState'
import { AuthorizationSettingsState } from './models/AuthorizationSettings/AuthorizationSettingsState'
export class GlobalState {
    acats: AcatsState
    accountApplicationStatuses: AccountApplicationStatusesState
    accountBalances: AccountBalancesState
    accountChart: AccountChartState
    accountOpenings: AccountOpeningsState
    accountSummary: AccountSummaryState
    accounts: AccountsState
    admin: AdminState
    alerts: AlertsState
    device: DeviceState
    documents: DocumentsState
    earningsCalls: EarningsCallsState
    errors: ErrorsState
    featureFlags: FeatureFlagState
    financialStatements: FinancialStatementsState
    /** @deprecated use FundingSourcesStore, FundingHistoryStore, FundingLimitsStore and PlaidStore */
    funding: FundingState
    funds: FundsState
    futuresOnboarding: FuturesOnboardingState
    login: LoginState
    logos: SecurityLogoState
    market: MarketState
    messages: MessagesState
    miniplayer: MiniplayerState
    mobileScreenLoading: MobileScreenLoadingState
    news: NewsState
    options: OptionsState
    orders: OrdersState
    positions: PositionsState
    relay: { [key: string]: any }
    sectorSecurities: SectorSecuritiesState
    securities: SecuritiesState
    securityAnalysis: SecurityAnalysisState
    securityChart: SecurityChartState
    securityQuote: SecurityQuoteState
    subscription: SubscriptionState
    system: SystemState
    taxlots: TaxlotState
    trading: TradingState
    transactions: TransactionsState
    user: UserState
    watchlistChart: WatchlistChartState
    watchlists: WatchlistsState
    weather: WeatherState
    whitelabel: WhitelabelState
    authorizationSettings: AuthorizationSettingsState

    constructor () {
        this.acats = new AcatsState()
        this.accountApplicationStatuses = new AccountApplicationStatusesState()
        this.accountBalances = new AccountBalancesState()
        this.accountChart = new AccountChartState()
        this.accountOpenings = new AccountOpeningsState()
        this.accountSummary = new AccountSummaryState()
        this.accounts = new AccountsState()
        this.admin = new AdminState()
        this.alerts = new AlertsState()
        this.device = new DeviceState()
        this.documents = new DocumentsState()
        this.earningsCalls = new EarningsCallsState()
        this.errors = new ErrorsState()
        this.featureFlags = new FeatureFlagState()
        this.financialStatements = new FinancialStatementsState()
        this.funding = new FundingState()
        this.futuresOnboarding = new FuturesOnboardingState()
        this.login = new LoginState()
        this.logos = new SecurityLogoState()
        this.market = new MarketState()
        this.messages = new MessagesState()
        this.miniplayer = new MiniplayerState()
        this.mobileScreenLoading = new MobileScreenLoadingState()
        this.news = new NewsState()
        this.options = new OptionsState()
        this.orders = new OrdersState()
        this.positions = new PositionsState()
        this.relay = {}
        this.sectorSecurities = new SectorSecuritiesState()
        this.securities = new SecuritiesState()
        this.securityAnalysis = new SecurityAnalysisState()
        this.securityChart = new SecurityChartState()
        this.securityQuote = new SecurityQuoteState()
        this.subscription = new SubscriptionState()
        this.taxlots = new TaxlotState()
        this.trading = new TradingState()
        this.transactions = new TransactionsState()
        this.user = new UserState()
        this.watchlistChart = new WatchlistChartState()
        this.watchlists = new WatchlistsState()
        this.weather = new WeatherState()
        this.whitelabel = new WhitelabelState()
        this.authorizationSettings = new AuthorizationSettingsState()
    }
}
