import { Chat } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { Flex } from '..';
import { useText } from 'phoenix/hooks/UseText';
import { SnexButton } from '../SnexButton/SnexButton';
import { useColors } from 'hooks/UseColors';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useOpenChatWindow } from 'hooks/UseChat';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { getSimulatedEnvironment } from 'phoenix/constants/Support';
import { isWhitelabel } from 'phoenix/constants/Whitelabels';

interface ChatWithSupportButtonProps {
    style?: React.CSSProperties;
}

function ChatWithSupportButtonImpl(props: ChatWithSupportButtonProps) {
    const colors = useColors();
    const openChatWindow = useOpenChatWindow();
    const env = getSimulatedEnvironment();

    const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            try {
                ErrorLogger.RecordMessage('attempting to open chat with support.', 'ChatWithSupportButton');
                if (e) e.preventDefault();
                openChatWindow();
            } catch (error) {
                ErrorLogger.RecordError(error as Error, 'ChatWithSupportButton');
            }
        },
        [openChatWindow]
    );

    const text = useText((s) => s.misc.chatWithSupport);

    if (isWhitelabel(env)) return null;

    return (
        <SnexButton eventTag='Support Chat' onClick={handleClick} flavor='secondary-transparent' style={{ color: colors.primaryItemColor }}>
            <Flex row align='center' justify='center'>
                <Chat />
                <span style={{ minWidth: 250, textAlign: 'center', ...props.style }}>{text}</span>
            </Flex>
        </SnexButton>
    );
}

export const ChatWithSupportButton = React.memo(TelemetryProvider(ChatWithSupportButtonImpl, TelemetryCategories.support));
