// @ts-strict-ignore
import { useTextStore } from 'phoenix/hooks/UseText';
import { ApiData } from '../../models';
import { ReduxAction } from '../../models/ReduxAction';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { MyPrefs } from '../models/Users/MyPrefs';
import { PublicUserInfo } from '../models/Users/PublicUserInfo';
import { UserState } from '../models/Users/UserState';

const permitted = GroupNameChecker([Actions.CheckLogin, Actions.Users]);

export const UserReducer = (state: UserState = new UserState(), action: ReduxAction): UserState => {
    if (!permitted(action)) return state;

    const withUpdateUserInfo = (update: (data: ApiData<PublicUserInfo>) => ApiData<PublicUserInfo>): UserState => ({
        ...state,
        byId: {
            ...state.byId,
            [action.subject]: {
                ...state.byId[action.subject],
                info: update(state.byId[action.subject]?.info || new ApiData<PublicUserInfo>())
            }
        }
    });

    switch (action.type) {
        case Actions.CheckLogin.Loading:
        case Actions.Users.GetMyInfo.Loading:
            return {
                ...state,
                myInfo: state.myInfo.startLoading(state.myInfo.data)
            };
        case Actions.CheckLogin.Success:
            return {
                ...state,
                myInfo: state.myInfo.succeeded({
                    ...state.myInfo?.data,
                    ...action.data
                })
            };
        case Actions.Users.GetMyInfo.Success:
            return { ...state, myInfo: state.myInfo.succeeded(action.data) };
        case Actions.Users.GetMyInfo.Failure:
        case Actions.CheckLogin.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error) };
        case Actions.Users.GetMyToken.Loading:
            return { ...state, myToken: state.myToken.startLoading() };
        case Actions.Users.GetMyToken.Success:
            return { ...state, myToken: state.myToken.succeeded(action.data) };
        case Actions.Users.GetMyToken.Failure:
            return { ...state, myToken: state.myToken.failed(action.error) };

        case Actions.Users.GetMyPrefs.Loading:
            return { ...state, myPrefs: state.myPrefs.startLoading() };
        case Actions.Users.GetMyPrefs.Success:
            return { ...state, myPrefs: state.myPrefs.succeeded(action.data) };
        case Actions.Users.GetMyPrefs.Failure:
            return { ...state, myPrefs: state.myPrefs.failed(action.error) };

        case Actions.Users.GetMyTaxInfo.Loading:
            return { ...state, myTaxInfo: state.myTaxInfo.startLoading() };
        case Actions.Users.GetMyTaxInfo.Success:
            return {
                ...state,
                myTaxInfo: state.myTaxInfo.succeeded(action.data)
            };
        case Actions.Users.GetMyTaxInfo.Failure:
            return { ...state, myTaxInfo: state.myTaxInfo.failed(action.error) };

        case Actions.Users.GetMyFeatures.Loading:
            return { ...state, myFeatures: state.myFeatures.startLoading() };
        case Actions.Users.GetMyFeatures.Success:
            return {
                ...state,
                myFeatures: state.myFeatures.succeeded(action.data)
            };
        case Actions.Users.GetMyFeatures.Failure:
            return {
                ...state,
                myFeatures: state.myFeatures.failed(action.error)
            };
        case Actions.Users.UpdateMyPrefs.Loading:
            return {
                ...state,
                myPrefs: state.myPrefs.succeeded((action.subject as MyPrefs[])[0]),
                updatePrefs: state.updatePrefs.succeeded()
            };
        case Actions.Users.UpdateMyPrefs.Failure:
            return {
                ...state,
                updatePrefs: state.updatePrefs.failed(action.error)
            };

        case Actions.Users.UpdateMyTaxInfo.Loading:
            return {
                ...state,
                updateTaxInfo: state.updateTaxInfo.startLoading()
            };
        case Actions.Users.UpdateMyTaxInfo.Success:
            return {
                ...state,
                updateTaxInfo: state.updateTaxInfo.succeeded(),
                myTaxInfo: state.myTaxInfo.succeeded({
                    ...(state.myTaxInfo?.data || {}),
                    ...action.data
                })
            };
        case Actions.Users.UpdateMyTaxInfo.Failure:
            return {
                ...state,
                updateTaxInfo: state.updateTaxInfo.failed(action.error)
            };

        case Actions.Users.GetUserInfo.Loading:
            return withUpdateUserInfo((d) => d.startLoading());
        case Actions.Users.GetUserInfo.Success:
            return withUpdateUserInfo((d) => d.succeeded(action.data));
        case Actions.Users.GetUserInfo.Failure:
            return withUpdateUserInfo((d) => d.failed(action.error));

        case Actions.Users.UpdateMyInfo.Loading:
            return {
                ...state,
                myInfo: state.myInfo.startLoading(state.myInfo.data)
            };
        case Actions.Users.UpdateMyInfo.Success:
            return { ...state, myInfo: state.myInfo.succeeded(action.data) };
        case Actions.Users.UpdateMyInfo.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error) };

        case Actions.Users.UpdateMyImage.Loading:
            return {
                ...state,
                myInfo: state.myInfo.startLoading(state.myInfo.data)
            };
        case Actions.Users.UpdateMyImage.Success:
            return { ...state, myInfo: state.myInfo.succeeded(action.data) };
        case Actions.Users.UpdateMyImage.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error, true) };

        case Actions.Users.UpdateMyOneClickTrading.Success:
            return { ...state, myInfo: state.myInfo.succeeded({ ...state.myInfo?.data, oneClickTrading: action.subject }) };
        case Actions.Users.UpdateMyOneClickTrading.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error, true) };

        case Actions.Users.UpdateAdvancedChartTradeTicket.Success:
            return { ...state, myInfo: state.myInfo.succeeded({ ...state.myInfo?.data, advancedChartTradeTicket: action.subject }) };
        case Actions.Users.UpdateAdvancedChartTradeTicket.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error, true) };

        case Actions.Users.UpdateMyTradingViewTicker.Success:
            return { ...state, myInfo: state.myInfo.succeeded({ ...state.myInfo?.data, showTradingViewTicker: action.subject }) };
        case Actions.Users.UpdateMyTradingViewTicker.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error, true) };

        case Actions.Users.DeleteMyImage.Loading:
            return {
                ...state,
                myInfo: state.myInfo.startLoading(state.myInfo.data)
            };
        case Actions.Users.DeleteMyImage.Success:
            return {
                ...state,
                myInfo: state.myInfo.succeeded({
                    ...state.myInfo.data,
                    image: null
                })
            };
        case Actions.Users.DeleteMyImage.Failure:
            return { ...state, myInfo: state.myInfo.failed(action.error) };

        case Actions.Messages.VerifyPhone.Loading:
            return { ...state, verifyPhone: state.verifyPhone.startLoading() };
        case Actions.Messages.VerifyPhone.Success:
            return { ...state, verifyPhone: state.verifyPhone.succeeded() };
        case Actions.Messages.VerifyPhone.Failure:
            return {
                ...state,
                verifyPhone: state.verifyPhone.failed(action.error)
            };
        case Actions.Users.UpdateMyProgress.Loading:
            return {
                ...state,
                updateProgress: state.updateProgress.startLoading(),
                myInfo: state.myInfo.succeeded({
                    ...state.myInfo.data,
                    hasBeenWelcomed: true
                })
            };
        case Actions.Users.UpdateMyProgress.Success:
            return {
                ...state,
                updateProgress: state.updateProgress.succeeded(),
                myInfo: state.myInfo.succeeded({ ...state.myInfo.data })
            };
        case Actions.Users.UpdateMyProgress.Failure:
            return {
                ...state,
                updateProgress: state.updateProgress.failed(action.error)
            };
        case Actions.Users.UpdateAppReviewStatus.Loading:
            return {
                ...state,
                updateReviewStatus: state.updateReviewStatus.startLoading()
            };
        case Actions.Users.UpdateAppReviewStatus.Success:
            return {
                ...state,
                updateReviewStatus: state.updateReviewStatus.succeeded(),
                myInfo: state.myInfo.succeeded({
                    ...state.myInfo.data,
                    appReviewStatus: action.subject,
                    lastAppReviewRequest: new Date().toISOString()
                })
            };
        case Actions.Users.UpdateAppReviewStatus.Failure:
            return {
                ...state,
                updateReviewStatus: state.updateReviewStatus.failed(action.error)
            };
        case Actions.Users.UpdateMyPhone.Loading:
            return { ...state, updatePhone: state.updatePhone.startLoading() };
        case Actions.Users.UpdateMyPhone.Success:
            return { ...state, updatePhone: state.updatePhone.succeeded() };
        case Actions.Users.UpdateMyPhone.Failure:
            return {
                ...state,
                updatePhone: state.updatePhone.failed(action.error)
            };

        case Actions.Users.SetLanguageAnonymous:
            if (action.data !== null) state.myPrefs = state.myPrefs.succeeded({ preference: { language: { code: action.data, displayName: '' } } });

            return { ...state };

        case Actions.Users.GetHasAcceptedWebTos.Success:
            return { ...state, webTosAcceptance: state.webTosAcceptance.succeeded(action.data) };
        case Actions.Users.GetHasAcceptedWebTos.Failure:
            return { ...state, webTosAcceptance: state.webTosAcceptance.failed(action.data) };

        default:
            return state;
    }
};
