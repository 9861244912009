import React from 'react';
import { Flex } from 'components';
import { Typography } from '@mui/material';
import { useText } from 'phoenix/hooks/UseText';
import { CallSupportButton } from 'components/Support/CallSupportButton';
import { EmailSupportButtonDeniedScreen } from 'components/Support/EmailSupportButton';
import { ChatWithSupportButton } from 'components/Support/ChatWithSuportButton';
import { useColors } from 'hooks/UseColors';
import Logo from 'components/Logo';
import '../SplashScreen/style.css';
import { getFriendlyName, getSimulatedEnvironment } from 'phoenix/constants/Support';
import useLastResortEmail from './UseLastResortEmail';

function DeniedScreen(): JSX.Element {
    const { boxShadowLight } = useColors();
    const userEmail = useLastResortEmail();
    const text = useDeniedText(userEmail);

    return (
        <section className='splash-screen'>
            <div className='image-wrapper'>
                <Logo />
                <Flex column align='center'>
                    <Typography style={{ marginTop: 40, textAlign: 'center' }} variant='h6'>
                        {text.split('\n').map((line, i) => (
                            <p key={i}>{line}</p>
                        ))}
                    </Typography>
                    <Flex column style={{ width: 400, marginTop: 20 }}>
                        <div style={{ borderTop: boxShadowLight, margin: '15px 0' }}></div>
                        <EmailSupportButtonDeniedScreen userEmail={userEmail} />
                        <CallSupportButton />
                        <ChatWithSupportButton />
                    </Flex>
                </Flex>
            </div>
        </section>
    );
}

/**
 * Show a message on the denied screen that includes the user's email and the domain they attempted to access
 * @returns The text to display on the denied screen
 */
function useDeniedText(userEmail: string | undefined): string {
    const loginDeadWithDetail = useText((s) => s.errors.loginDeadWithDetail);

    const simEnvironment = getSimulatedEnvironment();
    const getIntoWhat = getFriendlyName(simEnvironment);

    const userEmailInMessage = userEmail ?? ''; // empty string will cause whitespace in the message to collapse as if it were not there, but it's still grammatically correct

    // We see you're trying to get into {StoneX One Pro,StoneX One,Aiva for StoneX One} etc
    const text = loginDeadWithDetail(getIntoWhat, userEmailInMessage);

    return text;
}

export default DeniedScreen;
