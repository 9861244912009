// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models';
import { GetSingleOrderAction, GetSingleOrderV3Action } from '../actions';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { SnexMessage } from '../models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from '../models/Messages/SnexOrderUpdateMessagePayload';
import { Fill, Order, OrdersState } from '../models/Orders';
import { Page } from '../models/Paging/Page';
import { DispatchIntoSnexStore } from 'phoenix/redux/util/DispatchIntoSnexStore';

const permitted = GroupNameChecker([Actions.Orders, Actions.Trading, Actions.Messages.LiveStream.Update]);
export const OrdersReducer = (state: OrdersState = new OrdersState(), action: ReduxAction): OrdersState => {
    if (!permitted(action)) return state;

    const matchOrder = (a: Partial<Order>, b: Partial<Order>): boolean => {
        const orderIdMatch = a?.orderId && b?.orderId && a?.orderId === b?.orderId;
        // Color palette order updates lack order id
        const orderNumberMatch = a?.orderNumber && b?.orderNumber && a?.orderNumber === b?.orderNumber;
        return !!orderIdMatch || !!orderNumberMatch;
    };

    const doesOrderExist = (order: Order): boolean => {
        if (!order) return false;

        const matchByIdOrNumber = (o: Order) => matchOrder(o, order);
        return !!state.openOrders?.data?.find(matchByIdOrNumber) || !!state.searchResults.data?.find(matchByIdOrNumber);
    };

    const getOrderIndex = (source: ApiData<Order[]>, update: Partial<Order>): number => {
        const matchByIdOrNumber = (o: Order) => matchOrder(o, update);
        return (source?.data || []).findIndex(matchByIdOrNumber);
    };

    const getOrderFromStore = (source: ApiData<Order[]>, update: Partial<Order>): Order => {
        const idx = getOrderIndex(source, update);

        if (idx === -1) {
            return null;
        }

        return source.data[idx];
    };

    const updateOrder = (order: Order, update: Partial<Order>): Order => {
        const isUpdatePartialFill = update?.leavesQuantity && update?.leavesQuantity > 0 && order.leavesQuantity && order.leavesQuantity > update.leavesQuantity;
        const isOrderPartiallyFilled = order.leavesQuantity && order.leavesQuantity !== order.orderQuantity;

        // delete fields we want to exclude from merge of update
        const preparedUpdate = Object.entries(update)
            .filter(([key, value]) => value !== undefined)
            .filter(([key, value]) => {
                if(key === 'limitPrice' || key === 'stopPrice') return true;
                return value !== null
            })
            .filter(([key, value]) => !(key === 'fills' && !(value as Fill[])?.length)) // if the fills array is empty delete it too.
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        const updatedOrder = {
            ...order,
            ...preparedUpdate,
            ...(isUpdatePartialFill ? { orderStatus: 'Open' } : {}) // partial fills come back from color palette with a 'Filled' status, change to 'Open' but only if the update does not complete the fills.
        };

        // Switch from limit to stop price if update changes order type
        // Update is limit order
        if (update.limitPrice && !update.stopPrice && order.stopPrice && !order.limitPrice) {
            updatedOrder.limitPrice = update.limitPrice;
            delete updatedOrder.stopPrice;
        }
        // Update is stop order
        if (update.stopPrice && !update.limitPrice && order.limitPrice && !order.stopPrice) {
            updatedOrder.stopPrice = update.stopPrice;
            delete updatedOrder.limitPrice;
        }

        // we don't get fills from color pallete but we do get last fill info
        if ((isUpdatePartialFill || isOrderPartiallyFilled) && update?.lastFilledQuantity && order?.fills && updatedOrder?.fills?.length === order?.fills?.length) {
            const newFill: Fill = {
                symbol: order?.symbol,
                quantity: update?.lastFilledQuantity,
                price: update?.filledPrice,
                sequenceNumber: order?.fills?.length + 1,
                timestamp: update?.completedDate
            };

            updatedOrder.fills.push(newFill);
            const pricesAsCentsAvg = Math.round(updatedOrder.fills.map((f) => f.price * 100).reduce((acc, n) => acc + n) / updatedOrder.fills.length);
            updatedOrder.averageTradePriceAmount = pricesAsCentsAvg / 100;
        }

        // remove duplicate fills
        const uniqueFills: Fill[] = updatedOrder?.fills?.map((fill) => updatedOrder.fills.find((f) => f.timestamp === fill.timestamp)) ?? [];
        return { ...updatedOrder, fills: uniqueFills };
    };

    const updateApiOrders = (source: ApiData<Order[]>, update: Partial<Order>): Order[] => {
        const idx = getOrderIndex(source, update);
        const updatedOrders = source?.data?.slice(0) || [];

        if (idx === -1) {
            return updatedOrders;
        }

        updatedOrders[idx] = updateOrder(source.data[idx], update);

        return updatedOrders;
    };

    const isMessageRelevant = (payload: SnexMessage): boolean => {
        const updatedOrder = payload?.data as SnexOrderUpdateMessagePayload & Order;
        const isNotOrderUpdate = !payload?.payloadType?.includes('OrderUpdate') && updatedOrder?.type !== 'OrderUpdate';

        return !isNotOrderUpdate;
    };

    const withNewOrder = (order: Order, includeSearchResults?: boolean): OrdersState => {
        return {
            ...state,
            openOrders: state.openOrders?.succeeded([order, ...state.openOrders.data]) ?? state.openOrders,
            searchResults: includeSearchResults ? state.searchResults?.succeeded([order, ...state.searchResults.data]) : state.searchResults
        };
    };

    const withRemovedOrder = (order: Order): OrdersState => {
        const spliceOrderFromList = (source: ApiData<Order[]>): Order[] => {
            const idx = getOrderIndex(source, order);
            if (idx === -1) return source?.data;
            const updatedOrders = [...(source?.data || [])];
            updatedOrders.splice(idx, 1);
            return updatedOrders;
        };
        return {
            ...state,
            openOrders: state.openOrders.succeeded(spliceOrderFromList(state.openOrders) || []),
            searchResults: state.searchResults.succeeded(spliceOrderFromList(state.searchResults))
        };
    };

    const withUpdateOrder = (update: Partial<Order>, action?: ReduxAction, orderNumber?: string): OrdersState => {
        if (!update) return state;

        const preparedUpdate = {
            ...update,
            orderNumber: orderNumber || update.orderNumber,
            symbol: action?.data?.subjectSymbol || update?.symbol,
            canCancel: update?.isCancelable || update?.canCancel
        };

        return {
            ...state,
            openOrders: state.openOrders.succeeded(updateApiOrders(state.openOrders, preparedUpdate) || []),
            searchResults: state.searchResults.succeeded(updateApiOrders(state.searchResults, preparedUpdate))
        };
    };

    switch (action.type) {
        case Actions.Orders.GetOpenOrders.Loading:
            return { ...state, openOrders: state.openOrders.startLoading(state.openOrders.data) };
        case Actions.Orders.GetOpenOrders.Success:
            return { ...state, openOrders: state.openOrders.succeeded(action.data) };
        case Actions.Orders.GetOpenOrders.Failure:
            return { ...state, openOrders: state.openOrders.failed(action.error) };

        case Actions.Orders.GetSingleOrder.Success:
            return withUpdateOrder(action.data, action);

        case Actions.Orders.GetSingleOrderV3.Success: {
            return withUpdateOrder(action.data, action, action.subject);
        }

        case Actions.Orders.SearchV2.Loading:
        case Actions.Orders.SearchV3.Loading:
            return {
                ...state,
                searchResults: state.searchResults.startLoading(action.passthrough.append ? state.searchResults.data : []),
                nextOffsets: state.nextOffsets.startLoading(state.nextOffsets.data)
            };
        case Actions.Orders.SearchV2.Success:
        case Actions.Orders.SearchV3.Success: {
            const previousCount = state.searchResults?.data?.length;
            const newResponse = action.data as Page<Order>;
            const newOrderData = action.passthrough.append ? [...(state.searchResults.data || []), ...newResponse?.items] : newResponse?.items;

            return {
                ...state,
                noMoreResults: previousCount === newOrderData.length,
                searchResults: state.searchResults.succeeded(newOrderData),
                nextOffsets: state.nextOffsets.succeeded(newResponse.nextOffsets)
            };
        }
        case Actions.Orders.SearchV2.Failure:
        case Actions.Orders.SearchV3.Failure:
            return {
                ...state,
                noMoreResults: true,
                searchResults: state.searchResults.failed(action.error),
                nextOffsets: state.nextOffsets.failed(action.error)
            };

        case Actions.Trading.Submit.Success: {
            const newOrder = action.data as Order;
            return withNewOrder(newOrder);
        }

        case Actions.Trading.RemoveOrder:
            return withRemovedOrder(action.subject);

        case Actions.Trading.Cancel.Loading: {
            const orderId = action.subject;

            const update = { orderId, canCancel: false, orderStatus: 'Pending' };
            const result = {
                ...state,
                openOrders: state.openOrders?.succeeded(updateApiOrders(state.openOrders, update)),
                searchResults: state.openOrders?.succeeded(updateApiOrders(state.searchResults, update))
            };
            return result;
        }

        case Actions.Messages.LiveStream.Update: {
            const payload = action.data as SnexMessage;

            if (!isMessageRelevant(payload)) return state;

            const updatedOrder = payload?.data as SnexOrderUpdateMessagePayload & Order;
            const fromColorPalette = !updatedOrder.orderId;

            if (fromColorPalette) {
                const order = getOrderFromStore(state?.searchResults, updatedOrder) || getOrderFromStore(state.openOrders, updatedOrder);

                const isUpdatePartialFill =
                    updatedOrder?.leavesQuantity && updatedOrder?.leavesQuantity > 0 && order?.leavesQuantity && order?.leavesQuantity > updatedOrder.leavesQuantity;
                const isOrderPartiallyFilled = order?.leavesQuantity && order?.leavesQuantity !== order?.orderQuantity;

                if (isUpdatePartialFill || isOrderPartiallyFilled) {
                    // Use partial fill data from color pallete to immediately update the fills, but we don't want to fetch from torch since the fills take considerable time to register with torch
                    return withUpdateOrder(updatedOrder, action);
                }

                // if a update is from color palette, fetch from api to get missing/consistent data.
                // for fills we don't want to fetch the data from torch until it knows it has been filled, so we delay the fetch.
                const delay = updatedOrder.orderStatus.toLowerCase() === 'filled' ? 500 : 0;

                setTimeout(() => {
                    DispatchIntoSnexStore(GetSingleOrderV3Action(updatedOrder.orderNumber, 'OrderNumber'));
                }, delay);

                if (updatedOrder.orderStatus.toLowerCase() === 'open') return withNewOrder({ ...updatedOrder, orderStatus: 'Pending' }, true); // show color palette opens as pending, so user gets immediate feedback their order is in flight

                return state; // For most cases we don't want to don't show the data from color palette, we only use it to trigger fetch of torch data.
            }

            if (!doesOrderExist(updatedOrder)) {
                return withNewOrder(updatedOrder, true);
            }

            return withUpdateOrder(updatedOrder, action);
        }

        default:
            return state;
    }
};
