// @ts-strict-ignore
import { Urls } from '../../../constants'
import {
    EquityOnboEmploymentInfoModel,
    EquityOnboIndividualInfoModel,
    EquityOnboLocationInfoModel,
    EquityOnboNetworthModel,
    EquityOnboProfileSettingsModel,
    EquityOnboSignupModel,
    EquityOnboSourceOfIncomeModel,
    EquityOnboStoneXAffilCustomerTypesModel,
    EquityOnboStoneXAffilModel,
    EquityOnboTrustedContactInfoModel,
    EquityOnboIndustryAffiliationModel,
    EquityOnboPublicCompanyAffiliationModel,
    EquityOnboTermsAndAgreementModel,
    EquityUserExistsModel,
    EquityOnboGetTermsAndAgreementModel,
    EquityOnboVerificationModel,
    EquityOnboVerificationResult,
    EquityOnboAccountFeatureModel,
    EquityUserExistsProxyModel,
    EquityTradingExperienceModal,
    EquityOnboVerificationModelV2,
    EquityRegisterUserResult,
    EquityResendVerificationResult
} from './EquitiesOnboardingModels'
import { EquityOnboProfileResponse, EquityUserInfoResponse } from './EquitiesOnboardingResponse'
import { OnboardingPost, OnboardingPut, OnboardingGet, ActionResult, OnboardingDelete } from './OnboRequestHelpers'

const equityUrls = Urls.equitiesOnboarding

type Result<TResult> = Promise<ActionResult<TResult>>

export const EquitiesOnboardingActions = {
    userExists: async (email: string): Result<EquityUserExistsModel> => OnboardingGet(equityUrls.userExists(email), 'vulcan'),
    userExistsProxy: async (email: string): Result<EquityUserExistsProxyModel> => OnboardingGet(equityUrls.userExistsByProxy(email), 'snex'),
    signup: async (payload: EquityOnboSignupModel): Result<boolean> => OnboardingPost(equityUrls.selfSignup(), payload, 'vulcan'),
    resendVerificationCode: async (email: string): Result<boolean> => OnboardingGet(equityUrls.resendVerificationCode(email), 'vulcan'),
    verifySignupCode: async (payload: EquityOnboVerificationModel): Result<EquityOnboVerificationResult> => OnboardingPost(equityUrls.verify(), payload, 'vulcan'),
    hasProfile: async (): Result<boolean> => OnboardingGet(equityUrls.hasProfile(), 'vulcan'),
    userInfo: async (): Result<EquityUserInfoResponse> => OnboardingGet(equityUrls.userInfo(), 'vulcan'),
    profile: async (profileId: string): Result<EquityOnboProfileResponse> => OnboardingGet(equityUrls.profile(profileId), 'vulcan'),
    createProfile: async (): Result<EquityOnboProfileResponse> => OnboardingPost(equityUrls.createProfile(), null, 'vulcan'),
    profileSettings: async (profileId: string, payload: EquityOnboProfileSettingsModel): Result<any> => OnboardingPut(equityUrls.profileSettings(profileId), payload, 'vulcan'),

    tradingExperience: async (profileId: string, payload: EquityTradingExperienceModal): Result<any> => OnboardingPut(equityUrls.tradingExperience(profileId), payload, 'vulcan'),
    investmentObjective: async (accountOpeningId: string, objective: number): Result<any> => OnboardingPost(equityUrls.investmentObjective(accountOpeningId, objective), {}, 'vulcan'),
    optionsTradingLevel: async (profileId: string, level: number) => OnboardingPost(equityUrls.optionsTradingLevel(profileId, level), {}, 'vulcan'),

    individualInfo: async (profileId: string, payload: EquityOnboIndividualInfoModel): Result<any> => OnboardingPut(equityUrls.individualInfo(profileId), payload, 'vulcan'),
    accountType: async (profileId: string, accountType: number): Result<any> => OnboardingPost(equityUrls.accountType(profileId, accountType), null, 'vulcan'),
    location: async (payload: EquityOnboLocationInfoModel): Result<any> => OnboardingPut(equityUrls.locationInfo(), payload, 'vulcan'),
    deleteLocation: async (locationId?: any) => OnboardingDelete(equityUrls.locationInfo(locationId), 'vulcan'),
    networth: async (profileId: string, payload: EquityOnboNetworthModel): Result<any> => OnboardingPut(equityUrls.networth(profileId), payload, 'vulcan'),
    sourceOfIncome: async (profileId: string, payload: EquityOnboSourceOfIncomeModel): Result<any> => OnboardingPut(equityUrls.sourceOfIncome(profileId), payload, 'vulcan'),
    employmentInfo: async (profileId: string, payload: EquityOnboEmploymentInfoModel): Result<any> => OnboardingPut(equityUrls.employmentInfo(profileId), payload, 'vulcan'),
    trustedContactInfo: async (payload: EquityOnboTrustedContactInfoModel) => OnboardingPut(equityUrls.trustedContact(), payload, 'vulcan'),
    deleteTrustedContactInfo: async (trustedContactId: string): Result<any> => OnboardingDelete(equityUrls.trustedContact(trustedContactId), 'vulcan'),
    stonexAffiliation: async (profileId: string, payload: EquityOnboStoneXAffilModel): Result<any> => OnboardingPut(equityUrls.stoneXAffiliation(profileId), payload, 'vulcan'),
    stonexAffiliationTypes: async (profileId: string, payload: EquityOnboStoneXAffilCustomerTypesModel): Result<any> => OnboardingPut(equityUrls.stoneXAffiliationCustomerTypes(profileId), payload, 'vulcan'),
    industryAffilliation: async (profileId: string, payload: EquityOnboIndustryAffiliationModel): Result<any> => OnboardingPut(equityUrls.industryAffiliation(profileId), payload, 'vulcan'),
    publicCompanyAffiliation: async (profileId: string, payload: EquityOnboPublicCompanyAffiliationModel) => OnboardingPut(equityUrls.publicCompanyAffiliation(profileId), payload, 'vulcan'),
    getTermsAndAgreementMetadata: async (documentName): Result<EquityOnboGetTermsAndAgreementModel> => OnboardingGet(equityUrls.getTermsAndAgreement(documentName), 'vulcan'),
    putAccountFeature: async (accountOpeningId: string, payload: EquityOnboAccountFeatureModel) => OnboardingPut(equityUrls.accountFeature(accountOpeningId), payload, 'vulcan'),
    termsAndAgreements: async (payload: EquityOnboTermsAndAgreementModel) => OnboardingPut(equityUrls.termsAndAgreements(), payload, 'vulcan'),
    submitOnboApplication: async (accountOpeningId: string) => OnboardingPost(equityUrls.submitOnboApplication(accountOpeningId), null, 'vulcan')
}

const registrationUrls = Urls.registration
export const EquitiesOnboardingActionsV2 = {
    userExists: async (email: string): Result<EquityUserExistsModel> => OnboardingGet(registrationUrls.userExists(email)),
    signup: async (payload: EquityOnboSignupModel): Result<EquityRegisterUserResult> => OnboardingPost(registrationUrls.selfSignup(), payload),
    verifySignupCodeAndComplete: async (payload: EquityOnboVerificationModelV2): Result<EquityOnboVerificationResult> => OnboardingPost(registrationUrls.verifyCodeAndComplete(), payload),
    resendVerificationCode: async (email: string): Result<EquityResendVerificationResult> => OnboardingGet(registrationUrls.resendVerificationCode(email)),
}
