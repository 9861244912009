import { ApiOperation } from '../../../models'

export class MobileScreenLoadingState {
    appStartup: ApiOperation
    landingScreen: ApiOperation
    summaryScreen: ApiOperation
    equityDeepAnalysis: ApiOperation
    watchlists: ApiOperation
    watchlistDetails: ApiOperation
    securityScreen: ApiOperation
    browseScreen: ApiOperation
    profileScreen: ApiOperation
    sectorScreen: ApiOperation

    constructor () {
        this.appStartup = new ApiOperation()
        this.landingScreen = new ApiOperation()
        this.summaryScreen = new ApiOperation()
        this.equityDeepAnalysis = new ApiOperation()
        this.watchlists = new ApiOperation()
        this.watchlistDetails = new ApiOperation()
        this.securityScreen = new ApiOperation()
        this.browseScreen = new ApiOperation()
        this.profileScreen = new ApiOperation()
        this.sectorScreen = new ApiOperation()
    }
}
