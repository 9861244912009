import { FormControlLabel, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { MouseEvent, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { AnchoredMenu } from '../AnchoredMenu';
import { Flex } from 'components/Flex';
import './index.scss';

export const InputSplit = React.memo(
    (
        props: PropsWithChildren<{
            className?: string;
            label: string;
            labelColor?: string;
            onOptionSelect?: (value: string) => void;
            options?: { label: string; value: string }[];
            style?: React.CSSProperties;
            subLabel?: string;
            disabled?: boolean;
        }>
    ) => {
        const [menuEl, setMenuEl] = useState<HTMLButtonElement | null>(null);

        const LabelComponent = useCallback(
            () => (
                <>
                    <Flex align='center'>
                        <Typography style={{ ...props.style, '--color': props.labelColor } as React.CSSProperties} variant='h6'>
                            {props.label}
                        </Typography>
                        {props.options ? (
                            <IconButton style={{ marginLeft: 10 }} onClick={(e: MouseEvent<HTMLButtonElement>) => setMenuEl(e?.target as HTMLButtonElement)} size='large'>
                                <Edit fontSize='small' />
                            </IconButton>
                        ) : null}
                    </Flex>
                    {props.subLabel && <Typography color='textSecondary'>{props.subLabel}</Typography>}
                </>
            ),
            [props.label, props.labelColor, props.options, props.style, props.subLabel]
        );

        return (
            <>
                <FormControlLabel
                    className={`input-split${props.className ? ` ${props.className}` : ''}`}
                    control={props.children as ReactElement}
                    label={<LabelComponent />}
                    labelPlacement='start'
                    disabled={props.disabled}
                />
                {props.options && (
                    <AnchoredMenu
                        anchorEl={menuEl}
                        handleClose={() => setMenuEl(null)}
                        items={props.options.map((o) => ({ label: o.label, onClick: () => props.onOptionSelect && props.onOptionSelect(o.value) }))}
                    />
                )}
            </>
        );
    }
);
