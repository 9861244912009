import { ReduxAction } from '../../../models'
import { GlobalState } from '../../GlobalState'
import { asData } from '../ReducerUpdateHelpers'
import { ProfileScreenBundle } from '../../models/MobileScreens/ProfileScreenBundle'

export const ProfileScreenReducer = (state: GlobalState, action: ReduxAction): GlobalState => {
    const bundle = action.data as ProfileScreenBundle
    state.user.myTaxInfo = asData(bundle.taxInfo)
    state.accountSummary.buyingPower = asData({...bundle.buyingPower, optionsBuyingPower: bundle.buyingPower.cashAndEquivalents})
    state.accountSummary.aggregate = asData(bundle.summary)
    state.mobileScreenLoading.profileScreen = state.mobileScreenLoading.profileScreen.succeeded()

    return state
}
