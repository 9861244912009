let chaosProbability = 0

export const SetChaosCoefficient = (
    requestFailureProbability: number
) => {
    if (requestFailureProbability > 0) alert(`Notice: Chaos has been enabled, requests will fail at random with a fixed probability of ${requestFailureProbability * 100}%`)
    chaosProbability = requestFailureProbability
}

export const ChaosIsHappening = () => Math.random() <= chaosProbability
