import { ResolveEnvironment } from 'phoenix/constants';
import React from 'react';
import './style.scss';

const proLogo =  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 176.8" >
<style>{'.st0{fill: var(--generalTextColor);}'}</style>
<g>
<path className="st0" d="M405.5,88.4c0-22.5,12.9-36.4,32.5-36.4c19.5,0,32.4,13.7,32.4,36.5c0,22.6-13,36.5-32.5,36.5
   S405.5,110.9,405.5,88.4z M465,88.5c0-20.1-10.5-32.2-27.1-32.2c-16.7,0-27.1,12.1-27.1,32.2s10.6,32.2,27.1,32.2
   C454.5,120.7,465,108.7,465,88.5z"/>
<path className="st0" d="M483.6,123c0,0.6-0.3,0.8-0.8,0.8h-3.5c-0.5,0-0.8-0.2-0.8-0.8l0.2-21.9l-0.2-28.5c0-0.6,0.3-0.8,0.8-0.8h3.4
   c0.5,0,0.8,0.2,0.8,0.8l-0.2,10c2.9-5.9,8.7-12,17.7-12c10.5,0,17,6.7,17,21v9.9L518,123c0,0.6-0.3,0.8-0.8,0.8h-3.5
   c-0.5,0-0.8-0.2-0.8-0.8l0.2-21.5v-9.4c0-12.7-5.3-17.5-13.1-17.5c-7.8,0-13,5.9-16.5,15.4v11.5L483.6,123z"/>
<path className="st0" d="M565.2,106.1c0.1-0.5,0.3-0.7,0.8-0.7h3.2c0.5,0,0.9,0.3,0.8,0.9c-1.9,11.5-9,18.5-21.4,18.5
   c-15.4,0-23.4-10.8-23.4-26.9c0-16,8.6-27.2,22.8-27.2c13.7,0,22,9.5,22.3,26c0,0.5-0.3,0.8-0.8,0.8h-39.3V98
   c0,14.4,6.2,23.1,18.5,23.1C558.2,121.1,563.5,115.4,565.2,106.1z M530.4,93.7H565c-0.1-10.7-6-19.2-17-19.2
   C537.6,74.5,531.5,82,530.4,93.7z"/>
<path className="st0" d="M595.2,53.1h20.7c14.4,0,23.9,6.9,23.9,20.5c0,14.5-9.8,21-23.6,21h-16.6l0.2,28.4c0,0.6-0.3,0.8-0.8,0.8h-3.8
   c-0.5,0-0.8-0.2-0.8-0.8l0.3-36.2l-0.3-32.9C594.4,53.3,594.7,53.1,595.2,53.1z M599.5,90.6h16.9c11.3,0,18.2-4.5,18.2-17
   c0-11.1-6.8-16.4-18.6-16.4h-16.2L599.5,87V90.6z"/>
<path className="st0" d="M651,123c0,0.6-0.3,0.8-0.8,0.8h-3.5c-0.5,0-0.8-0.2-0.8-0.8l0.3-26.1l-0.3-24.3c0-0.6,0.3-0.8,0.8-0.8h3.5
   c0.5,0,0.8,0.2,0.8,0.8l-0.3,9.7c3.4-7.1,10-11.1,17.1-11.1c1.3,0,2.6,0.1,3.6,0.4c0.5,0.1,0.7,0.5,0.7,0.9V75
   c0,0.5-0.3,0.8-0.9,0.7c-1-0.3-2.1-0.4-3.6-0.4c-7.5,0-14.8,4.8-16.8,16L651,123z"/>
<path className="st0" d="M670.9,97.9c0-16.2,8.3-27.1,23.2-27.1c15,0,23.2,10.8,23.2,27.1c0,16.2-8.2,27-23.2,27
   C679.2,124.8,670.9,114.1,670.9,97.9z M712.2,97.9c0-14.1-6.1-23.3-18.1-23.3s-18.1,9.2-18.1,23.3c0,14.2,6.1,23.2,18.1,23.2
   C706.3,121.1,712.2,112,712.2,97.9z"/>
</g>
<g>
<path className="st0" d="M67.6,125c-8.9,0-14.8-1.5-19.2-3.4l-2.5-15.2h1.9l1.9,3.6c4.5,8.6,10,12.4,18.8,12.4c10.1,0,15.8-5,15.8-13.2
   c0-7.3-4.2-11-16.2-15.2c-13.6-4.7-20.4-9.9-20.4-21.6c0-11.9,10.7-20.4,24-20.4c8.3,0,13.1,1.1,17.7,3l2.5,15.8h-2l-1.8-3.7
   c-4.1-8.6-9.3-12.5-17.2-12.5c-8.3,0-13.2,5-13.2,12.1c0,7.4,4,10.7,15.4,14.6c15,5,21.3,10.7,21.3,22.3
   C94.4,116.4,82.2,125,67.6,125"/>
<path className="st0" d="M130.4,118.9c-4.2,3.6-8.5,6-13.9,6c-8,0-12.2-3.6-12.2-12.2V79.5h-7.4v-1.9l7.9-3l7.8-15.6h3.2v16h13.4v4.5
   h-13.4v33c0,4.9,2.4,7.3,6.3,7.3c2.7,0,4.5-0.7,7-2.5L130.4,118.9z"/>
<path className="st0" d="M158.5,125.2c-14.5,0-25.2-9.2-25.2-26.3c0-14.5,10.4-25.6,25.3-25.6c14.3,0,25.2,9.2,25.2,26.3
   C183.8,114.1,173.3,125.2,158.5,125.2 M157.1,75.9c-7.1,0-11.1,5.4-11.1,20.1c0,16.1,5.2,26.6,13.8,26.6c7.2,0,11.1-5.4,11.1-20.1
   C171,86.4,165.9,75.9,157.1,75.9"/>
<path className="st0" d="M245.3,124.2c-2.3,0-8.2-0.1-12.7-0.1c-4.4,0-10.3,0.1-12.8,0.1v-1.6l1.7-0.3c4.5-0.8,5.3-2.2,5.3-11.3V91.3
   c0-7.6-3.3-11.3-9.2-11.3c-4.1,0-7.5,1.6-10.5,3.8V111c0,9.1,0.7,10.5,5.3,11.3l1.7,0.3v1.6c-2.5,0-8.3-0.1-12.8-0.1
   c-4.5,0-10.4,0.1-12.8,0.1v-1.6l2-0.3c4.2-0.6,5-2.2,5-12.2V88.4c0-5.4-0.9-6.9-4.6-8.6l-2.5-1.2v-1.1l17.4-3.8l1.4,0.9v7
   c5.5-4.7,10.6-7.8,17.1-7.8c9.3,0,14.2,5,14.2,15.5v21c0,10.2,0.8,11.4,5.1,12.2l1.8,0.3V124.2z"/>
<path className="st0" d="M259.9,94.7v0.7c0.3,14.2,8,23,18.4,23c5.7,0,9.7-2.1,13.9-6.3l1.4,1c-5.4,7.5-12.4,12.2-21.4,12.2
   c-13.2,0-23.1-9-23.1-24.5c0-16.7,12.6-27.3,24.7-27.3c13.4,0,19.9,7.5,20.2,21.3H259.9z M281.7,91.8c-0.3-10.5-4.1-15.8-10.1-15.8
   c-6.8,0-11.1,5.8-11.7,15.8H281.7z"/>
<path className="st0" d="M367,124.2c-1.8,0-10.8-0.2-14.3-0.2c-3.7,0-14.9,0.2-16.8,0.2v-1.4l3.2-0.5c3.1-0.5,4.4-1.3,4.4-3.2
   c0-1.6-1-3.6-3.2-7L329.1,95L318,110.3c-2.6,3.6-3.8,6.3-3.8,8.3c0,1.9,1.2,3.2,4.3,3.7l3.3,0.5v1.4c-1.8,0-9.8-0.2-13.4-0.2
   c-3.6,0-9.3,0.2-11.2,0.2v-1.4l1.7-0.3c4.5-0.8,8.7-4.6,12.1-8.9l16.2-21.2l-16.1-23.6c-3.7-5.5-6.3-8-10.3-8.7l-2.1-0.4v-1.4
   c1.8,0,10.8,0.2,14.3,0.2c3.7,0,14.7-0.2,16.6-0.2v1.4l-3.2,0.5c-2.7,0.4-4.2,1.3-4.2,3.2c0,1.6,1,3.6,3.2,7l9.6,14.8l9.4-13.2
   c2.5-3.6,3.8-6.3,3.8-8.2c0-1.9-1.2-3.2-4.3-3.7l-3.3-0.5v-1.4c1.8,0,9.8,0.2,13.4,0.2c3.6,0,9.3-0.2,11.2-0.2v1.4l-1.7,0.3
   c-4.5,0.8-8.6,4.2-12.1,8.9l-14.5,19l17.6,25.8c3.7,5.5,6.3,8,10.3,8.7l2.1,0.4V124.2z"/>
<g>
   <path className="st0" d="M380.1,61.5c-0.3-0.7-0.7-1.2-1.1-1.7c-0.5-0.5-1.1-0.9-1.7-1.1c-0.7-0.3-1.4-0.4-2.1-0.4
       c-0.8,0-1.5,0.1-2.1,0.4c-0.7,0.3-1.2,0.7-1.7,1.1c-0.5,0.5-0.9,1.1-1.1,1.7c-0.3,0.7-0.4,1.4-0.4,2.1c0,0.8,0.1,1.5,0.4,2.1
       c0.3,0.7,0.7,1.2,1.1,1.7c0.5,0.5,1.1,0.9,1.7,1.2c0.7,0.3,1.4,0.4,2.1,0.4c0.8,0,1.5-0.1,2.1-0.4c0.7-0.3,1.2-0.7,1.7-1.2
       c0.5-0.5,0.9-1.1,1.1-1.7c0.3-0.7,0.4-1.4,0.4-2.1C380.5,62.8,380.3,62.1,380.1,61.5z M379.3,65.4c-0.2,0.6-0.6,1.1-1,1.5
       c-0.4,0.4-0.9,0.8-1.5,1c-0.6,0.2-1.2,0.4-1.8,0.4c-0.7,0-1.3-0.1-1.8-0.4c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.4-0.7-0.9-1-1.5
       c-0.2-0.6-0.3-1.2-0.3-1.8c0-0.7,0.1-1.3,0.3-1.8c0.2-0.6,0.6-1.1,1-1.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.2,1.2-0.4,1.8-0.4
       c0.7,0,1.3,0.1,1.8,0.4c0.6,0.2,1.1,0.6,1.5,1c0.4,0.4,0.7,0.9,1,1.5c0.2,0.6,0.3,1.2,0.3,1.8C379.7,64.3,379.6,64.9,379.3,65.4z"
       />
   <path className="st0" d="M376.5,64.3c-0.1-0.1-0.3-0.1-0.4-0.1c0.4-0.1,0.8-0.2,1-0.5c0.4-0.4,0.6-0.8,0.6-1.4c0-0.6-0.2-1.1-0.6-1.4
       c-0.4-0.3-1-0.5-1.7-0.5h-2.8v6.4h1.4v-2.5h0.7c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3l1.1,2.1h1.5l-1.1-1.9
       C376.9,64.6,376.7,64.5,376.5,64.3z M375.3,63.3H374v-1.8h1.3c0.4,0,0.7,0.1,0.8,0.2c0.2,0.1,0.3,0.4,0.3,0.7
       c0,0.3-0.1,0.6-0.3,0.7C376,63.2,375.7,63.3,375.3,63.3z"/>
</g>
</g>
</svg>

const defaultLogo = <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 615.23 176.82'>
            <style>{'.cls-1{fill: var(--generalTextColor)}'}</style>
            <g>
                <path
                    className='cls-1'
                    d='M67.68,125.02c-8.9,0-14.8-1.5-19.2-3.4l-2.6-15.2h1.9l1.9,3.6c4.5,8.6,10,12.4,18.8,12.4,10.1,0,15.8-5,15.8-13.2,0-7.3-4.2-11-16.2-15.2-13.6-4.7-20.4-9.9-20.4-21.6s10.7-20.4,24-20.4c8.3,0,13.1,1.1,17.7,3l2.5,15.8h-2l-1.8-3.7c-4.1-8.6-9.3-12.5-17.2-12.5-8.3,0-13.2,5-13.2,12.1s4,10.7,15.4,14.6c15,5,21.3,10.7,21.3,22.3,.1,12.7-12.2,21.4-26.7,21.4Z'
                />
                <path
                    className='cls-1'
                    d='M130.48,118.82c-4.2,3.6-8.5,6-13.9,6-8,0-12.2-3.6-12.2-12.2v-33.2h-7.4v-1.9l7.9-3,7.8-15.6h3.2v16h13.4v4.5h-13.4v33c0,4.9,2.4,7.3,6.3,7.3,2.7,0,4.5-.7,7-2.5l1.3,1.6Z'
                />
                <path
                    className='cls-1'
                    d='M158.58,125.22c-14.5,0-25.2-9.2-25.2-26.3,0-14.5,10.4-25.6,25.3-25.6s25.2,9.2,25.2,26.3c-.1,14.5-10.6,25.6-25.3,25.6Zm-1.4-49.3c-7.1,0-11.1,5.4-11.1,20.1,0,16.1,5.2,26.6,13.8,26.6,7.2,0,11.1-5.4,11.1-20.1,0-16.1-5.1-26.6-13.8-26.6Z'
                />
                <path
                    className='cls-1'
                    d='M245.38,124.12c-2.3,0-8.2-.1-12.7-.1s-10.3,.1-12.8,.1v-1.6l1.7-.3c4.5-.8,5.3-2.2,5.3-11.3v-19.6c0-7.6-3.3-11.3-9.2-11.3-4.1,0-7.5,1.6-10.5,3.8v27.1c0,9.2,.7,10.5,5.3,11.3l1.7,.3v1.6c-2.5,0-8.3-.1-12.8-.1s-10.4,.1-12.8,.1v-1.6l2-.3c4.2-.6,5-2.2,5-12.2v-21.6c0-5.4-.9-6.9-4.6-8.6l-2.5-1.2v-1.1l17.4-3.8,1.4,.9v7c5.5-4.7,10.6-7.8,17.1-7.8,9.3,0,14.2,5,14.2,15.5v21c0,10.2,.8,11.4,5.1,12.2l1.8,.3v1.3h-.1Z'
                />
                <path
                    className='cls-1'
                    d='M259.88,94.62v.7c.3,14.2,8,23,18.4,23,5.7,0,9.7-2.1,13.9-6.3l1.4,1c-5.4,7.5-12.4,12.2-21.4,12.2-13.2,0-23.1-9-23.1-24.5,0-16.7,12.6-27.3,24.7-27.3,13.4,0,19.9,7.5,20.2,21.3h-34.1v-.1Zm21.9-2.8c-.3-10.5-4.1-15.8-10.1-15.8-6.8,0-11.1,5.8-11.8,15.8h21.9Z'
                />
                <path
                    className='cls-1'
                    d='M367.08,124.12c-1.8,0-10.8-.2-14.3-.2-3.7,0-14.9,.2-16.8,.2v-1.4l3.2-.5c3.1-.5,4.4-1.3,4.4-3.2,0-1.6-1-3.6-3.2-7l-11.2-17.1-11.2,15.4c-2.6,3.6-3.8,6.3-3.8,8.3s1.2,3.2,4.3,3.7l3.3,.5v1.4c-1.8,0-9.8-.2-13.4-.2s-9.3,.2-11.2,.2v-1.5l1.7-.3c4.5-.8,8.7-4.6,12.1-8.9l16.2-21.2-15.9-23.6c-3.7-5.5-6.3-8-10.3-8.7l-2.1-.4v-1.4c1.8,0,10.8,.2,14.3,.2,3.7,0,14.7-.2,16.6-.2v1.4l-3.2,.5c-2.7,.4-4.2,1.3-4.2,3.2,0,1.6,1,3.6,3.2,7l9.6,14.8,9.4-13.2c2.5-3.6,3.8-6.3,3.8-8.2s-1.3-3.2-4.3-3.7l-3.3-.5v-1.4c1.8,0,9.8,.2,13.4,.2s9.3-.2,11.2-.2v1.4l-1.7,.3c-4.5,.8-8.6,4.2-12.1,8.9l-14.5,19,17.6,25.8c3.7,5.5,6.3,8,10.3,8.7l2.1,.4v1.5h0Z'
                />
            </g>
            <g>
                <path
                    className='cls-1'
                    d='M437.92,124.93c-19.49,0-32.42-14.04-32.42-36.46s12.93-36.46,32.52-36.46,32.32,13.74,32.32,36.46-12.93,36.46-32.42,36.46Zm0-4.44c16.56,0,27.07-11.92,27.07-32.02s-10.5-32.02-27.07-32.02-27.07,12.02-27.07,32.02,10.6,32.02,27.07,32.02Z'
                />
                <path
                    className='cls-1'
                    d='M479.23,123.82c-.4,0-.81-.4-.81-.81l.2-22.02-.2-28.38c0-.4,.4-.81,.81-.81h3.43c.4,0,.81,.4,.81,.81l-.2,9.9c2.93-5.86,8.69-11.92,17.67-11.92,10.5,0,16.97,6.77,16.97,21.01v9.9l.1,21.51c0,.4-.4,.81-.81,.81h-3.54c-.4,0-.81-.4-.81-.81l.2-21.51v-9.39c0-12.52-5.25-17.27-13.13-17.27s-13.03,5.86-16.46,15.35v11.31l.1,21.51c0,.4-.4,.81-.81,.81h-3.54Z'
                />
                <path
                    className='cls-1'
                    d='M569.32,105.44c.4,0,.71,.4,.71,.81-1.92,11.41-8.99,18.68-21.41,18.68-15.35,0-23.43-11.01-23.43-26.97s8.79-27.27,22.83-27.27,22.02,9.59,22.32,26.16c0,.4-.4,.81-.81,.81l-39.29,.1c0,14.65,6.16,23.23,18.48,23.23,9.39,0,14.75-5.55,16.46-14.75,0-.5,.5-.81,.91-.81h3.23Zm-21.31-30.8c-10.3,0-16.36,7.37-17.57,18.99h34.54c-.1-10.5-5.96-18.99-16.97-18.99Z'
                />
            </g>
            <path
                className='cls-1'
                d='M375.08,69.02c-.8,0-1.5-.1-2.1-.4-.7-.3-1.2-.7-1.7-1.2s-.9-1.1-1.1-1.7c-.3-.7-.4-1.4-.4-2.1,0-.8,.1-1.5,.4-2.1,.3-.7,.7-1.2,1.1-1.7s1.1-.9,1.7-1.1c.7-.3,1.4-.4,2.1-.4,.8,0,1.5,.1,2.1,.4,.7,.3,1.2,.7,1.7,1.1,.5,.5,.9,1.1,1.1,1.7,.3,.7,.4,1.4,.4,2.1,0,.8-.1,1.5-.4,2.1-.3,.7-.7,1.2-1.1,1.7-.5,.5-1.1,.9-1.7,1.2-.6,.2-1.3,.4-2.1,.4Zm0-.8c.7,0,1.3-.1,1.8-.4,.6-.2,1.1-.6,1.5-1s.7-.9,1-1.5c.2-.6,.3-1.2,.3-1.8,0-.7-.1-1.3-.3-1.8-.2-.6-.6-1.1-1-1.5s-.9-.7-1.5-1c-.6-.2-1.2-.4-1.8-.4-.7,0-1.3,.1-1.8,.4-.6,.2-1.1,.6-1.5,1s-.7,.9-1,1.5c-.2,.6-.3,1.2-.3,1.8,0,.7,.1,1.3,.3,1.8,.2,.6,.6,1.1,1,1.5s.9,.8,1.5,1c.6,.3,1.2,.4,1.8,.4Zm-2.3-1.4v-6.4h2.8c.7,0,1.3,.2,1.7,.5,.4,.4,.6,.8,.6,1.4s-.2,1-.6,1.4-.9,.5-1.6,.5l.3-.1c.3,0,.5,.1,.7,.2,.2,.1,.4,.3,.6,.6l1.1,1.9h-1.5l-1.1-2.1c-.1-.2-.2-.3-.3-.3-.1-.1-.3-.1-.5-.1h-.7v2.5h-1.5Zm1.3-3.6h1.3c.4,0,.7-.1,.8-.2,.2-.1,.3-.4,.3-.7s-.1-.6-.3-.7c-.2-.1-.5-.2-.8-.2h-1.3v1.8Z'
            />
        </svg>


const Logo = React.memo(() => {
    // Played with the cropping a bit, leaving here just in case we need more viewbox precision, this might be a good starting point
    // <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='40 40 543.23 110.82'>
    const env = ResolveEnvironment()
    const getLogo = () => {
        switch(env.toLowerCase()){
            case 'pro': return proLogo;
            default: return defaultLogo;
        }
    };

    return getLogo()
        
});

export default Logo;
