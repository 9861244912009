// @ts-strict-ignore
import { ApiOrderType } from 'phoenix/models/ApiTradeRequest';
import { Order } from '../redux/models';

/** @deprecated */
export const DeterminOrderType = (order: Order): ApiOrderType => DetermineOrderType(order);

export const DetermineOrderType = (order: Order): ApiOrderType => {
    switch (true) {
        case !!order.orderType:
            return order.orderType;
        case !!order.limitPrice && !!order.stopPrice:
            return 'stoplimit';
        case !!order.limitPrice && !order.stopPrice:
            return 'limit';
        case !order.limitPrice && !!order.stopPrice:
            return 'stop';
        default:
            return 'market';
    }
};

/** @deprecated */
export const FormattedOrderTypeLabel = (order: Order) => DeterminOrderType(order);
