// @ts-strict-ignore
// Storage layer abstraction, since RN doesn't have localStorage

type StorageDriver<T> = {
    get: (key: string) => T;
    set: (key: string, val: T) => void;
};

type Memory<T> = Record<string, T>;

const _stringMem: Memory<string> = {};
const _boolMem: Memory<boolean> = {};
const _arrayMem: Memory<any[]> = {};

const StringDriver: StorageDriver<string> = {
    get: (key) => _stringMem[key],
    set: (key, val) => (_stringMem[key] = val)
};

const BooleanDriver: StorageDriver<boolean> = {
    get: (key) => !!_boolMem[key],
    set: (key, val) => (_boolMem[key] = val)
};

const ArrayDriver: StorageDriver<any[]> = {
    get: (key) => _arrayMem[key],
    set: (key, val) => (_arrayMem[key] = val)
};

let _string: StorageDriver<string> = StringDriver;
let _bool: StorageDriver<boolean> = BooleanDriver;
let _array: StorageDriver<any[]> = ArrayDriver;

export const SetDebugPersist = (driver: StorageDriver<boolean>) => (_bool = driver);
export const SetDebugStringPersist = (driver: StorageDriver<string | null>) => (_string = driver);
export const SetDebugArrayPersist = (driver: StorageDriver<any[]>) => (_array = driver);

export type DebugGenericEntry<T> = {
    get: () => T;
    set: (val: T) => void;
    label: string;
    webOnly?: boolean;
    subLabel?: string;
};
export type DebugFlagEntry = DebugGenericEntry<boolean>;
export type DebugStringEntry = DebugGenericEntry<string>;

export const SetUseDebugIdleTime = (enable: boolean) => _bool.set('MANAGE_IDLE_DEBUG_TIME', enable);
export const GetUseDebugIdleTime = () => _bool.get('MANAGE_IDLE_DEBUG_TIME');
const IdleTimeEntry = {
    get: GetUseDebugIdleTime,
    set: SetUseDebugIdleTime,
    label: 'Use Idle Debug Time'
};

export const SetShowDebugHud = (show: boolean) => _bool.set('SHOW_DEBUG_HUD', show);
export const GetShowDebugHud = () => _bool.get('SHOW_DEBUG_HUD');
const ShowDebugHudEntry = {
    get: GetShowDebugHud,
    set: SetShowDebugHud,
    label: 'Show Debug HUD',
    webOnly: true
};

export const SetEnableDebugLogging = (enable: boolean) => _bool.set('SHOW_DEBUG_LOGGING', enable);
export const GetEnableDebugLogging = () => _bool.get('SHOW_DEBUG_LOGGING');
const EnableDebugLoggingEntry = {
    get: GetEnableDebugLogging,
    set: SetEnableDebugLogging,
    label: 'Enable Debug Logging'
};

export const SetSecurityStaleDateDebug = (enable: boolean) => _bool.set('SHOW_STALE_DATA', enable);
export const GetSecurityStaleDateDebug = () => _bool.get('SHOW_STALE_DATA');
const EnableSecurityStaleDateDebug = {
    get: GetSecurityStaleDateDebug,
    set: SetSecurityStaleDateDebug,
    label: 'Enable Security Stale Date Debug'
};

export const SetEnableLightstreamerErrorLogging = (enable: boolean) => _bool.set('SHOW_LS_LOGGING', enable);
export const GetEnableLightstreamerErrorLogging = () => _bool.get('SHOW_LS_LOGGING');
const EnableLightstreamerErrorLoggingEntry = {
    get: GetEnableDebugLogging,
    set: SetEnableDebugLogging,
    label: 'Enable Lightstreamer Error Logging'
};

export const SetEnableReduxLogging = (enable: boolean) => _bool.set('SHOW_REDUX_LOGGING', enable);
export const GetEnableReduxLogging = () => _bool.get('SHOW_REDUX_LOGGING');
const EnableReduxLoggingEntry = {
    get: GetEnableReduxLogging,
    set: SetEnableReduxLogging,
    label: 'Enable Redux Logging'
};

export const SetDisableTimeout = (enable: boolean) => _bool.set('DISABLE_TIMEOUT', enable);
export const GetDisableTimeout = () => _bool.get('DISABLE_TIMEOUT');
const DisableTimeoutEntry = {
    get: GetDisableTimeout,
    set: SetDisableTimeout,
    label: 'Disable Idle Timeout'
};

export const SetDisableCharts = (enable: boolean) => _bool.set('DISABLE_CHARTING', enable);
export const GetDisableCharts = () => _bool.get('DISABLE_CHARTING');
const DisableChartsEntry = {
    get: GetDisableCharts,
    set: SetDisableCharts,
    label: 'Disable Charts'
};

export const SetDisableSnexStreaming = (enable: boolean) => _bool.set('DISABLE_STREAMING_SNEX', enable);
export const GetDisableSnexStreaming = () => _bool.get('DISABLE_STREAMING_SNEX');
const DisableSnexStreamingEntry = {
    get: GetDisableSnexStreaming,
    set: SetDisableSnexStreaming,
    label: 'Disable SNEX Streaming'
};

export const SetDisableGainStreaming = (enable: boolean) => _bool.set('DISABLE_STREAMING_GAIN', enable);
export const GetDisableGainStreaming = () => _bool.get('DISABLE_STREAMING_GAIN');
const DisableGainStreamingEntry = {
    get: GetDisableGainStreaming,
    set: SetDisableGainStreaming,
    label: 'Disable Gain Streaming'
};

export const SetDebugAppReview = (enable: boolean) => _bool.set('ENABLE_APP_REVIEW', enable);
export const GetDebugAppReview = () => _bool.get('ENABLE_APP_REVIEW');
const DisableDebugAppReviewEntry = {
    get: GetDebugAppReview,
    set: SetDebugAppReview,
    label: 'Enable Debug App Review'
};

export const SetIgnoreAllLogsMobile = (enable: boolean) => _bool.set('IGNORE_ALL_LOGS', enable);
export const GetIgnoreAllLogsMobile = () => _bool.get('IGNORE_ALL_LOGS');
const IgnoreAllLogsMobileEntry = {
    get: GetIgnoreAllLogsMobile,
    set: SetIgnoreAllLogsMobile,
    label: 'Ignore All Logs (mobile)'
};

export const SetHidePositionSticker = (enable: boolean) => _bool.set('DISABLE_POS_STICKER', enable);
export const GetHidePositionSticker = () => _bool.get('DISABLE_POS_STICKER');
const HidePositionStickerEntry = {
    get: GetHidePositionSticker,
    set: SetHidePositionSticker,
    label: 'Hide Position Sidebar',
    webOnly: true
};

export const SetDisablePrestage = (enable: boolean) => _bool.set('DISABLE_PRESTAGE', enable);
export const GetDisablePrestage = () => _bool.get('DISABLE_PRESTAGE');
const DisablePrestageEntry = {
    get: GetDisablePrestage,
    set: SetDisablePrestage,
    label: 'Disable Prestage',
    webOnly: true
};

export const SetMessagingDebug = (enable: boolean) => _bool.set('DEBUG_MESSAGING', enable);
export const GetMessagingDebug = () => _bool.get('DEBUG_MESSAGING');
const MessagingDebugEntry = {
    get: GetMessagingDebug,
    set: SetMessagingDebug,
    label: 'Debug Messaging'
};

export const SetTradeDebug = (enable: boolean) => _bool.set('DEBUG_TRADE_DEBUG', enable);
export const GetTradeDebug = () => _bool.get('DEBUG_TRADE_DEBUG');
const TradeDebugEntry = {
    get: GetTradeDebug,
    set: SetTradeDebug,
    label: 'Debug Trades'
};

export const SetBuyingPowerDebug = (enable: boolean) => _bool.set('DEBUG_BUYING_POWER', enable);
export const GetBuyingPowerDebug = () => _bool.get('DEBUG_BUYING_POWER');
const BuyingPowerDebugEntry = {
    get: GetBuyingPowerDebug,
    set: SetBuyingPowerDebug,
    label: 'Debug Buying Power'
};

export const SetFundDebug = (enable: boolean) => _bool.set('DEBUG_FUND_DEBUG', enable);
export const GetFundDebug = () => _bool.get('DEBUG_FUND_DEBUG');
const FundDebugEntry = {
    get: GetFundDebug,
    set: SetFundDebug,
    label: 'Debug Funding'
};

export const SetOrderDebug = (enable: boolean) => _bool.set('DEBUG_ORDER_DEBUG', enable);
export const GetOrderDebug = () => _bool.get('DEBUG_ORDER_DEBUG');
const OrderDebugEntry = {
    get: GetOrderDebug,
    set: SetOrderDebug,
    label: 'Debug Orders'
};

export const SetZeroAccounts = (enable: boolean) => _bool.set('DEBUG_ZERO_ACCOUNTS', enable);
export const GetZeroAccounts = () => _bool.get('DEBUG_ZERO_ACCOUNTS');
const ZeroAccountsEntry = {
    get: GetZeroAccounts,
    set: SetZeroAccounts,
    label: 'Simulate Zero Accounts'
};

export const SetZeroFuturesAccounts = (enable: boolean) => _bool.set('DEBUG_ZERO_FUTURES_ACCOUNTS', enable);
export const GetZeroFuturesAccounts = () => _bool.get('DEBUG_ZERO_FUTURES_ACCOUNTS');
const ZeroFuturesAccountsEntry = {
    get: GetZeroFuturesAccounts,
    set: SetZeroFuturesAccounts,
    label: 'Simulate Zero Futures Accounts'
};

export const SetOneAccount = (enable: boolean) => _bool.set('DEBUG_ONE_ACCOUNT', enable);
export const GetOneAccount = () => _bool.get('DEBUG_ONE_ACCOUNT');
const OneAccountEntry = {
    get: GetOneAccount,
    set: SetOneAccount,
    label: 'Simulate One Account'
};

export const SetSimulateOptionsTrading = (enable: boolean) => _bool.set('DEBUG_OPTS_ALLOWED', enable);
export const GetSimulateOptionsTrading = () => _bool.get('DEBUG_OPTS_ALLOWED');
const SimulateOptionsTradingEntry = {
    get: GetSimulateOptionsTrading,
    set: SetSimulateOptionsTrading,
    label: 'Simlate Options Trading'
};

export const SetSimulateOptionsLevel = (val) => _string.set('DEBUG_OPTS_LEVEL', val);
export const GetSimulateOptionsLevel = () => _string.get('DEBUG_OPTS_LEVEL');
const SimulateOptionsLevelEntry = {
    get: GetSimulateOptionsLevel,
    set: SetSimulateOptionsLevel,
    label: 'Simulate Options Level'
};

export const SetNoFeatures = (enable: boolean) => _bool.set('DEBUG_NO_FEATURES', enable);
export const GetNoFeatures = () => _bool.get('DEBUG_NO_FEATURES');
const NoFeaturesEntry = {
    get: GetNoFeatures,
    set: SetNoFeatures,
    label: 'Simulate No Features'
};

export const SetSimulateNotFunded = (enable: boolean) => _bool.set('DEBUG_NOT_FUNDED', enable);
export const GetSimulateNotFunded = () => _bool.get('DEBUG_NOT_FUNDED');
const SimulateNotFundedEntry = {
    get: GetSimulateNotFunded,
    set: SetSimulateNotFunded,
    label: 'Simulate Not Funded'
};

export const SetSimulateNotTraded = (enable: boolean) => _bool.set('DEBUG_NOT_TRADED', enable);
export const GetSimulateNotTraded = () => _bool.get('DEBUG_NOT_TRADED');
const SimulateNotTradedEntry = {
    get: GetSimulateNotTraded,
    set: SetSimulateNotTraded,
    label: 'Simulate Not Traded'
};

export const SetShowTours = (enable: boolean) => _bool.set('DEBUG_TOURS', enable);
export const GetShowTours = () => _bool.get('DEBUG_TOURS');
const ShowToursEntry = {
    get: GetShowTours,
    set: SetShowTours,
    label: 'Show Tours'
};

export const SetUseLimitedAccess = (enable: boolean) => _bool.set('LIMIT_ACCESS', enable);
export const GetUseLimitedAccess = () => _bool.get('LIMIT_ACCESS');
const UseLimitedAccessEntry = {
    get: GetUseLimitedAccess,
    set: SetUseLimitedAccess,
    label: 'Use Limited Variant',
    webOnly: true
};

export const SetUseWhitelabel = (whitelabel: string) => _string.set('WHITELABEL', whitelabel);
export const GetUseWhitelabel = () => _string.get('WHITELABEL');
const UseWhitelabelEntry = {
    get: GetUseWhitelabel,
    set: SetUseWhitelabel,
    label: 'Use Whitelabel',
    webOnly: true
};

export const SetUseVerboseRedux = (enable: boolean) => _bool.set('DEBUG_REDUX_VERBOSE', enable);
export const GetUseVerboseRedux = () => _bool.get('DEBUG_REDUX_VERBOSE');
const UseVerboseReduxEntry = {
    get: GetUseVerboseRedux,
    set: SetUseVerboseRedux,
    label: 'Disable Redux Optimizations'
};

export const SetPremarketSimulatedHours = (enable: boolean) => _bool.set('DEBUG_PREMARKET', enable);
export const GetPremarketSimulatedHours = () => _bool.get('DEBUG_PREMARKET');
const SimulatePremarketHoursEntry = {
    get: GetPremarketSimulatedHours,
    set: SetPremarketSimulatedHours,
    label: 'Simulate Pre-Market Hours'
};

export const SetMarketSimulatedHours = (enable: boolean) => _bool.set('DEBUG_MARKET', enable);
export const GetMarketSimulatedHours = () => _bool.get('DEBUG_MARKET');
const SimulateMarketHoursEntry = {
    get: GetMarketSimulatedHours,
    set: SetMarketSimulatedHours,
    label: 'Simulate Market Hours'
};

export const SetPostmarketSimulatedHours = (enable: boolean) => _bool.set('DEBUG_POSTMARKET', enable);
export const GetPostmarketSimulatedHours = () => _bool.get('DEBUG_POSTMARKET');
const SimulatePostmarketHoursEntry = {
    get: GetPostmarketSimulatedHours,
    set: SetPostmarketSimulatedHours,
    label: 'Simulate Post-Market Hours'
};

export const SetClosedSimulatedHours = (enable: boolean) => _bool.set('DEBUG_CLOSED', enable);
export const GetClosedSimulatedHours = () => _bool.get('DEBUG_CLOSED');
const SimulateClosedHoursEntry = {
    get: GetClosedSimulatedHours,
    set: SetClosedSimulatedHours,
    label: 'Simulate Closed Hours'
};

export const SetHolidaySimulatedHours = (enable: boolean) => _bool.set('DEBUG_HOLIDAY', enable);
export const GetHolidaySimulatedHours = () => _bool.get('DEBUG_HOLIDAY');
const SimulateHolidayHoursEntry = {
    get: GetHolidaySimulatedHours,
    set: SetHolidaySimulatedHours,
    label: 'Simulate Holiday'
};

export const SetMaintenanceSimulatedHours = (enable: boolean) => _bool.set('DEBUG_MAINTENANCE', enable);
export const GetMaintenanceSimulatedHours = () => _bool.get('DEBUG_MAINTENANCE');
const SimulateMaintenanceHoursEntry = {
    get: GetMaintenanceSimulatedHours,
    set: SetMaintenanceSimulatedHours,
    label: 'Simulate Maintenance Hours'
};

export const SetNonWithdrawableSimulatedHours = (enable: boolean) => _bool.set('DEBUG_NON_WITHDRAW', enable);
export const GetNonWithdrawableSimulatedHours = () => _bool.get('DEBUG_NON_WITHDRAW');
const SimulateNonWithdrawableHoursEntry = {
    get: GetNonWithdrawableSimulatedHours,
    set: SetNonWithdrawableSimulatedHours,
    label: 'Simulate Non Withdrawable Hours'
};

export const SetWeekendSimulatedHours = (enable: boolean) => _bool.set('DEBUG_WEEKEND', enable);
export const GetWeekendSimulatedHours = () => _bool.get('DEBUG_WEEKEND');
const SimulateWeekendHoursEntry = {
    get: GetWeekendSimulatedHours,
    set: SetWeekendSimulatedHours,
    label: 'Simulate Weekend Hours'
};

export const SetHalfHolidaySimulatedHours = (enable: boolean) => _bool.set('DEBUG_HALF_HOLIDAY', enable);
export const GetHalfHolidaySimulatedHours = () => _bool.get('DEBUG_HALF_HOLIDAY');
const SimulateHalfHolidayHoursEntry = {
    get: GetHalfHolidaySimulatedHours,
    set: SetHalfHolidaySimulatedHours,
    label: 'Simulate Half-Holiday'
};

export const SetMarginCall = (enable: boolean) => _bool.set('DEBUG_MARGIN_CALL', enable);
export const GetMarginCall = () => _bool.get('DEBUG_MARGIN_CALL');
const SimulateMarginCall = {
    get: GetMarginCall,
    set: SetMarginCall,
    label: 'Simulate Maintenance Call'
};

export const SetNinetyDayRestriction = (enable: boolean) => _bool.set('DEBUG_90D_RESTRICTION', enable);
export const GetNinetyDayRestriction = () => _bool.get('DEBUG_90D_RESTRICTION');
const SimulateNinetyDayRestriction = {
    get: GetNinetyDayRestriction,
    set: SetNinetyDayRestriction,
    label: 'Simulate 90 Day Restriction'
};

export const SetIgnoreFPLMinimumBuyingPower = (enable: boolean) => _bool.set('DEBUG_IGNORE_FPL_MIN_BUYING_POWER', enable);
export const GetIgnoreFPLMinimumBuyingPower = () => _bool.get('DEBUG_IGNORE_FPL_MIN_BUYING_POWER');

export const SetShowFixedPlusNavLink = (enable: boolean) => _bool.set('DEBUG_SHOW_FIXED_PLUS_NAV_LINK', enable);
export const GetShowFixedPlusNavLink = () => _bool.get('DEBUG_SHOW_FIXED_PLUS_NAV_LINK');
const ShowFixedPlusNavLink = {
    get: GetShowFixedPlusNavLink,
    set: SetShowFixedPlusNavLink,
    label: 'Show Fixed Plus Nav Link'
};

export const SetAllAccountsHaveFPL = (enable: boolean) => {
    _bool.set('DEBUG_ENABLE_ALL_ACCT_FPL', enable);
    _bool.set('DEBUG_PENDING_ALL_ACCT_FPL', false);
};
export const GetAllAccountsHaveFPL = () => _bool.get('DEBUG_ENABLE_ALL_ACCT_FPL');
export const SetAllAccountsPendingFPL = (enable: boolean) => {
    _bool.set('DEBUG_PENDING_ALL_ACCT_FPL', enable);
    _bool.set('DEBUG_ENABLE_ALL_ACCT_FPL', false);
};

export const SetDebugAdvancedChart = (enable: boolean) => _bool.set('DEBUG_ADVANCED_CHART', enable);
export const GetDebugAdvancedChart = () => _bool.get('DEBUG_ADVANCED_CHART');
const DebugAdvancedChartEntry = {
    get: GetDebugAdvancedChart,
    set: SetDebugAdvancedChart,
    label: 'Debug Advanced Chart'
};

export const GetAllAccountsPendingFPL = () => _bool.get('DEBUG_PENDING_ALL_ACCT_FPL');

export const SetForceOnboardingFailure = (enable: boolean) => _bool.set('DEBUG_FORCE_ONBOARDING_FAILURE', enable);
export const GetForceOnboardingFailure = () => _bool.get('DEBUG_FORCE_ONBOARDING_FAILURE');

const SimulateFPLMinBuyingPower = {
    get: GetIgnoreFPLMinimumBuyingPower,
    set: SetIgnoreFPLMinimumBuyingPower,
    label: 'Ignore the minimum buying power for Fully Paid Lending'
};

export const SetLocalUrls = (urls: string[]) => {
    _array.set('DEBUG_SET_LOCAL_URLS', urls);
};
export const GetLocalUrls = () => _array.get('DEBUG_SET_LOCAL_URLS');

export const SetForceNewsFailure = (enable: boolean) => _bool.set('DEBUG_FORCE_NEWS_FAILURE', enable);
export const GetForceNewsFailure = () => _bool.get('DEBUG_FORCE_NEWS_FAILURE');

export const SetShowLoadingWelcomeScreen = (enable: boolean) => _bool.set('DEBUG_LOADING_SHOWWELCOME', enable);
export const GetShowLoadingWelcomeScreen = () => _bool.get('DEBUG_LOADING_SHOWWELCOME');
const UseLoadingShowWelcomeScreen = {
    get: GetShowLoadingWelcomeScreen,
    set: SetShowLoadingWelcomeScreen,
    label: 'Show Welcome Screen'
};

export const SetShowWelcomeScreen = (enable: boolean) => _bool.set('DEBUG_SHOWWELCOME', enable);
export const GetShowWelcomeScreen = () => _bool.get('DEBUG_SHOWWELCOME');
const UseShowWelcomeScreen = {
    get: GetShowWelcomeScreen,
    set: SetShowWelcomeScreen,
    label: 'Show Welcome Screen'
};

export const SetShowWelcomeScreenWithError = (enable: boolean) => _bool.set('DEBUG_SHOWWELCOME_ERROR', enable);
export const GetShowWelcomeScreenWithError = () => _bool.get('DEBUG_SHOWWELCOME_ERROR');
const UseShowWelcomeScreenWithError = {
    get: GetShowWelcomeScreenWithError,
    set: SetShowWelcomeScreenWithError,
    label: 'Show Welcome Screen with Error'
};

export const SetDebugToasts = (enable: boolean) => _bool.set('DEBUG_TOASTS', enable);
export const GetDebugToasts = () => _bool.get('DEBUG_TOASTS');
const DebugToastsEntry = {
    get: GetDebugToasts,
    set: SetDebugToasts,
    label: 'Debug Toasts'
};

export const AllDebugFlags: (DebugFlagEntry | DebugStringEntry)[] = [
    BuyingPowerDebugEntry,
    DebugAdvancedChartEntry,
    DisableChartsEntry,
    DisableDebugAppReviewEntry,
    DisableGainStreamingEntry,
    DisablePrestageEntry,
    DisableSnexStreamingEntry,
    DisableTimeoutEntry,
    EnableDebugLoggingEntry,
    EnableLightstreamerErrorLoggingEntry,
    EnableReduxLoggingEntry,
    EnableSecurityStaleDateDebug,
    FundDebugEntry,
    HidePositionStickerEntry,
    IdleTimeEntry,
    IgnoreAllLogsMobileEntry,
    MessagingDebugEntry,
    NoFeaturesEntry,
    OneAccountEntry,
    OrderDebugEntry,
    ShowDebugHudEntry,
    ShowFixedPlusNavLink,
    ShowToursEntry,
    SimulateClosedHoursEntry,
    SimulateFPLMinBuyingPower,
    SimulateHalfHolidayHoursEntry,
    SimulateHolidayHoursEntry,
    SimulateMaintenanceHoursEntry,
    SimulateMarginCall,
    SimulateNinetyDayRestriction,
    SimulateNonWithdrawableHoursEntry,
    SimulateNotFundedEntry,
    SimulateNotTradedEntry,
    SimulateOptionsLevelEntry,
    SimulateOptionsTradingEntry,
    SimulatePostmarketHoursEntry,
    SimulatePremarketHoursEntry,
    SimulateWeekendHoursEntry,
    TradeDebugEntry,
    UseLimitedAccessEntry,
    UseVerboseReduxEntry,
    UseWhitelabelEntry,
    ZeroAccountsEntry,
    ZeroFuturesAccountsEntry,
    UseLoadingShowWelcomeScreen,
    UseShowWelcomeScreen,
    UseShowWelcomeScreenWithError,
    DebugToastsEntry
];
