import { Urls } from "phoenix/constants";
import { AuthorizationSetting } from "../models/AuthorizationSettings/AuthorizationSetting";
import { Actions } from "./Constants";
import { ReduxApiGet, ReduxApiPost } from "./Helpers";

export const GetAuthorizationSettings = () => 
    ReduxApiGet(Urls.authorizationSettings.getSettings(), Actions.AuthorizationSettings.GetSettings)
        .withMutex()
        .run();
        // .almostRun(true, [
        //     {
        //         id: "authorizationSettings_dev",
        //         env: "development",
        //         settings: {
        //             permittedRoles: [
        //                 "admin",
        //                 "qa"
        //             ],
        //             subWhitelist: [
        //                 "101"
        //             ],
        //             subBlacklist: [],
        //             emailWhitelist: [],
        //             allowedBranchesForTrades: [
        //                 "SX"
        //             ]
        //         }
        //     },
        //     {
        //         id: "authorizationSettings_prod",
        //         env: "production",
        //         settings: {
        //             permittedRoles: [
        //                 "admin",
        //                 "qa"
        //             ],
        //             subWhitelist: [
        //                 "101"
        //             ],
        //             subBlacklist: [],
        //             emailWhitelist: [],
        //             allowedBranchesForTrades: [
        //                 "SX"
        //             ]
        //         },
        //     },
        //     {
        //         id: "authorizationSettings_test",
        //         env: "test",
        //         settings: {
        //             permittedRoles: [
        //                 "admin",
        //                 "qa"
        //             ],
        //             subWhitelist: [
        //                 "101",
        //                 "205"
        //             ],
        //             subBlacklist: [],
        //             emailWhitelist: [],
        //             allowedBranchesForTrades: [
        //                 "SX"
        //             ]
        //         },
        //     }
        // ])

export const SaveAuthorizationSettings = (s: AuthorizationSetting) => 
    ReduxApiPost(Urls.authorizationSettings.saveSettings(), Actions.AuthorizationSettings.GetSettings, s)
        .withMutex()
        .run();
   