// @ts-strict-ignore
import { Flex } from 'components/Flex';
import { useColors } from 'hooks/UseColors';
import React from 'react';
import _style from './style.module.scss';
import { AuthorAvatar } from '../avatar/AuthorAvatar';

export const PostTitleCard = ({
    title,
    authorIcon,
    authorName,
    authorTitle,
    duration,
    maxWidth,
    style
}: {
    title?: string;
    authorIcon?: string;
    authorName?: string;
    authorTitle?: string;
    duration?: number;
    maxWidth?: number | string;
    style?: React.CSSProperties;
}): JSX.Element => {
    const colors = useColors();

    return !title && !authorIcon && !authorName && !authorTitle ? null : (
        <Flex column fullWidth style={{ maxWidth, overflow: maxWidth ? 'hidden' : 'visible', ...style }}>
            {title && (
                <Flex
                    row
                    fullWidth
                    style={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        gap: 8,
                        marginBottom: 8,
                        position: 'relative'
                    }}
                >
                    <div>{title}</div>
                    {duration && <div>{duration}</div>}
                    <div className={_style['modal-title-fade-overlay']} />
                </Flex>
            )}
            {(authorName || authorTitle) && (
                <Flex row fullWidth justify={'center'} style={{ gap: 8 }}>
                    <AuthorAvatar url={authorIcon} borderColor={colors.selectListSelectedItemColor} />
                    <Flex column justify={'center'} style={{ color: colors.fadedTextColor }}>
                        {authorName && <div>{authorName}</div>}
                        {authorTitle && <div>{authorTitle}</div>}
                    </Flex>
                    <div style={{ flex: '1 1 auto' }} />
                </Flex>
            )}
        </Flex>
    );
};
