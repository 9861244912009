
export interface DebugEvent {
    timestamp?: string;
    item: string;
    event: string;
    sessionId?: string;
    other?: any;
}

let listOfDebugEvents: DebugEvent[] = [];

const RecordEvent = (event: DebugEvent): void => {
    listOfDebugEvents.unshift({ timestamp: new Date().toLocaleTimeString(), ...event });
};

const ClearLog = (): void => {
    listOfDebugEvents = [];
};

const GetDebugEvents = (): DebugEvent[] => listOfDebugEvents;

export const DebugDumpManager = { RecordEvent, ClearLog, GetDebugEvents };
