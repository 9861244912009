// @ts-strict-ignore
import { Urls } from "phoenix/constants";
import { FundingErrors, GetFundingErrorTextFromErrorCode, PlaidFailureCodes } from "phoenix/constants/FundingErrorsV2";
import { ApiTransferRequest, ApiTransferValidation } from "phoenix/models/Funding/ApiTransferRequest";
import { TransferResult } from "phoenix/models/Funding/TransferResult";
import { SnexErrorFromError } from "phoenix/models/SnexError";

import { T } from "phoenix/assets/lang/T";
import { SnexAxios } from "phoenix/stores/AxiosHelpers";

export const ValidateTransfer = async (transfer: ApiTransferRequest): Promise<TransferResult> => {

    try {

        /* validate transfer limits / min deposit etc... */
        const result = await SnexAxios.ApiPost<ApiTransferValidation>(Urls.funding.v2.transfer.validate(), { data: transfer })
            .run();
        if (!result?.isValid) {
            throw SnexErrorFromError(result?.error);
        }
    } catch (e) {
        if (e?.errorCode === FundingErrors.PlaidReauthNeeded.toString()) {
            return {
                error: {
                    errorCode: 'NEED_PLAID_REAUTH',
                    errorMessage: T((t) => t.bankingScreen.plaidAuthenticationBroken)
                },
                errorDisplaySubtitle: 'Plaid authorization needs to be updated', // todo: localize this?
                needsPlaidReauth: true,
                success: false
            };
        } else {
            return CreateTransferErrorResultResponse(transfer, e);
        }
    }

    return {
        success: true
    }

}


export const SubmitTransfer = async (transfer: ApiTransferRequest): Promise<TransferResult> => {

    const validation = await ValidateTransfer(transfer);
    if (!validation.success) return validation;

    const url = transfer.direction === 'deposit'
        ? Urls.funding.v2.transfer.deposit(transfer.accountNumber)
        : Urls.funding.v2.transfer.withdraw(transfer.accountNumber)

    try {
        const result = await SnexAxios.ApiPost(url, { data: { transferAmount: transfer.amount, fundingSourceId: transfer.fundingSourceId } })
            .run()
        if (result instanceof Error) throw result
        return { success: true }
    } catch (e) {
        return CreateTransferErrorResultResponse(transfer, e)
    }

}

export const CreateTransferErrorResultResponse = async (transfer: Partial<ApiTransferRequest>, e: unknown): Promise<TransferResult> => {
    const ae = SnexErrorFromError(e)
    const text = await GetFundingErrorTextFromErrorCode(transfer?.accountNumber, !ae ? FundingErrors.UnknownError : ae.errorCode)
    return {
        error: ae,
        errorDisplayTitle: text.title,
        errorDisplaySubtitle: text.subtitle,
        needsPlaidReauth: PlaidFailureCodes.has(ae.errorCode),
        warnings: [],
        success: false
    }
}