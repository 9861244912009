import { GetFromSnexStore } from 'phoenix/redux/util/GetFromSnexStore';
import { GetConfig, ProductTypes, SecurityType, SecurityTypeKeys, SecurityTypes } from '../constants';
import { QualifiedSecurityId } from '../models/QualifiedSecurityId';
import { GetSecurityMetadataAction } from '../redux/actions';
import { OptionSymbol, TradeableSecurityType } from '../redux/models';
import { SecurityMetadata } from '../redux/models/Securities/SecurityMetadata';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { SecurityMetadataV2, useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

/** @deprecated Please import from phoenix/redux/models  */
export { TradeableSecurityType } from '../redux/models';

export const IsMutualFund = (type: string): boolean =>
    type?.indexOf('Fund') !== -1 || (SecurityTypes[type as SecurityTypeKeys] || 'equity')?.indexOf('Fund') !== -1 || type.indexOf('mutual-fund') !== -1;

export const IsMutualFundByMetadata = (meta: SecurityMetadata): boolean => meta?.assetType === 'F' || meta?.assetType === 'OF';
export const IsMutualFundByMetadataV2 = (meta: SecurityMetadataV2): boolean => meta?.type === 'MutualFund' || meta?.type === 'OffshoreMutualFund';
export const IsOffshoreMutualFundByMetadata = (meta: SecurityMetadata): boolean => meta?.assetType === 'OF';
export const IsOffshoreMutualFundByMetadataV2 = (meta: SecurityMetadataV2): boolean => meta?.type === 'OffshoreMutualFund';

export const IsMutualFundBySymbol = async (symbol: string): Promise<boolean> => {
    const result = await GetConfig()?.Algolia?.Client?.search<{ type: string; symbol: string }>([
        { indexName: 'np_securities', params: { exactOnSingleWordQuery: 'word' }, query: symbol }
    ]);
    const type = result?.results.flatMap((r) => r.hits).find((h) => h.symbol === symbol)?.type || 'cs';
    return IsMutualFund(type);
};

export const TryGetTorchSecurityTypeSynchronous = (securityId: string): SecurityType | undefined => {
    return (usePositionsStore?.getState()?.positions || []).find((s) => {
        return [s.symbol, s.cusip, s.isin, s.sedol, s.securityNumber].some((x) => x === securityId);
    })?.productType;
};

/** @deprecated Please use GetSecurityTypeFromStore  */
export const DecodeSecurityMasterType = (assetType: string): TradeableSecurityType | null => {
    switch (assetType) {
        case 'C':
            return 'equity';
        case 'F':
            return 'mutual-fund';
        case 'P':
            return 'option';
        default:
            return null;
    }
};

export const GetSecurityTypeFromProductType = (productType: string): TradeableSecurityType => {
    switch (productType) {
        case ProductTypes.cryptos:
            return 'crypto';
        case ProductTypes.mutualFunds:
            return 'mutual-fund';
        case ProductTypes.options:
            return 'option';
        case ProductTypes.futures:
            return 'future';
        case ProductTypes.equities:
        default:
            return 'equity';
    }
};

/** @deprecated Please use GetSecurityTypeFromStore() */
export const GetSecurityType = async (qsi: string): Promise<TradeableSecurityType> => {
    if (/^F:/.test(qsi)) return 'future';
    if (/^X:/.test(qsi)) return 'crypto';
    if (OptionSymbol.IsOptionSymbol(qsi)) return 'option';
    const secId = QualifiedSecurityId.Parse(qsi);

    const dispatch = GetConfig()?.Store.dispatch;

    const metaPromise: Promise<SecurityMetadata> | null = dispatch ? dispatch(GetSecurityMetadataAction(secId.toString())) : null;

    let type = 'unknown';

    // If we have a symbol, we have more recourse
    if (secId.type === 'Symbol') {
        const cached = GetConfig()?.Store.getState().securities.bySymbol[secId.id || '']?.metadata?.data;
        if (cached) type = DecodeSecurityMasterType(cached?.assetType || '') || '';

        // Hit Algolia first if we have a symbol -- it's way quicker than SecMaster
        const algoliaSearch = GetConfig()?.Algolia?.Client?.search;
        if (algoliaSearch) {
            const result = await algoliaSearch<{ type: string; symbol: string }>([
                { indexName: 'np_securities', params: { exactOnSingleWordQuery: 'word' }, query: secId?.id || '' }
            ]);
            const iexType = result.results.flatMap((r) => r.hits).find((h) => h.symbol === secId.id)?.type;
            switch (iexType) {
                case 'cs':
                    type = 'equity';
                    break;
                case 'ad':
                    type = 'adr';
                    break;
                case 'oef':
                    type = 'mutual-fund';
                    break;
                case 'et':
                case 'cef':
                    type = 'etf';
                    break;
                default:
                    break;
            }
        }
    }
    const metaData = metaPromise && (await metaPromise);
    const metaType = metaData?.assetType;
    // Hit SecMaster if we're able to
    if (type === 'unknown' && metaPromise) type = DecodeSecurityMasterType(metaType || '') || '';

    return type as TradeableSecurityType;
};

let _defaultSecurityType: TradeableSecurityType;
export const ConfigureDefaultSecurityType = (type: TradeableSecurityType): void => {
    _defaultSecurityType = type;
};

/** @deprecated Use GetSecurityTypeFromStoreV2 for SecurityMetadataV2 */
export const GetSecurityTypeFromStore = (qsi: string): TradeableSecurityType => {
    if (OptionSymbol.IsOptionSymbol(qsi)) return 'option';
    if (/^F:/.test(qsi)) return 'future';
    if (/^X:/.test(qsi)) return 'crypto';

    const meta = GetFromSnexStore((s) => s.securities.bySymbol[qsi]?.metadata?.data);

    return GetSecurityTypeFromMetadata(meta);
};

export const GetSecurityTypeFromStoreV2 = (qsi: string): TradeableSecurityType => {
    if (OptionSymbol.IsOptionSymbol(qsi)) return 'option';
    if (/^F:/.test(qsi)) return 'future';
    if (/^X:/.test(qsi)) return 'crypto';

    const meta = useSecurityMetadataV2.getState().getMetadataBySymbol(qsi);

    return GetSecurityTypeFromMetadataV2(meta);
};

// Does not require store context
/** @deprecated Use GetSecurityTypeFromMetadataV2 for SecurityMetadataV2 */
export const GetSecurityTypeFromMetadata = (meta: SecurityMetadata | undefined): TradeableSecurityType => {
    const isFuture = FuturesSymbol.IsFuturesSymbol(meta?.symbol || '');
    if (OptionSymbol.IsOptionSymbol(meta?.symbol || '')) {
        return isFuture ? 'futures-option' : 'option';
    }
    if (FuturesSymbol.IsTimeSpreadSymbol(meta?.symbol || '')) return 'futures-time-spread';
    if (isFuture) return 'future';
    if (/^X:/.test(meta?.symbol || '')) return 'crypto';
    if (meta?.securityType === 'Unknown') return 'unknown';
    if (meta?.securityType === 'MutualFundClosed') return 'etf';
    if (meta?.assetType === 'C') return 'equity';
    if (meta?.assetType === 'F' || meta?.assetType === 'OF') return 'mutual-fund';
    if (meta?.assetType === 'P') return 'option';
    return _defaultSecurityType;
};

export const GetSecurityTypeFromMetadataV2 = (meta: SecurityMetadataV2 | undefined): TradeableSecurityType => {
    const isFuture = FuturesSymbol.IsFuturesSymbol(meta?.qsi || '');
    if (OptionSymbol.IsOptionSymbol(meta?.qsi || '')) {
        return isFuture ? 'futures-option' : 'option';
    }
    if (FuturesSymbol.IsTimeSpreadSymbol(meta?.qsi || '')) return 'futures-time-spread';
    if (isFuture) return 'future';
    if (/^X:/.test(meta?.qsi || '')) return 'crypto';
    if (meta?.type === 'Etf') return 'etf';
    if (meta?.type === 'Equity') return 'equity';
    if (meta?.type === 'MutualFund' || meta?.type === 'OffshoreMutualFund') return 'mutual-fund';
    if (meta?.type === 'EquityOption') return 'option';
    return _defaultSecurityType;
};
