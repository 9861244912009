// @ts-strict-ignore
import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Typography } from '@mui/material';
import { DecimalPriceInput, DecimalPriceInputProps } from 'components/CustomNumberInput/DecimalPriceInput';
import { Flex } from 'components/Flex';
import { TradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { omit } from 'lodash';
import { T } from 'phoenix/assets/lang/T';
import { useQuantityHeldV2 } from 'phoenix/hooks/UseQuantityHeld';
import { ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import './index.scss';

// The main difference between this component and V1 is the "Edit" functionality
// V1 opens a context menu; this component accepts an onClick fn prop with no args (toggle)

export const InputSplitV2 = React.memo(
    (props: { children: ReactElement; className?: string; label: string; labelColor?: string; onClick?: () => void; style?: React.CSSProperties; subLabel?: string }) => {
        const LabelComponent = useCallback(
            () => (
                <>
                    <Flex align='center'>
                        <Typography style={{ '--color': props.labelColor } as React.CSSProperties} variant='h6'>
                            {props.label}
                        </Typography>
                        {props.onClick && (
                            <IconButton style={{ marginLeft: 10 }} onClick={props.onClick} size='large'>
                                <Edit fontSize='small' />
                            </IconButton>
                        )}
                    </Flex>
                    {props.subLabel && <Typography color='textSecondary'>{props.subLabel}</Typography>}
                </>
            ),
            [props.label, props.labelColor, props.subLabel, props.onClick]
        );

        return (
            <FormControlLabel
                className={`input-split${props.className ? ` ${props.className}` : ''}`}
                control={props.children}
                label={<LabelComponent />}
                labelPlacement='start'
            />
        );
    }
);

export const QuantityInputSplit = React.memo(
    (
        props: {
            accountNumber?: string | null;
            initialValue?: number;
            label: string;
            liquidateOnSellAll?: boolean;
            onBuySellAll?: () => void;
            onQuantityToggle?: () => void;
            onUnitChange?: () => void;
            onValueChange?: (value: number) => void;
            priceFormat?: number;
            quantityQualifierState?: 'Shares' | 'EvenDollar';
            setQuantityQualifierState?: Dispatch<SetStateAction<'Shares' | 'EvenDollar'>>;
            showPosition?: boolean;
            showSellAll?: boolean;
            sublabel?: string;
            symbol: string;
            tradeAction?: ApiTradeAction;
        } & DecimalPriceInputProps
    ) => {
        const { onBuySellAll, symbol } = props;
        const filteredProps = omit(props, ['label', 'tradeAction', 'accountNumber', 'showSellAll', 'showPosition', 'liquidateOnSellAll']);
        const position = useQuantityHeldV2(QualifiedSecurityId.Parse(props.symbol), props.accountNumber);
        const { setViewModel, initialQuantity, quantity, quantityQualifier } = useTradeTicketViewModel<TradeTicketViewModel>();
        const sellAll = T((t) => t.tradeTicket.sellAll);
        const buyAll = T((t) => t.tradeTicket.buyAll);
        const sellBuyLabel = props.tradeAction === 'Buy' ? buyAll : sellAll;
        const assetClass = GetAssetClassForSecurity(symbol);
        const formattedPosition = assetClass.formatQuantity(position);

        const handleSellBuyAll = () => {
            const newState: Pick<TradeTicketViewModel, 'initialQuantity' | 'liquidate' | 'quantityQualifier' | 'quantity'> = {
                initialQuantity: Math.abs(position),
                quantity: null,
                quantityQualifier: 'Shares'
            };

            if (props.liquidateOnSellAll) newState.liquidate = true;

            setViewModel(newState);

            onBuySellAll && onBuySellAll();
        };

        useEffect(() => {
            const effectiveQuantity = quantity || initialQuantity;
            if (effectiveQuantity !== Math.abs(position) && props.liquidateOnSellAll) setViewModel({ liquidate: false });
        }, [position, setViewModel, quantity, props.liquidateOnSellAll, initialQuantity]);

        const showSellBuyAll = useMemo(
            () => props.showSellAll && ((props.tradeAction === 'Buy' && position < 0) || (props.tradeAction === 'Sell' && position > 0)),
            [props.showSellAll, props.tradeAction, position]
        );
        const _showPosition = useMemo(() => props.showPosition && !!position && position !== 0, [props.showPosition, position]);

        return (
            <Flex column>
                <InputSplitV2 label={props.label} subLabel={props.sublabel} onClick={props.onUnitChange}>
                    <DecimalPriceInput
                        key={`${symbol}-decimal-price`}
                        onDollarValueChange={props.onValueChange}
                        {...filteredProps}
                        showDollarSign={quantityQualifier === 'EvenDollar'}
                    />
                </InputSplitV2>
                {_showPosition && (
                    <Flex row justify='flex-end' style={{ marginTop: -2 }}>
                        {showSellBuyAll && (
                            <Typography className='sell-all-button' variant='caption' onClick={handleSellBuyAll}>
                                {sellBuyLabel}
                            </Typography>
                        )}
                        <Typography variant='caption'>
                            {T((t) => t.tradeTicket.position)} : {formattedPosition}
                        </Typography>
                    </Flex>
                )}
            </Flex>
        );
    }
);
