import { SystemConfiguration } from 'phoenix/redux/models/System/SystemConfiguration'
import { ApiData } from '../../../models'
import { QualifiedSecurityId } from '../../../models/QualifiedSecurityId'
import { Language } from './Language'
import { SystemStatus } from './SystemStatus'
import { TermsDocument } from './TermsDocument'
import { TimeZone } from './TimeZone'

export class SystemState {
    languages: ApiData<Language[]>
    timezones: ApiData<TimeZone[]>
    status: ApiData<SystemStatus[]>
    config: ApiData<SystemConfiguration>
    termsDocuments: {
        byName: { [key: string]: ApiData<TermsDocument> }
    }

    titleSecurity: QualifiedSecurityId | null

    constructor () {
        this.languages = new ApiData([])
        this.timezones = new ApiData([])
        this.status = new ApiData([])
        this.config = new ApiData()
        this.termsDocuments = { byName: {} }
        this.titleSecurity = null
    }
}
