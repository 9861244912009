// @ts-strict-ignore
import { XigniteAlertFields } from '../../constants/Alerts'
import { ReduxAction } from '../../models'
import { Actions, GroupNameChecker } from '../actions/Constants'
import { AlertField, AlertsState } from '../models'

const permitted = GroupNameChecker([Actions.Alerts])
export const AlertsReducer = (state: AlertsState = new AlertsState(), action: ReduxAction): AlertsState => {
    if (!permitted(action)) return state

    switch (action.type) {
        case Actions.Alerts.GetFields.Loading: return { ...state, fields: state.fields.startLoading(state.fields.data) }
        case Actions.Alerts.GetFields.Success: return { ...state, fields: state.fields.succeeded(action.data?.filter((field: AlertField) => XigniteAlertFields.has(field.value))) }
        case Actions.Alerts.GetFields.Failure: return { ...state, fields: state.fields.failed(action.error) }

        case Actions.Alerts.GetRules.Loading: return { ...state, rules: state.rules.startLoading(state.rules.data) }
        case Actions.Alerts.GetRules.Success: return { ...state, rules: state.rules.succeeded(action.data) }
        case Actions.Alerts.GetRules.Failure: return { ...state, rules: state.rules.failed(action.error) }

        case Actions.Alerts.CreateRule.Loading: return { ...state, createRuleOperation: state.createRuleOperation.startLoading() }
        case Actions.Alerts.CreateRule.Success: return { ...state, createRuleOperation: state.createRuleOperation.succeeded() }
        case Actions.Alerts.CreateRule.Failure: return { ...state, createRuleOperation: state.createRuleOperation.failed(action.error) }

        case Actions.Alerts.ToggleRule.Loading: return { ...state, toggleRuleOperation: state.toggleRuleOperation.startLoading() }
        case Actions.Alerts.ToggleRule.Success: return { ...state, toggleRuleOperation: state.toggleRuleOperation.succeeded() }
        case Actions.Alerts.ToggleRule.Failure: return { ...state, toggleRuleOperation: state.toggleRuleOperation.failed(action.error) }

        case Actions.Alerts.UpdateRule.Loading: return { ...state, updateRuleOperation: state.updateRuleOperation.startLoading() }
        case Actions.Alerts.UpdateRule.Success: return { ...state, updateRuleOperation: state.updateRuleOperation.succeeded() }
        case Actions.Alerts.UpdateRule.Failure: return { ...state, updateRuleOperation: state.updateRuleOperation.failed(action.error) }

        case Actions.Alerts.DeleteRule.Loading: return { ...state, deleteRuleOperation: state.deleteRuleOperation.startLoading() }
        case Actions.Alerts.DeleteRule.Success: return { ...state, deleteRuleOperation: state.deleteRuleOperation.succeeded() }
        case Actions.Alerts.DeleteRule.Failure: return { ...state, deleteRuleOperation: state.deleteRuleOperation.failed(action.error) }
    }
}
