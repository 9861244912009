// @ts-strict-ignore
import { Flex } from "components"
import React, { useEffect, useMemo } from "react"
import { OnboardingDropdown, OnboardingInputRow } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFullyPaidLendingStore } from "../../Store"
import { DropdownOption } from "phoenix/models/DropdownOptions"
import { useFullyPaidLending } from "phoenix/hooks/UseFullyPaidLending"
import { useDispatch } from "react-redux"
import { GetClientAccountsAction } from "phoenix/redux/actions"

export const FPL_FPLInfo_Form = (): JSX.Element => {

    const {data, setValue} = useFullyPaidLendingStore()
    const {fullyPaidLendingEligibleAccounts, isLoading} = useFullyPaidLending()
    const handleChange = (key: string, val: string | number) => setValue(key, val)

    const accountOptions: DropdownOption[] = useMemo(() => fullyPaidLendingEligibleAccounts?.map(a => ({label: a.effectiveName !== a.accountNumber? 
        `(${a.accountNumber}) ${a.effectiveName}` : a.accountNumber, value: a.accountNumber})), [fullyPaidLendingEligibleAccounts])

    useEffect(() => {
        if(!data.selectedAccount && fullyPaidLendingEligibleAccounts?.length === 1) {
            setValue('data.selectedAccount', fullyPaidLendingEligibleAccounts[0].accountNumber)
        }
    }, [fullyPaidLendingEligibleAccounts, setValue, data?.selectedAccount])

    return(
        <Flex column fullWidth center style={{minWidth: '450px'}}>
            <OnboardingInputRow noPadding align={'flex-end'}>  
                <OnboardingDropdown  
                    value={data?.selectedAccount} 
                    maxMenuHeight={100} 
                    options={accountOptions} 
                    handleChange={handleChange} 
                    name="data.selectedAccount" 
                    isLoading={isLoading}
                    errors={!data?.selectedAccount? [{key: 'data.selectedAccount', error: 'Please select an account to enroll'}] : []}/> 
            </OnboardingInputRow>
        </Flex>
        )
}