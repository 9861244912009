import { Brightness2, Brightness4, Brightness5, WbSunny } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useText } from 'phoenix/hooks/UseText';
import { SnexBadge } from '../SnexBadge';
import { MarketTimeSegment } from 'phoenix/util';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { MarketTimeSegments } from 'phoenix/constants/MarketTimeSegments';

export interface MarketHoursBadgeProps {
    marketTimeSegment: MarketTimeSegment;
    text: Snex1LanguagePack['marketTimeBadge'];
}

export function MarketHoursBadge(): JSX.Element {
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const text = useText((s) => s.marketTimeBadge);

    return <MarketHoursBadgePresentation marketTimeSegment={marketTimeSegment} text={text} />;
}

interface MarketHoursBadgePresentationProps extends MarketHoursBadgeProps {}

export function MarketHoursBadgePresentation({ marketTimeSegment, text }: MarketHoursBadgePresentationProps): JSX.Element {
    const segmentLabel = text[marketTimeSegment];

    const flavor = useMemo(() => {
        switch (marketTimeSegment) {
            case MarketTimeSegments.open:
                return 'green';
            case MarketTimeSegments.premarket:
                return 'orange';
            case MarketTimeSegments.postmarket:
            case 'closed':
            case 'loading':
            default:
                return 'blue';
        }
    }, [marketTimeSegment]);

    const Icon = useMemo(() => {
        switch (marketTimeSegment) {
            case MarketTimeSegments.closed:
                return Brightness2;
            case MarketTimeSegments.open:
                return WbSunny;
            case MarketTimeSegments.postmarket:
                return Brightness4;
            case MarketTimeSegments.premarket:
                return Brightness5;
            default:
                return Brightness5;
        }
    }, [marketTimeSegment]);

    return (
        <div style={{ display: 'inline-block', margin: '0 auto' }}>
            <SnexBadge loading={marketTimeSegment === 'loading'} whiteSpace={'normal'} flavor={flavor} iconComponent={Icon} style={{margin: 0}}>
                {segmentLabel}
            </SnexBadge>
        </div>
    );
}
