// @ts-strict-ignore
import { Checkbox, Typography } from '@mui/material';
import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { useText } from 'phoenix/hooks/UseText';
import { MutualFundAssetClass } from 'phoenix/models/AssetClasses/MutualFundAssetClass';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { IsOffshoreMutualFundByMetadataV2 } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { ChangeCaseToTitleCase } from 'util/Utils';
import { Flex } from '../..';
import { InputSplit } from '../../InputSplit/InputSplit';
import { TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { GetOffshoreReinvestmentType } from './GetOffshoreReinvestmentType';

export type ReinvestmentOptions = {
    dividends: boolean;
    shortTermGains: boolean;
    longTermGains: boolean;
};

interface ReinvestmentOptionsControlsProps {
    symbol: string;
    accountNumber: string;
    onOptionsChange: (val: ReinvestmentOptions) => void;
    options?: ReinvestmentOptions;
    isFund?: boolean;
}

export const ReinvestmentOptionsControls = (props: ReinvestmentOptionsControlsProps): ReactElement => {
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const { setViewModel } = viewModel;
    const meta = useSecurityMetadataV2().getMetadataBySymbol(props.symbol);
    const isOffshore = IsOffshoreMutualFundByMetadataV2(meta);
    const text = useText((s) => s.tradeTicket.reinvestmentOptions);
    const selectedAccountNumber = useSelectedAccountForOrderRequest(MutualFundAssetClass);
    const offshoreReinvestmentType = useMemo(() => GetOffshoreReinvestmentType(meta), [meta]);
    const offshoreInstructions =
        offshoreReinvestmentType === 'cash'
            ? text.offshoreCashInstructions(selectedAccountNumber, QualifiedId.RemovePrefix(props.symbol))
            : text.offshoreReinvestInstructions(selectedAccountNumber, QualifiedId.RemovePrefix(props.symbol));

    useEffect(() => {
        setViewModel({
            cashDividendsAvailable: meta?.mutualFund?.offshoreCashDividendsAvailable,
            offshoreReinvestmentType
        });
    }, [meta, offshoreReinvestmentType, setViewModel]);

    const { options } = props;
    const handleOptionChange = (field: string, value: boolean) => {
        const newOpts = { ...options, [field]: value };
        props.onOptionsChange(newOpts);
    };

    const ReinvestOptionCheckboxSplit = ({ checked, returnTypeLabel, stateKey }: { checked: boolean; returnTypeLabel: string; stateKey: string }) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleOptionChange(stateKey, !options[stateKey])}>
            <InputSplit label={ChangeCaseToTitleCase(returnTypeLabel)}>
                <Checkbox checked={checked} />
            </InputSplit>
        </div>
    );

    const ReinvestOptionsBody = useCallback(() => {
        return isOffshore ? (
            <Typography variant='body1' style={{ marginBottom: 10 }}>
                {offshoreInstructions}
            </Typography>
        ) : (
            <>
                <Typography variant='body1' style={{ marginBottom: 10 }}>
                    {text.mutualFundInstructions({ securityId: props.symbol, accountNumber: props.accountNumber })}
                </Typography>
                <ReinvestOptionCheckboxSplit returnTypeLabel={text.dividends} stateKey='dividends' checked={options.dividends} />
                <ReinvestOptionCheckboxSplit returnTypeLabel={text.shortTermCapitalGains} stateKey='shortTermGains' checked={options.shortTermGains} />
                <ReinvestOptionCheckboxSplit returnTypeLabel={text.longTermCapitalGains} stateKey='longTermGains' checked={options.longTermGains} />
            </>
        );
    }, [isOffshore, offshoreInstructions, text, props.symbol, props.accountNumber, options.dividends, options.shortTermGains, options.longTermGains]);

    return (
        <Flex column>
            <Typography variant='h5' style={{ fontSize: 18, marginBottom: 10 }}>
                {text.reinvestmentOptions}
            </Typography>
            <ReinvestOptionsBody />
        </Flex>
    );
};
