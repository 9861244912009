// @ts-strict-ignore
import React, { useCallback } from 'react';
import { Flex } from '..';
import { SnexButton } from '../SnexButton/SnexButton';
import './index.module.css';

export interface SelectListItem {
    label: string;
    sublabel?: string;
    value: number | string;
}
export interface SelectListProps {
    items: SelectListItem[];
    variant?: 'small' | 'default';
    vertical?: boolean;
    multiSelect?: boolean;
    onChange: (items: SelectListItem[]) => any;
    selectedItems: (string | number)[];
}

export const SelectList = React.memo((props: SelectListProps) => {
    const handleClick = async (item: SelectListItem) => {
        if (props.multiSelect) {
            let newSelectedItems = getSelectedItems();
            // check to see if it exists already in list
            const alreadyExists = newSelectedItems.some((i) => i.value === item.value);
            if (!alreadyExists) newSelectedItems.push(item);
            else newSelectedItems = newSelectedItems.filter((i) => i.value !== item.value);
            props.onChange(newSelectedItems);
        } else {
            const newSelectedItems = props.selectedItems && props.selectedItems.length > 0 && item.value === props.selectedItems[0] ? [] : [item];
            props.onChange(newSelectedItems);
        }
    };

    const getSelectedItems = useCallback((): SelectListItem[] => {
        if (!props.selectedItems) return [];
        return props.items.filter((i) => props.selectedItems.includes(i.value));
    }, [props.selectedItems, props.items]);

    const isSelected = (item: SelectListItem): boolean => {
        const isSelected = selectedItemIndex(item.value) > -1;
        return isSelected;
    };

    const selectedItemIndex = useCallback(
        (item: string | number): number => {
            return props.selectedItems?.findIndex((i) => i === item);
        },
        [JSON.stringify(props.selectedItems)]
    );

    return (
        <Flex row={!props.vertical} column={props.vertical} style={{ width: '100%' }}>
            {props.items?.map((i, idx) => (
                <SelectListButton
                    key={`select-list-checkbox-${idx}`}
                    label={i.label}
                    onClick={(e) => handleClick(i)}
                    selected={isSelected(i)}
                    sublabel={i.sublabel}
                    variant={props.variant}
                    vertical={props.variant !== 'small'}
                />
            ))}
        </Flex>
    );
});

interface SelectListButtonProps {
    label: string;
    sublabel: string;
    selected?: boolean;
    vertical?: boolean;
    variant: 'small' | 'default';
    onClick: (event: React.MouseEvent<HTMLInputElement>) => any;
}

const SelectListButton = React.memo((props: SelectListButtonProps) => {
    const { selected, variant, vertical } = props;
    const small = variant === 'small';

    return (
        <SnexButton
            // snexColor={props.selected ? colors.selectListSelectedItemColor : colors.selectListItemColor}
            // whiteText={props.selected}
            // snexDefaultTextColor={colors.primaryItemColor}
            className={`select-list-button${small ? ' small' : ''}${selected ? ' selected' : ''}`}
            onClick={props.onClick}
        >
            <Flex column={vertical} row={!vertical} justify='space-between' style={{ width: '100%' }}>
                <span className='label' style={{ marginBottom: vertical && props.sublabel ? 10 : undefined, flex: 1 }}>
                    {props.label}
                </span>
                {props.sublabel && <span className='sublabel' style={{flex: 1}}>{props.sublabel}</span>}
            </Flex>
        </SnexButton>
    );
});
