// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vTCtuZ7u9j892LcoFbdgA{width:100%}._3vTCtuZ7u9j892LcoFbdgA button{border:inherit;background:inherit;cursor:pointer}._2LcKOcAHXVUH0eI6uDr2Ej{height:100px;background-color:#fff;width:215px;border-radius:10px;box-shadow:0 0px 10px var(--boxShadow);transition-duration:200ms}._2LcKOcAHXVUH0eI6uDr2Ej:hover{background:#fff;cursor:pointer;box-shadow:0 2px 10px 5px var(--boxShadow)}.Tn6gnINnH8ULj3B-eiaK7{padding:0px;text-align:center}div._2ePDfnZm1jgXfxA0oieZ4g{width:215px;margin-top:-2px}._1w8YUIGfvRZMqVAzsYuNoq{font-Size:18px;color:var(--segmentedControlActiveColor);font-weight:bold;padding-Top:40px;padding-Bottom:45px}._34uAwKz2-V-Zv1m87UxnRz{font-Size:16px;vertical-Align:middle;margin-Left:10px}._2e-pI9PROr_Ox-hyGLIajT{border-radius:0px 0px 10px 10px}._3ha7djToVxli8ja5aT0JeN{padding:10px;margin-bottom:10px;overflow:hidden;transition:height .25s cubic-bezier(0.55, 0.055, 0.675, 0.19)}.goY3hVEdFN-0Vf327C4q0{justify-content:flex-start}._2zUCVZa86FSljXnaCQsKP8{border-radius:0}._2zUCVZa86FSljXnaCQsKP8:first-of-type{border-radius:10px 10px 0 0 !important}._2zUCVZa86FSljXnaCQsKP8:last-of-type{border-radius:0 0 10px 10px !important}._2zUCVZa86FSljXnaCQsKP8:hover{background-color:rgba(51,96,148,.05)}", ""]);
// Exports
exports.locals = {
	"wrapper": "_3vTCtuZ7u9j892LcoFbdgA",
	"add-account-card": "_2LcKOcAHXVUH0eI6uDr2Ej",
	"card-content": "Tn6gnINnH8ULj3B-eiaK7",
	"negative-chart-img": "_2ePDfnZm1jgXfxA0oieZ4g",
	"add-account-card-txt": "_1w8YUIGfvRZMqVAzsYuNoq",
	"add-icon": "_34uAwKz2-V-Zv1m87UxnRz",
	"tv-lightweight-charts": "_2e-pI9PROr_Ox-hyGLIajT",
	"account-list-row-wrapper": "_3ha7djToVxli8ja5aT0JeN",
	"account-grid-card-row-wrapper": "goY3hVEdFN-0Vf327C4q0",
	"add-account-select-list-item": "_2zUCVZa86FSljXnaCQsKP8"
};
module.exports = exports;
