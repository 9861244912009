// @ts-strict-ignore
import { ReduxAction } from "phoenix/models"
import { Actions } from "../actions"
import { AuthorizationSettingsState } from "../models/AuthorizationSettings/AuthorizationSettingsState"

export const AuthorizationSettingsReducer = (state: AuthorizationSettingsState = new AuthorizationSettingsState(), action: ReduxAction): AuthorizationSettingsState => {
    switch (action.type) {
        case Actions.AuthorizationSettings.GetSettings.Loading: return { ...state, settings: state.settings.startLoading() }
        case Actions.AuthorizationSettings.GetSettings.Success: return { ...state, settings: state.settings.succeeded(action.data) }
        case Actions.AuthorizationSettings.GetSettings.Failure: return { ...state, settings: state.settings.failed(action.error) }
        default: return state
    }
}
